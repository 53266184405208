import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import styles from "assets/jss/material-kit-react/views/components.js";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  Box,
  Typography,
  OutlinedInput,
  Snackbar,
  SnackbarContent,
} from "@material-ui/core";
import SignupFooter from "../Signup/footer";
import HomePage5 from "views/HomePage/HomePage5";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import facebook from "../../assets/img/facebook2.svg";
import google from "../../assets/img/google.svg";
import linkedin from "../../assets/img/in2.svg";
import twitter from "../../assets/img/twitter2.svg";
import signinImage from "../../assets/img/home-image2.png";
import { setAccessToken } from "utilities/tokenUtil";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { Store } from "context/store/storeState";
import Loader from "views/Components/loader/loader";
import { ContactlessOutlined } from "@material-ui/icons";
import {
  googleProvider,
  twitterProvider,
  facebookProvider,
} from "configs/AuthMethod";
import SocialMedia from "configs/SocialMediaAuth";
import { useLinkedIn } from "react-linkedin-login-oauth2";

var axios = require("axios");
const useStyles = makeStyles(styles);

export default function SignIn() {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const user_type = localStorage.getItem("user_type");
  const classes = useStyles();
  const history = useHistory();
  const api_url = process.env.REACT_APP_URL;
  const linkedin_client_id = process.env.REACT_APP_LINKEDIN_CLIENT_ID;
  const linkedin_redirect_url = process.env.REACT_APP_LINKEDIN_REDIRECT_URL;
  const [message, setMessage] = useState({});
  const [open, setOpen] = useState(false);
  const [login, setUserName] = useState("");
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [userName, setUsername] = useState("");
  const [values, setValues] = useState({ showPassword: false });
  const [error, setError] = useState({ type: "", content: "" });
  const [check, setCheck] = useState(
    localStorage.getItem("userName") !== null ? true : false
  );
  const verifyContext = useContext(Store);
  const setVerify = verifyContext.setVerify;

  let regEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  console.log("linkedin_client_id", linkedin_client_id);
  const signInHandle = () => {
    setLoading(true);

    if (login === "") {
      setLoading(false);
      setOpen(true);
      setError({ type: "error", content: "Email cannot be empty" });
      return;
    }
    if (!regEmail.test(login)) {
      setLoading(false);
      setOpen(true);
      setError({ type: "error", content: "Please enter a valid email." });
      return;
    }
    if (password === "") {
      setLoading(false);
      setOpen(true);
      setError({ type: "error", content: "Password cannot be empty" });
      return;
    }
    const dataSet = JSON.stringify({
      login,
      password,
    });
    const config = {
      method: "post",
      url: process.env.REACT_APP_URL + "/api/v1/auth/login",
      data: dataSet,
      headers: {
        "Content-Type": "text/plain",
      },
    };
    axios(config)
      .then((response) => {
        if (response && response.data.success) {
          setUsername(response.data.user_name); //Setting userName
          setAccessToken(response.data.access_token); //Setting access token
          localStorage.setItem("userId", response.data.user_id);
          localStorage.setItem("user_email", response.data.user_email);
          localStorage.setItem("access_token", response.data.access_token);
          setMessage({ type: "success", content: response.data.message });
          history.push("/verifyOTP");
          setOpen(true); ////Setting snackbar open

          setLoading(false);
        } else {
          setOpen(true);
          setError({ type: "error", content: response.data.message });
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };
  const onClickSocials = async (provider) => {
    const res = await SocialMedia(provider);
    window.scrollTo(0, 0);
    if (res !== undefined) {
      if (res.success) {
        setLoading(false);
        setOpen(true);
        setMessage({ type: "success", content: res.message });
        window.location.href = "supplierdash";
      } else {
        setLoading(false);
        setOpen(true);
        setMessage({ type: "error", content: res.message });
        setTimeout(() => {
          history.push("/signup");
        }, 3000);
      }
    }
  };
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const { linkedInLogin } = useLinkedIn({
    clientId: linkedin_client_id,
    redirectUri: linkedin_redirect_url,
    onSuccess: (code) => {
      history.push("/supplierdash");
      //console.log("Linked code", code);
    },
    onError: (error) => {
      reloadFunction();
      //console.log("Linked error", error);
    },
  });

  const linkedinAuthCode = () => {
    let linked_url = window.location.href;
    if (linked_url) {
      let split_url = linked_url.split("=")[1];
      let auth_token;
      if (split_url) {
        auth_token = split_url.split("&state")[0];

        const body = {
          id_token: "",
          accessToken: "",
          linkedin_token: auth_token,
          twitter_token: "",
          consumer_key: "",
          consumer_secret: "",
          access_token_secret: "",
          mobile: "",
          name: "",
          email: "",
          provider: "linkedin.com",
        };

        axios
          .post(api_url + "/api/v1/social/media/login", body, {
            headers: {
              "Content-Type": "text/plain",
            },
          })
          .then((res) => {
            if (res.data.status) {
              localStorage.setItem("userName", res.data.user_name);
              localStorage.setItem("userId", res.data.user_id);
              localStorage.setItem("user_type", res.data.user_type);
              localStorage.setItem("user_role", res.data.user_role);
              localStorage.setItem("access_token", res.data.access_token);
              localStorage.setItem("user_email", res.data.user_email);
              localStorage.setItem("kyc_type", res.data.kyc_type);
              localStorage.setItem("kyc_status", res.data.kyc_status);
              localStorage.setItem("currency_name", res.data.currency_name);
              localStorage.setItem("currency_symbol", res.data.currency_symbol);
              localStorage.setItem("is_lender", res.data.is_lender);
              if (res.data.user_type === "both") {
                localStorage.setItem("trader_type", "supplier");
              } else if (res.data.user_type === "buyer") {
                localStorage.setItem("trader_type", "buyer");
              } else {
              }
              localStorage.setItem(
                "redirect_company_info",
                res.data.redirect_company_info
              );
              localStorage.setItem(
                "is_manual_kyc_enabled",
                res.data.is_manual_kyc_enabled
              );
              setTimeout(() => {
                window.close();
              }, 1000);
              //history.push("/supplierdash");
            } else {
              setOpen(true);
              setMessage({ type: "error", content: res.data.message });
              setLoading(false);
              setTimeout(() => {
                window.close();
              }, 1000);
            }
          });
      }
    }
  };
  const reloadFunction = () => {
    window.location.reload(false);
  };

  useEffect(() => {
    setVerify({ email: "" });
    setTimeout(() => {
      setError({});
    }, 5000);
  }, [error.type == "error"]);

  useEffect(() => {
    window.scrollTo(0, 0);
    linkedinAuthCode();
    document.title = "Interswitch - Sign In";
  }, []);
  return (
    <>
      {check && check === true ? (
        <>
          {user_type === "buyer"
            ? history.push("/buyer/dashboard")
            : history.push("/supplierdash")}
        </>
      ) : (
        <>
          <Loader loading={loading} />
          {message.type === "success" && (
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={open}
              autoHideDuration={4000}
              onClose={handleClose}>
              <SnackbarContent
                className={
                  message.type === "error"
                    ? "snack-error"
                    : message.type === "success"
                    ? "snack-success"
                    : "snack-teal"
                }
                message={message.content}
              />
            </Snackbar>
          )}
          <Box className="signin-page">
            <Box className="scf-box">
              <Box className="scf-subbox">
                {isMobile ? (
                  <Grid item xs={12} sm={12} md={5} xl={5}>
                    <img className="mobile-img" src={signinImage} />
                  </Grid>
                ) : (
                  ""
                )}
                <Grid container spacing={10}>
                  <Grid item xs={12} sm={12} md={5} xl={5}>
                    <Box className="form-box">
                      <Grid item xs={12}>
                        <Grid item xs={12} className="error-msg">
                          {error ? error.content : ""}
                        </Grid>
                        <Grid item xs={12} className="heading">
                          Sign In
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Box className="input-box">
                          <span className="company-title3">
                            Email <span className="required">*</span>
                          </span>
                          <OutlinedInput
                            fullWidth
                            autoFocus
                            type="email"
                            className="company-title51"
                            placeholder="Email"
                            value={login}
                            onChange={(e) => setUserName(e.target.value)}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box className="input-box">
                          <span className="company-title3">
                            Password <span className="required">*</span>
                          </span>
                          <OutlinedInput
                            type={values.showPassword ? "text" : "password"}
                            fullWidth
                            className="company-title51"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end">
                                  {values.showPassword ? (
                                    <i className="far fa-eye-slash"></i>
                                  ) : (
                                    <i className="fas fa-eye"></i>
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} className="button-box">
                        <Button
                          onClick={signInHandle}
                          className="button"
                          disabled={!login || !password}>
                          Sign In
                        </Button>
                      </Grid>

                      <Grid container>
                        <Grid item xs={6}>
                          <Link to={"/forgotPassword"} className={classes.link}>
                            <span className="forgot-password">
                              Forgot Password
                            </span>
                          </Link>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          container
                          direction="row"
                          alignItems="center"
                          justify="flex-end">
                          <Link to={"/signup"} className={classes.link}>
                            <span className="sign-up">Signup</span>
                          </Link>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} className="social-box">
                        <Grid container className="social">
                          <Grid item xs={3}>
                            <hr className="border-line" />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              align="center"
                              className="social-login-title">
                              Or Sign In With
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <hr className="border-line" />
                          </Grid>
                        </Grid>

                        <Grid container spacing={3} className="social-login">
                          <Grid
                            item
                            xs={3}
                            sm={3}
                            md={3}
                            xl={3}
                            className="img-box"
                            onClick={() => onClickSocials(googleProvider)}>
                            <img src={google} />
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            sm={3}
                            md={3}
                            xl={3}
                            className="img-box"
                            onClick={() => onClickSocials(facebookProvider)}>
                            <img src={facebook} />
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            sm={3}
                            md={3}
                            xl={3}
                            className="img-box">
                            <img src={linkedin} onClick={linkedInLogin} />
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            sm={3}
                            md={3}
                            xl={3}
                            className="img-box"
                            onClick={() => onClickSocials(twitterProvider)}>
                            <img src={twitter} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  {isMobile ? (
                    ""
                  ) : (
                    <Grid item xs={12} sm={12} md={5} xl={5}>
                      <img className="right-image" src={signinImage} />
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Box>
          </Box>
        </>
      )}
      {isMobile ? "" : <HomePage5 />}
      <SignupFooter />
    </>
  );
}

// {"success":true,"message":"Login Successfully!","user_id":2,"user_name":"Administrator","user_email":"admin@example.com","access_token":"a0042cba509e507eeca1b0fd1a456a39d869df04"}
