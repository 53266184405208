import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Button, Snackbar, SnackbarContent, } from "@material-ui/core";
import mainImage from "../../assets/img/KYCComplete.png";
import secondImage from "../../assets/img/BankKyc.png"
import { isMobile } from 'react-device-detect';
import ThemeContainer from 'views/Components/sideBar/ThemeContainer';
import { Link } from 'react-router-dom';
import { Spinner } from "react-bootstrap";

export default function KYCDocSuccess() {
    const [loader, setLoader]       = useState(false);
    const [open, setOpen]           = useState(false);
    const [loading, setLoading]     = useState(true);
    const [message, setMessage]     = useState({});
    const kyc_type                  = localStorage.getItem("kyc_type");

    useEffect(() => {
        document.title = 'Interswitch - KYC Success';
        window.scrollTo(0, 0)
        setLoader(true)
    }, [])

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
      }
      setOpen(false);
    };
    return (
        <ThemeContainer type='private' value={5}>
            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={open} autoHideDuration={3000} onClose={handleClose} >
                <SnackbarContent className={message.type === "error" ? "snack-error" : message.type === "success" ? "snack-success" : "snack-teal" } message={message.content} />
            </Snackbar>
            {loader !== true ? (
                <Grid container spacing={3} className="bootstrap-loader">
                  
                    <Spinner animation="grow" variant="spinner1" />
                    <Spinner animation="grow" variant="spinner2" />
                    <Spinner animation="grow" variant="spinner3" />
                  
                </Grid>
              ) : (
              <>
            <Box className="scf-box kyc-success">
                <Box className="scf-subbox">
                    <Grid container className="scf-container">
                        <Grid item xs={12} className="image-box">
                            <img src={kyc_type === "Bank" ? secondImage : mainImage} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className="heading">{kyc_type === "Bank" ? "You have provided company banking details for KYC by Bank successfully" : "You have added company details with KYC documents successfully"} </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className="subheading">{kyc_type === "Bank" ? "Thank you for providing updated Company banking information for KYC by Bank. Bank to platform lender verify your company banking information and documents then you got an email confirmation for your account activation." : "Thank you for providing updated Company information and KYC Document Submission. Bank to platform lender & admin verify your company information and documents then you got an email confirmation for your account activation."}</Typography>
                        </Grid>

                        <Grid item xs={12} className="image-box">
                            <Link to={"/kyc-page"}>
                                <Button className="button">Back</Button>
                            </Link>
                        </Grid>

                    </Grid>

                </Box>
            </Box>
            </>
          )}
        </ThemeContainer>
    );
}