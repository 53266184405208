import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { Button, Box, Toolbar } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import image from "./home_image.png";
import CardMedia from "@material-ui/core/CardMedia";
import { Link } from "react-router-dom";
import styles from "assets/jss/material-kit-react/views/components.js";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(styles);
import { isMobile } from "react-device-detect";

export default function HomePage(props) {
  const classes = useStyles();
  const api_url = process.env.REACT_APP_URL;
  const [data, setData] = useState(props.info);
  const [strip, setStrip] = useState(props.strip);
  return (
    <>
      {data && strip && data.length > 0 && (
        <>
          {isMobile && strip.length > 0 ? (
            <Box className="homepage-banner">
              <Box className="box">
                {strip && strip.length > 0 && (
                  <Typography className="typography">
                    {" "}
                    <marquee>
                      {strip.map((item, home_key) => (
                        <>
                          <div
                            key={home_key}
                            dangerouslySetInnerHTML={{
                              __html: item.strip_line,
                            }}></div>
                        </>
                      ))}
                    </marquee>{" "}
                  </Typography>
                )}
              </Box>
              <Box className="image-box">
                {data.map((item, home_key1) => (
                  <img
                    className="image"
                    key={home_key1}
                    src={`${api_url + item.image_url}?${Date.now()}`}
                  />
                ))}
              </Box>
            </Box>
          ) : (
            <Box></Box>
          )}
          <Box className="homepage-banner-bottom">
            <Box className="box">
              <Grid container>
                {data.map((item, home_key2) => (
                  <div key={home_key2}>
                    <Grid item xs={12} sm={12} md={7} xl={7}>
                      <Box className="banner-text">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.banner_text,
                          }}></div>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={5} xl={5}>
                      {!isMobile ? (
                        <Box className="banner-image">
                          <CardMedia
                            className="card-media"
                            component="img"
                            image={`${api_url + item.image_url}?${Date.now()}`}
                          />
                        </Box>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </div>
                ))}
              </Grid>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}

//: header / h7;
