import React, { useRef, useContext, useState } from 'react';
import IdleTimer from 'react-idle-timer';
import { useHistory } from "react-router-dom";
import { getAccessToken } from "../../utilities/tokenUtil";
import { Store } from "../../context/store/storeState";
var axios = require("axios");

function IdleTimerContainer () {
  const idleTimerRef = useRef(null);
  const history           = useHistory();
  const [login, setLogin] = useState(
    localStorage.getItem("userName") !== undefined ||
      localStorage.getItem("userName") !== null ||
      localStorage.getItem("userName") !== ""
      ? true
      : false
  );

  const verifyContext           = useContext(Store);
  const logged_name             = verifyContext.logged_name;
  const setLoggedName           = verifyContext.setLoggedName;
  const logged_id               = verifyContext.logged_id;
  const setLoggedID             = verifyContext.setLoggedID;

  const handleOnIdle = (event) => {    

    const dataSet = JSON.stringify({
      user_id: JSON.parse(localStorage.getItem("userId")),
      access_token: getAccessToken(),
    });
    let config = {
      method: "post",
      url: process.env.REACT_APP_URL + "/api/v1/auth/logout",
      data: dataSet,
      headers: {
        "Content-Type": "text/plain",
      },
    };
    axios(config)
      .then(res => {

          if(res.data.success === true){
            localStorage.clear();
            setLogin(false);
            setLoggedName(null);
            setLoggedID(null);
            history.push('/')
          }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  
  return (
    <>
      <IdleTimer
        ref={idleTimerRef}
        timeout={10 * 60000} // 10 min
        onIdle={handleOnIdle}
      />
    </>
  )
}

export default IdleTimerContainer;