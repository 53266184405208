import React, { useEffect, useState } from "react";
import { Paper, Snackbar, SnackbarContent, Grid, TableContainer, Button, Box, OutlinedInput, } from "@material-ui/core";
import { Container, Row, Col, Form, Spinner } from "react-bootstrap";
import "views/UpdateCompany/UpdateComponents/invoice/newBuyerInvoice.css";
import InvoiceDollar from "../../../assets/img/InvoiceDollar.svg";
import InvoiceView from "../../../assets/img/InvoiceView.svg";
import InvoicePdf from "../../../assets/img/InvoicePdf.svg";
import InvoiceDownload from "../../../assets/img/InvoiceDownload.svg";
import InvoiceActionDownload from "../../../assets/img/InvoiceActionDownload.svg";
import InvoiceActionView from "../../../assets/img/InvoiceActionView.svg";
import ThemeContainer from "./../../Components/sideBar/ThemeContainer";
import { getAccessToken } from "../../../utilities/tokenUtil";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import MuiTableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import axios from "axios";
import { useHistory } from "react-router-dom";
import DocumentListings from "views/UpdateCompany/UpdateComponents/invoice/documentListings";
import TextField from "@mui/material/TextField";

const TableCell = withStyles({
  root: {
    borderBottom: "none",
  },
})(MuiTableCell);

const ViewLenderInvoice = (props) => {

  const userId        = localStorage.getItem("userId");
  const userName      = localStorage.getItem("userName");
  const trader_type   = localStorage.getItem("trader_type");
  const kyc_status    = localStorage.getItem("kyc_status");
  const access_token  = getAccessToken();

  const history = useHistory();

  const dataList = (props.location && props.location.state) || {};
  const [invoiceId, SetinvoiceId]     = useState(dataList.invoice_id);
  const [data, setData]               = useState();
  const [buyer, setBuyer]             = useState();
  const [supplier, setSupplier]       = useState();
  const [open, setOpen]               = useState(false);
  const [loader, setLoader]           = useState(false);
  const [loading, setLoading]         = useState(true);
  const [message, setMessage]         = useState({})

  const [buyerId, setbuyerId]             = useState("");
  const [buyerLocationId, setbuyerLocationId] = useState("");
  const [purchaseOrderNO, setpurchaseOrderNO] = useState("");
  const [purchaseOrderDate, setpurchaseOrderDate] = useState("");
  const [invoiceNo, setinvoiceNo]             = useState("");
  const [invoiceammount, setinvoiceammount]   = useState("");
  const [invoiceDate, setinvoiceDate]         = useState("");
  const [dueDate, setdueDate]                 = useState("");
  const [creditDays, setcreditDays]           = useState("");
  const [amountRequired, setamountRequired]   = useState("");
  const [logistic, setlogistic]               = useState("");

  const [locationId, setlocationId]           = useState("");
  const [deliveryChallan, setdeliveryChallan] = useState("");
  const [deliveryChallanDate, setdeliveryChallanDate] = useState("");
  const [goodReceivingNote, setgoodReceivingNote] = useState("");
  const [grnDate, setgrnDate]                 = useState("");
  const [grnNo, setgrnNo]                     = useState("");
  const [receivedBy, setreceivedBy]           = useState("");
  const [remark, setremark]                   = useState("");
  const [InvoiceDocuments, setInvoiceDocuments] = useState([]);
  const [lenderID, setLenderID]               = useState();
  const [lenderName, setLenderName]           = useState("");
  const [invoice_status, setInvoiceStatus]    = useState("");


  if(!userId || userName === null){
    history.push('/signin');
  } 
  const handleClose = (event, reason) => {
      if (reason === 'clickaway') { return; }
      setOpen(false);
  }

  // Download uploaded files
  const downloadDocument = (event, document_id) => {
      event.preventDefault();        
      
      const data = {
          user_id: parseInt(localStorage.getItem("userId")),
          access_token: access_token,
          invoice_id: parseInt(dataList.invoice_id),
          document_id: parseInt(document_id)
      };

      axios
      .post(`${process.env.REACT_APP_URL}/api/v1/invoice/document/download`, data, {
          headers: {
              "Content-Type": "text/plain",
          },
      })
      .then((response) => {
        
          if(response.data.success === true){
              const api_url = process.env.REACT_APP_URL
              let url = `${api_url+response.data.document_url}?${Date.now()}`;
              window.open( url, '_blank' );
          }else{
              setLoading(false);
              setOpen(true);
              setMessage({ type: "error", content: "Something went wrong." });
          }
          
      })
      .catch((error) => {
          console.log(error);
      });
  };
  const invoiceDetail = () => {
    
    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: access_token,
      invoice_id: invoiceId,
    };

    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/invoice/detail`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        if (response.data.success) {

          if(response.data.logistics === "" && trader_type === "buyer"){
            setLoading(false);
            setOpen(true);
            setMessage({ type: "error", content: "Please add logistic details." });
          }
          
          setData(response.data);
          setBuyer(response.data.buyer_detail[0]);
          setSupplier(response.data.supplier_detail[0]);

          setInvoiceDocuments(response.data.documents);
          setlocationId(response.data.location_id)
          setbuyerLocationId(response.data.buyer_location_id)
          setdueDate(response.data.due_date);
          setpurchaseOrderNO(response.data.purchase_order_no);
          setinvoiceNo(response.data.invoice_no);
          setinvoiceammount(response.data.invoice_amount);
          setpurchaseOrderDate(response.data.purchase_order_date);
          setcreditDays(response.data.credit_days);
          setlogistic(response.data.logistics);
          setdeliveryChallanDate(response.data.delivery_challan_date);
          setreceivedBy(response.data.received_by);
          setremark(response.data.remark);
          setLenderID(response.data.lender_id)
          setgrnNo(response.data.grn_no);
          setgrnDate(response.data.grn_date);
          setamountRequired(response.data.amount_required)
          setinvoiceDate(response.data.invoice_date)
          setdeliveryChallan(response.data.delivery_challan)
          setgoodReceivingNote(response.data.good_receiving_note)
          setbuyerId(response.data.buyer_id)
          setInvoiceStatus(response.data.status)
          setLoader(true)
        }else{
          setLoader(true)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  
  const onSubmit = () => {
        
      if (logistic === "" || locationId === "" || deliveryChallan === "" || deliveryChallanDate === ""
          || goodReceivingNote === "" || grnNo === "" || grnDate === "" || receivedBy === "" || remark === "") {
          setOpen(true)
          setMessage({ type: "error", content: "Please fill all logistic details." })
          return
      }

      const data =
      {
          id: invoiceId,
          access_token: access_token,
          user_id: parseInt(localStorage.getItem("userId")),
          buyer_id: buyerId,
          lender_id: lenderID,
          buyer_location_id: buyerLocationId,
          purchase_order_no: purchaseOrderNO,
          purchase_order_date: purchaseOrderDate !== "False" ? purchaseOrderDate : '',
          invoice_no: invoiceNo,
          invoice_date: invoiceDate !== "False" ? invoiceDate : '',
          invoice_amount: invoiceammount,
          due_date: dueDate !== "False" ? dueDate : '',
          credit_days: creditDays,
          amount_required: amountRequired,
          location_id: locationId,
          delivery_challan: deliveryChallan,
          delivery_challan_date: deliveryChallanDate !== "False" ? deliveryChallanDate : '',
          good_receiving_note: goodReceivingNote,
          grn_date: grnDate !== "False" ? grnDate : '',
          grn_no: grnNo,
          received_by: receivedBy,
          remark: remark,
          logistics: logistic
      };
      setLoader(false)
      axios
          .post(`${process.env.REACT_APP_URL}/api/v1/invoice/create`, data, {
              headers: {
                  "Content-Type": "text/plain",
              },
          })
          .then((response) => {
              if (response.data.success === true) {
                  SetinvoiceId(response.data.invoice_id)
                  setLoading(false);
                  setOpen(true);
                  setMessage({ type: "success", content: response.data.message });
                  setTimeout(() => {
                    history.push('/invoice')
                  }, 3000)
              }else{
                  setLoading(false);
                  setOpen(true);
                  setLoader(true)
                  setMessage({ type: "error", content: response.data.message });
              }

          })
          .catch((error) => {
              console.log(error);
              setLoader(true)
          });
  };

  const onCancel = () => {
      history.push('/invoice')
  }
  const statusUpdate = () => {
      if (logistic === "" || locationId === "" || deliveryChallan === "" || deliveryChallanDate === ""
          || goodReceivingNote === "" || grnNo === "" || grnDate === "" || receivedBy === "" || remark === "") {
          setOpen(true)
          setMessage({ type: "error", content: "Please fill all logistic details." })
          return
      }

      const data =
      {
          id: invoiceId,
          access_token: access_token,
          user_id: parseInt(localStorage.getItem("userId")),
          buyer_id: buyerId,
          lender_id: lenderID,
          buyer_location_id: buyerLocationId,
          purchase_order_no: purchaseOrderNO,
          purchase_order_date: purchaseOrderDate !== "False" ? purchaseOrderDate : '',
          invoice_no: invoiceNo,
          invoice_date: invoiceDate !== "False" ? invoiceDate : '',
          invoice_amount: invoiceammount,
          due_date: dueDate !== "False" ? dueDate : '',
          credit_days: creditDays,
          amount_required: amountRequired,
          location_id: locationId,
          delivery_challan: deliveryChallan,
          delivery_challan_date: deliveryChallanDate !== "False" ? deliveryChallanDate : '',
          good_receiving_note: goodReceivingNote,
          grn_date: grnDate !== "False" ? grnDate : '',
          grn_no: grnNo,
          received_by: receivedBy,
          remark: remark,
          logistics: logistic
      };
      setLoader(false)
      const status_data = {
          user_id: parseInt(localStorage.getItem("userId")),
          access_token: access_token,
          invoice_id: parseInt(invoiceId),
          status: "waiting_for_lender_approval",
          comment: ""
      };

      axios
          .post(`${process.env.REACT_APP_URL}/api/v1/invoice/create`, data, {
              headers: {
                  "Content-Type": "text/plain",
              },
          })
          .then((response) => {
              if (response.data.success === true) {

                  axios
                    .post(`${process.env.REACT_APP_URL}/api/v1/invoice/update/status`, status_data, {
                        headers: {
                            "Content-Type": "text/plain",
                        },
                    })
                    .then((response) => {
                        if(response.data.success === true){
                            setLoading(false);
                            setOpen(true);
                            setMessage({ type: "success", content: response.data.message });
                            setTimeout(() => {
                              history.push('/invoice')
                            }, 3000)
                        }else{
                            setLoading(false);
                            setOpen(true);
                            setLoader(true)
                            setMessage({ type: "error", content: response.data.message });                
                        }

                    })
                    .catch((error) => {
                        console.log(error);
                        setLoader(true)
                    });

              }else{
                  setLoading(false);
                  setOpen(true);
                  setLoader(true)
                  setMessage({ type: "error", content: response.data.message });
              }

          })
          .catch((error) => {
              console.log(error);
          });
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'Interswitch - Invoice Detail';
    invoiceDetail();
  }, []);

  return (
    <>
      
        {loader !== true ? (
          <Grid container spacing={3} className="bootstrap-loader">
            
              <Spinner animation="grow" variant="spinner1" />
              <Spinner animation="grow" variant="spinner2" />
              <Spinner animation="grow" variant="spinner3" />
            
          </Grid>
        ) : (
          <>
          {data && data != undefined && data != null && (
            <>
              <Container className="MainContainer view-invoice-detail">
                <Paper elevation={2} className="MainPaper">
                  {/* Inovice */}
                  <Row className="my-4">
                    <Col className="heading main" md={11} xs={8} > Invoice </Col>
                    <Col className="heading" md={1} xs={4} >                    
                      <button className="back-button upload" onClick={() => history.push("/lender/dashboard")}><i className="fas fa-long-arrow-alt-left"> {" "}Back </i></button>
                    </Col>
                  </Row>
                  <Paper elevation={2} className="paper">
                    <Row>
                      <Col md={9} className="heading3 pt-2">
                        <span>Invoice # :</span> {data.invoice_no}{" "}
                      </Col>
                      <Col md={3} className="invoice-status">
                        {data.status === "Rejected" &&
                          <button className="invoiceButton rejected">{data.status}</button>
                        }
                        {data.status === "Funded" &&
                          <button className="invoiceButton funded">{data.status}</button>
                        }
                        {data.status === "Awaiting Buyer Approval" &&
                          <button className="invoiceButton awaiting-buyer-approval">{data.status}</button>
                        }
                        {data.status === "Awaiting Lender Approval" &&
                          <button className="invoiceButton awaiting-lender-approval">{data.status}</button>
                        }
                        {data.status === "Draft" &&
                          <button className="invoiceButton draft">{data.status}</button>
                        }
                        {data.status === "Pending" &&
                          <button className="invoiceButton pending">{data.status}</button>
                        }
                      </Col>
                    </Row>
                  </Paper>
                  <br />
                  <Paper variant="outlined">
                    <Row>
                      <Col className="box1">
                        {supplier && (
                          <>
                            <Row>
                              <Col className="heading2">
                                {supplier.supplier_name}
                              </Col>
                            </Row>
                            <Row>
                              <Col>Supplier Details</Col>
                            </Row>
                            <br />

                            {supplier.tax_number &&
                            <Row>
                              <Col>
                                Tax Number :{" "}
                                {supplier.tax_number ? supplier.tax_number : ""}                            
                              </Col>
                            </Row>
                            }
                            {supplier.mobile &&
                            <Row>
                              <Col>
                                Mobile :{" "}
                                {supplier.mobile ? supplier.mobile : ""}                             
                              </Col>
                            </Row>
                            }
                            {supplier.phone &&
                            <Row>
                              <Col>
                                Phone :{" "}
                                {supplier.phone ? supplier.phone : ""}                            
                              </Col>
                            </Row>
                            }
                            <Row>
                              <Col>
                                Address :{" "}
                                  {supplier.address &&
                                      supplier.address
                                  }
                                  {supplier.city &&
                                      ", " + supplier.city
                                  }
                                  {supplier.state_name &&
                                      ", " + supplier.state_name 
                                  }
                                  {supplier.country_name &&
                                      ", " + supplier.country_name
                                  }
                                  {supplier.zip &&
                                      " - " + supplier.zip
                                  }

                              </Col>
                            </Row>
                          </>
                        )}
                      </Col>
                      <Col className="box2">
                        {buyer && (
                          <>
                            <Row>
                              <Col className="heading2">{buyer.buyer_name}</Col>
                            </Row>
                            <Row>
                              <Col>Buyer Details</Col>
                            </Row>
                            <br />
                            {buyer.tax_number &&
                            <Row>
                              <Col>
                                Tax Number :{" "}
                                {buyer.tax_number ? buyer.tax_number : ""}                            
                              </Col>
                            </Row>
                            }
                            {buyer.mobile &&
                            <Row>
                              <Col>
                                Mobile :{" "}
                                {buyer.mobile ? buyer.mobile : ""}                             
                              </Col>
                            </Row>
                            }
                            {buyer.phone &&
                            <Row>
                              <Col>
                                Phone :{" "}
                                {buyer.phone ? buyer.phone : ""}                            
                              </Col>
                            </Row>
                            }
                            <Row>
                              <Col>
                                Address :{" "}
                                  {buyer.address &&
                                      buyer.address
                                  }
                                  {buyer.city &&
                                      ", " + buyer.city
                                  }
                                  {buyer.state_name &&
                                      ", " + buyer.state_name 
                                  }
                                  {buyer.country_name &&
                                      ", " + buyer.country_name
                                  }
                                  {buyer.zip &&
                                      " - " + buyer.zip
                                  }
                              </Col>
                            </Row>
                          </>
                        )}
                      </Col>
                    </Row>
                    <Row className="toptable">                      
                      <TableContainer component={Paper}>
                        <Table className="table-top" aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell>Invoice</TableCell>
                              <TableCell align="left">Invoice Amount ($)</TableCell>
                              <TableCell align="left">
                                Approved Amount ($)
                              </TableCell>
                              <TableCell align="left">PO Number</TableCell>
                              <TableCell align="left">Issue Date</TableCell>
                              <TableCell align="left">Credit Days</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell align="left">{data.invoice_no}</TableCell>
                              <TableCell align="left">
                                {data.invoice_amount.toLocaleString(undefined, {minimumFractionDigits: 2})}
                              </TableCell>
                              <TableCell align="left">
                                {data.approved_amount.toLocaleString(undefined, {minimumFractionDigits: 2})}
                              </TableCell>
                              <TableCell align="left">
                                {data.purchase_order_no}
                              </TableCell>
                              <TableCell align="left">
                                {data.purchase_order_date}
                              </TableCell>
                              <TableCell align="left">{data.credit_days}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Row>
                  </Paper>
                  <Row>
                    <Col className="heading">Logistics</Col>
                  </Row>
                  <Paper variant="outlined" className="rounded logistics-form">                   

                    {invoice_status !== "Awaiting Buyer Approval" &&   
                    <>  
                    <div className="paper2">
                      <TableContainer component={Paper}>
                        <Table className="table-top" aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Logistic</TableCell>
                            <TableCell align="left">Buyer's Location</TableCell>
                            <TableCell align="left">Delevery Challan</TableCell>
                            <TableCell align="left">Delivery Challan Date</TableCell>
                            <TableCell>Good Receving Note</TableCell>
                            <TableCell align="left">GRN Number</TableCell>
                            <TableCell align="left">GRN Date</TableCell>
                            <TableCell align="left">Received By</TableCell>
                            <TableCell align="left">Remark</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell align="left">{logistic}</TableCell>
                            <TableCell align="left">{locationId}</TableCell>
                            <TableCell align="left">{deliveryChallan}</TableCell>
                            <TableCell align="left">{deliveryChallanDate !== "False" ? deliveryChallanDate : ""}</TableCell>
                            <TableCell align="left">{goodReceivingNote}</TableCell>
                            <TableCell align="left">{grnNo}</TableCell>
                            <TableCell align="left">{grnDate !== "False" ? grnDate : ""}</TableCell>
                            <TableCell align="left">{receivedBy}</TableCell>
                            <TableCell align="left">{remark}</TableCell>
                          </TableRow>
                        </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                    </>
                    }
                  </Paper>
                  {/* All Documents Report */}
                  <DocumentListings info={data.documents} invoice_id={invoiceId}/>
                  
                  {/*Invoice Blockchain Trail*/}
                  {/*<Row>
                    <Col className="heading">Invoice Blockchain Trail</Col>
                  </Row>
                  <Paper variant="outlined" className="rounded">
                    <div className="paper2">
                      <TableContainer component={Paper}>                      
                        <Table className="table-top" aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell>Sr.No</TableCell>
                              <TableCell align="left">Transaction Id</TableCell>
                              <TableCell align="left">Timestamp</TableCell>
                              <TableCell align="left">Status</TableCell>
                              <TableCell align="left">Transaction Description</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {data.blockchain_trail &&
                              data.blockchain_trail.length > 0 &&
                              data.blockchain_trail.map((info, i) => (
                                <>
                                  <TableRow key={i}>
                                    <TableCell align="left">{i+1}</TableCell>
                                    <TableCell align="left">{info.trans_hash_id}</TableCell>
                                    <TableCell align="left">{info.timestamp}</TableCell>
                                    <TableCell align="left">{info.status}</TableCell>
                                    <TableCell align="left"> </TableCell>
                                  </TableRow>
                                </>
                              ))}
                          </TableBody>
                        </Table>
                    </TableContainer>
                    </div>
                  </Paper>*/}

                  {/*Invoice History*/}
                  <Row>
                    <Col className="heading">Invoice Blockchain Trail</Col>
                  </Row>
                  <Paper variant="outlined" className="rounded">
                  <div className="paper2">
                    <TableContainer component={Paper}>
                      <Table className="table-top" aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="left">Updated By</TableCell>
                            <TableCell align="left">Updated On</TableCell>
                            <TableCell align="left">Blockchain Transaction Id</TableCell>
                            <TableCell align="left">Status</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data.status_history &&
                            data.status_history.length > 0 &&
                            data.status_history.map((info, j) => (
                              <>
                                <TableRow key={j}>
                                  <TableCell align="left">{info.name}</TableCell>
                                  <TableCell align="left">
                                    {info.updated_by_name}
                                  </TableCell>
                                  <TableCell align="left">
                                    {info.timestamp}
                                  </TableCell>
                                  <TableCell align="left">
                                    {info.blockchain_transaction_id}
                                  </TableCell>
                                  <TableCell align="left">
                                    {info.status}
                                  </TableCell>
                                </TableRow>
                              </>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    </div>
                  </Paper>
                  {/*Payment Schedule*/}
                  <Row>
                    <Col className="heading">Payment Schedule</Col>
                  </Row>
                  <Paper variant="outlined" className="rounded">
                    <div className="paper2">
                    <TableContainer component={Paper}>
                    <Table className="table-top" aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Lender Name</TableCell>
                          <TableCell align="left">Amount</TableCell>
                          <TableCell align="left">Comment</TableCell>
                          <TableCell align="left">Uploaded On</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.payment_schedule &&
                          data.payment_schedule.length > 0 &&
                          data.payment_schedule.map((info, k) => (
                            <>
                              <TableRow key={k}>
                                <TableCell align="left">{info.lender_name}</TableCell>
                                <TableCell align="left">{info.currency_symbol} {info.amount.toLocaleString(undefined, {minimumFractionDigits: 2})}</TableCell>
                                <TableCell align="left">{info.comment}</TableCell>
                                <TableCell align="left">{info.date}</TableCell>
                              </TableRow>
                            </>
                          ))}
                      </TableBody>
                    </Table>
                    </TableContainer>
                    </div>
                  </Paper>
                  {/*Repaid*/}
                  <Row>
                    <Col className="heading">Repaid</Col>
                  </Row>
                  <Paper variant="outlined" className="rounded">
                    <div className="paper2">
                    <TableContainer component={Paper}>
                      <Table className="table-top" aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Lender Name</TableCell>
                            <TableCell align="left">Paid By</TableCell>
                            <TableCell align="left">Amount</TableCell>
                            <TableCell align="left">Transaction Type</TableCell>
                            <TableCell align="left">Payment Mode</TableCell>
                            <TableCell align="left">Comment</TableCell>
                            <TableCell align="left">Uploaded On</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data.repaid &&
                            data.repaid.length > 0 &&
                            data.repaid.map((info, l) => (
                              <>
                                <TableRow key={l}>
                                  <TableCell align="left">{info.lender_name}</TableCell>
                                  <TableCell align="left">{info.paid_by_name}</TableCell>
                                  <TableCell align="left">{info.currency_symbol} {info.amount.toLocaleString(undefined, {minimumFractionDigits: 2})}</TableCell>
                                  <TableCell align="left">{info.transaction_type}</TableCell>
                                  <TableCell align="left">{info.payment_mode_name}</TableCell>
                                  <TableCell align="left">{info.comment}</TableCell>
                                  <TableCell align="left">{info.date}</TableCell>
                                </TableRow>
                              </>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    </div>
                  </Paper>
                  {/*Transaction*/}
                  <Row>
                    <Col className="heading">Transaction</Col>
                  </Row>
                  <Paper variant="outlined" className="rounded">
                    <div className="paper2">
                    <TableContainer component={Paper}>
                      <Table className="table-top" aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Lender Name</TableCell>
                            <TableCell align="left">Amount</TableCell>
                            <TableCell align="left">Transaction Type</TableCell>
                            <TableCell align="left">Comment</TableCell>
                            <TableCell align="left">Uploaded On</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data.transaction &&
                            data.transaction.length > 0 &&
                            data.transaction.map((info, index) => (
                              <>
                                <TableRow key={index}>
                                  <TableCell align="left">{info.lender_name}</TableCell>
                                  <TableCell align="left">{info.currency_symbol} {info.amount.toLocaleString(undefined, {minimumFractionDigits: 2})}</TableCell>
                                  <TableCell align="left">{info.transaction_type}</TableCell>
                                  <TableCell align="left">{info.comment}</TableCell>
                                  <TableCell align="left">{info.date}</TableCell>
                                </TableRow>
                              </>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    </div>
                  </Paper>

                </Paper>
              </Container>
            </>
          )}
          </>
        )}
        
      
    </>
  );
};
export default ViewLenderInvoice;
