import * as React from "react";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import BuyersIcon from "../../../assets/img/BuyersIcon.svg";
import DashboardIcon from "../../../assets/img/DashboardIcon.svg";
import UsersIcon from "../../../assets/img/UsersIcon.svg";
import LendersIcon from "../../../assets/img/LendersIcon.svg";
import InvoiceIcon from "../../../assets/img/InvoiceIcon.svg";
import CompanyIcon from "../../../assets/img/CompanyIcon.svg";
import SignatoryIcon from "../../../assets/img/SignatoryIcon.svg";
import SettingsIcon from "../../../assets/img/SettingsIcon.svg";
import OnlyActiveOffer from "../../../assets/img/Navbar/OnlyActiveOffer.svg";
import OnlyKyc from "../../../assets/img/Navbar/OnlyKyc.svg";
import OnlyCreditAssessment from "../../../assets/img/Navbar/OnlyCreaditAssessment.svg";
import OnlyCreditReq from "../../../assets/img/Navbar/OnlyCreditReq.svg";
import OnlyMyAccount from "../../../assets/img/Navbar/OnlyAccount.svg";
import OnlySupplier from "../../../assets/img/Navbar/OnlySupplier.svg";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import ListItemButton from "@mui/material/ListItemButton";
import Collapse from "@mui/material/Collapse";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

var axios = require("axios");
// import jwt from "jsonwebtoken";
import { getAccessToken } from "../../../utilities/tokenUtil";


export default function MobileSidebar({ activity, type, info }) {
  const [user, setUser] = React.useState(
    localStorage.getItem("userName") ? localStorage.getItem("userName") : ""
  );
  const trader_type = localStorage.getItem("trader_type");
  const datas = info;
  const [open, setOpen] = React.useState(activity.open);
  const [loading, setLoading] = React.useState(false);
  const userId = localStorage.getItem("userId");
  const userName = localStorage.getItem("userName");
  const [data, setData] = React.useState(datas);
  const [login, setLogin] = React.useState(
    localStorage.getItem("userName") !== undefined &&
    localStorage.getItem("userName") !== null
    ? true
    : false
  );

  const history = useHistory();
  const dataSet = JSON.stringify({
    user_id: JSON.parse(userId),
    access_token: getAccessToken(),
  });
  const logout = () => {
    setLoading(true);
    const config = {
      method: "post",
      url: process.env.REACT_APP_URL + "/api/v1/auth/logout",
      data: dataSet,
      headers: {
        "Content-Type": "text/plain",
      },
    };
    axios(config)
      .then((response) => {
        if (response && response.data.success) {
          // alert(response.data.success_msg)
          history.push("/");
          localStorage.clear();
          setLoading(false);
          window.location.reload(false);
        } else {

          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };
  const toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(false);
    activity.setOpen(false);
    //I;
  };
  const iconList = [
    DashboardIcon,
    UsersIcon,
    LendersIcon,
    BuyersIcon,
    InvoiceIcon,
    CompanyIcon,
    SignatoryIcon,
    SettingsIcon,
  ];

  const handleClick = () => {
    setOpen(!open);
  };

  const list = () => (
    <Box id={"Box"} style={{ paddingTop: "39%" }} role="presentation">
      {type === "private" ? (
        <List>
          <ListItem button component={Paper}>
            {" "}
            <Box style={{ display: "flex", flexDirection: "column" }}>
              <Typography
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#999999",
                }}
              >
                {"Logged in as :"}
              </Typography>
              <Typography
                style={{
                  fontSize: "13px",
                  fontWeight: "400",
                  color: "#000000",
                }}
              >
                {user}
              </Typography>
            </Box>
          </ListItem>
          <Link to={trader_type === "supplier" ? "/supplierdash" : "/buyer/dashboard"}>
            <ListItem button component={Paper} className={"DashBoard"}>
              <ListItemIcon>
                <img
                  style={{
                    width: "50%",
                    paddingTop: "1%",
                    paddingBottom: "5%",
                  }}
                  src={iconList[0]}
                />
              </ListItemIcon>
              <ListItemText primary={"DashBoard"} />
            </ListItem>
          </Link>
          <Link to={trader_type === "supplier" ? "/buyer" : "/supplier"}>
            <ListItem button className={"Buyers"} component={Paper}>
              <ListItemIcon>
                {trader_type == "supplier" ? (
                  <img
                    style={{
                      width: "50%",
                      paddingTop: "1%",
                      paddingBottom: "25%",
                    }}
                    src={iconList[3]}
                  />
                ) : (
                  <img
                    style={{
                      width: "50%",
                      paddingTop: "1%",
                      paddingBottom: "25%",
                    }}
                    src={OnlySupplier}
                  />
                )}
              </ListItemIcon>
              <ListItemText
                primary={trader_type === "buyer" ? "Supplier" : "Buyers"}
              />
            </ListItem>
          </Link>
          {trader_type === "buyer" ? (
          <Link to={"/domiciled-banks"}>
            <ListItem button className={"Domiciledbank"} component={Paper}>
              <ListItemIcon>
                <img
                  style={{
                    width: "50%",
                    paddingTop: "1%",
                    paddingBottom: "5%",
                  }}
                  src={iconList[5]}
                />
              </ListItemIcon>
              <ListItemText primary={"Domiciled Banks"} />
            </ListItem>
          </Link>
          ):null}
          <Link to={"/invoice"}>
            <ListItem button className={"Invoices"} component={Paper}>
              <ListItemIcon>
                <img
                  style={{
                    width: "50%",
                    paddingTop: "1%",
                    paddingBottom: "5%",
                  }}
                  src={iconList[4]}
                />
              </ListItemIcon>
              <ListItemText primary={"Invoices"} />
            </ListItem>
          </Link>
          <Link to={"/kyc-page"}>
            <ListItem button className={"Kycpage"} component={Paper}>
              <ListItemIcon>
                <img
                  style={{
                    width: "50%",
                    paddingTop: "1%",
                    paddingBottom: "5%",
                  }}
                  src={OnlyKyc}
                />
              </ListItemIcon>
              <ListItemText primary={"KYC"} />
            </ListItem>
          </Link>
          {trader_type !== "buyer" && (
            <>
              <Link to={"/trader-assessment"}>
                <ListItem
                  button
                  className={"CreditAssessment"}
                  component={Paper}
                >
                  <ListItemIcon>
                    <img
                      style={{
                        width: "50%",
                        paddingTop: "1%",
                        paddingBottom: "5%",
                      }}
                      src={OnlyCreditAssessment}
                    />
                  </ListItemIcon>
                  <ListItemText primary={"Trader Assessment"} />
                </ListItem>
              </Link>
              <Link to={"/credit"}>
                <ListItem button className={"Lenders"} component={Paper}>
                  <ListItemIcon>
                    <img
                      style={{
                        width: "50%",
                        paddingTop: "1%",
                        paddingBottom: "5%",
                      }}
                      src={OnlyCreditReq}
                    />
                  </ListItemIcon>
                  <ListItemText primary={"Credit Request"} />
                </ListItem>
              </Link>
              {/*<Link to={"/activeOffer"}>
                <ListItem button className={"active-offer"} component={Paper}>
                  <ListItemIcon>
                    <img
                      style={{
                        width: "50%",
                        paddingTop: "1%",
                        paddingBottom: "5%",
                      }}
                      src={OnlyActiveOffer}
                    />
                  </ListItemIcon>
                  <ListItemText primary={"Active Offer"} />
                </ListItem>
              </Link>*/}                    
              <Link to={"/creditoffers"}>
                <ListItem button className={"credit-offer"} component={Paper}>
                  <ListItemIcon>
                    <img
                      style={{
                        width: "50%",
                        paddingTop: "1%",
                        paddingBottom: "5%",
                      }}
                      src={OnlyActiveOffer}
                    />
                  </ListItemIcon>
                  <ListItemText primary={"Credit Offers"} />
                </ListItem>
              </Link>
            </>
          )}
          {/*<Link to={"/mycompany"}>
            <ListItem button className={"Company"} component={Paper}>
              <ListItemIcon>
                <img
                  style={{
                    width: "60%",
                    paddingTop: "1%",
                    paddingBottom: "5%",
                  }}
                  src={iconList[5]}
                />
              </ListItemIcon>
              <ListItemText primary={"Company"} />
            </ListItem>
          </Link>
          <Link to={"/signatory"}>
            <ListItem button className={"Signatory"} component={Paper}>
              <ListItemIcon>
                <img
                  style={{
                    width: "60%",
                    paddingTop: "1%",
                    paddingBottom: "5%",
                  }}
                  src={iconList[6]}
                />
              </ListItemIcon>
              <ListItemText primary={"Signatory"} />
            </ListItem>
          </Link>*/}
          <ListItemButton onClick={handleClick}>
            <ListItemIcon>
              <img
                style={{ width: "70%", paddingTop: "1%", paddingBottom: "25%" }}
                src={iconList[7]}
              />
            </ListItemIcon>
            <ListItemText primary="Settings" />
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItemButton>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link to={"/myaccount"}>
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <img
                      style={{
                        width: "70%",
                        paddingTop: "1%",
                        paddingBottom: "25%",
                      }}
                      src={OnlyMyAccount}
                    />
                  </ListItemIcon>
                  <ListItemText primary="My Account" />
                </ListItemButton>
              </Link>
              <Link to={"/viewCompany"}>
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <img
                      style={{
                        width: "70%",
                        paddingTop: "1%",
                        paddingBottom: "25%",
                      }}
                      src={iconList[5]}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Company" />
                </ListItemButton>
              </Link>
              <Link to={"/manageuser"}>
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <img
                      style={{
                        width: "70%",
                        paddingTop: "1%",
                        paddingBottom: "25%",
                      }}
                      src={iconList[1]}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Users" />
                </ListItemButton>
              </Link>
              <Link to={"/signatory"}>
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <img
                      style={{
                        width: "70%",
                        paddingTop: "1%",
                        paddingBottom: "25%",
                      }}
                      src={iconList[6]}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Authorized Signatory" />
                </ListItemButton>
              </Link>
            </List>
          </Collapse>
          <ListItem button className={"logout"}>
            <Typography
              style={{ color: "#479FC8" }}
              onClick={logout}
            >
              Logout
            </Typography>
          </ListItem>
        </List>
      ) : (
        <>
          {datas && datas.length > 0 && (
            <List>
              {datas.map((item, index) => (
                <>
                  <a key={index} href={"/cms/" + item.page_type_name} >                  
                    <ListItem
                      button
                      component={Paper}
                      className={item.name}
                      onClick={(e) => {
                        toggleDrawer(e);
                      }}
                    >
                      <ListItemText primary={item.name} />
                    </ListItem>
                  </a>
                </>
              ))}

              {login && login === true ? (
                <ListItem button className={"logout"}>
                  <Typography
                    style={{ color: "#479FC8" }}
                    onClick={logout}
                  >
                    Logout
                  </Typography>
                </ListItem>
              ) : (
              <>
                <Link to={"/signin"}>
                  <ListItem
                    button
                    component={Paper}
                    className={"Login"}
                    onClick={(e) => {
                      toggleDrawer(e);
                    }}
                  >
                    <ListItemText primary={"Login"} />
                  </ListItem>
                </Link>
                <Link to={"signup"}>
                  <ListItem
                    button
                    className={"Sign Up"}
                    onClick={(e) => {
                      toggleDrawer(e);
                    }}
                  >
                    <Button
                      style={{
                        color: "#479FC8",
                        border: "1px solid #479FC8",
                        fontFamily: "Averta-Regular",
                        width: "100%",
                      }}
                    >
                      Sign Up
                    </Button>
                  </ListItem>
                </Link>
              </>
              )}
            </List>
          )}
        </>
      )}
    </Box>
  );
  return (
    <>
      <div style={{ paddingLeft: "200px" }}>
        {
          <>
            <React.Fragment>
              <Drawer
                transitionDuration={1}
                anchor={"left"}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                open={activity.open}
                onClose={() => toggleDrawer(false)}
              >
                {list()}
              </Drawer>
            </React.Fragment>
          </>
        }
      </div>
    </>
  );
}
