import React from "react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import { isMobile } from "react-device-detect";

import MainHomePage from "views/HomePage/MainHomePage";
import SignUp from "views/Signup/Signup.js";
import SignIn from "views/SignIn/SignIn";
import Authentication from "views/AuthenticationPage/Authentication";
import CompanyInfo from "views/CompanyInfoPage/CompanyInfo";
import KycDocUpload from "views/KycPage/KYCDocUpload";
import KYCNow from "views/KycPage/KYCComponents/KYCNow";
import Custom2 from "views/Components/Custom2";
import ManageUser from "views/ManageUser/ManageUser";
import AddNewUser from "views/ManageUser/AddNewUser";
import EditUser from "views/ManageUser/EditUser";
import ViewUser from "views/ManageUser/ViewUser";
import ManageInvoice from "views/ManageInvoice";
import MainAboutUs from "views/AboutUs/MainAboutUs";
import MainContactUs from "views/AboutUs/MainContactUs";
import Documents from "views/UpdateCompany/UpdateComponents/Documents";
import GoogleAuth from "views/Components/Googleauth/GoogleAuth";
import NavBar from "views/Components/Navbar";
import MyCompany from "views/UpdateCompany/MyCompany";
import InvoiceUpload from "views/UpdateCompany/UpdateComponents/invoice/invoiceUpload";
import VerticalBar from "views/SupplierDashboard/VerticalBars";
import MainTerms from "views/AboutUs/MainTerms";
import MainPrivacy from "views/AboutUs/MainPrivacyy";
import Change from "views/Password/Change";
import Reset from "views/Password/Reset";
import forgotPassword from "views/Password/Forgot";
import VerifyOTP from "views/Password/VerifyOTP";
import GoogleAuthentication from "views/Password/GoogleAuth";
import Loader from "views/Maintanance/Loader";
import Otpverify from "views/Password/Otpverify";
import Under1 from "views/Maintanance/Under1";
import UnderMaintenance from "views/Maintanance/Under2";
import AboutInterswitch from "views/AboutUs/AboutInterswitch";
import KYCDocSuccess from "views/KycPage/KYCDocSuccess";
import supplierDashboard from "views/SupplierDashboard/SupplierDashboard";
import Buyer from "views/UpdateCompany/UpdateComponents/buyer/Buyer";
import AddBuyer from "views/UpdateCompany/UpdateComponents/buyer/AddBuyer";
import EditBuyer from "views/UpdateCompany/UpdateComponents/buyer/EditBuyer";
import TraderAssessment from "views/UpdateCompany/UpdateComponents/document/Documents";
import CreditOffer from "views/ManageUser/CreditOffer";
import CreditOfferDetail from "views/ManageUser/CreditOfferDetail";
import CreditRequest from "views/ManageUser/CreditRequest";
import AddCreditRequest from "views/ManageUser/CreditRequest/AddCreditRequest";
import EditCreditRequest from "views/ManageUser/CreditRequest/EditCreditRequest";
import ViewSoa from "views/soa";
import Invoice from "views/UpdateCompany/UpdateComponents/invoice/Invoice";
import AddInvoice from "views/UpdateCompany/UpdateComponents/invoice/AddInvoice";
import EditInvoice from "views/UpdateCompany/UpdateComponents/invoice/EditInvoice";
import BuyerInvoice from "views/UpdateCompany/UpdateComponents/BuyerInvoice";
import { Toolbar } from "@material-ui/core";
import Signatory from "views/UpdateCompany/UpdateComponents/authorized/Authorized";
import MobileAppbar from "views/Components/MobileAppbar";

import editSignatory from "views/UpdateCompany/UpdateComponents/authorized/editSignatory";
import viewSignatory from "views/UpdateCompany/UpdateComponents/authorized/viewSignatory";
import ViewInvoiceList from "views/UpdateCompany/UpdateComponents/invoice/NewBuyerInvoice";
import NewBuyerInvoice from "views/UpdateCompany/UpdateComponents/invoice/NewBuyerInvoice";
import CompanyProfile from "views/UpdateCompany/UpdateComponents/UpdateCompany/CompanyProfile";
import ViewBuyer from "views/ManageUser/ViewBuyer";
import ViewUsers from "views/ManageUser/ViewUsers";
import ActiveOffer from "views/ActiveOffer/ActiveOffer";
import CreditOffers from "views/ActiveOffer/CreditOffer";
import ActiveCreditOfferDetail from "views/ActiveOffer/CreditOfferDetail";
import Myaccount from "views/Myaccount/Myaccount";
import Editaccount from "views/Myaccount/Editaccount";
import ChangePassword from "views/Myaccount/Changepassword";
import LenderDashboard from "views/Components/LenderDashboard/LenderDashboard";
import ViewLenderInvoice from "views/Components/LenderDashboard/ViewLenderInvoice";
import DomiciledBanks from "views/DomiciledBanks/DomiciledBanks";
import DomiciledKycDocs from "views/DomiciledBanks/KycDocs";
import DomiciledViewKycDocs from "views/DomiciledBanks/ViewKycDocs";
import DomiciledSignatory from "views/DomiciledBanks/Signatory";
import ViewLender from "views/DomiciledBanks/ViewLender";
import IdleTimerContainer from "components/IdleTimerContainer/IdleTimerContainer";

var hist = createBrowserHistory();

function App() {
  const userId      = localStorage.getItem("userId");
  const userName    = localStorage.getItem("userName");
  return (
    <>
      <Router history={hist}>
        {isMobile ? <MobileAppbar /> : <NavBar />}
        <Toolbar />
        {userId && userName ? ( <IdleTimerContainer /> ) : null}
        <Switch>
          <Route path="/mainterms" component={MainTerms} />
          <Route path="/cms/:id" component={MainAboutUs} />
          <Route path="/mainprivacy" component={MainPrivacy} />
          <Route exact path="/" component={MainHomePage} />
          <Route path="/lender/dashboard" component={LenderDashboard} />
          <Route path="/lender/invoicedetail" component={ViewLenderInvoice} />
          <Route path="/signup" component={SignUp} />
          <Route path="/signin" component={SignIn} />
          <Route path="/authentication" component={Authentication} />
          <Route path="/custom2" component={Custom2} />
          <Route path="/supplierdash" component={supplierDashboard} />
          <Route path="/buyer/dashboard" component={supplierDashboard} />
          <Route path="/company-page" component={CompanyInfo} />
          <Route path="/kyc-page" component={KycDocUpload} />
          <Route path="/documents" component={Documents} />
          <Route path="/kycsuccess" component={KYCDocSuccess} />
          <Route path="/mycompany" component={MyCompany} />
          <Route path="/manageinvoice" component={ManageInvoice} />
          <Route path="/viewsoa" component={ViewSoa} />
          <Route path="/verticalbar" component={VerticalBar} />
          <Route path="/kycnow" component={KYCNow} />
          <Route path="/minaddoice" component={InvoiceUpload} />
          <Route path="/googleauth" component={GoogleAuth} />
          <Route path="/changePassword" component={Change} />
          <Route path="/forgotPassword" component={forgotPassword} />
          <Route path="/resetPassword" component={Reset} />
          <Route path="/otpverify/:email" component={Otpverify} />
          <Route path="/loader" component={Loader} />
          <Route path="/undermaintenance1" component={Under1} />
          <Route path="/aboutinterswitch" component={AboutInterswitch} />
          <Route path="/manageuser" component={ManageUser} />
          <Route path="/addnewuser" component={AddNewUser} />
          <Route path="/edituser/:id" component={EditUser} />
          <Route path="/viewuser/:id" component={ViewUser} />
          <Route path="/maincontactus" component={MainContactUs} />
          <Route path="/buyer" component={Buyer} />
          <Route path="/supplier" component={Buyer} />
          <Route path="/addbuyer" component={AddBuyer} />
          <Route path="/addsupplier" component={AddBuyer} />
          <Route path="/editbuyer/:id" component={EditBuyer} />
          <Route path="/editsupplier/:id" component={EditBuyer} />
          <Route path="/trader-assessment" component={TraderAssessment} />
          <Route path="/credit" component={CreditRequest} />
          <Route path="/credit-offer/:id" component={CreditOffer} />
          <Route
            path="/credit-offer-detail/:credit_request_id/:supplier_credit_lender_id"
            component={CreditOfferDetail}
          />
          <Route path="/add-supplier-credit" component={AddCreditRequest} />
          <Route
            path="/edit-supplier-credit/:id"
            component={EditCreditRequest}
          />
          <Route path="/invoice" component={Invoice} />
          <Route path="/buyerinvoice" component={BuyerInvoice} />
          <Route path="/signatory" component={Signatory} />
          <Route path="/editInvoice" component={EditInvoice} />
          <Route path="/addinvoice" component={AddInvoice} />
          <Route path="/editSignatory/:id" component={editSignatory} />
          <Route path="/viewSignatory/:id" component={viewSignatory} />
          <Route path="/viewInvoiceList" component={ViewInvoiceList} />
          <Route path="/verifyOTP" component={VerifyOTP} />
          <Route path="/viewCompany" component={CompanyProfile} />
          <Route path="/newBuyerInvoice" component={NewBuyerInvoice} />
          <Route path="/view-buyer" component={ViewBuyer} />
          <Route path="/view-supplier" component={ViewBuyer} />
          <Route path="/activeOffer" component={ActiveOffer} />
          <Route path="/viewUsers/:id" component={ViewUsers} />
          <Route path="/creditoffers" component={CreditOffers} />
          <Route path="/creditofferdetail/:id" component={ActiveCreditOfferDetail} />
          <Route path="/myaccount" component={Myaccount} />
          <Route path="/editaccount" component={Editaccount} />
          <Route path="/change-password" component={ChangePassword} />
          <Route
            path="/googleAuthentication"
            component={GoogleAuthentication}
          />
          <Route path="/domiciled-banks" component={DomiciledBanks} />
          <Route path="/kyc-docs" component={DomiciledKycDocs} />
          <Route path="/authorised-signatory" component={DomiciledSignatory} />
          <Route path="/viewkycdocs/:id" component={DomiciledViewKycDocs} />
          <Route path="/viewlender/:id" component={ViewLender} />

          <Route path="*" component={UnderMaintenance} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
