import React, { useState,useEffect } from 'react';
import { Box, Grid, Typography, FormControl, InputLabel, Select, MenuItem, Button, TableContainer, TableRow, TableCell, Table, Paper, TableHead, TableBody } from "@material-ui/core";
import image2 from "assets/img/Financedoodle.svg";
import Custom from "views/Components/Custom";
import axios from 'axios';





function createData(sno, document, authority, uploadedOn, uploadedBy, ip, status) {
    return { sno, document, authority, uploadedOn, uploadedBy, ip, status };
}
const rows = [
    createData(1, "Last 6 months bank statement", "Interswitch Bank", "2021 June 20th", "Manager", "210.123.211.210", "Verified"),
    createData(1, "Last 6 months bank statement", "Interswitch Bank", "2021 June 20th", "Manager", "210.123.211.210", "Verified"),
];



export default function Documents() {


    const [DocType,setDocType] = useState("")
    const [IssueAuth,setIssueAuth] = useState("")
    const [SelectDoc,setSelectDoc] = useState("")

  const  upload = ()=>{
        
        const data = {

            "IssueAuth":IssueAuth,
            "Doctype":DocType,
            "selectdoc":SelectDoc,

        }
        console.log(data)
        
      axios.post("",data,
      {
          header:{  "Content-Type": "text/plain"}
      })
      .then(response =>{
        
        response
         
        })
      .catch(error => {
          console.log( error)
      })
      
    }

    useEffect(() => {
        
        upload();
    }, [])


  

    return (
        <>
            <Box>
                <Box style={{ padding: "10%", height: "100%", backgroundImage: `url(${image2})` }} >
                    <Box style={{ background: "white", borderRadius: "16px", padding: "2%" }}>
                        <Typography style={{ color: "#18425D", fontSize: "24px", fontWeight: "bold" }}>Documents</Typography>
                        <Typography style={{ color: "#0275D8", fontSize: "16px", fontWeight: "600", paddingTop: "2%" }}>Add Documents</Typography>
                        <Grid container spacing={2} style={{ paddingTop: "2%" }}>
                            <Grid item xs={12} sm={12} md={4} xl={4}>
                                <Typography style={{ fontSize: "12px", fontWeight: "600" }}>Document Type*</Typography>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Select from option</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Age"
                                        onChange={e=>setDocType(e.target.value)}
                                        
                                    >
                                        <MenuItem value={10}>Ten</MenuItem>
                                        <MenuItem value={20}>Twenty</MenuItem>
                                        <MenuItem value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} xl={4}>
                                <Typography style={{ fontSize: "12px", fontWeight: "600" }}>Issuing Authority*</Typography>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Select from option</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Age"
                                        onChange={e=>setIssueAuth(e.target.value)}
                                        
                                    >
                                        <MenuItem value={10}>Ten</MenuItem>
                                        <MenuItem value={20}>Twenty</MenuItem>
                                        <MenuItem value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={6} sm={6} md={2} xl={2}>
                                <Button style={{ width: "90%", color: "#0275D8", border: "2px solid #0275D8", marginTop: "10%", textTransform: "initial" }} component="label">Select Document<input type="file"  onChange={e=>setSelectDoc(e.target.value)} hidden /></Button>
                            </Grid>

                            <Grid item xs={6} sm={6} md={2} xl={2}>
                                <Button type="submit" style={{ width: "90%", color: "#FFFFFF", background: "#479FC8", marginTop: "10%", textTransform: "initial" }} onSubmit={upload()} >Upload</Button>
                            </Grid>
                        </Grid>


                        <Typography style={{ color: "#0275D8", fontSize: "16px", fontWeight: "600", paddingTop: "2%" }}>Document List</Typography>


                        <Box >
                            <TableContainer component={Paper}>
                                <Table style={{ borderCollapse: 'separate', borderSpacing: '0px 20px' }} sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow style={{ background: "#F3F5F6", borderRadius: "4x" }}>
                                            <TableCell>S. No. </TableCell>
                                            <TableCell align="center">Document Name</TableCell>
                                            <TableCell align="center">Issuing Authority</TableCell>
                                            <TableCell align="center">Uploaded on</TableCell>
                                            <TableCell align="center">Uploaded By</TableCell>
                                            <TableCell align="center">IP Address</TableCell>
                                            <TableCell align="center">Status</TableCell>
                                            <TableCell align="center">Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody >
                                        {rows.map((row) => (
                                            <TableRow
                                                style={{ background: "#F3F5F6" }}
                                                key={row.name}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {row.sno}
                                                </TableCell>
                                                <TableCell align="center">{row.document}</TableCell>
                                                <TableCell align="center">{row.authority}</TableCell>
                                                <TableCell align="center">{row.uploadedOn}</TableCell>
                                                <TableCell align="center">{row.uploadedBy}</TableCell>
                                                <TableCell align="center">{row.ip}</TableCell>
                                                <TableCell align="center">{row.status}</TableCell>
                                                <TableCell align="center">{<Custom />}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
}




{/* <Box style={{ padding: "5%" }}>
                                <span style={{ fontSize: "12px", fontWeight: "600" }}>Taxation Number*</span>
                                <OutlinedInput
                                    fullWidth
                                    placeholder="e.g. TXN567890"
                                />
                            </Box> */}







