import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
// import MenuIcon from '@material-ui/icons/Menu';
import icon from "../../assets/img/I.svg";
import menu from "../../assets/img/AppbarMenu.svg";
import { Link } from "react-router-dom";
import MobileSidebar from "./sideBar/mobileSidebar";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const MobileAppbarPublic = () => {
  const classes = useStyles();
  const api_url = process.env.REACT_APP_URL;
  const userName    = localStorage.getItem("userName");
  const trader_type = localStorage.getItem("trader_type");
  const [login, setLogin] = useState(
    localStorage.getItem("userName") !== undefined &&
    localStorage.getItem("userName") !== null
    ? true
    : false
  );
  const [open, setOpen] = useState(false);
  const [data, setData] = useState();
  const activity = { open, setOpen };
  const getData = () => {
    axios
      .get(
        api_url + "/api/v1/header",

        {
          headers: {
            "Content-Type": "text/plain",
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          setData(res.data.header_list);
        }
      });
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <MobileSidebar activity={activity} info={data} type="public" />
      <AppBar
        position="fixed"
        color="white"
        style={{ zIndex: "1400", boxShadow: "none" }}
      >
        <Toolbar style={{ paddingRight: "5%" }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            className={classes.menuButton}
            onClick={() => setOpen(true)}
          >
            <img src={menu} />
          </IconButton>
          <Box variant="h6" align="center" className={classes.title}>
            <Link to={"/"}>
              <img src={icon} />
            </Link>
          </Box>
          {login && login === true ? (
            <Link to={trader_type === "supplier" ? "/supplierdash" : "/buyer/dashboard"}>
              <Button
                style={{
                  color: "#479FC8",
                  border: "1px solid #479FC8",
                  fontFamily: "Averta-Regular",
                }}
              >
                Dashboard
              </Button>
            </Link>
          ) : (
            <Link to={"/signup"}>
              <Button
                style={{
                  color: "#479FC8",
                  border: "1px solid #479FC8",
                  fontFamily: "Averta-Regular",
                }}
              >
                Sign Up
              </Button>
            </Link>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
};

export default MobileAppbarPublic;
