import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import IdCard from "../../../assets/img/idCard.svg";
import EditIcon from "../../../assets/img/editIcon.svg";
import DeleteIcon from "../../../assets/img/deleteIcon.svg";
import { isMobile } from "react-device-detect";
var axios = require("axios");
import { getAccessToken } from "../../../utilities/tokenUtil";
import {
  Box,
  OutlinedInput,
  Snackbar,
  SnackbarContent,
  Typography,
} from "@material-ui/core";

const KycUploadDocument = (props) => {
  const data = props.info;
  const pagename = props.pagename;
  const api_url = process.env.REACT_APP_URL;
  const [message, setMessage] = useState({});
  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const downloadDocument = (event, document_id) => {
    event.preventDefault();
    const access_token = getAccessToken();

    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: access_token,
      document_id: parseInt(document_id),
    };

    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/kyc/document/download`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        if (response.data.success === true) {
          const api_url = process.env.REACT_APP_URL;
          let url = `${api_url + response.data.document_url}?${Date.now()}`;
          window.open(url, "_blank");
        } else {
          setLoading(false);
          setOpen(true);
          setMessage({ type: "error", content: "Something went wrong." });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const grtListOfDocuments = () => {
    const access_token = getAccessToken();

    const data = {
      access_token: access_token,
      user_id: parseInt(localStorage.getItem("userId")),
      kyc_type: "bank_kyc",
    };

    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/kyc/get/document/list`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        if (response.data.kyc_type === "bank") {
          localStorage.setItem("kyc_status", response.data.kyc_status);
          localStorage.setItem("kyc_type", "Bank");
        }
        if (response.data.kyc_status === "Approved") {
          localStorage.setItem("kyc_status", "Approved");
          localStorage.setItem("kyc_type", "Bank");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleUpload = (e, doc) => {
    const access_token = getAccessToken();
    let data = new FormData();
    data.append("user_id", parseInt(localStorage.getItem("userId")));
    data.append("access_token", access_token);
    data.append("document_id", doc.document_id);
    data.append("document_type_id", doc.document_type_id);
    data.append("document_name", doc.document_name);
    data.append("document_data", e.target.files[0]);
    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/kyc/document/upload`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        grtListOfDocuments();

        window.location.reload(false);
        setOpen(true);
        setMessage({
          type: "success",
          content: response.data.message,
        });
      })
      .catch((error) => {
        console.log("ERROR Upload", error);
      });
  };
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}>
        <SnackbarContent
          className={
            message.type === "error"
              ? "snack-error"
              : message.type === "success"
              ? "snack-success"
              : "snack-teal"
          }
          message={message.content}
        />
      </Snackbar>
      {data && (
        <>
          <div className="Averta-font mt-5 view-uploaded-documents">
            {data && data.length > 0 && (
              <Row>
                <Col className="cp-heading2">Uploaded Documents</Col>
              </Row>
            )}
            <Row>
              {data &&
                data.length > 0 &&
                data.map((info, kyc_index) => (
                  <div key={kyc_index}>
                    <Row className="mt-5">
                      <Col className="cp-sub8">{info.doc_name}</Col>
                    </Row>
                    {info && (
                      <Row className=" my-3">
                        {info.doc_list.map((doc_item, kyc_key) => (
                          <>
                            {console.log("doc_item", doc_item)}
                            {doc_item.document_type_id === info.doc_id &&
                              doc_item.state !== "Rejected" && (
                                <Col
                                  md={3}
                                  key={kyc_key}
                                  className={isMobile ? "mb-3" : ""}>
                                  <>
                                    {doc_item.image_extension ===
                                    "application/pdf" ? (
                                      <embed
                                        src={`${
                                          api_url + doc_item.document
                                        }?${Date.now()}`}
                                        onClick={(e) => {
                                          pagename === "kycpage" &&
                                            downloadDocument(
                                              e,
                                              doc_item.document_id
                                            );
                                        }}
                                        type={doc_item.image_extension}
                                      />
                                    ) : (
                                      <img
                                        src={`${
                                          api_url + doc_item.document
                                        }?${Date.now()}`}
                                        onClick={(e) => {
                                          pagename === "kycpage" &&
                                            downloadDocument(
                                              e,
                                              doc_item.document_id
                                            );
                                        }}
                                      />
                                    )}
                                  </>
                                </Col>
                              )}
                            {doc_item.state === "Rejected" && (
                              <>
                                <Col md={3}>
                                  <label
                                    style={{
                                      color: "#0275D8",
                                      border: "2px solid #0275D8",
                                      borderRadius: "8px",
                                      textTransform: "initial",
                                      padding: isMobile ? "5%" : "15px",
                                      width: isMobile ? "90%" : "70%",
                                      marginLeft: "10%",
                                    }}>
                                    Upload Document
                                    <input
                                      label="click"
                                      style={{ display: "none" }}
                                      type="file"
                                      onChange={(e) => {
                                        handleUpload(e, doc_item);
                                      }}
                                    />
                                  </label>
                                  <Box
                                    style={{
                                      width: isMobile ? "90%" : "70%",
                                      marginLeft: "10%",
                                      marginTop: "5%",
                                    }}>
                                    <Typography
                                      style={{
                                        fontSize: isMobile ? "55%" : "75%",
                                      }}>
                                      In PNG, PDF JPG, GIF, PDF and SVG format
                                      size should be less then 2 MB
                                    </Typography>
                                  </Box>
                                </Col>
                                <Col md={3} className="text-danger">
                                  {doc_item && doc_item?.rejection_reason}
                                </Col>
                              </>
                            )}
                          </>
                        ))}
                      </Row>
                    )}
                  </div>
                ))}
            </Row>
          </div>
        </>
      )}
    </>
  );
};
export default KycUploadDocument;
