import React from "react";
import { Row, Col } from "react-bootstrap";
import IdCard from "../../../assets/img/idCard.svg";
import EditIcon from "../../../assets/img/editIcon.svg";
import DeleteIcon from "../../../assets/img/deleteIcon.svg";
import { isMobile } from "react-device-detect";
import { getAccessToken } from "../../../utilities/tokenUtil";

var axios = require("axios");

const UploadDocument = (props) => {
  const data      = props.info;
  const pagename = props.pagename;
  const api_url = process.env.REACT_APP_URL;  

  const downloadDocument = (event, document_id) => {
      event.preventDefault();        
      const access_token = getAccessToken();
      
      const data = {
          user_id: parseInt(localStorage.getItem("userId")),
          access_token: access_token,
          document_id: parseInt(document_id)
      };

      axios
      .post(`${process.env.REACT_APP_URL}/api/v1/kyc/document/download`, data, {
          headers: {
              "Content-Type": "text/plain",
          },
      })
      .then((response) => {
        
          if(response.data.success === true){
              const api_url = process.env.REACT_APP_URL
              let url = `${api_url+response.data.document_url}?${Date.now()}`;
              window.open( url, '_blank' );
          }else{
              setLoading(false);
              setOpen(true);
              setMessage({ type: "error", content: "Something went wrong." });
          }
          
      })
      .catch((error) => {
          console.log(error);
      });
  };

  return (
    <>
      {data && (
        <>
          <div className="Averta-font mt-5">
            {data &&
                data.length > 0 &&
              <Row>
                <Col className="cp-heading2">Uploaded Documents</Col>
              </Row>
            }
            <Row>
              {data &&
                data.length > 0 &&
                data.map((info, index) => (
                  <>

                    <Row key={index} className="mt-5">
                      <Col className="cp-sub8">{info.document_name}</Col>
                    </Row>
                    <Row className=" my-3">
                      <Col md={3} className={isMobile ? "mb-3" : ""}>
                        {info.document ? (
                          <>
                          {info.image_extension === "application/pdf" ? (
                              <embed src={`${api_url+info.document}?${Date.now()}`} onClick={(e) => {pagename === "kycpage" && downloadDocument(e, info.document_id)} } type={info.image_extension}/>                                                                  
                            ) : (
                              <img src={`${api_url+info.document}?${Date.now()}`} onClick={(e) => {pagename === "kycpage" && downloadDocument(e, info.document_id)} }/>
                            )}
                            </>
                        ) : (
                        <>
                          {info.image_extension === "application/pdf" ? (
                              <embed src={`${api_url+info.document_url}?${Date.now()}`} onClick={(e) => {pagename === "kycpage" && downloadDocument(e, info.document_id)} } type={info.image_extension}/>                                                                  
                            ) : (
                              <img src={`${api_url+info.document_url}?${Date.now()}`} onClick={(e) => {pagename === "kycpage" && downloadDocument(e, info.document_id)} }/>
                            )}
                          </>
                        )}
                      </Col>
                    </Row>
                  </>
                ))}
            </Row>
          </div>
        </>
      )}
    </>
  );
};
export default UploadDocument;
