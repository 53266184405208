import React from "react";
import { Link } from "react-router-dom";
import styles from "assets/jss/material-kit-react/views/components.js";
import Grid from "@material-ui/core/Grid";
import { Button, Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import image from "../../assets/img/pass.png";
import HomePage5 from "views/HomePage/HomePage5";
import { isMobile } from "react-device-detect";
import SignupFooter from "views/Signup/footer";
const useStyles = makeStyles(styles);

export default function UnderMaintenance() {
  const classes = useStyles();
  return (
    <>
      <Box style={{ background: "white" }}>
        <Box
          className={classes.imgfluid}
          style={{
            padding: "2%",
            "mix-blend-mode": "multiply",
            background: "#FFF",
          }}>
          <Box
            style={{
              margin: "5%",
              background: "#FFFFFF",
              borderRadius: "16px",
            }}>
            {isMobile ? (
              <img
                style={{ height: "90%", width: "90%", marginLeft: "5%" }}
                src={image}
              />
            ) : (
              ""
            )}

            <Grid container spacing={10}>
              <Grid item xs={12} sm={12} md={6} xl={6}>
                <Box
                  style={{
                    "margin-top": "8%",
                    "margin-left": "10%",
                    "margin-bottom": "40px",
                  }}>
                  <Grid
                    style={{
                      fontFamily: "Averta-SemiBold",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "56px",
                      lineHeight: "84px",
                      color: "#18425D",
                    }}>
                    404 Error
                  </Grid>
                  <Grid
                    style={{
                      fontFamily: "Averta-Regular",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "24px",
                      lineHeight: "36px",
                      color: "#479FC8",
                    }}>
                    Page Not Found
                  </Grid>

                  <Grid
                    style={{
                      height: "16px",
                      borderRadius: "16px",
                      marginTop: "3%",
                      marginLeft: "1%",
                    }}>
                    <Typography
                      style={{
                        color: "#848F9F",
                        fontSize: isMobile ? "12px" : "20px",
                        fontWeight: "400",
                        style: "normal",
                        marginBottom: "10%",
                        height: "36px",
                        fontFamily: "Averta-Regular",
                      }}>
                      Lorem ipsum dolor sit amet, consectetur
                      <br /> adipiscing elit.Sed feugiat, sapien in rhoncus
                      <br /> suscipit, metus mi accumsan dui, vel vulputate{" "}
                      <br />
                      metus mauris vitae tellus.
                    </Typography>
                  </Grid>

                  <Link to={"/"}>
                    <Button
                      style={{
                        background: "#479FC8",
                        padding: "5px",
                        marginTop: isMobile ? " 7%" : "8%",
                        width: "100%",
                        height: "55px",
                        top: "100px",
                        color: "#FFFFFF",
                        fontFamily: "Averta-Regular",
                        marginBottom: isMobile ? "20%" : " ",
                        marginLeft: isMobile ? "-5%" : " ",
                      }}>
                      Back to Home Page
                    </Button>
                  </Link>
                </Box>
              </Grid>

              {isMobile ? (
                " "
              ) : (
                <Grid item xs={12} sm={12} md={6} xl={6}>
                  <Box style={{ padding: "10%" }}>
                    <img style={{ height: "90%", width: "90%" }} src={image} />
                  </Box>
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
        <HomePage5 />
        {isMobile ? " " : <SignupFooter />}
      </Box>
    </>
  );
}
