import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Box } from '@material-ui/core';
import HomePage5 from 'views/HomePage/HomePage5';
import HomePage from 'views/HomePage/HomePage';
import BottomComponent from 'views/Components/HomePageComponents/BottomComponent';
import SignupFooter from 'views/Signup/footer';
import { isMobile } from 'react-device-detect';

export default function Privacy() {

    return (
        <>


            <Box style={{ background: "#FFFFFF" }}>



                <HomePage />
                {isMobile ? " " : <Typography style={{ fontSize: "20px", fontWeight: "400", "height": "50px", "background": "#FDED94", textAlign: "center", padding: "2% 0% 4% 0%", marginBottom: "3%" }}> <span style={{ fontSize: "24px", fontWeight: "700" }}>Announcements</span> - Lorem Ipsum is simply dummy text of the printing and typesetting industry. </Typography>}


                <Box style={{ padding: isMobile ? "5%" : "6%" }}>


                    <Typography style={{ height: "20px", margin: "12px", color: "#18425D", fontSize: isMobile ? "20px" : "24px", fontWeight: "700", fontFamily: "Averta-SemiBold" }}>Interswitch Privacy Policy
                    </Typography>
                    <Typography style={{ margin: "12px", height: "30px", color: "#479FC8", fontSize: "16px", fontWeight: "600", fontFamily: "Averta-Regular" }}>Effective Date: October 16, 2019</Typography>
                    <Grid>
                        <br /><Typography style={{ margin: "12px", height: "9px", color: "#353F50", fontSize: "14px", fontWeight: "400", fontFamily: "Averta-Regular" }}>User Privacy rights</Typography>
                        <Typography style={{ margin: "12px", color: "#353F50", fontSize: "12px", fontWeight: "400", fontFamily: "Averta-Regular" }}>This Privacy Policy describes a users privacy rights regarding our collection, use, storage, sharing and protection of personal information.

                            <br /><br />It applies to the Interswitch website and all related sites, applications, services and tools regardless of how they are accessed or used.

                            <br /><br />Click on any of the links below to go straight to one of the following sections.
                        </Typography>

                        <br /><Typography style={{ margin: "12px", height: "1px", color: "#353F50", fontSize: "14px", fontWeight: "400", fontFamily: "Averta-Regular" }}>User Privacy rights</Typography>
                        <br /><Typography style={{ margin: "-16px", color: "#353F50", fontSize: "12px", fontWeight: "400", fontFamily: "Averta-Regular" }}>
                            <ol>
                                <li >About Interswitch</li>
                                <li>Introduction</li>
                                <li>Scope and Consent</li>
                                <li>How we collect personal information</li>
                                <li>How we use collected information</li>
                                <li>Protection and storage of personal information</li>
                                <li>Marketing</li>
                                <li>Sharing personal information with other interswitch users</li>
                                <li>Sharing personal information with other third parties</li>
                                <li>Cross-border transfers of personal information</li>
                                <li>Customer data protection rights</li>
                                <li>The use of cookies and similar technologies</li>
                                <li>Privacy policies of other websites</li>
                                <li>Customer consent</li>
                                <li>Available remedies</li>
                                <li>Changes to our privacy policy</li>
                                <li>How to contact us</li>

                            </ol>
                        </Typography>
                        <br /><br /> <Typography style={{ margin: "12px", color: "#353F50", height: "9px", fontSize: "14px", fontWeight: "400", fontFamily: "Averta-Regular" }}>About Interswitch</Typography>
                        <Typography style={{ margin: "12px", color: "#353F50", fontSize: "12px", fontWeight: "400", fontFamily: "Averta-Regular" }}>Interswitch is an Africa-focused integrated digital payments and commerce company that facilitates the electronic circulation of money as well as the exchange of value between individuals and organisations on a timely and consistent basis.
                            <br /><br />
                            This privacy policy will explain how our organization uses the personal data we collect from our customers and related partners when they use our website or subscribe to any of our products and services.
                        </Typography>

                        <br /><Typography style={{ margin: "12px", color: "#353F50", fontSize: "14px", height: "9px", fontWeight: "400", fontFamily: "Averta-Regular" }}>Introduction</Typography>
                        <Typography style={{ margin: "12px", color: "#353F50", fontSize: "12px", fontWeight: "400", fontFamily: "Averta-Regular" }}>Interswitch conducts business in a responsible and sustainable manner and ensures customer information is securely collected, processed and stored based on business requirements. In furtherance to this and compliance with industry regulations, Interswitch has provided appropriate documentation, which includes a privacy policy. Interswitch therefore provides notice about this policy and applicable procedures. It identifies the purposes for which personal information is collected, used, processed, disclosed, retained and disposed.
                            <br /><br />
                            Throughout this Privacy Policy, we use the term personal information or data to describe information that can be associated with a specific person and can be used to identify that person. We do not consider personal information to include information that has been made anonymous such that it does not identify a specific user.
                        </Typography>
                        <br /><Typography style={{ margin: "12px", color: "#353F50", fontSize: "14px", height: "9px", fontWeight: "400", fontFamily: "Averta-Regular" }}>Scope and Consent</Typography>
                        <Typography style={{ margin: "12px", color: "#353F50", fontSize: "12px", fontWeight: "400", fontFamily: "Averta-Regular" }}>As a Customer, you accept this Privacy Policy when you sign up for, access, or use our products, services, content, features, technologies or functions offered on our website and all related sites, applications, and services (collectively referred to as “Interswitch Services”).
                            <br /><br />
                            This Privacy Policy is intended to govern the use of Interswitch Services by users (including, without limitation those who use these Interswitch Services in the daily course of their trade, practice or business) unless otherwise agreed through contract. User reserve the right to exercise their data protection right as listed under the Customer Data Protection Rights.</Typography>
                        <br /><Typography style={{ margin: "12px", color: "#353F50", fontSize: "14px", height: "9px", fontWeight: "400", fontFamily: "Averta-Regular" }}>Collection of Personal Information</Typography>
                        <Typography style={{ margin: "12px", color: "#353F50", fontSize: "12px", fontWeight: "400", fontFamily: "Averta-Regular" }}>We collect Personally Identifiable Information (PII), otherwise known as Personal Information or Personal Data. They include: Name, email address, phone number, contact address, limited financial information, location data, device data, etc.</Typography>
                        <br />
                        <Typography style={{ margin: "12px", color: "#353F50", fontSize: "14px", height: "9px", fontWeight: "400", fontFamily: "Averta-Regular" }}>How we collect Persnol information</Typography>
                        <Typography style={{ margin: "12px", color: "#353F50", fontSize: "12px", fontWeight: "400", fontFamily: "Averta-Regular" }}>Customers data is collected electronically when they visit our website and register to use any of our services. This is collected electronically with exchanges between your system (Computer, Server, Mobile Device) or service provider’s system and our system.
                            <br /><br />We collect Customers’ data manually when they complete our product and services registration forms in registering to use any of our services. Similar data are also collected when customers or visitors’ visit our physical locations for enquiries or business relationship. We collect information from or about customers from other sources, such as through your contact with us, including our Customer Support interfaces – email, portal, phone calls, social media and other communication channels; Customer support teams, Customer response to surveys, Training programmes, Corporate Social Responsibility events, Promotional events, and interactions with members of the Interswitch Group or other companies (subject to their privacy policies and applicable law).
                            <br /><br />We may also obtain information about you from third parties such as credit bureaus and identity verification services.
                        </Typography>
                        <br /><Typography style={{ margin: "12px", color: "#353F50", fontSize: "14px", height: "9px", fontWeight: "400", fontFamily: "Averta-Regular" }}>How we use collected information</Typography>
                        <Typography style={{ margin: "12px", color: "#353F50", fontSize: "12px", fontWeight: "400", fontFamily: "Averta-Regular" }}>We collect personal information to provide users of our services with a secure, smooth, efficient, and customized experience. Furthermore, the information collected may also be used to: provide Interswitch Services and customer support; process transactions and send notices about your transactions; verify your identity, including during account creation and password reset processes; resolve disputes, collect fees, and troubleshoot problems; manage risk, or to detect, prevent, and/or remediate fraud or other potentially prohibited or illegal activities; detect, prevent or remediate violations of policies or applicable user agreements.
                            <br /><br />We also collect personal information to improve Services by customizing your user experience; measure the performance of the Interswitch Services and improve their content and layout; manage and protect our information technology infrastructure; provide targeted marketing and advertising, provide service update notices, and deliver promotional offers based on your communication preferences; contact you at any telephone number, by placing a voice call or through text (SMS) or email messaging; perform creditworthiness and solvency checks, and compare information for accuracy and verify it with third parties.
                            <br /><br />
                            Additionally, we may contact you via electronic means to notify you regarding your account, to troubleshoot problems with your account, to resolve a dispute, to collect fees or monies owed, to poll your opinions through surveys or questionnaires, or as otherwise necessary to service your account. Furthermore, we may contact you to offer coupons, discounts and promotions, and inform you about Interswitch Services or its group services.

                            <br /><br />Finally, we may contact you as necessary to enforce our policies, applicable law, or any agreement we may have with you. When contacting you via phone, to reach you as efficiently as possible we may use, and you consent to receive, autodialled or pre-recorded calls and text messages. Where applicable and permitted by law, you may decline to receive certain communications.
                        </Typography>
                        <br /><Typography style={{ margin: "12px", color: "#353F50", fontSize: "14px", height: "9px", fontWeight: "400", fontFamily: "Averta-Regular" }}>Protection and storage of personal information</Typography>
                        <Typography style={{ margin: "12px", color: "#353F50", fontSize: "12px", fontWeight: "400", fontFamily: "Averta-Regular" }}>We store and process your personal information on our computers in Lagos, Nigeria and anywhere else where our facilities are located. We protect your information using physical, technical, and administrative security measures to reduce the risks of loss, misuse, unauthorized access, disclosure and alteration.

                            <br /><br />
                            Some of the safeguards we use are firewalls and data encryption, physical access controls to our data centres, and information access authorization controls. We have also taken additional measure by ensuring our system complies with industry information security standards.


                        </Typography>

                        <br /><Typography style={{ margin: "12px", color: "#353F50", fontSize: "14px", height: "9px", fontWeight: "400", fontFamily: "Averta-Regular" }}>Marketing</Typography>
                        <Typography style={{ margin: "12px", color: "#353F50", fontSize: "12px", fontWeight: "400", fontFamily: "Averta-Regular" }}>
                            We do not sell or rent your personal information to third parties for their marketing purposes without your explicit consent. We may combine customer’s information with information collected from other companies and use it to improve and personalize Interswitch Services, content, and advertising. We have also included opportunity for customers that had initially subscribed to receiving notification or information about their activities in relation to the use of Interswitch’s service to unsubscribed or request to be removed from applicable databases.
                        </Typography>

                        <br /><Typography style={{
                            margin: "12px", color: "#353F50", fontSize: "14px", height: "9px", fontWeight: "400", marginBottom: "10%",
                            fontFamily: "Averta-Regular"
                        }}>Sharing personal information with other interswitch users</Typography>
                        <Typography style={{ margin: "12px", color: "#353F50", fontSize: "12px", fontWeight: "400" }}>
                            When transacting with others, we may provide those parties with information to complete the transaction, such as your name, account ID, contact details, shipping and billing address, or other information needed to promote the reliability and security of the transaction. If a transaction is held, fails, or is later invalidated, we may also provide details of the unsuccessful transaction.

                            <br /><br />
                            To facilitate dispute resolution, we may provide a buyer with the seller’s address so that goods can be returned to the seller. The receiving party is not allowed to use this information for unrelated purposes, such as to directly market to you, unless you have agreed to it. Contacting users with unwanted or threatening messages is not authorised by Interswitch.
                            <br /><br />
                            We may provide or display customer information to a third-party while consummating transactions to validate that transactions are being exchanged with valid receivers.
                            <br /><br />
                            We work with third parties, including merchants, to enable them to accept or send payments from or to Customers using Interswitch Services. In doing so, a third party may share information about customers with us, such as email address or mobile phone number, to inform such customer that a payment has been sent. We use this information to confirm that users are Interswitch customer and that Interswitch as a form of payment can be enabled, or to send customer notification of payment status. Also, we may oblige request to validate that a customer transacts business with Interswitch.
                            <br /><br />
                            Customer’s card information may be available for their subsequent reuse if they chose for it to be remembered at previous attempts. Do note that merchants, sellers, and users involved in transactions may have their own privacy policies, and Interswitch does not allow the other transacting party to use this information for anything other than providing Interswitch Services, Interswitch is not responsible for their actions, including their information protection practices.
                            <br /><br />
                            Interswitch will not disclose your credit/debit card number or bank account number to anyone or with the third parties that offer or use Interswitch Services, except with customers’ express permission or if we are required to do so to comply with credit/debit card rules, a subpoena, law enforcement or other legal processes.

                        </Typography>

                        <br /><Typography style={{ margin: "12px", color: "#353F50", fontSize: "14px", height: "9px", fontWeight: "400", fontFamily: "Averta-Regular" }}>Marketing</Typography>
                        <Typography style={{ margin: "12px", color: "#353F50", fontSize: "12px", fontWeight: "400", fontFamily: "Averta-Regular" }}>
                            We do not sell or rent your personal information to third parties for their marketing purposes without your explicit consent. We may combine your information with information we collect from other companies and use it to improve and personalize Interswitch Services, content, and advertising.</Typography>
                        <br /><Typography style={{ margin: "12px", marginBottom: "10%", color: "#353F50", fontSize: "14px", height: "9px", fontWeight: "400", fontFamily: "Averta-Regular" }}>
                            Sharing personal information with other third parties</Typography>
                        <Typography style={{ margin: "12px", marginBottom: "5%", color: "#353F50", fontSize: "12px", fontWeight: "400", fontFamily: "Averta-Regular" }}>We may share your personal information with:
                            <ul>
                                <br /><li>
                                    Members of the Interswitch group to provide joint content, products, and services (such as registration, transactions and customer support), to help detect and prevent potentially illegal acts and violations of our policies, and to guide decisions about their products, services, and communications. This information will only be used for marketing communications only, if customers have requested for the services.
                                </li>
                                <br /><li>Financial institutions that we partner with to jointly create and offer a product may only use this information to market Interswitch related products, unless customer has given consent for other uses.
                                </li>
                                <br /><li>Credit bureaus and collection agencies to report account information, as permitted by law.
                                </li>
                                <br /><li>Banking partners as required by credit/debit card association rules for inclusion on their list of terminated merchants.
                                </li>
                                <br /><li>Companies that we plan to merge with or are acquired by. (Should such a combination occur, we will require that the new combined entity follow this Privacy Policy with respect to customer personal information. Customer will receive prior notice if personal information would be used contrary to this policy).
                                </li>
                                <br /><li>Law enforcement, government officials, or other third parties pursuant to a subpoena, court order, or other legal process or requirement applicable to Interswitch or one of its affiliates; when we need to do so to comply with law or credit/debit card rules; or when we believe, in our sole discretion, that the disclosure of personal information is necessary to prevent physical harm or financial loss, to report suspected illegal activity or to investigate violations of our User Agreement.
                                </li>
                            </ul>
                        </Typography>
                        <br /><Typography style={{ margin: "12px", color: "#353F50", fontSize: "14px", height: "9px", fontWeight: "400", fontFamily: "Averta-Regular", marginBottom: "10%", }}>Other unaffiliated third parties, for the following purposes:</Typography>
                        <Typography style={{ margin: "12px", marginBottom: "5%", color: "#353F50", fontSize: "12px", fontWeight: "400", fontFamily: "Averta-Regular" }}>We may share your personal information with:
                            <ul>
                                <br /><li>Fraud Prevention and Risk Management: to help prevent fraud or assess and manage risk.</li>
                                <br /><li>Customer Service: for customer service purposes, including to help service your accounts or resolve disputes.
                                </li>
                                <br /><li>Shipping in connection with shipping and related services for purchases made using Interswitch Services.
                                </li>
                                <br /><li>
                                    Legal Compliance to help them comply with anti-money laundering, counter-terrorist financing verification requirements and the Central Bank of Nigeria regulations on Know Your Customer (KYC).

                                </li>
                                <br /><li>

                                    Service Providers to enable service providers under contract with us to support our business operations, such as fraud prevention, bill collection, marketing, customer service, address verification and technology services. Our contracts dictate that these service providers only use customer information in connection with the services they perform for us and not for their own benefit.
                                </li>
                            </ul>
                            Other third parties with your consent or direction to do so.
                            <ul>
                                <br /><li>Please note that these third parties may be in other countries where the laws on processing personal information may be less stringent than in our country of primary jurisprudence.
                                </li>
                                <br /><li>If customers open any of our products and services or related wallet account directly on a third-party website or via a third-party application, any information that are entered on such systems (and not directly on an Interswitch website) will be shared with the owner of the third-party website or application. These sites are governed by their own privacy policies and customers are encouraged to review their privacy policies before providing them with personal information. Interswitch is not responsible for the content or information practices of such third parties.
                                </li>
                            </ul>

                        </Typography>
                        <br /><Typography style={{ margin: "12px", height: "9px", color: "#353F50", fontSize: "14px", fontWeight: "400", fontFamily: "Averta-Regular" }}>Customer data protection rights</Typography>
                        <Typography style={{ margin: "12px", marginBottom: "5%", color: "#353F50", fontSize: "12px", fontWeight: "400", fontFamily: "Averta-Regular" }}>Our customers have data protection rights and are entitled to the following:
                            <ul>
                                <br /><li>
                                    The right to access – Customer have the right to request for copies of their personal data. Interswitch may charge a fee for this service if it will require a substantial amount of resources to accomplish.

                                </li>
                                <br /><li>The right to rectification – Customers have the right to request that Interswitch corrects any information they believe and have proven have been captured inaccurately. Customers also have the right to request Interswitch to complete the information they believe is incomplete.
                                </li>
                                <br /><li>The right to erasure – Customers have the right to request that Interswitch erases their personal data, under certain conditions. These conditions are not limited to regulatory requirements, law enforcement agencies, or where such action may cause disruption to our system.

                                </li>
                                <br /><li>
                                    The right to restrict processing – Customers have the right to request that Interswitch restricts the processing of their personal data, under certain conditions. (as above)
                                </li>
                                <br /><li>The right to object to processing – Customers have the right to object to Our Company’s processing of your personal data, under certain conditions. (as above)
                                </li>
                                <br /><li>
                                    The right to data portability – Customers have the right to request that Interswitch transfer the data that we have collected to another organization, or directly to them, under certain conditions. (as above)
                                </li>
                                <br /><li>Customer request based on any of the rights above shall be handled between 2 to 4 working weeks. Customer may contact us by email or writing to details in Section 17.
                                </li>
                            </ul>
                        </Typography>
                        <br /><Typography style={{ margin: "12px", color: "#353F50", height: "9px", fontSize: "14px", fontWeight: "400", fontFamily: "Averta-Regular" }}>The use of cookies and similar technologies</Typography>
                        <Typography style={{ margin: "12px", color: "#353F50", fontSize: "12px", fontWeight: "400", fontFamily: "Averta-Regular" }}>When Customers access our websites or use Interswitch Services, we may place small data files on your computer or other devices. These data files may be cookies, pixel tags, Flash cookies, or other local storage provided by your browser or associated applications (collectively referred to as Cookies). These technologies are used to recognize users as customers; customize Interswitch Services, content, and advertising; measure promotional effectiveness; help ensure that account security is not compromised; mitigate risk and prevent fraud; and to promote trust and safety across Interswitch Services and related sites. Users can freely decline our Cookies if the web browser or browser add-on permits, unless our Cookies are required to prevent fraud or ensure the security of websites we control. However, declining our Cookies may interfere with users use of our websites and Interswitch Services.
                        </Typography>
                        <br /><Typography style={{ margin: "12px", color: "#353F50", height: "9px", fontSize: "14px", fontWeight: "400", fontFamily: "Averta-Regular" }}>Privacy policies of other websites</Typography>
                        <Typography style={{ margin: "12px", color: "#353F50", fontSize: "12px", fontWeight: "400", fontFamily: "Averta-Regular" }}>This privacy policy applies to only Interswitch Group and not any other brands, though mentioned on our Websites. Neither does it apply to our Merchants, Vendors, or other related partners.
                        </Typography>
                        <br /><Typography style={{ margin: "12px", color: "#353F50", fontSize: "14px", height: "9px", fontWeight: "400", fontFamily: "Averta-Regular" }}>Customer consent</Typography>
                        <Typography style={{ margin: "12px", color: "#353F50", fontSize: "12px", fontWeight: "400", fontFamily: "Averta-Regular" }}>Please refer to end of this notice for providing your consent. Your consent to personal data collection and processing may be revoked by notifying us via our contact page. For users below the age of 18, the consent should be provided by the holder of parental responsibility of the child. Please note, in case you choose to not provide us with the consent or withdraw the consent at any given point of time, we shall not be able to provision the services as detailed in section 2.2 of this notice.
                        </Typography>
                        <br /><Typography style={{ margin: "12px", color: "#353F50", fontSize: "14px", height: "9px", fontWeight: "400", fontFamily: "Averta-Regular" }}>Available remedies</Typography>
                        <Typography style={{ margin: "12px", color: "#353F50", fontSize: "12px", fontWeight: "400", fontFamily: "Averta-Regular" }}>In the event of a violation of this Policy by the Company, we shall immediately upon becoming aware of such violation, assess the extent of the violation and take specific actions such as correction, modification, or transfer of the data, subject to the lawful demands of the data subject. The specific actions of correction, modification and transfer shall be effected within 30 days, subject to external factors not within the reasonable control of the Company.
                        </Typography>
                        <br /><Typography style={{ margin: "12px", color: "#353F50", fontSize: "14px", height: "9px", fontWeight: "400", fontFamily: "Averta-Regular" }}>Changes to our privacy policy</Typography>
                        <Typography style={{ margin: "12px", color: "#353F50", fontSize: "12px", fontWeight: "400", fontFamily: "Averta-Regular" }}>We review our privacy policy periodically and when there is any substantial change to business or regulatory requirement. At the minimum, we shall review this annually and communicate via our communication channels such as Newsletter, on Website, Social Media Accounts.

                        </Typography>
                        <br /><Typography style={{ margin: "12px", color: "#353F50", height: "9px", fontSize: "14px", fontWeight: "400", fontFamily: "Averta-Regular" }}>How to Contact us</Typography>
                        <Typography style={{ margin: "12px", color: "#353F50", fontSize: "12px", fontWeight: "400", fontFamily: "Averta-Regular" }}>Customers who have concerns, questions about privacy policy or would like to exercise their protection rights can contact us through the following channels:
                            <br />Email us at: dpo@interswitchgroup.com
                            <br />Call us: 016283888
                            <br />Customer Contact Centre 0700-9065000
                            <br />Or write to us at: Interswitch Limited, 1648C Oko-Awo Street, Victoria Island Lagos.
                        </Typography>
                    </Grid>


                </Box>
            </Box>
            <BottomComponent />
            <HomePage5 />
            {isMobile ? " " : <SignupFooter />}

        </>

    );
}