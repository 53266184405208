import React, { useState } from "react";
import { Modal, Button, Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { getAccessToken } from "../../utilities/tokenUtil";
import Tooltip from "@mui/material/Tooltip";
var axios = require("axios");
import { makeStyles } from "@material-ui/core/styles";
import CancelIcon from "@material-ui/icons/HighlightOffOutlined";

function getModalStyle() {
  return {
    top: `50%`,
    left: `0`,
    right: `0`,
    margin: `0 auto`,
    transform: `translateY(-75%)`,
    borderRadius: "10px",
    padding: "50px 50px 20px 50px",
    width: "500px",
  };
}
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    width: 450,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function WebRedirectPaymentMethod(props) {
  const modelClasses = useStyles();
  const { classes } = props;
  const [modalStyle] = React.useState(getModalStyle);

  const currency_symbol = localStorage.getItem("currency_symbol");
  //const [openModel, setOpenModel] = useState(false);

  const modelPaymentClose = () => {
    setOpenPaymentModel(false);
    localStorage.removeItem("invoice_id");
  };

  //generate a random transaction ref
  const randomReference = () => {
    var length = 4;
    var chars =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  };

  return (
    <>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={props.openPaymentModel}>
        <div style={modalStyle} className={modelClasses.paper}>
          <CancelIcon
            style={{
              color: "#848f9f",
              position: "absolute",
              right: "15",
              top: "15",
              cursor: "pointer",
            }}
            onClick={props.modelPaymentClose}
          />
          <h4 className="reject-modal-title">
            Please pay platform fee of {currency_symbol}{" "}
            {(parseFloat(props.paymentMasterData.amount) / 100).toFixed(2)}{" "}
          </h4>
          <form
            method="post"
            action={process.env.REACT_APP_PAYMENT_WEB_CHECKOUT_URL}>
            <input
              type="hidden"
              name="merchant_code"
              value={props.paymentMasterData.merchant_code}
            />
            <input
              type="hidden"
              name="pay_item_id"
              value={props.paymentMasterData.pay_item_id}
            />
            <input
              type="hidden"
              name="site_redirect_url"
              value={
                process.env.REACT_APP_URL + "/api/v1/payment/site_redirect_url"
              }
            />
            <input
              type="hidden"
              name="txn_ref"
              value={
                props.po_number +
                "-" +
                props.invoice_no +
                "-" +
                randomReference()
              }
            />
            <input
              type="hidden"
              name="amount"
              value={props.paymentMasterData.amount}
            />
            <input
              type="hidden"
              name="currency"
              value={props.paymentMasterData.currency}
            />
            <br />
            <Grid item xs={6} sm={4} md={4} xl={4}>
              <Button type="submit" className="save">
                Make Payment
              </Button>
            </Grid>
          </form>
        </div>
      </Modal>
    </>
  );
}
