import React from "react";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
// sections for this page
import HeaderLinks from "components/Header/MyHeaderLinks.js";
// import image from 'assets/img/logo.png'

// import styles from "assets/jss/material-kit-react/views/components.js";

// const useStyles = makeStyles(styles);

export default function NavBar(props) {
    //   const classes = useStyles();
    const { ...rest } = props;
    return (
        <div>
            <Header
                rightLinks={<HeaderLinks />}
                absolute
                color="white"
                changeColorOnScroll={{
                    height: 400,
                    color: "white",
                }}
                {...rest}
            />
        </div>
    );
}
