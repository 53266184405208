import React, { useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import HomePage from "./HomePage";
import HomePage2 from "./HomePage2";
import HomePage3 from "./HomePage3";
import HomePage4 from "./HomePage4";
import HomePage5 from "./HomePage5";
import BottomComponent from "views/Components/HomePageComponents/BottomComponent";
import axios from "axios";
import SignupFooter from "views/Signup/footer";
import { Spinner } from "react-bootstrap";

export default function MainHomePage() {
  const [data, setData] = useState();
  const api_url = process.env.REACT_APP_URL;
  const [loader, setLoader] = useState(false);

  const getData = () => {
    setLoader(false);
    axios
      .get(
        api_url + "/api/v1/homepage",

        {
          headers: {
            "Content-Type": "text/plain",
            "ngrok-skip-browser-warning": true,
          },
        }
      )
      .then((res) => {
        if (res.status == 200) {
          setData(res.data);
          setLoader(true);
        } else {
          setLoader(true);
        }
      });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
  }, []);

  return (
    <>
      {loader !== true ? (
        <Grid container spacing={3} className="bootstrap-loader">
          <Spinner animation="grow" variant="spinner1" />
          <Spinner animation="grow" variant="spinner2" />
          <Spinner animation="grow" variant="spinner3" />
        </Grid>
      ) : (
        <>
          {data && data !== undefined && data !== null && (
            <Box className="homepage">
              <HomePage
                info={data.banner}
                strip={data.homepage_promotion_strip_line}
              />
              <HomePage2
                info={data.home_page_advantages}
                strip={data.homepage_promotion_strip_line}
              />
              <HomePage3
                info={data.homepage_promition_line}
                promo={data.promotion_body}
              />
              <HomePage4
                info={data.homepage_count_widget}
                img={data.homepage_supplier_list}
              />
              <BottomComponent info={data.registration_widget} />
              <HomePage5 />
              <SignupFooter />
            </Box>
          )}
        </>
      )}
    </>
  );
}
