import React, { useEffect, useState, useContext } from "react";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import axios from "axios";
import Button from "components/CustomButtons/Button.js";
import { useHistory } from "react-router-dom";
import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import { Link } from "react-router-dom";
import { getAccessToken } from "../../utilities/tokenUtil";
import { Store } from "../../context/store/storeState";

const useStyles = makeStyles(styles);

const customStyles = {
  headerLabel: {
    fontFamily: "Averta-Regular",
    // fontStyle: "normal",
    // fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "center",
    color: "#353F50",
    fontWeight: "500",
    marginRight: "12px",
    textTransform: "none",
  },
};

export default function HeaderLinks() {
  const history = useHistory();
  const [data, setData] = useState();
  const classes = useStyles();
  const api_url = process.env.REACT_APP_URL;
  const userName = localStorage.getItem("userName");
  const userId = localStorage.getItem("userId");
  const trader_type = localStorage.getItem("trader_type");
  const is_lender = localStorage.getItem("is_lender");
  const [login, setLogin] = useState(
    localStorage.getItem("userName") !== undefined ||
      localStorage.getItem("userName") !== null ||
      localStorage.getItem("userName") !== ""
      ? true
      : false
  );

  const verifyContext = useContext(Store);
  const logged_name = verifyContext.logged_name;
  const setLoggedName = verifyContext.setLoggedName;
  const logged_id = verifyContext.logged_id;
  const setLoggedID = verifyContext.setLoggedID;

  const getData = () => {
    let config = {
      method: "get",
      url: process.env.REACT_APP_URL + "/api/v1/header",
      headers: {
        "Content-Type": "text/plain",
        "Ngrok-Skip-Browser-Warning": true,
      },
    };
    axios(config).then((res) => {
      if (res.data.success) {
        setData(res.data.header_list);
        setLoggedName(userName);
        setLoggedID(userId);
      }
    });
  };
  const logout = () => {
    const dataSet = JSON.stringify({
      user_id: JSON.parse(localStorage.getItem("userId")),
      access_token: getAccessToken(),
    });
    let config = {
      method: "post",
      url: process.env.REACT_APP_URL + "/api/v1/auth/logout",
      data: dataSet,
      headers: {
        "Content-Type": "text/plain",
      },
    };
    axios(config)
      .then((response) => {
        if (response.data.success) {
          // alert(response.data.success_msg)
          localStorage.clear();
          setLogin(false);
          setLoggedName(null);
          setLoggedID(null);
          //window.location.reload(true);
          history.push("/");
        } else {
          //console.log(JSON.stringify(response.data));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const openLink = (link) => {
    if (link.page_name == "about") {
      history.push("/aboutUs");
    }
  };

  const get_url = window.location.pathname;
  const split = get_url.split("/cms/");
  const pagename = split[1];

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {api_url && data && data.length > 0 && (
        <List className={classes.list}>
          {(is_lender === "false" && trader_type !== "undefined") ||
          (is_lender === null && trader_type === null) ||
          is_lender === undefined ? (
            <>
              {data.map((item, header_key) => (
                <ListItem
                  className={
                    classes.listItem +
                    (pagename === item.page_type_name ? " active" : "")
                  }
                  key={header_key}>
                  <a
                    className={classes.navLink}
                    style={customStyles.headerLabel}
                    href={"/cms/" + item.page_type_name}>
                    {item.name}
                  </a>
                </ListItem>
              ))}
            </>
          ) : null}
          {logged_name !== null && logged_id !== null ? (
            <>
              {" "}
              <Link
                to={
                  trader_type === "supplier"
                    ? "/supplierdash"
                    : trader_type === "buyer"
                    ? "/buyer/dashboard"
                    : "/lender/dashboard"
                }
                className={classes.link}>
                <Button
                  colors="black"
                  color="transparent"
                  target="_blank"
                  className={classes.navLink}
                  style={customStyles.headerLabel}>
                  {userName}
                </Button>
              </Link>
              <Button
                href=""
                width="110px"
                height="40px"
                top="5px"
                border="1px solid #479FC8"
                bradius="5px"
                colors="#479FC8"
                color="transparent"
                target="_blank"
                onClick={logout}>
                Logout
              </Button>
            </>
          ) : (
            <>
              {" "}
              <ListItem
                className={
                  classes.listItem + (get_url === "/signin" ? " active" : "")
                }>
                <Link to={"/signin"} className={classes.link}>
                  <Button
                    href=""
                    colors="black"
                    color="transparent"
                    target="_blank"
                    className={classes.navLink}
                    style={customStyles.headerLabel}>
                    Login
                  </Button>
                </Link>
              </ListItem>
              <ListItem className={classes.listItem}>
                <Link to={"/signup"} className={classes.link}>
                  <Button
                    href=""
                    width="110px"
                    height="40px"
                    top="5px"
                    border="1px solid #479FC8"
                    bradius="5px"
                    colors="#479FC8"
                    color="transparent"
                    target="_blank">
                    Sign Up
                  </Button>
                </Link>
              </ListItem>
            </>
          )}
        </List>
      )}
    </>
  );
}
