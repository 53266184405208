import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom"
import {
  Grid,
  Box,
  Typography,
  OutlinedInput,
  withStyles,
  MenuItem,
  FormControl,
  Select,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Toolbar,
  Snackbar,
  SnackbarContent
} from "@material-ui/core";
import MuiButton from "@material-ui/core/Button";

import CloudUploadIcon from "@material-ui/icons/Check";
import preview from "assets/img/previewButton.svg";
import Custom from "views/Components/Custom";
import ThemeContainer from "views/Components/sideBar/ThemeContainer";
import { isMobile } from "react-device-detect";
import { PropTypes } from "prop-types";
import TableComponent from "views/Components/tableComponent/tablecomponent";
import { getAccessToken } from "../../../../utilities/tokenUtil";
import EditOutlinedIcon from '@material-ui/icons/BorderColor';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import image from "../../../../assets/img/BlackMan.svg";
import Slider from "react-slick";
import { Spinner } from "react-bootstrap";

var axios = require("axios");

const styles = (theme) => ({
  textFields: {
    background: "#F3F5F6",
    height: "40px",
    marginTop: "1%",
    "& div": {
      "&::before": {
        content: "",
        borderBottom: "none",
      },
      "&::after": {
        borderBottom: "none",
      },
      "&:hover": {
        "&::before": {
          borderBottom: "none !important",
        },
        "&::after": {
          borderBottom: "none !important",
        },
      },
      "& .MuiInputBase-input": {
        padding: "9px 7px",
      },
    },
  },
});


function editSignatory(props) {
    const { classes } = props;
    const history                 = useHistory()
    const api_url                 = process.env.REACT_APP_URL;
    const [name, setFullName]               = useState("");
    const [country, setCountry]             = useState("");
    const [countryList, setCountryList]     = useState("");
    const [email, setEmail]                 = useState("");
    const [designation, setDesignation]     = useState("");
    const [Mobilenumber, setMobileNumber]   = useState("");
    const [Bvnnumber, setBvnNumber]         = useState("");
    const [address, setAddress]             = useState("");
    const [zip, setZip]                     = useState("");
    const [WorkingatBranchHO, setWorkingatBranchHO] = useState("");
    const [state, setState]                 = useState("");
    const [city, setCity]                   = useState("");
    const [roles, setRoles]                 = useState("");
    const [ManageUsers, setManageUsers]     = useState("");
    const [status, setStatus]               = useState("");
    const [stateList, setStateList]         = useState("");
    const [password,setPassword]            = useState("");
    const [password2,setPassword2]          = useState("");
    const [user_list,setUserList]           = useState([]);
    const [documents,setDocuments]          = useState([]);
    const [loading, setLoading]             = useState(true);
    const [message, setMessage]             = useState({});
    const [open, setOpen]                   = useState(false);
    const [user_documents, setUserDocuments] = useState("")
    const [contact_id, setContactId]        = useState("");
    const [partner_id, setPartnerId]        = useState("");
    const [document_id, setDocumentId]      = useState("");
    const [document_type_id, setDocumentTypeID]   = useState("");
    const [file2, setfile2]                 = useState();
    const [fileview2, setfileView2]         = useState();
    const [file, setfile]                   = useState();
    const [newfile, setNewfile]             = useState();
    const [hqList, setHqList]               = useState([]);
    const [uploadDoc, setuploadDoc]         = useState([]);
    const [loader, setLoader]               = useState(false);
    var settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode:false,
      initialSlide:0,
      padSlides:false
    };
    const uploadDocumentList = (id) => {
        const access_token = getAccessToken();
    
     
    
        const data = {
          access_token: access_token,
          user_id: parseInt(localStorage.getItem("userId")),
          contact_id: id,
          "type" : "authorized_signatory"
        };
        
        axios
          .post(`${process.env.REACT_APP_URL}/api/v1/user/detail`, data, {
            headers: {
              "Content-Type": "text/plain",
            },
          })
          .then((response) => {
            setuploadDoc(response.data.documents);
            setLoader(true)
          })
          .catch((error) => {
            console.log(error);
          });
    };

    const masterFunction = () => {
        const access_token = getAccessToken();

        const data = {
            user_id: parseInt(localStorage.getItem("userId")),
            access_token: access_token
        };

        axios
        .post(`${process.env.REACT_APP_URL}/api/v1/signatory/master`, data, {
            headers: {
                "Content-Type": "text/plain",
            },
        })
        .then((response) => {
            console
            if (response) {
                
                setDocuments(response.data.signatory_documents);
               
            }
        })
        .catch((error) => {
            console.log(error);
        });
    };

    const masterFunction1 = () => {
      const access_token = getAccessToken();

      const data = {
          user_id: parseInt(localStorage.getItem("userId")),
          access_token: access_token
      };

      axios
      .post(`${process.env.REACT_APP_URL}/api/v1/user/master`, data, {
          headers: {
              "Content-Type": "text/plain",
          },
      })
      .then((response) => {
          console
          if (response) {
             
              setHqList(response.data.working_location);
          }
      })
      .catch((error) => {
          console.log(error);
      });
    };

    const userDetail = () => {
        const access_token = getAccessToken();
        const data = {
            user_id: parseInt(localStorage.getItem("userId")),
            access_token: access_token,
            contact_id: parseInt(props.match.params.id),
            "type" : "authorized_signatory",
        };

        axios
        .post(`${process.env.REACT_APP_URL}/api/v1/user/detail`, data, {
            headers: {
                "Content-Type": "text/plain",
            },
        })
        .then((response) => {

            if (response) {
                setAddress(response.data.address);
                setBvnNumber(response.data.bvn_number);
                setCity(response.data.city);
                setCountry(response.data.country_id);
                setState(response.data.state_id);
                setDesignation(response.data.designation);
                setMobileNumber(response.data.mobile);
                setFullName(response.data.name);
                setEmail(response.data.email);
                setRoles(response.data.user_role);
                setWorkingatBranchHO(response.data.working_location_name);
                setZip(response.data.zip);
                setUserDocuments(response.data.documents);
                setfileView2(process.env.REACT_APP_URL+response.data.image, response.data.image.length);
                setfile2(process.env.REACT_APP_URL+response.data.image.substring(1, response.data.image.length));

                if (response.data.country_id !== false) {
                  fetchState1(
                    response.data.country_id,
                    "allstate1",
                    response.data.state_id
                  );
                }
                setLoader(true)
            }else{
              setLoader(true)
            }
        })
        .catch((error) => {
            console.log(error);
        });
    };
    
    const onSubmit = () => {
        const access_token = getAccessToken();
     
        if (name === "" || name === undefined) {
          setLoading(false);
          setOpen(true);
          setMessage({ type: "error", content: "Name cannot be empty" });
          return;
        }
        if (Mobilenumber === "" || Mobilenumber === undefined) {
          setLoading(false);
          setOpen(true);
          setMessage({ type: "error", content: "Mobile cannot be empty" });
          return;
        }
        if (email === "" || email === undefined) {
          setLoading(false);
          setOpen(true);
          setMessage({ type: "error", content: "Email cannot be empty" });
          return;
        }
        if (roles === "" || roles === undefined) {
          setLoading(false);
          setOpen(true);
          setMessage({ type: "error", content: "Roles cannot be empty" });
          return;
        }
        if (designation === "" || designation === undefined) {
          setLoading(false);
          setOpen(true);
          setMessage({ type: "error", content: "Designation cannot be empty" });
          return;
        }
        if (Bvnnumber === "" || Bvnnumber === undefined) {
          setLoading(false);
          setOpen(true);
          setMessage({ type: "error", content: "Bvnnumber cannot be empty" });
          return;
        }
        if (WorkingatBranchHO === "" || WorkingatBranchHO === undefined) {
          setLoading(false);
          setOpen(true);
          setMessage({ type: "error", content: "Working at Branch HO cannot be empty" });
          return;
        }
        if (address === "" || address === undefined) {
          setLoading(false);
          setOpen(true);
          setMessage({ type: "error", content: "Address cannot be empty" });
          return;
        }
        if (zip === "" || zip === undefined) {
          setLoading(false);
          setOpen(true);
          setMessage({ type: "error", content: "Zip cannot be empty" });
          return;
        }
        if (country === "" || country === undefined) {
          setLoading(false);
          setOpen(true);
          setMessage({ type: "error", content: "Country cannot be empty" });
          return;
        }
        if (state === "" || state === undefined) {
          setLoading(false);
          setOpen(true);
          setMessage({ type: "error", content: "State cannot be empty" });
          return;
        }
        if (city === "" || city === undefined) {
          setLoading(false);
          setOpen(true);
          setMessage({ type: "error", content: "City cannot be empty" });
          return;
        }
        let image;
        if(newfile){
          image = newfile;
        }else{
          image = "";
        }

        let data = new FormData();
        data.append("id", parseInt(props.match.params.id));
        data.append("user_id", parseInt(localStorage.getItem("userId")));
        data.append("access_token", access_token);
        data.append("user_type", "user");
        data.append("user_role", roles);
        data.append("name", name);
        data.append("mobile", Mobilenumber);
        data.append("email", email);
        data.append("bvn_number", Bvnnumber);
        data.append("address", address);
        data.append("zip", zip);
        data.append("country_id", parseInt(country));
        data.append("state_id", parseInt(state));
        data.append("city", city);
        data.append("designation", designation);
        data.append("working_location_id", WorkingatBranchHO);
        data.append("image", image);

        const config = {
            method: "post",
            url: process.env.REACT_APP_URL + "/api/v1/user/update",
            headers: {
                "Content-Type": "text/plain",
            },
            data: data,
        };
        axios(config)
        .then((response) => {
            
            if (response && response.data) {
                
                if (response.data.success === true) {                    
                    setPartnerId(response.data.partner_id);
                    setMessage(response.data.message);
                    history.push('/manageuser');
                    //return userDetail();
                }
            }
        })
        .catch((error) => {
            console.log(error);
        });
  
    };
    const generatedocumentID = (event, document_name, document_type_id) => {
       
        if (!file2) {
          setLoading(false);
          setOpen(true);
          setMessage({ type: "error", content: "Pleasse insert image" });
          return;
        }
        if (name === "" || name === undefined) {
          setLoading(false);
          setOpen(true);
          setMessage({ type: "error", content: "Name cannot be empty" });
          return;
        }
        if (Mobilenumber === "" || Mobilenumber === undefined) {
          setLoading(false);
          setOpen(true);
          setMessage({ type: "error", content: "Mobile cannot be empty" });
          return;
        }
        if (email === "" || email === undefined) {
          setLoading(false);
          setOpen(true);
          setMessage({ type: "error", content: "Email cannot be empty" });
          return;
        }
        if (roles === "" || roles === undefined) {
          setLoading(false);
          setOpen(true);
          setMessage({ type: "error", content: "Roles cannot be empty" });
          return;
        }
        if (designation === "" || designation === undefined) {
          setLoading(false);
          setOpen(true);
          setMessage({ type: "error", content: "Designation cannot be empty" });
          return;
        }
        if (Bvnnumber === "" || Bvnnumber === undefined) {
          setLoading(false);
          setOpen(true);
          setMessage({ type: "error", content: "Bvnnumber cannot be empty" });
          return;
        }
        if (WorkingatBranchHO === "" || WorkingatBranchHO === undefined) {
          setLoading(false);
          setOpen(true);
          setMessage({ type: "error", content: "Working at Branch HO cannot be empty" });
          return;
        }
        if (address === "" || address === undefined) {
          setLoading(false);
          setOpen(true);
          setMessage({ type: "error", content: "Address cannot be empty" });
          return;
        }
        if (zip === "" || zip === undefined) {
          setLoading(false);
          setOpen(true);
          setMessage({ type: "error", content: "Zip cannot be empty" });
          return;
        }
        if (country === "" || country === undefined) {
          setLoading(false);
          setOpen(true);
          setMessage({ type: "error", content: "Country cannot be empty" });
          return;
        }
        if (state === "" || state === undefined) {
          setLoading(false);
          setOpen(true);
          setMessage({ type: "error", content: "State cannot be empty" });
          return;
        }
        if (city === "" || city === undefined) {
          setLoading(false);
          setOpen(true);
          setMessage({ type: "error", content: "City cannot be empty" });
          return;
        }

        const access_token = getAccessToken();

        const data = {
            user_id: parseInt(localStorage.getItem("userId")),
            access_token: access_token,
            partner_id: parseInt(props.match.params.id),
            document_type_id: parseInt(document_type_id),
        }

        const config = {
            method: "post",
            url: process.env.REACT_APP_URL + "/api/v1/user/document/id",
            headers: {
                "Content-Type": "text/plain",
            },
            data: data,
        };
        axios(config)
        .then((response) => {
            if (response && response.data) {
                if (response.data.success === true) {
                    setPartnerId(response.data.partner_id);        
                    setDocumentTypeID(response.data.document_type_id);        
                    setDocumentId(response.data.document_id);        
                    return documentUpload(event, document_name, document_type_id, response.data.document_id);            
                }
            }
        })
        .catch((error) => {
            console.log(error);
        });
    };
    const documentUpload = (event, document_name, document_type_id, document_id) => {
               
        const access_token = getAccessToken();
        const reader = new FileReader();
        /*reader.addEventListener("load", () => {
            setfile(reader.result);
        });*/
        //setfile2(event.target.files[0]);
        reader.readAsDataURL(event.target.files[0]);

        let data = new FormData();
        data.append("user_id", parseInt(localStorage.getItem("userId")));
        data.append("access_token", access_token);
        data.append("partner_id", parseInt(partner_id));
        data.append("document_type_id", parseInt(document_type_id));
        data.append("document_id", parseInt(document_id ? document_id : 0));
        data.append("document_name", document_name);
        data.append("document_data", event.target.files[0]);

        const config = {
            method: "post",
            url: process.env.REACT_APP_URL + "/api/v1/user/document/upload",
            headers: {
                "Content-Type": "text/plain",
            },
            data: data,
        };
        axios(config)
        .then((response) => {
            if (response && response.data) {
                if (response.data.success === true) {
                    setLoading(false);
                    setOpen(true);
                    setMessage({ type: "success", content: response.data.message });
                    return userDetail();            
                }else{
                    setLoading(false);
                    setOpen(true);
                    setMessage({ type: "error", content: response.data.message });
                }
            }
        })
        .catch((error) => {
            console.log(error);
        });
    };

    const editUpload = (event, document_name, document_type_id, document_id) => {
        
        const access_token = getAccessToken();
        const reader = new FileReader();
        /*reader.addEventListener("load", () => {
            setfileView(reader.result);
        });*/
        setfile(event.target.files[0]);
        reader.readAsDataURL(event.target.files[0]);

        let data = new FormData();
        data.append("user_id", parseInt(localStorage.getItem("userId")));
        data.append("access_token", access_token);
        data.append("partner_id", parseInt(partner_id));
        data.append("document_type_id", parseInt(document_type_id));
        data.append("document_id", parseInt(document_id ? document_id : 0));
        data.append("document_name", document_name);
        data.append("document_data", event.target.files[0]);

        const config = {
            method: "post",
            url: process.env.REACT_APP_URL + "/api/v1/trader/document/upload",
            headers: {
                "Content-Type": "text/plain",
            },
            data: data,
        };
        axios(config)
        .then((response) => {
            if (response && response.data) {
                if (response.data.success === true) {
                    setDocumentId(response.data.document_id); 
                    setLoading(false);
                    setOpen(true);
                    setMessage({ type: "success", content: response.data.message });
                    return userDetail();                       
                }else{
                    setLoading(false);
                    setOpen(true);
                    setMessage({ type: "error", content: response.data.message });
                }
            }
        })
        .catch((error) => {
            console.log(error);
        });
    };

    const deleteUpload = (event, contact_id, document_id) => {
        const access_token = getAccessToken();       
        const data = {
            user_id: parseInt(localStorage.getItem("userId")),
            access_token: access_token,
            partner_id: parseInt(partner_id),
            document_type_id: parseInt(document_type_id),
            document_id: parseInt(document_id),
        }
        const config = {
            method: "post",
            url: process.env.REACT_APP_URL + "/api/v1/user/document/delete",
            headers: {
                "Content-Type": "text/plain",
            },
            data: data,
        };
        axios(config)
        .then((response) => {
            if (response && response.data) {
                if (response.data.success === true) {
                    setLoading(false);
                    setOpen(true);
                    setMessage({ type: "success", content: response.data.message });
                    return userDetail();                        
                }else{
                    setLoading(false);
                    setOpen(true);
                    setMessage({ type: "error", content: response.data.message });
                }
            }
        })
        .catch((error) => {
            console.log(error);
        });
    };
    const countryFunction = () => {
        const access_token = getAccessToken();
        const userID = localStorage.getItem("userId");

        const data = {
          user_id: localStorage.getItem("userId"),
          access_token: access_token,
        };
        axios
        .post(`${process.env.REACT_APP_URL}/api/v1/country/master`,
        JSON.stringify(data)
        )
        .then((response) => {
            if (response && response.data.sucess) {
                setCountryList(response.data.country);
            }        
        })
        .catch((error) => {
            console.log(error);
        });
    };
    const fetchState = (country_id, data1) => {

        const dataSet = JSON.stringify({
            country_id: country_id,
        });
        const config = {
            method: "post",
            url: process.env.REACT_APP_URL + "/api/v1/state/master",
            headers: {
                "Content-Type": "text/plain",
            },
            data: dataSet,
        };
        axios(config)
            .then((response) => {

            setStateList(response.data.state);
            })
        .catch((error) => {
            console.log(error);
        });
    };
    const fetchState1 = (country_id, data1, state) => {
      
      const dataSet = JSON.stringify({
        country_id: country_id,
      });
      const config = {
        method: "post",
        url: process.env.REACT_APP_URL + "/api/v1/state/master",
        headers: {
          "Content-Type": "text/plain",
        },
        data: dataSet,
      };
      axios(config)
        .then((response) => {

          if (data1 == "allstate1") {
            setStateList(response.data.state);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };
    const removeUserImage = () => {
        setfileView2("");
    };
    const userImageUpload = (event) => {
        const access_token = getAccessToken();
        if (event.target.files && event.target.files[0]) {
          
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setfileView2(reader.result);
            });
            setNewfile(event.target.files[0]);
            reader.readAsDataURL(event.target.files[0]);
        }        
    };
  
    const downloadDocument = (event, document_id) => {
        // event.preventDefault();        
        
        const access_token = getAccessToken();
        
        const data = {
            user_id: parseInt(localStorage.getItem("userId")),
            access_token: access_token,
            partner_id: parseInt(props.match.params.id),
            document_id: parseInt(document_id)
        };

        axios
        .post(`${process.env.REACT_APP_URL}/api/v1/user/document/download`, data, {
            headers: {
                "Content-Type": "text/plain",
            },
        })
        .then((response) => {
          
            if(response.data.success === true){
                const api_url = process.env.REACT_APP_URL
                let url = `${api_url+response.data.document_url}?${Date.now()}`;
                window.open( url, '_blank' );
            }else{
                setLoading(false);
                setOpen(true);
                setMessage({ type: "error", content: "Something went wrong." });
            }
            
        })
        .catch((error) => {
            console.log(error);
        });
    };
    useEffect(() => {
        document.title = 'Interswitch - Signatory Detail';
        window.scrollTo(0, 0);
        countryFunction();
        masterFunction();
        masterFunction1();
        userDetail();
        uploadDocumentList(props.match.params.id);
    }, []);

  return (
    <ThemeContainer type="private" value={5}>
      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={open} autoHideDuration={3000} onClose={handleClose} >
        <SnackbarContent className={message.type === "error" ? "snack-error" : message.type === "success" ? "snack-success" : "snack-teal" } message={message.content} />
      </Snackbar>
      {loader !== true ? (
        <Grid container spacing={3} className="bootstrap-loader">
          
            <Spinner animation="grow" variant="spinner1" />
            <Spinner animation="grow" variant="spinner2" />
            <Spinner animation="grow" variant="spinner3" />
          
        </Grid>
      ) : (
      <>
        
           
        <Box className="scf-box">
          <Box className="scf-subbox">
            <Grid container>
                <Grid item xs={6} sm={6} md={11} xl={11}>
                    <Typography className="listing-main-title">Signatory Profile</Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={1} xl={1}>
                    <Button className="back-button" onClick={() => history.push("/signatory")} ><i className="fas fa-long-arrow-alt-left"></i> Back
                    </Button>
                </Grid>
            </Grid> 
            <Grid container className="view-signatory" >  
                <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                    <Box className="image-box" >
                      
                      {fileview2 ? (
                        <img className="img-thumb" src={`${fileview2}?${Date.now()}`} />
                      ) : (
                        <img className="img-thumb" src={image} />
                      )}
                    </Box>
                 
                </Grid>
                <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
                  <Typography className="view-title" > {name}
                    <span >
                      <MuiButton variant="contained" className="signatory-status" startIcon={<CloudUploadIcon />} > Verified </MuiButton>
                    </span>
                  </Typography >
                  <Typography className="designation"> {designation} </Typography>
                  <Typography className="bvn-number"> BVN Number -  <span> {Bvnnumber}</span> </Typography>
                  <Typography className="working-branch"> Working at Branch/ HO -   <span> {WorkingatBranchHO}</span> </Typography>
                </Grid>
              
            </Grid>
            <br></br>
            
            <Grid container className="signatory-contact-info">
              <Box className="box"> 
                <Typography className="main-title"> Contact Information </Typography>
                <Typography className="sub-title" > Email Address - <span> {email}</span> </Typography>   
                <Typography className="sub-title" > Phone Number - <span> {Mobilenumber}</span> </Typography>
                <Typography className="sub-title"> Address - <span> {address}</span> </Typography>
              </Box>
            </Grid>

            <Typography className="sub-heading"> Uploaded Documents</Typography> 
           
            {documents.map((item, index) => (
              <Box className="upload-documents" key={index}>
                <Grid container spacing={2}>
                 <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >       
                          <Typography className="company-title3"> {item.name}
                            {item.is_required === true ? (
                              <span className="document-required">*</span>
                            ) : (
                               ""
                            )}
                          </Typography>                          
                        </Grid>               
                      </Grid>
                 </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >

                    <Grid container spacing={2}>
                      {uploadDoc &&
                        uploadDoc.map((docItem_item) => (
                          <>
                            {docItem_item.doc_id === item.id ? (
                              <>
       
                              {docItem_item.doc_list.map((doc)=>(<> 
                              <Grid item xs={2} sm={2} md={2} lg={2} xl={2} key={doc.document_id}>                          
                                <center>
                                  <Box className="uploaded-documents-list image-box"
                                    onClick={(e) => downloadDocument(e, doc.document_id)}
                                  >
                                   {doc.image_extension === "application/pdf" ? (
                                      <embed src={`${api_url+doc.document_url}?${Date.now()}`} className="image" type={doc.image_extension} onClick={(e) => downloadDocument(e, doc.document_id)}/>                                                                  
                                    ) : (
                                      <img className="image" src={`${api_url+doc.document_url}?${Date.now()}`} onClick={(e) => downloadDocument(e, doc.document_id)}/>
                                    )}
                                  </Box>
                                </center>
                              </Grid>
                              </>
                              ))}   
                              </>
                             
                            ) :(
                              ""
                            )}
                          </>
                        ))}
                    </Grid>
                    
                  </Grid>
                </Grid>
              </Box>
            ))}

          </Box>
        </Box>
      </>
      )}
    </ThemeContainer>
  );
}

editSignatory.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(editSignatory);
