import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { Typography, Box } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/components.js";
import { isMobile } from "react-device-detect";
import signinImage from "../../assets/img/home-image2.png";
import { useLocation } from "react-router-dom";
import GoogleMap from "../ContactUs/GoogleMap";
import ContactForm from "../AboutUs/ContactUs";
import Slider from "react-slick";

const useStyles = makeStyles(styles);
var slider = {
  slidesToShow: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  infinite: true,
  slidesToScroll: 1,
  arrows: false,
};
export default function HomePage2(props) {
  const classes = useStyles();

  const loction = useLocation();
  const currentLocation =
    location.pathname == "/cms/policy" ||
    location.pathname == "/cms/terms" ||
    location.pathname == "/cms/contact-us"
      ? false
      : true;
  const contactUs = location.pathname == "/cms/contact-us" ? true : false;
  const api_url = process.env.REACT_APP_URL;
  const [data, setData] = useState(props.info);
  const [strip, setStrip] = useState(props.strip);
  const [img, setImg] = useState(
    api_url +
      (props.info.advantage_image_url !== undefined
        ? props.info.advantage_image_url
        : props.info.description_image_url)
  );

  return (
    <div className="home-advantages">
      {!isMobile ? (
        <>
          {strip && strip.length > 0 && (
            <div className="home-advantages-content">
              <Slider {...slider}>
                {strip.map((data, page2_key) => (
                  <Typography className="home-advantages-text" key={page2_key}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data.strip_line,
                      }}></div>
                  </Typography>
                ))}
              </Slider>
            </div>
          )}
        </>
      ) : (
        <>
          {img && img !== undefined && (
            <Box>
              <img className="strip-image" src={`${img}?${Date.now()}`} />
            </Box>
          )}
        </>
      )}
      <div>
        <Grid container className="home-advantages-description-content">
          <Grid item xs={12} sm={12} md={6} xl={6}>
            <div className="home-advantages-text">
              <div
                dangerouslySetInnerHTML={{
                  __html: data.advantage_content
                    ? data.advantage_content
                    : data.description_content,
                }}></div>
              {contactUs ? <ContactForm /> : ""}
            </div>
          </Grid>
          {img && img !== undefined && !isMobile && currentLocation ? (
            <Grid item xs={12} sm={12} md={6} xl={6}>
              <div className="home-advantages-images">
                <img
                  src={`${img}?${Date.now()}`}
                  className={classes.imgFluid}
                />
              </div>
            </Grid>
          ) : (
            ""
          )}
          {contactUs ? <GoogleMap /> : ""}
        </Grid>
      </div>
    </div>
  );
}
