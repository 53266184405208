import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Grid,
  Box,
  Typography,
  OutlinedInput,
  withStyles,
  MenuItem,
  FormControl,
  Select,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Toolbar,
  Snackbar,
  SnackbarContent,
} from "@material-ui/core";
import preview from "assets/img/previewButton.svg";
import Custom from "views/Components/Custom";
import ThemeContainer from "views/Components/sideBar/ThemeContainer";
import { isMobile } from "react-device-detect";
import { PropTypes } from "prop-types";
import TableComponent from "views/Components/tableComponent/tablecomponent";
import { getAccessToken } from "../../utilities/tokenUtil";
import EditOutlinedIcon from "@material-ui/icons/BorderColor";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import image from "../../assets/img/avatar.png";
//import image from "assets/img/defaultimg.jpg";
import { Spinner } from "react-bootstrap";

var axios = require("axios");

const styles = (theme) => ({
  textFields: {
    background: "#F3F5F6",
    height: "40px",
    marginTop: "1%",
    "& div": {
      "&::before": {
        content: "",
        borderBottom: "none",
      },
      "&::after": {
        borderBottom: "none",
      },
      "&:hover": {
        "&::before": {
          borderBottom: "none !important",
        },
        "&::after": {
          borderBottom: "none !important",
        },
      },
      "& .MuiInputBase-input": {
        padding: "9px 7px",
      },
    },
  },
});

function Editaccount(props) {
  const userId = localStorage.getItem("userId");
  const userName = localStorage.getItem("userName");
  const kyc_status = localStorage.getItem("kyc_status");
  const is_lender = localStorage.getItem("is_lender");
  const trader_type = localStorage.getItem("trader_type");

  const { classes } = props;
  const history = useHistory();
  const api_url = process.env.REACT_APP_URL;
  const [name, setFullName] = useState("");
  const [country, setCountry] = useState("");
  const [countryList, setCountryList] = useState("");
  const [email, setEmail] = useState("");
  const [Mobilenumber, setMobileNumber] = useState("");
  const [address, setAddress] = useState("");
  const [zip, setZip] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [stateList, setStateList] = useState("");
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState({});
  const [open, setOpen] = useState(false);
  const [file2, setfile2] = useState();
  const [fileview2, setfileView2] = useState();
  const [fileview3, setfileView3] = useState();
  const [newfile, setNewfile] = useState();
  const [phone, setPhone] = useState("");
  const [street2, setStreet2] = useState("");
  const [contactDeg, setContactDeg] = useState("");
  const [contactBVN, setConatctBVN] = useState("");
  const [contactWork, setContactWork] = useState("");
  const [hqList, setHqList] = useState("");
  const [loader, setLoader] = useState(false);

  if (!userId || userName === null) {
    history.push("/signin");
  }

  if (is_lender === "true" && trader_type === "undefined") {
    history.push("/lender/dashboard");
  }

  const masterFunction = () => {
    const access_token = getAccessToken();
    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: access_token,
    };

    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/invite/user/master`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        if (response) {
          setHqList(response.data.working_location);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const accountDetail = () => {
    const access_token = getAccessToken();
    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: access_token,
    };

    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/get/my_account/detail`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        if (response.data.success === true) {
          setAddress(response.data.account_detail[0].address);
          setCity(response.data.account_detail[0].city_id);
          setCountry(response.data.account_detail[0].country_id);
          setState(response.data.account_detail[0].state_id);
          setMobileNumber(response.data.account_detail[0].mobile);
          setPhone(response.data.account_detail[0].phone);
          setFullName(response.data.account_detail[0].name);
          setEmail(response.data.account_detail[0].email);
          setZip(response.data.account_detail[0].zip);
          setStreet2(response.data.account_detail[0].street2);
          setfileView3(response.data.account_detail[0].image);
          setfileView2(response.data.account_detail[0].image);
          setContactDeg(response.data.account_detail[0].job_position);
          setConatctBVN(response.data.account_detail[0].bvn_number);
          setContactWork(response.data.account_detail[0].working_at_id);

          if (response.data.account_detail[0].country_id !== false) {
            fetchState1(
              response.data.account_detail[0].country_id,
              "allstate1",
              response.data.account_detail[0].state_id
            );
          }
          setLoader(true);
        } else {
          setLoader(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSubmit = () => {
    const access_token = getAccessToken();
    let mobile_validate =
      /^(?:\+)?\s*[0][1-9]\d{9,12}$|^(?:\+)?\s*[1-9]\d{9,12}$/;

    let bvn_validate = /^[0][1-9]\d{10}$|^[1-9]\d{10}$/;

    if (
      name.trim() === "" ||
      name.trim() === undefined ||
      Mobilenumber === "" ||
      Mobilenumber === undefined ||
      email.trim() === "" ||
      email.trim() === undefined ||
      address.trim() === "" ||
      address.trim() === undefined ||
      country === "" ||
      country === undefined ||
      contactBVN.trim() === "" ||
      contactBVN.trim() === undefined
    ) {
      setLoading(false);
      setOpen(true);
      setMessage({ type: "error", content: "All fields are mandatory." });
      return;
    }

    if (!Mobilenumber.replace(/\s/g, "").match(mobile_validate)) {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "Mobile number should be 10 to 13 digit",
      });
      return;
    }
    if (phone) {
      if (!phone.replace(/\s/g, "").match(mobile_validate)) {
        setLoading(false);
        setOpen(true);
        setMessage({
          type: "error",
          content: "Phone number should be 10 digit",
        });
        return;
      }
    }

    if (!contactBVN.match(bvn_validate)) {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "BVN number should be 11 digit",
      });
      return;
    }

    let image;
    if (newfile) {
      image = newfile;
    } else {
      image = "";
    }

    let data = new FormData();
    data.append("user_id", parseInt(localStorage.getItem("userId")));
    data.append("access_token", access_token);
    data.append("name", name.trim());
    data.append("mobile", Mobilenumber.trim());
    data.append("phone", phone.trim());
    data.append("address", address.trim());
    data.append("zip", zip.trim());
    data.append("job_position", contactDeg.trim());
    data.append("working_at_id", contactWork);
    data.append("bvn_number", contactBVN.trim());
    data.append("country_id", parseInt(country ? country : 0));
    data.append("state_id", parseInt(state ? state : 0));
    data.append("city", city.trim());
    data.append("image", image);
    setLoader(false);
    const config = {
      method: "post",
      url: process.env.REACT_APP_URL + "/api/v1/update/my_account",
      headers: {
        "Content-Type": "text/plain",
      },
      data: data,
    };
    axios(config)
      .then((response) => {
        if (response && response.data) {
          if (response.data.success === true) {
            localStorage.setItem("userName", name);
            setLoading(false);
            setOpen(true);
            setMessage({ type: "success", content: response.data.message });
            setTimeout(() => {
              history.push("/myaccount");
            }, 3000);
          } else {
            setLoading(false);
            setOpen(true);
            setLoader(true);
            setMessage({ type: "error", content: response.data.message });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const countryFunction = () => {
    const access_token = getAccessToken();
    const userID = localStorage.getItem("userId");

    const data = {
      user_id: localStorage.getItem("userId"),
      access_token: access_token,
    };
    axios
      .post(
        `${process.env.REACT_APP_URL}/api/v1/country/master`,
        JSON.stringify(data)
      )
      .then((response) => {
        if (response && response.data.sucess) {
          setCountryList(response.data.country);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const fetchState = (country_id, data1) => {
    const dataSet = JSON.stringify({
      country_id: country_id,
    });
    const config = {
      method: "post",
      url: process.env.REACT_APP_URL + "/api/v1/state/master",
      headers: {
        "Content-Type": "text/plain",
      },
      data: dataSet,
    };
    axios(config)
      .then((response) => {
        setStateList(response.data.state);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const fetchState1 = (country_id, data1, state) => {
    const dataSet = JSON.stringify({
      country_id: country_id,
    });
    const config = {
      method: "post",
      url: process.env.REACT_APP_URL + "/api/v1/state/master",
      headers: {
        "Content-Type": "text/plain",
      },
      data: dataSet,
    };
    axios(config)
      .then((response) => {
        if (data1 == "allstate1") {
          setStateList(response.data.state);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const removeUserImage = () => {
    setfileView2("");
    setfileView3("");
  };
  const handleMobileNumber = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setMobileNumber(e.target.value);
    }
  };
  const handlePhoneNumber = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setPhone(e.target.value);
    }
  };
  const userImageUpload = (event) => {
    const access_token = getAccessToken();
    if (event.target.files && event.target.files[0]) {
      let fileext = event.target.files[0];
      if (fileext.name.match(/\.(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/)) {
        if (fileext.size > 2000000) {
          setOpen(true);
          setMessage({
            type: "error",
            content: "Image size should be less than 2MB.",
          });
          return;
        }
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setfileView2(reader.result);
        });
        setNewfile(event.target.files[0]);
        reader.readAsDataURL(event.target.files[0]);
        setfileView3("");
      } else {
        setLoading(false);
        setOpen(true);
        setMessage({
          type: "error",
          content: "Only jpg, jpeg, PNG, PDF gif files are allowed!",
        });
      }
    }
  };

  useEffect(() => {
    document.title = "Interswitch - Update Account";
    window.scrollTo(0, 0);
    countryFunction();
    masterFunction();
    accountDetail();
  }, []);

  return (
    <ThemeContainer type="private" value={1}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}>
        <SnackbarContent
          className={
            message.type === "error"
              ? "snack-error"
              : message.type === "success"
              ? "snack-success"
              : "snack-teal"
          }
          message={message.content}
        />
      </Snackbar>
      {loader !== true ? (
        <Grid container spacing={3} className="bootstrap-loader">
          <Spinner animation="grow" variant="spinner1" />
          <Spinner animation="grow" variant="spinner2" />
          <Spinner animation="grow" variant="spinner3" />
        </Grid>
      ) : (
        <>
          <Box className="scf-box">
            <Box className="scf-subbox">
              <Grid container>
                <Grid item xs={12} sm={12} md={12} xl={12}>
                  <Typography className="company-title11">
                    Update Account
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="form-style">
                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                  <center>
                    <Box className="image-box">
                      {fileview3 ? (
                        <img
                          className="img-thumb"
                          src={`${api_url + fileview3}?${Date.now()}`}
                        />
                      ) : (
                        <>
                          {fileview2 ? (
                            <img className="img-thumb" src={fileview2} />
                          ) : (
                            <img className="img-thumb" src={image} />
                          )}
                        </>
                      )}
                      {!fileview2 && (
                        <Box className="img-action-option edit">
                          <center>
                            <label>
                              <EditOutlinedIcon className="img-edit" />
                              <input
                                type="file"
                                hidden
                                onChange={(e) => userImageUpload(e)}
                              />
                            </label>
                          </center>
                        </Box>
                      )}
                      {fileview3 || fileview2 ? (
                        <Box className="img-action-option delete">
                          <center>
                            <DeleteOutlineIcon
                              className="img-delete"
                              onClick={removeUserImage}
                            />
                          </center>
                        </Box>
                      ) : null}
                    </Box>
                  </center>
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                  <Typography className="img-heading">Photo</Typography>
                  <Typography className="img-subheading">
                    In PNG, PDF JPG, GIF and JPEG format size should be less
                    then 2MB
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="input-box">
                    <span className="company-title3">
                      Full Name<span className="required">*</span>
                    </span>
                    <OutlinedInput
                      className="company-title5"
                      fullWidth
                      value={name}
                      placeholder="e.g. Full Name"
                      onChange={(e) => setFullName(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="input-box">
                    <span className="company-title3">
                      Mobile<span className="required">*</span>
                    </span>
                    <OutlinedInput
                      className="company-title5"
                      fullWidth
                      value={Mobilenumber}
                      placeholder="e.g. Mobile Number"
                      onChange={(e) => handleMobileNumber(e)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="input-box">
                    <span className="company-title3">Phone </span>
                    <OutlinedInput
                      className="company-title5"
                      fullWidth
                      value={phone}
                      placeholder="e.g. Phone Number"
                      onChange={(e) => handlePhoneNumber(e)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="input-box">
                    <span className="company-title3">
                      Email Address<span className="required">*</span>
                    </span>
                    <OutlinedInput
                      className="company-title5"
                      fullWidth
                      readOnly
                      type="email"
                      value={email}
                      placeholder="e.g. Email"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="input-box">
                    <span className="company-title3">Designation </span>
                    <OutlinedInput
                      className="company-title5"
                      fullWidth
                      placeholder=" e.g. Designation"
                      value={contactDeg}
                      onChange={(e) => setContactDeg(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="input-box">
                    <span className="company-title3">
                      BVN No. <span className="required">*</span>
                    </span>
                    <OutlinedInput
                      className="company-title5"
                      fullWidth
                      value={contactBVN}
                      placeholder=" e.g. BVN No. "
                      onChange={(e) => setConatctBVN(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="input-box">
                    <span className="company-title3">Working at HQ </span>
                    <FormControl fullWidth>
                      <Select
                        displayEmpty
                        className="company-title4"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={contactWork}
                        onChange={(e) => setContactWork(e.target.value)}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}>
                        <MenuItem value="" disabled={true}>
                          Select from options
                        </MenuItem>

                        {hqList !== "" && hqList !== undefined ? (
                          hqList.map((item) => (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          ))
                        ) : (
                          <></>
                        )}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="input-box">
                    <span className="company-title3">
                      Address <span className="required">*</span>
                    </span>
                    <OutlinedInput
                      className="company-title5"
                      fullWidth
                      value={address}
                      placeholder="e.g. Address"
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="input-box">
                    <span className="company-title3">Zip/ Postal Code </span>
                    <OutlinedInput
                      className="company-title5"
                      fullWidth
                      value={zip !== "False" ? zip : ""}
                      placeholder="e.g. Zip/ Postal Code"
                      onChange={(e) => setZip(e.target.value)}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="input-box">
                    <span className="company-title3">
                      Country<span className="required">*</span>
                    </span>
                    <FormControl fullWidth>
                      <Select
                        displayEmpty
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Age"
                        value={country}
                        onChange={(e) => {
                          setCountry(e.target.value);
                          const data = e.currentTarget.getAttribute("name");
                          fetchState(e.target.value, "allstate1");
                        }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}>
                        <MenuItem value={""}> Select from options</MenuItem>

                        {countryList !== "" ? (
                          countryList.map((item, index) => (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          ))
                        ) : (
                          <></>
                        )}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="input-box">
                    <span className="company-title3">State/Provience </span>
                    <FormControl fullWidth>
                      <Select
                        displayEmpty
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Age"
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}>
                        <MenuItem value="" disabled={true}>
                          Select from options
                        </MenuItem>
                        {stateList !== "" ? (
                          stateList.map((item, index) => (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          ))
                        ) : (
                          <></>
                        )}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="input-box">
                    <span className="company-title3">City </span>
                    <OutlinedInput
                      className="company-title5"
                      fullWidth
                      value={city}
                      placeholder="e.g. City"
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Grid container spacing={3} className="button-grid">
                <Grid item xs={6} sm={4} md={2} xl={2}>
                  <Button
                    className="save"
                    onClick={onSubmit}
                    disabled={
                      !name ||
                      !Mobilenumber.replace(/\s/g, "") ||
                      !email ||
                      !address ||
                      !country ||
                      !contactBVN
                    }>
                    Save
                  </Button>
                </Grid>
                <Grid item xs={6} sm={4} md={2} xl={2}>
                  <Link to="/myaccount">
                    <Button className="cancel">Cancel</Button>
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </>
      )}
    </ThemeContainer>
  );
}

Editaccount.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(Editaccount);
