import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Grid,
  Box,
  Typography,
  OutlinedInput,
  withStyles,
  MenuItem,
  FormControl,
  Select,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Toolbar,
  Snackbar,
  SnackbarContent,
} from "@material-ui/core";
import preview from "assets/img/previewButton.svg";
import Custom from "views/Components/Custom";
import ThemeContainer from "views/Components/sideBar/ThemeContainer";
import { isMobile } from "react-device-detect";
import { PropTypes } from "prop-types";
import TableComponent from "views/Components/tableComponent/tablecomponent";
import { getAccessToken } from "../../utilities/tokenUtil";
import EditOutlinedIcon from "@material-ui/icons/BorderColor";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import image from "../../assets/img/avatar.png";
import { Spinner } from "react-bootstrap";
var axios = require("axios");

const styles = (theme) => ({
  textFields: {
    background: "#F3F5F6",
    height: "40px",
    marginTop: "1%",
    "& div": {
      "&::before": {
        content: "",
        borderBottom: "none",
      },
      "&::after": {
        borderBottom: "none",
      },
      "&:hover": {
        "&::before": {
          borderBottom: "none !important",
        },
        "&::after": {
          borderBottom: "none !important",
        },
      },
      "& .MuiInputBase-input": {
        padding: "9px 7px",
      },
    },
  },
});

function EditUser(props) {
  const userId = localStorage.getItem("userId");
  const userName = localStorage.getItem("userName");
  const kyc_status = localStorage.getItem("kyc_status");
  const api_url = process.env.REACT_APP_URL;
  const { classes } = props;
  const history = useHistory();

  const [name, setFullName] = useState("");
  const [contact_user_id, setContactUserId] = useState("");
  const [country, setCountry] = useState("");
  const [countryList, setCountryList] = useState("");
  const [email, setEmail] = useState("");
  const [designation, setDesignation] = useState("");
  const [Mobilenumber, setMobileNumber] = useState("");
  const [Bvnnumber, setBvnNumber] = useState("");
  const [address, setAddress] = useState("");
  const [zip, setZip] = useState("");
  const [user_type, setUserType] = useState("");
  const [WorkingatBranchHO, setWorkingatBranchHO] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [roles, setRoles] = useState("");
  const [ManageUsers, setManageUsers] = useState("");
  const [status, setStatus] = useState("");
  const [stateList, setStateList] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [user_list, setUserList] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState({});
  const [open, setOpen] = useState(false);
  const [user_documents, setUserDocuments] = useState("");
  const [contact_id, setContactId] = useState("");
  const [partner_id, setPartnerId] = useState("");
  const [document_id, setDocumentId] = useState("");
  const [document_type_id, setDocumentTypeID] = useState("");
  const [file2, setfile2] = useState();
  const [fileview2, setfileView2] = useState();
  const [fileview3, setfileView3] = useState();
  const [file, setfile] = useState();
  const [newfile, setNewfile] = useState();
  const [hqList, setHqList] = useState([]);
  const [loader, setLoader] = useState(false);

  if (!userId || userName === null) {
    history.push("/signin");
  }
  if (kyc_status !== "Approved") {
    history.push("/kyc-page");
  }

  const masterFunction = () => {
    const access_token = getAccessToken();

    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: access_token,
    };

    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/user/master`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        console;
        if (response) {
          setDocuments(response.data.documents);
          setHqList(response.data.working_location);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const userDetail = () => {
    const access_token = getAccessToken();
    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: access_token,
      type: "user",
      contact_id: parseInt(props.match.params.id),
    };
    setLoader(false);
    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/user/detail`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        if (response) {
          setAddress(response.data.address);
          setBvnNumber(response.data.bvn_number);
          setCity(response.data.city);
          setCountry(response.data.country_id);
          setState(response.data.state_id);
          setDesignation(response.data.designation);
          setMobileNumber(response.data.mobile);
          setFullName(response.data.name);
          setEmail(response.data.email);
          setRoles(response.data.user_role);
          setWorkingatBranchHO(response.data.working_location_id);
          setZip(response.data.zip);
          setUserDocuments(response.data.documents);
          setfileView3(response.data.image);
          setfile2(response.data.image);
          setContactUserId(response.data.contact_user_id);
          setUserType(response.data.user_type);

          if (response.data.country_id !== false) {
            fetchState1(
              response.data.country_id,
              "allstate1",
              response.data.state_id
            );
          }
          setLoader(true);
        } else {
          setLoader(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const onBlurAddUser = () => {
    const access_token = getAccessToken();
    if (name === "" || name === undefined) {
      setLoading(false);
      setOpen(true);
      setMessage({ type: "error", content: "Name cannot be empty" });
      return;
    }
    if (Mobilenumber === "" || Mobilenumber === undefined) {
      setLoading(false);
      setOpen(true);
      setMessage({ type: "error", content: "Mobile cannot be empty" });
      return;
    }
    if (email === "" || email === undefined) {
      setLoading(false);
      setOpen(true);
      setMessage({ type: "error", content: "Email cannot be empty" });
      return;
    }
    if (roles === "" || roles === undefined) {
      setLoading(false);
      setOpen(true);
      setMessage({ type: "error", content: "Roles cannot be empty" });
      return;
    }
    if (designation === "" || designation === undefined) {
      setLoading(false);
      setOpen(true);
      setMessage({ type: "error", content: "Designation cannot be empty" });
      return;
    }
    if (Bvnnumber === "" || Bvnnumber === undefined) {
      setLoading(false);
      setOpen(true);
      setMessage({ type: "error", content: "Bvnnumber cannot be empty" });
      return;
    }
    if (WorkingatBranchHO === "" || WorkingatBranchHO === undefined) {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "Working at Branch HO cannot be empty",
      });
      return;
    }
    if (address === "" || address === undefined) {
      setLoading(false);
      setOpen(true);
      setMessage({ type: "error", content: "Address cannot be empty" });
      return;
    }
    if (zip === "" || zip === undefined) {
      setLoading(false);
      setOpen(true);
      setMessage({ type: "error", content: "Zip cannot be empty" });
      return;
    }
    if (country === "" || country === undefined) {
      setLoading(false);
      setOpen(true);
      setMessage({ type: "error", content: "Country cannot be empty" });
      return;
    }
    if (state === "" || state === undefined) {
      setLoading(false);
      setOpen(true);
      setMessage({ type: "error", content: "State cannot be empty" });
      return;
    }
    if (city === "" || city === undefined) {
      setLoading(false);
      setOpen(true);
      setMessage({ type: "error", content: "City cannot be empty" });
      return;
    }
    let image;
    if (newfile) {
      image = newfile;
    } else {
      image = "";
    }
    let data = new FormData();
    data.append("id", parseInt(props.match.params.id));
    data.append("user_id", parseInt(localStorage.getItem("userId")));
    data.append("access_token", access_token);
    data.append("user_type", user_type);
    data.append("user_role", roles);
    data.append("name", name.trim);
    data.append("mobile", Mobilenumber);
    data.append("email", email);
    data.append("bvn_number", Bvnnumber);
    data.append("address", address);
    data.append("zip", zip);
    data.append("country_id", parseInt(country));
    data.append("state_id", parseInt(state));
    data.append("city", city);
    data.append("designation", designation);
    data.append("working_location_id", WorkingatBranchHO);
    data.append("image", image);

    const config = {
      method: "post",
      url: process.env.REACT_APP_URL + "/api/v1/user/update",
      headers: {
        "Content-Type": "text/plain",
      },
      data: data,
    };
    axios(config)
      .then((response) => {
        if (response && response.data) {
          if (response.data.success === true) {
            setPartnerId(response.data.partner_id);
            setContactId(response.data.user_id);
            setMessage(response.data.message);
            //return userDetail();
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const onSubmit = () => {
    const access_token = getAccessToken();
    let mobile_validate =
      /^(?:\+)?\s*[0][1-9]\d{9,12}$|^(?:\+)?\s*[1-9]\d{9,12}$/;

    let bvn_validate = /^[0][1-9]\d{10}$|^[1-9]\d{10}$/;
    if (
      name.trim() === "" ||
      name.trim() === undefined ||
      Mobilenumber === "" ||
      Mobilenumber === undefined ||
      email.trim() === "" ||
      email.trim() === undefined ||
      roles === "" ||
      roles === undefined ||
      designation.trim() === "" ||
      designation.trim() === undefined ||
      Bvnnumber.trim() === "" ||
      Bvnnumber.trim() === undefined ||
      WorkingatBranchHO === "" ||
      WorkingatBranchHO === undefined ||
      address.trim() === "" ||
      address.trim() === undefined ||
      zip.trim() === "" ||
      zip.trim() === undefined ||
      country === "" ||
      country === undefined ||
      state === "" ||
      state === undefined ||
      city.trim() === "" ||
      city.trim() === undefined
    ) {
      setLoading(false);
      setOpen(true);
      setMessage({ type: "error", content: "All fields are mandatory." });
      return;
    }
    if (!Mobilenumber.replace(/\s/g, "").match(mobile_validate)) {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "Mobile number should be 10 to 13 digit",
      });
      return;
    }
    if (!Bvnnumber.match(bvn_validate)) {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "BVN number should be 11 digit",
      });
      return;
    }
    var a = new Set([]);
    documents.map((item) => {
      if (item.is_required === true) {
        user_documents.map((x) => {
          x.doc_list.map((y) => {
            if (y.document_type_id === item.id) {
              a.add(y.document_type_id);
            }
          });
        });
      }
    });

    let array = Array.from(a);

    let req = [];
    documents.map((item) => {
      if (item.is_required === true) {
        req.push(item.id);
      }
    });

    if (
      Array.isArray(req) &&
      Array.isArray(array) &&
      req.length === array.length &&
      req.every((val, index) => val === array[index])
    ) {
    } else {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "Please upload all mandatory documents.",
      });
      return;
    }

    let image;
    if (newfile) {
      image = newfile;
    } else {
      image = "";
    }

    let data = new FormData();
    data.append("id", parseInt(props.match.params.id));
    data.append("user_id", parseInt(localStorage.getItem("userId")));
    data.append("access_token", access_token);
    data.append("user_type", user_type);
    data.append("user_role", roles);
    data.append("name", name.trim());
    data.append("mobile", Mobilenumber.trim());
    data.append("email", email.trim());
    data.append("bvn_number", Bvnnumber.trim());
    data.append("address", address.trim());
    data.append("zip", zip.trim());
    data.append("country_id", parseInt(country));
    data.append("state_id", parseInt(state));
    data.append("city", city.trim());
    data.append("designation", designation.trim());
    data.append("working_location_id", WorkingatBranchHO);
    data.append("image", image);

    setLoader(false);
    const config = {
      method: "post",
      url: process.env.REACT_APP_URL + "/api/v1/user/update",
      headers: {
        "Content-Type": "text/plain",
      },
      data: data,
    };
    axios(config)
      .then((response) => {
        if (response && response.data) {
          if (response.data.success === true) {
            setPartnerId(response.data.partner_id);
            setLoading(false);
            setOpen(true);
            setMessage({ type: "success", content: response.data.message });
            setTimeout(() => {
              history.push("/manageuser");
            }, 3000);
          } else {
            setLoading(false);
            setOpen(true);
            setMessage({ type: "error", content: response.data.message });
            setLoader(true);
          }
        } else {
          setLoader(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const generatedocumentID = (event, document_name, document_type_id) => {
    let mobile_validate =
      /^(?:\+)?\s*[0][1-9]\d{9,12}$|^(?:\+)?\s*[1-9]\d{9,12}$/;

    let bvn_validate = /^[0][1-9]\d{10}$|^[1-9]\d{10}$/;
    if (
      name.trim() === "" ||
      name.trim() === undefined ||
      Mobilenumber === "" ||
      Mobilenumber === undefined ||
      email.trim() === "" ||
      email.trim() === undefined ||
      roles === "" ||
      roles === undefined ||
      designation.trim() === "" ||
      designation.trim() === undefined ||
      Bvnnumber.trim() === "" ||
      Bvnnumber.trim() === undefined ||
      WorkingatBranchHO === "" ||
      WorkingatBranchHO === undefined ||
      address.trim() === "" ||
      address.trim() === undefined ||
      zip.trim() === "" ||
      zip.trim() === undefined ||
      country === "" ||
      country === undefined ||
      state === "" ||
      state === undefined ||
      city.trim() === "" ||
      city.trim() === undefined
    ) {
      setLoading(false);
      setOpen(true);
      setMessage({ type: "error", content: "All fields are mandatory." });
      return;
    }
    if (!Mobilenumber.replace(/\s/g, "").match(mobile_validate)) {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "Mobile number should be 10 to 13 digit",
      });
      return;
    }
    if (!Bvnnumber.match(bvn_validate)) {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "BVN number should be 11 digit",
      });
      return;
    }
    const access_token = getAccessToken();

    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: access_token,
      partner_id: parseInt(props.match.params.id),
      document_type_id: parseInt(document_type_id),
    };

    const config = {
      method: "post",
      url: process.env.REACT_APP_URL + "/api/v1/user/document/id",
      headers: {
        "Content-Type": "text/plain",
      },
      data: data,
    };
    axios(config)
      .then((response) => {
        if (response && response.data) {
          if (response.data.success === true) {
            setPartnerId(response.data.partner_id);
            setDocumentTypeID(response.data.document_type_id);
            setDocumentId(response.data.document_id);
            return documentUpload(
              event,
              document_name,
              document_type_id,
              response.data.document_id
            );
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const documentUpload = (
    event,
    document_name,
    document_type_id,
    document_id
  ) => {
    const access_token = getAccessToken();
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    let data = new FormData();
    data.append("user_id", parseInt(localStorage.getItem("userId")));
    data.append("access_token", access_token);
    data.append("partner_id", parseInt(partner_id));
    data.append("document_type_id", parseInt(document_type_id));
    data.append("document_id", parseInt(document_id ? document_id : 0));
    data.append("document_name", document_name);
    data.append("document_data", event.target.files[0]);

    const config = {
      method: "post",
      url: process.env.REACT_APP_URL + "/api/v1/user/document/upload",
      headers: {
        "Content-Type": "text/plain",
      },
      data: data,
    };
    axios(config)
      .then((response) => {
        if (response && response.data) {
          if (response.data.success === true) {
            setLoading(false);
            setOpen(true);
            setMessage({ type: "success", content: response.data.message });
            return userDetail();
          } else {
            setLoading(false);
            setOpen(true);
            setMessage({ type: "error", content: response.data.message });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const editUpload = (event, document_name, document_type_id, document_id) => {
    const access_token = getAccessToken();
    const reader = new FileReader();
    /*reader.addEventListener("load", () => {
            setfileView(reader.result);
        });*/
    setfile(event.target.files[0]);
    reader.readAsDataURL(event.target.files[0]);

    let data = new FormData();
    data.append("user_id", parseInt(localStorage.getItem("userId")));
    data.append("access_token", access_token);
    data.append("partner_id", parseInt(partner_id));
    data.append("document_type_id", parseInt(document_type_id));
    data.append("document_id", parseInt(document_id ? document_id : 0));
    data.append("document_name", document_name);
    data.append("document_data", event.target.files[0]);

    const config = {
      method: "post",
      url: process.env.REACT_APP_URL + "/api/v1/trader/document/upload",
      headers: {
        "Content-Type": "text/plain",
      },
      data: data,
    };
    axios(config)
      .then((response) => {
        if (response && response.data) {
          if (response.data.success === true) {
            setDocumentId(response.data.document_id);
            setLoading(false);
            setOpen(true);
            setMessage({ type: "success", content: response.data.message });
            return userDetail();
          } else {
            setLoading(false);
            setOpen(true);
            setMessage({ type: "error", content: response.data.message });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteUpload = (event, contact_id, document_id) => {
    const access_token = getAccessToken();
    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: access_token,
      partner_id: parseInt(partner_id),
      document_type_id: parseInt(document_type_id),
      document_id: parseInt(document_id),
    };
    const config = {
      method: "post",
      url: process.env.REACT_APP_URL + "/api/v1/user/document/delete",
      headers: {
        "Content-Type": "text/plain",
      },
      data: data,
    };
    axios(config)
      .then((response) => {
        if (response && response.data) {
          if (response.data.success === true) {
            setLoading(false);
            setOpen(true);
            setMessage({ type: "success", content: response.data.message });
            return userDetail();
          } else {
            setLoading(false);
            setOpen(true);
            setMessage({ type: "error", content: response.data.message });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const countryFunction = () => {
    const access_token = getAccessToken();
    const userID = localStorage.getItem("userId");

    const data = {
      user_id: localStorage.getItem("userId"),
      access_token: access_token,
    };
    axios
      .post(
        `${process.env.REACT_APP_URL}/api/v1/country/master`,
        JSON.stringify(data)
      )
      .then((response) => {
        if (response && response.data.sucess) {
          setCountryList(response.data.country);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const fetchState = (country_id, data1) => {
    const dataSet = JSON.stringify({
      country_id: country_id,
    });
    const config = {
      method: "post",
      url: process.env.REACT_APP_URL + "/api/v1/state/master",
      headers: {
        "Content-Type": "text/plain",
      },
      data: dataSet,
    };
    axios(config)
      .then((response) => {
        setStateList(response.data.state);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const fetchState1 = (country_id, data1, state) => {
    const dataSet = JSON.stringify({
      country_id: country_id,
    });
    const config = {
      method: "post",
      url: process.env.REACT_APP_URL + "/api/v1/state/master",
      headers: {
        "Content-Type": "text/plain",
      },
      data: dataSet,
    };
    axios(config)
      .then((response) => {
        if (data1 == "allstate1") {
          setStateList(response.data.state);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const removeUserImage = () => {
    setfileView2("");
    setfileView3("");
    const access_token = getAccessToken();

    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: access_token,
      company_id: 0,
      contact_id: parseInt(props.match.params.id),
    };

    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/image/delete`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        console.log("response", response);
        if (response.data.success) {
          setLoading(false);
          setOpen(true);
          setMessage({
            type: "success",
            content: response.data.message,
          });
        } else {
          setLoading(false);
          setOpen(true);
          setMessage({
            type: "error",
            content: response.data.message,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const userImageUpload = (event) => {
    let fileext = event.target.files[0];
    if (fileext.name.match(/\.(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/)) {
      if (fileext.size > 2000000) {
        setOpen(true);
        setMessage({
          type: "error",
          content: "Image size should be less than 2MB.",
        });
        return;
      }
      const access_token = getAccessToken();
      if (event.target.files && event.target.files[0]) {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setfileView2(reader.result);
        });
        setNewfile(event.target.files[0]);
        reader.readAsDataURL(event.target.files[0]);
        setfileView3("");
      }
    } else {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "Only jpg, jpeg, PNG, PDF gif files are allowed!",
      });
    }
  };
  // Download uploaded files
  const downloadDocument = (event, document_id) => {
    event.preventDefault();
    const access_token = getAccessToken();

    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: access_token,
      partner_id: parseInt(props.match.params.id),
      document_id: parseInt(document_id),
    };

    axios
      .post(
        `${process.env.REACT_APP_URL}/api/v1/user/document/download`,
        data,
        {
          headers: {
            "Content-Type": "text/plain",
          },
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          const api_url = process.env.REACT_APP_URL;
          let url = `${api_url + response.data.document_url}?${Date.now()}`;
          window.open(url, "_blank");
        } else {
          setLoading(false);
          setOpen(true);
          setMessage({ type: "error", content: "Something went wrong." });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // Send invitation to the user
  const sendInvitation = (event, email) => {
    event.preventDefault();
    const access_token = getAccessToken();
    setLoader(false);
    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      id: parseInt(contact_user_id ? contact_user_id : 0),
      access_token: access_token,
      email: email,
    };

    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/send/user/invitation`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        if (response.data.success === true) {
          setLoading(false);
          setOpen(true);
          setMessage({ type: "success", content: response.data.message });
          setTimeout(() => {
            history.push("/manageuser");
          }, 3000);
        } else {
          setLoading(false);
          setOpen(true);
          setMessage({ type: "error", content: response.data.message });
          setLoader(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleMobileNumber = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setMobileNumber(e.target.value);
    }
  };
  useEffect(() => {
    document.title = "Interswitch - Update User";
    window.scrollTo(0, 0);
    countryFunction();
    masterFunction();
    userDetail();
  }, []);

  return (
    <ThemeContainer type="private" value={1}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}>
        <SnackbarContent
          className={
            message.type === "error"
              ? "snack-error"
              : message.type === "success"
              ? "snack-success"
              : "snack-teal"
          }
          message={message.content}
        />
      </Snackbar>
      {loader !== true ? (
        <Grid container spacing={3} className="bootstrap-loader">
          <Spinner animation="grow" variant="spinner1" />
          <Spinner animation="grow" variant="spinner2" />
          <Spinner animation="grow" variant="spinner3" />
        </Grid>
      ) : (
        <>
          <Box className="scf-box">
            <Box className="scf-subbox">
              <Grid container>
                <Grid item xs={12} sm={12} md={12} xl={12}>
                  <Typography className="company-title11">Edit User</Typography>
                </Grid>
              </Grid>
              <Grid container className="form-style">
                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                  <center>
                    <Box className="image-box">
                      {fileview3 ? (
                        <img
                          className="img-thumb"
                          src={`${api_url + fileview3}?${Date.now()}`}
                        />
                      ) : (
                        <>
                          {fileview2 ? (
                            <img className="img-thumb" src={fileview2} />
                          ) : (
                            <img className="img-thumb" src={image} />
                          )}
                        </>
                      )}
                      {!fileview2 && !fileview3 && (
                        <Box className="img-action-option edit">
                          <center>
                            <label>
                              <EditOutlinedIcon className="img-edit" />
                              <input
                                type="file"
                                hidden
                                onChange={(e) => userImageUpload(e)}
                              />
                            </label>
                          </center>
                        </Box>
                      )}
                      {fileview3 || fileview2 ? (
                        <Box className="img-action-option delete">
                          <center>
                            <DeleteOutlineIcon
                              className="img-delete"
                              onClick={removeUserImage}
                            />
                          </center>
                        </Box>
                      ) : null}
                    </Box>
                  </center>
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                  <Typography className="img-heading">User Photo</Typography>
                  <Typography className="img-subheading">
                    In PNG, PDF JPG, GIF and SVG format size should be less then
                    2MB
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="image-box">
                    <span className="company-title3">
                      Full Name<span className="required">*</span>
                    </span>
                    <OutlinedInput
                      className="company-title5"
                      fullWidth
                      value={name}
                      placeholder="e.g. Full Name"
                      onChange={(e) => setFullName(e.target.value)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="image-box">
                    <span className="company-title3">
                      Mobile<span className="required">*</span>
                    </span>
                    <OutlinedInput
                      className="company-title5"
                      fullWidth
                      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                      value={Mobilenumber}
                      placeholder="e.g. Mobile Number"
                      onChange={(e) => handleMobileNumber(e)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="image-box">
                    <span className="company-title3">
                      Email Address<span className="required">*</span>
                    </span>
                    <OutlinedInput
                      className="company-title5"
                      fullWidth
                      readOnly
                      type="email"
                      value={email}
                      placeholder="e.g. Email"
                      //onChange={(e) => setEmail(e.target.value)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="image-box">
                    <span className="company-title3">
                      Roles<span className="required">*</span>
                    </span>
                    <FormControl fullWidth>
                      <Select
                        displayEmpty
                        value={roles}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Age"
                        onChange={(e) => setRoles(e.target.value)}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}>
                        <MenuItem value={""}> Select from options</MenuItem>
                        <MenuItem value="initiator">
                          Orgnization Admin (Initiator)
                        </MenuItem>
                        <MenuItem value="admin">
                          Orgnization Admin (Admin)
                        </MenuItem>
                        <MenuItem value="approver">Approver</MenuItem>
                        <MenuItem value="executive">Executive</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="image-box">
                    <span className="company-title3">
                      Designation<span className="required">*</span>
                    </span>
                    <OutlinedInput
                      className="company-title5"
                      fullWidth
                      value={designation !== false ? designation : ""}
                      placeholder="e.g. Designation"
                      onChange={(e) => setDesignation(e.target.value)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="image-box">
                    <span className="company-title3">
                      BVN Number<span className="required">*</span>
                    </span>
                    <OutlinedInput
                      className="company-title5"
                      fullWidth
                      value={Bvnnumber}
                      placeholder="e.g. BVN Number"
                      onChange={(e) => setBvnNumber(e.target.value)}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="image-box">
                    <span className="company-title3">
                      Working At Branch HO<span className="required">*</span>
                    </span>
                    <FormControl fullWidth>
                      <Select
                        displayEmpty
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Age"
                        value={WorkingatBranchHO}
                        onChange={(e) => setWorkingatBranchHO(e.target.value)}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}>
                        <MenuItem value={""}> Select from options</MenuItem>

                        {hqList !== "" ? (
                          hqList.map((item, index) => (
                            <MenuItem value={item.id} key={index}>
                              {item.name}
                            </MenuItem>
                          ))
                        ) : (
                          <></>
                        )}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="image-box">
                    <span className="company-title3">
                      Address<span className="required">*</span>
                    </span>
                    <OutlinedInput
                      className="company-title5"
                      fullWidth
                      value={address}
                      placeholder="e.g. Address"
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="image-box">
                    <span className="company-title3">
                      Zip/ Postal Code<span className="required">*</span>
                    </span>
                    <OutlinedInput
                      className="company-title5"
                      fullWidth
                      value={zip !== "False" ? zip : ""}
                      placeholder="e.g. Zip/ Postal Code"
                      onChange={(e) => setZip(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="image-box">
                    <span className="company-title3">
                      Country<span className="required">*</span>
                    </span>
                    <FormControl fullWidth>
                      <Select
                        displayEmpty
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Age"
                        value={country}
                        onChange={(e) => {
                          setCountry(e.target.value);
                          const data = e.currentTarget.getAttribute("name");
                          fetchState(e.target.value, "allstate1");
                        }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}>
                        <MenuItem value={""}> Select from options</MenuItem>

                        {countryList !== "" ? (
                          countryList.map((item, index1) => (
                            <MenuItem value={item.id} key={index1}>
                              {item.name}
                            </MenuItem>
                          ))
                        ) : (
                          <></>
                        )}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="image-box">
                    <span className="company-title3">
                      State/Provience<span className="required">*</span>
                    </span>
                    <FormControl fullWidth>
                      <Select
                        displayEmpty
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Age"
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}>
                        <MenuItem value="" disabled={true}>
                          Select from options
                        </MenuItem>
                        {stateList !== "" ? (
                          stateList.map((item, index2) => (
                            <MenuItem value={item.id} key={index2}>
                              {item.name}
                            </MenuItem>
                          ))
                        ) : (
                          <></>
                        )}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="image-box">
                    <span className="company-title3">
                      City<span className="required">*</span>
                    </span>
                    <OutlinedInput
                      className="company-title5"
                      fullWidth
                      value={city}
                      placeholder="e.g. City"
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Typography className="sub-heading">Upload Documents</Typography>
              <Box className="upload-documents">
                {user_documents &&
                  user_documents.map((doc_item, index3) => (
                    <Box key={index3}>
                      <Grid container spacing={2}>
                        <Grid item xs={6} sm={12} md={3} lg={3} xl={3}>
                          <center>
                            <h4 className="company-title3 documents-title">
                              {doc_item.doc_name}{" "}
                              {doc_item.is_required === true ? (
                                <span className="document-required">*</span>
                              ) : (
                                ""
                              )}
                            </h4>
                          </center>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                          <center>
                            <Box className="button">
                              <Button
                                component="label"
                                className="button-title">
                                Upload Document
                                <input
                                  type="file"
                                  hidden
                                  onChange={(e) =>
                                    generatedocumentID(
                                      e,
                                      doc_item.name,
                                      doc_item.doc_id
                                    )
                                  }
                                />
                              </Button>
                            </Box>
                          </center>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Grid container spacing={2}>
                            {doc_item.doc_list &&
                              doc_item.doc_list.map((user_item, index4) => (
                                <>
                                  {user_item.document_type_id ===
                                  doc_item.doc_id ? (
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={4}
                                      lg={4}
                                      xl={4}
                                      key={index4}>
                                      <center>
                                        <Box className="uploaded-documents-list image-box">
                                          {user_item.image_extension ===
                                          "application/pdf" ? (
                                            <embed
                                              src={`${
                                                api_url + user_item.document_url
                                              }?${Date.now()}`}
                                              className="image"
                                              type={user_item.image_extension}
                                              onClick={(e) =>
                                                downloadDocument(
                                                  e,
                                                  user_item.document_id
                                                )
                                              }
                                            />
                                          ) : (
                                            <img
                                              className="image"
                                              src={`${
                                                api_url + user_item.document_url
                                              }?${Date.now()}`}
                                              onClick={(e) =>
                                                downloadDocument(
                                                  e,
                                                  user_item.document_id
                                                )
                                              }
                                            />
                                          )}

                                          <Box className="img-action-option edit">
                                            <center>
                                              <Button
                                                component="label"
                                                className="edit-label">
                                                <EditOutlinedIcon className="img-edit" />
                                                <input
                                                  type="file"
                                                  hidden
                                                  onChange={(e) =>
                                                    editUpload(
                                                      e,
                                                      doc_item.name,
                                                      doc_item.doc_id,
                                                      user_item.document_id
                                                    )
                                                  }
                                                />
                                              </Button>
                                            </center>
                                          </Box>
                                          <Box className="img-action-option delete">
                                            <center>
                                              <DeleteOutlineIcon
                                                className="img-delete"
                                                onClick={(e) =>
                                                  deleteUpload(
                                                    e,
                                                    user_item.buyer_supplier_id,
                                                    user_item.document_id
                                                  )
                                                }
                                              />
                                            </center>
                                          </Box>
                                        </Box>
                                      </center>
                                    </Grid>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ))}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  ))}
              </Box>

              <Grid container spacing={3} className="button-grid">
                <Grid item xs={6} sm={4} md={2} xl={2}>
                  <Button
                    className="save"
                    onClick={onSubmit}
                    disabled={
                      !name ||
                      !Mobilenumber.replace(/\s/g, "") ||
                      !email ||
                      !roles ||
                      !designation ||
                      !Bvnnumber ||
                      !WorkingatBranchHO ||
                      !address ||
                      !zip ||
                      !country ||
                      !state ||
                      !city
                    }>
                    Save
                  </Button>
                </Grid>
                <Grid item xs={6} sm={4} md={2} xl={2}>
                  <Link to="/manageuser">
                    <Button className="cancel">Cancel</Button>
                  </Link>
                </Grid>
                {/*<Grid item xs={6} sm={4} md={2} xl={2}>
                  <Button className="invite" onClick={(e) => sendInvitation(e, email)}>Send Invitation</Button>
              </Grid>*/}
              </Grid>
            </Box>
          </Box>
        </>
      )}
    </ThemeContainer>
  );
}

EditUser.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(EditUser);
