import React from 'react';
import { Box, TableRow, TableCell, TableContainer, Table, TableHead, Paper, TableBody, Toolbar } from "@material-ui/core";


function createData(sno, invoiceNo, issueDate, DueDate, Buyer, amount, logistics, status, upload) {
    return { sno, invoiceNo, issueDate, DueDate, Buyer, amount, logistics, status, upload };
}
const rows = [
    createData(1, "2021-00001", "2021-06-20", "2021-07-01", "Thomson", "Oyo State Office, Nigeria", "P O", "Verified"),
    createData(2, "ABS LTD", 987654321, "purchase@absltd.io", "AMSDF87564A", "Oyo State Office , Nigeria", "P O", "Verified"),
];


export default function ManageInvoice() {
    return (
        <Box>
            <Toolbar />
            <TableContainer component={Paper}>
                <Table style={{ borderCollapse: 'separate', borderSpacing: '0px 20px' }} sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow style={{ background: "#F3F5F6", borderRadius: "4x" }}>
                            <TableCell>S. No. </TableCell>
                            <TableCell align="center">Name</TableCell>
                            <TableCell align="center">Mobile No</TableCell>
                            <TableCell align="center">Email</TableCell>
                            <TableCell align="center">Taxation ID</TableCell>
                            <TableCell align="center">Address</TableCell>
                            <TableCell align="center">Document</TableCell>
                            <TableCell align="center">Status</TableCell>
                            <TableCell align="center">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody >
                        {rows.length > 0 ? rows.map((row) => (
                            <TableRow
                                style={{ background: "#F3F5F6" }}
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.sno}
                                </TableCell>
                                <TableCell align="right">{row.name}</TableCell>
                                <TableCell align="right">{row.mobile}</TableCell>
                                <TableCell align="right">{row.email}</TableCell>
                                <TableCell align="right">{row.taxation}</TableCell>
                                <TableCell align="right">{row.address}</TableCell>
                                <TableCell align="right">{row.document}</TableCell>
                                <TableCell align="right">{row.status}</TableCell>
                            </TableRow>
                        ))
                            : <Box>
                                No Data to Display.
                            </Box>}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}