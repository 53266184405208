import React from "react";
import { Row, Col } from "react-bootstrap";
import Defaultimg from "../../../assets/img/previewButton.svg";

const Header = (props) => { 
  const api_url = process.env.REACT_APP_URL;

  let data = props.info;
  return (
    <>
      <Row>
        <Col md={3}>
          {data.image ? (
            <img src={`${api_url + data.image}?${Date.now()}`} height="100%" width="100%" alt="" />
          ) : (
            <img src={Defaultimg} alt="" className="cp-contact-img" />
          )}
        </Col>
        <Col md={9}>
          <div className="cp-heading3">
            {data.name ? data.name : "Company Name"}
          </div>
          <Row className="cp-sub1 my-2">
            <Col className="cp-sub2">
              {" "}
              {data.entity_name
                ? data.entity_name
                : data.company_entity_name
                ? data.company_entity_name
                : ""}
            </Col>
          </Row>
          <Row className="cp-sub1 my-2">
            <Col>
              <span>Registration Number - </span>
              <span className="cp-sub2">
                {data.registration_number
                  ? data.registration_number
                  : data.registration_no}
              </span>
            </Col>
          </Row>
          <Row className="cp-sub1 my-2">
            <Col>
              <span>Taxation Number - </span>
              <span className="cp-sub2">{data.tax_number}</span>
            </Col>
          </Row>
          <Row className="cp-sub1 my-2">
            <Col>
              {data.industry_id &&
                data.industry_id.length > 0 &&
                <span>Industry - </span>
              }
              {data.industry_id &&
                data.industry_id.length > 0 &&
                data.industry_id.map((info, industry_key) => (
                  <>
                    <span className="cp-sub2" key={industry_key}>
                      {info.name}
                    </span>
                  </>
                ))}
                {data.industry ? (
                  <>
                    <span className="cp-sub2">{data.industry[0].name}</span>
                  </>
                ) : (
                  ""
                )}
            </Col>
          </Row>
          {data && data?.default_domiciled_bank?.name &&
          <Row className="cp-sub1 my-2">
            <Col>
              <span>Default Domiciled Bank - </span>
              <span className="cp-sub2">{data.default_domiciled_bank.name}</span>
            </Col>
          </Row>
          }
        </Col>
      </Row>
    </>
  );
};

export default Header;
