import React from "react";
import { Card } from "@material-ui/core";
import { CardMedia, CardContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/components.js";
import { Typography } from "@material-ui/core";
import image from "../../../assets/img/noImage.svg";
import PropTypes from "prop-types";
import { isMobile } from "react-device-detect";

const useStyles = makeStyles(styles);

export default function DetailCard(props) {
  const classes = useStyles();
  
  const { img, title, details } = props;
  return (
    <Card className="our-presence-card">
      <div className="our-presence-div">
        <CardMedia
          image={img}
          className={classes.media +(" our-presence-cardmedia")}
          component="img"
        />
      </div>
      <div className="our-presence-content">
        <CardContent>
          <Typography className="our-presence-title">
            {title}
          </Typography>
          <Typography className="our-presence-details">
            {details}
          </Typography>
        </CardContent>
      </div>
    </Card>
  );
}

DetailCard.defaultProp = {
  img: image,
  title: "Add title here",
  details: "Add details here",
};

DetailCard.propTypes = {
  img: PropTypes.any,
  title: PropTypes.string,
  details: PropTypes.string,
};
