import React from 'react';
import { Grid, Box, Typography, OutlinedInput,withStyles, MenuItem, FormControl, Select, Table, TableBody, TableContainer, TableCell, TableHead, TableRow, Paper, Button } from "@material-ui/core";

import Custom from './Components/Custom';
import { isMobile } from 'react-device-detect'
import PropTypes from 'prop-types';


const styles = ()=> ({
    
    textFields: {
        background: "#F3F5F6",
        height: "16px",
        fontSize:"11px",
        marginTop: "1%",
        "& div": {
            // "& div": {

            // },
            "&::before": {
                content: "",
                borderBottom: "none",

            },
            "&::after": {
                borderBottom: "none"
            },
            "&:hover": {
                "&::before": {
                    borderBottom: "none !important"
                },
                "&::after": {
                    borderBottom: "none !important"
                },
            },
            "& .MuiInputBase-input": {
                padding: "9px 7px"
            }
        },

    },

})






function createData(invoice, transictdate, valuedate, type, currency,debit,credit,balance,borrower,buyer,logistic) {
    return {invoice, transictdate, valuedate, type, currency,debit,credit,balance,borrower,buyer,logistic };
}

const mystyles = {
    topFiltersInput: { paddingTop: "5%", paddingBottom: "5%", "borderRadius": "5px" },
    topFilterSelect: { paddingTop: "12%", paddingBottom: "5%", "borderRadius": "5px" },
    upload : { marginTop: "1%", fontSize: isMobile ? "12px" : "14px", fontWeight: isMobile ? "normal" : "600", color: "#0275D8", border: "1px solid #0275D8", width: "115%" },

}

const rows = [
    createData("INV/BTS/001", "10-6-2021", "10-6-2021", "Payment", "USD", "450,000", "0", "450,000", "Boston Trading Company", "Sony Inc", "Fedex Logistic"),
    createData("INV/BTS/001", "10-6-2021", "10-6-2021", "Payment", "USD", "450,000", "0", "450,000", "Boston Trading Company", "Sony Inc", "Fedex Logistic"),
    createData("INV/BTS/001", "10-6-2021", "10-6-2021", "Payment", "USD", "450,000", "0", "450,000", "Boston Trading Company", "Sony Inc", "Fedex Logistic"),
    createData("INV/BTS/001", "10-6-2021", "10-6-2021", "Payment", "USD", "450,000", "0", "450,000", "Boston Trading Company", "Sony Inc", " Fedex Logistic"),
    createData("INV/BTS/001", "10-6-2021", "10-6-2021", "Payment", "USD", "450,000", "0", "450,000", "Boston Trading Company", "Sony Inc", "Fedex Logistic"),
    createData("INV/BTS/001", "10-6-2021", "10-6-2021", "Payment", "USD", "450,000", "0", "450,000", "Boston Trading Company", "Sony Inc", "Fedex Logistic"),
    createData("INV/BTS/001", "10-6-2021", "10-6-2021", "Payment", "USD", "450,000", "0", "450,000", "Boston Trading Company", "Sony Inc", "Fedex Logistic"),
    createData("INV/BTS/001", "10-6-2021", "10-6-2021", "Payment", "USD", "450,000", "0", "450,000", "Boston Trading Company", "Sony Inc", "Fedex Logistic"),
];

function Soa(props) {
    const { classes } = props;
    return (
        <>

<Box style={{ padding:"3%",minHeight: "150vh", height: "100%", background: "#FFFFFF", paddingTop: "2%" }}>
                    
                        <Grid container>
                            <Grid item xs={8} sm={8} md={10} xl={10}>
                                <Typography style={{fontFamily:"Averta-Regular", fontSize:"24px", lineHeight:"36px", fontStyle:"normal",fontWeight:"bold",color:"#1A4983",marginTop:"3%"}}>View SOA for All</Typography>
                            </Grid>
                            
                        </Grid>

                        <Typography style={{ marginTop: "3%", color: "#0275D8", fontSize: "16px", fontWeight: "600" }}> Filters</Typography>


                       

                        <Grid container style={{ display:  "flex",marginTop:"3%", flexFlow:isMobile ? " " : "inherit", flexDirection: "row", justifyContent: "center", padding: "8px 12px" }}  spacing={2}>
                        <Grid item xs={6} sm={12} md={4} xl={4}>
                            <Box style={{ padding: "5%" }}>
                                    <span className="company-title-manages">Start Date<span style={{color:"red"}}>*</span></span>
                                    <OutlinedInput className="company-title5"
                                        fullWidth
                                        placeholder=" Start Date"
                                    />
                                
                                </Box>
                            </Grid>


                            <Grid item xs={6} sm={12} md={4} xl={4}>
                            <Box style={{ padding: "5%" }}>
                                    <span className="company-title3">End Date<span style={{color:"red"}}>*</span></span>
                                    <OutlinedInput className="company-title-manages"
                                        fullWidth
                                        placeholder=" End Date"
                                    />
                                </Box>
                            </Grid>


                            <Grid item xs={6} sm={12} md={4} xl={4}>
                            <Box style={{ padding: "5%" }}>
                                    <span className="company-title3">Invoice Number<span style={{color:"red"}}>*</span></span>
                                    <OutlinedInput className="company-title-manages"
                                        fullWidth
                                        placeholder=" Invoice Number"
                                    />
                            
                                </Box>
                            </Grid>


                            <Grid item xs={6} sm={12} md={4} xl={4}>
                            <Box style={{ padding: "5%",marginTop:"2%" }}>
                                    <Typography className="company-title3">Invoice Range<span style={{color:"red"}}>*</span></Typography>
                                    <FormControl fullWidth className="company-title-manages">

                                        <Select
                                            displayEmpty
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Age"
                                            renderValue={(select) => { if (!select) { return <Typography className="company-title-manages" >Select from options</Typography> } return select }}

                                        >
                                            <MenuItem value="" disabled>Select from options</MenuItem>
                                            <MenuItem value={10}>Ten</MenuItem>
                                            <MenuItem value={20}>Twenty</MenuItem>
                                            <MenuItem value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>


                            <Grid item xs={6} sm={12} md={4} xl={4}>
                            <Box style={{ padding: "5%" ,marginTop:"2%"}}>
                                    <Typography className="company-title3">Select Supplier<span style={{color:"red"}}>*</span></Typography>
                                    <FormControl fullWidth className="company-title-manages">

                                        <Select
                                            displayEmpty
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Age"
                                            renderValue={(select) => { if (!select) { return <Typography className="company-title-manages" >Select from options</Typography> } return select }}

                                        >
                                            <MenuItem value="" disabled>Select from options</MenuItem>
                                            <MenuItem value={10}>Ten</MenuItem>
                                            <MenuItem value={20}>Twenty</MenuItem>
                                            <MenuItem value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>


                            </Grid>

                            <Grid item xs={6} sm={12} md={4} xl={4}>
                            <Box style={{ padding: "5%" ,marginTop:"2%"}}>
                                    <Typography className="company-title3">Select Buyer<span style={{color:"red"}}>*</span></Typography>
                                    <FormControl fullWidth className="company-title-manages">

                                        <Select
                                            displayEmpty
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Age"
                                            renderValue={(select) => { if (!select) { return <Typography className="company-title-manages" >Select from options</Typography> } return select }}

                                        >
                                            <MenuItem value="" disabled>Select from options</MenuItem>
                                            <MenuItem value={10}>Ten</MenuItem>
                                            <MenuItem value={20}>Twenty</MenuItem>
                                            <MenuItem value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>

                            </Grid>

                            <Grid item xs={6} sm={12} md={4} xl={4}>
                            <Box style={{ padding: "5%" ,marginTop:"2%"}}>
                                    <Typography className="company-title3">Select Status<span style={{color:"red"}}>*</span></Typography>
                                    <FormControl fullWidth className="company-title-manages">

                                        <Select
                                            displayEmpty
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Age"
                                            renderValue={(select) => { if (!select) { return <Typography className="company-title-manages" >Select from options</Typography> } return select }}

                                        >
                                            <MenuItem value="" disabled>Select from options</MenuItem>
                                            <MenuItem value={10}>Ten</MenuItem>
                                            <MenuItem value={20}>Twenty</MenuItem>
                                            <MenuItem value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>


                            </Grid>

                            <Grid item xs={6} sm={12} md={4} xl={4}>
                                <Box style={{ paddingTop: isMobile ? " 18%" :  "20%", paddingBottom: "5%", paddingRight: "10%", }}>

                                    <Button style={{ "alignItems": "center", "width": "90px", "height": "32px", "background": "#479FC8", "borderRadius": "5px" }} >Submit</Button>

                                </Box>
                            </Grid>
                            

                        </Grid>
                      
                        <Box style={{paddingTop:"2%"}}>
                            <TableContainer component={Paper}>
                                <Table style={{ padding: "27px", borderCollapse: 'separate', borderSpacing: '0px 5px'}} sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow style={{ background: "#F3F5F6", borderRadius: "4x" }}>
                                           
                                            <TableCell align="left">Invoice </TableCell>
                                            <TableCell align="left">Tansict Date </TableCell>
                                            <TableCell align="left">Value Date</TableCell>
                                            <TableCell align="left"> Currency</TableCell>
                                            <TableCell align="left">Debit</TableCell>
                                            <TableCell align="left">Credit</TableCell>
                                            <TableCell align="left">Balance</TableCell>
                                            <TableCell align="left">Borrower</TableCell>
                                            <TableCell className="left">Buyer</TableCell>
                                            <TableCell align="left">Logistic</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody >
                                        {rows.map((row) => (
                                            <TableRow
                                                style={{ background: "#F3F5F6" }}
                                                key={row.name}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                
                                                <TableCell align="left">{row.invoice}</TableCell>
                                                <TableCell align="left">{row.transictdate}</TableCell>
                                                <TableCell align="left">{row.valuedate}</TableCell>
                                                <TableCell align="left">{row.currency}</TableCell>
                                                <TableCell align="left">{row.debit}</TableCell>
                                                <TableCell align="left">{row.credit}</TableCell>
                                                <TableCell align="left">{row.balance}</TableCell>
                                                <TableCell align="left">{row.borrower}</TableCell>
                                                <TableCell align="left">{row.buyer}</TableCell>
                                         <TableCell align="left">{row.logistic}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>


                          
                        </Box>
                        <Grid container>
                        <Grid item xs={6} sm={6} md={2} xl={2}>
                                <Box style={{ padding: "5%" }}>
                                    <Typography className="company-title3">Select on Page display limit<span style={{color:"red"}}>*</span></Typography>
                                    <FormControl fullWidth className={classes.textFields}>

                                        <Select
                                            displayEmpty
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Age"
                                            onChange={(e) => setSupplier(e.target.value)}
                                            renderValue={(select) => { if (!select) { return <Typography className="company-title4" >Select from options</Typography> } return select }}

                                        >
                                            <MenuItem value="" disabled>Select from options</MenuItem>
                                            <MenuItem value={10}>Ten</MenuItem>
                                            <MenuItem value={20}>Twenty</MenuItem>
                                            <MenuItem value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                            </Grid>


                </Box>



        </>
    )
}

Soa.propTypes = {
    classes: PropTypes.object
}


export default withStyles(styles)(Soa);