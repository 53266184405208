import React, { useState,useEffect } from 'react';
import { Link, useHistory } from "react-router-dom"
import { 
    Grid, 
    OutlinedInput, 
    MenuItem, 
    Box, 
    withStyles, 
    Typography, 
    FormControl, 
    Select, 
    Button, 
    Table, 
    TableBody, 
    TableContainer, 
    TableCell, 
    TableHead, 
    TableRow, 
    Paper,
    IconButton
} from "@material-ui/core";
import PropTypes from 'prop-types';
import Custom from "views/Components/Custom";
import ThemeContainer from 'views/Components/sideBar/ThemeContainer';
import TableComponent from 'views/Components/tableComponent/tablecomponent';
import { isMobile } from 'react-device-detect';
import { getAccessToken } from "../../utilities/tokenUtil";
import { setAccessToken } from "utilities/tokenUtil";
import image11 from "assets/img/write(2).png";
import image2 from "assets/img/right(1).png";
import image3 from "assets/img/delete(3).png";
import image4 from "assets/img/eyes.png";
import TextField from "@mui/material/TextField";
import { Spinner } from "react-bootstrap";
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@mui/material/Tooltip';

var axios = require("axios");

const styles = theme => ({
    textFields: {
        background: "#F3F5F6",
        height: "40px",
        marginTop: "1%",
        "& div": {
            "&::before": {
                content: "",
                borderBottom: "none",

            },
            "&::after": {
                borderBottom: "none"
            },
            "&:hover": {
                "&::before": {
                    borderBottom: "none !important"
                },
                "&::after": {
                    borderBottom: "none !important"
                },
            },
            "& .MuiInputBase-input": {
                padding: "9px 7px"
            }
        },

    },

})

function CreditOffer(props) {

    const userId            = localStorage.getItem("userId");
    const userName          = localStorage.getItem("userName");
    const kyc_status        = localStorage.getItem("kyc_status");
    const trader_type       = localStorage.getItem("trader_type");
    const currency_symbol   = localStorage.getItem("currency_symbol");

    const history = useHistory();
    const [start_date, setStartDate]    = useState("")
    const [end_date, setEndDate]        = useState("")
    const [lender, setLender]           = useState("")
    const [percentage, setPercentage]   = useState("")
    const [status, setStatus]           = useState("")
    const [creditList, setCreditList]   = useState([]);
    const [lenderName, setLenderName]   = useState("");
    const [lenderList, setLenderList]   = useState([]);
    const [loader, setLoader]           = useState(false);

    if(!userId || userName === null){
        history.push('/signin');
    }
    if(kyc_status !== "Approved"){
        history.push('/kyc-page');
    }
    if(trader_type !== "supplier"){
        history.push('/invoice');
    }

    const creditMaster=()=>{
        const access_token = getAccessToken();       
        setLoader(false)
        const data = {
            "access_token":access_token, 
            "user_id": parseInt(localStorage.getItem("userId"))
          };

        axios
          .post(`${process.env.REACT_APP_URL}/api/v1/credit/master`,data, {
            headers: {
              "Content-Type": "text/plain",
            },
          })
          .then((response) => {
            setLenderList(response.data.lender)    
            setLoader(true)      
          })
          .catch((error) => {
            console.log(error);
          });
    }
    const allCreditList=()=>{
        const access_token = getAccessToken();       
        //setLoader(false)
        const data = {
            "access_token":access_token, 
            "user_id": parseInt(localStorage.getItem("userId")),
            "credit_request_id": parseInt(props.match.params.id)
          };

        axios
          .post(`${process.env.REACT_APP_URL}/api/v1/credit/offer/list`,data, {
            headers: {
              "Content-Type": "text/plain",
            },
          })
          .then((response) => {
            setCreditList(response.data.credit_offer_list)    
            setLoader(true)      
          })
          .catch((error) => {
            console.log(error);
          });
    }
    const filter=()=>{
        let search_value;
        if(status === "all"){
              search_value = "";
          }else{
              search_value = status;
          }
        setLoader(false)
        setCreditList("")
        const access_token = getAccessToken();    
        const data = {
            "access_token":access_token, 
            "user_id": parseInt(localStorage.getItem("userId")), 
            "credit_request_id": parseInt(props.match.params.id), 
            "lender_name":lenderName,
            "status": search_value
        };

        axios
          .post(`${process.env.REACT_APP_URL}/api/v1/credit/offer/search`,data, {
            headers: {
              "Content-Type": "text/plain",
            },
          })
          .then((response) => {           
            setCreditList(response.data.credit_offer_list)
            setLoader(true)
          })
          .catch((error) => {
            console.log(error);
          });
    }
    const clearSearch=()=>{
        setStatus("");
        setLenderName("");
        //setCreditList("")
        allCreditList();
    }
    // Get Lender's name
    let lendersName = (arrayData)=> {
        if (arrayData.length === 0) {
            return '';
        }
        let commaSepratedElement = [];
        arrayData.map((item)=>{
            commaSepratedElement.push(item.name);
        });
        return commaSepratedElement.join(', ');
    }
    useEffect(() => {
        document.title = 'Interswitch - Supplier Credit Offer';
        creditMaster();
        allCreditList();
    }, []);
    const { classes } = props;

    return (
        <ThemeContainer type="private" value={2}>
          {loader !== true ? (
            <Grid container spacing={3} className="bootstrap-loader">              
                <Spinner animation="grow" variant="spinner1" />
                <Spinner animation="grow" variant="spinner2" />
                <Spinner animation="grow" variant="spinner3" />              
            </Grid>
          ) : (
          <>
            <Box className="scf-box">
                <Box className="scf-subbox">                   
                    
                    <Box>
                        <Grid container>
                            <Grid item xs={9} sm={12} md={11} xl={11}>
                                <Typography className="listing-main-title">Supplier Credit Offer</Typography>
                            </Grid>
                            <Grid item xs={3} sm={6} md={1} xl={1}>
                                <Button className="back-button" onClick={() => history.push("/credit")} ><i className="fas fa-long-arrow-alt-left" ></i> Back
                                </Button>
                            </Grid>
                        </Grid>
                        <Typography className="company-title2">Filters</Typography>
                        
                        <Grid container spacing={2}>                                    
                            <Grid item xs={6} sm={6} md={4} xl={4}>
                                <Box>
                                    <span className="company-title3">Lender Name<span className="required">*</span></span>
                                    <FormControl fullWidth>
                                    <OutlinedInput className="company-title5"
                                        fullWidth
                                        value={lenderName}
                                        placeholder="Lender name"
                                        onChange={(e)=>setLenderName(e.target.value)}
                                    />
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={6} sm={6} md={4} xl={4}>
                                <Box>
                                    <span className="company-title3">Select Status<span className="required">*</span></span>
                                    <FormControl fullWidth>
                                        <Select
                                        displayEmpty
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Age"
                                        value={status}
                                        onChange={(e) => setStatus(e.target.value)}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left",
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left",
                                            },
                                            getContentAnchorEl: null,
                                        }}
                                        >

                                            <MenuItem value="" disabled>Select from options</MenuItem>
                                            <MenuItem value="all">All</MenuItem>
                                            <MenuItem value="approved">Approved</MenuItem>
                                            <MenuItem value="rejected">Rejected</MenuItem>
                                            <MenuItem value="waiting_for_approval">Awaiting Approval</MenuItem>
                                        </Select>
                                        
                                    </FormControl>
                                </Box>
                            </Grid>
                            
                            <Grid item xs={6} sm={6} md={4} xl={4}>
                                <span className="company-title3">&nbsp;</span>
                                <Box className="filter-action">
                                    <Button className="submit-button" onClick={filter}>Submit</Button>
                                    <Button className="cancel-button" onClick={clearSearch}>Clear</Button>
                                </Box>
                            </Grid>
                        </Grid>
                        <Typography className="list-sub-title">Supplier Credit Offer List</Typography>
                    </Box>
                    <Box>
                        <TableContainer component={Paper}>
                            <Table className="table-top" aria-label="simple table">
                                
                                <TableRow className="table-row">
                                    <TableCell className="table-header">S. No. </TableCell>
                                    <TableCell align="left" className="table-header">Lender Name</TableCell>
                                    <TableCell align="left" className="table-header">Lender Email</TableCell>
                                    <TableCell align="left" className="table-header">Expected Interest Rate (%)</TableCell>
                                    <TableCell align="left" className="table-header">Limit Required ({currency_symbol})</TableCell>
                                    <TableCell align="left" className="table-header">Limit Approved ({currency_symbol})</TableCell>
                                    <TableCell align="left" className="table-header">Status</TableCell>
                                </TableRow>
                                
                                <TableBody >                                            
                                    {creditList.length > 0 ? (
                                        <>
                                        {creditList ? creditList.map((row, index) => 
                                            <TableRow className="table-row" key={row.name} >
                                                <TableCell component="th" scope="row" onClick={() => history.push("/credit-offer-detail/"+(props.match.params.id)+"/"+(row.supplier_credit_lender_id))}> {index+1} </TableCell>
                                                <TableCell align="left" onClick={() => history.push("/credit-offer-detail/"+(props.match.params.id)+"/"+(row.supplier_credit_lender_id))}> {row.lender_name} </TableCell>
                                                <TableCell align="left" onClick={() => history.push("/credit-offer-detail/"+(props.match.params.id)+"/"+(row.supplier_credit_lender_id))}> {row.email} </TableCell>
                                                <TableCell align="left" onClick={() => history.push("/credit-offer-detail/"+(props.match.params.id)+"/"+(row.supplier_credit_lender_id))}> {row.expected_interest_rate} </TableCell>
                                                <TableCell align="left" onClick={() => history.push("/credit-offer-detail/"+(props.match.params.id)+"/"+(row.supplier_credit_lender_id))}> {row.limit_required.toLocaleString(undefined, {minimumFractionDigits: 2})} </TableCell>
                                                <TableCell align="left" onClick={() => history.push("/credit-offer-detail/"+(props.match.params.id)+"/"+(row.supplier_credit_lender_id))}> {row.limit.toLocaleString(undefined, {minimumFractionDigits: 2})} </TableCell>
                                                <TableCell align="left" onClick={() => history.push("/credit-offer-detail/"+(props.match.params.id)+"/"+(row.supplier_credit_lender_id))} className={row.status.toLowerCase().replace(/\s/g, '-')}> 
                                                    {row.status} {" "}
                                                    {row.rejection_reason &&
                                                        <Tooltip title={row.rejection_reason} placement="top" arrow>
                                                            <InfoIcon />
                                                        </Tooltip>
                                                    }
                                                </TableCell>                                                       
                                            </TableRow>
                                        ):null}
                                        </>
                                    ) : (
                                        <TableRow >
                                            <TableCell colSpan={7} className="nodata">
                                                No Data to Display
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                                  
                </Box>
            </Box>
            </>
          )}
        </ThemeContainer>
    );
}

CreditOffer.propTypes = {
    classes: PropTypes.object
}

export default withStyles(styles)(CreditOffer)