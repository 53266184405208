import React from "react";
import { Row, Col } from "react-bootstrap";

const Card = (props) => {
  let data = props.info;
  let name = props.name;
  let company = props.company ? props.company : "";
  let background = props.color;
  return (
    <div className="my-5">
      {data && data.length > 0 ? (
        data.map((info, index) => (
          <div key={index}>
            <div
              className="cp-card p-4" style={{ backgroundColor: background }}
            >
              <Row className="cp-sub3 mb-4">
                <Col>{name}</Col>
              </Row>
              {info.email || info.mobile || info.phone_number ? (
              <Row className="my-3">
                {info.email &&
                <Col md={6} className="cp-sub4">
                  {" "}
                  <span>Email Address - </span>
                  <span className="cp-sub5">{info.email}</span>
                </Col>
                }
                {info.mobile &&
                <Col md={6} className="cp-sub4">
                  <span>Mobile Number - </span>
                  <span className="cp-sub5">
                    {info.mobile &&
                        info.mobile
                    }
                  </span>
                </Col>
                }
                {info.phone_number &&
                <Col md={6} className="cp-sub4">
                  <span>Phone Number - </span>
                  <span className="cp-sub5">
                    {info.phone_number &&
                      info.phone_number
                    }
                  </span>
                </Col>
                }
              </Row>
              ):null}
              <Row className="my-3">
                <Col className="cp-sub4">
                  {" "}
                  <span>Address - </span>
                  <span className="cp-sub5">
                    {" "}
                    {info.address &&
                        info.address
                    }
                    {info.street2 &&
                        ", " + info.street2
                    }
                    {info.city &&
                        ", " + info.city
                    }
                    {info.state_name &&
                        ", " + info.state_name 
                    }
                    {info.country_name &&
                        ", " + info.country_name
                    }
                    {info.zip &&
                        " - " + info.zip
                    }
                  </span>
                </Col>
              </Row>
            </div>
          </div>
        ))
      ) : (
        <>
          <div className="cp-card p-4" style={{ backgroundColor: background }}>
            <Row className="cp-sub3 mb-4">
              <Col>{name}</Col>
            </Row>
            {company && company !== "" && (
              <>
                {info.email &&
                <Row className="my-3">
                  <Col md={6} className="cp-sub4">
                    {" "}
                    <span>Email Address - </span>
                    <span className="cp-sub5">{info.email}</span>
                  </Col>
                </Row>
                }
                {info.mobile &&
                <Row>
                  <Col md={6} className="cp-sub4">
                    <span>Mobile Number - </span>
                    <span className="cp-sub5">
                      {info.mobile &&
                          info.mobile
                      }
                    </span>
                  </Col>
                </Row>
                }
                {info.phone_number &&
                <Row>
                  <Col md={6} className="cp-sub4">
                    <span>Phone Number - </span>
                    <span className="cp-sub5">
                      {info.phone_number &&
                        info.phone_number
                      }
                    </span>
                  </Col>
                </Row>
                }
              </>
            )}
            <Row className="my-3">
              <Col className="cp-sub4">
                {" "}
                <span>Address - </span>
                <span className="cp-sub5">
                  {" "}
                  {data.address &&
                      data.address
                  }
                  {data.street2 &&
                      ", " + data.street2
                  }
                  {data.city &&
                      ", " + data.city
                  }
                  {data.state_name &&
                      ", " + data.state_name 
                  }
                  {data.country_name &&
                      ", " + data.country_name
                  }
                  {data.zip &&
                      " - " + data.zip
                  }
                </span>
              </Col>
            </Row>
          </div>
        </>
      )}
    </div>
  );
};

export default Card;
