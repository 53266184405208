import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Grid,
  OutlinedInput,
  MenuItem,
  Box,
  withStyles,
  InputLabel,
  Typography,
  FormControl,
  Select,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Snackbar,
  SnackbarContent,
  Chip,
  Checkbox,
} from "@material-ui/core";
import PropTypes from "prop-types";
import Custom from "views/Components/Custom";
import ThemeContainer from "views/Components/sideBar/ThemeContainer";
import TableComponent from "views/Components/tableComponent/tablecomponent";
import { isMobile } from "react-device-detect";
import { getAccessToken } from "../../../utilities/tokenUtil";
import { setAccessToken } from "utilities/tokenUtil";
import image from "../../../assets/img/previewButton.svg";
import TextField from "@mui/material/TextField";
import { Spinner } from "react-bootstrap";
import ListItemText from "@mui/material/ListItemText";
import { useTheme } from "@mui/material/styles";
import Stack from "@mui/material/Stack";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import NumberFormat from "react-number-format";

var axios = require("axios");

const styles = (theme) => ({
  textFields: {
    background: "#F3F5F6",
    height: "40px",
    marginTop: "1%",
    "& div": {
      "&::before": {
        content: "",
        borderBottom: "none",
      },
      "&::after": {
        borderBottom: "none",
      },
      "&:hover": {
        "&::before": {
          borderBottom: "none !important",
        },
        "&::after": {
          borderBottom: "none !important",
        },
      },
      "& .MuiInputBase-input": {
        padding: "9px 7px",
      },
    },
  },
});

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function AddCreditRequest(props) {
  const theme = useTheme();
  const userId = localStorage.getItem("userId");
  const userName = localStorage.getItem("userName");
  const kyc_status = localStorage.getItem("kyc_status");
  const trader_type = localStorage.getItem("trader_type");
  const currency_symbol = localStorage.getItem("currency_symbol");

  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [start, setStart] = useState("");
  const [name, setName] = useState("");
  //const [limit_required, setLimitRequired]    = useState("")
  const [expected_interest_rate, setExpectedInterest] = useState("");
  const [lender_id, setLenderId] = useState("");
  const [buyerList, setbuyerList] = useState([]);
  const [supplierList, setsupplierList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState({});
  const [open, setOpen] = useState(false);
  const [lenderList, setLenderList] = useState([]);
  const [lenderIds, setlenderIds] = useState([]);
  console.log("lenderIds", lenderIds);
  const [start_date, setStartDate] = useState("");
  const [end_date, setEndDate] = useState("");
  // If not logged in redirect to login page
  if (!userId || userName === null) {
    history.push("/signin");
  }
  // If kyc is not approved redirect to kyc page
  if (kyc_status !== "Approved") {
    history.push("/kyc-page");
  }
  // If trader type is not supplier, redirect to invoice
  if (trader_type !== "supplier") {
    history.push("/invoice");
  }

  const creditMaster = () => {
    const access_token = getAccessToken();

    const data = {
      access_token: access_token,
      user_id: parseInt(localStorage.getItem("userId")),
    };

    axios
      .post(
        `${process.env.REACT_APP_URL}/api/v1/supplier/lender/master`,
        data,
        {
          headers: {
            "Content-Type": "text/plain",
          },
        }
      )
      .then((response) => {
        setLenderList(response.data.data);
        setLoader(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSubmit = () => {
    const access_token = getAccessToken();

    var lenderNames = lenderList.map(function (el) {
      return el.name;
    });

    const lender_ids = [];
    lenderIds.forEach((name) => {
      var i = lenderNames.indexOf(name);
      if (i >= 0) {
        lender_ids.push(lenderList[i].id);
      }
    });

    if (
      lenderIds === "" ||
      lenderIds === undefined ||
      expected_interest_rate === "" ||
      expected_interest_rate === undefined ||
      start_date === "" ||
      start_date === undefined
    ) {
      setLoading(false);
      setOpen(true);
      setMessage({ type: "error", content: "All fields are mandatory" });
      return;
    }

    //let limitRequired = limit_required.toString().replace(/,/g, '');
    let expectedInterestRate = expected_interest_rate
      .toString()
      .replace(/,/g, "");

    if (expectedInterestRate < 0) {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "Interest rate not allowed less than 0.",
      });
      return;
    }
    if (expectedInterestRate > 100) {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "Interest rate not allowed more than 100.",
      });
      return;
    }
    let today = new Date().toISOString().slice(0, 10);
    if (start_date < today) {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "Please select start date greater or equal current date.",
      });
      return;
    }

    setLoader(false);
    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: access_token,
      id: parseInt(0),
      //limit_required: limitRequired,
      expected_interest_rate: expectedInterestRate,
      lender_ids: lender_ids,
      start_date: start_date,
    };

    const config = {
      method: "post",
      url: process.env.REACT_APP_URL + "/api/v1/credit/request/create",
      headers: {
        "Content-Type": "text/plain",
      },
      data: data,
    };
    axios(config)
      .then((response) => {
        if (response && response.data) {
          if (response.data.success === true) {
            setLoading(false);
            setOpen(true);
            setMessage({ type: "success", content: response.data.message });
            setTimeout(() => {
              history.push("/credit");
            }, 3000);
          } else {
            setLoading(false);
            setOpen(true);
            setMessage({ type: "error", content: response.data.message });
            setLoader(true);
            setTimeout(() => {
              history.push("/credit");
            }, 3000);
          }
        } else {
          setLoader(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const startDateChange = (newValue) => {
    var date = new Date(newValue);
    var finaldate =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    setStartDate(finaldate);
  };
  const handleInterestRate = (e) => {
    const re = /^(\d+(\.\d{0,2})|\.?\d{1,3})$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setExpectedInterest(e.target.value);
    }
  };
  /*const handleLimitRequired = (e) => {
      const re = /^(\d+(\.\d{0,2})|\.?\d{1,9})$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        setLimitRequired(e.target.value);
      }
    };*/
  /*const formatLimitRequired = (event) => {
        event.preventDefault();
        let limitRequired = limit_required.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        setLimitRequired(limitRequired);
    } */
  useEffect(() => {
    document.title = "Interswitch - Add Supplier Credit Request";
    creditMaster();
  }, []);
  const { classes } = props;

  return (
    <>
      <ThemeContainer type="private" value={2}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}>
          <SnackbarContent
            className={
              message.type === "error"
                ? "snack-error"
                : message.type === "success"
                ? "snack-success"
                : "snack-teal"
            }
            message={message.content}
          />
        </Snackbar>

        {loader !== true ? (
          <Grid container spacing={3} className="bootstrap-loader">
            <Spinner animation="grow" variant="spinner1" />
            <Spinner animation="grow" variant="spinner2" />
            <Spinner animation="grow" variant="spinner3" />
          </Grid>
        ) : (
          <>
            <Box className="scf-box credit-request">
              <Box className="scf-subbox">
                <Grid container>
                  <Grid item xs={12} sm={12} md={12} xl={12}>
                    <Typography className="company-title11">
                      Add Supplier Credit Request
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={4} xl={4}>
                    <Box>
                      <span className="company-title3">
                        Lender<span className="required">*</span>
                      </span>
                      <FormControl
                        fullWidth
                        className="multiple-lenders select-placeholder">
                        <InputLabel id="demo-multiple-chip-label">
                          Please Select Option
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-chip-label"
                          id="demo-multiple-chip"
                          multiple
                          value={lenderIds}
                          onChange={(e) => setlenderIds(e.target.value)}
                          input={
                            <OutlinedInput
                              id="select-multiple-chip"
                              label="Chip"
                            />
                          }
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}>
                              {selected.map((value) => (
                                <Chip key={value} label={value} />
                              ))}
                            </Box>
                          )}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}>
                          {lenderList !== undefined &&
                            lenderList.map((item, index) => {
                              return (
                                <MenuItem
                                  key={index}
                                  value={item.name}
                                  style={getStyles(item, lenderIds, theme)}>
                                  <Checkbox
                                    checked={lenderIds.indexOf(item.name) > -1}
                                    color="primary"
                                  />
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  {/*<Grid item xs={12} sm={12} md={4} xl={4}>
                                <Box>
                                    <span className="company-title3">Limit Required ({currency_symbol})<span className="required">*</span></span>
                                    <span className="MuiInputBase-root MuiOutlinedInput-root company-title5 MuiInputBase-fullWidth">
                                        <NumberFormat
                                            value={limit_required}
                                            thousandSeparator={true}
                                            thousandsGroupStyle="thousand"
                                            allowNegative={false}
                                            decimalSeparator="."
                                            decimalScale={2}
                                            className="MuiInputBase-input MuiOutlinedInput-input"
                                            fixedDecimalScale={true}
                                            onChange={(e) => setLimitRequired(e.target.value)}
                                        />
                                    </span>
                                </Box>
                            </Grid>*/}
                  <Grid item xs={12} sm={12} md={4} xl={4}>
                    <Box>
                      <span className="company-title3">
                        Expected Interest Rate(%)
                        <span className="required">*</span>
                      </span>
                      {/*<OutlinedInput
                                        fullWidth
                                        placeholder="Enter Expected Interest Rate"
                                        onChange={(e) => handleInterestRate(e)}
                                        value={expected_interest_rate}
                                    />*/}
                      <span className="MuiInputBase-root MuiOutlinedInput-root company-title5 MuiInputBase-fullWidth">
                        <NumberFormat
                          value={expected_interest_rate}
                          thousandSeparator={true}
                          thousandsGroupStyle="thousand"
                          allowNegative={false}
                          decimalSeparator="."
                          decimalScale={2}
                          className="MuiInputBase-input MuiOutlinedInput-input"
                          placeholder="Enter Expected Interest Rate"
                          fixedDecimalScale={true}
                          onChange={(e) => setExpectedInterest(e.target.value)}
                        />
                      </span>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} xl={4}>
                    <Box>
                      <span className="company-title3">
                        Start Date<span className="required">*</span>
                      </span>

                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack spacing={3}>
                          <DesktopDatePicker
                            inputFormat="MM/dd/yyyy"
                            value={start_date}
                            minDate={new Date()}
                            onChange={startDateChange}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </Box>
                  </Grid>

                  <Grid container spacing={3} className="button-grid">
                    <Grid item xs={6} sm={4} md={2} xl={2}>
                      <Button
                        className="save"
                        onClick={onSubmit}
                        disabled={
                          lenderIds.length == 0 ||
                          !expected_interest_rate ||
                          !start_date
                        }>
                        Save
                      </Button>
                    </Grid>
                    <Grid item xs={6} sm={4} md={2} xl={2}>
                      <Link to="/credit">
                        <Button className="cancel">Cancel</Button>
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </>
        )}
      </ThemeContainer>
    </>
  );
}

AddCreditRequest.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(AddCreditRequest);
