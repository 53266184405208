import React, { useState, useEffect } from "react";
import {
  IconButton,
  Snackbar,
  SnackbarContent,
  Modal,
  Button,
  Box,
  Grid,
} from "@material-ui/core";
import View from "assets/img/view.svg";
import Updatestatus from "assets/img/updatestatus.svg";
import Addedit from "assets/img/add-edit.svg";
import Delete from "assets/img/delete.svg";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";
import { getAccessToken } from "../../utilities/tokenUtil";
import Tooltip from "@mui/material/Tooltip";
var axios = require("axios");
import { makeStyles } from "@material-ui/core/styles";
import CancelIcon from "@material-ui/icons/HighlightOffOutlined";
import WebRedirectPaymentMethod from "./webRedirectPaymentMethod";

function getModalStyle() {
  return {
    top: `50%`,
    left: `0`,
    right: `0`,
    margin: `0 auto`,
    transform: `translateY(-75%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    width: 450,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function UploadButtons(props) {
  const modelClasses = useStyles();
  const { classes } = props;
  const [modalStyle] = React.useState(getModalStyle);

  const history = useHistory();
  const user_role = localStorage.getItem("user_role");
  const trader_type = localStorage.getItem("trader_type");
  const currency_symbol = localStorage.getItem("currency_symbol");
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState({});
  const [open, setOpen] = useState(false);
  //const [paymentMasterData, setPaymentMasterData]       = useState(props.paymentMasterData);
  const { value } = props;
  const status = props.value.status;
  const logistics = props.value.logistics;
  const [openPaymentModel, setOpenPaymentModel] = useState(false);
  function handleClick() {
    alert(JSON.stringify(value));
  }
  const EditInvoice = (item1) => {
    history.push("/editInvoice", item1);
  };

  const viewInvoice = (item) => {
    history.push("/ViewInvoiceList", item);
  };

  const updateInvoiceStatus = () => {
    const invoice_no = props.value.invoice_no;
    const purchaseOrderNO = props.value.purchaseOrderNO;

    if (
      props.paymentMasterData.payment_fee_needed &&
      props.paymentMasterData.pay_by === "supplier"
    ) {
      setOpenPaymentModel(true);
      localStorage.setItem("invoice_id", props.value.invoice_id);
      /*const paymentRequest = {
                merchant_code: props.paymentMasterData.merchant_code,          
                pay_item_id: props.paymentMasterData.pay_item_id,
                txn_ref: purchaseOrderNO+'-'+invoice_no,
                amount: props.paymentMasterData.amount, 
                currency: props.paymentMasterData.currency,
                onComplete: paymentCallback,
                site_redirect_url: process.env.REACT_APP_SITE_REDIRECT_URL,
                mode: props.paymentMasterData.mode
            };
            window.webpayCheckout(paymentRequest);*/
    } else {
      invoiceUpdateStatus();
    }
  };
  const invoiceUpdateStatus = () => {
    const access_token = getAccessToken();
    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: access_token,
      invoice_id: parseInt(props.value.invoice_id),
      status: "waiting_for_buyer_approval",
      comment: "",
    };

    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/invoice/update/status`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        console.log(response);
        if (response.data.message) {
          setLoading(false);
          setOpen(true);
          setMessage({ type: "success", content: response.data.message });
          window.location.reload();
        } else {
          setLoading(false);
          setOpen(true);
          setMessage({ type: "error", content: response.data.message });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const buyerStatusUpdate = (item) => {
    history.push("/NewBuyerInvoice", item);
  };

  //callback function that gets triggered on payment success or failure
  const paymentCallback = (response) => {
    if (response.amount !== undefined && response.payRef !== undefined) {
      invoiceUpdateStatus();
    }
  };

  const modelOpen = (event) => {
    event.preventDefault();
    setOpenPaymentModel(true);
  };
  const modelPaymentClose = () => {
    setOpenPaymentModel(false);
    localStorage.removeItem("invoice_id");
  };
  /*let paymentStatus   = (new URLSearchParams(window.location.search)).get("payment_status");
    useEffect(() => {      
        if(paymentStatus !== null){
            if(paymentStatus === "success"){
                invoiceUpdateStatus();
            }else{
                setLoading(false);
                setOpen(true);
                setMessage({ type: "error", content: "Somthing went wrong. Please try again." });
            }
        }
    }, [paymentStatus])*/
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}>
        <SnackbarContent
          style={{
            backgroundColor:
              message.type === "error"
                ? "#eb3d2a"
                : message.type === "success"
                ? "green"
                : "teal",
          }}
          message={message.content}
        />
      </Snackbar>
      <Box className="table-action">
        {trader_type === "supplier" && status === "Draft" && (
          <Tooltip title="Edit" placement="top" arrow>
            <IconButton
              size={isMobile ? "small" : "medium"}
              onClick={() => EditInvoice(value)}>
              <img className="iconstyle" src={Addedit} />
            </IconButton>
          </Tooltip>
        )}
        {trader_type === "supplier" && status === "Draft" ? (
          <Tooltip title="Update Status" placement="top" arrow>
            <IconButton
              size={isMobile ? "small" : "medium"}
              onClick={(e) => updateInvoiceStatus(e)}>
              <img className="iconstyle" src={Updatestatus} />
            </IconButton>
          </Tooltip>
        ) : null}
        {status === "Awaiting Buyer Approval" && trader_type === "buyer" ? (
          <Tooltip title="Edit" placement="top" arrow>
            <IconButton
              size={isMobile ? "small" : "medium"}
              onClick={() => buyerStatusUpdate(value)}>
              <img className="iconstyle" src={Addedit} />
            </IconButton>
          </Tooltip>
        ) : null}
      </Box>

      {openPaymentModel && (
        <WebRedirectPaymentMethod
          paymentMasterData={props.paymentMasterData}
          po_number={props.value.po_number}
          invoice_no={props.value.invoice_no}
          openPaymentModel={openPaymentModel}
          modelPaymentClose={modelPaymentClose}
        />
      )}
    </>
  );
}
