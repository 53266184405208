import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
// import MenuIcon from '@material-ui/icons/Menu';
import icon from "../../assets/img/I.svg"
import menu from "../../assets/img/AppbarMenu.svg"
import { Link } from "react-router-dom"
import MobileSidebar from './sideBar/mobileSidebar';
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";

const useStyles = makeStyles(theme => ({
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#1890ff" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgb(0, 184, 222)",
    boxSizing: "border-box",
  },
}));

const getuserType = localStorage.getItem("user_type");
const trader_type = localStorage.getItem("trader_type");
let user_type;

if(localStorage.getItem("userId")){
  if(getuserType === "both" ){
    if( !trader_type || trader_type === null){
      user_type = localStorage.setItem('trader_type', "supplier");
    }else{
      localStorage.setItem('trader_type', trader_type);
    }
  } else if(getuserType === ""){
    user_type = localStorage.setItem('trader_type', "supplier");
  } else {
    user_type = getuserType;
    localStorage.setItem('trader_type', getuserType);
  }
}

const MobileAppbarPrivate = () => {
    const classes               = useStyles();
    const [open, setOpen]       = useState(false)
    const activity              = { open, setOpen }

    const traderType              = localStorage.getItem("trader_type");
    const [userType, setUserType] = useState(trader_type === "buyer" ? false : true);
    let usertype;
    if(traderType === null){
        window.location.reload(true);
    }

    const handleToggle = (event) => {
        setUserType(!userType);    
        const user_type = userType ? 'buyer' : 'supplier';
        localStorage.setItem('trader_type', user_type);
        if(user_type === "buyer"){
          window.location.href = "/buyer/dashboard"
        }else{
          window.location.href = "/supplierdash"
        }
        //window.location.reload(false);
    }

    return (
        <>
            <MobileSidebar activity={activity} type="private" />
            <AppBar position="fixed" color="white" style={{ zIndex: "1400" }}>
                <Toolbar style={{ paddingLeft: "5%", paddingRight: "5%" }}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        className={classes.menuButton}
                        onClick={() => setOpen(true)}
                    >
                        <img src={menu} />
                    </IconButton>
                    <Box variant="h6" align="center" className={classes.title}>
                        <Link to={"/"}><img src={icon} /></Link>
                    </Box>
                    <Box>
                        {getuserType === "both" &&
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography style={userType ? {color: "#848f9f"}:{fontWeight: "600", color: "#0275D8"}}>Buyer</Typography>
                            <AntSwitch checked={userType} inputProps={{ 'aria-label': 'controlled' }} onClick={handleToggle}/>
                          <Typography style={!userType ? {color: "#848f9f"}:{fontWeight: "600", color: "#0275D8"}}>Supplier</Typography>
                        </Stack>
                        }
                    </Box>
                </Toolbar>
            </AppBar>
        </>
    );
};

export default MobileAppbarPrivate;