import React, { useEffect, useState } from "react";
import HomePage from "views/HomePage/HomePage";
import AboutInterswitch from "views/AboutUs/AboutInterswitch";
import CoreValues from "views/AboutUs/Corevalues";
import BottomComponent from "views/Components/HomePageComponents/BottomComponent";
import HomePage5 from "views/HomePage/HomePage5";
import SignupFooter from "views/Signup/footer";
import { isMobile } from "react-device-detect";
import axios from "axios";
import HomePage2 from "views/HomePage/HomePage2";
import HomePage4 from "views/HomePage/HomePage4";
import HomePage3 from "views/HomePage/HomePage3";
import { Spinner } from "react-bootstrap";
import { Box, Grid } from "@material-ui/core";

export default function MainAboutUs(props) {
  const [data, setData]         = useState();
  const [about, setAbout]       = useState();
  const api_url                 = process.env.REACT_APP_URL;
  const [loader, setLoader]     = useState(false);
  
  const getHomePage = (props) => {
    axios
      .get(
        api_url + "/api/v1/homepage",

        {
          headers: {
            "Content-Type": "text/plain",
          },
        }
      )
      .then((res) => {
        if (res.status == 200) {
          setData(res.data);
        }
      });
  };
  const getAboutUs = () => {
    setLoader(false)
    axios
      .get(
        api_url + "/api/v1/cms/pages/" + props.match.params.id,

        {
          headers: {
            "Content-Type": "text/plain",
          },
        }
      )
      .then((res) => {
        if (res.status == 200) {
          setAbout(res.data);
          document.title = 'Interswitch - '+res.data.data.title;
          setLoader(true)
        }else{
          setLoader(true)
        }
      });
  };
  const pageReload = () => {
    window.location.reload(false);
  };
  const contactUs = location.pathname == "/cms/contact-us" ? true : false;
  useEffect(() => {
    getHomePage();
    getAboutUs();
  }, []);
  return (
    <>
      {loader !== true ? (
        <Grid container spacing={3} className="bootstrap-loader">
          
            <Spinner animation="grow" variant="spinner1" />
            <Spinner animation="grow" variant="spinner2" />
            <Spinner animation="grow" variant="spinner3" />
          
        </Grid>
      ) : (
      <> 
      {data && about && (
        <>
          <HomePage
            info={data.banner}
            strip={data.homepage_promotion_strip_line}
          />
          <HomePage2
            info={about.cms_page_description}
            strip={data.homepage_promotion_strip_line}
          />
          {/* <AboutInterswitch /> */}
          {/* <CoreValues  /> */}
          {/* <BottomComponent /> */}
          <HomePage3
            info={about.cms_page_promition_line}
            promo={about.promotion_body}
          />
          {!contactUs &&
          <HomePage4
            info={data.homepage_count_widget}
          />
          }
          <BottomComponent info={data.registration_widget} />
          <HomePage5 />
          <SignupFooter />
        </>
      )}
      </>
      )}
    </>
  );
}
