import * as React from "react";
import { CssBaseline, Divider } from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
// import Divider from '@material-ui/core/Divider';
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Toolbar, Grid } from "@material-ui/core";
import BuyersIcon from "../../assets/img/BuyersIcon.svg";
import DashboardIcon from "../../assets/img/DashboardIcon.svg";
import UsersIcon from "../../assets/img/UsersIcon.svg";
import LendersIcon from "../../assets/img/LendersIcon.svg";
import InvoiceIcon from "../../assets/img/InvoiceIcon.svg";
import CompanyIcon from "../../assets/img/CompanyIcon.svg";
import SignatoryIcon from "../../assets/img/SignatoryIcon.svg";
import SettingsIcon from "../../assets/img/SettingsIcon.svg";
import newDashImage from "../../assets/img/newDashImage.svg";
import newUserImage from "../../assets/img/newUserImage.svg";
import newLenderImage from "../../assets/img/newLenderImage.svg";
import newBuyerImage from "../../assets/img/newBuyerImage.svg";
import newInvoiceImage from "../../assets/img/newInvoiceImage.svg";
import newCompanyImage from "../../assets/img/newCompanyImage.svg";
import newSignatoryImage from "../../assets/img/newSignatoryImage.svg";
import newSettingImage from "../../assets/img/newSettingImage.svg";
import ListItemButton from "@mui/material/ListItemButton";
import CancelIcon from "@material-ui/icons/HighlightOffOutlined";

import NewDashboardIcon from "../../assets/img/Navbar/DashboardIcon.svg";
import NewBuyerIcon from "../../assets/img/Navbar/BuyerIcon.svg";
import NewSupplierIcon from "../../assets/img/Navbar/SupplierIcon.svg";
import NewInvoiceIcon from "../../assets/img/Navbar/InvoiceIcon.svg";
import NewLenderIcon from "../../assets/img/Navbar/LenderIcon.svg";
import NewCreditOfferIcon from "../../assets/img/Navbar/CreditOfferIcon.svg";
import NewCreditRequestIcon from "../../assets/img/Navbar/CreaditAssessmentIcon.svg";
import NewCreditAssessmentIcon from "../../assets/img/Navbar/CreateReqIcon.svg";
import NewSettingIcon from "../../assets/img/Navbar/SettingIcon.svg";
import NewSignatorIcon from "../../assets/img/Navbar/SignatoryIcon.svg";
import OnlySupplier from "../../assets/img/Navbar/OnlySupplier.svg";
import OnlyKyc from "../../assets/img/Navbar/OnlyKyc.svg";
import OnlyCreditAssessment from "../../assets/img/Navbar/OnlyCreaditAssessment.svg";
import OnlyCreditReq from "../../assets/img/Navbar/OnlyCreditReq.svg";
import OnlyActiveOffer from "../../assets/img/Navbar/OnlyActiveOffer.svg";
import OnlyMyAccount from "../../assets/img/Navbar/OnlyAccount.svg";
import NewKycIcon from "../../assets/img/Navbar/KycIcon.svg";

import Collapse from "@mui/material/Collapse";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Link, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import SupplierDashboard from "./../SupplierDashboard/SupplierDashboard";
const theme = createMuiTheme({});

const useStyle = makeStyles({
  active: {
    borderLeft: "5px solid #0275D8",
    background: "#EBF8FE",
  },
  font: {
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "16px",
    fontFamily: "Averta-Regular",
    letterSpacing: "-0.4px",
    color: " #9CA3AF",
    marginTop: "10px",
  },
  margin: {
    marginLeft: "-20px",
  },
});

export default function TemporaryDrawer({ value }) {
  const trader_type = localStorage.getItem("trader_type");

  const [selected, setSelected] = React.useState(value);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const classes = useStyle();
  // const [active, setActive] = React.useState("Dashboard")
  const traderType = localStorage.getItem("trader_type");
  const drawerWidth = 300;

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = (e) => {
    /*if (e.currentTarget.localName !== "ul") {
      const menu = document.getElementById("Box");
      const menuBoundary = {
        left: menu.offsetLeft,
        top: e.currentTarget.offsetTop + e.currentTarget.offsetHeight,
        right: menu.offsetLeft + menu.offsetHeight,
        bottom: menu.offsetTop + menu.offsetHeight,
      };
      if (
        e.clientX >= menuBoundary.left &&
        e.clientX <= menuBoundary.right &&
        e.clientY <= menuBoundary.bottom &&
        e.clientY >= menuBoundary.top
      ) {
        return;
      }
    }

    setOpen(false);*/
    setOpen(!open);
  };
  const toggleDrawer = () => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    //setOpen(!open);
  };

  const handleCloseDrawer = (e) => {
    setOpen(!open);
  };
  const handleClick = () => {
    setOpen(!open);
  };

  const iconList = [
    DashboardIcon,
    UsersIcon,
    LendersIcon,
    BuyersIcon,
    InvoiceIcon,
    CompanyIcon,
    SignatoryIcon,
    SettingsIcon,
  ];
  const newIconList = [
    newDashImage,
    newUserImage,
    newLenderImage,
    newBuyerImage,
    newInvoiceImage,
    newCompanyImage,
    newSignatoryImage,
    newSettingImage,
  ];
  const list = () => (
    <Box
      className="left-sidebar"
      id={"Box"}
      style={{ paddingTop: "30%", display: "block" }}
      role="presentation"
      onMouseOver={handleOpen}
      supp
      onMouseLeave={handleClose}>
      <List>
        <ListItemIcon
          onClick={handleCloseDrawer}
          style={{ float: "right", cursor: "pointer" }}>
          <CancelIcon style={{ color: "#848f9f" }} />
        </ListItemIcon>
        <Link
          to={
            trader_type === "supplier" ? "/supplierdash" : "/buyer/dashboard"
          }>
          <ListItem button className={"DashBoard"}>
            <ListItemIcon>
              <img className="drawer-icons" src={iconList[0]} />
            </ListItemIcon>
            <ListItemText primary={"DashBoard"} />
          </ListItem>
        </Link>
        <Link to={trader_type === "supplier" ? "/buyer" : "/supplier"}>
          <ListItem button className={"Buyers"}>
            <ListItemIcon>
              {trader_type == "supplier" ? (
                <img className="drawer-icons" src={iconList[3]} />
              ) : (
                <img className="drawer-icons" src={OnlySupplier} />
              )}
            </ListItemIcon>
            <ListItemText
              primary={trader_type === "buyer" ? "Supplier" : "Buyers"}
            />
          </ListItem>
        </Link>
        {trader_type === "buyer" ? (
          <Link to={"/domiciled-banks"}>
            <ListItem button className={"Domiciledbank"}>
              <ListItemIcon>
                <img className="drawer-icons" src={iconList[5]} />
              </ListItemIcon>
              <ListItemText primary={"Domiciled Banks"} />
            </ListItem>
          </Link>
        ) : null}
        <Link to={"/invoice"}>
          <ListItem button className={"Invoices"}>
            <ListItemIcon>
              <img className="drawer-icons" src={iconList[4]} />
            </ListItemIcon>
            <ListItemText primary={"Invoices"} />
          </ListItem>
        </Link>
        <Link to={"/kyc-page"}>
          <ListItem button className={"Kycpage"}>
            <ListItemIcon>
              <img className="drawer-icons" src={OnlyKyc} />
            </ListItemIcon>
            <ListItemText primary={"KYC"} />
          </ListItem>
        </Link>
        {trader_type !== "buyer" && (
          <>
            <Link to={"/trader-assessment"}>
              <ListItem button className={"CreditAssessment"}>
                <ListItemIcon>
                  <img className="drawer-icons" src={OnlyCreditAssessment} />
                </ListItemIcon>
                <ListItemText primary={"Trader Assessment"} />
              </ListItem>
            </Link>
            <Link to={"/credit"}>
              <ListItem button className={"Lenders"}>
                <ListItemIcon>
                  <img className="drawer-icons" src={OnlyCreditReq} />
                </ListItemIcon>
                <ListItemText primary={"Credit Request"} />
              </ListItem>
            </Link>
          </>
        )}
        {traderType === "supplier" ? (
          <>
            {/*<Link to={"/activeOffer"}>
              <ListItem button className={"Lenders"}>
                <ListItemIcon>
                  <img className="drawer-icons" src={OnlyActiveOffer}
                  />
                </ListItemIcon>
                <ListItemText primary={"Active Offer"} />
              </ListItem>
            </Link>*/}
            <Link to={"/creditoffers"}>
              <ListItem button className={"Lenders"}>
                <ListItemIcon>
                  <img className="drawer-icons" src={OnlyActiveOffer} />
                </ListItemIcon>
                <ListItemText primary={"Credit Offers"} />
              </ListItem>
            </Link>
          </>
        ) : (
          ""
        )}

        <ListItemButton onClick={handleClick}>
          <ListItemIcon>
            <img className="drawer-icons" src={iconList[7]} />
          </ListItemIcon>
          <ListItemText primary="Settings" />
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <Link to={"/myaccount"}>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <img className="drawer-icons" src={OnlyMyAccount} />
                </ListItemIcon>
                <ListItemText primary="My Account" />
              </ListItemButton>
            </Link>
            <Link to={"/viewCompany"}>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <img className="drawer-icons" src={iconList[5]} />
                </ListItemIcon>
                <ListItemText primary="Company" />
              </ListItemButton>
            </Link>
            <Link to={"/manageuser"}>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <img className="drawer-icons" src={iconList[1]} />
                </ListItemIcon>
                <ListItemText primary="Users" />
              </ListItemButton>
            </Link>
            <Link to={"/signatory"}>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <img className="drawer-icons" src={iconList[6]} />
                </ListItemIcon>
                <ListItemText primary="Authorized Signatory" />
              </ListItemButton>
            </Link>
          </List>
        </Collapse>
      </List>
    </Box>
  );

  const permanentList = () => (
    <Box
      sx={{ display: "flex" }}
      onClick={toggleDrawer()}
      onKeyDown={toggleDrawer()}
      onMouseOver={handleOpen}
      onMouseLeave={handleClose}>
      <CssBaseline />

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="permanent"
        anchor="left">
        <Toolbar />

        {/* <Divider /> */}
        <List>
          <ListItem
            button
            key={"dasshboard"}
            style={{
              justifyContent: "center",
            }}
            className={
              location.pathname == "/supplierdash" ? classes.active : ""
            }>
            <ListItemIcon>
              {<img style={{ width: "95%" }} src={NewDashboardIcon} />}
            </ListItemIcon>
          </ListItem>

          {trader_type !== "buyer" ? (
            <ListItem
              button
              key={"buyers"}
              style={{
                justifyContent: "center",
              }}
              className={
                location.pathname == "/buyer" ||
                location.pathname == "/addbuyer" ||
                location.pathname == "/editbuyer/:id"
                  ? classes.active
                  : ""
              }>
              <ListItemIcon>
                {<img style={{ width: "70%" }} src={NewBuyerIcon} />}
              </ListItemIcon>
            </ListItem>
          ) : (
            <ListItem
              button
              key={"buyers"}
              style={{
                justifyContent: "center",
              }}
              className={
                location.pathname == "/buyer" ||
                location.pathname == "/addbuyer" ||
                location.pathname == "/editbuyer/:id"
                  ? classes.active
                  : ""
              }>
              <ListItemIcon>
                {<img style={{ width: "100%" }} src={NewSupplierIcon} />}
              </ListItemIcon>
            </ListItem>
          )}

          <ListItem
            button
            key={"invoices"}
            style={{
              justifyContent: "center",
            }}
            className={
              location.pathname == "/invoice" ||
              location.pathname == "/ViewInvoiceList" ||
              location.pathname == "/editInvoice" ||
              location.pathname == "/addinvoice"
                ? classes.active
                : ""
            }>
            <ListItemIcon>
              {<img style={{ width: "80%" }} src={NewInvoiceIcon} />}
            </ListItemIcon>
          </ListItem>
          <ListItem
            button
            key={"kycpage"}
            style={{
              justifyContent: "center",
            }}
            className={location.pathname == "/kyc-page" ? classes.active : ""}>
            <ListItemIcon>
              {
                <img
                  style={{ width: "100%", marginLeft: "-10px" }}
                  src={NewKycIcon}
                />
              }
            </ListItemIcon>
          </ListItem>
          {trader_type !== "buyer" && (
            <>
              <ListItem
                button
                key={"creditassessment"}
                style={{
                  justifyContent: "center",
                }}
                className={
                  location.pathname == "/trader-assessment"
                    ? classes.active
                    : ""
                }>
                <Grid item>
                  <ListItemIcon
                    style={{ display: "block", textAlign: "center" }}>
                    {
                      <>
                        <img
                          style={{ width: "40px", marginLeft: "-15px" }}
                          src={OnlyCreditAssessment}
                        />
                        <br />
                        <br />
                        <span
                          className={classes.font}
                          style={{ marginLeft: "-10px", fontSize: "14px" }}>
                          Trader
                          <br />
                          Assessment
                        </span>
                      </>
                    }
                  </ListItemIcon>
                </Grid>
              </ListItem>
              <ListItem
                button
                key={"credit"}
                style={{
                  justifyContent: "center",
                }}
                className={
                  location.pathname == "/credit" ||
                  location.pathname == "/add-supplier-credit"
                    ? classes.active
                    : ""
                }>
                <Grid item>
                  <ListItemIcon>
                    {
                      <img
                        style={{ width: "90px", marginLeft: "-10px" }}
                        src={NewCreditRequestIcon}
                      />
                    }
                  </ListItemIcon>
                </Grid>
              </ListItem>
              <ListItem
                button
                key={"activeoffer"}
                style={{
                  justifyContent: "center",
                }}
                className={
                  location.pathname == "/activeOffer" ? classes.active : ""
                }>
                <Grid item>
                  <ListItemIcon>
                    {
                      <img
                        style={{ width: "70px", marginLeft: "-20px" }}
                        src={NewCreditOfferIcon}
                      />
                    }
                  </ListItemIcon>
                </Grid>
              </ListItem>
            </>
          )}
          <ListItem
            button
            key={"settings"}
            style={{
              justifyContent: "center",
              marginLeft: "-10px",
            }}>
            <ListItemIcon>
              {<img style={{ width: "100%" }} src={newIconList[7]} />}
            </ListItemIcon>
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );
  theme.props = {
    MuiList: {
      onMouseLeave: (e) => {
        handleClose(e);
      },
    },
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Toolbar />
        {permanentList()}
        <div style={{ paddingLeft: `${drawerWidth}px` }}>
          {
            <>
              <React.Fragment>
                <Drawer
                  transitionDuration={0}
                  anchor={"left"}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  open={open}
                  onClose={toggleDrawer(false)}>
                  {list()}
                </Drawer>
              </React.Fragment>
              <Box>{selected === 0 && <supplierDashboard />}</Box>
            </>
          }
        </div>
      </ThemeProvider>
    </>
  );
}
