import React, { useEffect } from 'react';
import { Grid, Box, Typography, Button } from "@material-ui/core";
import image from "../../assets/img/authentication.png"
import googleauth from "../../assets/img/googleauth.svg"
import HomePage5 from 'views/HomePage/HomePage5';
import SignupFooter from 'views/Signup/footer';
import { isMobile } from 'react-device-detect';
import { useHistory } from "react-router-dom";
const axios = require("axios");

export default function Authentication() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const styles = () => ({
        otpContainer: {
            "& input": {
                width: "10%",
                marginRight: "2%",
                height: "40px",
                borderRadius: "4px",
                border: "none",
                background: "#F3F5F6",
                "text-align": "center"
            }
        }
    });

    const [userInputOtp, setUserInputOtp] = React.useState({});
    const [seconds, setSeconds] = React.useState(60);
    const history = useHistory();
    const tabChange = function (val, e) {
        e.preventDefault();
        let ele = document.querySelectorAll("input");
        console.log(ele)
        if (ele[val - 1].value != "") {
            if (typeof ele[val] != "undefined") {
                ele[val].focus();
            }
        } else if (ele[val - 1].value == "") {
            if (typeof ele[val - 2] != "undefined") {
                ele[val - 2].focus();
            }
        }

        setUserInputOtp({ ...userInputOtp, [e.target.name]: e.target.value });
    };
    const OtpVerifier = () => {
        // history.push('/company-page')
        const otp = Object.values(userInputOtp).join("");
        // alert(otp)        // e.stopPropagation();

        // const otp1 = Object.values(userInputOtp1).join("");
        // if (Object.values(userInputOtp).join("").length !== 6)
        //   return toastDisplay("Please enter OTP.", "warn");
        // const sessionID = getCookie("sessionID");


        const data = {
            email: localStorage.getItem("email"),
            otp: otp
        };

        const config = {
            method: 'post',
            url: process.env.REACT_APP_URL + '/api/v1/registration/otp/submit',
            headers: {
                'Content-Type': 'text/plain',
            },
            data: data
        };
        axios(config)
            .then(function (response) {
                if (response && response.data && response.data.success) {
                    history.push("/company-page");
                }
                console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
                console.log(error);
            });
        history.push("/company-page");

    }




    React.useEffect(() => {
        if (seconds > 0) {
            setTimeout(() => setSeconds(seconds - 1), 1000);
        } else {
            setSeconds("0");


        }
    });
    return (
        <>
            <Box style={{ background: "#FFF", width: "100%", height: "100%", padding: isMobile ? "" : "7%", paddingBottom: isMobile ? "" : "10%" }}>
                {isMobile ? <Box style={{ background: "white", padding: "2%" }}>
                    <img style={{ width: "95%", paddingTop: "3%" }} src={image} />
                </Box> : ""}
                <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", background: "white", borderRadius: isMobile ? "" : "16px" }}>

                    <Grid container>
                        <Grid item xs={12} sm={12} md={6} xl={6} style={{ marginRight: "0%" }}>
                            <Grid container style={{ paddingLeft: isMobile ? "5%" : "15%", paddingRight: isMobile ? "" : "15%", paddingTop: "8%" }}>
                                <Grid item xs={12} style={{ fontSize: isMobile ? "24px" : "32px", color: "#18425D", fontWeight: "bold", lineHeight: "48px", fontFamily: "Averta-SemiBold" }} >Verification
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography style={{ fontWeight: "600", color: "#848F9F", fontSize: "12px" }} >We have just sent you SMS and email with OTP, please enter below to verify</Typography>
                                </Grid>
                                <Grid item xs={12} style={{ paddingTop: "10%" }}>
                                    <Typography style={{ fontSize: "14px", fontWeight: "600", fontFamily: "Averta-Regular", color: "#353F50", lineHeight: "20px" }}>OTP</Typography>
                                </Grid>
                                <Grid item xs={12} style={{ alignContent: "center" }}>
                                    <form >
                                        <div style={styles.otpContainer}>

                                            <input
                                                style={{
                                                    width: "10%",
                                                    marginRight: "2%",
                                                    height: "40px",
                                                    borderRadius: "4px",
                                                    border: "none",
                                                    background: "#F3F5F6",
                                                    "text-align": "center"
                                                }}
                                                value={userInputOtp["otp1"]}
                                                name="otp1"
                                                type="password"
                                                autoComplete="off"
                                                className="otpInput"
                                                margin="5px"
                                                maxLength={1}
                                                onKeyUp={(e) => {
                                                    tabChange(1, e);
                                                }}
                                                tabIndex="1"

                                            />
                                            <input
                                                style={{
                                                    width: "10%",
                                                    marginRight: "2%",
                                                    height: "40px",
                                                    borderRadius: "4px",
                                                    border: "none",
                                                    background: "#F3F5F6",
                                                    "text-align": "center"
                                                }}
                                                value={userInputOtp["otp2"]}
                                                name="otp2"
                                                type="password"
                                                autoComplete="off"
                                                className="otpInput"
                                                onKeyUp={(e) => {
                                                    tabChange(2, e);
                                                }}
                                                tabIndex="2" maxLength="1"

                                            />
                                            <input
                                                style={{
                                                    width: "10%",
                                                    marginRight: "2%",
                                                    height: "40px",
                                                    borderRadius: "4px",
                                                    border: "none",
                                                    background: "#F3F5F6",
                                                    "text-align": "center"
                                                }}
                                                value={userInputOtp["otp3"]}
                                                name="otp3"
                                                type="password"
                                                autoComplete="off"
                                                className="otpInput"
                                                onKeyUp={(e) => {
                                                    tabChange(3, e);
                                                }}
                                                tabIndex="3" maxLength="1"

                                            />
                                            <input
                                                style={{
                                                    width: "10%",
                                                    marginRight: "2%",
                                                    height: "40px",
                                                    borderRadius: "4px",
                                                    border: "none",
                                                    background: "#F3F5F6",
                                                    "text-align": "center"
                                                }}
                                                value={userInputOtp["otp4"]}
                                                name="otp4"
                                                type="password"
                                                autoComplete="off"
                                                className="otpInput"
                                                onKeyUp={(e) => {
                                                    tabChange(4, e);
                                                }}
                                                tabIndex="4" maxLength="1"
                                            />

                                            <input

                                                style={{
                                                    width: "10%",
                                                    marginRight: "2%",
                                                    height: "40px",
                                                    borderRadius: "4px",
                                                    border: "none",
                                                    background: "#F3F5F6",
                                                    "text-align": "center"
                                                }}
                                                value={userInputOtp["otp5"]}
                                                name="otp5"
                                                type="password"
                                                autoComplete="off"
                                                className="otpInput"
                                                onKeyUp={(e) => {
                                                    tabChange(5, e);
                                                }}
                                                tabIndex="5" maxLength="1"
                                            />

                                            <input
                                                style={{
                                                    width: "10%",
                                                    marginRight: "2%",
                                                    height: "40px",
                                                    borderRadius: "4px",
                                                    border: "none",
                                                    background: "#F3F5F6",
                                                    "text-align": "center"
                                                }}
                                                value={userInputOtp["otp6"]}
                                                name="otp6"
                                                type="password"
                                                autoComplete="off"
                                                className="otpInput"
                                                onKeyUp={(e) => {
                                                    tabChange(6, e);
                                                }}
                                                tabIndex="6" maxLength="1"
                                            />
                                        </div>
                                        {/* <Button className="primary" type="submit">
                    Submit
                </Button> */}
                                    </form>
                                </Grid>
                                <Grid item xs={12}>

                                    <Typography style={{ paddingLeft: "25%", color: "red" }}>Resend in 0:{seconds}</Typography>
                                </Grid>
                            </Grid>
                            {isMobile ?
                                <Grid container style={{ paddingTop: "5%", height: "15%", paddingBottom: "5%" }}>
                                    <Grid item xs={6} container direction="row" alignItems="center" justify="flex-end" style={{ paddingRight: "4%" }}>
                                        <Button onClick={OtpVerifier} style={{ color: "#FFFFFF", background: "#479FC8", width: isMobile ? "90%" : "60%", height: "100%" }}>Verify now</Button>
                                    </Grid>
                                    <Grid item xs={6} >
                                        <Button style={{ marginRight: isMobile ? "" : "10%", color: "#479FC8", background: "#EBF8FE", width: isMobile ? "90%" : "50%", height: "100%" }}>Cancel</Button>
                                    </Grid>
                                </Grid>
                                :
                                <Grid container style={{ paddingTop: "5%", height: "15%", paddingBottom: "5%" }}>
                                    <Grid item xs={5} container direction="row" alignItems="center" justify="flex-end" style={{ paddingRight: "4%" }}>
                                        <Button onClick={OtpVerifier} style={{ color: "#FFFFFF", background: "#479FC8", width: isMobile ? "90%" : "60%", height: "100%" }} onClick={OtpVerifier} >Verify now</Button>
                                    </Grid>
                                    <Grid item xs={6} >
                                        <Button style={{ marginRight: isMobile ? "" : "10%", color: "#479FC8", background: "#EBF8FE", width: isMobile ? "90%" : "50%", height: "100%" }}>Cancel</Button>
                                    </Grid>
                                </Grid>
                            }
                            <Grid container style={{ padding: "10%", marginLeft: isMobile ? "" : "3%" }}>
                                <Grid item xs={5} sm={3} md={3} xl={3}>
                                    <hr />
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography align="center" style={{ fontSize: "13px", fontWeight: "700", color: "#008AA7" }}>Or Use</Typography>
                                </Grid>
                                <Grid item xs={5} sm={3} md={3} xl={3}>
                                    <hr />
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} xl={6} style={{ paddingLeft: "25%", marginTop: "5%", paddingBottom: "20%" }}>
                                <img src={googleauth} />
                                {/* <responseGoogle /> */}
                            </Grid>

                        </Grid>
                        {isMobile ? "" : <Grid item xs={12} sm={12} md={6} xl={6} style={{ padding: "10%" }}>
                            <img style={{ width: "100%" }} src={image} />
                        </Grid>}
                    </Grid>
                </Box>
            </Box>
            {isMobile ? "" : <HomePage5 />}
            <SignupFooter />
        </>
    );
}