
import React from 'react';
// import { styled } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
// import Stack from '@material-ui/core/St';
import image1 from "assets/img/edit1.svg";
import image2 from "assets/img/edit2.svg";
import image3 from "assets/img/edit3.svg";
import image4 from "assets/img/edit4.svg";
import { Box } from '@material-ui/core';


const myStyles = {
    buttonStyle: {
        margin: "2%"
    },
    box: {
        display: "flex"
    }
}

// const Input = styled('input')({
//     display: 'none',
// });

export default function Custom2() {
    return (<>


        <Box style={myStyles.box}>
            <IconButton aria-label="delete" size="small">
                <img style={myStyles.buttonStyle} src={image1} /></IconButton>
            <IconButton aria-label="delete" size="small">
                <img style={myStyles.buttonStyle} src={image2} /></IconButton>
            <IconButton aria-label="delete" size="small"> <img style={myStyles.buttonStyle} src={image3} /></IconButton>
            <IconButton aria-label="delete" size="small"> <img style={myStyles.buttonStyle} src={image4} /></IconButton>
        </Box>


    </>


    );
}