import React, { useState, useContext, useEffect } from "react";
import styles from "assets/jss/material-kit-react/views/components.js";
import Grid from "@material-ui/core/Grid";
import {
  Button,
  Box,
  OutlinedInput,
  Snackbar,
  SnackbarContent,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import SignupFooter from "views/Signup/footer";
import image from "../../assets/img/forgot-password.png";
import image2 from "../../assets/img/Financedoodle.svg";
import HomePage5 from "views/HomePage/HomePage5";
import { isMobile } from "react-device-detect";
import { Link, useHistory } from "react-router-dom";
import { Store } from "../../context/store/storeState";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { Spinner } from "react-bootstrap";
import InfoIcon from '@material-ui/icons/Help';
import Tooltip from '@mui/material/Tooltip';

const useStyles = makeStyles(styles);

export default function Reset() {
  const [password, setPassword] = useState({ new: "", confirm: "" });
  const [error, setError]       = useState(false);
  const api_url                 = process.env.REACT_APP_URL;
  const verifyContext           = useContext(Store);
  const [open, setOpen]         = useState(false);
  const verify                  = verifyContext.verify;
  const setVerify               = verifyContext.setVerify;
  const history                 = useHistory();
  const classes                 = useStyles();
  const [values, setValues] = useState({
    showPassword1: false,
    showPassword2: false,
  });
  const [message, setMessage]         = useState({});
  const [loading, setLoading]         = useState(false);
  const [loader, setLoader]           = useState(false);
  const [matchmsg, setMatchMsg]       = useState(false);
  const [mismatchmsg, setMismatchMsg] = useState(false);
  const [matchRegex, setMatchRegex]   = useState(false);

  function handleChange(e) {
    const value = e.target.value;
    setPassword({
      ...password,
      [e.target.name]: value,
    });
  }
  const handleClickShowPassword = (num) => {
    if (num == 1) {
      setValues({
        ...values,
        showPassword1: !values.showPassword1,
      });
    } else {
      setValues({
        ...values,
        showPassword2: !values.showPassword2,
      });
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  function validate() {
    var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    var hasNumber = /\d/;
    if (password.new === password.confirm) {
      var regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/
      if(!password.new.match(regex)){
        setLoading(false);
        setOpen(true);
        setMessage({
          type: "error",
          content: "Password should be minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character",
        });
        return;
      }else{
        return true;
      }

    } else {
      setOpen(true);
      setMessage({ type: "error", content: "Password did not match" });
      return false;
    }
  }
  const passwordValidate = () => {    
    var regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/
    if(!password.new.match(regex)){
      setMatchRegex(true);
      setTimeout(() => {
        setMatchRegex(false);
      }, 3000);
      return;
    }    
  }
  const matchPassword = () => {
    if (password.new === password.confirm) {    
      setMatchMsg(true);
      setTimeout(() => {
        setMatchMsg(false);
      }, 2000);
      return;
    }else{
      setMismatchMsg(true);
      setTimeout(() => {
        setMismatchMsg(false);
      }, 2000);
      return;
    }
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const submitData = () => {
    const verification = validate();
    if (verification && verification === true) {
      setLoader(false)
      const body = {
        email: verify.email,
        confirm_password: password.confirm,
      };
      axios
        .post(api_url + "/api/v1/set_password", body, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then((res) => {
          if (res.data.success) {
            setOpen(true);
            setMessage({ type: "success", content: res.data.message });
            setTimeout(() => {
              history.push("/signin");
            }, 3000)
            
          } else {
            setLoader(true)
            setOpen(true);
            setMessage({ type: "error", content: res.data.message });
          }
        });
    }
  };

  useEffect(() => {
    document.title = 'Interswitch - Reset Password';    
    setLoader(true);
  }, []);

  return (
    <>
      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={open} autoHideDuration={4000} onClose={handleClose} >
        <SnackbarContent className={message.type === "error" ? "snack-error" : message.type === "success" ? "snack-success" : "snack-teal" } message={message.content} />
      </Snackbar>
      {loader !== true ? (
        <Grid container spacing={3} className="bootstrap-loader">          
            <Spinner animation="grow" variant="spinner1" />
            <Spinner animation="grow" variant="spinner2" />
            <Spinner animation="grow" variant="spinner3" />          
        </Grid>
      ) : (
      <>
      <Box className="forgot-password">
        <Box className="scf-box">
          <Box className="scf-subbox">
            <Grid container>
              {isMobile ? (
                <img className="mobile-img" src={image} />
              ) : (
                ""
              )}

              <Grid item xs={12} sm={12} md={6} xl={6}>
                <Box>
                  <Grid xs={12} className="heading"> Reset Password </Grid>

                  {/*<Typography className="text" >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.Sed feugiat, sapien
                    <br />
                    in rhoncus suscipit, metus mi accumsan dui, vel vulputate metus mauris
                    <br /> vitae tellus.Aenean porttitor condimentum eros sed tempus.
                  </Typography>*/}

                  <Box className="input-box">
                    <span className="company-title3">
                      New Password<span className="required">*</span>{" "}
                      <Tooltip title="Password should be minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character" placement="top" arrow>
                          <InfoIcon />
                      </Tooltip>
                    </span>
                    <OutlinedInput
                      className="company-title51"
                      type={values.showPassword1 ? "text" : "password"}
                      fullWidth
                      placeholder="New Password"
                      name="new"
                      value={password.new}
                      onKeyUp={passwordValidate}
                      onChange={handleChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => handleClickShowPassword(1)}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showPassword1 ? (
                              <i className="far fa-eye-slash"></i>
                            ) : (
                              <i className="fas fa-eye"></i>
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </Box>
                  <Box className="input-box">
                    <span className="company-title3">
                      Confirm Password<span className="required">*</span>
                    </span>
                    <OutlinedInput
                      type={values.showPassword2 ? "text" : "password"}
                      className="company-title51"
                      fullWidth
                      placeholder="Confirm Password"
                      name="confirm"
                      value={password.confirm}
                      onChange={handleChange}
                      onKeyUp={matchPassword}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => handleClickShowPassword(2)}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showPassword2 ? (
                              <i class="far fa-eye-slash"></i>
                            ) : (
                              <i class="fas fa-eye"></i>
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </Box>
                  <Box className="input-box">
                    {matchmsg && matchmsg === true && (
                      <span className="company-title3" style={{ color: "green" }}>
                        Password matched.
                      </span>
                    )}
                    {mismatchmsg && mismatchmsg === true && (
                      <span className="company-title3" style={{ color: "red" }}>
                        Password not matched.
                      </span>
                    )}
                    {matchRegex && matchRegex === true && (
                      <span className="company-title3" style={{ color: "red" }}>
                        Password should be minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character
                      </span>
                    )}
                  </Box>
                  <Grid container spacing={3} className="button-grid">
                    <Grid item xs={6} sm={4} md={5} xl={5}>
                        <Button className="save" onClick={() => submitData()} disabled={!password.new || !password.confirm}>Reset Password</Button>
                    </Grid>
                    <Grid item xs={6} sm={4} md={5} xl={5}>
                        <Link to="/signin"><Button className="cancel">Cancel</Button></Link>
                    </Grid>
                  </Grid>
                  
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                {isMobile ? (
                  " "
                ) : (
                  <Box className="right-image">
                    <img src={image} />
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
        <HomePage5 />
        {isMobile ? " " : <SignupFooter />}
      </Box>
      </>
      )}
    </>
  );
}
