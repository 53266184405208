import React, { useState, useEffect, useContext } from "react";
import styles from "assets/jss/material-kit-react/views/components.js";
import { makeStyles } from "@material-ui/core/styles";
import { isMobile } from "react-device-detect";
import {
  Grid,
  TextField,
  Typography,
  Button,
  Divider,
  Box,
  OutlinedInput,
  Snackbar,
  SnackbarContent,
} from "@material-ui/core";

import SignupFooter from "../Signup/footer";
import HomePage5 from "views/HomePage/HomePage5";
import image from "../../assets/img/forgot-password.png";
import otpverifyimg from "../../assets/img/otpverify.svg";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import OtpTimer from 'otp-timer'
import { Store } from "../../context/store/storeState";

import axios from "axios";
const useStyles = makeStyles(styles);

export default function Otpverify(props) {
  const history                         = useHistory();
  const classes                         = useStyles();
  const api_url                         = process.env.REACT_APP_URL;
  const [error, setError]               = useState(false);
  const [message, setMessage]           = useState({});
  const [open, setOpen]                 = useState(false);
  const [loading, setLoading]           = useState(false);
  const [loader, setLoader]             = useState(false);
  const [email, setEmail]               = useState(props.match.params.email);
  const [userInputOtp, setUserInputOtp] = useState({});
  const verifyContext                   = useContext(Store);
  const otp_timer                       = verifyContext.otp_timer;
  const setOtpTimer                     = verifyContext.setOtpTimer;

  const onSubmit = () => {
    const otps =
      userInputOtp.digit1 +
      userInputOtp.digit2 +
      userInputOtp.digit3 +
      userInputOtp.digit4 +
      userInputOtp.digit5 +
      userInputOtp.digit6;
    const request = {
      email: email,
      otp: otps,
    };
    if (otps !== "" && email !== "") {
      setLoader(false)
      axios
        .post(api_url + "/api/v1/submit/otp", request, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then((response) => {
          if (response && response.data.success === true) {
            setLoading(false);
            setOpen(true);
            //setMessage({ type: "success", content: "Logged in successfully" });
            localStorage.setItem("userName", response.data.user_name); //Setting username and password to local storage
            localStorage.setItem("userId", response.data.user_id);
            localStorage.setItem("user_type", response.data.user_type);
            localStorage.setItem("user_role", response.data.user_role);
            localStorage.setItem("access_token", response.data.access_token);
            localStorage.setItem("user_email", response.data.user_email);
            localStorage.setItem("kyc_type", response.data.kyc_type);
            localStorage.setItem("kyc_status", response.data.kyc_status);
            localStorage.setItem("currency_name", response.data.currency_name);
            localStorage.setItem("currency_symbol", response.data.currency_symbol);
            localStorage.setItem("redirect_company_info", response.data.redirect_company_info);
            localStorage.setItem("is_manual_kyc_enabled", response.data.is_manual_kyc_enabled);
            if(response.data.user_type === "both"){
              localStorage.setItem("trader_type", "supplier");
            }else if(response.data.user_type === "buyer"){
              localStorage.setItem("trader_type", "buyer");
            }else{

            }
            if(response.data.user_type === "buyer"){
              window.location.href = "/buyer/dashboard"
            }else if(response.data.is_lender === true){
              window.location.href = "/lender/dashboard"
            }else{
              window.location.href = "/supplierdash"
            }
            
          } else {
            setOpen(true);
            setLoading(false);
            setLoader(true)
            setMessage({ type: "error", content: response.data.message });
          }
        });
    } else {
      setLoader(true)
      setError(true);
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const handleCancel = () => {
    history.push("/signin");
  };
  const tabChange = function (val, e) {
    e.preventDefault();
    let ele = document.querySelectorAll("input");
    if (ele[val - 1].value != "") {
      if (typeof ele[val] != "undefined") {
        ele[val].focus();
      }
    } else if (ele[val - 1].value == "") {
      if (typeof ele[val - 2] != "undefined") {
        ele[val - 2].focus();
      }
    }

    setUserInputOtp({ ...userInputOtp, [e.target.name]: e.target.value });
  };
  const resedOTP = () => {
    setLoader(false);
    const body = {
      email: email,
    };
    axios
      .post(api_url + "/api/v1/generate/otp", body, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((res) => {
        if (res.data.success) {
          setOtpTimer({
            otp_timer: res.data.otp_time
          });
          setLoader(true);
          setOpen(true);
          setMessage({ type: "success", content: res.data.message });
        } else {
          setLoader(true);
          setOpen(true);
          setMessage({ type: "error", content: res.data.message });
        }
      });
  };
  let minutes;
  let seconds;
  if(otp_timer !== undefined && otp_timer.otp_timer){
    minutes = Math.floor(otp_timer.otp_timer / 60);
    seconds = otp_timer.otp_timer - minutes * 60;
  }  
  useEffect(() => {
    window.scrollTo(0, 0);
    setLoader(true)
    document.title = 'Interswitch - Verify OTP';
  }, []);
  return (
    <>
      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={open} autoHideDuration={3000} onClose={handleClose} >
        <SnackbarContent className={message.type === "error" ? "snack-error" : message.type === "success" ? "snack-success" : "snack-teal" } message={message.content} />
      </Snackbar>
      {loader !== true ? (
        <Grid container spacing={3} className="bootstrap-loader">          
            <Spinner animation="grow" variant="spinner1" />
            <Spinner animation="grow" variant="spinner2" />
            <Spinner animation="grow" variant="spinner3" />          
        </Grid>
      ) : (
      <> 
      <Box className="forgot-password">
        <Box className="scf-box">
          <Box className="scf-subbox">
            {isMobile ? (
              <img className="mobile-img" src={otpverifyimg} />
            ) : (
              ""
            )}

            <Grid container spacing={10}>
              <Grid item xs={12} sm={12} md={6} xl={6}>
                <Box>
                  <Grid item xs={12}>
                    <Grid item xs={12} className="heading">
                      OTP Verification
                    </Grid>
                    <Grid >
                      <Typography className="text">
                        We have just sent you email with OTP, please enter below
                        to verify
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item xs={10}>
                    {otp_timer !== undefined && otp_timer.otp_timer && 
                      <span className="otp-msg text"><OtpTimer minutes={minutes} seconds={1} text="This OTP is valid only for: " ButtonText="Resend" resend={resedOTP}/></span>
                    }
                    <Grid className="company-title3">
                      OTP <span style={{ color: "red" }}>*</span>
                    </Grid>
                    <Grid container>
                      <Grid item xm={2} xs={2} md={2} lg={2} xl={2}>
                        <input
                          id="input1"
                          className="otp-all-input"
                          inputMode="numeric"
                          pattern="[0-9]*"
                          type="text"
                          name="digit1"
                          maxLength={1}
                          onKeyUp={(e) => {
                            tabChange(1, e);
                          }}
                        />
                      </Grid>
                      <Grid item xm={2} xs={2} md={2} lg={2} xl={2}>
                        <input
                          id="input1"
                          className="otp-all-input"
                          inputMode="numeric"
                          pattern="[0-9]*"
                          type="text"
                          name="digit2"
                          maxLength={1}
                          onKeyUp={(e) => {
                            tabChange(2, e);
                          }}
                        />
                      </Grid>
                      <Grid item xm={2} xs={2} md={2} lg={2} xl={2}>
                        <input
                          id="input1"
                          className="otp-all-input"
                          inputMode="numeric"
                          pattern="[0-9]*"
                          type="text"
                          name="digit3"
                          maxLength={1}
                          onKeyUp={(e) => {
                            tabChange(3, e);
                          }}
                        />
                      </Grid>
                      <Grid item xm={2} xs={2} md={2} lg={2} xl={2}>
                        <input
                          id="input1"
                          className="otp-all-input"
                          inputMode="numeric"
                          pattern="[0-9]*"
                          type="text"
                          name="digit4"
                          maxLength={1}
                          onKeyUp={(e) => {
                            tabChange(4, e);
                          }}
                        />
                      </Grid>
                      <Grid item xm={2} xs={2} md={2} lg={2} xl={2}>
                        <input
                          id="input1"
                          className="otp-all-input"
                          inputMode="numeric"
                          pattern="[0-9]*"
                          type="text"
                          name="digit5"
                          maxLength={1}
                          onKeyUp={(e) => {
                            tabChange(5, e);
                          }}
                        />
                      </Grid>
                      <Grid item xm={2} xs={2} md={2} lg={2} xl={2}>
                        <input
                          id="input1"
                          className="otp-all-input"
                          inputMode="numeric"
                          pattern="[0-9]*"
                          type="text"
                          name="digit6"
                          maxLength={1}
                          onKeyUp={(e) => {
                            tabChange(6, e);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid className="company-title3">
                    <a href="#" onClick={() => resedOTP()} style={{ color: "red" }}>Resend OTP</a>
                  </Grid>
                  {error && error === true && (
                    <Grid className="company-title3" style={{ color: "red" }}>
                      OTP is mandatory
                    </Grid>
                  )}

                  <Grid container spacing={3} className="button-grid">
                    <Grid item xs={6} sm={4} md={5} xl={5}>
                        <Button className="save" onClick={() => onSubmit()} disabled={!userInputOtp.digit1 || !userInputOtp.digit2 || !userInputOtp.digit3 || !userInputOtp.digit4 || !userInputOtp.digit5 || !userInputOtp.digit6}>Verify</Button>
                    </Grid>
                    <Grid item xs={6} sm={4} md={5} xl={5}>
                        <Link to={"/signin"}><Button className="cancel" onClick={() => handleCancel()}>Cancel</Button></Link>
                    </Grid>
                  </Grid>

                  <Grid container></Grid>
                  <Grid item xs={12}></Grid>
                </Box>
              </Grid>
              {isMobile ? (
                " "
              ) : (
                <Grid item xs={12} sm={12} md={5} xl={5} className="right-image">
                  <img src={otpverifyimg} />
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
      </Box>
      <HomePage5 />
      {isMobile ? " " : <SignupFooter />}
      </>
      )}
    </>
  );
}
