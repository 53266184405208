import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";

import {
  Box,
  OutlinedInput,
  Button,
  SnackbarContent,
  Snackbar,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import image from "assets/img/nigeria.png";
import { isMobile } from "react-device-detect";
import axios from "axios";

export default function AboutInterswitch() {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState({});
  const api_url = process.env.REACT_APP_URL;

  const [data, setData] = useState({
    name: "",
    email: "",
    mobile: "",
    comment: "",
  });

  function handleChange(e) {
    const value = e.target.value;
    setData({
      ...data,
      [e.target.name]: value,
    });
  }
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const emailValid = () => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data.email)) {
      return true;
    }
    return false;
  };
  const validation = () => {
    const email = emailValid();
    let num = parseInt(data.mobile);
    if (
      data.name === "" ||
      data.email === "" ||
      data.mobile === "" ||
      data.comment === ""
    ) {
      setOpen(true);
      setMessage({ type: "error", content: "Please fill all the information" });
      return false;
    } else if (
      /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[789]\d{9}$/.test(data.mobile) ==
      false
    ) {
      setOpen(true);
      setMessage({ type: "error", content: "Please enter a valid Number" });
      return false;
    } else if (email !== true) {
      setOpen(true);
      setMessage({
        type: "error",
        content: "Please enter a valid Email Address",
      });
      return false;
    } else {
      return true;
    }
  };
  const onSubmit = () => {
    const valid = validation(); 
    if (valid) {
      const body = {
        name: data.name,
        email: data.email,
        mobile: data.mobile,
        comment: data.comment,
      };
      axios
        .post(api_url + "/api/v1/contact/us", body, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then((res) => {
          if (res.data.status) {
            setOpen(true);
            setMessage({ type: "success", content: res.data.message });
          } else {
            setOpen(true);
            setMessage({ type: "error", content: res.data.message });
          }
        });
    }
  };
  return (
    <>
      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={open} autoHideDuration={4000} onClose={handleClose} >
        <SnackbarContent style={{ backgroundColor: message.type === "error" ? "#DC4437" : message.type === "success" ? "#74C965" : "teal", }} message={message.content} />
      </Snackbar>
      

          <Box style={{ "margin-right": "25%" }}>
            <Grid container >
              <Grid item xs={12} sm={12} md={12} xl={12}>

                <Box style={{ padding: "2%" }}>
                  <span className="company-title3">
                    Name <span style={{ color: "red" }}>*</span>
                  </span>
                  <OutlinedInput
                    fullWidth
                    className="company-title-manages"
                    placeholder="e.g. James Align"
                    name="name"
                    value={data.name}
                    onChange={handleChange}
                  />
                </Box>

                <Box style={{ padding: "2%" }}>
                  <span className="company-title3">
                    Email <span style={{ color: "red" }}>*</span>
                  </span>
                  <OutlinedInput
                    fullWidth
                    className="company-title5"
                    placeholder="e.g. james@interswitch.io"
                    name="email"
                    value={data.email}
                    onChange={handleChange}
                  />
                </Box>
                <Box style={{ padding: "2%" }}>
                  <span className="company-title3">
                    {" "}
                    Mobile <span style={{ color: "red" }}>*</span>
                  </span>
                  <OutlinedInput
                    fullWidth
                    className="company-title5"
                    placeholder="e.g. 9876543210"
                    name="mobile"
                    value={data.mobile}
                    onChange={handleChange}
                  />
                </Box>
                <Box style={{ padding: "2%" }}>
                  <span className="company-title3">
                    {" "}
                    Comments <span style={{ color: "red" }}>*</span>
                  </span>
                  <OutlinedInput
                    name="comment"
                    value={data.comment}
                    rows={4}
                    multiline
                    fullWidth
                    className="company-title5"
                    style={{ padding: "0px" }}
                    placeholder="e.g. Comments"
                    onChange={handleChange}
                  />
                </Box>
                <Box style={{ padding: "2%" }}>
                  <Button
                    fullWidth
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      lineHeight: " 132.24%",
                      fontSize: isMobile ? "14px" : "16px",
                      color: "#FFFFFF",
                      // width: isMobile ? "130px" : "184px",
                      height: "55px",
                      background: "#0275D8",
                      borderRadius: "5px",
                    }}
                    onClick={() => onSubmit()}
                  >
                    Submit
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
    </>
  );
}
