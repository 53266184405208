import React from "react";
import { Row, Col } from "react-bootstrap";
const Financal = (props) => {
  let data = props.info;
  let name = props.name;
  const currency_symbol       = localStorage.getItem('currency_symbol');
  return (
    <>
      {data && (
        <div className="mb-5">
          <Row
            className={
              name == "Financial Turnover"
                ? "cp-heading4 justify-content-center"
                : `justify-content-center cp-heading4`
            }
          >
            {name == "No of employees in company" ? "" : currency_symbol} {data.toLocaleString(undefined, {minimumFractionDigits:2})}
          </Row>
          <Row
            className={
              name == "Financial Turnover"
                ? "justify-content-center cp-sub1 ml-3"
                : `justify-content-center cp-sub1`
            }
          >
            {name}
          </Row>
        </div>
      )}
    </>
  );
};
export default Financal;
