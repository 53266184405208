import React from 'react';
import { Grid, Box, Typography, OutlinedInput, withStyles, MenuItem, FormControl, Select, Table, TableBody, TableContainer, TableCell, TableHead, TableRow, Paper, Button } from "@material-ui/core";

import Custom from './Components/Custom';
import { isMobile } from 'react-device-detect'
import PropTypes from 'prop-types';


const styles = () => ({
    // imgfluid1: {
    //     backgroundImage: `url(${image2})`,
    //     [theme.breakpoints.down('sm')]: {
    //         backgroundImage: `none`,
    //     }
    // },             //This one
    textFields: {
        background: "#F3F5F6",
        height: "16px",
        fontSize: "11px",
        marginTop: "1%",
        "& div": {
            // "& div": {

            // },
            "&::before": {
                content: "",
                borderBottom: "none",

            },
            "&::after": {
                borderBottom: "none"
            },
            "&:hover": {
                "&::before": {
                    borderBottom: "none !important"
                },
                "&::after": {
                    borderBottom: "none !important"
                },
            },
            "& .MuiInputBase-input": {
                padding: "9px 7px"
            }
        },

    },

})






function createData(sno, invoiceno, issuedate, duedate, buyername, invamount, appramount, supplier, status, action) {
    return { sno, invoiceno, issuedate, duedate, buyername, invamount, appramount, supplier, status, action };
}

const mystyles = {
    topFiltersInput: { paddingTop: "5%", paddingBottom: "5%", "borderRadius": "5px" },
    topFilterSelect: { paddingTop: "12%", paddingBottom: "5%", "borderRadius": "5px" },
    upload: { marginTop: "1%", fontSize: isMobile ? "12px" : "14px", fontWeight: isMobile ? "normal" : "600", color: "#0275D8", border: "1px solid #0275D8", width: "115%" },
    box: { paddingTop: "6%", paddingRight: "5%", paddingBottom: "5%" },
    sfo: { color: "#AAB7C6", fontSize: "12px" }

}

const rows = [
    createData(1, "2021 - 00001", "2021 - 06 - 20", "2021 - 07 - 01", "Thomson", "$1500000", "$1450000", "Fedex", "Funded"),
    createData(2, "2021 - 00001", "2021 - 06 - 20", "2021 - 07 - 01", "Thomson", "$1500000", "$1450000", "Fedex", "Varified"),
    createData(3, "2021 - 00001", "2021 - 06 - 20", "2021 - 07 - 01", "Thomson", "$1500000", "$1450000", "Fedex", "Funded"),
    createData(4, "2021 - 00001", "2021 - 06 - 20", "2021 - 07 - 01", "Thomson", "$1500000", "$1450000", "Fedex", " Varified"),
    createData(5, "2021 - 00001", "2021 - 06 - 20", "2021 - 07 - 01", "Thomson", "$1500000", "$1450000", "Fedex", "Funded"),
    createData(6, "2021 - 00001", "2021 - 06 - 20", "2021 - 07 - 01", "Thomson", "$1500000", "$1450000", "Fedex", "Varified"),
    createData(7, "2021 - 00001", "2021 - 06 - 20", "2021 - 07 - 01", "Thomson", "$1500000", "$1450000", "Fedex", "Funded"),
    createData(8, "2021 - 00001", "2021 - 06 - 20", "2021 - 07 - 01", "Thomson", "$1500000", "$1450000", "Fedex", "Varified"),
];

function ManageInvoice(props) {
    const { classes } = props;
    return (
        <>

            <Box style={{ padding: "3%", minHeight: "150vh", height: "100%", background: "#FFFFFF", paddingTop: "2%" }}>
                <Box>
                    <Grid container>
                        <Grid item xs={8} sm={8} md={10} xl={10}
                            style={{ color: "#1A4983", fontSize: "24px", fontWeight: "700", fontFamily: "Averta-SemiBold" }}>Manage Invoices
                        </Grid>

                    </Grid>
                    <Typography style={{ paddingTop: "2%", color: "#0275D8", fontSize: "16px", fontWeight: "600" }}> Filters</Typography>



                    <Grid container style={{ display: "flex", flexFlow: isMobile ? " " : "inherit", flexDirection: "row", justifyContent: "center", padding: "8px 12px" }} spacing={2}>
                        <Grid item xs={6} sm={12} md={2} xl={1} lg={2} >
                            <Box style={{ padding: "5%" }}>
                                <span className="company-title3">Start Date<span style={{ color: "red" }}>*</span></span>
                                <OutlinedInput className="company-title-manages"
                                    fullWidth
                                    placeholder=" Start Date"
                                />

                            </Box>
                        </Grid>


                        <Grid item xs={6} sm={12} md={2} xl={1} lg={2}>
                            <Box style={{ padding: "5%" }}>
                                <span className="company-title3">End Date<span style={{ color: "red" }}>*</span></span>
                                <OutlinedInput className="company-title-manages"
                                    fullWidth
                                    placeholder=" End Date"
                                />
                            </Box>
                        </Grid>


                        <Grid item xs={6} sm={12} md={2} xl={1} lg={2}>
                            <Box style={{ padding: "5%" }}>
                                <span className="company-title3">Invoice Number<span style={{ color: "red" }}>*</span></span>
                                <OutlinedInput className="company-title-manages"
                                    fullWidth
                                    placeholder=" Invoice Number"
                                />

                            </Box>
                        </Grid>


                        <Grid item xs={6} sm={12} md={2} xl={1} lg={2}>
                            <Box style={{ padding: "5%" }}>
                                <Typography className="company-title3">Invoice Range<span style={{ color: "red" }}>*</span></Typography>
                                <FormControl fullWidth className="company-title-manages">

                                    <Select
                                        displayEmpty
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Age"
                                        renderValue={(select) => { if (!select) { return <Typography className="company-title-manages" >Select from options</Typography> } return select }}

                                    >
                                        <MenuItem value="" disabled>Select from options</MenuItem>
                                        <MenuItem value={10}>Ten</MenuItem>
                                        <MenuItem value={20}>Twenty</MenuItem>
                                        <MenuItem value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>


                        <Grid item xs={6} sm={12} md={2} xl={1} lg={2}>
                            <Box style={{ padding: "5%" }}>
                                <Typography className="company-title3">Select Supplier<span style={{ color: "red" }}>*</span></Typography>
                                <FormControl fullWidth className="company-title-manages">

                                    <Select
                                        displayEmpty
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Age"
                                        renderValue={(select) => { if (!select) { return <Typography className="company-title-manages" >Select from options</Typography> } return select }}

                                    >
                                        <MenuItem value="" disabled>Select from options</MenuItem>
                                        <MenuItem value={10}>Ten</MenuItem>
                                        <MenuItem value={20}>Twenty</MenuItem>
                                        <MenuItem value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>


                        </Grid>

                        <Grid item xs={6} sm={12} md={2} xl={1} lg={2}>
                            <Box style={{ padding: "5%" }}>
                                <Typography className="company-title3">Select Buyer<span style={{ color: "red" }}>*</span></Typography>
                                <FormControl fullWidth className="company-title-manages">

                                    <Select
                                        displayEmpty
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Age"
                                        renderValue={(select) => { if (!select) { return <Typography className="company-title-manages" >Select from options</Typography> } return select }}

                                    >
                                        <MenuItem value="" disabled>Select from options</MenuItem>
                                        <MenuItem value={10}>Ten</MenuItem>
                                        <MenuItem value={20}>Twenty</MenuItem>
                                        <MenuItem value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>

                        </Grid>

                        <Grid item xs={6} sm={12} md={2} xl={1} lg={2}>
                            <Box style={{ padding: "5%" }}>
                                <Typography className="company-title3">Select Status<span style={{ color: "red" }}>*</span></Typography>
                                <FormControl fullWidth className="company-title-manages">

                                    <Select
                                        displayEmpty
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Age"
                                        renderValue={(select) => { if (!select) { return <Typography className="company-title-manages" >Select from options</Typography> } return select }}

                                    >
                                        <MenuItem value="" disabled>Select from options</MenuItem>
                                        <MenuItem value={10}>Ten</MenuItem>
                                        <MenuItem value={20}>Twenty</MenuItem>
                                        <MenuItem value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>


                        </Grid>

                        <Grid item xs={6} sm={12} md={2} xl={1} lg={1}>
                            <Box style={{ paddingTop: "27%", paddingBottom: "5%", paddingRight: "10%", }}>

                                <Button style={{ "alignItems": "center", "width": "90px", "height": "37px", color: '#FFF', "background": "#479FC8", "borderRadius": "5px" }} >Submit</Button>

                            </Box>
                        </Grid>
                        <Grid item xs={6} sm={12} md={2} xl={1} lg={1}>
                            <Box style={{ paddingTop: "27%", paddingBottom: "5%", paddingRight: "10%" }}>

                                <Button style={{ "alignItems": "center", color: "#479FC8", "width": "90px", "height": "37px", "background": "#EBF8FE", "borderRadius": "5px" }}>Clear</Button>

                            </Box>
                        </Grid>


                    </Grid>
                    <Grid>
                        <Typography style={{ paddingTop: "2%", color: "#0275D8", fontSize: "16px", fontWeight: "600" }}> Invoice List</Typography>

                    </Grid>
                    <Box>
                        <TableContainer component={Paper}>
                            <Table style={{ padding: "27px", borderCollapse: 'separate', borderSpacing: '0px 5px' }} sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow style={{ background: "#F3F5F6", borderRadius: "4x" }}>
                                        <TableCell>S. No. </TableCell>
                                        <TableCell align="left">Invoice Number</TableCell>
                                        <TableCell align="left">Issue Date </TableCell>
                                        <TableCell align="left">Due Date</TableCell>
                                        <TableCell align="left"> Buyer Name</TableCell>
                                        <TableCell align="left">Inv Amount</TableCell>
                                        <TableCell align="left">Appr Amount</TableCell>
                                        <TableCell align="left">Supplier</TableCell>
                                        <TableCell align="left">Status</TableCell>
                                        <TableCell className="left">Upload</TableCell>
                                        <TableCell align="left">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody >
                                    {rows.map((row) => (
                                        <TableRow
                                            style={{ background: "#F3F5F6" }}
                                            key={row.name}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.sno}
                                            </TableCell>
                                            <TableCell align="left">{row.invoiceno}</TableCell>
                                            <TableCell align="left">{row.issuedate}</TableCell>
                                            <TableCell align="left">{row.duedate}</TableCell>
                                            <TableCell align="left">{row.buyername}</TableCell>
                                            <TableCell align="left">{row.invamount}</TableCell>
                                            <TableCell align="left">{row.appramount}</TableCell>
                                            <TableCell align="left">{row.supplier}</TableCell>
                                            <TableCell align="left">{row.status}</TableCell>
                                            <TableCell style={mystyles.tableCell}>{
                                                <Box style={{ width: "100%" }}>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            displayEmpty
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            renderValue={(select) => { if (!select) { return <Typography className="company-title4" style={{ marginLeft: "10%", marginTop: "10%" }} >Upload</Typography> } return select }}

                                                        >

                                                            <MenuItem value="" disabled>Upload</MenuItem>
                                                            <MenuItem value={1}>One</MenuItem>
                                                            <MenuItem value={2}>Two</MenuItem>
                                                            <MenuItem value={3}>Three</MenuItem>
                                                            <MenuItem value={4}>Four</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                            }</TableCell>
                                            <TableCell align="left">{<Custom />}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>


                </Box>
                <Grid container>
                    <Grid item xs={12} sm={12} md={4} xl={4}>
                        <Box style={mystyles.box}>
                            <Typography className="company-title3">Select On Page Display Limit   <span style={{ color: "red" }}>*</span ></Typography>
                            <FormControl fullWidth className={classes.textFields}>
                                <Select
                                    displayEmpty
                                    renderValue={(select) => { if (!select) { return <Typography style={mystyles.sfo}>Select from options</Typography> } return select }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Age"
                                >
                                    <MenuItem value={""}> Select from options</MenuItem>

                                    <MenuItem value={10}>Ten</MenuItem>
                                    <MenuItem value={20}>Twenty</MenuItem>
                                    <MenuItem value={30}>Thirty</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                </Grid>

            </Box>



        </>
    )
}

ManageInvoice.propTypes = {
    classes: PropTypes.object
}


export default withStyles(styles)(ManageInvoice);