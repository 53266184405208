import React from "react";
import Grid from "@material-ui/core/Grid";
import image2 from "../../assets/img/Financedoodle.svg";
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-kit-react/views/components.js";
import { Typography } from "@material-ui/core";
import image1 from "../../assets/img/p1.png";
import image3 from "../../assets/img/p2.png";
import image4 from "../../assets/img/p3.png";
import image5 from "../../assets/img/p4.png";
import DetailCard from "../Components/HomePageComponents/DetailCard.js";
const useStyles = makeStyles(styles);

export default function CoreValues() {
  const classes = useStyles();
  return (
    <div
      className={classes.imgfluid}
      style={{ backgroundImage: `url(${image2})` }}
    >
      <div>
        <Grid container style={{ width: "98vw", height: "100%" }}>
          <Grid item xs={12}>
            <div
              style={{
                color: "#FFFFFF",
                textAlign: "center",
                paddingTop: "86px",
                marginBottom: "15px",
              }}
            >
              <Typography
                style={{
                  fontWeight: "700",
                  fontFamily: "Averta-Regular",
                  fontSize: "32px",
                  lineHeight: "38.41px",
                  letter: "3%",
                  style: "normal",
                  color: "#fff",
                }}
              >
                Our Presence
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div
              style={{
                paddingRight: "10%",
                paddingLeft: "10%",
                color: "#FFFFFF",
                textAlign: "center",
                "margin-bottom": "10px",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Raleway",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "19.6px",
                  color: "#FFF",
                }}
              >
                Lets explore available possibilities or design
              </Typography>
              <Typography
                style={{
                  fontFamily: "Raleway",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "19.6px",
                  color: "#FFF",
                }}
              >
                bespoke solutions to meet your business needs
              </Typography>
            </div>
          </Grid>
          <Grid item xs={6} sm={6} md={3} xl={3}>
            <DetailCard
              img={image1}
              title="Germany"
              details="Our customers are the ultimate beneficiaries in everything we do"
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
