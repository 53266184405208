import React from "react";
import { Grid, Typography, Toolbar } from "@material-ui/core";
import { Box } from "@material-ui/core";
import HomePage from "views/HomePage/HomePage";
import HomePage5 from "views/HomePage/HomePage5";
import BottomComponent from "views/Components/HomePageComponents/BottomComponent";
import SignupFooter from "views/Signup/footer";
import { isMobile } from "react-device-detect";

export default function Terms() {
  return (
    <>
      <Box style={{ height: "100%" }}>
        <Grid>
          <Box>
            <HomePage />
          </Box>
          {isMobile ? (
            " "
          ) : (
            <Box style={{ border: "black", background: "#FDED94" }}>
              <Typography
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  textAlign: "center",
                  padding: "2% 0% 4% 0%",
                  height: "50px",
                }}
              >
                <b> Announcements -</b> Lorem Ipsum is simply dummy text of the
                printing and typesetting industry.{" "}
              </Typography>
            </Box>
          )}
        </Grid>
        <Box style={{ background: "#FFFFFF", padding: "6%" }}>
          <Typography
            style={{
              height: "20px",
              marginTop: isMobile ? "-8%" : " ",
              fontFamily: "Averta-SemiBold",
              margin: "12px",
              color: "#18425D",
              fontSize: "24px",
              fontWeight: "700",
            }}
          >
            Interswitch Terms of Use
          </Typography>
          <Typography
            style={{
              margin: "12px",
              fontFamily: "Averta-Regular",
              height: "30px",
              color: "#479FC8",
              fontSize: "16px",
              fontWeight: "600",
            }}
          >
            Effective Date: October 1, 2015
          </Typography>

          <Grid>
            <br />
            <Typography
              style={{
                margin: "12px",
                fontFamily: "Averta-Regular",
                color: "#353F50",
                fontSize: "12px",
                fontWeight: "400",
              }}
            >
              If you require any more information or have any questions about
              our Terms of Service, please feel free to contact us by email
              risk@interswitchgroup.com
              <br />
              <br />
              These terms and conditions govern the use of this website; by
              using this website, you hereby accept these terms and conditions
              in full and without any reservation. If you disagree with these
              terms and conditions or any part of these terms and conditions,
              then you must not use this website.
              <br />
              <br />
              Furthermore, you must be at least 18 [eighteen] years of age to
              use this website. By using this website and by agreeing to these
              terms and conditions, you hereby warrant and represent that you
              are at least 18 years of age.
            </Typography>

            <br />
            <Typography
              style={{
                margin: "12px",
                fontFamily: "Averta-Regular",
                height: "9px",
                color: "#353F50",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              License to use website
            </Typography>

            <Typography
              style={{
                margin: "12px",
                fontFamily: "Averta-Regular",
                color: "#353F50",
                fontSize: "12px",
                fontWeight: "400",
              }}
            >
              Unless otherwise stated, Interswitch Limited and/or its licensors
              own the intellectual property rights published on this website and
              materials used on www.interswitchgroup.com.
              <br />
              <br />
              Subject to the license below, all these intellectual property
              rights are reserved. You may view, download for caching purposes
              only, and print pages, files or other content from the website for
              your own personal use, subject to the restrictions set out below
              and elsewhere in these terms and conditions.
              <ul>
                <li>
                  Republish material from this website in neither print nor
                  digital media or documents (including republication on another
                  website);
                </li>
                <br />
                <li>Sell, rent or sub-license material from the website;</li>
                <br />
                <li>show any material from the website in public;</li>
                <br />
                <li>
                  reproduce, duplicate, copy or otherwise exploit material on
                  this website for a commercial purpose;
                </li>
                <br />
                <li>
                  redistribute material from this website - except for content
                  specifically and expressly made available for redistribution;
                  or
                </li>
                <br />
                <li>
                  republish or reproduce any part of this website through
                  screenshots.
                </li>
                <br />
                <li>
                  Where content is specifically made available for
                  redistribution, it may only be redistributed within your
                  organization.
                </li>
              </ul>
            </Typography>
            <br />
            <br />
            <Typography
              style={{
                margin: "12px",
                fontFamily: "Averta-Regular",
                height: "9px",
                color: "#353F50",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              Acceptable Use
            </Typography>
            <Typography
              style={{
                margin: "12px",
                fontFamily: "Averta-Regular",
                color: "#353F50",
                fontSize: "12px",
                fontWeight: "400",
              }}
            >
              This website must not be used in any way that causes, or may
              cause, damage to the website or impairment of the availability or
              accessibility of www.interswitchgroup.com or in any way which is
              unlawful, illegal, fraudulent or harmful, or in connection with
              any unlawful, illegal, fraudulent or harmful purpose or activity
              <br />
              <br />
              This website must not be used to copy, store, host, transmit,
              send, use, publish or distribute any material which consists of
              (or is linked to) any spyware, computer virus, Trojan, or other
              malicious computer software. You must not conduct any systematic
              or automated data collection activities on or in relation to this
              website without Interswitch Limited express written consent. E.g.
              scraping, data mining, data extraction, data harvesting,
              screenshots.
              <br />
              <br />
              This website or any part thereof must not be used to transmit or
              send unsolicited commercial communications, nor must it be used
              for any purposes related to marketing without the express written
              consent of Interswitch Limited.
            </Typography>
            <br />
            <Typography
              style={{
                margin: "12px",
                fontFamily: "Averta-Regular",
                height: "9px",
                color: "#353F50",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              Restricted Access
            </Typography>
            <Typography
              style={{
                margin: "12px",
                fontFamily: "Averta-Regular",
                color: "#353F50",
                fontSize: "12px",
                fontWeight: "400",
              }}
            >
              Access to certain areas of this website is restricted. Interswitch
              Limited reserves the right to restrict access to certain areas of
              this website, or at our discretion, this entire website.
              <br />
              <br />
              Interswitch Limited may change or modify this policy without
              notice. If Interswitch Limited provides you with a user ID and
              password to enable you to access restricted areas of this website
              or other content or services, you must ensure that the user ID and
              password are kept confidential. You alone are responsible for your
              password and user ID security. Interswitch Limited may disable
              your user ID and password at its sole discretion without notice or
              explanation.
            </Typography>
            <br />
            <Typography
              style={{
                margin: "12px",
                fontFamily: "Averta-Regular",
                height: "9px",
                color: "#353F50",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              User Content
            </Typography>
            <Typography
              style={{
                margin: "12px",
                fontFamily: "Averta-Regular",
                color: "#353F50",
                fontSize: "12px",
                fontWeight: "400",
              }}
            >
              In these terms and conditions, “your user content” means material
              (including without limitation text, images, audio material, video
              material and audio-visual material) that you submit to this
              website, for whatever purpose. You grant to Interswitch Limited a
              worldwide, irrevocable, non-exclusive, royalty-free license to
              use, reproduce, adapt, publish, translate and distribute your user
              content in any existing or future media. You also grant to
              Interswitch Limited the right to sub-license these rights, and the
              right to bring an action for infringement of these rights. Your
              user content must not be illegal or unlawful, must not infringe
              any third partys legal rights, and must not be capable of giving
              rise to legal action whether against you or Interswitch Limited or
              a third party (in each case under any applicable law).
              <br />
              <br />
              You must not submit any user content to the website that is or has
              ever been the subject of any threatened or actual legal
              proceedings or other similar complaint. Interswitch Limited
              reserves the right to edit or remove any material submitted to
              this website, or stored on its servers, or hosted or published
              upon this website. In relation to user content, Interswitch
              Limited under these terms and conditions does not undertake to
              monitor the submission of such content to, or the publication of
              such content on, this website.
            </Typography>
            <br />
            <Typography
              style={{
                margin: "12px",
                fontFamily: "Averta-Regular",
                color: "#353F50",
                height: "9px",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              No warranties
            </Typography>
            <Typography
              style={{
                margin: "12px",
                fontFamily: "Averta-Regular",
                color: "#353F50",
                fontSize: "12px",
                fontWeight: "400",
              }}
            >
              This website is provided “as is” without any representations or
              warranties, express or implied. Interswitch Limited makes no
              representations or warranties in relation to this website or the
              information and materials provided on this website. Without
              prejudice to the generality of the foregoing paragraph,
              Interswitch Limited does not warrant that this website will be
              constantly available, or available at all; or the information on
              this website is complete, true, accurate or non-misleading.
              <br />
              <br />
              Nothing on this website constitutes, or is meant to constitute,
              advice of any kind. If you require advice in relation to any
              legal, financial or medical matter you should consult an
              appropriate professional.
            </Typography>
            <br />
            <Typography
              style={{
                margin: "12px",
                fontFamily: "Averta-Regular",
                color: "#353F50",
                height: "9px",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              Limitations of liabilities
            </Typography>
            <Typography
              style={{
                margin: "12px",
                fontFamily: "Averta-Regular",
                color: "#353F50",
                fontSize: "12px",
                fontWeight: "400",
              }}
            >
              Interswitch Limited will not be liable to you (whether under the
              law of contact, the law of torts or otherwise) in relation to the
              contents of, or use of, or otherwise in connection with, this
              website:
              <br />
              <br />
              to the extent that the website is provided free-of-charge, for any
              direct loss; for any indirect, special or consequential loss; or
              for any business losses, loss of revenue, income, profits or
              anticipated savings, loss of contracts or business relationships,
              loss of reputation or goodwill, or loss or corruption of
              information or data.
              <br />
              <br />
              These limitations of liability apply even if Interswitch Limited
              has been expressly advised of the potential loss.
            </Typography>
            <br />
            <Typography
              style={{
                margin: "12px",
                fontFamily: "Averta-Regular",
                color: "#353F50",
                height: "9px",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              Exceptions
            </Typography>
            <Typography
              style={{
                margin: "12px",
                fontFamily: "Averta-Regular",
                color: "#353F50",
                fontSize: "12px",
                fontWeight: "400",
              }}
            >
              Nothing in this website disclaimer will exclude or limit any
              warranty implied by law and which is unlawful to exclude or limit;
              and nothing in this website disclaimer will exclude or limit the
              liability of Interswitch Limited in respect of any: death or
              personal injury caused by the negligence of Interswitch Limited or
              its agents, employees or shareholders/owners; fraud or fraudulent
              misrepresentation on the part of Interswitch Limited; or matter
              which it would be illegal or unlawful for Interswitch Limited to
              exclude or limit, or to attempt or purport to exclude or limit,
              its liability.
            </Typography>
            <br />
            <Typography
              style={{
                margin: "12px",
                fontFamily: "Averta-Regular",
                color: "#353F50",
                height: "9px",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              Reasonable
            </Typography>
            <Typography
              style={{
                margin: "12px",
                fontFamily: "Averta-Regular",
                color: "#353F50",
                fontSize: "12px",
                fontWeight: "400",
              }}
            >
              By using this website, you agree that the exclusions and
              limitations of liability set out in this website disclaimer are
              reasonable. If you do not think they are reasonable, you must not
              use this website.
            </Typography>
            <br />
            <Typography
              style={{
                margin: "12px",
                fontFamily: "Averta-Regular",
                color: "#353F50",
                height: "9px",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              Other Parties
            </Typography>
            <Typography
              style={{
                margin: "12px",
                fontFamily: "Averta-Regular",
                color: "#353F50",
                fontSize: "12px",
                fontWeight: "400",
              }}
            >
              You accept that, as a limited liability entity, Interswitch
              Limited has an interest in limiting the personal liability of its
              officers and employees. You agree that you will not bring any
              claim personally against Interswitch Limiteds officers or
              employees in respect of any losses you suffer in connection with
              the website. Without prejudice to the foregoing paragraph, you
              agree that the limitations of warranties and liability set out in
              this website disclaimer will protect Interswitch Limited officers,
              employees, agents, subsidiaries, affiliates, successors, assigns
              and sub-contractors.
            </Typography>
            <br />
            <Typography
              style={{
                margin: "12px",
                fontFamily: "Averta-Regular",
                color: "#353F50",
                height: "9px",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              Unenforceable Provision
            </Typography>
            <Typography
              style={{
                margin: "12px",
                fontFamily: "Averta-Regular",
                color: "#353F50",
                fontSize: "12px",
                fontWeight: "400",
              }}
            >
              If any provision of this website disclaimer is, or is found to be,
              unenforceable under applicable law, that will not affect the
              enforceability of the other provisions of this website disclaimer.
            </Typography>
            <br />
            <Typography
              style={{
                margin: "12px",
                fontFamily: "Averta-Regular",
                color: "#353F50",
                height: "9px",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              Indemnity
            </Typography>
            <Typography
              style={{
                margin: "12px",
                fontFamily: "Averta-Regular",
                color: "#353F50",
                fontSize: "12px",
                fontWeight: "400",
              }}
            >
              You hereby indemnify Interswitch Limited and undertake to keep
              Interswitch Limited indemnified against any losses, damages,
              costs, liabilities and expenses (including without limitation
              legal expenses and any amounts paid by Interswitch Limited to a
              third party in settlement of a claim or dispute on the advice of
              Interswitch Limiteds legal advisers) incurred or suffered by
              Interswitch Limited arising out of any breach by you of any
              provision of these terms and conditions, or arising out of any
              claim that you have breached any provision of these terms and
              conditions.
            </Typography>
            <br />
            <Typography
              style={{
                margin: "12px",
                color: "#353F50",
                height: "9px",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              Breaches of these Terms and Conditions
            </Typography>
            <Typography
              style={{
                margin: "12px",
                fontFamily: "Averta-Regular",
                color: "#353F50",
                fontSize: "12px",
                fontWeight: "400",
              }}
            >
              Without prejudice to Interswitch Limited other rights under these
              terms and conditions, if you breach these terms and conditions in
              any way, Interswitch Limited may take such action(s);it deems
              appropriate to deal with the breach, including suspending your
              access to the website, prohibiting you from accessing the website,
              blocking computers using your IP address from accessing the
              website, contacting your Internet service provider to request that
              they block your access to the website and/or bringing court
              proceedings against you.
            </Typography>
            <br />
            <Typography
              style={{
                margin: "12px",
                fontFamily: "Averta-Regular",
                color: "#353F50",
                height: "9px",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              Variation
            </Typography>
            <Typography
              style={{
                margin: "12px",
                fontFamily: "Averta-Regular",
                color: "#353F50",
                fontSize: "12px",
                fontWeight: "400",
              }}
            >
              Interswitch Limited may revise these terms and conditions from
              time-to-time. Revised terms and conditions will apply to the use
              of this website from the date of the publication of the revised
              terms and conditions on this website. Please check this page
              regularly to ensure you are familiar with the current version.
            </Typography>
            <br />
            <Typography
              style={{
                margin: "12px",
                fontFamily: "Averta-Regular",
                color: "#353F50",
                height: "9px",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              Assignment
            </Typography>
            <Typography
              style={{
                margin: "12px",
                fontFamily: "Averta-Regular",
                color: "#353F50",
                fontSize: "12px",
                fontWeight: "400",
              }}
            >
              Interswitch Limited may transfer, sub-contract or otherwise deal
              with its rights and/or obligations under these terms and
              conditions without notifying you or obtaining your consent. You
              may not transfer, sub-contract or otherwise deal with your rights
              and/or obligations under these terms and conditions.
            </Typography>
            <br />
            <Typography
              style={{
                margin: "12px",
                fontFamily: "Averta-Regular",
                color: "#353F50",
                fontSize: "14px",
                height: "9px",
                fontWeight: "400",
              }}
            >
              Entire Agreement
            </Typography>
            <Typography
              style={{
                margin: "12px",
                fontFamily: "Averta-Regular",
                color: "#353F50",
                fontSize: "12px",
                fontWeight: "400",
              }}
            >
              These terms and conditions, together with Interswitch Limited
              Privacy Policy; constitute the entire agreement between you and
              Interswitch Limited in relation to your use of this website, and
              supersede all previous agreements in respect of your use of this
              website.
            </Typography>
            <br />
            <Typography
              style={{
                margin: "12px",
                fontFamily: "Averta-Regular",
                color: "#353F50",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              Law and Jurisdiction
            </Typography>
            <Typography
              style={{
                margin: "12px",
                fontFamily: "Averta-Regular",
                color: "#353F50",
                fontSize: "12px",
                fontWeight: "400",
              }}
            >
              These terms and conditions will be governed by and construed in
              accordance with the laws of the Federal Republic of Nigeria, and
              any disputes relating to these terms and conditions will be
              subject to the exclusive jurisdiction of the courts thereof.
            </Typography>
          </Grid>
        </Box>
      </Box>
      <BottomComponent />
      <HomePage5 />
      {isMobile ? " " : <SignupFooter />}
    </>
  );
}
