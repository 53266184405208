import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import image from "../../assets/img/coun1.svg";
import image1 from "../../assets/img/coun2.svg";
import image2 from "../../assets/img/coun3.svg";
import image3 from "../../assets/img/coun4.svg";
import image4 from "../../assets/img/countries.svg";
import { isMobile } from "react-device-detect";
import SignupFooter from "../Signup/footer";
import axios from "axios";
import { useHistory, Link } from "react-router-dom";

const FooterColumn = (props) => {
  const [header, setHeader] = useState(props.header);
  const [name, setName] = useState(props.names);
  const api_url = process.env.REACT_APP_URL;
  const history = useHistory();

  return (
    <>
      {name && header && name !== undefined && header !== undefined && (
        <>
          <div className="menu-heading">{header} </div>
          {name.map((data, page5_index) => (
            <div key={page5_index} className="menu-div">
              {data.display_name && data.display_name !== undefined && (
                <>
                  {data.image_url && data.image_url !== "" && (
                    <>
                      <img src={`${api_url + data.image_url}?${Date.now()}`} className="footer-image" />{" "}
                    </>
                  )}
                  {data.main_head === "Footprint" ? (
                    <Link className="footer-links" to="#">
                      {data.display_name}
                    </Link>
                  ) : (
                    <a className="footer-links" href={"/cms/" + data.page_type_name} >
                      {data.display_name}
                    </a>
                  )}
                </>
              )}
            </div>
          ))}
        </>
      )}
    </>
  );
};
export default function HomePage5() {
  // const classes = useStyles();
  const [head1, setHead1] = useState([]);
  const [head2, setHead2] = useState([]);
  const [head3, setHead3] = useState([]);
  const [head4, setHead4] = useState([]);
  const [head5, setHead5] = useState([]);
  const [sub1, setSub1] = useState([]);
  const [sub2, setSub2] = useState([]);
  const [sub3, setSub3] = useState([]);
  const [sub4, setSub4] = useState([]);
  const [sub5, setSub5] = useState([]);

  const getData = () => {
    const api_url = process.env.REACT_APP_URL;
    const filterData = (info, main_head) => {
      let data = info.filter((obj) => main_head == obj.main_head);
      return data;
    };
    const arrayData = (info, main_head) => {
      let data = [
        filterData(info, main_head[0]),
        filterData(info, main_head[1]),
        filterData(info, main_head[2]),
        filterData(info, main_head[3]),
        filterData(info, main_head[4]),
      ];
      return data;
    };
    axios
      .get(api_url + "/api/v1/footer", {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((res) => {
        if (res.status == 200) {
          let info = res.data.column1;
          let info2 = res.data.column2;
          let info3 = res.data.column3;
          let info4 = res.data.column4;
          let info5 = res.data.column5;
          let main_head = [];
          let main_head2 = [];
          let main_head3 = [];
          let main_head4 = [];
          let main_head5 = [];
          info.map((data) => {
            if (main_head.includes(data.main_head)) {
              return "";
            } else {
              main_head.push(data.main_head);
            }
          });
          info2.map((data) => {
            if (main_head2.includes(data.main_head)) {
              return "";
            } else {
              main_head2.push(data.main_head);
            }
          });
          info3.map((data) => {
            if (main_head3.includes(data.main_head)) {
              return "";
            } else {
              main_head3.push(data.main_head);
            }
          });
          info4.map((data) => {
            if (main_head4.includes(data.main_head)) {
              return "";
            } else {
              main_head4.push(data.main_head);
            }
          });
          info5.map((data) => {
            if (main_head5.includes(data.main_head)) {
              return "";
            } else {
              main_head5.push(data.main_head);
            }
          });
          setHead1(main_head);
          setHead2(main_head2);
          setHead3(main_head3);
          setHead4(main_head4);
          setHead5(main_head5);
          setSub1(arrayData(info, main_head));
          setSub2(arrayData(info2, main_head2));
          setSub3(arrayData(info3, main_head3));
          setSub4(arrayData(info4, main_head4));
          setSub5(arrayData(info5, main_head5));
        }
      });
  };
  const LinkRedirect = (data) => {
    window.open(api_url + data);
  };
  useEffect(() => {
    getData();
  }, []);
  return !isMobile ? (
    <div className="footer-menus">
      <Grid container spacing={10} className="footer-container">
        <Grid item xs={2.4}>
          {head1 && sub1 && head1.length > 0 && sub1.length > 0 && (
            <>
              <FooterColumn header={head1[0]} names={sub1[0]} />
              <FooterColumn header={head1[1]} names={sub1[1]} />
              <FooterColumn header={head1[2]} names={sub1[2]} />
              <FooterColumn header={head1[3]} names={sub1[3]} />
              <FooterColumn header={head1[4]} names={sub1[4]} />
            </>
          )}
        </Grid>
        <Grid item xs={2.4}>
          {head2 && sub2 && head2.length > 0 && sub2.length > 0 && (
            <>
              <FooterColumn header={head2[0]} names={sub2[0]} />
              <FooterColumn header={head2[1]} names={sub2[1]} />
              <FooterColumn header={head2[2]} names={sub2[2]} />
              <FooterColumn header={head2[3]} names={sub2[3]} />
              <FooterColumn header={head2[4]} names={sub2[4]} />
            </>
          )}
        </Grid>
        <Grid item xs={2.4}>
          {head3 && sub3 && head3.length > 0 && sub3.length > 0 && (
            <>
              <FooterColumn header={head3[0]} names={sub3[0]} />
              <FooterColumn header={head3[1]} names={sub3[1]} />
              <FooterColumn header={head3[2]} names={sub3[2]} />
              <FooterColumn header={head3[3]} names={sub3[3]} />
              <FooterColumn header={head3[4]} names={sub3[4]} />
            </>
          )}
        </Grid>
        <Grid item xs={2.4}>
          {head4 && sub4 && head4.length > 0 && sub4.length > 0 && (
            <>
              <FooterColumn header={head4[0]} names={sub4[0]} />
              <FooterColumn header={head4[1]} names={sub4[1]} />
              <FooterColumn header={head4[2]} names={sub4[2]} />
              <FooterColumn header={head4[3]} names={sub4[3]} />
              <FooterColumn header={head4[4]} names={sub4[4]} />
            </>
          )}
        </Grid>
        <Grid item xs={2.4}>
          {head5 && sub5 && head5.length > 0 && sub5.length > 0 && (
            <>
              <FooterColumn header={head5[0]} names={sub5[0]} />
              <FooterColumn header={head5[1]} names={sub5[1]} />
              <FooterColumn header={head5[2]} names={sub5[2]} />
              <FooterColumn header={head5[3]} names={sub5[3]} />
              <FooterColumn header={head5[4]} names={sub5[4]} />
            </>
          )}
        </Grid>
      </Grid>
    </div>
  ) : (
    ""
  );
}
