import React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import { Grid, Box } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import PropTypes from "prop-types";

export default function TableComponent(props) {
    const { id, A1, A2, A3, A4, A5, A6, A7, A8, A9, A10, A11, A12 } = props;

    return (

        <Accordion style={{ background: "#F3F5F6" }}>

            < AccordionSummary >
                <Grid container>
                    <Grid item xs={3} sm={3}>
                        <Box>
                            <Typography style={{ fontFamily: "Averta-Regular", fontSize: "12px", fontWeight: "400", color: "#18425D", marginTop: "9%", lineHeight: "16px" }}>
                                {A1}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={3} sm={3}>
                        <Box style={{ padding: "2%" }}>

                            <Typography style={{ fontFamily: "Averta-Regular", fontSize: "12px", fontWeight: "400", color: "#18425D", marginTop: "9%", lineHeight: "16px" }} >
                                {A2}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={2} sm={2}>
                        <Box style={{ padding: "2%" }}>

                            <Typography style={{ fontFamily: "Averta-Regular", fontSize: "12px", fontWeight: "400", color: "#18425D", marginTop: "15%", lineHeight: "16px" }} >
                                {A3}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                        <Box style={{ padding: "0%" }}>

                            <Typography style={{ fontFamily: "Averta-Regular", fontSize: "12px", fontWeight: "400", color: "#18425D", marginTop: "9%", lineHeight: "16px" }} >
                                {A4}
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={4} sm={4}>
                        <Box style={{ padding: "0%" }}>

                            <Typography style={{ fontFamily: "Averta-Regular", fontSize: "12px", fontWeight: "400", color: "#18425D", marginTop: "9%", lineHeight: "16px" }} >
                                {A12}
                            </Typography>
                        </Box>
                    </Grid>


                </Grid>
            </AccordionSummary>

            <AccordionDetails>
                <Grid container spacing={1}>
                    <Grid item xs={3} sm={3}>
                        <Box style={{
                            padding: "2%"
                        }}>
                            < Typography className="textClass" style={{ fontFamily: "Averta-Regular", fontSize: "12px", fontWeight: "400", color: "#18425D", paddingTop: "7%", lineHeight: "16px", dislay: "flex" }} >
                                {A5}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={3} sm={3}>
                        <Box style={{ padding: "2%" }}>

                            <Typography className="textClass" style={{ fontFamily: "Averta-Regular", fontSize: "12px", fontWeight: "400", color: "#18425D", paddingTop: "7%", lineHeight: "16px" }} >
                                {A6}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={3} sm={3}>
                        <Box style={{ padding: "2%" }}>

                            <Typography className="textClass" style={{ fontFamily: "Averta-Regular", fontSize: "12px", fontWeight: "400", color: "#18425D", paddingTop: "7%", lineHeight: "16px" }} >
                                {A7}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={3} sm={3}>
                        <Box style={{ padding: "2%" }}>

                            <Typography className="textClass" style={{ fontFamily: "Averta-Regular", fontSize: "12px", fontWeight: "400", color: "#18425D", paddingTop: "7%", lineHeight: "16px" }} >
                                {A8}
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={3} sm={3}>
                        <Box style={{ padding: "2%" }}>

                            <Typography className="textClass" style={{ fontFamily: "Averta-Regular", fontSize: "12px", fontWeight: "400", color: "#18425D", paddingTop: "7%", lineHeight: "16px" }} >
                                {A9}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={3} sm={3}>
                        <Box style={{ padding: "2%" }}>

                            <Typography className="textClass" style={{ fontFamily: "Averta-Regular", fontSize: "12px", fontWeight: "400", color: "#18425D", paddingTop: "7%", lineHeight: "16px" }} >
                                {A10}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={3} sm={3}>
                        <Box style={{ padding: "2%" }}>

                            <Typography className="textClass" style={{ fontFamily: "Averta-Regular", fontSize: "12px", fontWeight: "400", color: "#18425D", paddingTop: "7%", lineHeight: "16px" }} >
                                {A11}
                            </Typography>
                        </Box>
                    </Grid>

                </Grid>
            </AccordionDetails >

        </Accordion >
    );
}

TableComponent.defaultProp = {
    A1: "",
    A2: "",
    A3: "",
    A4: "",
    A5: "",
    A6: "",
    A7: "",
    A8: "",
    A9: "",
    A10: "",
    A11: "",
    A12: ""
};

TableComponent.propTypes = {
    A1: PropTypes.any,
    A2: PropTypes.any,
    A3: PropTypes.any,
    A4: PropTypes.any,
    A5: PropTypes.any,
    A6: PropTypes.any,
    A7: PropTypes.any,
    A8: PropTypes.any,
    A9: PropTypes.any,
    A10: PropTypes.any,
    A11: PropTypes.any,
    A11: PropTypes.any,
    A12: PropTypes.any,


};

