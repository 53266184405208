import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { Paper } from "@material-ui/core";
import Card from "../../../Components/Common/Card";
import Financal from "../../../Components/Common/Financal";
import Header from "../../../Components/Common/Header";
import ContactDetail from "../../../Components/Common/ContactDetail";
import ThemeContainer from "views/Components/sideBar/ThemeContainer";
import EditICon from "../../../../assets/img/editIcon.svg";
import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";

const CompanyProfile = () => {
  //const userId        = localStorage.getItem("userId");
  const userName                    = localStorage.getItem("userName");
  const history                     = useHistory();
  const api_url                     = process.env.REACT_APP_URL;
  const userId                      = parseInt(localStorage.getItem("userId"));
  const accessToken                 = localStorage.getItem("access_token");
  const [data, setData]             = useState({});
  const userRole                    = localStorage.getItem("user_role");
  const is_lender                   = localStorage.getItem("is_lender");
  const trader_type                 = localStorage.getItem('trader_type');
  const [loader, setLoader]         = useState(false);

  if(!userId || userName === null){
      history.push('/signin');
  }

  if(is_lender === "true" && trader_type === "undefined"){
    history.push('/lender/dashboard');
  }
    
  const getData = () => {
    const body = {
      user_id: userId,
      access_token: accessToken,
    };
    axios
      .post(api_url + "/api/v1/company/information/detail", body, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((res) => {
        if (res.data.success) {
          let info = res.data;

          setData({
            contactList: info.contact_list,
            addressList: info.correspondence_address_list,
            trader: info.trader,
            success: info.success,
          });
          setLoader(true)
        } else {
          setLoader(true)
        }
      });
  };
  useEffect(() => {
    document.title = 'Interswitch - Company Profile';
    getData();
  }, []);

  return (
    <>
      {loader !== true ? (
          <Grid container spacing={3} className="bootstrap-loader">
            
              <Spinner animation="grow" variant="spinner1" />
              <Spinner animation="grow" variant="spinner2" />
              <Spinner animation="grow" variant="spinner3" />
            
          </Grid>
        ) : (
        <>
          {data && userRole && data.success && (
            <>
              <ThemeContainer type="private" value={5}>
                <Container className="Averta-font cp-container">
                  <Row className="my-3">
                    <Col className="cp-heading1" md={11} xs={10}>
                      Company Profile
                    </Col>
                    <Col md={1} className="mt-1" xs={2}>
                      {userRole == "admin" || userRole == "approver" ? (
                        <button className="view-edit" onClick={() => history.push("/mycompany")} >
                          <img src={EditICon} alt="" />
                        </button>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                  {/* Company Name & Image */}
                  <Header info={data.trader} />

                  {/* Card 1 */}
                  <Card
                    info={data.trader}
                    name={"Business Address"}
                    color={"#479FC8"}
                    page={"companyView"}
                  />
                  {/* Card 2 */}
                  <Card
                    info={data.addressList}
                    name={"Correspondence Address"}
                    color={"#9CA3AF"}
                    className="my-3"
                    page={"companyView"}
                  />

                  {/* Financial Information */}
                  {data.trader.financial_turnover && data.trader.financial_limit && data.trader.employee_range_id ? (
                    <>
                    <Row className="cp-heading2 my-5">
                      <Col> Financial Information</Col>
                    </Row>
                    <Row>
                    {data.trader.financial_turnover &&
                      <Col md={4}>
                        <Financal
                          info={data.trader.financial_turnover}
                          name={"Financial Turnover"}
                        />
                      </Col>
                    }
                    {data.trader.financial_limit &&
                      <Col md={4}>
                        <Financal info={data.trader.financial_limit} name={"Limit"} />
                      </Col>
                    }
                    {data.trader.no_of_employee &&
                      <Col md={4}>
                        <Financal
                          info={data.trader.no_of_employee}
                          name={"No of employees in company"}
                        />
                      </Col>
                    }
                  </Row>
                    </>
                  ):null}
                  {/* Contact Personal Detail */}
                  <ContactDetail info={data.contactList} color={"#f3f4f6"} />
                </Container>
              </ThemeContainer>
            </>
          )}
        </>
      )}
    </>
  );
};
export default CompanyProfile;
