import React, { createContext, useState } from "react";

export const Store = createContext();

const StoreState = (props) => {
  // const [userDetail, setUserDetail] = useReducer((state, action = {}) => {
  //     return action;
  // }, {})
  const [open, setOpen]                   = useState(false);
  const [verify, setVerify]               = useState();
  const [kyc_status_add, setKycStatusAdd] = useState();
  const [logged_id, setLoggedID]          = useState();
  const [logged_name, setLoggedName]      = useState();
  const [otp_timer, setOtpTimer]          = useState();

  const state = {
    open,
    setOpen,
    verify,
    setVerify,
    kyc_status_add,
    setKycStatusAdd,
    logged_name,
    setLoggedName,
    logged_id,
    setLoggedID,
    otp_timer,
    setOtpTimer,
  };
  return <Store.Provider value={state}>{props.children}</Store.Provider>;
};
export default StoreState;
