import { auth } from "./firebase-config";
import { signInWithPopup } from "firebase/auth";
import axios from "axios";
const SocialMedia = (provider) => {
  return signInWithPopup(auth, provider)
    .then((resp) => {
      //console.log("Firebase Res: ", resp);
      const api_url                     = process.env.REACT_APP_URL;
      const twitter_consumer_key        = process.env.REACT_APP_TWITTER_CONSUMER_KEY;
      const twitter_consumer_secret     = process.env.REACT_APP_TWITTER_CONSUMER_SECRET;
      const twitter_access_token_secret = process.env.REACT_APP_TWITTER_TOKEN_SECRET;
      const body = {
        id_token: resp.providerId === "google.com" ? resp.user.accessToken : "",
        accessToken: resp.providerId === "facebook.com" ? resp._tokenResponse.oauthAccessToken : "",
        linkedin_token: "",
        twitter_token: resp.providerId === "twitter.com" ? resp._tokenResponse.oauthAccessToken : "",
        consumer_key: resp.providerId === "twitter.com" ? twitter_consumer_key : "",
        consumer_secret: resp.providerId === "twitter.com" ? twitter_consumer_secret : "",
        access_token_secret: resp.providerId === "twitter.com" ? twitter_access_token_secret : "",
        mobile: resp.user.phoneNumber ? resp.user.phoneNumber : "",
        name: resp.user.displayName ? resp.user.displayName : "",
        email: resp.user.email
          ? resp.user.email
          : resp._tokenResponse.email
          ? resp._tokenResponse.email
          : resp.user.reloadUserInfo.email
          ? resp.user.reloadUserInfo.email
          : "",
        provider: resp.providerId,
      };
      return axios
        .post(api_url + "/api/v1/social/media/login", body, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then((res) => {
          let data = {};
          //console.log("Social Res: ", res);
          if (res.data.status) {
            localStorage.setItem("userName", res.data.user_name); //Setting username and password to local storage
            localStorage.setItem("userId", res.data.user_id);
            localStorage.setItem("user_type", res.data.user_type);
            localStorage.setItem("user_role", res.data.user_role);
            localStorage.setItem("access_token", res.data.access_token);
            localStorage.setItem("user_email", res.data.user_email);
            localStorage.setItem("kyc_type", res.data.kyc_type);
            localStorage.setItem("kyc_status", res.data.kyc_status);
            localStorage.setItem("currency_name", res.data.currency_name);
            localStorage.setItem("currency_symbol", res.data.currency_symbol);
            localStorage.setItem("is_lender", res.data.is_lender);
            if(res.data.user_type === "both"){
              localStorage.setItem("trader_type", "supplier");
            }else if(res.data.user_type === "buyer"){
              localStorage.setItem("trader_type", "buyer");
            }else{

            }
            localStorage.setItem(
              "redirect_company_info",
              res.data.redirect_company_info
            );
            localStorage.setItem("is_manual_kyc_enabled", res.data.is_manual_kyc_enabled);
            
            return (data = {
              success: res.data.success,
              message: res.data.message,
            });
          } else {
            return (data = {
              success: res.data.status,
              message: res.data.message,
            });
          }
        });
    })
    .catch((err) => {
      console.log("error", err);
    });
};
export default SocialMedia;
