import React, { useContext, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {
  Box,
  CircularProgress,
  Dialog,
  Typography,
  Switch,
} from "@material-ui/core";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import { Link } from "react-router-dom";
import { PropTypes } from "prop-types";
import { Store } from "../../context/store/storeState";

var axios = require("axios");
// import jwt from "jsonwebtoken";
import { getAccessToken } from "../../utilities/tokenUtil";

const useStyles = makeStyles(styles);

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#1890ff" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgb(0, 184, 222)",
    boxSizing: "border-box",
  },
}));

const getuserType = localStorage.getItem("user_type");
const trader_type = localStorage.getItem("trader_type");
let user_type;

if (localStorage.getItem("userId")) {
  if (getuserType === "both") {
    if (!trader_type || trader_type === null) {
      user_type = localStorage.setItem("trader_type", "supplier");
    } else {
      localStorage.setItem("trader_type", trader_type);
    }
  } else if (getuserType === "") {
    user_type = localStorage.setItem("trader_type", "supplier");
  } else {
    user_type = getuserType;
    localStorage.setItem("trader_type", getuserType);
  }
}

export default function HeaderLinks() {
  const [loading, setLoading] = useState(false);
  const userId      = localStorage.getItem("userId");
  const userName    = localStorage.getItem("userName");
  const traderType  = localStorage.getItem("trader_type");
  const [userType, setUserType] = useState(
    trader_type === "buyer" ? false : true
  );
  let usertype;
  
  const verifyContext           = useContext(Store);
  const logged_name             = verifyContext.logged_name;
  const setLoggedName           = verifyContext.setLoggedName;
  const logged_id               = verifyContext.logged_id;
  const setLoggedID             = verifyContext.setLoggedID;

  const history = useHistory();
  const dataSet = JSON.stringify({
    user_id: JSON.parse(userId),
    access_token: getAccessToken(),
  });

  const logout = () => {
    setLoading(true);
    const config = {
      method: "post",
      url: process.env.REACT_APP_URL + "/api/v1/auth/logout",
      data: dataSet,
      headers: {
        "Content-Type": "text/plain",
      },
    };
    axios(config)
      .then((response) => {
        if (response && response.data.success) {
          // alert(response.data.success_msg)
          localStorage.clear();
          setLoading(false);
          setLoggedName(null);
          setLoggedID(null);
          //window.location.reload(false);
          history.push('/')
        } else {
          //console.log(JSON.stringify(response.data));
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  const handleToggle = (event) => {
    setUserType(!userType);
    const user_type = userType ? "buyer" : "supplier";
    localStorage.setItem("trader_type", user_type);
    if(user_type === "buyer"){
      window.location.href = "/buyer/dashboard"
    }else{
      window.location.href = "/supplierdash"
    }
    //window.location.reload(false);
  };

  const classes = useStyles();
  useEffect(() => {
    setLoggedName(userName);
    setLoggedID(userId);
  }, []); 

  return (
    <List className={classes.list}>
      <ListItem
        className={classes.listItem}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "end",
        }}
      >
        <Box>
          <span
            style={{
              fontFamily: "Averta-Regular",
              color: "#999999",
              lineHeight: "14px",
              fontSize: "12px",
              paddingBottom: "5%",
            }}
          >
            Logged in as:
          </span>
          <span
            style={{
              fontFamily: "Averta-Regular",
              color: "#000000",
              lineHeight: "14px",
              fontSize: "12px",
              paddingBottom: "5%",
            }}
          >
            &nbsp; {userName}
          </span>
          <Link to={"/change-password"}>
            <span
              style={{
                fontFamily: "Averta-Regular",
                color: "#00B8DE",
                lineHeight: "14px",
                fontSize: "12px",
                paddingBottom: "5%",
              }}
            >
              &nbsp;Change Password
            </span>
          </Link>
          <span
            style={{
              fontFamily: "Averta-Regular",
              color: "#00B8DE",
              lineHeight: "14px",
              fontSize: "12px",
              paddingBottom: "5%",
            }}
          >
            &nbsp;|
          </span>
          <Link to={"/"}>
            <span
              style={{
                fontFamily: "Averta-Regular",
                color: "#00B8DE",
                lineHeight: "14px",
                fontSize: "12px",
                paddingBottom: "5%",
              }}
              onClick={logout}
            >
              &nbsp;Logout
            </span>
          </Link>
        </Box>
        <Dialog maxWidth={"xs"} open={loading}>
          <Box
            noValidate
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              m: "auto",
              width: 100,
              height: 100,
            }}
          >
            {loading && <CircularProgress />}
          </Box>
        </Dialog>

        <Box>
          {getuserType === "both" && (
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography
                style={
                  userType
                    ? { color: "#848f9f" }
                    : { fontWeight: "600", color: "#0275D8" }
                }
              >
                Buyer
              </Typography>
              <AntSwitch
                checked={userType}
                inputProps={{ "aria-label": "controlled" }}
                onClick={handleToggle}
              />
              <Typography
                style={
                  !userType
                    ? { color: "#848f9f" }
                    : { fontWeight: "600", color: "#0275D8" }
                }
              >
                Supplier
              </Typography>
            </Stack>
          )}
        </Box>
      </ListItem>
    </List>
  );
}

HeaderLinks.propTypes = {
  name: PropTypes.string,
};
