import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  OutlinedInput,
  withStyles,
  MenuItem,
  FormControl,
  Select,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Toolbar,
  IconButton,
  Snackbar,
  SnackbarContent,
  Modal,
  makeStyles,
} from "@material-ui/core";
import Custom from "views/Components/Custom";
import PropTypes from "prop-types";
import TableComponent from "views/Components/tableComponent/tablecomponent";
import ThemeContainer from "views/Components/sideBar/ThemeContainer";
import { isMobile } from "react-device-detect";
import { getAccessToken } from "../../../../utilities/tokenUtil";
import { Link, useHistory } from "react-router-dom";
import image11 from "assets/img/write(2).png";
import image2 from "assets/img/right(1).png";
import image3 from "assets/img/delete(3).png";
import image4 from "assets/img/eyes.png";
import plusicon from "assets/img/plusicon.png";
import image from "../../../../assets/img/profile.svg";
import galleryIcon from "../../../../assets/img/gallery-icon.png";
import InvoiceDownload from "../../../../assets/img/InvoiceDownload.svg";
import reject from "assets/img/reject1.png";
import View from "assets/img/view.svg";
import Updatestatus from "assets/img/updatestatus.svg";
import Addedit from "assets/img/add-edit.svg";
import Delete from "assets/img/delete.svg";
import { Spinner } from "react-bootstrap";
import Tooltip from "@mui/material/Tooltip";
import EyeIcon from "@material-ui/icons//RemoveRedEye";

var axios = require("axios");

const styles = () => ({
  textFields: {
    background: "#F3F5F6",
    height: "40px",
    marginTop: "1%",
    "& div": {
      "&::before": {
        content: "",
        borderBottom: "none",
      },
      "&::after": {
        borderBottom: "none",
      },
      "&:hover": {
        "&::before": {
          borderBottom: "none !important",
        },
        "&::after": {
          borderBottom: "none !important",
        },
      },
      "& .MuiInputBase-input": {
        padding: "9px 7px",
      },
    },
  },
});

function getModalStyle() {
  return {
    top: `50%`,
    left: `0`,
    right: `0`,
    margin: `0 auto`,
    transform: `translateY(-75%)`,
  };
}
function getModalDocStyle() {
  return {
    top: `65%`,
    left: `0`,
    right: `0`,
    margin: `0 auto`,
    transform: `translateY(-75%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    width: 450,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function Documents(props) {
  const access_token = getAccessToken();
  const user_role = localStorage.getItem("user_role");
  const userId = localStorage.getItem("userId");
  const userName = localStorage.getItem("userName");
  const kyc_status = localStorage.getItem("kyc_status");
  const trader_type = localStorage.getItem("trader_type");
  const api_url = process.env.REACT_APP_URL;
  const history = useHistory();
  const modelClasses = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [modalDocStyle] = React.useState(getModalDocStyle);
  const [loader, setLoader] = useState(false);

  const { classes } = props;
  const [documentListView, setDocumentListView] = useState(true);
  const [documentDetail, setDocumentDetail] = useState(false);
  const [addDocument, setAddDocument] = useState(false);
  const [assessment_documents, setAssessmentDocumentsList] = useState([]);
  const [file, setfile] = useState("");
  const [fileview, setfileView] = useState("");
  const [uploaded_file, setUploadedFile] = useState("");
  const [other_assessment_document_list, setDocumentList] = useState("");
  const [document_name, setDocumentName] = useState("");
  const [document_id, setDocumentID] = useState("");
  const [id, setID] = useState("");
  const [issuing_authority, setIssuingAuthority] = useState("");
  const [issuing_authority_name, setIssuingAuthorityName] = useState("");
  const [status, setStatus] = useState("");
  const [rejection_reason, setRejectionReason] = useState("");
  const [name, setName] = useState("");
  const [upload_date, setUploadDate] = useState("");
  const [upload_by, setUploadBy] = useState("");
  const [document_url, setDocumentUrl] = useState();
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState({});
  const [open, setOpen] = useState(false);
  const [fileName, setFileName] = useState("");
  const [issuing_authority_list, setIssuingAuthorityList] = useState([]);
  const [credit_assessment_documents, setCreditAssessmentDocuments] = useState(
    []
  );
  const [reject_reason, setRejectReason] = useState("");
  const [openModel, setOpenModel] = useState(false);
  const [openDocModel, setOpenDocModel] = useState(false);
  const [error, setError] = useState(false);
  const [uploaded_doc_url, setDocumentImage] = useState("");
  const [image_extension, setImageExtension] = useState("");

  if (!userId || userName === null) {
    history.push("/signin");
  }
  if (kyc_status !== "Approved") {
    history.push("/kyc-page");
  }
  if (trader_type !== "supplier") {
    history.push("/invoice");
  }

  const assessmentDocumentsList = () => {
    //setLoader(false)
    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: access_token,
    };
    axios
      .post(
        `${process.env.REACT_APP_URL}/api/v1/credit_assessment/doc/list`,
        data,
        {
          headers: {
            "Content-Type": "text/plain",
          },
        }
      )
      .then((response) => {
        if (response && response.data.success === true) {
          setAssessmentDocumentsList(response.data.assessment_documents);
          setLoader(true);
        } else {
          setLoader(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const masterFunction = () => {
    setLoader(false);
    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: access_token,
    };
    axios
      .post(
        `${process.env.REACT_APP_URL}/api/v1/credit_assessment/master`,
        data,
        {
          headers: {
            "Content-Type": "text/plain",
          },
        }
      )
      .then((response) => {
        if (response && response.data.success === true) {
          setCreditAssessmentDocuments(
            response.data.credit_assessment_documents
          );
          setIssuingAuthorityList(response.data.issuing_authority);
          setLoader(true);
        } else {
          setLoader(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const filter = () => {
    if (name !== "" || issuing_authority_name !== "" || status !== "") {
      let search_status;
      if (status === "all") {
        search_status = "";
      } else {
        search_status = status;
      }

      const data = {
        access_token: access_token,
        user_id: parseInt(localStorage.getItem("userId")),
        name: name,
        issuing_authority: issuing_authority_name,
        status: search_status,
      };

      axios
        .post(
          `${process.env.REACT_APP_URL}/api/v1/credit_assessment/filter`,
          data,
          {
            headers: {
              "Content-Type": "text/plain",
            },
          }
        )
        .then((response) => {
          if (response && response.data.success === true) {
            setAssessmentDocumentsList(response.data.assessment_document_list);
            setLoader(true);
          } else {
            setLoader(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "Please select at least one option.",
      });
      return;
    }
  };
  const clearSearch = () => {
    setStatus("");
    setName("");
    setIssuingAuthorityName("");
    assessmentDocumentsList();
  };
  const uploadDocument = () => {
    if (
      issuing_authority.trim() === "" ||
      issuing_authority.trim() === undefined
    ) {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "Please add the Issuing authority.",
      });
      return;
    }
    if (file === "") {
      setLoading(false);
      setOpen(true);
      setMessage({ type: "error", content: "Please upload document." });
      return;
    }

    if (file.size > 2000000) {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "Document size should be less than 2MB.",
      });
      return;
    }

    let data = new FormData();
    data.append("user_id", parseInt(localStorage.getItem("userId")));
    data.append("access_token", access_token);
    data.append("document_id", parseInt(document_id ? document_id : 0));
    data.append("id", parseInt(id ? id : 0));
    data.append("document_name", document_name);
    data.append("issuing_authority_id", issuing_authority.trim());
    data.append("document_data", file);

    axios
      .post(
        `${process.env.REACT_APP_URL}/api/v1/credit_assessment/doc/upload`,
        data,
        {
          headers: {
            "Content-Type": "text/plain",
          },
        }
      )
      .then((response) => {
        if (response.data.message) {
          setLoading(false);
          setOpen(true);
          setMessage({ type: "success", content: response.data.message });
        } else {
          setLoading(false);
          setOpen(true);
          setMessage({ type: "error", content: response.data.message });
        }

        setDocumentListView(!documentListView);
        setAddDocument(!addDocument);
        setDocumentDetail(documentDetail);
        assessmentDocumentsList();
        setfileView("");
        setFileName("");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const updateDocument = (event, document_id, id, document_name) => {
    event.preventDefault();
    setDocumentListView(!documentListView);
    setAddDocument(!addDocument);
    setDocumentDetail(documentDetail);
    setIssuingAuthority("");

    setDocumentID(document_id);
    setID(id);
    setDocumentName(document_name);
    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: access_token,
      document_id: parseInt(document_id),
      id: parseInt(id),
    };

    axios
      .post(
        `${process.env.REACT_APP_URL}/api/v1/credit_assessment/doc/detail`,
        data,
        {
          headers: {
            "Content-Type": "text/plain",
          },
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          setDocumentName(response.data.document_name);
          setUploadedFile(response.data.document_url);
          setDocumentID(response.data.document_id);
          setIssuingAuthority(response.data.issuing_authority);
          setRejectionReason(response.data.rejection_reason);
          setID(response.data.id);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const addDocuments = (event, document_id, id, document_name) => {
    event.preventDefault();
    setDocumentListView(!documentListView);
    setAddDocument(!addDocument);
    setDocumentDetail(documentDetail);
    setDocumentID(document_id);
    setID(id);
    setDocumentName(document_name);
    setIssuingAuthority("");
  };
  const viewDocument = (event, document_id, id) => {
    event.preventDefault();

    setDocumentListView(!documentListView);
    setAddDocument(addDocument);
    setDocumentDetail(!documentDetail);
    setLoader(false);
    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: access_token,
      document_id: parseInt(document_id),
      id: id,
    };

    axios
      .post(
        `${process.env.REACT_APP_URL}/api/v1/credit_assessment/doc/detail`,
        data,
        {
          headers: {
            "Content-Type": "text/plain",
          },
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          setDocumentList(response.data.other_assessment_document_list);
          setDocumentName(response.data.document_name);
          setIssuingAuthority(response.data.issuing_authority);
          setRejectionReason(response.data.rejection_reason);
          setStatus(response.data.status);
          setUploadDate(response.data.upload_date);
          setUploadBy(response.data.upload_by);
          setDocumentUrl(response.data.document_url);
          setLoader(true);
        } else {
          setLoader(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const uploadDocumentFile = (event) => {
    let fileext = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      if (fileext.size > 2000000) {
        setOpen(true);
        setMessage({
          type: "error",
          content: "Document size should be less than 2MB.",
        });
        return;
      }
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setfileView(reader.result);
      });
      setfile(event.target.files[0]);
      setFileName(event.target.files[0].name);
      reader.readAsDataURL(event.target.files[0]);
      setUploadedFile("");
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  function CancelFromView() {
    setLoader(false);
    setDocumentListView(!documentListView);
    setAddDocument(addDocument);
    setDocumentDetail(!documentDetail);
    setFileName("");
    setfile("");
    setfileView("");
    setLoader(true);
  }
  function CancelFromAdd() {
    setLoader(false);
    setDocumentListView(!documentListView);
    setAddDocument(!addDocument);
    setDocumentDetail(documentDetail);
    setFileName("");
    setfile("");
    setfileView("");
    setUploadedFile("");
    setLoader(true);
  }
  const updateStatus = (event, document_id, id, status) => {
    event.preventDefault();
    let setStatus;
    if (openModel && reject_reason === "") {
      setError(true);
    } else {
      setError(false);
      if (status === "Uploaded" && reject_reason === "") {
        setStatus = "waiting_for_approval";
      } else if (status === "Awaiting Approval" && reject_reason === "") {
        setStatus = "verified";
      } else if (reject_reason !== "") {
        setStatus = "rejected";
      }
      setLoader(false);
      const data = {
        user_id: parseInt(localStorage.getItem("userId")),
        access_token: access_token,
        id: parseInt(id),
        document_id: parseInt(document_id),
        status: setStatus,
        reject_reason: reject_reason ? reject_reason : "",
      };

      axios
        .post(
          `${process.env.REACT_APP_URL}/api/v1/credit_assessment/update/status`,
          data,
          {
            headers: {
              "Content-Type": "text/plain",
            },
          }
        )
        .then((response) => {
          if (response.data.message) {
            setLoading(false);
            setOpen(true);
            setMessage({ type: "success", content: response.data.message });
            setLoader(true);
            return assessmentDocumentsList();
          } else {
            setLoading(false);
            setOpen(true);
            setMessage({ type: "error", content: response.data.message });
            setLoader(true);
            return assessmentDocumentsList();
          }
          setOpenModel(false);
          setRejectReason("");
        })
        .catch((error) => {
          setLoader(true);
          console.log(error);
        });
    }
  };
  const modelOpen = (event, document_id, id, status) => {
    event.preventDefault();
    setDocumentID(document_id);
    setID(id);
    setStatus(status);
    setOpenModel(true);
  };

  // Download uploaded files
  const downloadDocument = (event, document_id) => {
    event.preventDefault();

    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: access_token,
      document_id: parseInt(document_id),
    };

    axios
      .post(
        `${process.env.REACT_APP_URL}/api/v1/credit_assessment/doc/download`,
        data,
        {
          headers: {
            "Content-Type": "text/plain",
          },
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          const api_url = process.env.REACT_APP_URL;
          let url = `${api_url + response.data.document_url}?${Date.now()}`;
          window.open(url, "_blank");
        } else {
          setLoading(false);
          setOpen(true);
          setMessage({ type: "error", content: "Something went wrong." });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const modelClose = () => {
    setOpenModel(false);
    setRejectReason("");
  };

  const docModelOpen = (event, document_url, image_extension) => {
    event.preventDefault();
    let url = process.env.REACT_APP_URL + document_url;
    setDocumentImage(url);
    setImageExtension(image_extension);
    setOpenDocModel(true);
  };
  const docModelClose = () => {
    setDocumentImage("");
    setImageExtension("");
    setOpenDocModel(false);
  };

  useEffect(() => {
    document.title = "Interswitch - Trader Assessment";
    assessmentDocumentsList();
    masterFunction();
  }, []);

  return (
    <ThemeContainer type="private" value={3}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}>
        <SnackbarContent
          className={
            message.type === "error"
              ? "snack-error"
              : message.type === "success"
              ? "snack-success"
              : "snack-teal"
          }
          message={message.content}
        />
      </Snackbar>
      {loader !== true ? (
        <Grid container spacing={3} className="bootstrap-loader">
          <Spinner animation="grow" variant="spinner1" />
          <Spinner animation="grow" variant="spinner2" />
          <Spinner animation="grow" variant="spinner3" />
        </Grid>
      ) : (
        <>
          {documentListView && (
            <>
              <Box className="scf-box">
                <Box className="scf-subbox">
                  <Box>
                    <Box>
                      <Grid container>
                        <Grid item xs={6} sm={6} md={10} xl={10}>
                          <Typography className="listing-main-title">
                            Documents
                          </Typography>
                        </Grid>
                      </Grid>
                      <Typography className="company-title2">
                        {" "}
                        Filters
                      </Typography>

                      <Grid container spacing={2}>
                        <Grid item xs={6} sm={6} md={4} xl={4}>
                          <Box>
                            <span className="company-title3">
                              Name<span className="required">*</span>
                            </span>
                            <FormControl fullWidth>
                              <OutlinedInput
                                className="company-title5"
                                fullWidth
                                value={name}
                                placeholder="name"
                                onChange={(e) => setName(e.target.value)}
                              />
                            </FormControl>
                          </Box>
                        </Grid>
                        <Grid item xs={6} sm={6} md={4} xl={4}>
                          <Box>
                            <span className="company-title3">
                              Issuing Authority{" "}
                              <span className="required">*</span>
                            </span>
                            <FormControl fullWidth>
                              <OutlinedInput
                                className="company-title5"
                                fullWidth
                                value={issuing_authority_name}
                                placeholder="Issuing Authority"
                                onChange={(e) =>
                                  setIssuingAuthorityName(e.target.value)
                                }
                              />
                            </FormControl>
                          </Box>
                        </Grid>
                        <Grid item xs={6} sm={6} md={4} xl={4}>
                          <Box>
                            <span className="company-title3">
                              Select Status<span className="required">*</span>
                            </span>
                            <FormControl fullWidth>
                              <Select
                                displayEmpty
                                value={status}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                onChange={(e) => setStatus(e.target.value)}
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                  },
                                  getContentAnchorEl: null,
                                }}>
                                <MenuItem value="" disabled>
                                  Select from options
                                </MenuItem>
                                <MenuItem value="all">All</MenuItem>
                                <MenuItem value="draft">Draft</MenuItem>
                                <MenuItem value="uploaded">Uploaded</MenuItem>
                                <MenuItem value="waiting_for_approval">
                                  Waiting for Approval
                                </MenuItem>
                                <MenuItem value="pending">Pending</MenuItem>
                                <MenuItem value="verified">Verified</MenuItem>
                                <MenuItem value="rejected">Rejected</MenuItem>
                                <MenuItem value="expired">Expired</MenuItem>
                              </Select>
                            </FormControl>
                          </Box>
                        </Grid>
                        <Grid item xs={6} sm={6} md={4} xl={4}></Grid>
                        <Grid item xs={6} sm={6} md={4} xl={4}></Grid>
                        <Grid item xs={6} sm={6} md={4} xl={4}>
                          <span className="company-title3">&nbsp;</span>
                          <Box className="filter-action">
                            <Button className="submit-button" onClick={filter}>
                              Submit
                            </Button>
                            <Button
                              className="cancel-button"
                              onClick={clearSearch}>
                              Clear
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>

                    <Typography className="list-sub-title">
                      Document List
                    </Typography>
                    <Box>
                      <TableContainer component={Paper}>
                        <Table className="table-top" aria-label="simple table">
                          <TableHead>
                            <TableRow className="table-row">
                              <TableCell align="left" className="table-header">
                                S. No.{" "}
                              </TableCell>
                              <TableCell align="left" className="table-header">
                                Document Name
                              </TableCell>
                              <TableCell align="left" className="table-header">
                                Issuing Authority
                              </TableCell>
                              <TableCell align="left" className="table-header">
                                Uploaded Date
                              </TableCell>
                              <TableCell align="left" className="table-header">
                                Uploaded By
                              </TableCell>
                              <TableCell align="left" className="table-header">
                                Status
                              </TableCell>
                              <TableCell align="left" className="table-header">
                                Action
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {assessment_documents.length > 0 &&
                            assessment_documents !== undefined ? (
                              assessment_documents.map((row, index) => (
                                <TableRow className="table-row" key={row.name}>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    align="left"
                                    onClick={(e) => {
                                      row.status === "Uploaded" ||
                                      row.status === "Verified" ||
                                      row.status === "Awaiting Approval" ||
                                      row.status === "Rejected"
                                        ? viewDocument(
                                            e,
                                            row.document_id,
                                            row.id
                                          )
                                        : null;
                                    }}>
                                    {index + 1}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    onClick={(e) => {
                                      row.status === "Uploaded" ||
                                      row.status === "Verified" ||
                                      row.status === "Awaiting Approval" ||
                                      row.status === "Rejected"
                                        ? viewDocument(
                                            e,
                                            row.document_id,
                                            row.id
                                          )
                                        : null;
                                    }}>
                                    {row.document_name}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    onClick={(e) => {
                                      row.status === "Uploaded" ||
                                      row.status === "Verified" ||
                                      row.status === "Awaiting Approval" ||
                                      row.status === "Rejected"
                                        ? viewDocument(
                                            e,
                                            row.document_id,
                                            row.id
                                          )
                                        : null;
                                    }}>
                                    {row.issuing_authority}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    onClick={(e) => {
                                      row.status === "Uploaded" ||
                                      row.status === "Verified" ||
                                      row.status === "Awaiting Approval" ||
                                      row.status === "Rejected"
                                        ? viewDocument(
                                            e,
                                            row.document_id,
                                            row.id
                                          )
                                        : null;
                                    }}>
                                    {row.upload_date}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    onClick={(e) => {
                                      row.status === "Uploaded" ||
                                      row.status === "Verified" ||
                                      row.status === "Awaiting Approval" ||
                                      row.status === "Rejected"
                                        ? viewDocument(
                                            e,
                                            row.document_id,
                                            row.id
                                          )
                                        : null;
                                    }}>
                                    {row.upload_by}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    onClick={(e) => {
                                      row.status === "Uploaded" ||
                                      row.status === "Verified" ||
                                      row.status === "Awaiting Approval" ||
                                      row.status === "Rejected"
                                        ? viewDocument(
                                            e,
                                            row.document_id,
                                            row.id
                                          )
                                        : null;
                                    }}
                                    className={row.status
                                      .toLowerCase()
                                      .replace(/\s/g, "-")}>
                                    {row.status}
                                  </TableCell>
                                  <TableCell align="left">
                                    <Box className="table-action">
                                      {row.status === "Uploaded" ||
                                      row.status === "Processed" ||
                                      row.status === "Expired" ||
                                      row.status === "Rejected" ? (
                                        <Link
                                          onClick={(e) =>
                                            updateDocument(
                                              e,
                                              row.document_id,
                                              row.id,
                                              row.document_name
                                            )
                                          }>
                                          <Tooltip
                                            title="Edit"
                                            placement="top"
                                            arrow>
                                            <IconButton
                                              size={
                                                isMobile ? "small" : "medium"
                                              }>
                                              <img
                                                className="iconstyle"
                                                src={Addedit}
                                              />
                                            </IconButton>
                                          </Tooltip>
                                        </Link>
                                      ) : null}
                                      {row.status == "Uploaded" &&
                                      user_role == "approver" ? (
                                        <>
                                          <Link
                                            onClick={(e) =>
                                              updateStatus(
                                                e,
                                                row.document_id,
                                                row.id,
                                                row.status
                                              )
                                            }>
                                            <Tooltip
                                              title="Send for approval"
                                              placement="top"
                                              arrow>
                                              <IconButton
                                                size={
                                                  isMobile ? "small" : "medium"
                                                }>
                                                <img
                                                  className="iconstyle"
                                                  src={Updatestatus}
                                                />
                                              </IconButton>
                                            </Tooltip>
                                          </Link>
                                          <Link
                                            onClick={(e) =>
                                              modelOpen(
                                                e,
                                                row.document_id,
                                                row.id,
                                                row.document_name
                                              )
                                            }>
                                            <Tooltip
                                              title="Reject"
                                              placement="top"
                                              arrow>
                                              <IconButton
                                                size={
                                                  isMobile ? "small" : "medium"
                                                }>
                                                <i class="far fa-times-circle"></i>
                                              </IconButton>
                                            </Tooltip>
                                          </Link>
                                        </>
                                      ) : null}
                                      {row.status == "Uploaded" && (
                                        <Link
                                          onClick={(e) =>
                                            updateStatus(
                                              e,
                                              row.document_id,
                                              row.id,
                                              row.status
                                            )
                                          }>
                                          <Tooltip
                                            title="Send for approval"
                                            placement="top"
                                            arrow>
                                            <IconButton
                                              size={
                                                isMobile ? "small" : "medium"
                                              }>
                                              <img
                                                className="iconstyle"
                                                src={Updatestatus}
                                              />
                                            </IconButton>
                                          </Tooltip>
                                        </Link>
                                      )}
                                      {row.status === "Pending" ? (
                                        <Link
                                          onClick={(e) =>
                                            addDocuments(
                                              e,
                                              row.document_id,
                                              row.id,
                                              row.document_name
                                            )
                                          }>
                                          <Tooltip
                                            title="Add Document"
                                            placement="top"
                                            arrow>
                                            <IconButton
                                              size={
                                                isMobile ? "small" : "medium"
                                              }>
                                              <img
                                                className="iconstyle"
                                                src={Addedit}
                                              />
                                            </IconButton>
                                          </Tooltip>
                                        </Link>
                                      ) : null}
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              ))
                            ) : (
                              <TableRow>
                                <TableCell colSpan={7} className="nodata">
                                  No Data to Display
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </>
          )}

          {documentDetail && (
            <>
              <Box className="scf-box">
                <Box className="scf-subbox">
                  <Box>
                    <Grid container>
                      <Grid item xs={9} sm={6} md={11} xl={11}>
                        <Typography className="company-title11">
                          Document Detail
                        </Typography>
                      </Grid>
                      <Grid item xs={3} sm={6} md={1} xl={1}>
                        <Button
                          className="back-button"
                          onClick={CancelFromView}>
                          <i className="fas fa-long-arrow-alt-left"></i> Back{" "}
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={4} xl={4}>
                        <Box className="input-box">
                          <span className="company-title3">
                            Document Name<span className="required">*</span>
                          </span>
                          <OutlinedInput
                            className="company-title5"
                            fullWidth
                            readOnly
                            placeholder="e.g. Document Name"
                            value={document_name}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} xl={4}>
                        <Box className="input-box">
                          <span className="company-title3">
                            Issuing Authority<span className="required">*</span>
                          </span>
                          <OutlinedInput
                            className="company-title5"
                            fullWidth
                            readOnly
                            placeholder="e.g. Issuing Authority"
                            value={issuing_authority}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} xl={4}>
                        <Box className="input-box">
                          <span className="company-title3">
                            Upload By<span className="required">*</span>
                          </span>
                          <OutlinedInput
                            className="company-title5"
                            fullWidth
                            readOnly
                            placeholder="e.g. Upload By"
                            value={upload_by}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} xl={4}>
                        <Box className="input-box">
                          <span className="company-title3">
                            Upload Date<span className="required">*</span>
                          </span>
                          <OutlinedInput
                            className="company-title5"
                            fullWidth
                            readOnly
                            placeholder="e.g. Upload Date"
                            value={upload_date}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} xl={4}>
                        <Box className="input-box">
                          <span className="company-title3">
                            Status<span className="required">*</span>
                          </span>
                          <OutlinedInput
                            className="company-title5"
                            fullWidth
                            readOnly
                            placeholder="e.g. Status"
                            value={status}
                          />
                        </Box>
                      </Grid>
                      {rejection_reason && (
                        <Grid item xs={12} sm={12} md={4} xl={4}>
                          <Box className="input-box">
                            <span className="company-title3">
                              Rejection Reason
                              <span className="required">*</span>
                            </span>
                            <OutlinedInput
                              className="company-title5"
                              fullWidth
                              readOnly
                              placeholder="e.g. Rejection Reason"
                              value={rejection_reason}
                            />
                          </Box>
                        </Grid>
                      )}
                    </Grid>

                    <Typography className="sub-heading">
                      {document_name}
                    </Typography>
                    <Box className="trader-assesment-detail no-cursor-table">
                      <TableContainer component={Paper}>
                        <Table className="table-top" aria-label="simple table">
                          <TableHead>
                            <TableRow className="table-row">
                              <TableCell align="left" className="table-header">
                                S. No.{" "}
                              </TableCell>
                              <TableCell align="left" className="table-header">
                                Document Name
                              </TableCell>
                              <TableCell align="left" className="table-header">
                                Issuing Authority
                              </TableCell>
                              <TableCell align="left" className="table-header">
                                Uploaded Date
                              </TableCell>
                              <TableCell align="left" className="table-header">
                                Uploaded By
                              </TableCell>
                              <TableCell align="left" className="table-header">
                                Status
                              </TableCell>
                              <TableCell align="left" className="table-header">
                                Action
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {other_assessment_document_list !== undefined &&
                            other_assessment_document_list.length > 0 ? (
                              other_assessment_document_list.map(
                                (row, index1) => (
                                  <TableRow className="table-row" key={index1}>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      align="left">
                                      {index1 + 1}
                                    </TableCell>
                                    <TableCell align="left">
                                      {row.document_name}
                                    </TableCell>
                                    <TableCell align="left">
                                      {row.issuing_authority}
                                    </TableCell>
                                    <TableCell align="left">
                                      {row.upload_date}
                                    </TableCell>
                                    <TableCell align="left">
                                      {row.upload_by}
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      className={row.status
                                        .toLowerCase()
                                        .replace(/\s/g, "-")}>
                                      {row.status}
                                    </TableCell>
                                    <TableCell align="left">
                                      <Box className="table-action">
                                        <Tooltip
                                          title="Download Document"
                                          placement="top"
                                          arrow>
                                          <IconButton
                                            size={
                                              isMobile ? "small" : "medium"
                                            }>
                                            <img
                                              className="iconstyle"
                                              src={InvoiceDownload}
                                              onClick={(e) =>
                                                downloadDocument(e, row.id)
                                              }
                                            />
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip
                                          title="View Document"
                                          placement="top"
                                          arrow>
                                          <IconButton
                                            size={isMobile ? "small" : "medium"}
                                            onClick={(e) =>
                                              docModelOpen(
                                                e,
                                                row.document_url,
                                                row.image_extension
                                              )
                                            }>
                                            <EyeIcon />
                                          </IconButton>
                                        </Tooltip>
                                      </Box>
                                    </TableCell>
                                  </TableRow>
                                )
                              )
                            ) : (
                              <TableRow>
                                <TableCell colSpan={7} className="nodata">
                                  No Data to Display
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </>
          )}

          {addDocument && (
            <>
              <Box className="scf-box">
                <Box className="scf-subbox">
                  <Box>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} xl={12}>
                        <Typography className="company-title11">
                          Upload {document_name}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Box>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={4} xl={4}>
                          <Box className="input-box">
                            <span className="company-title3">
                              Issuing Authority
                              <span className="required">*</span>
                            </span>
                            <FormControl fullWidth>
                              <OutlinedInput
                                className="company-title5"
                                fullWidth
                                value={issuing_authority}
                                placeholder="Issuing Authority"
                                onChange={(e) =>
                                  setIssuingAuthority(e.target.value)
                                }
                              />
                            </FormControl>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={12}>
                          <label className="credit-assement-uploads">
                            <div className="upload">
                              <div className="upload-images text-color col-lg-6">
                                {uploaded_file ? (
                                  <img
                                    className="uploaded-image"
                                    src={`${
                                      api_url + uploaded_file
                                    }?${Date.now()}`}
                                  />
                                ) : (
                                  <>
                                    {fileview ? (
                                      <img
                                        className="uploaded-image"
                                        src={fileview}
                                      />
                                    ) : (
                                      <img src={galleryIcon} />
                                    )}
                                  </>
                                )}
                                {fileName && (
                                  <p>
                                    <b>{fileName}</b>
                                  </p>
                                )}
                                <p className="label-color">
                                  PNG, PDF GIF and JPEG. Max 2mb
                                </p>
                                <p>Upload Document</p>
                                <input
                                  type="file"
                                  hidden
                                  onChange={(e) => uploadDocumentFile(e)}
                                />
                              </div>
                            </div>
                          </label>
                        </Grid>
                      </Grid>
                    </Box>

                    <Grid container spacing={3} className="button-grid">
                      <Grid item xs={6} sm={4} md={2} xl={2}>
                        <Button
                          className="save"
                          onClick={uploadDocument}
                          disabled={!issuing_authority || !file}>
                          Upload
                        </Button>
                      </Grid>
                      <Grid item xs={6} sm={4} md={2} xl={2}>
                        <Button className="cancel" onClick={CancelFromAdd}>
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
            </>
          )}

          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={openModel}
            onClose={modelClose}>
            <div style={modalStyle} className={modelClasses.paper}>
              <h4 className="reject-modal-title">Reject Reason</h4>
              <Grid item xs={12} sm={12} md={12} xl={12}>
                <Box className="input-box">
                  <FormControl fullWidth className={classes.textFields}>
                    <OutlinedInput
                      className="company-title5"
                      fullWidth
                      required
                      value={reject_reason}
                      placeholder="Enter Reject Reason"
                      onChange={(e) => setRejectReason(e.target.value)}
                    />
                  </FormControl>
                  {error && error === true && (
                    <Grid className="company-title3" style={{ color: "red" }}>
                      This field is required.
                    </Grid>
                  )}
                </Box>
              </Grid>
              <Grid container spacing={3} className="button-grid">
                <Grid item xs={6} sm={4} md={3} xl={3}>
                  <Button
                    type="submit"
                    className="save reason"
                    onClick={(e) => updateStatus(e, document_id, id, status)}>
                    {" "}
                    Submit{" "}
                  </Button>
                </Grid>
                <Grid item xs={6} sm={4} md={3} xl={3}>
                  <Button className="cancel reason" onClick={modelClose}>
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Modal>

          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={openDocModel}
            onClose={docModelClose}>
            <div style={modalDocStyle} className={modelClasses.paper}>
              <h4 className="modal-title">Uploaded Document</h4>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                xl={12}
                className="modal-image">
                <Box style={{ paddingBottom: "5%", textAlign: "center" }}>
                  {image_extension === "application/pdf" ? (
                    <embed
                      src={`${uploaded_doc_url}?${Date.now()}`}
                      width="300"
                      height="300"
                      type={image_extension}
                    />
                  ) : (
                    <img src={`${uploaded_doc_url}?${Date.now()}`} alt="" />
                  )}
                </Box>
              </Grid>
            </div>
          </Modal>
        </>
      )}
    </ThemeContainer>
  );
}

Documents.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(Documents);
