import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  OutlinedInput,
  withStyles,
  MenuItem,
  FormControl,
  Select,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Toolbar,
  IconButton,
  Snackbar,
  SnackbarContent,
} from "@material-ui/core";
import preview from "assets/img/previewButton.svg";
import Custom from "views/Components/Custom";
import ThemeContainer from "views/Components/sideBar/ThemeContainer";
import { isMobile } from "react-device-detect";
import { PropTypes } from "prop-types";
import TableComponent from "views/Components/tableComponent/tablecomponent";
import { getAccessToken } from "../../utilities/tokenUtil";
import { Link, useHistory } from "react-router-dom";
import image11 from "assets/img/write(2).png";
import image2 from "assets/img/right(1).png";
import image3 from "assets/img/delete(3).png";
import image4 from "assets/img/eyes.png";
import View from "assets/img/view.svg";
import Send from "assets/img/send.png";
import Updatestatus from "assets/img/updatestatus.svg";
import Addedit from "assets/img/add-edit.svg";
import Delete from "assets/img/delete.svg";
import { Spinner } from "react-bootstrap";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";

var axios = require("axios");

const styles = (theme) => ({
  textFields: {
    background: "#F3F5F6",
    height: "40px",
    marginTop: "1%",
    "& div": {
      "&::before": {
        content: "",
        borderBottom: "none",
      },
      "&::after": {
        borderBottom: "none",
      },
      "&:hover": {
        "&::before": {
          borderBottom: "none !important",
        },
        "&::after": {
          borderBottom: "none !important",
        },
      },
      "& .MuiInputBase-input": {
        padding: "9px 7px",
      },
    },
  },
});

function ManageUsers(props) {
  const userId = localStorage.getItem("userId");
  const userName = localStorage.getItem("userName");
  const kyc_status = localStorage.getItem("kyc_status");

  const { classes } = props;
  const history = useHistory();
  const [name, setFullName] = useState("");
  const [country, setCountry] = useState("");
  const [countryList, setCountryList] = useState("");
  const [email, setEmail] = useState("");
  const [entity, setDesignation] = useState("");
  const [Mobilenumber, setMobileNumber] = useState("");
  const [Bvnnumber, setBvnNumber] = useState("");
  const [address, setAddress] = useState("");
  const [zip, setZip] = useState("");
  const [WorkingatBranchHO, setWorkingatBranchHO] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [roles, setRoles] = useState("");
  const [ManageUsers, setManageUsers] = useState("");
  const [status, setStatus] = useState("");
  const [stateList, setStateList] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [user_list, setUserList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState({});
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [page_no, setPageNo] = useState(1);
  const [page_count, setPageCount] = useState(1);

  if (!userId || userName === null) {
    history.push("/signin");
  }
  if (kyc_status !== "Approved") {
    history.push("/kyc-page");
  }

  const onSubmit = () => {
    const access_token = getAccessToken();
    if (password != password2) {
      return;
    }

    const data = {
      user_id: localStorage.getItem(userID),
      access_token: access_token,
      user_type: "user",
      id: 0,
      name: name,
      mobile: Mobilenumber,
      bvn_number: Bvnnumber,
      address: address,
      zip: zip,
      country_id: 1,
      state_id: 1,
      city: city,
      designation: entity,
      working_location_id: WorkingatBranchHO,
      email: email,
      password: password,
    };
    axios
      .post(
        `${process.env.REACT_APP_URL}/api/v1/user/update`,
        JSON.stringify(data)
      )
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
  };
  const userList = (value = 1) => {
    const access_token = getAccessToken();

    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: access_token,
      page_no: parseInt(value),
    };
    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/user/list`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        if (response && response.data.success === true) {
          setUserList(response.data.user_list);
          let total_records = response.data.total_rec;
          let records_per_page = response.data.records_per_page;
          let page_count = Math.ceil(total_records / records_per_page);
          setPageNo(response.data.current_page);
          setLoader(true);
          if (page_count < 1) {
            setPageCount(1);
          } else {
            setPageCount(page_count);
          }
        } else {
          setLoader(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const countryFunction = () => {
    const access_token = getAccessToken();
    const userID = localStorage.getItem("userId");

    const data = {
      user_id: localStorage.getItem("userId"),
      access_token: access_token,
    };
    axios
      .post(
        `${process.env.REACT_APP_URL}/api/v1/country/master`,
        JSON.stringify(data)
      )
      .then((response) => {
        if (response && response.data.sucess) {
          setCountryList(response.data.country);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const fetchState = (country_id, data1) => {
    const dataSet = JSON.stringify({
      country_id: country_id,
    });
    const config = {
      method: "post",
      url: process.env.REACT_APP_URL + "/api/v1/state/master",
      headers: {
        "Content-Type": "text/plain",
      },
      data: dataSet,
    };
    axios(config)
      .then((response) => {
        setStateList(response.data.state);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const filter = () => {
    let regEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let mobile_validate =
      /^(?:\+)?\s*[0][1-9]\d{9,12}$|^(?:\+)?\s*[1-9]\d{9,12}$/;

    const access_token = getAccessToken();
    if (email) {
      if (!regEmail.test(email)) {
        setLoading(false);
        setOpen(true);
        setMessage({ type: "error", content: "Please enter a valid email." });
        return false;
      }
    }
    if (Mobilenumber) {
      if (!Mobilenumber.replace(/\s/g, "").match(mobile_validate)) {
        setLoading(false);
        setOpen(true);
        setMessage({
          type: "error",
          content: "Mobile number should be 10 to 13 digit",
        });
        return false;
      }
    }
    setUserList("");
    const data = {
      access_token: access_token,
      user_id: parseInt(localStorage.getItem("userId")),
      name: name,
      email: email,
      mobile: Mobilenumber,
      status: status,
      page_no: parseInt(page_no ? page_no : 1),
    };

    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/user/list/search`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        if (response && response.data.success === true) {
          setUserList(response.data.user_list);
          let total_records = response.data.total_rec;
          let records_per_page = response.data.records_per_page;
          let page_count = Math.ceil(total_records / records_per_page);
          setPageNo(response.data.current_page);
          if (page_count < 1) {
            setPageCount(1);
          } else {
            setPageCount(page_count);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const clearSearch = () => {
    setStatus("");
    setFullName("");
    setEmail("");
    setMobileNumber("");
    //setUserList("");
    userList();
  };
  const handleMobileNumber = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setMobileNumber(e.target.value);
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  // Send invitation to the user
  const sendInvitation = (event, email, contact_user_id) => {
    event.preventDefault();
    const access_token = getAccessToken();

    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      id: parseInt(contact_user_id ? contact_user_id : 0),
      access_token: access_token,
      email: email,
    };

    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/send/user/invitation`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        if (response.data.success === true) {
          setLoading(false);
          setOpen(true);
          setMessage({ type: "success", content: response.data.message });
        } else {
          setLoading(false);
          setOpen(true);
          setMessage({ type: "error", content: response.data.message });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePageClick = (event, value) => {
    userList(value);
  };

  useEffect(() => {
    document.title = "Interswitch - Users";
    window.scrollTo(0, 0);
    countryFunction();
    userList();
  }, []);

  return (
    <ThemeContainer type="private" value={1}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}>
        <SnackbarContent
          className={
            message.type === "error"
              ? "snack-error"
              : message.type === "success"
              ? "snack-success"
              : "snack-teal"
          }
          message={message.content}
        />
      </Snackbar>
      {loader !== true ? (
        <Grid container spacing={3} className="bootstrap-loader">
          <Spinner animation="grow" variant="spinner1" />
          <Spinner animation="grow" variant="spinner2" />
          <Spinner animation="grow" variant="spinner3" />
        </Grid>
      ) : (
        <>
          <Box className="scf-box">
            <Box className="scf-subbox">
              <Box>
                <Grid container>
                  <Grid item xs={6} sm={6} md={10} xl={10}>
                    <Typography className="listing-main-title">
                      Manage Users
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={2} xl={2}>
                    <Link to="/addnewuser">
                      <Button className="upload">Add New User</Button>
                    </Link>
                  </Grid>
                </Grid>
                <Typography className="company-title2"> Filters</Typography>

                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6} md={4} xl={4}>
                    <Box>
                      <span className="company-title3">
                        By Name<span className="required">*</span>
                      </span>
                      <OutlinedInput
                        className="company-title5"
                        fullWidth
                        placeholder="Enter Name"
                        value={name}
                        onChange={(e) => setFullName(e.target.value)}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={6} sm={6} md={4} xl={4}>
                    <Box>
                      <span className="company-title3">
                        By Email<span className="required">*</span>
                      </span>
                      <OutlinedInput
                        className="company-title5"
                        fullWidth
                        placeholder="Enter Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4} xl={4}>
                    <Box>
                      <span className="company-title3">
                        By Mobile<span className="required">*</span>
                      </span>
                      <OutlinedInput
                        className="company-title5"
                        fullWidth
                        placeholder="Enter Mobile"
                        value={Mobilenumber}
                        onChange={(e) => handleMobileNumber(e)}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={6} sm={6} md={4} xl={4}></Grid>
                  <Grid item xs={6} sm={6} md={4} xl={4}></Grid>
                  <Grid item xs={6} sm={6} md={4} xl={4}>
                    <span className="company-title3">&nbsp;</span>
                    <Box className="filter-action">
                      <Button className="submit-button" onClick={filter}>
                        {" "}
                        Submit{" "}
                      </Button>
                      <Button className="cancel-button" onClick={clearSearch}>
                        Clear
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Typography className="list-sub-title">Users List</Typography>

                <TableContainer component={Paper}>
                  <Table className="table-top" aria-label="simple table">
                    <TableRow className="table-row">
                      <TableCell className="table-header" align="left">
                        S. No.{" "}
                      </TableCell>
                      <TableCell align="left" className="table-header">
                        {" "}
                        Name{" "}
                      </TableCell>
                      <TableCell align="left" className="table-header">
                        {" "}
                        Mobile No{" "}
                      </TableCell>
                      <TableCell align="left" className="table-header">
                        {" "}
                        Email{" "}
                      </TableCell>
                      <TableCell align="left" className="table-header">
                        {" "}
                        Roles{" "}
                      </TableCell>
                      <TableCell align="left" className="table-header">
                        {" "}
                        Designation{" "}
                      </TableCell>
                      <TableCell align="left" className="table-header">
                        {" "}
                        Address{" "}
                      </TableCell>
                      <TableCell align="left" className="table-header">
                        {" "}
                        Zip{" "}
                      </TableCell>
                      <TableCell align="left" className="table-header">
                        {" "}
                        City{" "}
                      </TableCell>
                      <TableCell align="left" className="table-header">
                        {" "}
                        Action{" "}
                      </TableCell>
                    </TableRow>

                    <TableBody>
                      {user_list.length > 0 ? (
                        user_list.map((row, index) => (
                          <TableRow className="table-row" key={row.name}>
                            <TableCell
                              align="left"
                              component="th"
                              scope="row"
                              onClick={() =>
                                history.push("/ViewUsers/" + row.id)
                              }>
                              {" "}
                              {index + 1}{" "}
                            </TableCell>
                            <TableCell
                              align="left"
                              onClick={() =>
                                history.push("/ViewUsers/" + row.id)
                              }>
                              {" "}
                              {row.name}{" "}
                            </TableCell>
                            <TableCell
                              align="left"
                              onClick={() =>
                                history.push("/ViewUsers/" + row.id)
                              }>
                              {" "}
                              {row.mobile}{" "}
                            </TableCell>
                            <TableCell
                              align="left"
                              onClick={() =>
                                history.push("/ViewUsers/" + row.id)
                              }>
                              {" "}
                              {row.email}{" "}
                            </TableCell>
                            <TableCell
                              align="left"
                              onClick={() =>
                                history.push("/ViewUsers/" + row.id)
                              }>
                              {" "}
                              {row.user_role}{" "}
                            </TableCell>
                            <TableCell
                              align="left"
                              onClick={() =>
                                history.push("/ViewUsers/" + row.id)
                              }>
                              {" "}
                              {row.designation}{" "}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{ width: "20%" }}
                              onClick={() =>
                                history.push("/ViewUsers/" + row.id)
                              }>
                              {" "}
                              {row.address}{" "}
                            </TableCell>
                            <TableCell
                              align="left"
                              onClick={() =>
                                history.push("/ViewUsers/" + row.id)
                              }>
                              {" "}
                              {row.zip}{" "}
                            </TableCell>
                            <TableCell
                              align="left"
                              onClick={() =>
                                history.push("/ViewUsers/" + row.id)
                              }>
                              {" "}
                              {row.city}{" "}
                            </TableCell>
                            <TableCell align="left">
                              <Box className="table-action">
                                <Link to={`/edituser/${row.id}`}>
                                  <Tooltip title="Edit" placement="top" arrow>
                                    <IconButton
                                      size={isMobile ? "small" : "medium"}>
                                      <img
                                        className="iconstyle"
                                        src={Addedit}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </Link>
                                <Tooltip
                                  title="Send Invitation"
                                  placement="top"
                                  arrow>
                                  <IconButton
                                    size={isMobile ? "small" : "medium"}
                                    onClick={(e) =>
                                      sendInvitation(
                                        e,
                                        row.email,
                                        row.contact_user_id
                                      )
                                    }>
                                    <i className="far fa-paper-plane"></i>
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={9} className="nodata">
                            No Data to Display
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                {user_list.length > 0 && user_list !== undefined ? (
                  <Grid container spacing={2} className="paginate">
                    <Grid item xs={6} sm={6} md={4} xl={4}>
                      <Box>
                        <Stack spacing={2}>
                          <Pagination
                            count={page_count}
                            page={page_no}
                            variant="outlined"
                            shape="rounded"
                            onChange={handlePageClick}
                          />
                        </Stack>
                      </Box>
                    </Grid>
                  </Grid>
                ) : null}
              </Box>
            </Box>
          </Box>
        </>
      )}
    </ThemeContainer>
  );
}

ManageUsers.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(ManageUsers);
