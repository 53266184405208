import React, { useState, useEffect, useRef, createRef } from "react";
import {
  Grid,
  Box,
  Typography,
  OutlinedInput,
  withStyles,
  MenuItem,
  FormControl,
  Select,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Toolbar,
  IconButton,
  Snackbar,
  SnackbarContent,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Custom from "views/Components/Custom";
import { ConsoleView, isMobile } from "react-device-detect";
import preview from "assets/img/previewButton.svg";
import TableComponent from "views/Components/tableComponent/tablecomponent";
import ThemeContainer from "views/Components/sideBar/ThemeContainer";
import { getAccessToken } from "../../../../utilities/tokenUtil";
import { setAccessToken } from "utilities/tokenUtil";
import image1 from "assets/img/coun1.svg";
import image from "../../../../assets/img/avatar.png";
import EditOutlinedIcon from "@material-ui/icons/BorderColor";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { Link } from "react-router-dom";
import image11 from "assets/img/write.svg";
import image2 from "assets/img/right(1).png";
import image3 from "assets/img/delete(3).png";
import image4 from "assets/img/eyes.png";
import Slider from "react-slick";
import TablePagination from "@mui/material/TablePagination";
import { Spinner } from "react-bootstrap";
import Tooltip from "@mui/material/Tooltip";

var axios = require("axios");

const styles = (theme) => ({
  textFields: {
    background: "#F3F5F6",
    height: "40px",
    marginTop: "1%",
    "& div": {
      "&::before": {
        content: "",
        borderBottom: "none",
      },
      "&::after": {
        borderBottom: "none",
      },
      "&:hover": {
        "&::before": {
          borderBottom: "none !important",
        },
        "&::after": {
          borderBottom: "none !important",
        },
      },
      "& .MuiInputBase-input": {
        padding: "9px 7px",
      },
    },
  },
});

export default function Authorized() {
  const user_Id = localStorage.getItem("userId");
  const userName = localStorage.getItem("userName");
  const kyc_status = localStorage.getItem("kyc_status");
  const api_url = process.env.REACT_APP_URL;
  const history = useHistory();
  const [addAuthorized, setAddAuthorized] = useState(true);
  const [ListAuthorized, setListAuthorized] = useState([]);
  const [roles, setRoles] = useState("");
  const [roles1, setRoles1] = useState("");
  const [name, setName] = useState("");
  const [status, setStatus] = useState("");
  const [fullame, setFullname] = useState("");
  const [mobile, setmobile] = useState("");
  const [femail, setfemail] = useState("");
  const [fmobile, setfmobile] = useState("");
  const [email, setEmail] = useState("");
  const [Deg, setDeg] = useState("");
  const [bvn, setBvn] = useState("");
  const [working, setworking] = useState("");
  const [address, setAddress] = useState("");
  const [zip, setzip] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [stateList, setStateList] = useState([]);
  const [city, setCity] = useState("");
  const [allcountry, setcountryList] = useState([]);
  const [hqList, setHqList] = useState([]);
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [pathnerId, setpatnerId] = useState(0);
  const [filterName, setFilterName] = useState("");
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState({});
  const [filter_Box_StartDate, setFilter_Box_StartDate] = useState({});
  const [filter_Box_EndDate, setFilter_Box_EndDate] = useState({});
  const [filter_Box_Roles, setFilter_Box_Roles] = useState({});
  const [filter_Box_Name, setFilter_Box_Name] = useState({});
  const [filter_Box_Status, setFilter_Box_Status] = useState({});
  const [docList, setDocList] = useState([]);
  const [file2, setfile2] = useState();
  const [sendfile, setfilesend] = useState();
  const [uploadDoc, setuploadDoc] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [userId, setuserId] = useState();
  const [taxID, setTaxID] = useState("");
  let regEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const documentRef = useRef([]);
  documentRef.current = [];

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loader, setLoader] = useState(false);
  const [contact_user_id, setContactUserId] = useState("");

  if (!user_Id || userName === null) {
    history.push("/signin");
  }
  if (kyc_status !== "Approved") {
    history.push("/kyc-page");
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const addToRefs = (el) => {
    if (el && !documentRef.current.includes(el)) documentRef.current.push(el);
  };

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    initialSlide: 0,
    padSlides: false,
  };
  const editUpload = (event, type, id, name) => {
    const access_token = getAccessToken();
    let data1 = new FormData();
    data1.append("user_id", parseInt(localStorage.getItem("userId")));
    data1.append("access_token", access_token);
    data1.append("document_id", id);
    data1.append("document_type_id", type);
    data1.append("document_name", name);
    data1.append("document_data", event.target.files[0]);
    data1.append("partner_id", pathnerId);
    setLoader(false);
    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/user/document/upload`, data1, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response1) => {
        if (response1.data.success == true) {
          uploadDocumentList(pathnerId != 0 ? pathnerId : 0);
          setpatnerId(pathnerId);
          setLoading(false);
          setOpen(true);
          setLoader(true);
          setMessage({ type: "succes", content: response.data.message });
        }
      })
      .catch((error) => {});
  };

  const viewPage = (id) => {
    history.push(`/viewSignatory/${id}`);
  };

  const handleTeamMemberImageChage2 = (event) => {
    const access_token = getAccessToken();
    if (event.target.files && event.target.files[0]) {
      let fileext = event.target.files[0];

      if (
        fileext.name.match(/\.(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/) &&
        fileext.size < 2000000
      ) {
        setfilesend(event.target.files[0]);
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setfile2(reader.result);
        });
        reader.readAsDataURL(event.target.files[0]);
      } else {
        setLoading(false);
        setOpen(true);
        setMessage({
          type: "error",
          content:
            "Only jpg, jpeg, PNG, PDF gif files are allowed which are smaller an 2mb!",
        });
      }
    }
  };
  var pid = 0;
  const handleTeamMemberImageChage = async (event, id) => {
    // if (pathnerId == 0) {
    const access_token = getAccessToken();

    let formData = new FormData();

    formData.append("user_id", localStorage.getItem("userId")); //append the values with key, value pair
    formData.append("access_token", access_token);
    formData.append("id", pathnerId != 0 && !isNaN(pathnerId) ? pathnerId : 0);
    formData.append("user_type", "authorized_signatory");
    formData.append("name", fullame);
    formData.append("mobile", mobile);
    formData.append("working_location_id", working); //append the values with key, value pair
    formData.append("address", address);
    formData.append("country_id", country);
    formData.append("state_id", state);
    formData.append("city", city);
    formData.append("zip", zip);
    formData.append("address", address);
    formData.append("designation", Deg);
    formData.append("email", email);
    formData.append("bvn_number", bvn);
    formData.append("password", "");
    formData.append("image", sendfile === undefined ? "" : sendfile);
    formData.append("user_role", roles1);

    const data = {
      user_id: localStorage.getItem("userId"),
      access_token: access_token,
      id: pathnerId,
      user_type: "authorized_signatory",
      status: status,
      name: fullame,
      mobile: mobile,
      working_location_id: working,
      address: address,
      country_id: country,
      state_id: state,
      city: city,
      zip: zip,
      address: address,
      designation: Deg,
      email: email,
      bvn_number: bvn,
      image: sendfile,
      user_role: roles1,
    };

    const datass = await axios.post(
      `${process.env.REACT_APP_URL}/api/v1/user/update`,
      formData,
      {
        headers: {
          "Content-Type": "text/plain",
        },
      }
    );
    if (datass) {
      if (datass.data.success) {
        setpatnerId(parseInt(datass.data.partner_id));
        pid = datass.data.partner_id;
        setIsLoading(!isLoading);
      } else {
        setLoading(false);
        setOpen(true);
        setMessage({ type: "error", content: datass.data.message });
        return;
      }
      // setpatnerId(datass.data.partner_id);
    }

    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {});
      reader.readAsDataURL(event.target.files[0]);
      let myVar = docList.filter((x) => x.id === id);

      const access_token = getAccessToken();
      const userID = localStorage.getItem("userId");
      // setAccessToken(access_token);

      const data11 = {
        user_id: localStorage.getItem("userId"),
        access_token: access_token,
        document_type_id: id,
        partner_id: pathnerId != 0 ? pathnerId : pid,
      };

      setLoader(false);
      await axios
        .post(`${process.env.REACT_APP_URL}/api/v1/user/document/id`, data11, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then(async (response) => {
          const data0 = {
            user_id: parseInt(localStorage.getItem("userId")),
            access_token: access_token,
            document_type_id: id,
          };

          let data1 = new FormData();
          data1.append("user_id", localStorage.getItem("userId"));
          data1.append("access_token", access_token);
          data1.append("document_id", response.data.document_id);
          data1.append("document_type_id", id);
          data1.append("document_name", myVar[0].name);
          data1.append("document_data", event.target.files[0]);
          data1.append("partner_id", pathnerId != 0 ? pathnerId : pid);

          await axios
            .post(
              `${process.env.REACT_APP_URL}/api/v1/user/document/upload`,
              data1,
              {
                headers: {
                  "Content-Type": "text/plain",
                },
              }
            )
            .then((response1) => {
              if (response1.data.success == true) {
                uploadDocumentList(pathnerId != 0 ? pathnerId : pid);
                setpatnerId(pathnerId != 0 ? pathnerId : pid);
                setLoader(true);
                setLoading(false);
                setOpen(true);
                setMessage({
                  type: "success",
                  content: response1.data.message,
                });
              }
            })
            .catch((error) => {});
        })
        .catch((error) => {});
    }
  };

  const ListOfDocuments = () => {
    const access_token = getAccessToken();

    // setAccessToken(access_token);

    const data = {
      access_token: access_token,
      user_id: parseInt(localStorage.getItem("userId")),
    };

    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/signatory/master`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        setDocList(response.data.signatory_documents);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const uploadDocumentList = (id) => {
    const access_token = getAccessToken();

    const data = {
      access_token: access_token,
      user_id: parseInt(localStorage.getItem("userId")),
      contact_id: id,
      type: "authorized_signatory",
    };

    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/user/detail`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        setuploadDoc(response.data.documents);
        setContactUserId(response.data.contact_user_id);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const clearList = () => {
    setTaxID("");
    setfmobile("");
    setfemail("");
    setFilterName("");
    allAuthList();
  };

  const masterFunction = () => {
    const access_token = getAccessToken();

    // const user = jwt.decode(getAccessToken());
    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: access_token,
    };

    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/invite/user/master`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        setHqList(response.data.working_location);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function handleState() {
    setAddAuthorized(!addAuthorized);
  }

  const contactImage = (event) => {
    const access_token = getAccessToken();
    if (event.target.files && event.target.files[0]) {
      setfile(event.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setfile2(reader.result);
      });
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const removeUserImage = () => {
    setfilesend("");

    setfile2("");
  };

  useEffect(() => {
    document.title = "Interswitch - Signatory";
    setuserId(localStorage.getItem("userId"));
    ListOfDocuments();
    allAuthList();
    masterFunction();
  }, [addAuthorized]);
  const allAuthList = () => {
    const access_token = getAccessToken();
    const data = {
      access_token: access_token,
      user_id: parseInt(localStorage.getItem("userId")),
      //user_type: localStorage.getItem("trader_type"),
    };

    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/signatory/list`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        setListAuthorized(response.data.signatory);
        setLoader(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSubmit = () => {
    const access_token = getAccessToken();
    let mobile_validate =
      /^(?:\+)?\s*[0][1-9]\d{9,12}$|^(?:\+)?\s*[1-9]\d{9,12}$/;

    let bvn_validate = /^[0][1-9]\d{10}$|^[1-9]\d{10}$/;

    if (
      fullame.trim() === "" ||
      !regEmail.test(email) ||
      email.trim() === "" ||
      email.trim() === undefined ||
      mobile.trim() === "" ||
      Deg.trim() === "" ||
      bvn.trim() === "" ||
      working === "" ||
      address.trim() === "" ||
      zip.trim() === "" ||
      city.trim() === "" ||
      state === "" ||
      country === "" ||
      roles1 === ""
    ) {
      setLoading(false);
      setOpen(true);
      setMessage({ type: "error", content: "All fields are mandatory." });
      return;
    }

    if (!mobile.match(mobile_validate)) {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "Mobile number should be 10 to 13 digit",
      });
      return;
    }
    if (!bvn.match(bvn_validate)) {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "BVN number should be 11 digit",
      });
      return;
    }
    /*if (password != password2) {
      setLoading(false);
      setOpen(true);
      setMessage({ type: "error", content: "password not match" });
      return;
    }*/

    var a = new Set([]);
    docList.map((item) => {
      if (item.is_required === true) {
        uploadDoc.map((x) => {
          x.doc_list.map((y) => {
            if (y.document_type_id === item.id) {
              a.add(y.document_type_id);
            }
          });
        });
      }
    });

    let array = Array.from(a);

    let req = [];
    docList.map((item) => {
      if (item.is_required === true) {
        req.push(item.id);
      }
    });

    if (
      Array.isArray(req) &&
      Array.isArray(array) &&
      req.length === array.length &&
      req.every((val, index) => val === array[index])
    ) {
    } else {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "Please upload all mandatory documents.",
      });
      return;
    }

    let formData = new FormData();

    formData.append("user_id", localStorage.getItem("userId")); //append the values with key, value pair
    formData.append("access_token", access_token);
    formData.append("id", pathnerId != 0 && !isNaN(pathnerId) ? pathnerId : 0);
    formData.append("user_type", "authorized_signatory");
    formData.append("name", fullame.trim());
    formData.append("mobile", mobile.trim());
    formData.append("working_location_id", working); //append the values with key, value pair
    formData.append("address", address.trim());
    formData.append("country_id", country);
    formData.append("state_id", state);
    formData.append("city", city.trim());
    formData.append("zip", zip.trim());
    formData.append("address", address.trim());
    formData.append("designation", Deg.trim());
    formData.append("email", email.trim());
    formData.append("bvn_number", bvn.trim());
    //formData.append("password", password);
    formData.append("image", sendfile === undefined ? "" : sendfile);
    formData.append("user_role", roles1);
    setLoader(false);
    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/user/update`, formData, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response1) => {
        if (response1.data.success == true) {
          setLoading(false);
          setOpen(true);
          setMessage({ type: "success", content: response1.data.message });
          /*setTimeout(() => {
            history.push("/signatory")
          }, 1000);*/
          setAddAuthorized(!addAuthorized);
          setLoader(true);
        } else {
          setLoading(false);
          setOpen(true);
          setLoader(true);
          setMessage({ type: "error", content: response1.data.message });
        }
      })
      .catch((error) => {
        setLoader(true);
      });
  };
  // Send invitation to the user
  const submitSendInvitation = () => {
    let mobile_validate =
      /^(?:\+)?\s*[0][1-9]\d{9,12}$|^(?:\+)?\s*[1-9]\d{9,12}$/;

    let bvn_validate = /^[0][1-9]\d{10}$|^[1-9]\d{10}$/;
    if (
      fullame.trim() === "" ||
      !regEmail.test(email) ||
      email.trim() === "" ||
      email.trim() === undefined ||
      mobile.trim() === "" ||
      Deg.trim() === "" ||
      bvn.trim() === "" ||
      working === "" ||
      address.trim() === "" ||
      zip.trim() === "" ||
      city.trim() === "" ||
      state === "" ||
      country === "" ||
      roles1 === ""
    ) {
      setLoading(false);
      setOpen(true);
      setMessage({ type: "error", content: "All fields are mandatory." });
      return;
    }
    if (!mobile.match(mobile_validate)) {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "Mobile number should be 10 to 13 digit",
      });
      return;
    }
    if (!bvn.match(bvn_validate)) {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "BVN number should be 11 digit",
      });
      return;
    }

    let uploadFile;
    if (file2) {
      uploadFile = file2;
    } else {
      uploadFile = "";
    }

    var a = new Set([]);
    docList.map((item) => {
      if (item.is_required === true) {
        uploadDoc.map((x) => {
          x.doc_list.map((y) => {
            if (y.document_type_id === item.id) {
              a.add(y.document_type_id);
            }
          });
        });
      }
    });

    let array = Array.from(a);

    let req = [];
    docList.map((item) => {
      if (item.is_required === true) {
        req.push(item.id);
      }
    });

    if (
      Array.isArray(req) &&
      Array.isArray(array) &&
      req.length === array.length &&
      req.every((val, index) => val === array[index])
    ) {
    } else {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "Please upload all mandatory documents.",
      });
      return;
    }
    const access_token = getAccessToken();
    if (
      contact_user_id === "" ||
      contact_user_id === undefined ||
      contact_user_id === null
    ) {
      let data = new FormData();
      formData.append("user_id", localStorage.getItem("userId")); //append the values with key, value pair
      formData.append("access_token", access_token);
      formData.append(
        "id",
        pathnerId != 0 && !isNaN(pathnerId) ? pathnerId : 0
      );
      formData.append("user_type", "authorized_signatory");
      formData.append("name", fullame.trim());
      formData.append("mobile", mobile.trim());
      formData.append("working_location_id", working); //append the values with key, value pair
      formData.append("address", address.trim());
      formData.append("country_id", country);
      formData.append("state_id", state);
      formData.append("city", city.trim());
      formData.append("zip", zip.trim());
      formData.append("designation", Deg.trim());
      formData.append("email", email.trim());
      formData.append("bvn_number", bvn.trim());
      //formData.append("password", password);
      formData.append("image", sendfile === undefined ? "" : sendfile);
      formData.append("user_role", roles1);
      setLoader(false);
      const config = {
        method: "post",
        url: process.env.REACT_APP_URL + "/api/v1/user/update",
        headers: {
          "Content-Type": "text/plain",
        },
        data: data,
      };
      axios(config)
        .then((response) => {
          if (response && response.data) {
            if (response.data.success === true) {
              setPartnerId(response.data.user_id);
              sendSubmitInvitation(response.data.user_id);
            } else {
              setLoading(false);
              setOpen(true);
              setMessage({ type: "error", content: response.data.message });
              setLoader(true);
            }
          } else {
            setLoader(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      sendSubmitInvitation(contact_user_id);
    }
  };
  const sendSubmitInvitation = (contact_userid) => {
    let mobile_validate =
      /^(?:\+)?\s*[0][1-9]\d{9,12}$|^(?:\+)?\s*[1-9]\d{9,12}$/;

    let bvn_validate = /^[0][1-9]\d{10}$|^[1-9]\d{10}$/;
    if (
      fullame.trim() === "" ||
      !regEmail.test(email) ||
      email.trim() === "" ||
      email.trim() === undefined ||
      mobile.trim() === "" ||
      Deg.trim() === "" ||
      bvn.trim() === "" ||
      working === "" ||
      address.trim() === "" ||
      zip.trim() === "" ||
      city.trim() === "" ||
      state === "" ||
      country === "" ||
      roles1 === ""
    ) {
      setLoading(false);
      setOpen(true);
      setMessage({ type: "error", content: "All fields are mandatory." });
      return;
    }
    if (!mobile.match(mobile_validate)) {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "Mobile number should be 10 to 13 digit",
      });
      return;
    }
    if (!bvn.match(bvn_validate)) {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "BVN number should be 11 digit",
      });
      return;
    }

    let uploadFile;
    if (file2) {
      uploadFile = file2;
    } else {
      uploadFile = "";
    }

    var a = new Set([]);
    docList.map((item) => {
      if (item.is_required === true) {
        uploadDoc.map((x) => {
          x.doc_list.map((y) => {
            if (y.document_type_id === item.id) {
              a.add(y.document_type_id);
            }
          });
        });
      }
    });

    let array = Array.from(a);

    let req = [];
    docList.map((item) => {
      if (item.is_required === true) {
        req.push(item.id);
      }
    });

    if (
      Array.isArray(req) &&
      Array.isArray(array) &&
      req.length === array.length &&
      req.every((val, index) => val === array[index])
    ) {
    } else {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "Please upload all mandatory documents.",
      });
      return;
    }

    const access_token = getAccessToken();
    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      id: parseInt(contact_userid ? contact_userid : 0),
      access_token: access_token,
      email: email,
    };
    setLoader(false);
    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/send/user/invitation`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        if (response.data.success === true) {
          setLoading(false);
          setOpen(true);
          setMessage({ type: "success", content: response.data.message });
          setAddAuthorized(!addAuthorized);
          setLoader(true);
        } else {
          setLoading(false);
          setOpen(true);
          setMessage({ type: "error", content: response.data.message });
          setLoader(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const filter = () => {
    let regEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let mobile_validate =
      /^(?:\+)?\s*[0][1-9]\d{9,12}$|^(?:\+)?\s*[1-9]\d{9,12}$/;

    const access_token = getAccessToken();
    if (femail) {
      if (!regEmail.test(femail)) {
        setLoading(false);
        setOpen(true);
        setMessage({ type: "error", content: "Please enter a valid email." });
        return false;
      }
    }
    if (fmobile) {
      if (!fmobile.match(mobile_validate)) {
        setLoading(false);
        setOpen(true);
        setMessage({
          type: "error",
          content: "Mobile number should be 10 to 13 digit",
        });
        return false;
      }
    }
    const data123 = {
      access_token: access_token,
      user_id: parseInt(localStorage.getItem("userId")),
      user_type: localStorage.getItem("trader_type"),
      signatory_name: filterName,
      status: status,
      email: femail,
      mobile: fmobile,
      tax_id: taxID,
    };

    axios
      .post(
        `${process.env.REACT_APP_URL}/api/v1/signatory/list/search`,
        data123,
        {
          headers: {
            "Content-Type": "text/plain",
          },
        }
      )
      .then((response) => {
        setListAuthorized(response.data.signatory_list);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const countryFunction = () => {
    const access_token = getAccessToken();
    const userID = localStorage.getItem("userId");
    // setAccessToken(access_token);

    const data = {
      user_id: localStorage.getItem("userId"),
      access_token: access_token,
    };

    axios
      .post(
        `${process.env.REACT_APP_URL}/api/v1/country/master`,
        JSON.stringify(data)
      )
      .then((response) => {
        if (response && response.data.sucess) {
          setcountryList(response.data.country);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const remove = (type, id) => {
    const access_token = getAccessToken();

    const dataSet = JSON.stringify({
      access_token: access_token,
      user_id: localStorage.getItem("userId"),
      partner_id: pathnerId,
      document_type_id: type,
      document_id: id,
    });

    const config = {
      method: "post",
      url: process.env.REACT_APP_URL + "/api/v1/user/document/delete",
      headers: {
        "Content-Type": "text/plain",
      },
      data: dataSet,
    };
    axios(config)
      .then((response) => {
        setLoading(false);
        setOpen(true);
        setMessage({ type: "succes", content: response.data.message });
        uploadDocumentList(pathnerId);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    countryFunction();
  }, []);

  const fetchState = (country_id) => {
    const dataSet = JSON.stringify({
      country_id: country_id,
    });
    const config = {
      method: "post",
      url: process.env.REACT_APP_URL + "/api/v1/state/master",
      headers: {
        "Content-Type": "text/plain",
      },
      data: dataSet,
    };
    axios(config)
      .then((response) => {
        setStateList(response.data.state);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const openFileSystem = async (event, ref) => {
    let mobile_validate =
      /^(?:\+)?\s*[0][1-9]\d{9,12}$|^(?:\+)?\s*[1-9]\d{9,12}$/;

    let bvn_validate = /^[0][1-9]\d{10}$|^[1-9]\d{10}$/;

    if (
      fullame.trim() === "" ||
      !regEmail.test(email) ||
      email.trim() === "" ||
      email.trim() === undefined ||
      mobile.trim() === "" ||
      Deg.trim() === "" ||
      bvn.trim() === "" ||
      working === "" ||
      address.trim() === "" ||
      zip.trim() === "" ||
      city.trim() === "" ||
      state === "" ||
      country === "" ||
      roles1 === ""
    ) {
      setLoading(false);
      setOpen(true);
      setMessage({ type: "error", content: "All fields are mandatory." });
      return;
    }

    if (!mobile.match(mobile_validate)) {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "Mobile number should be 10 to 13 digit",
      });
      return;
    }
    if (!bvn.match(bvn_validate)) {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "BVN number should be 11 digit",
      });
      return;
    }

    /*if (password != password2) {
      setLoading(false);
      setOpen(true);
      setMessage({ type: "error", content: "password not match" });
      return;
    }*/
    ref.click();
  };

  // Send invitation to the user
  const sendInvitation = (event, email, contact_user_id) => {
    event.preventDefault();
    const access_token = getAccessToken();

    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      id: parseInt(contact_user_id ? contact_user_id : 0),
      access_token: access_token,
      email: email,
    };

    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/send/user/invitation`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        if (response.data.success === true) {
          setLoading(false);
          setOpen(true);
          setMessage({ type: "success", content: response.data.message });
        } else {
          setLoading(false);
          setOpen(true);
          setMessage({ type: "error", content: response.data.message });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleMobileNumber = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setfmobile(e.target.value);
    }
  };

  return (
    <ThemeContainer type="private" value={2}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}>
        <SnackbarContent
          className={
            message.type === "error"
              ? "snack-error"
              : message.type === "success"
              ? "snack-success"
              : "snack-teal"
          }
          message={message.content}
        />
      </Snackbar>
      {loader !== true ? (
        <Grid container spacing={3} className="bootstrap-loader">
          <Spinner animation="grow" variant="spinner1" />
          <Spinner animation="grow" variant="spinner2" />
          <Spinner animation="grow" variant="spinner3" />
        </Grid>
      ) : (
        <>
          {addAuthorized ? (
            <>
              <Box className="scf-box">
                <Box className="scf-subbox">
                  <Box>
                    <Grid container>
                      <Grid item xs={6} sm={6} md={10} xl={10}>
                        <Typography className="listing-main-title">
                          Authorized Signatory
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={2} xl={2}>
                        <Button className="upload" onClick={handleState}>
                          Add New Signatory
                        </Button>
                      </Grid>
                    </Grid>
                    <Typography className="company-title2"> Filters</Typography>

                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={12} md={4} xl={4}>
                        <Box>
                          <span className="company-title3">
                            By Name<span className="required">*</span>
                          </span>
                          <OutlinedInput
                            className="company-title5"
                            fullWidth
                            value={filterName}
                            placeholder="Enter Name"
                            onChange={(e) => setFilterName(e.target.value)}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={12} md={4} xl={4}>
                        <Box>
                          <span className="company-title3">
                            By Email<span className="required">*</span>
                          </span>
                          <OutlinedInput
                            className="company-title5"
                            fullWidth
                            value={femail}
                            placeholder="Enter Email"
                            onChange={(e) => setfemail(e.target.value)}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={12} md={4} xl={4}>
                        <Box>
                          <span className="company-title3">
                            By Mobile<span className="required">*</span>
                          </span>
                          <OutlinedInput
                            className="company-title5"
                            fullWidth
                            value={fmobile}
                            placeholder="Enter Mobile"
                            onChange={(e) => handleMobileNumber(e)}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={12} md={4} xl={4}>
                        <Box>
                          <span className="company-title3">
                            Taxation Number<span className="required">*</span>
                          </span>
                          <OutlinedInput
                            className="company-title5"
                            fullWidth
                            value={taxID}
                            placeholder="Taxation Number"
                            onChange={(e) => setTaxID(e.target.value)}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={12} md={4} xl={4}></Grid>
                      <Grid item xs={6} sm={12} md={4} xl={4}>
                        <span className="company-title3">&nbsp;</span>
                        <Box className="filter-action">
                          <Button className="submit-button" onClick={filter}>
                            Submit
                          </Button>
                          <Button className="cancel-button" onClick={clearList}>
                            Clear
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                    <Typography className="list-sub-title">
                      Signatory List
                    </Typography>
                  </Box>

                  <Box>
                    <TableContainer component={Paper}>
                      <Table className="table-top" aria-label="simple table">
                        <TableHead>
                          <TableRow className="table-row">
                            <TableCell align="left" className="table-header">
                              S. No.{" "}
                            </TableCell>
                            <TableCell align="left" className="table-header">
                              {" "}
                              Name{" "}
                            </TableCell>
                            <TableCell align="left" className="table-header">
                              {" "}
                              Mobile No{" "}
                            </TableCell>
                            <TableCell align="left" className="table-header">
                              {" "}
                              Email{" "}
                            </TableCell>
                            <TableCell align="left" className="table-header">
                              {" "}
                              Roles{" "}
                            </TableCell>
                            <TableCell align="left" className="table-header">
                              {" "}
                              Taxation ID{" "}
                            </TableCell>
                            <TableCell align="left" className="table-header">
                              {" "}
                              Address{" "}
                            </TableCell>
                            <TableCell align="left" className="table-header">
                              {" "}
                              City{" "}
                            </TableCell>
                            <TableCell align="left" className="table-header">
                              {" "}
                              Action{" "}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {ListAuthorized && ListAuthorized.length > 0 ? (
                            ListAuthorized.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            ).map((row, index) => (
                              <>
                                <TableRow className="table-row" key={row.name}>
                                  <TableCell
                                    onClick={(e) => viewPage(row.partner_id)}
                                    component="th"
                                    scope="row">
                                    {index + 1}{" "}
                                  </TableCell>
                                  <TableCell
                                    onClick={(e) => viewPage(row.partner_id)}
                                    align="left">
                                    {row.name}
                                  </TableCell>
                                  <TableCell
                                    onClick={(e) => viewPage(row.partner_id)}
                                    align="left">
                                    {row.mobile}
                                  </TableCell>
                                  <TableCell
                                    onClick={(e) => viewPage(row.partner_id)}
                                    align="left">
                                    {row.email}
                                  </TableCell>
                                  <TableCell
                                    onClick={(e) => viewPage(row.partner_id)}
                                    align="left">
                                    {row.user_role}
                                  </TableCell>
                                  <TableCell
                                    onClick={(e) => viewPage(row.partner_id)}
                                    align="left">
                                    {row.bvn_number}
                                  </TableCell>
                                  <TableCell
                                    onClick={(e) => viewPage(row.partner_id)}
                                    align="left">
                                    {row.address}
                                  </TableCell>
                                  <TableCell
                                    onClick={(e) => viewPage(row.partner_id)}
                                    align="left">
                                    {row.city}
                                  </TableCell>

                                  <TableCell>
                                    <Box className="table-action">
                                      <Link
                                        to={`/editSignatory/${row.partner_id}`}>
                                        <Tooltip
                                          title="Edit"
                                          placement="top"
                                          arrow>
                                          <IconButton
                                            size={
                                              isMobile ? "small" : "medium"
                                            }>
                                            <img
                                              className="iconstyle"
                                              src={image11}
                                            />
                                          </IconButton>
                                        </Tooltip>
                                      </Link>
                                      <Tooltip
                                        title="Send Invitation"
                                        placement="top"
                                        arrow>
                                        <IconButton
                                          size={isMobile ? "small" : "medium"}
                                          onClick={(e) =>
                                            sendInvitation(
                                              e,
                                              row.email,
                                              row.contact_user_id
                                            )
                                          }>
                                          <i className="far fa-paper-plane"></i>
                                        </IconButton>
                                      </Tooltip>
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              </>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell colSpan={9} className="nodata">
                                No Data to Display
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Box>
              </Box>
            </>
          ) : (
            <Box className="scf-box">
              <Box className="scf-subbox">
                <Grid container>
                  <Grid item xs={12} sm={12} md={12} xl={12}>
                    <Typography className="company-title11">
                      Add New Signatory Person
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  container
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="form-style">
                  <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <center>
                      <Box className="image-box">
                        {file2 ? (
                          <img className="img-thumb" src={file2} />
                        ) : (
                          <img className="img-thumb" src={image} />
                        )}
                        {!file2 && (
                          <Box className="img-action-option edit">
                            <center>
                              <label>
                                <EditOutlinedIcon className="img-edit" />
                                <input
                                  type="file"
                                  hidden
                                  onChange={(e) =>
                                    handleTeamMemberImageChage2(e)
                                  }
                                />
                              </label>
                            </center>
                          </Box>
                        )}
                        {file2 && (
                          <Box
                            className="img-action-option delete"
                            onClick={removeUserImage}>
                            <center>
                              <DeleteOutlineIcon className="img-delete" />
                            </center>
                          </Box>
                        )}
                      </Box>
                    </center>
                  </Grid>
                  <Grid item xs={8} sm={6} md={4} lg={4} xl={4}>
                    <Typography className="img-heading">
                      {" "}
                      New Contact Person Photo
                    </Typography>
                    <Typography className="img-subheading">
                      {" "}
                      In PNG, PDF JPG, GIF and JPEG format <br></br>
                      size should be less then 2MB
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={4} xl={4}>
                    <Box className="input-box">
                      <span className="company-title3">
                        Full Name<span className="required">*</span>
                      </span>
                      <OutlinedInput
                        value={fullame}
                        className="company-title5"
                        fullWidth
                        placeholder="e.g. Full Name"
                        onChange={(e) => setFullname(e.target.value)}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12} md={4} xl={4}>
                    <Box className="input-box">
                      <span className="company-title3">
                        Mobile<span className="required">*</span>
                      </span>
                      <OutlinedInput
                        className="company-title5"
                        fullWidth
                        value={mobile}
                        placeholder="e.g. 12345556"
                        onChange={(e) => setmobile(e.target.value)}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12} md={4} xl={4}>
                    <Box className="input-box">
                      <span className="company-title3">
                        Email Address<span className="required">*</span>
                      </span>
                      <OutlinedInput
                        className="company-title5"
                        fullWidth
                        value={email}
                        placeholder="e.g. Email Address"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} xl={4}>
                    <Box className="input-box">
                      <span className="company-title3">
                        Designation<span className="required">*</span>
                      </span>

                      <OutlinedInput
                        className="company-title5"
                        fullWidth
                        placeholder=" e.g. TXN567890 "
                        value={Deg}
                        onChange={(e) => setDeg(e.target.value)}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12} md={4} xl={4}>
                    <Box className="input-box">
                      <span className="company-title3">
                        BVN Number<span className="required">*</span>
                      </span>
                      <OutlinedInput
                        className="company-title5"
                        fullWidth
                        placeholder="e.g. 123344"
                        value={bvn}
                        onChange={(e) => setBvn(e.target.value)}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} xl={4}>
                    <Box className="input-box">
                      <span className="company-title3">
                        Working at Branch/HO<span className="required">*</span>
                      </span>
                      <FormControl fullWidth>
                        <Select
                          displayEmpty
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={working}
                          label="Age"
                          onChange={(e) => setworking(e.target.value)}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}>
                          <MenuItem value="" disabled>
                            {" "}
                            Select from options
                          </MenuItem>
                          {hqList &&
                            hqList.map((item, index) => {
                              return (
                                <MenuItem value={item.id}>{item.name}</MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={4} xl={4}>
                    <Box className="input-box">
                      <span className="company-title3">
                        Address <span className="required">*</span>
                      </span>
                      <OutlinedInput
                        className="company-title5"
                        fullWidth
                        placeholder="e.g. Address"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        inputProps={{ maxLength: 30 }}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12} md={4} xl={4}>
                    <Box className="input-box">
                      <span className="company-title3">
                        Zip/ Postal Code<span className="required">*</span>
                      </span>
                      <OutlinedInput
                        className="company-title5"
                        fullWidth
                        placeholder="e.g. Zip/ Postal Code"
                        value={zip}
                        onChange={(e) => setzip(e.target.value)}
                        inputProps={{ maxLength: 10 }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} xl={4}>
                    <Box className="input-box">
                      <span className="company-title3">
                        Country<span className="required">*</span>
                      </span>
                      <FormControl fullWidth>
                        <Select
                          displayEmpty
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={country}
                          label="Age"
                          onChange={(e) => {
                            setCountry(e.target.value);
                            fetchState(e.target.value);
                          }}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}>
                          <MenuItem value="" disabled>
                            {" "}
                            Select from options
                          </MenuItem>
                          {allcountry.map((item) => (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} xl={4}>
                    <Box className="input-box">
                      <span className="company-title3">
                        State/ Province<span className="required">*</span>
                      </span>
                      <FormControl fullWidth>
                        <Select
                          displayEmpty
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Age"
                          value={state}
                          onChange={(e) => setState(e.target.value)}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}>
                          <MenuItem value="" disabled>
                            {" "}
                            Select from options
                          </MenuItem>
                          {stateList !== "" ? (
                            stateList.map((item, index) => (
                              <MenuItem value={item.id}>{item.name}</MenuItem>
                            ))
                          ) : (
                            <></>
                          )}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} xl={4}>
                    <Box className="input-box">
                      <span className="company-title3">
                        City<span className="required">*</span>
                      </span>
                      <OutlinedInput
                        className="company-title5"
                        fullWidth
                        placeholder="e.g. Zip/ Postal Code"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        inputProps={{ maxLength: 30 }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} xl={4}>
                    <Box className="input-box">
                      <span className="company-title3">
                        Roles<span className="required">*</span>
                      </span>
                      <FormControl fullWidth>
                        <Select
                          displayEmpty
                          value={roles1}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Age"
                          onChange={(e) => setRoles1(e.target.value)}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}>
                          <MenuItem value={""}> Select from options</MenuItem>
                          <MenuItem value="initiator">
                            Orgnization Admin (Initiator)
                          </MenuItem>
                          <MenuItem value="admin">
                            Orgnization Admin (Admin)
                          </MenuItem>
                          <MenuItem value="approver">Approver</MenuItem>
                          <MenuItem value="executive">Executive</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>

                <Typography className="sub-heading">
                  Upload Documents
                </Typography>
                {docList &&
                  docList.map((item, index) => (
                    <Box className="upload-documents" key={index}>
                      <Grid container spacing={2}>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                              <center>
                                <h4 className="company-title3 documents-title">
                                  {item.name}{" "}
                                  {item.is_required === true ? (
                                    <span className="document-required">*</span>
                                  ) : (
                                    ""
                                  )}
                                </h4>
                              </center>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                              <center>
                                <Box className="button">
                                  <Button
                                    component="label"
                                    className="button-title"
                                    onClick={(event) =>
                                      openFileSystem(
                                        event,
                                        documentRef.current[index]
                                      )
                                    }>
                                    Upload Document{" "}
                                  </Button>
                                  <input
                                    ref={addToRefs}
                                    label="click"
                                    hidden
                                    type="file"
                                    onChange={(e) => {
                                      handleTeamMemberImageChage(e, item.id);
                                    }}
                                  />
                                </Box>
                              </center>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                          <Grid container spacing={2}>
                            {uploadDoc &&
                              uploadDoc.map((docItem_item) => (
                                <>
                                  {docItem_item.doc_id === item.id ? (
                                    <>
                                      {docItem_item.doc_list.map((doc) => (
                                        <>
                                          <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={6}
                                            lg={6}
                                            xl={6}>
                                            <center>
                                              <Box className="uploaded-documents-list image-box">
                                                {doc.image_extension ===
                                                "application/pdf" ? (
                                                  <embed
                                                    src={`${
                                                      api_url + doc.document_url
                                                    }?${Date.now()}`}
                                                    className="image"
                                                    type={doc.image_extension}
                                                  />
                                                ) : (
                                                  <img
                                                    className="image"
                                                    src={`${
                                                      api_url + doc.document_url
                                                    }?${Date.now()}`}
                                                  />
                                                )}
                                                <Box className="img-action-option edit">
                                                  <label className="edit-label">
                                                    <center>
                                                      <EditOutlinedIcon className="img-edit" />
                                                    </center>
                                                    <input
                                                      type="file"
                                                      hidden
                                                      onChange={(e) =>
                                                        editUpload(
                                                          e,
                                                          doc.document_type_id,
                                                          doc.document_id,
                                                          docItem_item.doc_name
                                                        )
                                                      }
                                                    />
                                                  </label>
                                                </Box>
                                                <Box className="img-action-option delete">
                                                  <center>
                                                    <DeleteOutlineIcon
                                                      onClick={() =>
                                                        remove(
                                                          doc.document_type_id,
                                                          doc.document_id
                                                        )
                                                      }
                                                      className="img-delete"
                                                    />
                                                  </center>
                                                </Box>
                                              </Box>
                                            </center>
                                          </Grid>
                                        </>
                                      ))}{" "}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </>
                              ))}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  ))}

                <Grid container spacing={3} className="button-grid">
                  <Grid item xs={6} sm={4} md={2} xl={2}>
                    <Button
                      className="save"
                      onClick={onSubmit}
                      disabled={
                        !fullame ||
                        !email ||
                        !mobile ||
                        !Deg ||
                        !city ||
                        !state ||
                        !country ||
                        !zip ||
                        !address ||
                        !working ||
                        !bvn ||
                        !roles1
                      }>
                      {" "}
                      Save{" "}
                    </Button>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2} xl={2}>
                    <Button className="cancel" onClick={handleState}>
                      {" "}
                      Cancel{" "}
                    </Button>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2} xl={2}>
                    <Button
                      className="invite"
                      onClick={submitSendInvitation}
                      disabled={
                        !fullame ||
                        !email ||
                        !mobile ||
                        !Deg ||
                        !city ||
                        !state ||
                        !country ||
                        !zip ||
                        !address ||
                        !working ||
                        !bvn ||
                        !roles1
                      }>
                      Send Invitation
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          )}
        </>
      )}
    </ThemeContainer>
  );
}
