import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  withStyles,
  OutlinedInput,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Snackbar,
  SnackbarContent,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { getAccessToken } from "../../utilities/tokenUtil";
import ThemeContainer from "views/Components/sideBar/ThemeContainer";
import { useHistory } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { isMobile } from "react-device-detect";

var axios = require("axios");

const styles = () => ({
  textFields: {
    background: "#F3F5F6",
    height: "40px",
    marginTop: "1%",
    "& div": {
      "&::before": {
        content: "",
        borderBottom: "none",
      },
      "&::after": {
        borderBottom: "none",
      },
      "&:hover": {
        "&::before": {
          borderBottom: "none !important",
        },
        "&::after": {
          borderBottom: "none !important",
        },
      },
      "& .MuiInputBase-input": {
        padding: "9px 7px",
      },
    },
  },
});

function ViewLender(props) {
  const { classes } = props;

  const userId = localStorage.getItem("userId");
  const userName = localStorage.getItem("userName");
  const kyc_status = localStorage.getItem("kyc_status");

  const history = useHistory();
  const [signatory, setSignatory] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [lenderDetail, setLenderDetail] = useState("");
  const [message, setMessage] = useState({});
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);

  if (!userId || userName === null) {
    history.push("/signin");
  }
  if (kyc_status !== "Approved") {
    history.push("/kyc-page");
  }
  const lenderData = () => {
    const access_token = getAccessToken();
    const data = {
      access_token: access_token,
      user_id: parseInt(localStorage.getItem("userId")),
      lender_id: parseInt(props.match.params.id),
    };
    axios
      .post(
        `${process.env.REACT_APP_URL}` + "/api/v1/get/lender/with/buyer",
        data,
        {
          headers: {
            "Content-Type": "text/plain",
          },
        }
      )
      .then((response) => {
        if (response && response.data.success === true) {
          setSignatory(response.data.signatory);
          setLenderDetail(response.data.lender_detail);
          setDocuments(response.data.documents);
          setLoader(true);
        } else {
          setLoader(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(true);
      });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleUploadImage = (
    event,
    document_id,
    document_type_id,
    document_name
  ) => {
    if (event.target.files && event.target.files[0]) {
      let fileext = event.target.files[0];
      if (
        fileext.name.match(/\.(jpg|jpeg|png|gif|pdf|JPG|JPEG|PNG|GIF|PDF)$/)
      ) {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          //
        });
        reader.readAsDataURL(event.target.files[0]);

        const access_token = getAccessToken();

        let data = new FormData();
        data.append("user_id", parseInt(localStorage.getItem("userId")));
        data.append("access_token", access_token);
        data.append("document_id", document_id);
        data.append("document_type_id", document_type_id);
        data.append("document_name", document_name);
        data.append("document_data", event.target.files[0]);
        axios
          .post(
            `${process.env.REACT_APP_URL}/api/v1/kyc/document/upload`,
            data,
            {
              headers: {
                "Content-Type": "text/plain",
              },
            }
          )
          .then((response) => {
            console.log("response", response);
            if (response.data.success) {
              setOpen(true);
              lenderData();
              setLoader(true);
              setMessage({ type: "success", content: response.data.message });
            } else {
              setOpen(true);
              lenderData();
              setLoader(true);
              setMessage({ type: "error", content: response.data.message });
            }
          })
          .catch((error) => {});
      } else {
        setLoading(false);
        setOpen(true);
        setMessage({
          type: "error",
          content: "Only jpg, jpeg, PNG, PDF pdf,gif files are allowed!",
        });
      }
    }
  };

  useEffect(() => {
    document.title = "Interswitch - Domiciled Banks Lender Detail";
    lenderData();
  }, []);

  return (
    <ThemeContainer type="private" value={3}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}>
        <SnackbarContent
          className={
            message.type === "error"
              ? "snack-error"
              : message.type === "success"
              ? "snack-success"
              : "snack-teal"
          }
          message={message.content}
        />
      </Snackbar>
      {loader !== true ? (
        <Grid container spacing={3} className="bootstrap-loader">
          <Spinner animation="grow" variant="spinner1" />
          <Spinner animation="grow" variant="spinner2" />
          <Spinner animation="grow" variant="spinner3" />
        </Grid>
      ) : (
        <Box className="scf-box">
          <Box className="scf-subbox">
            <Box>
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6} md={11} xl={11}>
                    <Typography className="listing-main-title">
                      {"Domiciled Banks Lender Detail"}{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={1} xl={1}>
                    <Button
                      className="upload back-button"
                      onClick={() => history.push("/domiciled-banks")}>
                      <i className="fas fa-long-arrow-alt-left"> Back </i>
                    </Button>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6} md={4} xl={4}>
                    <Box>
                      <span className="company-title3">Lender Name</span>
                      <OutlinedInput
                        className="company-title5"
                        fullWidth
                        placeholder="Lender Name"
                        value={lenderDetail.name}
                        readOnly
                      />
                    </Box>
                  </Grid>
                  {lenderDetail.email && (
                    <Grid item xs={6} sm={6} md={4} xl={4}>
                      <Box>
                        <span className="company-title3">Lender Email</span>
                        <OutlinedInput
                          className="company-title5"
                          fullWidth
                          placeholder="Lender Email"
                          value={lenderDetail.email}
                          readOnly
                        />
                      </Box>
                    </Grid>
                  )}
                  <Grid item xs={6} sm={6} md={4} xl={4}>
                    <Box>
                      <span className="company-title3">Lender RC Number</span>
                      <OutlinedInput
                        className="company-title5"
                        fullWidth
                        placeholder="Lender RC Number"
                        value={lenderDetail.rc_number}
                        readOnly
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4} xl={4}>
                    <Box>
                      <span className="company-title3">Margin rate(%)</span>
                      <OutlinedInput
                        className="company-title5"
                        fullWidth
                        placeholder="Margin"
                        value={lenderDetail.margin}
                        readOnly
                      />
                    </Box>
                  </Grid>
                  {lenderDetail.tax_number && (
                    <Grid item xs={6} sm={6} md={4} xl={4}>
                      <Box>
                        <span className="company-title3">
                          Lender Tax Number
                        </span>
                        <OutlinedInput
                          className="company-title5"
                          fullWidth
                          placeholder="Lender Tax Number"
                          value={lenderDetail.tax_number}
                          readOnly
                        />
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Box>
              <Box>
                <Typography className="list-sub-title">
                  KYC Documents List
                </Typography>
              </Box>
              <Box className="view-kyc-docs">
                <TableContainer component={Paper}>
                  <Table className="table-top" aria-label="simple table">
                    <TableHead>
                      <TableRow className="table-row">
                        <TableCell
                          className="table-header"
                          align="left"
                          style={{ width: "5%" }}>
                          {" "}
                          S. No.{" "}
                        </TableCell>
                        <TableCell align="left" className="table-header">
                          {" "}
                          Name{" "}
                        </TableCell>
                        <TableCell align="left" className="table-header">
                          {" "}
                          Uploaded By{" "}
                        </TableCell>
                        <TableCell align="left" className="table-header">
                          {" "}
                          Status{" "}
                        </TableCell>
                        <TableCell align="left" className="table-header">
                          {" "}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {documents &&
                      documents.length > 0 &&
                      documents !== undefined ? (
                        documents.map((info, index) => (
                          <TableRow className="table-row" key={index}>
                            <TableCell
                              align="left"
                              component="th"
                              scope="row"
                              onClick={() =>
                                history.push("/viewkycdocs/" + info.doc_id)
                              }>
                              {" "}
                              {index + 1}{" "}
                            </TableCell>
                            <TableCell
                              align="left"
                              onClick={() =>
                                history.push("/viewkycdocs/" + info.doc_id)
                              }>
                              {" "}
                              {info.name}{" "}
                            </TableCell>
                            <TableCell
                              align="left"
                              onClick={() =>
                                history.push("/viewkycdocs/" + info.doc_id)
                              }>
                              {" "}
                              {info.uploaded_by}{" "}
                            </TableCell>
                            <TableCell
                              align="left"
                              className={info.status
                                .toLowerCase()
                                .replace(/\s/g, "-")}
                              style={{ textTransform: "capitalize" }}>
                              {" "}
                              {info.status}{" "}
                            </TableCell>
                            {info.status !== "Awaiting Approval" &&
                            info.status !== "Approved" ? (
                              <TableCell align="left">
                                <label
                                  style={{
                                    color: "#0275D8",
                                    border: "1px solid #0275D8",
                                    borderRadius: "8px",
                                    textTransform: "initial",
                                    padding: isMobile ? "5%" : "10px",
                                    cursor: "pointer",
                                  }}>
                                  Upload Document
                                  <input
                                    label="click"
                                    style={{ display: "none" }}
                                    type="file"
                                    onChange={(e) => {
                                      handleUploadImage(
                                        e,
                                        info.doc_id,
                                        info.document_type_id,
                                        info.name
                                      );
                                    }}
                                  />
                                </label>
                              </TableCell>
                            ) : (
                              <TableCell align="left"></TableCell>
                            )}
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={5} className="nodata">
                            No Data to Display
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box>
                <Typography className="list-sub-title">
                  Signatory List
                </Typography>
              </Box>
              <Box className="view-kyc-docs no-cursor-table">
                <TableContainer component={Paper}>
                  <Table className="table-top" aria-label="simple table">
                    <TableHead>
                      <TableRow className="table-row">
                        <TableCell
                          className="table-header"
                          align="left"
                          style={{ width: "5%" }}>
                          {" "}
                          S. No.{" "}
                        </TableCell>
                        <TableCell align="left" className="table-header">
                          {" "}
                          Lender{" "}
                        </TableCell>
                        <TableCell align="left" className="table-header">
                          {" "}
                          Name{" "}
                        </TableCell>
                        <TableCell align="left" className="table-header">
                          {" "}
                          Position{" "}
                        </TableCell>
                        <TableCell align="left" className="table-header">
                          {" "}
                          Status{" "}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {signatory &&
                      signatory.length > 0 &&
                      signatory !== undefined ? (
                        signatory.map((info, index) => (
                          <TableRow className="table-row" key={index}>
                            <TableCell align="left" component="th" scope="row">
                              {" "}
                              {index + 1}{" "}
                            </TableCell>
                            <TableCell align="left"> {info.bank} </TableCell>
                            <TableCell align="left"> {info.name} </TableCell>
                            <TableCell align="left">
                              {" "}
                              {info.position}{" "}
                            </TableCell>
                            <TableCell
                              align="left"
                              className={info.status
                                .toLowerCase()
                                .replace(/\s/g, "-")}
                              style={{ textTransform: "capitalize" }}>
                              {" "}
                              {info.status}{" "}
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={5} className="nodata">
                            No Data to Display
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </ThemeContainer>
  );
}

ViewLender.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(ViewLender);
