import React from "react";
import { PropTypes } from "prop-types";
import { Box, Typography, Grid } from "@material-ui/core";
import bill from "../../../assets/img/bill.svg";
import discounting from "../../../assets/img/discounting.svg";
import interest from "../../../assets/img/interest.svg";
import creditLimit from "../../../assets/img/creditLimit.svg";
import dues from "../../../assets/img/dues.svg";
import penalties from "../../../assets/img/penalties.svg";

export default function TopCard(props) {
  const { title, subtitle, type } = props;
  let image;
  if(subtitle === "Total Invoice Value"){
    image = bill;
  }else if(subtitle === "Discounting Value"){
    image = discounting;
  }else if(subtitle === "Total Intrest Paid" || subtitle === "Total Funded" || subtitle === "Total Funded Invoice"){
    image = interest;
  }else if(subtitle === "Total Credit Limit"){
    image = creditLimit;
  }else if(subtitle === "Current Dues"){
    image = dues;
  }else if(subtitle === "Total Penalties"){
    image = penalties;
  }else {
    image = bill;
  }
  const currency_symbol = localStorage.getItem("currency_symbol")
    ? localStorage.getItem("currency_symbol")
    : "$";
  const trader_type = localStorage.getItem("trader_type");

  return (
    <Box className="card-box">
      <Grid container spacing={2}>
        
        <Grid item xs={3} className="card-img">
          <img src={image} />
        </Grid>
        <Grid item xs={9} className="card-header">
          <Typography className="card-value">
            {type !== "monetary" ? "" : currency_symbol}{" "}
            {type === "monetary" ? (
              title ? title.toLocaleString(undefined, {minimumFractionDigits: 2}) : 0
            ) : (
              title ? title : 0
            )}
          </Typography>
          <Typography className="card-title">
            {subtitle}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

TopCard.propTypes = {
  title: PropTypes.any,
  subtitle: PropTypes.string,
  image: PropTypes.any,
};
