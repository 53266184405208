import React from "react";
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Marker,
} from "react-google-maps";

function Map() {
  return (
    <GoogleMap defaultZoom={7} defaultCenter={{ lat: 9.076479, lng: 7.398574 }}>
      <Marker position={{ lat: 9.076479, lng: 7.398574 }} />
    </GoogleMap>
  );
}

const WapperMap = withScriptjs(withGoogleMap(Map));
export default function Maps() { 
  return (
    <div style={{ minHeight: "50px", minWidth: "500px" }}>
      {/*<WapperMap
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_KEY}&v=3.exp&libraries=geometry,drawing,places`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `400px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />*/}
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126093.8408132807!2d7.379827471333515!3d9.0242493682335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104e745f4cd62fd9%3A0x53bd17b4a20ea12b!2sAbuja%2C%20Nigeria!5e0!3m2!1sen!2sin!4v1641902737400!5m2!1sen!2sin" width="600" height="450" style={{ border:"0" }} allowfullscreen="" loading="lazy"></iframe>
    </div>
  );
}
