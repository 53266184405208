import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { Typography, CardMedia, Box } from "@material-ui/core";
import image from "../../assets/img/I.svg";
import { isMobile } from "react-device-detect";

const myStyles = {
  topHeading: {
    color: "#6B7280",
    fontSize: "16px",
    fontWeight: "normal",
    fontFamily: "Averta-Regular",
    textAlign: "center",
    marginTop: isMobile ? "0px" : "",
  },
  subHeading: {
    color: "#00B8DE",
    fontFamily: "Averta",
    fontSize: isMobile ? "35px" : "40px",
    fontWeight: "bold",
    fontStyle: "normal",
    textAlign: "center",
    padding: isMobile ? "15px 0px" : "",
  },
  millions: {
    color: "#6B7280",
    fontSize: isMobile ? "18px" : "16px",
    fontWeight: "normal",
    fontFamily: "Averta-Regular",
    textAlign: "center",
  },
  image: { width: "60%", paddingTop: "10%", paddingLeft: "2%" },
};

export default function HomePage4(props) {
  const api_url = process.env.REACT_APP_URL;
  const [data, setData] = useState(props.info);
  const [imgs, setImg] = useState(props.img);

  return (
    <div className="home-widget-supplier-list">
      <Grid container spacing={2}>
        {data &&
          data !== undefined &&
          data.length > 0 &&
          data.map((info, index) => (
            <Grid item xs={6} sm={6} md={3} xl={3} key={index}>
              <div>
                <Typography className="topHeading"> {info.name} </Typography>
                <Typography className="subHeading"> {info.value1} </Typography>
                <Typography className="millions"> {info.value2} </Typography>
              </div>
            </Grid>
          ))}
      </Grid>
      {!isMobile && imgs ? (
        <Box>
          <Typography className="home-supplier">Suppliers</Typography>

          <Grid container className="supplier-grid" justify="center">
            {imgs &&
              imgs !== undefined &&
              imgs.length > 0 &&
              imgs.map((data, index) => (
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={3}
                  xl={3}
                  className="supplier-flex"
                  display="flex"
                  key={index}>
                  <Box className="supplier-box">
                    <img
                      className="supplier-img"
                      src={`${
                        api_url + data.supplier_attachment
                      }?${Date.now()}`}
                    />
                  </Box>
                </Grid>
              ))}
          </Grid>
        </Box>
      ) : (
        ""
      )}
    </div>
  );
}
