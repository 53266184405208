import React, { useState, useEffect, useRef } from 'react';
import { Grid, Box, Typography, OutlinedInput, MenuItem, FormControl, Select, Button, Table, TableBody, TableContainer, TableCell, TableHead, TableRow, Paper, Snackbar, SnackbarContent } from "@material-ui/core";
import CustomInvoice from "views/Components/CustomInvoice";
import TableComponent from 'views/Components/tableComponent/tablecomponent';
import ThemeContainer from "views/Components/sideBar/ThemeContainer";
import { isMobile } from 'react-device-detect'
import { getAccessToken } from "../../utilities/tokenUtil";
import TextField from "@mui/material/TextField";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { Link, useHistory } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

var axios = require("axios");

export default function DashboardInvoices(props) {
    const history = useHistory();

    const trader_type   = localStorage.getItem("trader_type");
    const userId        = localStorage.getItem("userId");
    const userName      = localStorage.getItem("userName");
    const kyc_status    = localStorage.getItem("kyc_status");

    const [addInvoices, setAddInvoices]             = useState(true);
    const [startDate, setstartdate]                 = useState("");
    const [endDate, setendDate]                     = useState("");
    const [buyerName, setbuyerName]                 = useState("");
    const [invoiceNoSearch, setinvoiceNoSearch]     = useState("");
    const [searchStatus, setsearchStatus]           = useState("");
    const [InvoiceDocuments, setInvoiceDocuments]   = useState([]);
    const [invoiceList, setinvoiceList]             = useState([]);
    const [file, setfile]                           = useState();
    const [serverImage, setserverImage]             = useState("")
    const [invoiceId, setinvoiceId]                 = useState(0);
    const [buyerListing, setbuyerListing]           = useState([]);
    const [buyerId, setbuyerId]                     = useState("");
    const [docId, setdocId]                         = useState("");
    const [serverDocId, setserverDocId]             = useState("");
    const [editeddocId, setediteddocId]             = useState("");
    const [lenderId, setlenderId]                   = useState("");
    const [buyerLocationId, setbuyerLocationId]     = useState("");
    const [purchaseOrderNO, setpurchaseOrderNO]     = useState("");
    const [purchaseOrderDate, setpurchaseOrderDate] = useState("");
    const [invoiceNo, setinvoiceNo]                 = useState("");
    const [invoiceammount, setinvoiceammount]       = useState("");
    const [invoiceDate, setinvoiceDate]             = useState("");
    const [dueDate, setdueDate]                     = useState("");
    const [creditDays, setcreditDays]               = useState("");
    const [amountRequired, setamountRequired]       = useState("");
    const [logistic, setlogistic]                   = useState("");
    const [locationId, setlocationId]               = useState("");
    const [deliveryChallan, setdeliveryChallan]     = useState("");
    const [deliveryChallanDate, setdeliveryChallanDate] = useState("");
    const [goodReceivingNote, setgoodReceivingNote] = useState("");
    const [grnDate, setgrnDate]                     = useState("");
    const [grnNo, setgrnNo]                         = useState("");
    const [receivedBy, setreceivedBy]               = useState("");
    const [remark, setremark]                       = useState("");
    const [loading, setLoading]                     = useState(true);
    const [message, setMessage]                     = useState({});
    const [lenderName, setLenderName]               = useState("");
    const [currency_symbol, setCurrencySymbol]      = useState("");
    const [lenderID, setLenderID]                   = useState();
    const [open, setOpen]                           = useState(false)
    const documentRef                               = useRef([]);
    const [loader, setLoader]                       = useState(false);
    const [page_no, setPageNo]                      = useState(1);
    const [page_count, setPageCount]                = useState(1);
    const [creditOffersLength, setCreditOffersLength]       = useState(false);
    const [paymentMasterData, setPaymentMasterData]       = useState("");

    documentRef.current = [];

    if(!userId || userName === null){
        history.push('/signin');
    }
    if(kyc_status !== "Approved"){
        history.push('/kyc-page');
    }
    
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    }
    const filter = () => {
        const access_token = getAccessToken();
        let traderType = trader_type === "buyer" ? "buyer" : "supplier";
        const data = {
            user_id: parseInt(localStorage.getItem("userId")),
            access_token: access_token,
            status: searchStatus,
            invoice_no: invoiceNoSearch,
            filed_from: startDate,
            filed_to: endDate,
            buyer_name: buyerName,
            user_type: traderType,
            supplier_name: "",
            page_no: parseInt(page_no ? page_no : 1)
        };
        axios
            .post(`${process.env.REACT_APP_URL}/api/v1/invoice/list/search`, data, {
                headers: {
                    "Content-Type": "text/plain",
                },
            })
            .then((response) => {
                if (response.data.success) {
                    setinvoiceList(response.data.invoice_list)
                    let total_records       = response.data.total_rec;
                    let records_per_page    = response.data.records_per_page;
                    let page_count          = Math.ceil(total_records / records_per_page);
                    setPageNo(response.data.current_page)
                    if(page_count < 1){
                        setPageCount(1)
                    }else {
                        setPageCount(page_count)
                    }
                } else {
                    setOpen(true); 
                    setMessage({ type: "error", content: response.data.message })
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const invoiceMaster = () => {
        const access_token = getAccessToken();
        let traderType = trader_type === "buyer" ? "supplier" : "buyer";
        const data = {
            access_token: access_token,
            user_id: parseInt(localStorage.getItem("userId")),
            user_type: traderType
        };
        axios
            .post(`${process.env.REACT_APP_URL}/api/v1/invoice/master`, data, {
                headers: {
                    "Content-Type": "text/plain",
                },
            })
            .then((response) => {

                setdocId(response.data.document);
                setbuyerListing(response.data.buyer);
                setlenderId(response.data.lender_id);
                setLenderName(response.data.lender)
                setCurrencySymbol(response.data.currency_symbol)
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const getInvoiceList = (value=1) => {
        setLoader(false)
        const access_token = getAccessToken();
        let traderType = trader_type === "buyer" ? "buyer" : "supplier";
        const data = {
            access_token: access_token,
            user_id: parseInt(localStorage.getItem("userId")),
            user_type: traderType,
            page_no: parseInt(value)
        };
        axios
            .post(`${process.env.REACT_APP_URL}/api/v1/invoice/list`, data, {
                headers: {
                    "Content-Type": "text/plain",
                },
            })
            .then((response) => {
                if (response.data.success == true) {
                    setinvoiceList(response.data.invoice_list)

                    let total_records       = response.data.total_rec;
                    let records_per_page    = response.data.records_per_page;
                    let page_count          = Math.ceil(total_records / records_per_page);
                    setPageNo(response.data.current_page)
                    if(page_count < 1){
                        setPageCount(1)
                    }else {
                        setPageCount(page_count)
                    }
                    setLoader(true)
                }else{
                    setLoader(true)
                }

            })
            .catch((error) => {
                console.log(error);
                setLoader(true)
            });
    };

    const clearSearch=()=>{
        setstartdate("");
        setendDate("")
        setbuyerName("")
        setinvoiceNoSearch("")
        setsearchStatus("")
        getInvoiceList();
    }

    const viewInvoiceDetail = (item) => {
        history.push("/ViewInvoiceList", item);
    }
    const addNewInovice = () => {
        if(!creditOffersLength /*|| !lenderId && !lenderName*/){
            setLoading(false);
            setOpen(true);
            setMessage({ type: "error", content: "No active offer found, please apply/track credit request." });
            setTimeout(() => {
              history.push('/credit')
            }, 3000)
        }else{
            history.push("/addinvoice");
        }
    }

    const activeCreditOffers = () => {
        const access_token = getAccessToken();
        const data = {
            "access_token": access_token,
            "user_id": parseInt(localStorage.getItem("userId")),
        };

        axios
            .post(`${process.env.REACT_APP_URL}/api/v1/supplier/credit_offer/list`, data, {
                headers: {
                    "Content-Type": "text/plain",
                },
            })
            .then((response) => {
                if (response.data.success) {
                    setLoader(true);
                    if (response?.data?.data?.length > 0) {
                        setCreditOffersLength(true);
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handlePageClick = (event, value) => {        
        getInvoiceList(value);
    };
    const paymentMaster = () => {
        axios
            .get(`${process.env.REACT_APP_URL}/api/v1/payment/master`, {
                headers: {
                    "Content-Type": "text/plain",
                },
            })
            .then((response) => {
                if(response.status === 200){
                    setPaymentMasterData(response.data);                    
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => { 
        window.scrollTo(0, 0);
        invoiceMaster();
        getInvoiceList();
        activeCreditOffers();
        paymentMaster();
        // Include payment script library
        const script = document.createElement('script');
        script.src = process.env.REACT_APP_PAYMENT_CHECKOUT_URL;
        script.async = true;
        document.body.appendChild(script);
    }, [])
  return (
    
      
      <ThemeContainer type="private" value={1}>
          <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={open} autoHideDuration={3000} onClose={handleClose}>
              <SnackbarContent className={message.type === "error" ? "snack-error" : message.type === "success" ? "snack-success" : "snack-teal" } message={message.content} />
          </Snackbar>
        {loader !== true ? (
          <Grid container spacing={3} className="bootstrap-loader">            
              <Spinner animation="grow" variant="spinner1" />
              <Spinner animation="grow" variant="spinner2" />
              <Spinner animation="grow" variant="spinner3" />            
          </Grid>
        ) : (
        <>
        
          <Box className="scf-box">
            <Box className="scf-subbox">
              
              <Box>
                    <Grid container>
                        <Grid item xs={8} sm={8} md={10} xl={10}>
                            <Typography className="listing-main-title">Manage Invoices </Typography>
                        </Grid>
                        {trader_type === "supplier" &&
                        <Grid item xs={4} sm={4} md={2} xl={2}>
                            <Button className="upload" onClick={addNewInovice}>Add New Invoice</Button>
                        </Grid>
                        }
                    </Grid>
                  <Typography className="list-sub-title"> Invoice List</Typography>
              </Box>
              <Box>
                  <TableContainer component={Paper}>
                      <Table className="table-top" aria-label="simple table">
                          <TableHead>
                              <TableRow className="table-row">
                                  <TableCell align="left" className="table-header" >S. No. </TableCell>
                                  <TableCell align="left" className="table-header" >Invoice No</TableCell>
                                  <TableCell align="left" className="table-header"  >Invoice Date</TableCell>
                                  <TableCell align="left" className="table-header"  >Due Date</TableCell>
                                  <TableCell align="left" className="table-header" style={{ width: "20%" }}>Buyer Name</TableCell>
                                  <TableCell align="left" className="table-header" >Inv. Amount ({currency_symbol})</TableCell>
                                  <TableCell align="left" className="table-header" >Appr. Amount ({currency_symbol})</TableCell>
                                  <TableCell align="left" className="table-header"  >Logistics</TableCell>
                                  <TableCell align="left" className="table-header"  >Status</TableCell>
                                  <TableCell className="table-header" >Action</TableCell>
                              </TableRow>
                          </TableHead>
                          <TableBody >

                              {props.invoiceList.length > 0 && props.invoiceList !== undefined ? (
                                  props.invoiceList.map((row, invoice_key) => (
                                  <TableRow className="table-row" key={invoice_key} >
                                      <TableCell component="th" scope="row" align="left"> {invoice_key + 1} </TableCell>
                                      <TableCell align="left" onClick={() => viewInvoiceDetail(row)}>{row.invoice_no}</TableCell>
                                      <TableCell align="left" onClick={() => viewInvoiceDetail(row)}>{row.invoice_date}</TableCell>
                                      <TableCell align="left" onClick={() => viewInvoiceDetail(row)}>{row.due_date}</TableCell>
                                      <TableCell align="left" onClick={() => viewInvoiceDetail(row)}>{row.buyer_name}</TableCell>
                                      <TableCell align="left" onClick={() => viewInvoiceDetail(row)}>{row.invoice_ammount.toLocaleString(undefined, {minimumFractionDigits: 2})}</TableCell>
                                      <TableCell align="left" onClick={() => viewInvoiceDetail(row)}>{row.approved_amount.toLocaleString(undefined, {minimumFractionDigits: 2})}</TableCell>
                                      <TableCell align="left" onClick={() => viewInvoiceDetail(row)}>{row.logistics}</TableCell>
                                      <TableCell align="left" onClick={() => viewInvoiceDetail(row)} className={row.status.toLowerCase().replace(/\s/g, '-')}>{row.status}</TableCell>
                                      <TableCell className="table-header1" >{<CustomInvoice value={row} paymentMasterData={paymentMasterData}/>}</TableCell>
                                  </TableRow>
                                  ))
                              ): (
                                <TableRow >
                                    <TableCell colSpan={10} className="nodata">
                                        No Data to Display
                                    </TableCell>
                                </TableRow>
                              )}
                          </TableBody>
                      </Table>
                  </TableContainer>
              </Box>

                {props.invoiceList.length > 0 && props.invoiceList !== undefined ? (
                <Grid container spacing={2} className="paginate">
                    <Grid item xs={6} sm={6} md={4} xl={4}>
                      <Box>
                        <Stack spacing={2}>
                          <Pagination count={page_count} page={page_no} variant="outlined" shape="rounded" onChange={handlePageClick} />
                        </Stack>
                      </Box>
                    </Grid>
                </Grid>
                ):null}
            </Box>
          </Box>
        </>
      )}
      </ThemeContainer>
    
  );
}
