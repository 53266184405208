import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom"
import { Box, Tab, Tabs, withStyles, Typography } from "@material-ui/core"
import CompanyDetail from "../../assets/img/CompanyDetails.svg"
import MobileCompany from "../../assets/img/mobileCompany.svg"
import authorizedImage from "../../assets/img/authorized.svg"
import MobileSignatory from "../../assets/img/mobileSignatory.svg"
import documents from "../../assets/img/documents.svg"
import MobileDocuments from "../../assets/img/mobileDocuments.svg"
import buyers from "../../assets/img/Buyers.svg"
import MobileBuyer from "../../assets/img/mobileBuyer.svg"
import invoice from "../../assets/img/invoice.svg"
import MobileInvoice from "../../assets/img/mobileInvoice.svg"
import { PropTypes } from 'prop-types';
import UpdateInfo from './UpdateComponents/UpdateCompany/UpdateInfo';
import Authorized from './UpdateComponents/authorized/Authorized';
import Documents from './UpdateComponents/document/Documents';
import Buyer from './UpdateComponents/buyer/Buyer';
import Invoice from './UpdateComponents/invoice/Invoice';
import { isMobile } from 'react-device-detect';
import ThemeContainer from 'views/Components/sideBar/ThemeContainer';

const styles = () => ({
    menuTab: {
        "& div div": {
            justifyContent: "space-between",
            backgroundColor: "#EBF8FE"
        }
    },
    indicator: {
        backgroundColor: '#6EB4E0',
    },
});

function MyCompany(props) {

    const trader_type   = localStorage.getItem("trader_type");
    const userId        = localStorage.getItem("userId");

    const history = useHistory()
    const [value, setValue] = React.useState(0);
    const { classes } = props;

    if(!userId){
        history.push('/signin');
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
        localStorage.setItem("mycompanyStage", newValue);
    };
    useEffect(() => {
        document.title = 'Interswitch - Update Company Information';
        if (localStorage.getItem("mycompanyStage"))
            setValue(parseInt(localStorage.getItem("mycompanyStage")));
    }, [])

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    return (
        <ThemeContainer type="private" value={5}>
            <Box className="scf-box">
                <Box className="scf-subbox">
                    <Box>

                        <UpdateInfo />

                    </Box>
                </Box>
            </Box>
        </ThemeContainer>
    );
}

MyCompany.propTypes = {
    classes: PropTypes.object
}


export default withStyles(styles)(MyCompany);
