import React, { useEffect, useState } from "react";
import { Box, Paper, Snackbar, SnackbarContent, Grid, Modal, OutlinedInput, TableContainer } from "@material-ui/core";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "./newBuyerInvoice.css";
import InvoiceDollar from "../../../../assets/img/InvoiceDollar.svg";
import InvoiceView from "../../../../assets/img/InvoiceView.svg";
import InvoicePdf from "../../../../assets/img/InvoicePdf.svg";
import InvoiceDownload from "../../../../assets/img/InvoiceDownload.svg";
import InvoiceActionDownload from "../../../../assets/img/InvoiceActionDownload.svg";
import InvoiceActionView from "../../../../assets/img/InvoiceActionView.svg";
import ThemeContainer from "./../../../Components/sideBar/ThemeContainer";
import { getAccessToken } from "../../../../utilities/tokenUtil";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import MuiTableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import axios from "axios";
import { useHistory } from "react-router-dom";

const TableCell = withStyles({
  root: {
    borderBottom: "none",
  },
})(MuiTableCell);

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'absolute',
        width: 450,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

function getModalStyle() {
    return {
        top: `65%`,
        left: `0`,
        right: `0`,
        margin: `0 auto`,
        transform: `translateY(-75%)`,
    };
};

export default function viewModal(props) {
    let data              = props.info;
    let invoice_id        = props.invoice_id;
    const downloading     = props.download;
    const modelClasses    = useStyles();
    const [modalStyle]    = React.useState(getModalStyle);
    const [document_url, setDocumentImage]    = useState("");
    const [image_extension, setImageExtension]    = useState("");
    const [open, setOpen]               = useState(false);
    const [loading, setLoading]         = useState(true);
    const [message, setMessage]         = useState({})
    const [openModel, setOpenModel]     = useState(false);
  
  // Download uploaded files
  const downloadDocument = (event, document_id) => {
        event.preventDefault();        
        const access_token = getAccessToken();
      
        const data = {
              user_id: parseInt(localStorage.getItem("userId")),
              access_token: access_token,
              invoice_id: parseInt(invoice_id),
              document_id: parseInt(document_id)
        };

        axios
        .post(`${process.env.REACT_APP_URL}/api/v1/invoice/document/download`, data, {
          headers: {
              "Content-Type": "text/plain",
          },
        })
        .then((response) => {        
            if(response.data.success === true){
                const api_url = process.env.REACT_APP_URL
                let url = `${api_url+response.data.document_url}?${Date.now()}`;
                window.open( url, '_blank' );
            }else{
                setLoading(false);
                setOpen(true);
                setMessage({ type: "error", content: "Something went wrong." });
            }          
        })
        .catch((error) => {
            console.log(error);
        });
    };

    const modelOpen = (event, document_url, image_extension) => {
        event.preventDefault();   
        let url = process.env.REACT_APP_URL+document_url;
        setDocumentImage(url)
        setImageExtension(image_extension)
        setOpenModel(true);
    };
    const modelClose = () => {
        setDocumentImage("")
        setImageExtension("")
        setOpenModel(false);
    };
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };
  return (
    <>
    <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={open} autoHideDuration={3000} onClose={handleClose} >
        <SnackbarContent className={message.type === "error" ? "snack-error" : message.type === "success" ? "snack-success" : "snack-teal" } message={message.content} />
    </Snackbar>
    {data &&
        data.length > 0 &&
        data.map((item, n) => (
        <>
            <Row key={n}>
                <Col className="heading">{item.doc_name}</Col>
            </Row>
            <Paper variant="outlined" className="rounded">
                <div className="paper2">
                  <TableContainer component={Paper}>
                    <Table className="table-top" aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell align="left">Uploaded By</TableCell>
                          <TableCell align="left">Uploaded On</TableCell>
                          <TableCell align="left">Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {item.doc_list &&
                          item.doc_list.length > 0 &&
                          item.doc_list.map((info, o) => (
                            <>
                              <TableRow key={o}>
                                <TableCell align="left">{info.document_name}</TableCell>
                                <TableCell align="left"> {info.upload_by} </TableCell>
                                <TableCell align="left"> {info.upload_date} </TableCell>
                                <TableCell align="left">
                                  <button className="downloaddoc" onClick={(e) => downloadDocument(e, info.document_id)}>
                                    <img src={InvoiceActionDownload} alt="" className="invoiceImg2"/>
                                  </button>
                                  <button className="downloaddoc" onClick={(e) => modelOpen(e, info.document_url, info.image_extension)}>
                                    <img src={InvoiceActionView} alt="" className="invoiceImg2"/>
                                  </button>
                                </TableCell>
                              </TableRow>
                            </>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  
                </div>
            </Paper>
        </>
    ))}

        <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description"
              open={openModel}
              onClose={modelClose}
          >
            <div style={modalStyle} className={modelClasses.paper}>
                <h4 className="modal-title">Uploaded Document</h4>
              
                <Grid item xs={12} sm={12} md={12} xl={12} className="modal-image">
                    <Box style={{ paddingBottom: "5%", textAlign: "center" }}>
                      {image_extension === "application/pdf" ? (
                        <embed src={`${document_url}?${Date.now()}`} width="300" height="300" type={image_extension} />                                                                              
                      ) : (
                        <img src={`${document_url}?${Date.now()}`} alt=""/>
                      )}                        
                    </Box>
                </Grid>              
            </div>
        </Modal>
    </>
  );
};