import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Box,
  Typography,
  OutlinedInput,
  MenuItem,
  FormControl,
  Select,
  Button,
  Snackbar,
  SnackbarContent,
} from "@material-ui/core";
import { isMobile } from "react-device-detect";
import { getAccessToken } from "../../../../utilities/tokenUtil";
import TextField from "@mui/material/TextField";
import EditOutlinedIcon from "@material-ui/icons/BorderColor";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import image from "../../../../assets/img/forgot-password.png";
import ThemeContainer from "views/Components/sideBar/ThemeContainer";
import { useHistory } from "react-router-dom";
import { Row, Col, Spinner } from "react-bootstrap";
import Stack from "@mui/material/Stack";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import WebRedirectPaymentMethod from "views/Components/webRedirectPaymentMethod";

var axios = require("axios");

export default function EditInvoice(props) {
  const trader_type = localStorage.getItem("trader_type");
  const userId = localStorage.getItem("userId");
  const userName = localStorage.getItem("userName");
  const kyc_status = localStorage.getItem("kyc_status");
  const user_role = localStorage.getItem("user_role");

  const history = useHistory();
  const dataList = (props.location && props.location.state) || {};
  const api_url = process.env.REACT_APP_URL;
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState({});
  const [invoiceId, SetinvoiceId] = useState(dataList.invoice_id);

  const [buyerListing, setbuyerListing] = useState([]);
  const [buyerId, setbuyerId] = useState("");
  const [docId, setdocId] = useState([]);
  const [serverDocId, setserverDocId] = useState("");
  const [documents, setDocuments] = useState([]);
  const [creditOfferListing, setCreditOfferListing] = useState([]);
  const [creditOfferId, setCreditOfferId] = useState("");

  const [lenderId, setlenderId] = useState("");
  const [buyerLocationId, setbuyerLocationId] = useState("");
  const [purchaseOrderNO, setpurchaseOrderNO] = useState("");
  const [purchaseOrderDate, setpurchaseOrderDate] = useState("");

  const [invoiceNo, setinvoiceNo] = useState("");
  const [invoiceammount, setinvoiceammount] = useState("");
  const [invoiceDate, setinvoiceDate] = useState("");
  const [dueDate, setdueDate] = useState("");
  const [creditDays, setcreditDays] = useState("");
  const [amountRequired, setamountRequired] = useState("");
  const [logistic, setlogistic] = useState("");

  const [locationId, setlocationId] = useState("");
  const [deliveryChallan, setdeliveryChallan] = useState("");
  const [deliveryChallanDate, setdeliveryChallanDate] = useState("");
  const [goodReceivingNote, setgoodReceivingNote] = useState("");
  const [grnDate, setgrnDate] = useState("");
  const [grnNo, setgrnNo] = useState("");
  const [receivedBy, setreceivedBy] = useState("");
  const [remark, setremark] = useState("");
  const [InvoiceDocuments, setInvoiceDocuments] = useState([]);
  const [lenderID, setLenderID] = useState();
  const [lenderName, setLenderName] = useState("");
  const [loading, setLoading] = useState(true);
  const [loader, setLoader] = useState(false);
  const [currency_symbol, setCurrencySymbol] = useState("");
  const [paymentMasterData, setPaymentMasterData] = useState("");
  const [openPaymentModel, setOpenPaymentModel] = useState(false);

  if (!userId || userName === null) {
    history.push("/signin");
  }
  if (kyc_status !== "Approved") {
    history.push("/kyc-page");
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const invoiceDetail = () => {
    const access_token = getAccessToken();
    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: access_token,
      invoice_id: invoiceId,
    };

    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/invoice/detail`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        if (response) {
          setInvoiceDocuments(response.data.documents);
          setlocationId(response.data.location_id);
          setbuyerLocationId(response.data.buyer_location_id);
          setdueDate(response.data.due_date);
          setpurchaseOrderNO(response.data.purchase_order_no);
          setinvoiceNo(response.data.invoice_no);
          setinvoiceammount(response.data.invoice_amount);
          setpurchaseOrderDate(response.data.purchase_order_date);
          setcreditDays(response.data.credit_days);
          setlogistic(response.data.logistics);
          setdeliveryChallanDate(response.data.delivery_challan_date);
          setreceivedBy(response.data.received_by);
          setremark(response.data.remark);
          setLenderID(response.data.lender_id);
          setgrnNo(response.data.grn_no);
          setgrnDate(response.data.grn_date);
          setamountRequired(response.data.amount_required);
          setinvoiceDate(response.data.invoice_date);
          setdeliveryChallan(response.data.delivery_challan);
          setgoodReceivingNote(response.data.good_receiving_note);
          setbuyerId(response.data.buyer_id);
          setCreditOfferId(response.data.credit_offer_id);
          setLoader(true);
        } else {
          setLoader(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const createInvoiceApi = () => {
    var buyer_Id;
    buyerListing.map((item) => {
      if (item.buyer_id[1] == buyerId) {
        buyer_Id = item.buyer_id[0];
      }
    });

    var a = new Set([]);
    docId.map((item) => {
      if (item.is_required === true) {
        InvoiceDocuments.map((x) => {
          x.doc_list.map((y) => {
            if (y.document_type_id === item.id) {
              a.add(y.document_type_id);
            }
          });
        });
      }
    });

    let array = Array.from(a);

    let req = [];
    docId.map((item) => {
      if (item.is_required === true) {
        req.push(item.id);
      }
    });

    if (
      Array.isArray(req) &&
      Array.isArray(array) &&
      req.length === array.length &&
      req.every((val, index) => val === array[index])
    ) {
    } else {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "Please upload all mandatory documents.",
      });
      return;
    }

    if (isNaN(amountRequired) || isNaN(invoiceammount)) {
      setOpen(true);
      setMessage({
        type: "error",
        content: "Invoice amount & amount required field can only be number.",
      });
      return;
    }
    if (parseInt(amountRequired) >= parseInt(invoiceammount)) {
      setOpen(true);
      setMessage({
        type: "error",
        content: "Amount require should be less than invoice amount.",
      });
      return;
    }

    const access_token = getAccessToken();
    setLoader(false);
    const data = {
      id: invoiceId,
      access_token: access_token,
      user_id: parseInt(localStorage.getItem("userId")),
      buyer_id: buyerId,
      lender_id: lenderID,
      credit_offer_id: creditOfferId,
      buyer_location_id: buyerLocationId.trim(),
      purchase_order_no: purchaseOrderNO.trim(),
      purchase_order_date:
        purchaseOrderDate !== "False" ? purchaseOrderDate : "",
      invoice_no: invoiceNo.trim(),
      invoice_date: invoiceDate !== "False" ? invoiceDate : "",
      invoice_amount: invoiceammount,
      due_date: dueDate !== "False" ? dueDate : "",
      credit_days: creditDays,
      amount_required: amountRequired,
      location_id: locationId,
      delivery_challan: deliveryChallan,
      delivery_challan_date:
        deliveryChallanDate !== "False" ? deliveryChallanDate : "",
      good_receiving_note: goodReceivingNote,
      grn_date: grnDate !== "False" ? grnDate : "",
      grn_no: grnNo,
      received_by: receivedBy,
      remark: remark,
      logistics: logistic,
    };
    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/invoice/create`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        if (response.data.success === true) {
          SetinvoiceId(response.data.invoice_id);
          setLoading(false);
          setOpen(true);
          setMessage({ type: "success", content: response.data.message });
          setTimeout(() => {
            history.push("/invoice");
          }, 3000);
          setLoader(true);
        } else {
          setLoading(false);
          setOpen(true);
          setMessage({ type: "error", content: response.data.message });
          setLoader(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(true);
      });
  };

  const submitForm = () => {
    if (trader_type === "supplier") {
      if (
        buyerId === "" ||
        creditOfferId == "" ||
        buyerLocationId.trim() === "" ||
        purchaseOrderNO.trim() === "" ||
        invoiceNo.trim() === "" ||
        invoiceammount === "" ||
        invoiceDate === "" ||
        dueDate === "" ||
        purchaseOrderDate === "" ||
        creditDays === "" ||
        amountRequired === ""
      ) {
        setOpen(true);
        setMessage({ type: "error", content: "All fields are mandatory." });
        return;
      }
      if (isNaN(amountRequired) || isNaN(invoiceammount)) {
        setOpen(true);
        setMessage({
          type: "error",
          content: "Invoice amount & amount required field can only be number.",
        });
        return;
      }
      if (isNaN(creditDays)) {
        setOpen(true);
        setMessage({
          type: "error",
          content: "Credit days field can only be number.",
        });
        return;
      }
      if (parseInt(amountRequired) >= parseInt(invoiceammount)) {
        setOpen(true);
        setMessage({
          type: "error",
          content: "Amount require should be less than invoice amount.",
        });
        return;
      }
    }

    if (trader_type === "buyer") {
      if (
        logistic === "" ||
        locationId === "" ||
        deliveryChallan === "" ||
        deliveryChallanDate === "" ||
        goodReceivingNote === "" ||
        grnNo === "" ||
        grnDate === "" ||
        receivedBy === "" ||
        remark === ""
      ) {
        setOpen(true);
        setMessage({ type: "error", content: "All fields are mandatory." });
        return;
      }
    }

    var buyer_Id;
    buyerListing.map((item) => {
      if (item.buyer_id[1] == buyerId) {
        buyer_Id = item.buyer_id[0];
      }
    });

    var a = new Set([]);
    docId.map((item) => {
      if (item.is_required === true) {
        InvoiceDocuments.map((x) => {
          x.doc_list.map((y) => {
            if (y.document_type_id === item.id) {
              a.add(y.document_type_id);
            }
          });
        });
      }
    });

    let array = Array.from(a);

    let req = [];
    docId.map((item) => {
      if (item.is_required === true) {
        req.push(item.id);
      }
    });

    if (
      Array.isArray(req) &&
      Array.isArray(array) &&
      req.length === array.length &&
      req.every((val, index) => val === array[index])
    ) {
      if (
        paymentMasterData.payment_fee_needed &&
        paymentMasterData.pay_by === "supplier"
      ) {
        setOpenPaymentModel(true);
        localStorage.setItem("invoice_id", invoiceId);
        /*const paymentRequest = {
                    merchant_code: paymentMasterData.merchant_code,          
                    pay_item_id: paymentMasterData.pay_item_id,
                    txn_ref: purchaseOrderNO+'-'+invoiceNo,
                    amount: paymentMasterData.amount, 
                    currency: paymentMasterData.currency,
                    onComplete: paymentCallback,
                    site_redirect_url: process.env.REACT_APP_SITE_REDIRECT_URL,
                    mode: paymentMasterData.mode
                };
                window.webpayCheckout(paymentRequest);*/
      } else {
        invoiceUpdateStatus(invoiceId);
      }
    } else {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "Please upload all mandatory documents.",
      });
      return;
    }
  };
  const deleteUpload = (event, document_id) => {
    const access_token = getAccessToken();
    setLoader(false);
    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: access_token,
      invoice_id: invoiceId,
      document_id: document_id,
    };
    const config = {
      method: "post",
      url: process.env.REACT_APP_URL + "/api/v1/invoice/document/delete ",
      headers: {
        "Content-Type": "text/plain",
      },
      data: data,
    };
    axios(config)
      .then((response) => {
        if (response && response.data) {
          if (response.data.success === true) {
            setMessage(response.data.message);
            return invoiceDetail();
          }
        } else {
          setLoader(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const function_Second = (event, item) => {
    let fileext = event.target.files[0];
    if (fileext.name.match(/\.(jpg|jpeg|png|pdf|JPG|JPEG|PNG|PDF)$/)) {
      const access_token = getAccessToken();
      const reader = new FileReader();
      reader.addEventListener("load", () => {});
      // setfile(event.target.files[0]);
      reader.readAsDataURL(event.target.files[0]);
      setLoader(false);
      const data11 = {
        user_id: parseInt(localStorage.getItem("userId")),
        access_token: access_token,
        document_type_id: item.id,
        invoice_id: invoiceId,
      };
      axios
        .post(
          `${process.env.REACT_APP_URL}/api/v1/invoice/document/id`,
          data11,
          {
            headers: {
              "Content-Type": "text/plain",
            },
          }
        )
        .then((response) => {
          if (response.data.success == true) {
            setserverDocId(response.data.document_id);
            const data0 = {
              user_id: parseInt(localStorage.getItem("userId")),
              document_type_id: item.id,
              access_token: access_token,
              invoice_id: invoiceId,
              document_name: item.name,
              Document_id: serverDocId,
              document_data: event.target.files[0],
            };

            let data1 = new FormData();
            data1.append("user_id", parseInt(localStorage.getItem("userId")));
            data1.append("access_token", access_token);
            data1.append("document_id", response.data.document_id);
            data1.append("document_type_id", item.id);
            data1.append("document_name", item.name);
            data1.append("document_data", event.target.files[0]);
            data1.append("invoice_id", invoiceId);
            axios
              .post(
                `${process.env.REACT_APP_URL}/api/v1/invoice/document/upload`,
                data1,
                {
                  headers: {
                    "Content-Type": "text/plain",
                  },
                }
              )
              .then((response) => {
                if (response.data.success == true) {
                  invoiceDetail();
                } else {
                  setLoader(true);
                }
              })
              .catch((error) => {});
          } else {
            setLoader(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "Only jpg, jpeg, PNG, PDF pdf files are allowed!",
      });
    }
  };

  const invoiceMaster = () => {
    const access_token = getAccessToken();
    let traderType = trader_type === "buyer" ? "supplier" : "buyer";
    const data = {
      access_token: access_token,
      user_id: parseInt(localStorage.getItem("userId")),
      user_type: traderType,
    };

    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/invoice/master`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        setLenderName(response.data.lender);
        setLenderID(response.data.lender_id);
        setdocId(response.data.document);
        setbuyerListing(response.data.buyer);
        setCurrencySymbol(response.data.currency_symbol);
        for (var i = 0; i <= response.data.buyer.length; i++) {
          if (response.data.buyer[i].buyer_id[1] == dataList.buyer_name) {
            setbuyerId(response.data.buyer[i].buyer_id[1]);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getInvoiceList = () => {
    const access_token = getAccessToken();
    let traderType = trader_type === "buyer" ? "supplier" : "buyer";
    const data = {
      access_token: access_token,
      user_id: parseInt(localStorage.getItem("userId")),
      user_type: traderType,
      page_no: 1,
    };

    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/invoice/list`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        if (response.data.success == true) {
          //setinvoiceList(response.data.invoice_list)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const editUpload = (event, item, name) => {
    let fileext = event.target.files[0];
    if (fileext.name.match(/\.(jpg|jpeg|png|pdf|JPG|JPEG|PNG|PDF)$/)) {
      const access_token = getAccessToken();
      const reader = new FileReader();
      setLoader(false);
      reader.readAsDataURL(event.target.files[0]);

      let data1 = new FormData();
      data1.append("user_id", parseInt(localStorage.getItem("userId")));
      data1.append("access_token", access_token);
      data1.append("document_id", item.document_id);
      data1.append("document_type_id", item.document_type_id);
      data1.append("document_name", name);
      data1.append("document_data", event.target.files[0]);
      data1.append("invoice_id", invoiceId);
      axios
        .post(
          `${process.env.REACT_APP_URL}/api/v1/invoice/document/upload`,
          data1,
          {
            headers: {
              "Content-Type": "text/plain",
            },
          }
        )
        .then((response) => {
          if (response.data.success == true) {
            invoiceDetail();
          } else {
            setLoader(true);
          }
        })
        .catch((error) => {});
    } else {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "Only jpg, jpeg, PNG, PDF pdf files are allowed!",
      });
    }
  };

  const invoiceDateChange = (newValue) => {
    var date = new Date(newValue);
    var finaldate =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    setinvoiceDate(finaldate);
  };
  const dueDateChange = (newValue) => {
    var date = new Date(newValue);
    var finaldate =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    setdueDate(finaldate);
  };
  const purchaseOrderDateChange = (newValue) => {
    var date = new Date(newValue);
    var finaldate =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    setpurchaseOrderDate(finaldate);
  };

  const getCreditOfferFromBuyer = (e) => {
    if (e.target.value) {
      setbuyerId(e.target.value);
      const access_token = getAccessToken();
      const data = {
        access_token: access_token,
        user_id: parseInt(localStorage.getItem("userId")),
        buyer_id: parseInt(e.target.value),
      };
      axios
        .post(`${process.env.REACT_APP_URL}/api/v1/credit_offer/master`, data, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then((response) => {
          if (response.data.success) {
            setCreditOfferListing(response.data.data);
            setLoading(false);
          } else {
            setLoader(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const getCreditOffer = (e) => {
    if (e.target.value) {
      var lender_name;
      var lender_id;
      creditOfferListing.map((item) => {
        if (item.credit_offer_id == e.target.value) {
          lender_name = item.lender_name;
          lender_id = item.lender_id;
        }
      });
      setCreditOfferId(e.target.value);
      setLenderName(lender_name);
      setlenderId(lender_id);
    }
  };

  const getCreditOfferList = (buyerId) => {
    if (buyerId) {
      const access_token = getAccessToken();
      const data = {
        access_token: access_token,
        user_id: parseInt(localStorage.getItem("userId")),
        buyer_id: parseInt(buyerId),
      };
      axios
        .post(`${process.env.REACT_APP_URL}/api/v1/credit_offer/master`, data, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then((response) => {
          if (response.data.success) {
            setCreditOfferListing(response.data.data);
            setLoading(false);
          } else {
            setLoader(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  //callback function that gets triggered on payment success or failure
  const paymentCallback = (response) => {
    if (response.amount !== undefined && response.payRef !== undefined) {
      invoiceUpdateStatus(invoiceId);
    }
  };

  const invoiceUpdateStatus = (invoiceId) => {
    const access_token = getAccessToken();
    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: access_token,
      invoice_id: parseInt(invoiceId),
      status: "waiting_for_buyer_approval",
      comment: "",
    };

    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/invoice/update/status`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setLoading(false);
          setOpen(true);
          setMessage({
            type: "success",
            content: "Invoice updated & send for buyer approval successfully.",
          });
          setTimeout(() => {
            history.push("/invoice");
          }, 3000);
        } else {
          setLoading(false);
          setOpen(true);
          setLoader(true);
          setMessage({ type: "error", content: response.data.message });
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(true);
      });
  };

  const paymentMaster = () => {
    axios
      .get(`${process.env.REACT_APP_URL}/api/v1/payment/master`, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        if (response.status) {
          setPaymentMasterData(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const modelPaymentClose = () => {
    setOpenPaymentModel(false);
    localStorage.removeItem("invoice_id");
  };

  useEffect(() => {
    document.title = "Interswitch - Update Invoice";
    window.scrollTo(0, 0);
    invoiceDetail();
    invoiceMaster();
    getInvoiceList();
    paymentMaster();
    // Include payment script library
    const script = document.createElement("script");
    script.src = process.env.REACT_APP_PAYMENT_INLINE_CHECKOUT_URL;
    script.async = true;
    document.body.appendChild(script);
  }, []);
  useEffect(() => {
    getCreditOfferList(buyerId);
  }, [buyerId]);

  return (
    <>
      <ThemeContainer type="private" value={5}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}>
          <SnackbarContent
            className={
              message.type === "error"
                ? "snack-error"
                : message.type === "success"
                ? "snack-success"
                : "snack-teal"
            }
            message={message.content}
          />
        </Snackbar>
        {loader !== true ? (
          <Grid container spacing={3} className="bootstrap-loader">
            <Spinner animation="grow" variant="spinner1" />
            <Spinner animation="grow" variant="spinner2" />
            <Spinner animation="grow" variant="spinner3" />
          </Grid>
        ) : (
          <>
            <Box className="scf-box">
              <Box className="scf-subbox">
                <Grid container>
                  <Grid item xs={12} sm={12} md={12} xl={12}>
                    <Typography className="company-title11">
                      Edit Invoice
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12} xl={12}>
                    <Row className="mb-5 mt-3">
                      <Col>
                        <span className="ao-header-box">
                          Lender - {lenderName}
                        </span>
                      </Col>
                    </Row>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item sm={12} xs={12} md={4} lg={4} xl={4}>
                    <Box className="input-box">
                      <span className="company-title3">
                        Buyer Name<span className="required">*</span>
                      </span>
                      <FormControl fullWidth>
                        <Select
                          displayEmpty
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          readOnly={trader_type === "buyer" ? true : false}
                          value={buyerId}
                          //onChange={(e) => setbuyerId(e.target.value)}
                          onChange={(e) => getCreditOfferFromBuyer(e)}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}>
                          <MenuItem value="" disabled>
                            Select from options
                          </MenuItem>
                          {buyerListing != undefined &&
                            buyerListing.map((item, index) => {
                              return (
                                <MenuItem value={item.buyer_id[0]} key={index}>
                                  {item.buyer_id[1]} ({item.taxation_no})
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} xl={4}>
                    <Box className="input-box">
                      <span className="company-title3">
                        Credit Offer<span className="required">*</span>
                      </span>
                      <FormControl fullWidth>
                        <Select
                          displayEmpty
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={creditOfferId}
                          readOnly={trader_type === "buyer" ? true : false}
                          onChange={(e) => getCreditOffer(e)}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}>
                          <MenuItem value="" disabled>
                            Select from options
                          </MenuItem>
                          {creditOfferListing != undefined &&
                            creditOfferListing.map((item, index1) => {
                              return (
                                <MenuItem
                                  value={item.credit_offer_id}
                                  key={index1}>
                                  {item.credit_offer_name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item sm={12} xs={12} md={4} lg={4} xl={4}>
                    <Box className="input-box">
                      <span className="company-title3">
                        Buyer Location<span className="required">*</span>
                      </span>
                      <OutlinedInput
                        className="company-title5"
                        fullWidth
                        placeholder="e.g. Buyer Location"
                        value={buyerLocationId}
                        readOnly={trader_type === "buyer" ? true : false}
                        onChange={(e) => setbuyerLocationId(e.target.value)}
                      />
                    </Box>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item sm={12} xs={12} md={4} lg={4} xl={4}>
                    <Box className="input-box">
                      <span className="company-title3">
                        Purchase Order Number<span className="required">*</span>
                      </span>
                      <OutlinedInput
                        className="company-title5"
                        fullWidth
                        placeholder="e.g. Purchase Order Number"
                        readOnly={trader_type === "buyer" ? true : false}
                        value={purchaseOrderNO}
                        onChange={(e) => setpurchaseOrderNO(e.target.value)}
                      />
                    </Box>
                  </Grid>
                  <Grid item sm={12} xs={12} md={4} lg={4} xl={4}>
                    <Box className="input-box">
                      <span className="company-title3">
                        Invoice Number<span className="required">*</span>
                      </span>
                      <OutlinedInput
                        className="company-title5"
                        fullWidth
                        placeholder="e.g. invoice Number"
                        value={invoiceNo}
                        readOnly={trader_type === "buyer" ? true : false}
                        onChange={(e) => setinvoiceNo(e.target.value)}
                      />
                    </Box>
                  </Grid>
                  <Grid item sm={12} xs={12} md={4} lg={4} xl={4}>
                    <Box className="input-box">
                      <span className="company-title3">
                        Invoice Amount ({currency_symbol})
                        <span className="required">*</span>
                      </span>
                      <OutlinedInput
                        className="company-title5"
                        fullWidth
                        placeholder="e.g. TXN567890"
                        value={invoiceammount}
                        readOnly={trader_type === "buyer" ? true : false}
                        onChange={(e) => setinvoiceammount(e.target.value)}
                      />
                    </Box>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item sm={12} xs={12} md={4} lg={4} xl={4}>
                    <Box className="input-box">
                      <span className="company-title3">
                        Invoice Date<span className="required">*</span>
                      </span>
                      {/*<TextField
                                        id="date"
                                        type="date"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={invoiceDate}
                                        readOnly={trader_type === "buyer" ? true : false}
                                        onChange={(e) => { setinvoiceDate(e.target.value) }}
                                    />*/}
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack spacing={3}>
                          <DesktopDatePicker
                            inputFormat="MM/dd/yyyy"
                            readOnly={trader_type === "buyer" ? true : false}
                            value={invoiceDate}
                            onChange={invoiceDateChange}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </Box>
                  </Grid>
                  <Grid item sm={12} xs={12} md={4} lg={4} xl={4}>
                    <Box className="input-box">
                      <span className="company-title3">
                        Due Date<span className="required">*</span>
                      </span>
                      {/*<TextField
                                        id="date"
                                        type="date"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={dueDate}
                                        readOnly={trader_type === "buyer" ? true : false}
                                        onChange={(e) => { setdueDate(e.target.value) }}
                                    />*/}
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack spacing={3}>
                          <DesktopDatePicker
                            inputFormat="MM/dd/yyyy"
                            readOnly={trader_type === "buyer" ? true : false}
                            value={dueDate}
                            onChange={dueDateChange}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </Box>
                  </Grid>
                  <Grid item sm={12} xs={12} md={4} lg={4} xl={4}>
                    <Box className="input-box">
                      <span className="company-title3">
                        Purchase Order Date <span className="required">*</span>
                      </span>
                      {/*<TextField
                                        id="date"
                                        type="date"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={purchaseOrderDate}
                                        readOnly={trader_type === "buyer" ? true : false}
                                        onChange={(e) => { setpurchaseOrderDate(e.target.value) }}
                                    />*/}
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack spacing={3}>
                          <DesktopDatePicker
                            inputFormat="MM/dd/yyyy"
                            readOnly={trader_type === "buyer" ? true : false}
                            value={purchaseOrderDate}
                            onChange={purchaseOrderDateChange}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </Box>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item sm={12} xs={12} md={4} lg={4} xl={4}>
                    <Box className="input-box">
                      <span className="company-title3">
                        Credit Days<span className="required">*</span>
                      </span>
                      <OutlinedInput
                        className="company-title5"
                        fullWidth
                        type="number"
                        placeholder="e.g. Credit Days"
                        readOnly={trader_type === "buyer" ? true : false}
                        value={creditDays}
                        onChange={(e) => setcreditDays(e.target.value)}
                      />
                    </Box>
                  </Grid>
                  <Grid item sm={12} xs={12} md={4} lg={4} xl={4}>
                    <Box className="input-box">
                      <span className="company-title3">
                        Amount Require ({currency_symbol})
                        <span className="required">*</span>
                      </span>
                      <OutlinedInput
                        className="company-title5"
                        fullWidth
                        placeholder="e.g. Amount Require"
                        value={amountRequired}
                        onChange={(e) => setamountRequired(e.target.value)}
                        readOnly={trader_type === "buyer" ? true : false}
                      />
                    </Box>
                  </Grid>
                </Grid>

                <Typography className="sub-heading">Upload Document</Typography>

                {docId.map((item, index1) => (
                  <Box className="upload-documents" key={index1}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <span className="company-title3">
                          {item.name}
                          {item.is_required === true ? (
                            <span className="document-required">*</span>
                          ) : (
                            ""
                          )}
                        </span>
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <center>
                          <Box className="button">
                            <label className="button-title edit">
                              Upload Document
                              <input
                                label="click"
                                hidden
                                type="file"
                                onChange={(e) => {
                                  function_Second(e, item);
                                }}
                              />
                            </label>
                          </Box>
                        </center>
                      </Grid>
                      {InvoiceDocuments && (
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Grid container spacing={2}>
                            {InvoiceDocuments.map((doc_list, index2) => (
                              <>
                                {doc_list.doc_list &&
                                  doc_list.doc_list.map((doc_item, index3) => (
                                    <>
                                      {doc_item.document_type_id === item.id ? (
                                        <>
                                          <Grid
                                            item
                                            xs={6}
                                            sm={4}
                                            md={4}
                                            lg={4}
                                            xl={4}
                                            key={index3}>
                                            <center>
                                              <Box className="uploaded-documents-list image-box">
                                                {doc_item.image_extension ===
                                                "application/pdf" ? (
                                                  <embed
                                                    src={`${
                                                      api_url +
                                                      doc_item.document_url
                                                    }?${Date.now()}`}
                                                    className="image"
                                                    type={
                                                      doc_item.image_extension
                                                    }
                                                  />
                                                ) : (
                                                  <img
                                                    className="image"
                                                    src={`${
                                                      api_url +
                                                      doc_item.document_url
                                                    }?${Date.now()}`}
                                                  />
                                                )}

                                                <Box className="img-action-option edit">
                                                  <label className="edit-label">
                                                    <center>
                                                      <EditOutlinedIcon className="img-edit" />
                                                    </center>
                                                    <input
                                                      type="file"
                                                      hidden
                                                      onChange={(e) =>
                                                        editUpload(
                                                          e,
                                                          doc_item,
                                                          item.name
                                                        )
                                                      }
                                                    />
                                                  </label>
                                                </Box>
                                                <Box className="img-action-option delete">
                                                  <center>
                                                    <DeleteOutlineIcon
                                                      onClick={(e) =>
                                                        deleteUpload(
                                                          e,
                                                          doc_item.document_id
                                                        )
                                                      }
                                                      className="img-delete"
                                                    />
                                                  </center>
                                                </Box>
                                              </Box>
                                            </center>
                                          </Grid>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  ))}
                              </>
                            ))}
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                ))}

                <Grid container spacing={3} className="button-grid">
                  <Grid item xs={6} sm={4} md={2} xl={2}>
                    <Button
                      onClick={createInvoiceApi}
                      className="save"
                      disabled={
                        !buyerId ||
                        !creditOfferId ||
                        !buyerLocationId ||
                        !purchaseOrderNO ||
                        !invoiceNo ||
                        !invoiceammount ||
                        !invoiceDate ||
                        !dueDate ||
                        !purchaseOrderDate ||
                        !creditDays ||
                        !amountRequired
                      }>
                      Save
                    </Button>
                  </Grid>
                  {user_role === "admin" || user_role === "approver" ? (
                    <Grid item xs={6} sm={4} md={2} xl={2}>
                      <Button
                        className="invite"
                        onClick={submitForm}
                        disabled={
                          !buyerId ||
                          !creditOfferId ||
                          !buyerLocationId ||
                          !purchaseOrderNO ||
                          !invoiceNo ||
                          !invoiceammount ||
                          !invoiceDate ||
                          !dueDate ||
                          !purchaseOrderDate ||
                          !creditDays ||
                          !amountRequired
                        }>
                        Submit
                      </Button>
                    </Grid>
                  ) : null}
                  <Grid item xs={6} sm={4} md={2} xl={2}>
                    <Button
                      className="cancel"
                      onClick={(e) => history.push("/invoice")}>
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            {openPaymentModel && (
              <WebRedirectPaymentMethod
                paymentMasterData={paymentMasterData}
                po_number={purchaseOrderNO}
                invoice_no={invoiceNo}
                openPaymentModel={openPaymentModel}
                modelPaymentClose={modelPaymentClose}
              />
            )}
          </>
        )}
      </ThemeContainer>
    </>
  );
}
