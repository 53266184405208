import React from "react";
// import { Box } from "@material-ui/core"
import { Bar } from "react-chartjs-2";

export default function VerticalBar(props) {
  const label = Object.keys(props.info);
  const names = Object.values(props.info);
  //const data_value = names.toLocaleString(undefined, {minimumFractionDigits: 2});

  const data = {
    labels: [label],
    datasets: [
      {
        data: [names],
        backgroundColor: ["#1890FF"],
        borderRadius: Number.parseInt(4),
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,

    plugins: {
      tooltip: {
        displayColor: false,
        callbacks: {
          label: function (item) {
            return item.parsed.y;
          },
        },
      },
      title: {
        display: true,
        text: props.name,
        align: "start",
        font: {
          size: 16,
          weight: "bold",
        },
        padding: 20,
        color: "#479FC8",
      },
      legend: {
        display: false,
      },
    },

    scales: {
      x: {
        grid: {
          lineWidth: 0,
        },
      },
      y: {
        grid: {
          borderDash: [7, 5],
        },
      },
    },
  };

  return <Bar data={data} options={options} />;
}
