import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import image2 from "../../assets/img/Financedoodle.svg";
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-kit-react/views/components.js";
import { Typography } from "@material-ui/core";
import { isMobile } from "react-device-detect";
import DetailCard from "../Components/HomePageComponents/DetailCard.js";
const useStyles = makeStyles(styles);

export default function HomePage3(props) {
  const api_url = process.env.REACT_APP_URL;
  const classes = useStyles();
  const [data, setData] = useState(props.info);
  const [promo, setPromo] = useState(props.promo);
  return (
    <>
      {data && promo && (
        <div
          className={classes.imgfluid + " fastcash-promotion-line"}
          style={{ backgroundImage: `url(${image2})` }}>
          <div>
            <Grid container className="fastcash-container">
              <Grid item xs={12}>
                {promo && promo !== undefined && (
                  <div
                    className="fastcash-promo-content"
                    dangerouslySetInnerHTML={{ __html: promo }}></div>
                )}
              </Grid>

              <Grid container spacing={2} className="fastcash-box">
                {data &&
                  data !== undefined &&
                  data !== null &&
                  data.map((info, page3_index) => (
                    <Grid item xs={6} sm={6} md={3} xl={3} key={page3_index}>
                      <DetailCard
                        img={`${
                          api_url + info.promotion_line_image
                        }?${Date.now()}`}
                        title={info.heading}
                        details={info.content}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </div>
        </div>
      )}
    </>
  );
}
