import React from 'react';
import styles from "assets/jss/material-kit-react/views/components.js";
import Grid from '@material-ui/core/Grid'
import { Button, Box, OutlinedInput } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import { makeStyles } from "@material-ui/core/styles";
import SignupFooter from 'views/Signup/footer';
import image from "../../assets/img/forgot-password.png"
import image2 from "../../assets/img/Financedoodle.svg"
import HomePage5 from "views/HomePage/HomePage5";
import { isMobile } from 'react-device-detect';
const useStyles = makeStyles(styles);



export default function Change() {
    const classes = useStyles();
    return (
        <>
            <Box style={{ background: "white" }}>
                <Box className={classes.imgfluid} style={{ padding: "1%", background: "#FFF" }}>
                    <Box style={{ margin: "10%", background: "#FFFFFF", borderRadius: "16px" }}>

                        {isMobile ? <Box padding="2%" style={{ display: "flex", alighContent: "center", justifyContent: "center" }}>

                            <img style={{ height: "90%", width: "90%" }} src={image} />
                        </Box> : " "}
                        <Grid container spacing={10} >

                            <Grid item xs={12} sm={12} md={6} xl={6} style={{ marginRight: "0%" }}>
                                <Box style={{ "margin-left": "5%", "margin-bottom": "40px" }}>
                                    <Grid item xs={12} style={{ fontSize: isMobile ? "24px" : "32px", color: "#18425D", fontWeight: "bold", lineHeight: "48px", fontFamily: "Averta-SemiBold" }}>
                                        Change Password
                                    </Grid>


                                    <Typography style={{ color: "#848F9F", fontFamily: "Averta-Regular", fontSize: isMobile ? "11px" : "12px", fontWeight: "600", style: "normal", marginTop: "8%", marginBottom: isMobile ? "40%" : "10%", height: "16px" }} >Lorem ipsum dolor sit amet, consectetur adipiscing elit.Sed feugiat, sapien in rhoncus suscipit, metus mi accumsan dui, vel vulputate metus mauris vitae tellus.Aenean poettitor condimentum eros sed tempus.

                                    </Typography>

                                    <Box style={{ marginTop: isMobile ? "" : "20%" }}>
                                        <span className="company-title3">Old Password<span style={{ color: "red" }}>*</span></span>
                                        <OutlinedInput
                                            fullWidth className="company-title51"
                                            placeholder="Old Password"
                                        />
                                    </Box>

                                    <Box style={{ marginTop: "8%" }} >
                                        <span className="company-title3">New Password<span style={{ color: "red" }}>*</span></span>
                                        <OutlinedInput
                                            fullWidth className="company-title51"
                                            placeholder="New Password"
                                        />
                                    </Box>
                                    <Box style={{ marginTop: "8%" }}>
                                        <span className="company-title3">Confirm Password<span style={{ color: "red" }}>*</span></span>
                                        <OutlinedInput
                                            fullWidth className="company-title51"
                                            placeholder="Confirm Password"
                                        />
                                    </Box>
                                    <Grid container spacing={3} style={{ paddingTop: "10%" }}>
                                        <Grid item xs={6} sm={6} md={6} lg={6} xl={3}>


                                            <Button style={{
                                                height: "150%",
                                                width: "100%", fontWeight: "bold",
                                                lineHeight: " 132.24%",
                                                fontSize: isMobile ? "12px" : "16px", color: "#FFFFFF", "background": "#479FC8", "borderRadius": "5px", fontFamily: "Averta-Regular"
                                            }} >

                                                Reset Password
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6} lg={6} xl={3}>

                                            <Button style={{
                                                height: "150%",
                                                width: "100%", fontSize: isMobile ? "12px" : "16px", lineHeight: " 132.24%", color: "#479FC8", "background": "#EBF8FE", "borderRadius": "5px", fontFamily: "Averta-Regular"
                                            }}>
                                                Cancel
                                            </Button>

                                        </Grid>
                                    </Grid>


                                </Box>
                            </Grid>
                            {isMobile ? " " : <Grid item xs={12} sm={12} md={6} xl={6}>
                                <Box padding="6%" style={{ display: "flex", marginTop: "20%", alighContent: "center", justifyContent: "center" }}>
                                    <img style={{ height: "90%", width: "90%" }} src={image} />

                                </Box>
                            </Grid>}
                        </Grid>

                    </Box >
                </Box >
            </Box>
            <HomePage5 />
            {isMobile ? " " : <SignupFooter />}
        </>
    )
}
