import React, { useState } from 'react'
import { Dialog, Box, withStyles, Button, DialogTitle } from "@material-ui/core"
import { PropTypes } from "prop-types"
const styles = () => ({

    box: {
        "& div": {
            background: "bottom !important",
            "&.MuiDialog-paperWidthSm": {
                maxWidth: "100%"
            },
            "&.MuiDialog-paperScrollPaper": {
                maxHeight: "100%"
            },
            "&.MuiDialog-paper": {
                margin: 0,
            },
        }

    }
})

function Preview(props) {
    const { setOpen1, image, activity } = props;
    const { classes } = props;
    const handleClose = () => {
        activity.setOpen1(false)
    }
    return (
        <Dialog className={classes.box}
            // maxWidth={"xs"}
            open={open}>
            <Box
                component="form"
                style={{
                    background: "rgba(255, 255, 255, 0.5)",
                }}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: "center",
                    alignItems: "center",
                    m: 'auto',
                    width: "100vw",
                    height: "100vh"
                }}
            >

                <img src={image} />
                <DialogTitle>
                    <Button onClick={() => handleClose()}>x</Button>
                </DialogTitle >
            </Box>
        </Dialog>
    );
}

Preview.propTypes = {
    classes: PropTypes.object
}


export default withStyles(styles)(Preview);