import React, { useState, useRef, useContext } from "react";
import {
  Box,
  OutlinedInput,
  Typography,
  Grid,
  Button,
  withStyles,
  Snackbar,
  SnackbarContent,
} from "@material-ui/core";

import { useEffect } from "react";
import { MenuItem } from "@material-ui/core";
import { FormControl } from "@material-ui/core";
import { Select } from "@material-ui/core";
import { isMobile } from "react-device-detect";
import { PropTypes } from "prop-types";
var axios = require("axios");
import { getAccessToken } from "../../../utilities/tokenUtil";
import { setAccessToken } from "utilities/tokenUtil";
import EditOutlinedIcon from "@material-ui/icons/BorderColor";
import { setUserId } from "utilities/tokenUtil";
import Tooltip from "@mui/material/Tooltip";

import image from "../../../assets/img/profile.svg";
// import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import InfoIcon from "@material-ui/icons/Help";
import Slider from "react-slick";
import { useHistory } from "react-router-dom";
//import InfoIcon from '@mui/icons-material/Info';
import { Store } from "../../../context/store/storeState";
import { Spinner } from "react-bootstrap";

const styles = (theme) => ({
  textFields: {
    background: "#F3F5F6",
    height: "40px",
    marginTop: "1%",
    "& div": {
      "&::before": {
        content: "",
        borderBottom: "none",
      },
      "&::after": {
        borderBottom: "none",
      },
      "&:hover": {
        "&::before": {
          borderBottom: "none !important",
        },
        "&::after": {
          borderBottom: "none !important",
        },
      },
      "& .MuiInputBase-input": {
        padding: "9px 7px",
      },
    },
  },
});

function BankKYC(props) {
  const kyc_status = localStorage.getItem("kyc_status");

  const { classes } = props;
  const { activity } = props;
  const api_url = process.env.REACT_APP_URL;
  const [doc, setdoc] = React.useState([]);
  const [reqdoc, setReqDoc] = React.useState([{}]);
  const [check, setCheck] = React.useState([]);
  const [docList, setDocList] = React.useState([]);
  const teamMemberImageInputRef = useRef();
  const [docTypeList, setDocType] = React.useState([]);
  const [alldoc, setalldoc] = React.useState();
  const [lender, setlender] = React.useState();
  const [account_number, setAccNumber] = React.useState("");
  const [branch_name, setBranchName] = React.useState("");
  const [rm_email, setEmail] = React.useState("");
  const [bank_name, setBankName] = React.useState("");
  const [nonReqDoc, setNonReqDoc] = React.useState([{ doctype: "", file: "" }]);
  const [kycType, setKycType] = React.useState("");
  const [files, setFile] = React.useState("");
  const [allList, setallList] = useState({});

  const verifyContext = useContext(Store);
  const setKycStatusAdd = verifyContext.setKycStatusAdd;
  const kyc_status_add = verifyContext.kyc_status_add;

  const [message, setMessage] = useState({});
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [loader, setLoader] = useState(false);

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    initialSlide: 0,
    padSlides: false,
  };

  const handleTeamMemberImageChage1 = (event, id) => {
    /*if(kycType !== "bank"  && kycType !== "" ){
      setLoading(false)
      setOpen(true)
      setMessage({ type: "error", content: "You have already added the documents, if you upload these then the earlier ones will be deleted." })
      //return
    }*/
    if (
      account_number === "" ||
      account_number === false ||
      branch_name === "" ||
      branch_name === false ||
      rm_email === "" ||
      rm_email === false
    ) {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "Please fill all mandatory fields.",
      });
      return;
    }

    if (bank_name === "" || bank_name === false) {
      const access_token = getAccessToken();
      const dataset = {
        account_number: account_number,
        rm_email: rm_email,
        user_id: parseInt(localStorage.getItem("userId")),
        access_token: access_token,
        lender_id: branch_name,
      };

      axios
        .post(`${process.env.REACT_APP_URL}/api/v1/kyc/bank/process`, dataset, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then((response) => {
          /*setLoading(false);
          setOpen(true);
          setMessage({ type: "success", content: "KYC document uploaded successfully." });
          history.push("/kycsuccess")*/
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (event.target.files && event.target.files[0]) {
      let fileext = event.target.files[0];
      if (
        fileext.name.match(/\.(jpg|jpeg|png|gif|pdf|JPG|JPEG|PNG|GIF|PDF)$/)
      ) {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          // setfile(reader.result);
        });
        reader.readAsDataURL(event.target.files[0]);
        let myVar = docTypeList.filter((x) => x.id === id);

        const access_token = getAccessToken();
        const userID = localStorage.getItem("userId");
        setAccessToken(access_token);
        setFile(event.target.files[0]);
        setUserId(userID);
        setLoader(false);
        const data11 = {
          user_id: parseInt(localStorage.getItem("userId")),
          access_token: access_token,
          document_type_id: id,
          kyc_type: "bank",
        };

        axios
          .post(
            `${process.env.REACT_APP_URL}/api/v1/kyc/get/document/id`,
            data11,
            {
              headers: {
                "Content-Type": "text/plain",
              },
            }
          )
          .then((response) => {
            const data0 = {
              user_id: parseInt(localStorage.getItem("userId")),
              access_token: access_token,
              document_id: response.data.document_id,
              document_type_id: id,
              document_name: myVar[0].name,
            };

            let data1 = new FormData();
            data1.append("user_id", parseInt(localStorage.getItem("userId")));
            data1.append("access_token", access_token);
            data1.append("document_id", response.data.document_id);
            data1.append("document_type_id", id);
            data1.append("document_name", myVar[0].name);
            data1.append("document_data", event.target.files[0]);
            axios
              .post(
                `${process.env.REACT_APP_URL}/api/v1/kyc/document/upload`,
                data1,
                {
                  headers: {
                    "Content-Type": "text/plain",
                  },
                }
              )
              .then((response) => {
                grtListOfDocuments();
                setLoader(true);
              })
              .catch((error) => {});
          })
          .catch((error) => {});
      } else {
        setLoading(false);
        setOpen(true);
        setMessage({
          type: "error",
          content: "Only jpg, jpeg, PNG, PDF pdf, gif files are allowed!",
        });
      }
    }
  };

  const editUpload = (event, document_name, document_type_id, document_id) => {
    let fileext = event.target.files[0];
    if (fileext.name.match(/\.(jpg|jpeg|png|gif|pdf|JPG|JPEG|PNG|GIF|PDF)$/)) {
      const access_token = getAccessToken();
      let data1 = new FormData();
      data1.append("user_id", parseInt(localStorage.getItem("userId")));
      data1.append("access_token", access_token);
      data1.append("document_id", document_id);
      data1.append("document_type_id", document_type_id);
      data1.append("document_name", document_name);
      data1.append("document_data", event.target.files[0]);
      setLoader(false);
      axios
        .post(
          `${process.env.REACT_APP_URL}/api/v1/kyc/document/upload`,
          data1,
          {
            headers: {
              "Content-Type": "text/plain",
            },
          }
        )
        .then((response) => {
          grtListOfDocuments();
          setLoader(true);
        })
        .catch((error) => {});
    } else {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "Only jpg, jpeg, PNG, PDF pdf, gif files are allowed!",
      });
    }
  };

  const updateDocumentTypeValue1 = (index, id) => {
    nonReqDoc.map((x, i) => {
      if (index == i) {
        let newArray = [...nonReqDoc];
        newArray[index].doctype = id;

        setNonReqDoc(newArray);
      }
    });

    setdoc(doc);
    setReqDoc(doc);
  };

  const addDocument1 = () => {
    setNonReqDoc([
      ...nonReqDoc,
      {
        doctype: "",
        file: "",
      },
    ]);
  };

  const handleTeamMemberImageChage = (event, id, doc_id) => {
    /*if(kycType !== "bank" ){
      setLoading(false)
      setOpen(true)
      setMessage({ type: "error", content: "You have already added the documents, if you upload these then the earlier ones will be deleted." })
      //return
    }*/
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {});
      reader.readAsDataURL(event.target.files[0]);
      let myVar = docTypeList.filter((x) => x.document_id === doc_id);
      const access_token = getAccessToken();
      const userID = localStorage.getItem("userId");
      setAccessToken(access_token);
      setUserId(userID);

      const data = {
        user_id: parseInt(localStorage.getItem("userId")),
        access_token: access_token,
        document_id: doc_id,
        document_type_id: 1,
        document_name: myVar[0].document,
      };

      let data1 = new FormData();
      data1.append("user_id", parseInt(localStorage.getItem("userId")));
      data1.append("access_token", access_token);
      data1.append("document_id", id);
      data1.append("document_type_id", doc_id);
      data1.append("document_name", myVar[0].document);
      data1.append("document_data", event.target.files[0]);
      axios
        .post(
          `${process.env.REACT_APP_URL}/api/v1/kyc/document/upload`,
          data1,
          {
            headers: {
              "Content-Type": "text/plain",
            },
          }
        )
        .then((response) => {
          addDocument(doc_id, id);
          grtListOfDocuments();
        })
        .catch((error) => {});
    }
  };

  const remove = (val) => {
    doc.slice(val, 1);

    const access_token = getAccessToken();
    const userID = localStorage.getItem("userId");
    setAccessToken(access_token);
    setUserId(userID);

    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: access_token,
      document_id: val,
    };

    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/kyc/document/delete`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setOpen(true);
          setMessage({ type: "success", content: response.data.message });
          grtListOfDocuments();
        } else {
          setOpen(true);
          setMessage({ type: "error", content: response.data.message });
        }
      })
      .catch((error) => {});
  };

  const handleFileUpload = (e) => {};

  const addDocument = (doc, id) => {};
  const kycMaster = () => {
    const access_token = getAccessToken();

    setAccessToken(access_token);

    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: access_token,
      user_type: "buyer",
      kyc_type: "bank_kyc",
    };

    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/kyc/master`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        //setlender(response.data.lender_bank_list[0].id);
        setDocType(response.data.documents);
        setlender(response.data.lender_bank_list);
        setDocType(response.data.documents);
        setdoc(response.data.documents);
        setLoader(true);
      })
      .catch((error) => {});
  };

  const grtListOfDocuments = () => {
    const access_token = getAccessToken();
    setAccessToken(access_token);

    const data = {
      access_token: access_token,
      user_id: parseInt(localStorage.getItem("userId")),
      kyc_type: "bank_kyc",
    };

    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/kyc/get/document/list`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        const result = response.data.document_list.filter(
          (word) => word.kyc_type === "bank"
        );
        if (response.data.kyc_type === "bank") {
          setDocList(response.data.document_list);
          localStorage.setItem("kyc_status", response.data.kyc_status);
          localStorage.setItem("kyc_type", "Bank");
        }
        if (response.data.kyc_status === "Approved") {
          localStorage.setItem("kyc_status", "Approved");
          localStorage.setItem("kyc_type", "Bank");
          //window.location.reload(false);
        }

        if (response.data.kyc_type === "manual") {
          setLoading(false);
          setOpen(true);
          setMessage({
            type: "error",
            content:
              "On switching of the KYC process, all previous documents will be delete.",
          });
        }

        setKycType(response.data.kyc_type);
        setKycStatusAdd(response.data.kyc_status);
        setAccNumber(response.data.account_number);
        setBankName(response.data.kyc_bank_name);
        //setBranchName(response.data.responsible_id);
        //setBankName(response.data.default_domicile_bank.name);
        setBranchName(response.data.default_domicile_bank.id);
        setEmail(response.data.rm_email);
        activity.setallList(response.data.document_list);
        setLoader(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateDocumentTypeValue = (index, id) => {
    docList[index].document_type_id = id;
    setDocList(docList);
  };

  const downloadDocument = (event, document_id) => {
    event.preventDefault();
    const access_token = getAccessToken();

    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: access_token,
      document_id: parseInt(document_id),
    };

    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/kyc/document/download`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        if (response.data.success === true) {
          let url = process.env.REACT_APP_URL + response.data.document_url;
          window.open(url, "_blank");
        } else {
          setLoading(false);
          setOpen(true);
          setMessage({ type: "error", content: "Something went wrong." });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const onSubmit = (event) => {
    /*if( kycType !== "bank" && kycType !== false ){
      setLoading(false)
      setOpen(true)
      setMessage({ type: "error", content: "You have already added the documents, if you upload these then the earlier ones will be deleted." })
      //return
    }*/
    let regEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let account_validate = /^[0][1-9]\d{9}$|^[1-9]\d{9}$/;

    if (
      account_number === "" ||
      account_number === false ||
      branch_name === "" ||
      branch_name === false ||
      rm_email === "" ||
      rm_email === false
    ) {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "Please fill all mandatory fields.",
      });
      return;
    }

    if (!account_number.match(account_validate)) {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "Account number should be 10 digit",
      });
      return false;
    }
    if (!regEmail.test(rm_email)) {
      setLoading(false);
      setOpen(true);
      setMessage({ type: "error", content: "Please enter a valid email." });
      return false;
    }

    var a = new Set([]);
    docTypeList.map((item) => {
      if (item.is_required === true) {
        docList.map((x) => {
          x.doc_list.map((y) => {
            if (y.document_type_id === item.id) {
              a.add(y.document_type_id);
            }
          });
        });
      }
    });

    let array = Array.from(a);

    let req = [];
    docTypeList.map((item) => {
      if (item.is_required === true) {
        req.push(item.id);
      }
    });

    if (
      Array.isArray(req) &&
      Array.isArray(array) &&
      req.length === array.length &&
      req.every((val, index) => val === array[index])
    ) {
      setLoader(false);
      const access_token = getAccessToken();
      const dataset = {
        user_id: parseInt(localStorage.getItem("userId")),
        access_token: access_token,
        kyc_type: "bank",
        status: "",
      };

      axios
        .post(`${process.env.REACT_APP_URL}/api/v1/submit/kyc`, dataset, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then((response) => {
          if (response.data.success === true) {
            setLoading(false);
            setOpen(true);
            setMessage({ type: "success", content: response.data.message });
            setTimeout(() => {
              history.push("/kycsuccess");
            }, 3000);
          } else {
            setLoading(false);
            setOpen(true);
            setMessage({ type: "error", content: response.data.message });
            setLoader(true);
            return;
          }
        })
        .catch((error) => {
          console.log(error);
        });

      /*setLoading(false)
      setOpen(true)
      setMessage({ type: "success", content: "KYC document uploaded successfully." })
      setTimeout(() => {
        history.push('/kycsuccess')
      }, 3000)*/
    } else {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "Please upload all KYC documents.",
      });
      return;
    }

    /*if(!files){
      setLoading(false)
      setOpen(true)
      setMessage({ type: "error", content: "Please upload all KYC documents" })
      return
    }*/
    if (bank_name === "" || bank_name === false) {
      const access_token = getAccessToken();
      const dataset = {
        account_number: account_number,
        rm_email: rm_email,
        user_id: parseInt(localStorage.getItem("userId")),
        access_token: access_token,
        lender_id: branch_name,
      };

      axios
        .post(`${process.env.REACT_APP_URL}/api/v1/kyc/bank/process`, dataset, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then((response) => {
          setLoading(false);
          setOpen(true);
          setMessage({
            type: "success",
            content: "KYC document uploaded successfully.",
          });
          setTimeout(() => {
            history.push("/kycsuccess");
          }, 3000);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const handleAccountNumber = (e) => {
    const re = /^[0-9\b]{0,10}$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setAccNumber(e.target.value);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    kycMaster();
    grtListOfDocuments();
  }, []);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}>
        <SnackbarContent
          className={
            message.type === "error"
              ? "snack-error"
              : message.type === "success"
              ? "snack-success"
              : "snack-teal"
          }
          message={message.content}
        />
      </Snackbar>

      {loader !== true ? (
        <Grid container spacing={3} className="bootstrap-loader">
          <Spinner animation="grow" variant="spinner1" />
          <Spinner animation="grow" variant="spinner2" />
          <Spinner animation="grow" variant="spinner3" />
        </Grid>
      ) : (
        <>
          <Box>
            <Grid container>
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#6EB4E0",
                  padding: "3% 0",
                }}>
                Enter Bank details
              </Typography>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4} xl={4}>
                <Box>
                  <span className="company-title3">
                    Account Number<span className="required">*</span>
                  </span>
                  <OutlinedInput
                    className="company-title51"
                    fullWidth
                    placeholder="e.g. Account Number"
                    value={account_number ? account_number : ""}
                    onChange={(e) => handleAccountNumber(e)}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4} xl={4}>
                <Box>
                  <span className="company-title3">
                    Bank Name & Branch<span className="required">*</span>
                  </span>
                  <FormControl fullWidth>
                    <Select
                      displayEmpty
                      required
                      readOnly
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={branch_name ? branch_name : ""}
                      onChange={(e) => setBranchName(e.target.value)}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}>
                      <MenuItem value="" disabled={true}>
                        Select from options
                      </MenuItem>

                      {lender != undefined &&
                        lender.map((item, index) => {
                          return (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4} xl={4}>
                <Box>
                  <span className="company-title3">
                    Relationship Manager Email
                    <span className="required">*</span>
                  </span>
                  <OutlinedInput
                    className="company-title51"
                    fullWidth
                    placeholder="e.g. rm@interswitch.com"
                    value={rm_email ? rm_email : ""}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid container>
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#6EB4E0",
                  padding: "4% 0",
                }}>
                Other required details
              </Typography>
            </Grid>
            {doc &&
              doc.map((item, index) => (
                <Box>
                  <Grid container align="center" spacing={2}>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Grid container align="center" spacing={2}>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          lg={6}
                          xl={6}
                          display="flex"
                          direction="column"
                          container
                          className={classes.centerColumn}>
                          <span className="company-title3">
                            {item.name}
                            {item.is_required === true ? (
                              <span style={{ color: "red" }}>*</span>
                            ) : (
                              ""
                            )}
                          </span>
                        </Grid>
                        {kyc_status !== "Approved" && (
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                            display="flex"
                            justify="center"
                            direction="column"
                            container
                            className={classes.centerColumn}
                            style={{ marginTop: "2%" }}>
                            <label
                              style={{
                                color: "#0275D8",
                                border: "2px solid #0275D8",
                                borderRadius: "8px",
                                textTransform: "initial",
                                padding: isMobile ? "5%" : "15px",
                                width: isMobile ? "90%" : "70%",
                                marginLeft: "10%",
                              }}>
                              Upload Document
                              <input
                                label="click"
                                style={{ display: "none" }}
                                type="file"
                                onChange={(e) => {
                                  handleTeamMemberImageChage1(e, item.id);
                                }}
                              />
                            </label>
                            <Box
                              style={{
                                width: isMobile ? "90%" : "70%",
                                marginLeft: "10%",
                                marginTop: "5%",
                              }}>
                              <Typography
                                style={{ fontSize: isMobile ? "55%" : "75%" }}>
                                In PNG, PDF JPG, GIF and SVG format size should
                                be less then 2 MB
                              </Typography>
                            </Box>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      style={{ marginTop: "1%" }}>
                      <Grid container spacing={2}>
                        {docList &&
                          docList.map((docItem_item) => (
                            <>
                              {docItem_item.doc_list.map(
                                (doc_item, j) =>
                                  doc_item.document_type_id === item.id && (
                                    <>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={6}
                                        lg={6}
                                        xl={6}>
                                        <center>
                                          <Box
                                            style={{
                                              height: "160px",
                                              width: "90%",
                                              position: "relative",
                                              textAlign: "center",
                                            }}>
                                            <img
                                              style={{
                                                height: "100%",
                                                width: "100%",
                                              }}
                                              src={`${
                                                api_url + doc_item.document
                                              }?${Date.now()}`}
                                              onClick={(e) =>
                                                downloadDocument(
                                                  e,
                                                  doc_item.document_id
                                                )
                                              }
                                            />
                                            {doc_item.state !== "Verified" && (
                                              <>
                                                <Box
                                                  style={{
                                                    position: "absolute",
                                                    bottom: "5px",
                                                    left: "3%",
                                                    background: "#0275d8",
                                                    height: "27px",
                                                    width: "30px",
                                                  }}>
                                                  <label>
                                                    <center>
                                                      <EditOutlinedIcon
                                                        style={{
                                                          marginTop: "6%",
                                                          color: "#ffff",
                                                        }}
                                                      />
                                                    </center>
                                                    <input
                                                      type="file"
                                                      hidden
                                                      onChange={(e) =>
                                                        editUpload(
                                                          e,
                                                          item.name,
                                                          item.id,
                                                          doc_item.document_id
                                                        )
                                                      }
                                                    />
                                                  </label>
                                                </Box>
                                                {doc_item.state !==
                                                  "Rejected" && (
                                                  <Box
                                                    style={{
                                                      position: "absolute",
                                                      bottom: "6px",
                                                      right: "3%",
                                                      background: "#0275d8",
                                                      height: "27px",
                                                      height: "27px",
                                                      width: "30px",
                                                    }}>
                                                    <center>
                                                      <DeleteOutlineIcon
                                                        onClick={() =>
                                                          remove(
                                                            doc_item.document_id
                                                          )
                                                        }
                                                        style={{
                                                          marginTop: "6%",
                                                          color: "#ffff",
                                                        }}
                                                      />
                                                    </center>
                                                  </Box>
                                                )}
                                              </>
                                            )}
                                            {doc_item.state === "Verified" ||
                                            doc_item.state === "Uploaded" ||
                                            doc_item.state === "Approved" ? (
                                              <Box
                                                style={{
                                                  position: "absolute",
                                                  bottom: "6px",
                                                  right: "3%",
                                                  top: "0",
                                                  background: "#0FDB60",
                                                  height: "25px",
                                                  width: isMobile
                                                    ? "80px"
                                                    : "60px",
                                                  color: "#fff",
                                                  lineHeight: "25px",
                                                }}>
                                                <center>
                                                  <span>{doc_item.state}</span>
                                                </center>
                                              </Box>
                                            ) : null}
                                            {doc_item.state ===
                                              "Awaiting Approval" && (
                                              <Box
                                                style={{
                                                  position: "absolute",
                                                  bottom: "6px",
                                                  right: "3%",
                                                  top: "0",
                                                  background: "#AEDDF1",
                                                  height: "25px",
                                                  width: isMobile
                                                    ? "140px"
                                                    : "130px",
                                                  color: "#fff",
                                                  lineHeight: "25px",
                                                }}>
                                                <center>
                                                  <span>{doc_item.state}</span>
                                                </center>
                                              </Box>
                                            )}
                                            {doc_item.state === "Rejected" && (
                                              <Box
                                                style={{
                                                  position: "absolute",
                                                  bottom: "6px",
                                                  right: "3%",
                                                  top: "0",
                                                  background: "#ED342B",
                                                  height: "25px",
                                                  width: isMobile
                                                    ? "100px"
                                                    : "80px",
                                                  color: "#fff",
                                                  lineHeight: "25px",
                                                }}>
                                                <center>
                                                  <Tooltip
                                                    title={
                                                      doc_item.rejection_reason
                                                    }
                                                    placement="top"
                                                    arrow>
                                                    <span>
                                                      <InfoIcon
                                                        style={{
                                                          fontSize: "15px",
                                                          marginRight: "5px",
                                                        }}
                                                      />
                                                      {doc_item.state}
                                                    </span>
                                                  </Tooltip>
                                                </center>
                                              </Box>
                                            )}
                                            {doc_item.state === "Pending" && (
                                              <Box
                                                style={{
                                                  position: "absolute",
                                                  bottom: "6px",
                                                  right: "3%",
                                                  top: "0",
                                                  background: "#FFCE00",
                                                  height: "25px",
                                                  width: isMobile
                                                    ? "100px"
                                                    : "80px",
                                                  color: "#fff",
                                                  lineHeight: "25px",
                                                }}>
                                                <center>
                                                  <span>{doc_item.state}</span>
                                                </center>
                                              </Box>
                                            )}
                                          </Box>
                                        </center>
                                      </Grid>
                                    </>
                                  )
                              )}
                            </>
                          ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              ))}
          </Box>
          {kyc_status !== "Approved" && (
            <Grid container spacing={3} className="button-grid">
              <Grid item xs={6} sm={4} md={2} xl={2}>
                <Button
                  className="save"
                  onClick={onSubmit}
                  disabled={!account_number || !branch_name || !rm_email}>
                  Submit
                </Button>
              </Grid>
              {/*<Grid item xs={6} sm={4} md={2} xl={2}>
          <Button className="cancel">Cancel</Button>
      </Grid>*/}
            </Grid>
          )}
        </>
      )}
    </>
  );
}

BankKYC.propTypes = {
  activity: PropTypes.any,
};

export default withStyles(styles)(BankKYC);
