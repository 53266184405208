import React from "react";
import {
  Box,
  Button,
  Grid,
  Paper,
  Toolbar,
  Typography,
} from "@material-ui/core";
import image from "../../../../assets/img/uploadInvoice.svg";

const mystyle = {
  heading: {
    fontSize: "24px",
    fontWeight: "700",
    paddingBottom: "2%",
    color: "#479FC8",
  },
  titles: {
    fontSize: "16px",
    fontWeight: "600",
    paddingBottom: "2%",
    color: "#479FC8",
  },
  textfield: { fontSize: "12px", fontWeight: "600", color: "#848F9F" },
  detail: { fontSize: "9px", fontWeight: "400", color: "#999999" },
  box: { paddingTop: "6%", paddingRight: "5%", paddingBottom: "5%" },
  upload: {
    fontSize: "14px",
    fontWeight: "600",
    color: "#0275D8",
    border: "1px solid #0275D8",
    width: "100%",
  },
  save: {
    background: "#479FC8",
    color: "#FFFFFF",
    fontSize: "16px",
    fontWeight: "600",
    padding: "2%",
    width: "100%",
  },
  cancel: {
    background: "#EBF8FE",
    color: "##479FC8",
    fontSize: "16px",
    fontWeight: "600",
    padding: "2%",
    width: "100%",
  },
  cell: { color: "green" },
};

export default function InvoiceUpload() {
  return (
    <Box>
      <Toolbar />
      <Typography style={mystyle.heading}>Upload Invoice Copy</Typography>

      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Paper
            style={{
              height: "70vh",
              borderRadius: "12px",
              background: "#F3F5F6",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}>
              <Box>
                <img src={image} />
              </Box>
              <Typography style={{ fontSize: "14px", fontWeight: "400" }}>
                PNG, PDF GIF and JPEG. Max 30mb
              </Typography>
              <Typography style={{ fontSize: "16px", fontWeight: "600" }}>
                Upload Document
              </Typography>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={6} sm={6} md={2} xl={1}>
          <Button style={mystyle.save}>Upload</Button>
        </Grid>
        <Grid item xs={6} sm={6} md={2} xl={1}>
          <Button style={mystyle.cancel}>Cancel</Button>
        </Grid>
      </Grid>
    </Box>
  );
}
