import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom"
import {
  Grid,
  Box,
  Typography,
  OutlinedInput,
  withStyles,
  MenuItem,
  FormControl,
  Select,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Toolbar,
  Snackbar,
  SnackbarContent
} from "@material-ui/core";
import preview from "assets/img/previewButton.svg";
import Custom from "views/Components/Custom";
import ThemeContainer from "views/Components/sideBar/ThemeContainer";
import { isMobile } from "react-device-detect";
import { PropTypes } from "prop-types";
import TableComponent from "views/Components/tableComponent/tablecomponent";
import { getAccessToken } from "../../utilities/tokenUtil";
import EditOutlinedIcon from '@material-ui/icons/BorderColor';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import image from "../../assets/img/profile.svg";
import { Spinner } from "react-bootstrap";
import InfoIcon from '@material-ui/icons/Help';
import Tooltip from '@mui/material/Tooltip';

var axios = require("axios");

const styles = (theme) => ({
  textFields: {
    background: "#F3F5F6",
    height: "40px",
    marginTop: "1%",
    "& div": {
      "&::before": {
        content: "",
        borderBottom: "none",
      },
      "&::after": {
        borderBottom: "none",
      },
      "&:hover": {
        "&::before": {
          borderBottom: "none !important",
        },
        "&::after": {
          borderBottom: "none !important",
        },
      },
      "& .MuiInputBase-input": {
        padding: "9px 7px",
      },
    },
  },
});

function Changepassword(props) {
    const userId        = localStorage.getItem("userId");
    const userName      = localStorage.getItem("userName");
    const kyc_status    = localStorage.getItem("kyc_status");    
    const is_lender     = localStorage.getItem("is_lender");
    const trader_type   = localStorage.getItem('trader_type');

    const { classes } = props;
    const history = useHistory()
    const api_url = process.env.REACT_APP_URL;

    const [password, setPassword]      = useState("");
    const [password2, setPassword2]    = useState("");
    const [email, setEmail]             = useState("");
    const [loader, setLoader]          = useState(false);
    const [loading, setLoading]        = useState(true);
    const [message, setMessage]        = useState({});
    const [open, setOpen]              = useState(false);
    const [matchmsg, setMatchMsg]       = useState(false);
    const [mismatchmsg, setMismatchMsg] = useState(false);
    const [matchRegex, setMatchRegex]   = useState(false);

    if(!userId || userName === null){
        history.push('/signin');
    }

    if(is_lender === "true" && trader_type === "undefined"){
      history.push('/lender/dashboard');
    }
    
    const myDetail = () => {
      setLoader(false)
      const access_token = getAccessToken();
      const data = {
        user_id: parseInt(localStorage.getItem("userId")),
        access_token: access_token
      };
      axios
        .post(`${process.env.REACT_APP_URL}/api/v1/get/my_account/detail`, data, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then((response) => {
          if (response.data.success === true) {
            setEmail(response.data.account_detail[0].email);
            setLoader(true)
          }else{
            setLoader(true)
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    
    const onSubmit = () => {
        const access_token = getAccessToken();
        if(password === "" || password2 === ""){
          setLoading(false);
          setOpen(true);
          setMessage({ type: "error", content: "All fields are mandatory." });
          return
        }
        var format = /^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;

        var hasNumber = /\d/;
        if (password === password2) {
              var regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;
              if(password.match(regex)){
                setLoader(false)
                const body = {
                  email: email,
                  confirm_password: password,
                };
                axios
                  .post(api_url + "/api/v1/set_password", body, {
                    headers: {
                      "Content-Type": "text/plain",
                    },
                  })
                  .then((res) => {
                    if (res.data.success) {
                      setLoading(false);
                      setOpen(true);
                      setMessage({ type: "success", content: res.data.message });
                      setTimeout(() => {
                        history.push('/viewCompany')
                      }, 3000)
                      
                    } else {
                      setLoading(false);
                      setOpen(true);
                      setLoader(true)
                      setMessage({ type: "error", content: res.data.message });
                    }
                  });
              } else {
                setOpen(true);
                setLoading(false);
                setLoader(true)
                setMessage({
                  type: "error",
                  content: "Password should be minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character",
                });
                return false;
              }
        } else {
          setOpen(true);
          setMessage({ type: "error", content: "Password did not match" });
          return false;
        }  
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };
    const passwordValidate = () => {    
      var regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/
      if(!password.match(regex)){
        setMatchRegex(true);
        setTimeout(() => {
          setMatchRegex(false);
        }, 3000);
        return;
      }    
    }
    const matchPassword = () => {
      if (password === password2) {    
        setMatchMsg(true);
        setTimeout(() => {
          setMatchMsg(false);
        }, 2000);
        return;
      }else{
        setMismatchMsg(true);
        setTimeout(() => {
          setMismatchMsg(false);
        }, 2000);
        return;
      }
    }
    
    useEffect(() => {
        document.title = 'Interswitch - Change Password';
        window.scrollTo(0, 0);
        myDetail();
    }, []);

  return (
    
        <ThemeContainer type="private" value={1}>          
          <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={open} autoHideDuration={3000} onClose={handleClose} >
          <SnackbarContent className={message.type === "error" ? "snack-error" : message.type === "success" ? "snack-success" : "snack-teal" }
              message={message.content} />
          </Snackbar>
          {loader !== true ? (
            <Grid container spacing={3} className="bootstrap-loader">
              
                <Spinner animation="grow" variant="spinner1" />
                <Spinner animation="grow" variant="spinner2" />
                <Spinner animation="grow" variant="spinner3" />
              
            </Grid>
          ) : (
            <>
            <Box className="scf-box">
              <Box className="scf-subbox">
                <Grid container>
                  <Grid item xs={12} sm={12} md={12} xl={12}>
                    <Typography className="company-title11">Change Password</Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={4} xl={4}>
                    <Box className="input-box">
                      <span className="company-title3">New Password <span className="required">*</span>{" "}
                        <Tooltip title="Password should be minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character" placement="top" arrow>
                            <InfoIcon />
                        </Tooltip>
                      </span>
                      <OutlinedInput
                        className="company-title5"
                        fullWidth
                        type="password"
                        value={password}
                        onKeyUp={passwordValidate}
                        placeholder="e.g. New Password"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={4} xl={4}>
                    <Box className="input-box">
                      <span className="company-title3">Confirm Password<span className="required">*</span></span>                  
                      <OutlinedInput
                        className="company-title5"
                        fullWidth
                        type="password"
                        value={password2}
                        onKeyUp={matchPassword}
                        placeholder="e.g. Confirm Password"
                        onChange={(e) => setPassword2(e.target.value)}
                      />
                      {matchmsg && matchmsg === true && (
                        <span className="company-title3" style={{ color: "green" }}>
                          Password matched.
                        </span>
                      )}
                      {mismatchmsg && mismatchmsg === true && (
                        <span className="company-title3" style={{ color: "red" }}>
                          Password not matched.
                        </span>
                      )}
                      {matchRegex && matchRegex === true && (
                        <span className="company-title3" style={{ color: "red" }}>
                          Password should be minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character
                        </span>
                      )}
                    </Box>
                  </Grid>
                </Grid>
                
                <Grid container spacing={3} className="button-grid">
                  <Grid item xs={6} sm={4} md={2} xl={2}>
                      <Button className="save" onClick={onSubmit} disabled={!password || !password2}>Save</Button>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2} xl={2}>
                      <Link to="/viewCompany"><Button className="cancel">Cancel</Button></Link>
                  </Grid>
                </Grid>

              </Box>
            </Box>
            </>
          )}    
        </ThemeContainer>
  );
}

Changepassword.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(Changepassword);
