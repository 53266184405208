import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  OutlinedInput,
  Toolbar,
  withStyles,
  MenuItem,
  FormControl,
  Select,
  Button,
  Snackbar,
  SnackbarContent,
} from "@material-ui/core";
import { useHistory, Link } from "react-router-dom";
import PropTypes from "prop-types";
import image1 from "assets/img/coun1.svg";
import { isMobile } from "react-device-detect";
import preview from "assets/img/previewButton.svg";
import Preview from "../../../Components/preview/preview";
import EditOutlinedIcon from "@material-ui/icons/BorderColor";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
var axios = require("axios");
import { getAccessToken } from "../../../../utilities/tokenUtil";
import image from "assets/img/defaultimg.jpg";
import { SelectAllSharp } from "@material-ui/icons";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Spinner } from "react-bootstrap";
import NumberFormat from "react-number-format";

const styles = () => ({
  textFields: {
    background: "#F3F5F6",
    height: "40px",
    marginTop: "1%",
    "& div": {
      "&::before": {
        content: "",
        borderBottom: "none",
      },
      "&::after": {
        borderBottom: "none",
      },
      "&:hover": {
        "&::before": {
          borderBottom: "none !important",
        },
        "&::after": {
          borderBottom: "none !important",
        },
      },
      "& .MuiInputBase-input": {
        padding: "9px 7px",
      },
    },
  },
});

const multiselectRef = React.createRef();

function UpdateInfo(props) {
  const userId = localStorage.getItem("userId");
  const userName = localStorage.getItem("userName");
  const redirect_company_info = localStorage.getItem("redirect_company_info");
  const currency_symbol = localStorage.getItem("currency_symbol");
  const is_lender = localStorage.getItem("is_lender");
  const trader_type = localStorage.getItem("trader_type");

  const history = useHistory();
  const [toDisplay, setToDisplay] = useState(preview);
  const api_url = process.env.REACT_APP_URL;
  const [name, setName] = useState("");
  const [registration, setRegistration] = useState("");
  const [suppliers, setSupplier] = useState("");
  const [taxation, setTaxation] = useState("");
  const [entity, setEntity] = useState("");
  const [industry, setIndustry] = useState([]);
  const [businessaddress, setBusinessAddress] = useState("");
  const [businesscountry, setBusinessCountry] = useState("");
  const [businessstate, setBusinessState] = useState("");
  const [businesscity, setBusinessCity] = useState("");
  const [correspondanceaddress, setCorrespondanceAddress] = useState("");
  const [correspondancecountry, setCorrespondanceCountry] = useState("");
  const [correspondancestate, setCorrespondanceState] = useState("");
  const [correspondancecity, setCorrespondancCity] = useState("");
  const [turnover, setTurnover] = useState("");
  const [limit, setLimit] = useState("");
  const [employee, setEmployee] = useState("");
  const [fullname, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [designation, setDesignation] = useState("");
  const [bvn, setbvn] = useState("");
  const [hq, setHq] = useState("");
  const [photo, setPhoto] = useState(preview);
  const [logo, setLogo] = useState(preview);
  const [allcountry, setcountry] = useState([]);
  const [allState1, setAllState1] = useState();
  const [allState2, setAllState2] = useState();
  const [entityList, setentityList] = useState([]);
  const [indusrtyList, setIndustryList] = useState([]);
  const [empList, setempList] = useState("");
  const [details, setDetails] = useState("");
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState({});
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [file, setfile] = useState("");
  const [fileView, setfileView] = useState("");
  const [hqList, setHqList] = useState("");
  const [contactId, setcontactId] = useState("");
  const [file2, setfile2] = useState("");
  const [fileView2, setfileView2] = useState("");
  const [filesend, setfilesend] = useState("");
  const [filesend1, setfilesend1] = useState("");
  const [correspondanceId, setCorrespondancId] = useState(0);
  const [contactName, setContactName] = useState("");
  const [contactEmail, contactSetEmail] = useState("");
  const [contactID, setContactId] = useState(0);
  const [contactPhone, setContactPhone] = useState("");
  const [contactDeg, setContactDeg] = useState("");
  const [contactBVN, setConatctBVN] = useState("");
  const [contactWork, setContactWork] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [contact_country, setContactCountry] = useState("");
  const [domiciled_bank, setDomiciledBank] = useState("");
  const [domiciledBankList, setDomiciledBankList] = useState([]);
  const [loader, setLoader] = useState(false);

  if (!userId || userName === null) {
    history.push("/signin");
  }

  if (is_lender === "true" && trader_type === "undefined") {
    history.push("/lender/dashboard");
  }

  const addContact = () => {
    setContact([
      ...contact,
      {
        id: 0,
        name: "",
        phone_number: "",
        email: "",
        job_position: "",
        working_at_id: "",
        bvn_number: "",
      },
    ]);
  };

  const changeCountry = (id, index) => {
    contact.map((x, i) => {
      if (index === i) {
        let newArray = [...contact];
        newArray[index].country_id = id;
        setContact(newArray);
      }
    });
  };

  const changeName = (id, index) => {
    contact.map((x, i) => {
      if (index === i) {
        let newArray = [...contact];
        newArray[index].name = id;
        setContact(newArray);
      }
    });
  };

  const changeEmail = (id, index) => {
    contact.map((x, i) => {
      if (index === i) {
        let newArray = [...contact];
        newArray[index].email = id;
        setContact(newArray);
      }
    });
  };

  const changePhone = (id, index) => {
    contact.map((x, i) => {
      if (index === i) {
        let newArray = [...contact];
        newArray[index].phone_number = id;
        setContact(newArray);
      }
    });
  };
  const changeDesc = (id, index) => {
    contact.map((x, i) => {
      if (index === i) {
        let newArray = [...contact];
        newArray[index].job_position = id;
        setContact(newArray);
      }
    });
  };

  const changeBvn = (id, index) => {
    contact.map((x, i) => {
      if (index === i) {
        let newArray = [...contact];
        newArray[index].bvn_number = id;
        setContact(newArray);
      }
    });
  };

  const changeWork = (id, index) => {
    contact.map((x, i) => {
      if (index === i) {
        let newArray = [...contact];
        newArray[index].working_at_id = id;
        setContact(newArray);
      }
    });
  };

  const [contact, setContact] = useState([
    {
      id: 0,
      name: "",
      phone_number: "",
      email: "",
      job_position: "",
      working_at_id: "",
      bvn_number: "",
    },
  ]);

  const handleTeamMemberImageChage = (event) => {
    const access_token = getAccessToken();
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setfile(reader.result);
      });
      reader.readAsDataURL(event.target.files[0]);
      let data1 = new FormData();
      data1.append("user_id", parseInt(localStorage.getItem("userId")));
      data1.append("access_token", access_token);
      data1.append("image", event.target.files[0]);
      data1.append("contact_image", filesend);
      axios
        .post(`${process.env.REACT_APP_URL}/api/v1/image/upload`, data1, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then((response1) => {
          grtListOfDocuments();
        })
        .catch((error) => {});
    }
  };

  const handleTeamMemberImageChage2 = (event) => {
    const access_token = getAccessToken();
    if (event.target.files && event.target.files[0]) {
      setfilesend(event.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setfile2(reader.result);
        setfileView2(reader.result);
      });
      reader.readAsDataURL(event.target.files[0]);
    }
  };
  const handleTeamMemberImageChage3 = (event) => {
    const access_token = getAccessToken();
    if (event.target.files && event.target.files[0]) {
      let fileext = event.target.files[0];
      if (fileext.name.match(/\.(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/)) {
        if (fileext.size > 2000000) {
          setOpen(true);
          setMessage({
            type: "error",
            content: "Image size should be less than 2MB.",
          });
          return;
        }
        setfilesend1(event.target.files[0]);
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setfile(reader.result);
          setfileView(reader.result);
        });
        reader.readAsDataURL(event.target.files[0]);
      } else {
        setLoading(false);
        setOpen(true);
        setMessage({
          type: "error",
          content: "Only jpg, jpeg, PNG, PDF gif files are allowed!",
        });
      }
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const masterFunction = () => {
    const access_token = getAccessToken();

    // const user = jwt.decode(getAccessToken());
    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: access_token,
    };

    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/invite/user/master`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        if (response) {
          setHqList(response.data.working_location);
          setIndustryList(response.data.industry);
          setentityList(response.data.company_entity);
          setempList(response.data.employee_range);
          setDomiciledBankList(response.data.domiciled_banks);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const countryFunction = () => {
    const access_token = getAccessToken();
    const userID = localStorage.getItem("userId");

    const data = {
      user_id: localStorage.getItem("userId"),
      access_token: access_token,
    };

    axios
      .post(
        `${process.env.REACT_APP_URL}/api/v1/country/master`,
        JSON.stringify(data)
      )
      .then((response) => {
        if (response && response.data.sucess) {
          setcountry(response.data.country);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCompanyDetails = () => {
    if (redirect_company_info && redirect_company_info === "true") {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "Please update company information.",
      });
    }

    const access_token = getAccessToken();
    const userID = localStorage.getItem("userId");

    const dataSet = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: access_token,
    };

    const config = {
      method: "post",
      url: process.env.REACT_APP_URL + "/api/v1/company/information/detail",
      headers: {
        "Content-Type": "text/plain",
      },
      data: dataSet,
    };
    axios(config)
      .then((response) => {
        if (localStorage.getItem("user_type") === "both") {
          setSupplier("buyer_supplier");
        } else {
          setSupplier(localStorage.getItem("user_type"));
        }

        if (response && response.data.success === true) {
          if (response.data.trader.image.length > 0) {
            axios
              .get(response.data.trader.image)
              .then((response1) => {})
              .catch((error) => {});
          }

          setcontactId(response.data.contact_list.contact_id);
          setEmployee(response.data.trader.employee_range_id);
          setBusinessAddress(response.data.trader.address);
          setDetails(response.data);
          setName(response.data.trader.name);
          setRegistration(response.data.trader.registration_number);

          setTaxation(response.data.trader.tax_number);
          setEntity(response.data.trader.entity_id);
          setDomiciledBank(response.data.trader.default_domiciled_bank.id);

          if (response.data.trader.industry_id.length > 0) {
            let arr = [];
            arr.push(response.data.trader.industry_id[0].id);
            setIndustry(arr);
          }

          // setType(response.data.user_type)
          setBusinessCity(response.data.trader.city);
          setLimit(response.data.trader.financial_limit);
          setTurnover(response.data.trader.financial_turnover);
          setfile(response.data.trader.image);
          /*let res;
          res = response.data.trader.image.split("/");*/

          if (
            response.data.correspondence_address_list.length > 0 &&
            response.data.correspondence_address_list !== undefined
          ) {
            let last = response.data.correspondence_address_list.length - 1;

            setCorrespondanceAddress(
              response.data.correspondence_address_list[0].address
            );

            setCorrespondanceCountry(
              response.data.correspondence_address_list[0].country_id
            );
            setCorrespondancCity(
              response.data.correspondence_address_list[0].city
            );

            fetchState1(
              response.data.correspondence_address_list[0].country_id,
              "allstate2",
              response.data.correspondence_address_list[0].state_id
            );
          }

          if (response.data.contact_list.length > 0) {
            let last = response.data.contact_list.length - 1;

            setContact(response.data.contact_list);
            setContactName(response.data.contact_list[0].name);
            setConatctBVN(response.data.contact_list[0].bvn_number);
            setContactWork(response.data.contact_list[0].working_at_id);
            setContactPhone(response.data.contact_list[0].mobile);
            setContactDeg(response.data.contact_list[0].job_position);
            contactSetEmail(response.data.contact_list[0].email);
            setContactId(response.data.contact_list[0].contact_id);
            setContactCountry(response.data.contact_list[0].country_id);
            setfile2(response.data.contact_list[0].image);
          }

          if (response.data.trader.country_id != false) {
            setBusinessCountry(response.data.trader.country_id);

            fetchState1(
              response.data.trader.country_id,
              "allstate1",
              response.data.trader.state_id
            );
          }
          setLoader(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const fetchState1 = (country_id, data1, state) => {
    const dataSet = JSON.stringify({
      country_id: country_id,
    });
    const config = {
      method: "post",
      url: process.env.REACT_APP_URL + "/api/v1/state/master",
      headers: {
        "Content-Type": "text/plain",
      },
      data: dataSet,
    };
    axios(config)
      .then((response) => {
        if (data1 == "allstate1") {
          setAllState1(response.data.state);
          setBusinessState(state);
        }
        if (data1 == "allstate2") {
          setAllState2(response.data.state);
          setCorrespondanceState(state);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSubmit = () => {
    const access_token = getAccessToken();

    if (
      name.trim() === "" ||
      registration.trim() === "" ||
      taxation === "" ||
      entity === "" ||
      entity === false ||
      industry === "" ||
      industry === false ||
      businessaddress.trim() === "" ||
      businesscountry === "" ||
      businesscountry === false ||
      businessstate === "" ||
      businessstate === false ||
      businesscity.trim() === "" ||
      correspondanceaddress.trim() === "" ||
      correspondancecountry === "" ||
      correspondancestate === "" ||
      correspondancecountry === false ||
      correspondancestate === false ||
      correspondancecity.trim() === "" ||
      turnover === "" ||
      limit === "" ||
      employee === "" ||
      employee === false
    ) {
      setLoading(false);
      setOpen(true);
      setMessage({ type: "error", content: "All fields are mandatory." });
      return;
    }
    /*if ( isNaN(turnover) ) {
      setOpen(true)
      setMessage({ type: "error", content: "Turnover field can only be a number." })
      return
    }
    if ( isNaN(limit) ) {
      setOpen(true)
      setMessage({ type: "error", content: "Limit field can only be a number." })
      return
    }*/

    let financial_turnover = turnover.toString().replace(/,/g, "");
    let financial_limit = limit.toString().replace(/,/g, "");

    const dataSet = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: access_token,
      user_type: suppliers,
      trader_list: [
        {
          id: parseInt(details.trader.trader_id ? details.trader.trader_id : 0),
          tax_number: taxation,
          name: name.trim(),
          mobile: "",
          registration_number: registration.trim(),
          entity_id: parseInt(entity ? entity : 0),
          industry_id: industry,
          address: businessaddress.trim(),
          city: businesscity.trim(),
          state_id: parseInt(businessstate ? businessstate : 0),
          country_id: parseInt(businesscountry ? businesscountry : 0),
          financial_turnover: financial_turnover,
          financial_limit: financial_limit,
          employee_range_id: parseInt(employee ? employee : 0),
          domiciled_bank: parseInt(domiciled_bank ? domiciled_bank : 0),
        },
      ],
      correspondence_address_list: [
        {
          id: parseInt(correspondanceId ? correspondanceId : 0),
          address: correspondanceaddress.trim(),
          city: correspondancecity.trim(),
          state_id: parseInt(correspondancestate ? correspondancestate : 0),
          country_id: parseInt(
            correspondancecountry ? correspondancecountry : 0
          ),
        },
      ],
      contact_list: [
        {
          id: parseInt(contactID ? contactID : 0),
          name: contactName.trim(),
          phone_number: contactPhone.trim(),
          job_position: contactDeg.trim(),
          working_at_id: parseInt(contactWork ? contactWork : 0),
          bvn_number: contactBVN.trim(),
          image: "",
        },
      ],
    };
    setLoader(false);
    const config = {
      method: "post",
      url: process.env.REACT_APP_URL + "/api/v1/company/information/update",
      headers: {
        "Content-Type": "text/plain",
      },
      data: dataSet,
    };
    axios(config)
      .then((response) => {
        if (response && response.data) {
          if (response.data.success) {
            let data1 = new FormData();
            data1.append("user_id", parseInt(localStorage.getItem("userId")));
            data1.append("access_token", access_token);
            data1.append("image", filesend1);
            data1.append("contact_image", filesend);
            axios
              .post(`${process.env.REACT_APP_URL}/api/v1/image/upload`, data1, {
                headers: {
                  "Content-Type": "text/plain",
                },
              })
              .then((response1) => {
                setLoading(false);
                setOpen(true);
                setMessage({
                  type: "success",
                  content: "Company information updated successfully.",
                });
                localStorage.setItem("redirect_company_info", false);
                setTimeout(() => {
                  history.push("/viewCompany");
                }, 3000);
              })
              .catch((error) => {});
          } else {
            setLoading(false);
            setOpen(true);
            setLoader(true);
            setMessage({ type: "error", content: response.data.message });
          }
        } else {
          setLoader(true);
        }
      })
      .catch((error) => {});
  };

  const handlePreview = (image) => {
    setToDisplay(image);
    setOpen1(true);
  };

  const fetchState = (country_id, data1) => {
    const dataSet = {
      country_id: parseInt(country_id),
    };
    const config = {
      method: "post",
      url: process.env.REACT_APP_URL + "/api/v1/state/master",
      headers: {
        "Content-Type": "text/plain",
      },
      data: dataSet,
    };
    axios(config)
      .then((response) => {
        if (data1 == "allstate1") {
          setAllState1(response.data.state);
        }
        if (data1 == "allstate2") {
          setAllState2(response.data.state);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const removeUserImage = () => {
    setfile2("");
    setfilesend1("");
  };
  const removeCompaanyImage = () => {
    setfile("");
    setfileView("");
    const access_token = getAccessToken();

    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: access_token,
      company_id: parseInt(
        details.trader.trader_id ? details.trader.trader_id : 0
      ),
      contact_id: 0,
    };

    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/image/delete`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        console.log("response", response);
        if (response.data.success) {
          setLoading(false);
          setOpen(true);
          setMessage({
            type: "success",
            content: response.data.message,
          });
        } else {
          setLoading(false);
          setOpen(true);
          setMessage({
            type: "error",
            content: response.data.message,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleTurnover = (e) => {
    const re = /^(\d+(\.\d{0,2})|\.?\d{1,9})$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setTurnover(e.target.value);
    }
  };
  const handleLimit = (e) => {
    const re = /^(\d+(\.\d{0,2})|\.?\d{1,9})$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setLimit(e.target.value);
    }
  };
  const formatTurnover = (event) => {
    event.preventDefault();
    let turnOver = turnover.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    setTurnover(turnOver);
  };
  const formatLimit = (event) => {
    event.preventDefault();
    let Limit = limit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    setLimit(Limit);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    countryFunction();
    getCompanyDetails();
    masterFunction();
  }, []);
  const activity = { setOpen1 };
  const { classes } = props;

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}>
        <SnackbarContent
          className={
            message.type === "error"
              ? "snack-error"
              : message.type === "success"
              ? "snack-success"
              : "snack-teal"
          }
          message={message.content}
        />
      </Snackbar>
      {loader !== true ? (
        <Grid container spacing={3} className="bootstrap-loader">
          <Spinner animation="grow" variant="spinner1" />
          <Spinner animation="grow" variant="spinner2" />
          <Spinner animation="grow" variant="spinner3" />
        </Grid>
      ) : (
        <>
          <Box>
            <Box>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} xl={12}>
                  <Typography className="listing-main-title">
                    Update Company Information
                  </Typography>
                </Grid>
              </Grid>
              <Typography className="company-title2">
                {" "}
                Company Information{" "}
              </Typography>
              <Grid container spacing={2} className="form-style">
                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                  <center>
                    <Box className="image-box">
                      {fileView ? (
                        <img className="img-thumb" src={fileView} />
                      ) : (
                        <>
                          {file ? (
                            <img
                              className="img-thumb"
                              src={`${api_url + file}?${Date.now()}`}
                            />
                          ) : (
                            <img className="img-thumb" src={preview} />
                          )}
                        </>
                      )}
                      {!file && (
                        <Box className="img-action-option edit">
                          <center>
                            <label>
                              <EditOutlinedIcon className="img-edit" />
                              <input
                                type="file"
                                hidden
                                onChange={(e) => handleTeamMemberImageChage3(e)}
                              />
                            </label>
                          </center>
                        </Box>
                      )}
                      {file && (
                        <Box className="img-action-option delete">
                          <center>
                            <DeleteOutlineIcon
                              className="img-delete"
                              onClick={removeCompaanyImage}
                            />
                          </center>
                        </Box>
                      )}
                    </Box>
                  </center>
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                  <Typography className="img-heading">
                    Upload Company Logo
                  </Typography>
                  <Typography className="img-subheading">
                    In PNG, PDF JPG, GIF, and SVG format size should be less
                    than 2MB*
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="input-box">
                    <span className="company-title3">
                      Company's Name<span className="required">*</span>
                    </span>
                    <OutlinedInput
                      className="company-title5"
                      fullWidth
                      placeholder=" Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="input-box">
                    <span className="company-title3">
                      Registration number<span className="required">*</span>
                    </span>
                    <OutlinedInput
                      className="company-title5"
                      fullWidth
                      readOnly
                      placeholder="e.g. NCR1234"
                      value={registration}
                      //onChange={(e) => setRegistration(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="input-box">
                    <span className="company-title3">
                      User Type<span className="required">*</span>
                    </span>
                    <FormControl fullWidth>
                      <Select
                        displayEmpty
                        value={suppliers}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Age"
                        readOnly
                        //onChange={(e) => setSupplier(e.target.value)}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}>
                        <MenuItem value="" disabled>
                          Select from options
                        </MenuItem>
                        <MenuItem value="buyer">Buyer</MenuItem>
                        <MenuItem value="buyer_supplier">
                          Buyer-supplier
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="input-box">
                    <span className="company-title3">
                      Taxation Number<span className="required">*</span>
                    </span>
                    <OutlinedInput
                      className="company-title5"
                      fullWidth
                      readOnly
                      placeholder=" e.g. TXN567890 "
                      value={taxation}
                      //onChange={(e) => setTaxation(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="input-box">
                    <span className="company-title3">
                      Company Entity<span className="required">*</span>
                    </span>
                    <FormControl fullWidth>
                      <Select
                        displayEmpty
                        className="company-title4"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select2"
                        placeholder="Select from Option"
                        value={entity}
                        onChange={(e) => setEntity(e.target.value)}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}>
                        <MenuItem value="" disabled={true}>
                          Select from options
                        </MenuItem>

                        {entityList != undefined &&
                          entityList.map((item, index) => {
                            return (
                              <MenuItem value={item.id}>{item.name}</MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="input-box">
                    <span className="company-title3">
                      Industry<span className="required">*</span>
                    </span>
                    <FormControl fullWidth>
                      <Select
                        displayEmpty
                        className="company-title4"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={industry.length != 0 ? industry[0] : 0}
                        onChange={(e) => {
                          let a = [];
                          a.push(parseInt(e.target.value));
                          setIndustry(a);
                        }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}>
                        <MenuItem value="" disabled={true}>
                          Select from options
                        </MenuItem>

                        {indusrtyList &&
                          indusrtyList.map((item) => (
                            <MenuItem value={item.id} name={item.state_id}>
                              {item.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="input-box">
                    <span className="company-title3">
                      Default Domiciled Bank<span className="required">*</span>
                    </span>
                    <FormControl fullWidth>
                      <Select
                        displayEmpty
                        className="company-title4"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select2"
                        placeholder="Select from Option"
                        value={domiciled_bank}
                        onChange={(e) => setDomiciledBank(e.target.value)}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}>
                        <MenuItem value="" disabled={true}>
                          Select from options
                        </MenuItem>

                        {domiciledBankList != undefined &&
                          domiciledBankList.map((item, index) => {
                            return (
                              <MenuItem value={item.id}>{item.name}</MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="input-box">
                    <span className="company-title3">
                      Number of employees in correspondance
                      <span className="required">*</span>
                    </span>
                    <FormControl fullWidth>
                      <Select
                        displayEmpty
                        className="company-title4"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={employee}
                        onChange={(e) => setEmployee(e.target.value)}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}>
                        <MenuItem value="" disabled={true}>
                          Select from options
                        </MenuItem>

                        {empList !== "" && empList !== undefined ? (
                          empList.map((item) => (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          ))
                        ) : (
                          <></>
                        )}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
              </Grid>

              <Typography className="company-title2">
                {" "}
                Business Information{" "}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} xl={12}>
                  <Box>
                    <span className="company-title3">
                      Address<span className="required">*</span>
                    </span>
                    <OutlinedInput
                      className="company-title5"
                      fullWidth
                      placeholder="Select from option"
                      value={businessaddress}
                      readOnly
                      //onChange={(e) => setBusinessAddress(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="input-box">
                    <span className="company-title3">
                      Country<span className="required">*</span>
                    </span>
                    <FormControl fullWidth>
                      <Select
                        value={businesscountry}
                        readOnly
                        onChange={(e) => {
                          setBusinessCountry(e.target.value);
                          const data = e.currentTarget.getAttribute("name");
                          fetchState(e.target.value, "allstate1");
                        }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}>
                        <MenuItem value={""}>Select from options</MenuItem>
                        {allcountry !== "" ? (
                          allcountry.map((item, index) => (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          ))
                        ) : (
                          <></>
                        )}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="input-box">
                    <span className="company-title3">
                      State<span className="required">*</span>
                    </span>
                    <FormControl fullWidth>
                      <Select
                        displayEmpty
                        className="company-title4"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={businessstate}
                        onChange={(e) => {
                          setBusinessState(e.target.value);
                        }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}>
                        <MenuItem value="" disabled={true}>
                          Select from options
                        </MenuItem>

                        {allState1 &&
                          allState1.map((item) => (
                            <MenuItem value={item.id} name={item.state_id}>
                              {item.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="input-box">
                    <span className="company-title3">
                      City<span className="required">*</span>
                    </span>
                    <OutlinedInput
                      className="company-title4"
                      fullWidth
                      varient="filled"
                      placeholder="Select from option"
                      onChange={(e) => setBusinessCity(e.target.value)}
                      value={businesscity}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Typography className="company-title2">
                {" "}
                Correspondence Address{" "}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} xl={12}>
                  <Box>
                    <span className="company-title3">
                      Address<span className="required">*</span>
                    </span>
                    <OutlinedInput
                      className="company-title5"
                      fullWidth
                      placeholder="Select from option"
                      value={correspondanceaddress}
                      onChange={(e) => setCorrespondanceAddress(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="input-box">
                    <span className="company-title3">
                      Country<span className="required">*</span>
                    </span>
                    <FormControl fullWidth>
                      <Select
                        displayEmpty
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={correspondancecountry}
                        onChange={(e) => {
                          setCorrespondanceCountry(e.target.value);
                          fetchState(e.target.value, "allstate2");
                        }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}>
                        <MenuItem value={""}>Select from options</MenuItem>
                        {allcountry !== "" ? (
                          allcountry.map((item, index) => (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          ))
                        ) : (
                          <></>
                        )}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="input-box">
                    <span className="company-title3">
                      State<span className="required">*</span>
                    </span>
                    <FormControl fullWidth>
                      <Select
                        displayEmpty
                        className="company-title4"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={correspondancestate}
                        onChange={(e) => setCorrespondanceState(e.target.value)}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}>
                        <MenuItem value="" disabled>
                          Select from option
                        </MenuItem>
                        {allState2 &&
                          allState2.map((item) => (
                            <MenuItem value={item.id} name={item.state_id}>
                              {item.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="input-box">
                    <span className="company-title3">
                      City<span className="required">*</span>
                    </span>
                    <OutlinedInput
                      className="company-title5"
                      fullWidth
                      varient="filled"
                      placeholder="City"
                      value={correspondancecity}
                      onChange={(e) => setCorrespondancCity(e.target.value)}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Typography className="company-title2">
                {" "}
                Financial Information
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="input-box">
                    <span className="company-title3">
                      Turnover ({currency_symbol})
                      <span className="required">*</span>
                    </span>
                    {/*<OutlinedInput
                  className="company-title4"
                  fullWidth
                  placeholder="Enter Amount"
                  value={turnover.toLocaleString(undefined, {minimumFractionDigits:2})}
                  onChange={(e) => handleTurnover(e)}
                  onBlur={(e) => formatTurnover(e)}
                />*/}
                    <span className="MuiInputBase-root MuiOutlinedInput-root company-title5 MuiInputBase-fullWidth">
                      <NumberFormat
                        value={turnover}
                        thousandSeparator={true}
                        thousandsGroupStyle="thousand"
                        allowNegative={false}
                        decimalSeparator="."
                        decimalScale={2}
                        className="MuiInputBase-input MuiOutlinedInput-input"
                        fixedDecimalScale={true}
                        onChange={(e) => setTurnover(e.target.value)}
                      />
                    </span>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="input-box">
                    <span className="company-title3">
                      Limit ({currency_symbol})
                      <span className="required">*</span>
                    </span>
                    {/* <OutlinedInput
                  className="company-title5"
                  fullWidth
                  placeholder="e.g."
                  value={limit.toLocaleString(undefined, {minimumFractionDigits:2})}
                  onChange={(e) => handleLimit(e)}
                  onBlur={(e) => formatLimit(e)}
                />*/}
                    <span className="MuiInputBase-root MuiOutlinedInput-root company-title5 MuiInputBase-fullWidth">
                      <NumberFormat
                        value={limit}
                        thousandSeparator={true}
                        thousandsGroupStyle="thousand"
                        allowNegative={false}
                        decimalSeparator="."
                        decimalScale={2}
                        className="MuiInputBase-input MuiOutlinedInput-input"
                        fixedDecimalScale={true}
                        onChange={(e) => setLimit(e.target.value)}
                      />
                    </span>
                  </Box>
                </Grid>
              </Grid>

              <Grid container spacing={3} className="button-grid">
                <Grid item xs={6} sm={4} md={2} xl={2}>
                  <Button
                    className="save"
                    onClick={onSubmit}
                    disabled={
                      !name ||
                      !registration ||
                      !taxation ||
                      !entity ||
                      !industry ||
                      !domiciled_bank ||
                      !businessaddress ||
                      !businesscountry ||
                      !businessstate ||
                      !businesscity ||
                      !correspondanceaddress ||
                      !correspondancecountry ||
                      !correspondancestate ||
                      !correspondancecity ||
                      !turnover ||
                      !limit ||
                      !employee
                    }>
                    Save
                  </Button>
                </Grid>
                <Grid item xs={6} sm={4} md={2} xl={2}>
                  <Link to="/viewCompany">
                    <Button className="cancel">Cancel</Button>
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}

UpdateInfo.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(UpdateInfo);
