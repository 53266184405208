import React from "react"
import HomePage from 'views/HomePage/HomePage';
import ContactUs from 'views/AboutUs/ContactUs';
import OurPresence from 'views/AboutUs/OurPresence';
import BottomComponent from "views/Components/HomePageComponents/BottomComponent";
import HomePage5 from "views/HomePage/HomePage5";
import SignupFooter from "views/Signup/footer";
import { isMobile } from "react-device-detect";

export default function MainContactUs() {
    return (
        <>
            <HomePage />
            <ContactUs />
            <OurPresence />
            <BottomComponent info={data.registration_widget} />
            <HomePage5 />
            {isMobile ? " " : <SignupFooter />}
        </>
    );
}