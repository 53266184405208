import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Box,
  Typography,
  OutlinedInput,
  MenuItem,
  FormControl,
  Select,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Snackbar,
  SnackbarContent,
} from "@material-ui/core";
import CustomInvoice from "views/Components/CustomInvoice";
import TableComponent from "views/Components/tableComponent/tablecomponent";
import ThemeContainer from "views/Components/sideBar/ThemeContainer";
import { isMobile } from "react-device-detect";
import { getAccessToken } from "../../../../utilities/tokenUtil";
import TextField from "@mui/material/TextField";
import EditOutlinedIcon from "@material-ui/icons/BorderColor";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { Link, useHistory } from "react-router-dom";
import { Row, Col, Spinner } from "react-bootstrap";
import Stack from "@mui/material/Stack";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import WebRedirectPaymentMethod from "views/Components/webRedirectPaymentMethod";

var axios = require("axios");

export default function AddInvoice(props) {
  const trader_type = localStorage.getItem("trader_type");
  const userId = localStorage.getItem("userId");
  const userName = localStorage.getItem("userName");
  const kyc_status = localStorage.getItem("kyc_status");
  const user_role = localStorage.getItem("user_role");

  const history = useHistory();
  const api_url = process.env.REACT_APP_URL;
  const [addInvoices, setAddInvoices] = useState(true);
  const [startDate, setstartdate] = useState("");
  const [endDate, setendDate] = useState("");
  const [buyerName, setbuyerName] = useState("");
  const [invoiceNoSearch, setinvoiceNoSearch] = useState("");
  const [searchStatus, setsearchStatus] = useState("");
  const [InvoiceDocuments, setInvoiceDocuments] = useState([]);
  const [invoiceList, setinvoiceList] = useState([]);
  const [file, setfile] = useState();
  const [serverImage, setserverImage] = useState("");
  const [invoiceId, setinvoiceId] = useState(0);
  const [buyerListing, setbuyerListing] = useState([]);
  const [creditOfferListing, setCreditOfferListing] = useState([]);
  const [buyerId, setbuyerId] = useState("");
  const [creditOfferId, setCreditOfferId] = useState("");
  const [docId, setdocId] = useState("");
  const [serverDocId, setserverDocId] = useState("");
  const [editeddocId, setediteddocId] = useState("");
  const [lenderId, setlenderId] = useState("");
  const [buyerLocationId, setbuyerLocationId] = useState("");
  const [purchaseOrderNO, setpurchaseOrderNO] = useState("");
  const [purchaseOrderDate, setpurchaseOrderDate] = useState(null);
  const [invoiceNo, setinvoiceNo] = useState("");
  const [invoiceammount, setinvoiceammount] = useState("");
  const [invoiceDate, setinvoiceDate] = useState(null);
  const [dueDate, setdueDate] = useState(null);
  const [creditDays, setcreditDays] = useState("");
  const [amountRequired, setamountRequired] = useState("");
  const [logistic, setlogistic] = useState("");
  const [locationId, setlocationId] = useState("");
  const [deliveryChallan, setdeliveryChallan] = useState("");
  const [deliveryChallanDate, setdeliveryChallanDate] = useState("");
  const [goodReceivingNote, setgoodReceivingNote] = useState("");
  const [grnDate, setgrnDate] = useState("");
  const [grnNo, setgrnNo] = useState("");
  const [receivedBy, setreceivedBy] = useState("");
  const [remark, setremark] = useState("");
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState({});
  const [lenderName, setLenderName] = useState("");
  const [currency_symbol, setCurrencySymbol] = useState("");
  const [lenderID, setLenderID] = useState();
  const [open, setOpen] = useState(false);
  const documentRef = useRef([]);
  const [loader, setLoader] = useState(false);
  const [paymentMasterData, setPaymentMasterData] = useState("");
  const [openPaymentModel, setOpenPaymentModel] = useState(false);

  documentRef.current = [];

  if (!userId || userName === null) {
    history.push("/signin");
  }
  if (kyc_status !== "Approved") {
    history.push("/kyc-page");
  }
  if (trader_type !== "supplier") {
    history.push("/invoice");
  }

  const addToRefs = (el) => {
    if (el && !documentRef.current.includes(el)) documentRef.current.push(el);
  };

  const deleteUpload = (event, document_id) => {
    const access_token = getAccessToken();
    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: access_token,
      invoice_id: invoiceId,
      document_id: document_id,
    };
    const config = {
      method: "post",
      url: process.env.REACT_APP_URL + "/api/v1/invoice/document/delete ",
      headers: {
        "Content-Type": "text/plain",
      },
      data: data,
    };
    axios(config)
      .then((response) => {
        if (response && response.data) {
          if (response.data.success === true) {
            setMessage(response.data.message);
            return invoiceDetail();
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function handleState() {
    setAddInvoices(!addInvoices);
  }

  const editUpload = (event, item) => {
    let fileext = event.target.files[0];
    if (fileext.name.match(/\.(jpg|jpeg|png|pdf|JPG|JPEG|PNG|PDF)$/)) {
      const access_token = getAccessToken();
      const reader = new FileReader();
      setfile(event.target.files[0]);
      reader.readAsDataURL(event.target.files[0]);

      let data1 = new FormData();
      data1.append("user_id", parseInt(localStorage.getItem("userId")));
      data1.append("access_token", access_token);
      data1.append("document_id", editeddocId);
      data1.append("document_type_id", item.id);
      data1.append("document_name", item.name);
      data1.append("document_data", event.target.files[0]);
      data1.append("invoice_id", invoiceId);
      axios
        .post(
          `${process.env.REACT_APP_URL}/api/v1/invoice/document/upload`,
          data1,
          {
            headers: {
              "Content-Type": "text/plain",
            },
          }
        )
        .then((response) => {
          if (response.data.success == true) {
            invoiceDetail();
          }
        })
        .catch((error) => {});
    } else {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "Only jpg, jpeg, PNG, PDF pdf files are allowed!",
      });
    }
  };

  const openFileSystem = async (event, ref) => {
    if (trader_type === "supplier") {
      if (
        buyerId === "" ||
        creditOfferId == "" ||
        buyerLocationId === "" ||
        purchaseOrderNO === "" ||
        invoiceNo === "" ||
        invoiceammount === "" ||
        !invoiceDate ||
        !dueDate ||
        dueDate == null ||
        purchaseOrderDate == null ||
        !purchaseOrderDate ||
        creditDays === "" ||
        amountRequired === ""
      ) {
        setOpen(true);
        setMessage({ type: "error", content: "All fields are mandatory." });
        return;
      }

      if (isNaN(amountRequired) || isNaN(invoiceammount)) {
        setOpen(true);
        setMessage({
          type: "error",
          content: "Invoice amount & amount required field can only be number.",
        });
        return;
      }
      if (parseInt(amountRequired) >= parseInt(invoiceammount)) {
        setOpen(true);
        setMessage({
          type: "error",
          content: "Amount require should be less than invoice amount.",
        });
        return;
      }
    }
    if (trader_type === "buyer") {
      if (
        logistic === "" ||
        locationId === "" ||
        deliveryChallan === "" ||
        deliveryChallanDate === "" ||
        goodReceivingNote === "" ||
        grnNo === "" ||
        grnDate === "" ||
        receivedBy === "" ||
        remark === ""
      ) {
        setOpen(true);
        setMessage({ type: "error", content: "All fields are mandatory." });
        return;
      }
    }

    await createInvoiceApi();
    ref.click();
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const filter = () => {
    const access_token = getAccessToken();
    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: access_token,
      status: searchStatus,
      invoice_no: invoiceNoSearch,
      filed_from: startDate,
      filed_to: endDate,
      buyer_name: buyerName,
      supplier_name: "",
      page_no: 1,
    };
    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/invoice/list/search`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setinvoiceList(response.data.invoice_list);
        } else {
          setOpen(true);
          setMessage({ type: "error", content: response.data.message });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const function_Second = (event, item) => {
    let fileext = event.target.files[0];
    if (fileext.name.match(/\.(jpg|jpeg|png|pdf|JPG|JPEG|PNG|PDF)$/)) {
      const access_token = getAccessToken();
      const reader = new FileReader();
      reader.addEventListener("load", () => {});
      setfile(event.target.files[0]);
      reader.readAsDataURL(event.target.files[0]);

      const data11 = {
        user_id: parseInt(localStorage.getItem("userId")),
        access_token: access_token,
        document_type_id: item.id,
        invoice_id: invoiceId,
      };

      axios
        .post(
          `${process.env.REACT_APP_URL}/api/v1/invoice/document/id`,
          data11,
          {
            headers: {
              "Content-Type": "text/plain",
            },
          }
        )
        .then((response) => {
          if (response.data.success == true) {
            setserverDocId(response.data.document_id);
            setediteddocId(response.data.document_id);
            const data0 = {
              user_id: parseInt(localStorage.getItem("userId")),
              document_type_id: item.id,
              access_token: access_token,
              invoice_id: invoiceId,
              document_name: item.name,
              Document_id: serverDocId,
              document_data: event.target.files[0],
            };

            let data1 = new FormData();
            data1.append("user_id", parseInt(localStorage.getItem("userId")));
            data1.append("access_token", access_token);
            data1.append("document_id", response.data.document_id);
            data1.append("document_type_id", item.id);
            data1.append("document_name", item.name);
            data1.append("document_data", event.target.files[0]);
            data1.append("invoice_id", invoiceId);
            axios
              .post(
                `${process.env.REACT_APP_URL}/api/v1/invoice/document/upload`,
                data1,
                {
                  headers: {
                    "Content-Type": "text/plain",
                  },
                }
              )
              .then((response) => {
                if (response.data.success == true) {
                  invoiceDetail();
                }
              })
              .catch((error) => {});
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "Only jpg, jpeg, PNG, PDF pdf files are allowed!",
      });
    }
  };

  const invoiceDetail = () => {
    const access_token = getAccessToken();
    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: access_token,
      invoice_id: invoiceId,
    };

    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/invoice/detail`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        if (response) {
          setInvoiceDocuments(response.data.documents);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const createInvoiceApi = () => {
    var buyer_Id;
    buyerListing.map((item) => {
      if (item.buyer_id[1] == buyerId) {
        buyer_Id = item.buyer_id[0];
      }
    });
    const access_token = getAccessToken();

    const data = {
      id: invoiceId,
      access_token: access_token,
      user_id: parseInt(localStorage.getItem("userId")),
      buyer_id: buyer_Id,
      credit_offer_id: creditOfferId,
      lender_id: lenderId,
      buyer_location_id: buyerLocationId.trim(),
      purchase_order_no: purchaseOrderNO.trim(),
      purchase_order_date: purchaseOrderDate,
      invoice_no: invoiceNo.trim(),
      invoice_date: invoiceDate,
      invoice_amount: invoiceammount,
      due_date: dueDate,
      credit_days: creditDays,
      amount_required: amountRequired,
      logistics: logistic,
      location_id: locationId,
      delivery_challan: deliveryChallan,
      delivery_challan_date: deliveryChallanDate,
      good_receiving_note: goodReceivingNote,
      grn_date: grnDate,
      grn_no: grnNo,
      received_by: receivedBy,
      remark: remark,
    };

    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/invoice/create`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        if (response.data.success == true) {
          setinvoiceId(response.data.invoice_id);
        } else {
          setLoading(false);
          setOpen(true);
          setMessage({ type: "error", content: response.data.message });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const invoiceMaster = () => {
    setLoader(false);
    const access_token = getAccessToken();
    let traderType = trader_type === "buyer" ? "supplier" : "buyer";
    const data = {
      access_token: access_token,
      user_id: parseInt(localStorage.getItem("userId")),
      user_type: traderType,
    };
    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/invoice/master`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        setdocId(response.data.document);
        setbuyerListing(response.data.buyer);
        setlenderId(response.data.lender_id);
        setLenderName(response.data.lender);
        setCurrencySymbol(response.data.currency_symbol);
        setLoader(true);
        /*if (response.data.lender === "") {

                    setLoading(false);
                    setOpen(true);
                    setMessage({ type: "error", content: "No active offer found, please apply/track credit request." });
                    setTimeout(() => {
                        history.push('/credit')
                    }, 3000)
                } else {
                    setLoader(true)
                }*/
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getInvoiceList = () => {
    setLoader(false);
    const access_token = getAccessToken();
    let traderType = trader_type === "buyer" ? "supplier" : "buyer";
    const data = {
      access_token: access_token,
      user_id: parseInt(localStorage.getItem("userId")),
      user_type: traderType,
      page_no: 1,
    };
    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/invoice/list`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        if (response.data.success == true) {
          setinvoiceList(response.data.invoice_list);
          setLoader(true);
        } else {
          setLoader(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const viewInvoice = (item) => {
    history.push("/displayInvoiceList", item);
  };

  const clearSearch = () => {
    setstartdate("");
    setendDate("");
    setbuyerName("");
    setinvoiceNoSearch("");
    setsearchStatus("");
    getInvoiceList();
  };

  const saveForm = () => {
    if (trader_type === "supplier") {
      if (
        buyerId === "" ||
        creditOfferId == "" ||
        buyerLocationId.trim() === "" ||
        purchaseOrderNO.trim() === "" ||
        invoiceNo.trim() === "" ||
        invoiceammount === "" ||
        invoiceDate === "" ||
        dueDate === "" ||
        dueDate === null ||
        purchaseOrderDate === "" ||
        purchaseOrderDate === null ||
        creditDays === "" ||
        amountRequired === ""
      ) {
        setOpen(true);
        setMessage({ type: "error", content: "All fields are mandatory." });
        return;
      }
      if (isNaN(amountRequired) || isNaN(invoiceammount)) {
        setOpen(true);
        setMessage({
          type: "error",
          content: "Invoice amount & amount required field can only be number.",
        });
        return;
      }
      if (parseInt(amountRequired) >= parseInt(invoiceammount)) {
        setOpen(true);
        setMessage({
          type: "error",
          content: "Amount require can't be greater than invoice amount.",
        });
        return;
      }
    }

    if (trader_type === "buyer") {
      if (
        logistic === "" ||
        locationId === "" ||
        deliveryChallan === "" ||
        deliveryChallanDate === "" ||
        goodReceivingNote === "" ||
        grnNo === "" ||
        grnDate === "" ||
        receivedBy === "" ||
        remark === ""
      ) {
        setOpen(true);
        setMessage({ type: "error", content: "All fields are mandatory." });
        return;
      }
    }

    var a = new Set([]);
    docId.map((item) => {
      if (item.is_required === true) {
        InvoiceDocuments.map((x) => {
          x.doc_list.map((y) => {
            if (y.document_type_id === item.id) {
              a.add(y.document_type_id);
            }
          });
        });
      }
    });

    let array = Array.from(a);

    let req = [];
    docId.map((item) => {
      if (item.is_required === true) {
        req.push(item.id);
      }
    });
    if (
      Array.isArray(req) &&
      Array.isArray(array) &&
      req.length === array.length &&
      req.every((val, index) => val === array[index])
    ) {
      setLoader(false);
      setLoading(false);
      setOpen(true);
      setMessage({ type: "success", content: "Invoice created successfully." });
      setTimeout(() => {
        history.push("/invoice");
      }, 3000);
      setLoader(true);
    } else {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "Please upload all mandatory documents.",
      });
      return;
    }
  };

  const submitForm = () => {
    if (trader_type === "supplier") {
      if (
        buyerId === "" ||
        creditOfferId == "" ||
        buyerLocationId.trim() === "" ||
        purchaseOrderNO.trim() === "" ||
        invoiceNo.trim() === "" ||
        invoiceammount === "" ||
        invoiceDate === "" ||
        dueDate === "" ||
        dueDate === null ||
        purchaseOrderDate === "" ||
        purchaseOrderDate === null ||
        creditDays === "" ||
        amountRequired === ""
      ) {
        setOpen(true);
        setMessage({ type: "error", content: "All fields are mandatory." });
        return;
      }
      if (isNaN(amountRequired) || isNaN(invoiceammount)) {
        setOpen(true);
        setMessage({
          type: "error",
          content: "Invoice amount & amount required field can only be number.",
        });
        return;
      }
      if (isNaN(creditDays)) {
        setOpen(true);
        setMessage({
          type: "error",
          content: "Credit days field can only be number.",
        });
        return;
      }
      if (parseInt(amountRequired) >= parseInt(invoiceammount)) {
        setOpen(true);
        setMessage({
          type: "error",
          content: "Amount require should be less than invoice amount.",
        });
        return;
      }
    }

    if (trader_type === "buyer") {
      if (
        logistic === "" ||
        locationId === "" ||
        deliveryChallan === "" ||
        deliveryChallanDate === "" ||
        goodReceivingNote === "" ||
        grnNo === "" ||
        grnDate === "" ||
        receivedBy === "" ||
        remark === ""
      ) {
        setOpen(true);
        setMessage({ type: "error", content: "All fields are mandatory." });
        return;
      }
    }

    var a = new Set([]);
    docId.map((item) => {
      if (item.is_required === true) {
        InvoiceDocuments.map((x) => {
          x.doc_list.map((y) => {
            if (y.document_type_id === item.id) {
              a.add(y.document_type_id);
            }
          });
        });
      }
    });

    let array = Array.from(a);

    let req = [];
    docId.map((item) => {
      if (item.is_required === true) {
        req.push(item.id);
      }
    });

    if (
      Array.isArray(req) &&
      Array.isArray(array) &&
      req.length === array.length &&
      req.every((val, index) => val === array[index])
    ) {
      if (
        paymentMasterData.payment_fee_needed &&
        paymentMasterData.pay_by === "supplier"
      ) {
        setOpenPaymentModel(true);
        localStorage.setItem("invoice_id", invoiceId);
        /*const paymentRequest = {
                        merchant_code: paymentMasterData.merchant_code,          
                        pay_item_id: paymentMasterData.pay_item_id,
                        txn_ref: purchaseOrderNO+'-'+invoiceNo,
                        amount: paymentMasterData.amount, 
                        currency: paymentMasterData.currency,
                        onComplete: paymentCallback,
                        site_redirect_url: process.env.REACT_APP_SITE_REDIRECT_URL,
                        mode: paymentMasterData.mode
                    };
                    window.webpayCheckout(paymentRequest);*/
      } else {
        invoiceUpdateStatus(invoiceId);
      }
    } else {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "Please upload all mandatory documents.",
      });
      return;
    }
  };
  const cancelForm = () => {
    setAddInvoices(!addInvoices);
  };
  const viewInvoiceDetail = (item) => {
    history.push("/ViewInvoiceList", item);
  };

  const invoiceDateChange = (newValue) => {
    var date = new Date(newValue);
    var finaldate =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    setinvoiceDate(finaldate);
  };
  const dueDateChange = (newValue) => {
    var date = new Date(newValue);
    var finaldate =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();

    if (new Date(invoiceDate) <= new Date(finaldate)) {
      setdueDate(finaldate);
    } else {
      setdueDate(null);
      setOpen(true);
      setMessage({
        type: "error",
        content: "Cannot Enter Date Smaller then Invoice Date.",
      });
    }
  };
  const purchaseOrderDateChange = (newValue) => {
    var date = new Date(newValue);
    var finaldate =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    if (new Date(invoiceDate) >= new Date(finaldate)) {
      setpurchaseOrderDate(finaldate);
    } else {
      setpurchaseOrderDate(null);
      setOpen(true);
      setMessage({
        type: "error",
        content: "Cannot Enter Date Smaller then Invoice Date.",
      });
    }
  };

  const getCreditOfferFromBuyer = (e) => {
    if (e.target.value) {
      var buyer_Id;
      buyerListing.map((item) => {
        if (item.buyer_id[1] == e.target.value) {
          buyer_Id = item.buyer_id[0];
        }
      });
      setbuyerId(e.target.value);
      const access_token = getAccessToken();
      const data = {
        access_token: access_token,
        user_id: parseInt(localStorage.getItem("userId")),
        buyer_id: parseInt(buyer_Id),
      };
      axios
        .post(`${process.env.REACT_APP_URL}/api/v1/credit_offer/master`, data, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then((response) => {
          if (response.data.success) {
            setCreditOfferListing(response.data.data);
            setLenderName("");
            setlenderId("");
            setLoading(false);
          } else {
            setLoader(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const getCreditOffer = (e) => {
    if (e.target.value) {
      var lender_name;
      var lender_id;
      creditOfferListing.map((item) => {
        if (item.credit_offer_id == e.target.value) {
          lender_name = item.lender_name;
          lender_id = item.lender_id;
        }
      });
      setCreditOfferId(e.target.value);
      setLenderName(lender_name);
      setlenderId(lender_id);
    }
  };

  const activeCreditOffers = () => {
    const access_token = getAccessToken();
    const data = {
      access_token: access_token,
      user_id: parseInt(localStorage.getItem("userId")),
    };

    axios
      .post(
        `${process.env.REACT_APP_URL}/api/v1/supplier/credit_offer/list`,
        data,
        {
          headers: {
            "Content-Type": "text/plain",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          if (response?.data?.data?.length == 0) {
            setLoader(true);
            setLoading(false);
            setOpen(true);
            setMessage({
              type: "error",
              content:
                "No active offer found, please apply/track credit request.",
            });
            setTimeout(() => {
              history.push("/credit");
            }, 3000);
          } else {
            setLoader(true);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //callback function that gets triggered on payment success or failure
  const paymentCallback = (response) => {
    if (response.amount !== undefined && response.payRef !== undefined) {
      invoiceUpdateStatus(invoiceId);
    }
  };

  const invoiceUpdateStatus = (invoiceId) => {
    const access_token = getAccessToken();
    setLoader(false);
    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: access_token,
      invoice_id: parseInt(invoiceId),
      status: "waiting_for_buyer_approval",
      comment: "",
    };

    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/invoice/update/status`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        if (response.data.message) {
          setLoading(false);
          setOpen(true);
          setMessage({
            type: "success",
            content: "Invoice created & send for buyer approval successfully.",
          });
          setTimeout(() => {
            history.push("/invoice");
          }, 3000);
        } else {
          setLoading(false);
          setOpen(true);
          setMessage({ type: "error", content: response.data.message });
          setLoader(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(true);
      });
  };

  const paymentMaster = () => {
    axios
      .get(`${process.env.REACT_APP_URL}/api/v1/payment/master`, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        if (response.status) {
          setPaymentMasterData(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const modelPaymentClose = () => {
    setOpenPaymentModel(false);
    localStorage.removeItem("invoice_id");
  };

  useEffect(() => {
    document.title = "Interswitch - Add Invoice";
    window.scrollTo(0, 0);
    invoiceMaster();
    getInvoiceList();
    activeCreditOffers();
    paymentMaster();
    // Include payment script library
    const script = document.createElement("script");
    script.src = process.env.REACT_APP_PAYMENT_INLINE_CHECKOUT_URL;
    script.async = true;
    document.body.appendChild(script);
  }, []);
  return (
    <ThemeContainer type="private" value={1}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}>
        <SnackbarContent
          className={
            message.type === "error"
              ? "snack-error"
              : message.type === "success"
              ? "snack-success"
              : "snack-teal"
          }
          message={message.content}
        />
      </Snackbar>

      {loader !== true ? (
        <Grid container spacing={3} className="bootstrap-loader">
          <Spinner animation="grow" variant="spinner1" />
          <Spinner animation="grow" variant="spinner2" />
          <Spinner animation="grow" variant="spinner3" />
        </Grid>
      ) : (
        <>
          <Box className="scf-box">
            <Box className="scf-subbox">
              <Grid container>
                <Grid item xs={12} sm={12} md={12} xl={12}>
                  <Typography className="company-title11">
                    Add New Invoice
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} xl={12}>
                  <Row className="mb-5 mt-3">
                    <Col>
                      <span className="ao-header-box">
                        Lender - {lenderName}
                      </span>
                    </Col>
                  </Row>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="input-box">
                    <span className="company-title3">
                      Buyer Name<span className="required">*</span>
                    </span>
                    <FormControl fullWidth>
                      <Select
                        displayEmpty
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={buyerId}
                        readOnly={trader_type === "buyer" ? true : false}
                        //onChange={(e) => setbuyerId(e.target.value)}
                        onChange={(e) => getCreditOfferFromBuyer(e)}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}>
                        <MenuItem value="" disabled>
                          Select from options
                        </MenuItem>
                        {buyerListing != undefined &&
                          buyerListing.map((item, index1) => {
                            return (
                              <MenuItem value={item.buyer_id[1]} key={index1}>
                                {item.buyer_id[1]} ({item.taxation_no})
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="input-box">
                    <span className="company-title3">
                      Credit Offer<span className="required">*</span>
                    </span>
                    <FormControl fullWidth>
                      <Select
                        displayEmpty
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={creditOfferId}
                        readOnly={trader_type === "buyer" ? true : false}
                        onChange={(e) => getCreditOffer(e)}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}>
                        <MenuItem value="" disabled>
                          Select from options
                        </MenuItem>
                        {creditOfferListing != undefined &&
                          creditOfferListing.map((item, index1) => {
                            return (
                              <MenuItem
                                value={item.credit_offer_id}
                                key={index1}>
                                {item.credit_offer_name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="input-box">
                    <span className="company-title3">
                      Buyer Location<span className="required">*</span>
                    </span>
                    <OutlinedInput
                      className="company-title5"
                      fullWidth
                      placeholder="e.g. Buyer Location"
                      value={buyerLocationId}
                      readOnly={trader_type === "buyer" ? true : false}
                      onChange={(e) => setbuyerLocationId(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="input-box">
                    <span className="company-title3">
                      Purchase Order Number<span className="required">*</span>
                    </span>
                    <OutlinedInput
                      className="company-title5"
                      fullWidth
                      placeholder="e.g. Purchase Order Number"
                      value={purchaseOrderNO}
                      readOnly={trader_type === "buyer" ? true : false}
                      onChange={(e) => setpurchaseOrderNO(e.target.value)}
                      inputProps={{ maxLength: 20 }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="input-box">
                    <span className="company-title3">
                      Invoice Number<span className="required">*</span>
                    </span>
                    <OutlinedInput
                      className="company-title5"
                      fullWidth
                      placeholder="e.g. invoice Number"
                      value={invoiceNo}
                      readOnly={trader_type === "buyer" ? true : false}
                      onChange={(e) => setinvoiceNo(e.target.value)}
                      inputProps={{ maxLength: 20 }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="input-box">
                    <span className="company-title3">
                      Invoice Amount ({currency_symbol})
                      <span className="required">*</span>
                    </span>
                    <OutlinedInput
                      className="company-title5"
                      fullWidth
                      placeholder="e.g. Invoice Amount"
                      type="number"
                      value={invoiceammount}
                      readOnly={trader_type === "buyer" ? true : false}
                      onChange={(e) => {
                        const newValue = e.target.value
                          .replace(/[^0-9]/g, "")
                          .substring(0, 20);
                        setinvoiceammount(newValue);
                      }}
                      inputProps={{ maxLength: 20, min: 0 }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="input-box">
                    <span className="company-title3">
                      Invoice Date<span className="required">*</span>
                    </span>

                    {/* <TextField
                                      id="date"
                                      type="date"
                                      fullWidth
                                      InputLabelProps={{
                                          shrink: true,
                                      }}
                                      readOnly={trader_type === "buyer" ? true : false}
                                      onChange={(e) => { setinvoiceDate(e.target.value) }}
                                  /> */}
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Stack spacing={3}>
                        <DesktopDatePicker
                          inputFormat="MM/dd/yyyy"
                          readOnly={trader_type === "buyer" ? true : false}
                          value={invoiceDate}
                          onChange={invoiceDateChange}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="input-box">
                    <span className="company-title3">
                      Due Date<span className="required">*</span>
                    </span>

                    {/* <TextField
                                  id="date"
                                  type="date"
                                  fullWidth
                                  InputLabelProps={{
                                      shrink: true,
                                  }}
                                  readOnly={trader_type === "buyer" ? true : false}
                                  onChange={(e) => { setdueDate(e.target.value) }}
                                /> */}
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Stack spacing={3}>
                        <DesktopDatePicker
                          inputFormat="MM/dd/yyyy"
                          readOnly={trader_type === "buyer" ? true : false}
                          value={dueDate}
                          disabled={!invoiceDate}
                          onChange={dueDateChange}
                          renderInput={(params) => <TextField {...params} />}
                          defaultValue={new Date(invoiceDate)}
                          minDate={new Date(invoiceDate)}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="input-box">
                    <span className="company-title3">
                      Purchase Order Date
                      <span className="required">*</span>
                    </span>

                    {/*<TextField
                                  id="date"
                                  type="date"
                                  fullWidth
                                  InputLabelProps={{
                                      shrink: true,
                                  }}
                                  readOnly={trader_type === "buyer" ? true : false}
                                  onChange={(e) => { setpurchaseOrderDate(e.target.value) }}
                                />*/}
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Stack spacing={3}>
                        <DesktopDatePicker
                          inputFormat="MM/dd/yyyy"
                          readOnly={trader_type === "buyer" ? true : false}
                          value={purchaseOrderDate}
                          onChange={purchaseOrderDateChange}
                          renderInput={(params) => <TextField {...params} />}
                          disabled={!invoiceDate}
                          defaultValue={new Date(invoiceDate)}
                          maxDate={new Date(invoiceDate)}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="input-box">
                    <span className="company-title3">
                      Credit Days<span className="required">*</span>
                    </span>
                    <OutlinedInput
                      className="company-title5"
                      fullWidth
                      type="number"
                      placeholder="e.g. Credit Days"
                      value={creditDays}
                      onChange={(e) => {
                        const newValue = e.target.value
                          .replace(/[^0-9]/g, "")
                          .substring(0, 4);
                        setcreditDays(newValue);
                      }}
                      inputProps={{ maxLength: 4, min: 0 }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="input-box">
                    <span className="company-title3">
                      Amount Require ({currency_symbol})
                      <span className="required">*</span>
                    </span>
                    <OutlinedInput
                      className="company-title5"
                      fullWidth
                      placeholder="e.g. Amount Require"
                      type="number"
                      value={amountRequired}
                      readOnly={trader_type === "buyer" ? true : false}
                      onChange={(e) => {
                        const newValue = e.target.value
                          .replace(/[^0-9]/g, "")
                          .substring(0, 20);
                        setamountRequired(newValue);
                      }}
                      inputProps={{ maxLength: 20, min: 0 }}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Typography className="sub-heading">Upload Document</Typography>
              <Box className="upload-documents">
                {docId !== undefined && docId.length > 0
                  ? docId.map((item, index2) => {
                      return (
                        <Box key={index2} className="upload-documents">
                          <Grid container align="center" spacing={2}>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                              <Grid container align="center" spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                  <span className="company-title3">
                                    {item.name}
                                    {item.is_required === true ? (
                                      <span className="document-required">
                                        *
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                  <center>
                                    <Box className="button">
                                      <Button
                                        className="button-title"
                                        onClick={() =>
                                          openFileSystem(
                                            event,
                                            documentRef.current[index2]
                                          )
                                        }>
                                        Upload Document
                                      </Button>
                                      <input
                                        ref={addToRefs}
                                        hidden
                                        type="file"
                                        onChange={(e) => {
                                          function_Second(e, item);
                                        }}
                                      />
                                    </Box>
                                  </center>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                              <Grid container spacing={2}>
                                {InvoiceDocuments &&
                                  InvoiceDocuments.map(
                                    (invoice_item, index3) => (
                                      <>
                                        {invoice_item.doc_list &&
                                          invoice_item.doc_list.map(
                                            (doc_item, index4) => (
                                              <>
                                                {doc_item.document_type_id ===
                                                item.id ? (
                                                  <Grid
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                    key={index4}>
                                                    <Box className="uploaded-documents-list image-box">
                                                      {doc_item.image_extension ===
                                                      "application/pdf" ? (
                                                        <embed
                                                          src={`${
                                                            api_url +
                                                            doc_item.document_url
                                                          }?${Date.now()}`}
                                                          className="image"
                                                          type={
                                                            doc_item.image_extension
                                                          }
                                                        />
                                                      ) : (
                                                        <img
                                                          className="image"
                                                          src={`${
                                                            api_url +
                                                            doc_item.document_url
                                                          }?${Date.now()}`}
                                                        />
                                                      )}
                                                      <Box className="img-action-option edit">
                                                        <label className="edit-label">
                                                          <center>
                                                            <EditOutlinedIcon className="img-edit" />
                                                          </center>
                                                          <input
                                                            type="file"
                                                            hidden
                                                            onChange={(e) =>
                                                              editUpload(
                                                                e,
                                                                item
                                                              )
                                                            }
                                                          />
                                                        </label>
                                                      </Box>

                                                      <Box className="img-action-option delete">
                                                        <center>
                                                          <DeleteOutlineIcon
                                                            onClick={(e) =>
                                                              deleteUpload(
                                                                e,
                                                                doc_item.document_id
                                                              )
                                                            }
                                                            className="img-delete"
                                                          />
                                                        </center>
                                                      </Box>
                                                    </Box>
                                                  </Grid>
                                                ) : (
                                                  <></>
                                                )}
                                              </>
                                            )
                                          )}
                                      </>
                                    )
                                  )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>
                      );
                    })
                  : null}
              </Box>

              <Grid container spacing={3} className="button-grid">
                <Grid item xs={6} sm={4} md={2} xl={2}>
                  <Button
                    className="save"
                    onClick={saveForm}
                    disabled={
                      !buyerId ||
                      !creditOfferId ||
                      !buyerLocationId ||
                      !purchaseOrderNO ||
                      !invoiceNo ||
                      !invoiceammount ||
                      !invoiceDate ||
                      !dueDate ||
                      dueDate == null ||
                      purchaseOrderDate == null ||
                      !purchaseOrderDate ||
                      !creditDays ||
                      !amountRequired
                    }>
                    Save
                  </Button>
                </Grid>
                {user_role === "admin" || user_role === "approver" ? (
                  <Grid item xs={6} sm={4} md={2} xl={2}>
                    <Button
                      className="invite"
                      onClick={submitForm}
                      disabled={
                        !buyerId ||
                        !creditOfferId ||
                        !buyerLocationId ||
                        !purchaseOrderNO ||
                        !invoiceNo ||
                        !invoiceammount ||
                        !invoiceDate ||
                        !dueDate ||
                        dueDate == null ||
                        purchaseOrderDate == null ||
                        !purchaseOrderDate ||
                        !creditDays ||
                        !amountRequired
                      }>
                      Submit
                    </Button>
                  </Grid>
                ) : null}
                <Grid item xs={6} sm={4} md={2} xl={2}>
                  <Link to="/invoice">
                    <Button className="cancel">Cancel</Button>
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>

          {openPaymentModel && (
            <WebRedirectPaymentMethod
              paymentMasterData={paymentMasterData}
              po_number={purchaseOrderNO}
              invoice_no={invoiceNo}
              openPaymentModel={openPaymentModel}
              modelPaymentClose={modelPaymentClose}
            />
          )}
        </>
      )}
    </ThemeContainer>
  );
}
