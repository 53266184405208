import React, { useEffect, useState } from "react";
import { Grid, Box, Typography, MenuItem, FormControl, Select, Button, OutlinedInput  } from "@material-ui/core";
import VerticalBar from "../../SupplierDashboard/VerticalBars";
import { withStyles } from "@material-ui/styles";
import { PropTypes } from "prop-types";
import TopCard from "./../../SupplierDashboard/Component/TopCard";
import bill from "../../../assets/img/bill.svg";
import discounting from "../../../assets/img/discounting.svg";
import interest from "../../../assets/img/interest.svg";
import creditLimit from "../../../assets/img/creditLimit.svg";
import dues from "../../../assets/img/dues.svg";
import penalties from "../../../assets/img/penalties.svg";
import axios from "axios";
import { Row, Col, Spinner } from "react-bootstrap";
import { getAccessToken } from "utilities/tokenUtil";
import LenderInvoice from "./LenderInvoice";
import TextField from "@mui/material/TextField";
import Stack from '@mui/material/Stack';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';

const styles = () => ({
  graph: {
    "& canvas": {
      height: "280px !important",
    },
  },
  line: {
    height: "240px",
    "& canvas": {
      height: "220px !important",
    },
  },
  typoBox: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
  },
});

function LenderDashboard(props) {
  const { classes }           = props;
  const [data, setData]       = useState();
  const api_url               = process.env.REACT_APP_URL;
  const [loader, setLoader]   = useState(false);
  const [startDate, setstartdate]                 = useState("");
  const [endDate, setendDate]                     = useState("");
  const [buyerName, setbuyerName]                 = useState("");
  const [invoiceNoSearch, setinvoiceNoSearch]     = useState("");
  const [searchStatus, setsearchStatus]           = useState("");
  const [page_no, setPageNo]                      = useState(1);
  const [invoiceList, setinvoiceList]             = useState([]);
  const [page_count, setPageCount]                = useState(1);

  const getData = () => {
    let body = {
      user_id: localStorage.getItem("userId"),
      access_token: getAccessToken(),
    };
    //setLoader(false)
    axios
      .post(
        api_url + "/api/v1/lender/dashboard",
        body,
        {
          headers: {
            "Content-Type": "text/plain",
          },
        }
      )
      .then((res) => {
        if (res.status == 200) {
          let info = res.data;
          setData({
            vertical_bar: info.invoices_by_months,
            discount_bar: info.discount_by_months,
            final_dashboard_list: info.final_dashboard_list,
          });
          setLoader(true)
        }else{
          setLoader(true)
        }
      });
  };
  const getInvoiceList = (value=1) => {
    //setLoader(false)
    const access_token = getAccessToken();
    const data = {
        access_token: access_token,
        user_id: parseInt(localStorage.getItem("userId")),
        page_no: parseInt(value)
    };
    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/lender/invoice/list`, data, {
          headers: {
              "Content-Type": "text/plain",
          },
      })
      .then((response) => {
          if (response.data.success == true) {
              setinvoiceList(response.data.invoice_list)

              let total_records       = response.data.total_rec;
              let records_per_page    = response.data.records_per_page;
              let page_count          = Math.ceil(total_records / records_per_page);
              setPageNo(response.data.current_page)
              if(page_count < 1){
                  setPageCount(1)
              }else {
                  setPageCount(page_count)
              }
              setLoader(true)
          }else{
              setLoader(true)
          }

      })
      .catch((error) => {
          console.log(error);
          setLoader(true)
      });
  };
  const filter = () => {
      const access_token = getAccessToken();
      const data = {
          user_id: parseInt(localStorage.getItem("userId")),
          access_token: access_token,
          status: searchStatus,
          invoice_no: invoiceNoSearch,
          filed_from: startDate,
          filed_to: endDate,
          buyer_name: buyerName,
          supplier_name: "",
          page_no: parseInt(page_no ? page_no : 1)
      };
      axios
        .post(`${process.env.REACT_APP_URL}/api/v1/lender/dashboard/search`, data, {
            headers: {
                "Content-Type": "text/plain",
            },
        })
        .then((response) => {
          
            if (response.data.success) {
                setinvoiceList(response.data.invoice_list)
                setData({
                  vertical_bar: response.data.invoices_by_months,
                  discount_bar: response.data.discount_by_months,
                  final_dashboard_list: response.data.final_dashboard_list,
                });
                let total_records       = response.data.total_rec;
                let records_per_page    = response.data.records_per_page;
                let page_count          = Math.ceil(total_records / records_per_page);
                setPageNo(response.data.current_page)
                if(page_count < 1){
                    setPageCount(1)
                }else {
                    setPageCount(page_count)
                }
            } else {
                setOpen(true); 
                setMessage({ type: "error", content: response.data.message })
            }
        })
        .catch((error) => {
            console.log(error);
        });
  }
  const clearSearch=()=>{
      setstartdate("");
      setendDate("")
      setbuyerName("")
      setinvoiceNoSearch("")
      setsearchStatus("")
      getInvoiceList();
      getData();
  }
  const startDateChange = (newValue) => {
      var date = new Date(newValue);
      var finaldate = date.getFullYear() + '-' +  (date.getMonth() + 1)  + '-' +  date.getDate();
      setstartdate(finaldate);
  };
  const endDateChange = (newValue) => {
      var date = new Date(newValue);
      var finaldate = date.getFullYear() + '-' +  (date.getMonth() + 1)  + '-' +  date.getDate();
      setendDate(finaldate);
  };
  function disablePrevDates(startDate) {
    const startSeconds = Date.parse(startDate);
    return (date) => {
      return Date.parse(date) < startSeconds;
    }
  }
  useEffect(() => {
    document.title = 'Interswitch - Dashboard';
    getData();
    getInvoiceList();
  }, []);
  return (
    <>
      {loader !== true ? (
        <Grid container spacing={3} className="bootstrap-loader">        
          <Spinner animation="grow" variant="spinner1" />
          <Spinner animation="grow" variant="spinner2" />
          <Spinner animation="grow" variant="spinner3" />
        </Grid>
        ) : (
        <>
        {data && data !== undefined && (
          
          <Box className="lender dashboard">
            <Box className="top-card">
              <Grid container spacing={2}>  
                {data.final_dashboard_list !== "" && data.final_dashboard_list !== undefined ? (
                  data.final_dashboard_list.map((item, index) => (
                    <Grid item xs={6} sm={4} md={4} lg={3} xl={3} key="index">
                      <TopCard
                        image={bill}
                        title={item.value}
                        subtitle={item.key}
                        type={item.type}
                      />
                    </Grid>
                    ))
                ) : (
                    <></>
                )}             
                        
              </Grid>
            </Box>

            <Box className="filter-box">          
              <Typography className="company-title2"> Filters</Typography>
              <Grid container spacing={2} className="filters">
                  <Grid item xs={6} sm={12} md={4} xl={4}>
                      <Box>
                          <span className="company-title3">Start Date<span className="required">*</span></span>
                          
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Stack spacing={3}>
                              <DesktopDatePicker
                                  inputFormat="MM/dd/yyyy"
                                  value={startDate}
                                  onChange={startDateChange}
                                  renderInput={(params) => <TextField {...params} />}
                              />
                            </Stack>
                          </LocalizationProvider>
                      </Box>
                  </Grid>
                  <Grid item xs={6} sm={12} md={4} xl={4}>

                      <Box>
                          <span className="company-title3"> End Date<span className="required">*</span></span>
                          
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <Stack spacing={3}>
                                <DesktopDatePicker
                                    inputFormat="MM/dd/yyyy"
                                    shouldDisableDate={disablePrevDates(startDate)}
                                    value={endDate}
                                    onChange={endDateChange}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                              </Stack>
                            </LocalizationProvider>
                      </Box>
                  </Grid>
                  <Grid item xs={6} sm={12} md={4} xl={4}>

                      <Box>
                          <span className="company-title3">Buyer Name<span className="required">*</span></span>
                          <OutlinedInput className="company-title5"
                              fullWidth
                              placeholder="e.g. Buyer Name"
                              value={buyerName}
                              onChange={(e) => setbuyerName(e.target.value)}
                          />
                      </Box>
                  </Grid>

                  <Grid item xs={6} sm={12} md={4} xl={4}>

                      <Box>
                          <span className="company-title3"> Invoice Number<span className="required">*</span></span>
                          <OutlinedInput className="company-title5"
                              fullWidth
                              placeholder="e.g. Invoice Number"
                              value={invoiceNoSearch}
                              onChange={(e) => setinvoiceNoSearch(e.target.value)}
                          />
                      </Box>
                  </Grid>
                  <Grid item xs={6} sm={12} md={4} xl={4}>
                      <Box>
                          <span className="company-title3"> Status Type<span className="required">*</span></span>
                          <FormControl fullWidth>
                                <Select
                                    displayEmpty
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Age"
                                    value={searchStatus}
                                    onChange={(e) => setsearchStatus(e.target.value)}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left",
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left",
                                        },
                                        getContentAnchorEl: null,
                                    }}
                                >

                                  <MenuItem value="" disabled>Select from options</MenuItem>
                                  <MenuItem value="funded">Funded</MenuItem>
                                  <MenuItem value="rejected">Rejected</MenuItem>
                                  <MenuItem value="awaiting_disbursal">Awaiting Disbursal</MenuItem>
                                  <MenuItem value="waiting_for_lender_approval">Awaiting Lender Approval</MenuItem>
                              </Select>
                          </FormControl>
                      </Box>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4} xl={4}>

                      <span className="company-title3">&nbsp;</span>
                      <Box className="filter-action">
                          <Button className="submit-button" onClick={filter}>Submit</Button>
                          <Button className="cancel-button" onClick={clearSearch} >Clear</Button>
                      </Box>
                  </Grid>
              </Grid>
            </Box>

            {data && data.vertical_bar || data.discount_bar ? (
            <Box className="graph-box">
              <Grid container spacing={5} className="graph-grid" >
                <Grid item xs={12} sm={12} md={12} xl={12}>
                  <Grid container spacing={5}>
                    <Grid item xs={12} sm={6} md={6} xl={6}>
                      <Box className={classes.graph+(" monthly-graph")} >
                        {data && data.vertical_bar && (
                          <VerticalBar info={data.vertical_bar} name={"Monthly Invoicing"}/>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} xl={6}>
                      <Box className={classes.graph+(" monthly-graph")} >
                        {data && data.discount_bar && (
                          <VerticalBar info={data.discount_bar} name={"Monthly Discounting"}/>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>
               
              <LenderInvoice invoiceList={invoiceList} />
            </Box>
            ):null}
          </Box>
          
        )}
        </>
      )}
    </>
  );
}

LenderDashboard.propTypes = {
  classes: PropTypes.any,
};

export default withStyles(styles)(LenderDashboard);
