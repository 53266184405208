import React, { useState, useEffect, useDebugValue } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid,
  Box,
  InputLabel,
  Typography,
  OutlinedInput,
  withStyles,
  MenuItem,
  FormControl,
  Select,
  Button,
  Snackbar,
  SnackbarContent,
  TextField,
} from "@material-ui/core";

import PropTypes from "prop-types";
import image1 from "../../assets/img/coun1.svg";
import { isMobile } from "react-device-detect";
import ThemeContainer from "views/Components/sideBar/ThemeContainer";
import { Link } from "react-router-dom";
var axios = require("axios");
import { getAccessToken } from "../../utilities/tokenUtil";
import { Details, Email } from "@material-ui/icons";
import { unstable_useEventCallback } from "@mui/utils";
import Menu from "@material-ui/core/Menu";

const styles = (theme) => ({
  textFields: {
    background: "#F3F5F6",
    height: "40px",
    marginTop: "1%",
    "& div": {
      "&::before": {
        content: "",
        borderBottom: "none",
      },
      "&::after": {
        borderBottom: "none",
      },
      "&:hover": {
        "&::before": {
          borderBottom: "none !important",
        },
        "&::after": {
          borderBottom: "none !important",
        },
      },
      "& .MuiInputBase-input": {
        padding: "9px 7px",
      },
    },
  },
});

function CompanyInfo(props) {
  const history = useHistory();
  const [userId, setUserId] = useState("");
  const [open1, setOpen1] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [userState, setUserState] = useState([]);
  const [name, setName] = useState("");
  const [registration, setRegistration] = useState("");
  const [type, setType] = useState("");
  const [taxation, setTaxation] = useState("");
  const [entity, setEntity] = useState("");
  const [industry, setIndustry] = useState([]);
  const [businessaddress, setBusinessAddress] = useState("");
  const [businesscountry, setBusinessCountry] = useState("");
  const [businessstate, setBusinessState] = useState("");
  const [businesscity, setBusinessCity] = useState("");
  const [correspondanceaddress, setCorrespondanceAddress] = useState("");
  const [correspondancecountry, setCorrespondanceCountry] = useState("");
  const [crcountry, setcrcountry] = useState(0);
  const [correspondancestate, setCorrespondanceState] = useState("");
  const [correspondancecity, setCorrespondancCity] = useState("");
  const [turnover, setTurnover] = useState("");
  const [limit, setLimit] = useState("");
  const [employee, setEmployee] = useState("");
  const [fullname, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [designation, setDesignation] = useState("");
  const [contacttaxation, setContactTaxation] = useState("");
  const [hq, setHq] = useState("");
  const [open, setOpen] = useState(false);
  const [allcountry, setcountry] = useState([]);
  const [details, setDetails] = useState();
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState({});
  const [allState1, setAllState1] = useState();
  const [allState2, setAllState2] = useState();
  const [indusrtyList, setIndustryList] = useState();
  const [entityList, setentityList] = useState();
  const [hqList, setHqList] = useState([]);
  const [bvn, setbvn] = useState("");
  const [emp, setemp] = useState("");
  const [empList, setempList] = useState("");
  const [s1, setS1] = useState();
  const [correspondanceId,setCorrespondancId]=useState(0);
  const [contactName,setContactName]=useState();
  const [contactEmail,contactSetEmail]=useState();
  const [contactID,setContactId]=useState(0);
  const [contactPhone,setContactPhone]=useState();
  const[contactDeg,setContactDeg]=useState();
  const[contactBVN,setConatctBVN]=useState();
  const[contactWork,setContactWork]=useState();

  const changeCountry = (id, index) => {
    console.log(id, index);
    contact.map((x, i) => {
      console.log(i, index);
      if (index === i) {
        let newArray = [...contact];
        newArray[index].country_id = id;
        setContact(newArray);
      }
    });
  };

  const changeName = (id, index) => {
    contact.map((x, i) => {
      console.log(i, index);
      if (index === i) {
        let newArray = [...contact];
        newArray[index].name = id;
        setContact(newArray);
      }
    });
  };

  const changeEmail = (id, index) => {
    contact.map((x, i) => {
      console.log(i, index);
      if (index === i) {
        let newArray = [...contact];
        newArray[index].email = id;
        setContact(newArray);
      }
    });
  };

  const changePhone = (id, index) => {
    console.log("item", id);
    contact.map((x, i) => {
      if (index === i) {
        let newArray = [...contact];
        newArray[index].phone_number = id;
        setContact(newArray);
      }
    });
  };
  const changeDesc = (id, index) => {
    contact.map((x, i) => {
      if (index === i) {
        let newArray = [...contact];
        newArray[index].job_position = id;
        setContact(newArray);
      }
    });
  };

  const changeBvn = (id, index) => {
    contact.map((x, i) => {
      if (index === i) {
        let newArray = [...contact];
        newArray[index].bvn_number = id;
        setContact(newArray);
      }
    });
  };

  const changeWork = (id, index) => {
    contact.map((x, i) => {
      if (index === i) {
        let newArray = [...contact];
        newArray[index].working_at_id = id;
        setContact(newArray);
      }
    });
  };

  const [contact, setContact] = useState([
    {country_id:"",
        image:"",
      id: 0,
      name: "",
      phone_number: "",
      email: "",
      job_position: "",
      working_at_id: "",
      bvn_number: "",
    },
  ]);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const getCompanyDetails = () => {
    const access_token = getAccessToken();
    const userID = localStorage.getItem("userId");

    const dataSet = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: access_token,
    };
    console.log(dataSet);
    const config = {
      method: "post",
      url: process.env.REACT_APP_URL + "api/v1/company/information/detail",
      headers: {
        "Content-Type": "text/plain",
      },
      data: dataSet,
    };
    axios(config)
      .then((response) => {
        console.log("jinesh", response.data.trader.address);
        if (response && response.data.success) {
          
          setBusinessAddress(response.data.trader.address);
          setDetails(response.data);
     
          setName(response.data.trader.name);
          setRegistration(response.data.trader.registration_number);
          setTaxation(response.data.trader.tax_number);
          setEntity(response.data.trader.entity_id);
          setType(response.data.user_type);
          setBusinessCity(response.data.trader.city);
          setLimit(response.data.trader.financial_limit);
          setTurnover(response.data.trader.financial_turnover);
          setEmployee(response.data.trader.employee_range_id)
        let arr=[];
        arr.push(response.data.trader.industry_id[0].id)
         setIndustry(arr)
       
          if (response.data.trader.country_id != false) {
            setBusinessCountry(response.data.trader.country_id);
            console.log(response.data.trader.state_id);

            console.log(s1);
            fetchState1(
              response.data.trader.country_id,
              "allstate1",
              response.data.trader.state_id
            );
          }

          if (response.data.correspondence_address_list) {
            let last = response.data.correspondence_address_list.length - 1;
            console.log(last);
            console.log(response.data.correspondence_address_list[last].city);
            setCorrespondanceCountry(
              response.data.correspondence_address_list[last].country_id
            );
            setCorrespondanceAddress(response.data.correspondence_address_list[last].address)
            setCorrespondancCity(
              response.data.correspondence_address_list[last].city
            );
           setCorrespondancId(response.data.correspondence_address_list[last].id)
            console.log(response.data.correspondence_address_list[last]);
            console.log(response.data.correspondence_address_list[last]);
            console.log(s1);
            fetchState1(
              response.data.correspondence_address_list[last].country_id,
              "allstate2",
              response.data.correspondence_address_list[last].state_id
            );
          }
          console.log("qazxcvb", response.data.contact_list);
          setLoading(false);
        }
        if (response.data.contact_list.length > 0) {
          let last = response.data.contact_list.length - 1;
          setContact(response.data.contact_list);
          setContactName(response.data.contact_list[last].name)
          setConatctBVN(response.data.contact_list[last].bvn_number)
          setContactWork(response.data.contact_list[last].working_at_id)
          setContactPhone(response.data.contact_list[last].phone_number)
          setContactDeg(response.data.contact_list[last].job_position)
          contactSetEmail(response.data.contact_list[last].email)
          console.log("ssss",response.data.contact_list[last])
          setContactId(response.data.contact_list[last].contact_id)
        }
        console.log(response);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
    console.log(Details.data);
  };

  const countryFunction = () => {
    const access_token = getAccessToken();
    const userID = localStorage.getItem("userId");
    setAccessToken(access_token);
    setUserId(userID);
    console.log(access_token, userID);

    const data = {
      user_id: localStorage.getItem("userId"),
      access_token: access_token,
    };
    console.log(data);
    axios
      .post(
        `${process.env.REACT_APP_URL}api/v1/country/master`,
        JSON.stringify(data)
      )
      .then((response) => {
        if (response && response.data.sucess) {
          setcountry(response.data.country);
        }
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const masterFunction = () => {
    const access_token = getAccessToken();
    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: access_token,
    };
    console.log(data);

    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/invite/user/master`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        setIndustryList(response.data.industry);
        setentityList(response.data.company_entity);
        console.log("trhgtrg",response.data.working_location)
        setHqList(response.data.working_location);
        setempList(response.data.employee_range);
        console.log("ewfrer", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const addContact = () => {
    setContact([
      ...contact,
      {
        id: 0,
        name: "",
        phone_number: "",
        email: "",
        job_position: "",
        working_at_id: "",
        bvn_number: "",
      },
    ]);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    countryFunction();
    getCompanyDetails();
    masterFunction();
  }, []);

  const fetchState = (country_id, data1) => {
    console.log(country_id, data1);
    const dataSet = JSON.stringify({
      country_id: parseInt(country_id),
    });
    const config = {
      method: "post",
      url: process.env.REACT_APP_URL + "api/v1/state/master",
      headers: {
        "Content-Type": "text/plain",
      },
      data: dataSet,
    };
    axios(config)
      .then((response) => {
        console.log(response);
        if (data1 == "allstate1") {
          setAllState1(response.data.state);
        }
        if (data1 == "allstate2") {
          setAllState2(response.data.state);
        }
        s;
        setUserState(response.data.state);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchState1 = (country_id, data1, state) => {
    console.log(country_id, data1);
    const dataSet = JSON.stringify({
      country_id: parseInt(country_id),
    });
    const config = {
      method: "post",
      url: process.env.REACT_APP_URL + "api/v1/state/master",
      headers: {
        "Content-Type": "text/plain",
      },
      data: dataSet,
    };
    axios(config)
      .then((response) => {
        console.log(response);
        if (data1 == "allstate1") {
          setAllState1(response.data.state);
          setBusinessState(state);
        }
        if (data1 == "allstate2") {
          setAllState2(response.data.state);
          setCorrespondanceState(state);
        }

        setUserState(response.data.state);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const correspondanceCountry = (country_id) => {
    const dataSet = JSON.stringify({
      country_id: JSON.parse(country_id),
    });
    const config = {
      method: "post",
      url: process.env.REACT_APP_URL + "api/v1/state/master",
      headers: {
        "Content-Type": "text/plain",
      },
      data: dataSet,
    };
    axios(config)
      .then((response) => {
        setCorrespondanceCountry(response.data.state);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 50 * 4.5 + 2,
        width: 200,
      },
    },
  };

  const onSubmit = () => {
    setLoading(true);
    console.log(details.trader.trader_id);

    if (name === "") {
      setLoading(false);
      setOpen(true);
      setMessage({ type: "error", content: "Name cannot be empty" });
      return;
    }

    if (registration === "") {
      setLoading(false);
      setOpen(true);
      setMessage({ type: "error", content: "Registration cannot be empty" });
      return;
    }
    if (registration.length < 9) {
      setLoading(false);
      setOpen(true);
      setMessage({ type: "error", content: "Please Enter Valid Registration Number" });
      return;
    }
    if (type === "") {
      setLoading(false);
      setOpen(true);
      setMessage({ type: "error", content: "USER Type cannot be empty" });
      return;
    }
    if (taxation === "") {
      setLoading(false);
      setOpen(true);
      setMessage({ type: "error", content: " Taxation cannot be empty" });
      return;
    }
    if (entity === "") {
      setLoading(false);
      setOpen(true);
      setMessage({ type: "error", content: "Entity cannot be empty" });
      return;
    }
    if (industry === "") {
      setLoading(false);
      setOpen(true);
      setMessage({ type: "error", content: "Industry cannot be empty" });
      return;
    }
    if (businessaddress === "") {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "Business Address cannot be empty",
      });
      return;
    }
    if (businesscountry === "") {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "Business Country cannot be empty",
      });
      return;
    }
    if (businessstate === "") {
      setLoading(false);
      setOpen(true);
      setMessage({ type: "error", content: "Business State cannot be empty" });
      return;
    }
    if (businesscity === "") {
      setLoading(false);
      setOpen(true);
      setMessage({ type: "error", content: "Business City cannot be empty" });
      return;
    }
    if (correspondanceaddress === "") {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "Correspondance Address cannot be empty",
      });
      return;
    }
    if (correspondancecountry === "") {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "Correspondance Country cannot be empty",
      });
      return;
    }
    if (correspondancestate === "") {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "Correspondance State cannot be empty",
      });
      return;
    }
    if (correspondancecity === "") {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "Correspondance City cannot be empty",
      });
      return;
    }
    if (limit === "") {
      setLoading(false);
      setOpen(true);
      setMessage({ type: "error", content: "Limit cannot be empty" });
      return;
    }
    if (contactName === "") {
      setLoading(false);
      setOpen(true);
      setMessage({ type: "error", content: "contact Name cannot be empty" });
      return;
    }
    if (contactPhone === "") {
      setLoading(false);
      setOpen(true);
      setMessage({ type: "error", content: "contact Phone cannot be empty" });
      return;
    }

    if (contactDeg === "") {
      setLoading(false);
      setOpen(true);
      setMessage({ type: "error", content: "contact Degination cannot be empty" });
      return;
    }

    if (contactBVN === "") {
      setLoading(false);
      setOpen(true);
      setMessage({ type: "error", content: "contact bvn cannot be empty" });
      return;
    }
    if (contactPhone === "") {
      setLoading(false);
      setOpen(true);
      setMessage({ type: "error", content: "contact Phone cannot be empty" });
      return;
    }

    if (contactWork === "") {
      setLoading(false);
      setOpen(true);
      setMessage({ type: "error", content: "contact Phone cannot be empty" });
      return;
    }
    const dataSet = {
      user_id: localStorage.getItem("userId"),
      access_token: accessToken,
      user_type: "buyer",
      trader_list: [
        {
          id: details.trader.trader_id,
          tax_number: taxation,
          name: name,
          mobile: "",
          registration_number: registration,
          entity_id: entity,
          industry_id:industry,
          address: businessaddress,
          city: businesscity,
          state_id: parseInt(businessstate),
          country_id: parseInt(businesscountry),
          financial_turnover: parseInt(turnover),
          financial_limit: parseInt(limit),
          employee_range_id: employee,
        },
      ],
      correspondence_address_list: [
        {
          id: parseInt(correspondanceId),
          address: correspondanceaddress,
          city: correspondancecity,
          state_id: parseInt(correspondancestate),
          country_id: parseInt(correspondancecountry),
        },
      ],
      contact_list: [{
        "id": contactID,
         "name": contactName,
         "phone_number": contactPhone,
        "job_position":contactDeg,
         "working_at_id":contactWork,
         "bvn_number":contactBVN,
         "image" : ""
  }
  
  ],
     
    };
    console.log("dataSet", dataSet);

    console.log(dataSet);
    const config = {
      method: "post",
      url: process.env.REACT_APP_URL + "/api/v1/company/information/update",
      headers: {
        "Content-Type": "text/plain",
      },
      data: dataSet,
    };
    axios(config)
      .then((response) => {
        console.log(response.data);
        if (response && response.data) {
          if (response.data.success) {
            history.push("/kyc-page");
            setOpen(true);
            history.push("/kyc-page");
            setMessage({ type: "success", content: "Update successful" });
            setLoading(false);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  const { classes } = props;
  return (
    <ThemeContainer type="private" value={5}>
      {/* <Loader loading={loading} /> */}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <SnackbarContent
          style={{
            backgroundColor:
              message.type === "error"
                ? "#eb3d2a"
                : message.type === "success"
                  ? "green"
                  : "teal",
          }}
          message={message.content}
        />
      </Snackbar>
      <Box style={{ padding: isMobile ? "" : "3%", background: "#FFF" }}>
        <Box style={{ height: "100%" }}>
          <Box
            style={{
              background: "#FFFFFF",
              padding: isMobile ? "" : "5%",
              borderRadius: isMobile ? " " : "16px",
            }}
          >
            <Typography
              className="company-title1"
              style={{
                padding: isMobile ? "5%" : "2%",
                color: "#18425D",
                fontSize: isMobile ? "20px" : "24px",
                fontWeight: "700",
              }}
            >
              Company Information
            </Typography>
            <Typography
              className="company-title2"
              style={{
                padding: isMobile ? "5%" : "2%",
                color: "#0275D8",
                fontSize: isMobile ? "16px" : "16px",
                fontWeight: "600",
              }}
            >
              {" "}
              Company Information
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4} xl={4}>
                <Box style={{ padding: "5%" }}>
                  <span className="company-title3">
                    Name<span style={{ color: "red" }}>*</span>
                  </span>
                
                  <OutlinedInput
                    className="company-title5"
                    fullWidth
                    placeholder=" Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4} xl={4}>
                <Box style={{ padding: "5%" }}>
                  <span
                    className="MuiTypography-body1 test"
                    className="company-title3"
                  >
                    Registration number<span style={{ color: "red" }}>*</span>
                  </span>
                  <OutlinedInput
                    className="company-title5"
                    fullWidth
                    placeholder="e.g. NCR1234"
                    value={registration}
                    onChange={(e) => setRegistration(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4} xl={4}>
                <Box style={{ padding: "5%" }}>
                  <Typography className="company-title3">
                    User Type<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <FormControl fullWidth className="company-title5">
                    <select
                      displayEmpty
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Age"
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                      style={{ height: "100%", border: "0px", lineHeight: "3.5px", backgroundColor: "#F3F5F6           " }}
                    >
                      <option value="" disabled>
                        Select from options
                      </option>
                      <option value={10}>Buyer</option>
                      <option value={20}>Supplier</option>
                      <option value={30}>Both</option>
                    </select>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4} xl={4}>
                <Box style={{ padding: "5%" }}>
                  <span className="company-title3">
                    Taxation Number<span style={{ color: "red" }}>*</span>
                  </span>
                  <OutlinedInput
                    className="company-title5"
                    fullWidth
                    value={taxation}
                    placeholder=" e.g. TXN567890 "
                    onChange={(e) => setTaxation(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4} xl={4}>
                <Box style={{ padding: "5%" }}>
                  <Typography className="company-title3">
                    Company Entity<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <FormControl fullWidth className={classes.textFields}>
                    <select
                      displayEmpty
                      className="company-title4"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select2"
                      placeholder="Select from Option"
                      value={entity}
                      onChange={(e) => setEntity(e.target.value)}
                      style={{ height: "100%", border: "0px", lineHeight: "3.5px", backgroundColor: "#F3F5F6           " }}
                    >
                      <option value="" disabled={true}>
                        Select from options
                      </option>

                      {entityList != undefined &&
                        entityList.map((item, index) => {
                          return (
                            <option style={{ fontSize: "18px" }} value={item.id}>{item.name}</option>
                          );
                        })}
                    </select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4} xl={4}>
                <Box style={{ padding: "5%" }}>
                  <Typography className="company-title3">
                    Industry<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <FormControl fullWidth className={classes.textFields}>
                     <select
                      displayEmpty
                      className="company-title4"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select1"
                      value={industry.length==0? 0:industry[0]}
                      onChange={(e) => {
                        let a=[];
                       a.push(parseInt(e.target.value))
                        setIndustry(a)}}
                      style={{height:"100%",border:"0px", lineHeight:"3.5px", backgroundColor:"#F3F5F6           "}}
                    >
                      <option value="" disabled={true}>
                        Select from options
                      </option>

                      {indusrtyList != undefined &&
                        indusrtyList.map((item, index) => {
                          return (
                            <option style={{ fontSize: "18px" }} value={item.id}>{item.name}</option>
                          );
                        })}
                    </select>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>

            <Typography
              style={{
                padding: isMobile ? "5%" : "2%",
                color: "#0275D8",
                fontSize: "16px",
                fontWeight: "600",
              }}
            >
              Business Information
            </Typography>
            <Box style={{ padding: isMobile ? "5%" : "2%" }}>
              <span className="MuiTypography-body1" className="company-title3">
                Address<span style={{ color: "red" }}>*</span>
              </span>
              <OutlinedInput
                className="company-title5"
                fullWidth
                placeholder="Enter Address"
                value={businessaddress}
                onChange={(e) => setBusinessAddress(e.target.value)}
              />
            </Box>
            <Grid container style={{ padding: isMobile ? "5%" : "2%" }}>
              <Grid item xs={4} sm={4} md={1} xl={1}>
                <Box style={{ padding: "5%", marginTop: "5%" }}>
                  <Typography className="company-title3">
                    Country<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <FormControl fullWidth className={classes.textFields}>
                    <FormControl fullWidth className={classes.textFields}>
                    
                       <select 
                    
                    style={{height:"100%",border:"0px", lineHeight:"3.5px", backgroundColor:"#F3F5F6"}}
                     labelId="demo-simple-select-label"
                     id="demo-simple-select"
                     label="Age"
                    value={businesscountry}
                     onChange={(e) => {
                        setBusinessCountry(e.target.value);
                        console.log(e.target.value, e.target.name);
                        const data = e.currentTarget.getAttribute("name");
                        console.log(e.target, data);
                        fetchState(e.target.value, "allstate1");
                      }}
                   >
                              <option > 🇳🇬</option> 
                    
                     {allcountry !== "" ? (
                       allcountry.map((item, index) => (
                         <option style={{fontSize:"22px", fontSize:"18px"}} value={item.id}>{item.name}</option>
                       ))
                     ) : (
                       <></>
                     )}
                   </select>
                   {/* <Autocomplete
     
      businesscountry={businesscountry}
      onChange={(e, newValue) => {
        if(newValue!=null)
        {
        console.log("rishabh",e.target.value,newValue)
        setBusinessCountry(newValue.id);                       
                       
        fetchState(newValue.id, "allstate1");
        }
      }}
      onInputChange={(e, value, reason) => {
        if (!value) {
          setOpen1(false);
        }
      }}
      options={allcountry}
      getOptionLabel={(option) => option.name}
      style={{ width: 300 }}
      renderInput={(params) => (
        <TextField {...params}  variant="outlined" />
      )}
    /> */}
                    </FormControl>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={8} sm={8} md={3} xl={3}>
              <Box style={{ padding: "5%", marginTop: "8%" }}>
                  <OutlinedInput
                    className="company-title5"
                    fullWidth
                    varient="filled"
                    placeholder="Select from option"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4} xl={4}>
                <Box style={{ padding: isMobile ? "1%" : "2%" }}>
                  <Typography className="company-title3">
                    State<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <FormControl fullWidth className={classes.textFields}>
                    <select
                      displayEmpty
                      className="company-title4"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={businessstate}
                      onChange={(e) => {
                        console.log(e.target.value);
                        setBusinessState(e.target.value);
                      }}
                      style={{ height: "100%", border: "0px", lineHeight: "3.5px", backgroundColor: "#F3F5F6           " }}
                    >
                      <option style={{ fontSize: "18px" }} value="" disabled={true}>
                        Select from options
                      </option>

                      {allState1 &&
                        allState1.map((item) => (
                          <option style={{ fontSize: "18px" }} value={item.id} name={item.state_id}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4} xl={4}>
                <Box style={{ padding: isMobile ? "1%" : "2%" }}>
                  <Typography className="company-title3">
                    City<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <OutlinedInput
                    className="company-title5"
                    fullWidth
                    varient="filled"
                    placeholder="City"
                    value={businesscity}
                    onChange={(e) => setBusinessCity(e.target.value)}
                  />
                </Box>
              </Grid>
            </Grid>
            <Typography
              style={{
                padding: isMobile ? "5%" : "2%",
                color: "#0275D8",
                fontSize: "16px",
                fontWeight: "600",
              }}
            >
              Correspondence Address
            </Typography>
            <Box style={{ padding: isMobile ? "5%" : "2%" }}>
              <span className="MuiTypography-body1" className="company-title3">
                Address<span style={{ color: "red" }}>*</span>
              </span>
              <OutlinedInput
                className="company-title5"
                fullWidth
                value={correspondanceaddress}
                placeholder="Enter Address"
                onChange={(e) => setCorrespondanceAddress(e.target.value)}
              />
            </Box>

            <Grid container style={{ padding: isMobile ? "3%" : "2%" }}>
              <Grid item xs={4} sm={4} md={1} xl={1}>
                <Box >
                  <Typography className="company-title3">
                    Country<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <FormControl fullWidth variant="standard" >

                    <select

                      style={{ height: "100%", border: "0px", lineHeight: "3.5px", backgroundColor: "#F3F5F6           " }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Age"
                      value={correspondancecountry}
                      onChange={(e) => {
                        console.log(e.target.value);
                        setCorrespondanceCountry(e.target.value);
                        fetchState(e.target.value, "allstate2");
                      }}
                    >

                      <option > 🇳🇬</option>
                      {allcountry !== "" ? (
                        allcountry.map((item, index) => (
                          <option style={{ fontSize: "18px" }} value={item.id}>{item.name}</option>
                        ))
                      ) : (
                        <></>
                      )}
                    </select>

                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={8} sm={8} md={3} xl={3}>
                <Box style={{ padding: "5%", marginTop: "5%" }}>
                  <OutlinedInput
                    className="company-title5"
                    fullWidth
                    varient="filled"
                    placeholder="Select from option"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4} xl={4}>
                <Box style={{ padding: isMobile ? "3%" : "2%" }}>
                  <Typography className="company-title3">
                    State<span style={{ color: "red" }}>*</span>
                  </Typography>

                  <FormControl fullWidth className="company-title5">
                    <select
                      displayEmpty
                      className="company-title4"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={correspondancestate}
                      onChange={(e) => setCorrespondanceState(e.target.value)}
                      style={{ height: "100%", border: "0px", lineHeight: "3.5px", backgroundColor: "#F3F5F6           " }}
                    // renderValue={(select) => { if (!select) { return <Typography className="company-title4" >Select from options</Typography> } return select }}        // Ye likhna hai
                    >
                      <option style={{ fontSize: "18px" }} value="" disabled>
                        Select from option
                      </option>
                      {allState2 &&
                        allState2.map((item) => (
                          <option style={{ fontSize: "18px" }} value={item.id} name={item.state_id}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4} xl={4}>
                <Box style={{ padding: isMobile ? "3%" : "2%" }}>
                  <Typography className="company-title3">
                    City<span style={{ color: "red" }}>*</span>
                  </Typography>

                  <OutlinedInput
                    className="company-title5"
                    fullWidth
                    placeholder="City"
                    value={correspondancecity}
                    onChange={(e) => {
                      fetchState(e.target.value, "allstate3");
                      setCorrespondancCity(e.target.value);
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
            <Typography
              style={{
                padding: isMobile ? "5%" : "2%",
                color: "#0275D8",
                fontSize: "16px",
                fontWeight: "600",
              }}
            >
              Financial Information
            </Typography>

            <Grid container style={{ padding: isMobile ? "3%" : "2%" }}>
              <Grid item xs={4} sm={4} md={1} xl={1}>
                <Box style={{ padding: "3%", marginTop: "-15%" }}>
                  <Typography
                    className="company-title3"
                    style={{ display: "flex" }}
                  >
                    country<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <FormControl fullWidth    >
                    <select

                      style={{ height: "100%", border: "0px", lineHeight: "3.5px", backgroundColor: "#F3F5F6           " }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Age"
                      value={correspondancecountry}
                      onChange={(e) => {
                        console.log(e.target.value);
                        setCorrespondanceCountry(e.target.value);
                        fetchState(e.target.value, "allstate2");
                      }}
                    >

                      <option > 🇳🇬</option>
                      {allcountry !== "" ? (
                        allcountry.map((item, index) => (
                          <option style={{ fontSize: "22px", fontSize: "18px" }} value={item.id}>{item.name}</option>
                        ))
                      ) : (
                        <></>
                      )}
                    </select>
                  </FormControl>

                  {/* /</Box>/  <select value="Radish"> */}
                  {/* {allcountry !== "" ? (
                        allcountry.map((item, index) => (
                            <select value="Radish">
  <option value="Orange">Orange</option>
  <option value="Radish">Radish</option>
  <option value="Cherry">Cherry</option>
</select>
))
) : (
  <></>
)} */}
                </Box>
              </Grid>
              <Grid item xs={8} sm={8} md={3} xl={3}>
                <Box style={{ marginTop: "5%",  
    width: "170px",
    marginLeft: "10px" }}>
             
                  <OutlinedInput
                    className="company-title5"
                    fullWidth
                    placeholder="Enter Amount"
                    value={turnover}
                    onChange={(e) => setTurnover(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4} xl={4}>
                <Box
                  style={{ marginTop: "-2%", padding: isMobile ? "2%" : "0%" }}
                >
                  <span
                    className="MuiTypography-body1"
                    className="company-title3"
                  >
                    Limit<span style={{ color: "red" }}>*</span>
                  </span>
                  <OutlinedInput
                    className="company-title5"
                    fullWidth
                    value={limit}
                    placeholder="e.g."
                    onChange={(e) => setLimit(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4} xl={4}>
                <Box style={{ padding: "2%" ,marginTop: "-9px"}}>
                  <Typography className="company-title3">
                    Number of employees in correspondance
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <FormControl fullWidth className="company-title5">
                    <select
                      displayEmpty
                      className="company-title4"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={employee}
                      onChange={(e) => setEmployee(e.target.value)}
                      style={{ height: "100%", border: "0px", lineHeight: "3.5px", backgroundColor: "#F3F5F6" }}
                    //renderValue={(select) => { if (!select) { return <Typography className="company-title4" >Select from options</Typography> } return select }}        // Ye likhna hai
                    >
                      <option value="" disabled={true}>
                        Select from options
                      </option>

                      {empList !== "" ? (
                        empList.map((item) => (
                          <option style={{ fontSize: "18px" }} value={item.id}>{item.name}</option>
                        ))
                      ) : (
                        <></>
                      )}
                    </select>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
            <Typography
              style={{
                padding: isMobile ? "5%" : "2%",
                color: "#0275D8",
                fontSize: "16px",
                fontWeight: "600",
              }}
            >
              Contact Person Details
            </Typography>
           
            {/* {contact.map((x, index) => (
              <> */}
                <Grid container >
                  <Grid item xs={12} sm={8} md={4} xl={4}>
                    <Box style={{ padding: "5%" }}>
                      <span
                        className="MuiTypography-body1"
                        className="company-title3"
                      >
                        Fullname<span style={{ color: "red" }}>*</span>
                      </span>
                      <OutlinedInput
                        className="company-title5"
                        fullWidth
                        value={contactName}
                        placeholder="Enter title"
                        onChange={(e) => setContactName(e.target.value)}
                        // onChange={(e) => changeName(e.target.value, index)}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={8} md={4} xl={4}>
                    <Box style={{ padding: "5%" }}>
                      <span
                        className="MuiTypography-body1"
                        className="company-title3"
                      >
                        Email Address<span style={{ color: "red" }}>*</span>
                      </span>
                      <OutlinedInput
                        className="company-title5"
                        fullWidth
                        value={contactEmail}
                        placeholder="Enter title"
                        onChange={(e) => contactSetEmail(e.target.value)}
                        // onChange={(e) => changeEmail(e.target.value, index)}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={8} md={4} xl={4}>
                    <Grid container >
                      <Grid item xs={4}>
                        <Box style={{ padding: "10%", marginTop: "5%" }}>
                          <Typography className="company-title3">
                            Country<span style={{ color: "red" }}>*</span>
                          </Typography>
                          <FormControl fullWidth className="company-title5">
                            
                            <select

                              displayEmpty
                              style={{ height: "100%", border: "0px", lineHeight: "3.5px", backgroundColor: "#F3F5F6           " }}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={x.country_id}
                              onChange={(e) =>
                                changeCountry(e.target.value, index)
                              }

                            >

                              <option > 🇳🇬</option>
                              {allcountry.map((item) => (
                                <option style={{ fontSize: "22px", backgroundColor: "white", fontSize: "18px" }} value={item.id}>{item.name}</option>
                              ))}
                            </select>
                            {/* </>
                            ) : (
                              ""
                            )}  */}
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={8}>
                        <Box style={{ padding: "9%" }}>
                          <span
                            className="MuiTypography-body1"
                            className="company-title3"
                          >
                            Phone<span style={{ color: "red" }}>*</span>
                          </span>
                          <OutlinedInput
                            className="company-title51"
                            fullWidth
                            placeholder=" e.g. TXN567890 "
                            value={x.phone_number}
                            onChange={(e) => changePhone(e.target.value, index)}
                          // onChange={(e) =>contact[index].phone=e.target.value}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container >
                  <Grid item xs={12} sm={8} md={4} xl={4}>
                    <Box style={{ padding: "5%" }}>
                      <span
                        className="MuiTypography-body1"
                        className="company-title3"
                      >
                        Designation<span style={{ color: "red" }}>*</span>
                      </span>
                      <OutlinedInput
                        className="company-title5"
                        fullWidth
                        placeholder=" e.g. TXN567890 "
                        value={contactDeg}
                        onChange={(e) => setContactDeg(e.target.value)}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={8} md={4} xl={4}>
                    <Box style={{ padding: "5%" }}>
                      <span
                        className="MuiTypography-body1"
                        className="company-title3"
                      >
                        BVN No.<span style={{ color: "red" }}>*</span>
                      </span>
                      <OutlinedInput
                        className="company-title5"
                        fullWidth
                        value={contactBVN}
                        placeholder=" e.g. TXN567890 "
                        onChange={(e) => setConatctBVN(e.target.value)}
                        // onChange={(e) => changeBvn(e.target.value, index)}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={8} md={4} xl={4}>
                    <Box style={{ padding: "5%" }}>
                      <span
                        className="MuiTypography-body1"
                        className="company-title3"
                      >
                        Working at HQ<span style={{ color: "red" }}>*</span>
                      </span>
                      <FormControl fullWidth className="company-title5">
                        <select
                          displayEmpty
                          className="company-title4"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={x.working_at_id}
                          onChange={(e) => changeWork(e.target.value, index)}
                          style={{ height: "100%", border: "0px", lineHeight: "3.5px", backgroundColor: "#F3F5F6           " }}
                        >
                          <option value="" disabled={true}>
                            Select from options
                          </option>

                          {hqList &&
                            hqList.map((item, index) => {
                              return (
                                <select style={{ fontSize: "18px" }} value={item.id}>{item.name}</select>
                              );
                            })}
                        </select>
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>
              {/* </>
            ))} */}

            <Grid
              item
              xs={12}
              sm={12}
              md={5}
              xl={5}
              style={{ marginRight: "10px" }}
            >
              <Box style={{ padding: "5%" }}></Box>
            </Grid>

            <Grid style={{ marginBottom: "50px" }} item lg={12} xs={12} md={12}>
              {isMobile ? (
                <Grid container style={{ padding: isMobile ? "5%" : " " }}>
                  <Grid item xs={6}>
                    <Button
                      style={{
                        textAlign: "center",
                        width: "100%",
                        height: "40px",
                        background: "#479FC8",
                        borderRadius: "5px",
                      }}
                      onClick={onSubmit}
                    >
                      Update
                    </Button>
                  </Grid>

                  <Grid item xs={6}>
                    <Link to={"/signup"}>
                      <Button
                        style={{
                          margin: "2%",
                          textAlign: "center",
                          width: "100%",
                          height: "40px",
                          background: "#EBF8FE",
                          borderRadius: "5px",
                        }}
                      >
                        Cancel
                      </Button>
                    </Link>
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  container
                  xs={12}
                  spacing={3}
                  style={{ marginLeft: "1%" }}
                >
                  <Grid item lg={2} md={3} xl={6}>
                    <Button
                      style={{
                        height: "120%",
                        width: "100%",
                        background: "#479FC8",
                        borderRadius: "5px",
                        color: "#FFFFFF",
                        textTransform: "initial",
                      }}
                      onClick={onSubmit}
                    >
                      Save
                    </Button>
                  </Grid>
                  <Grid item lg={2} md={3} xl={6}>
                    <Link to={"/signup"}>
                      <Button
                        style={{
                          height: "120%",
                          width: "100%",
                          background: "#EBF8FE",
                          borderRadius: "5px",
                          color: "#479FC8",
                          textTransform: "initial",
                        }}
                      >
                        Cancel
                      </Button>
                    </Link>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
      </Box>
    </ThemeContainer>
  );
}

CompanyInfo.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(CompanyInfo);
