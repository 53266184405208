import React from 'react';
import { Line } from 'react-chartjs-2';

const data = {
    labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
    datasets: [
        {
            label: 'Full Invoice Discounting',
            data: [12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3],
            fill: {
                target: 'origin',
                below: 'rgba(0, 153, 255, 0.2)'
            },
            backgroundColor: 'rgba(0, 153, 255, 0.2)',
            borderColor: 'rgba(0, 153, 255, 0.2)',
            tension: 0.3
        },
        {
            label: 'Partial Invoice Discounting',
            data: [8, 10, 4, 5, 12, 9, 8, 10, 4, 5, 12, 9],
            fill: {
                target: 'origin',
                below: 'rgba(0, 153, 255,1)'
            },
            backgroundColor: 'rgba(0, 153, 255, 0.7)',
            borderColor: 'rgba(0, 153, 255, 0.7)',
            tension: 0.3
        },
    ],
};

const options = {

    elements: {
        point: {
            radius: 0
        }
    },
    scales: {
        x: {
            display: false,
            grid: {
                // drawBorder: false, // <-- this removes y-axis line
                lineWidth: 0,
            }
        },
        y: {
            display: false,
            grid: {
                // drawBorder: false, // <-- this removes y-axis line
                lineWidth: 0,
            }
        },
    },
    plugins: {
        title: {
            display: true,
            text: "Full/ Partial",
            align: 'start',
            font: {
                size: 13,
                weight: 'bold'
            },
            padding: 20,
            color: "#479FC8"
        },
        legend: {
            labels: {
                usePointStyle: true,
            },
        }
    }
};

function AreaGraph() {
    return (
        <Line data={data} options={options} />
    );
}

export default AreaGraph;