import React, { useState, useEffect, useContext } from "react";
import styles from "assets/jss/material-kit-react/views/components.js";
import { makeStyles } from "@material-ui/core/styles";
import { isMobile } from "react-device-detect";
import verifyImg from "../../assets/img/verifyOTP.svg";
import googleAuthImg from "../../assets/img/google-auth.svg";
import {
  Grid,
  TextField,
  Typography,
  Button,
  Divider,
  Box,
  Snackbar,
  SnackbarContent,
  OutlinedInput,
} from "@material-ui/core";

import SignupFooter from "../Signup/footer";
import HomePage5 from "views/HomePage/HomePage5";
import image from "../../assets/img/forgot-password.png";
import { Link } from "react-router-dom";
import axios from "axios";
import { Store } from "../../context/store/storeState";
import { useHistory } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
const useStyles = makeStyles(styles);

export default function forgotPassword() {
  const classes = useStyles();
  const api_url = process.env.REACT_APP_URL;
  const [data, setData] = useState();
  const [error, setError] = useState(false);
  const verifyContext = useContext(Store);
  const verify = verifyContext.verify;
  const setVerify = verifyContext.setVerify;
  const history = useHistory();
  const [userInputOtp, setUserInputOtp] = useState({});
  const [open, setOpen] = useState(false);
  const [img, setImg] = useState();
  const [message, setMessage] = useState({});
  let user_id =
    localStorage.getItem("userId") == null ||
    localStorage.getItem("userId") == undefined
      ? verify.userId
      : parseInt(localStorage.getItem("userId"));
  let access_token =
    localStorage.getItem("access_token") == null ||
    localStorage.getItem("access_token") == undefined
      ? verify.accessToken
      : localStorage.getItem("access_token"); 

  const validation = () => {

    if (
      userInputOtp !== {} &&
      userInputOtp !== null &&
      userInputOtp !== undefined &&
      userInputOtp.digit1 !== "" &&
      userInputOtp.digit2 !== "" &&
      userInputOtp.digit3 !== "" &&
      userInputOtp.digit4 !== "" &&
      userInputOtp.digit5 !== "" &&
      userInputOtp.digit6 !== ""
    ) {
      return true;
    }
    setError(true);
    return false;
  };

  const onSubmit = () => {
    const validate = validation();
    if (validate) {
      const otp =
        userInputOtp.digit1 +
        userInputOtp.digit2 +
        userInputOtp.digit3 +
        userInputOtp.digit4 +
        userInputOtp.digit5 +
        userInputOtp.digit6;
      let body = {
        user_id,
        access_token,
        qr_code: otp,
      };
      axios
        .post(api_url + "/api/v1/validate/google/qrcode", body, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then((res) => {
          if (res.data.success) {
            localStorage.setItem("userName", res.data.user_name); //Setting username and password to local storage
            localStorage.setItem("userId", res.data.user_id);
            localStorage.setItem("user_type", res.data.user_type);
            localStorage.setItem("user_role", res.data.user_role);
            localStorage.setItem("access_token", res.data.access_token);
            localStorage.setItem("user_email", res.data.user_email);
            localStorage.setItem("kyc_type", res.data.kyc_type);
            localStorage.setItem("kyc_status", res.data.kyc_status);
            localStorage.setItem("currency_name", res.data.currency_name);
            localStorage.setItem("is_lender", res.data.is_lender);
            localStorage.setItem("currency_symbol", res.data.currency_symbol );
            localStorage.setItem("redirect_company_info", res.data.redirect_company_info);
            localStorage.setItem("is_manual_kyc_enabled", res.data.is_manual_kyc_enabled);
            if(res.data.user_type === "both"){
              localStorage.setItem("trader_type", "supplier");
            }else if(res.data.user_type === "buyer"){
              localStorage.setItem("trader_type", "buyer");
            }else{

            }
            if(res.data.user_type === "buyer"){
              window.location.href = "/buyer/dashboard"
            }else if(res.data.is_lender === true){
              window.location.href = "/lender/dashboard"
            }else{
              window.location.href = "/supplierdash"
            }
          } else {
            setOpen(true);
            setMessage({ type: "error", content: res.data.message });
          }
        });
    }
  };
  const tabChange = function (val, e) {
    e.preventDefault();
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      let ele = document.querySelectorAll("input");
      if (ele[val - 1].value != "") {
        if (typeof ele[val] != "undefined") {
          ele[val].focus();
        }
      } else if (ele[val - 1].value == "") {
        if (typeof ele[val - 2] != "undefined") {
          ele[val - 2].focus();
        }
      }

      setUserInputOtp({ ...userInputOtp, [e.target.name]: e.target.value });
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const onCancel = () => {
    // localStorage.clear();
    history.push("/verifyOTP");
  };
  const copyToClip = () => {
    navigator.clipboard.writeText(data.secret_key);
    setOpen(true);
    setMessage({ type: "success", content: "Secret Key Copied" });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    if (user_id !== null && access_token !== null) {
      let body = {
        user_id,
        access_token,
      };
      axios
        .post(api_url + "/api/v1/generate/google/qrcode", body, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then((res) => {
          if (res.data.success) {
            let resp = res.data;
            if (resp.totp_enabled) {
              setOpen(true);
              setMessage({
                type: "success",
                content: "Please enter Google Authentication Code",
              });
            } else {
              let base64 = res.data.qr_code;
              let imgs = base64.split("'");
              setImg(imgs[1]);
            }
            setData(res.data);
          } else {
            setOpen(true);
            setMessage({ type: "error", content: "Something went wrong" });
          }
        });
    }
  }, []);
  return (
    <>
      {" "}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <SnackbarContent className={message.type === "error" ? "snack-error" : message.type === "success" ? "snack-success" : "snack-teal" } message={message.content}
        />
      </Snackbar>
      {data && data !== undefined && data.success && (
        <>
          <Box className="forgot-password" >
            <Box className="scf-box" >
              <Box className="scf-subbox" >
                {isMobile ? (
                  <>
                    {img && img !== "" ? (
                      <>
                        <Grid container spacing={12}>
                          <Grid item xs={12} sm={12} md={6} xl={6}>
                            <div className="text-center qrcodecopy">
                              <img className="mobile-img" src={`data:image/png;base64,${img}`} />

                              <Row>
                                <Col md={3}>
                                  <Typography variant="button" display="block">
                                    Secret Key :
                                  </Typography>
                                </Col>
                                <Col md={8}>
                                  {" "}
                                  <Typography variant="button" display="block">
                                    {data.secret_key}
                                  </Typography>
                                </Col>
                                <Col md={1}>
                                  <i className="far fa-copy" onClick={() => copyToClip()} ></i>
                                </Col>
                              </Row>
                            </div>
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      <img className="mobile-img" src={image} />
                    )}
                  </>
                ) : (
                  ""
                )}

                <Grid container spacing={10}>
                  <Grid item xs={12} sm={12} md={6} xl={6}>
                    <Box>
                      <Grid item xs={12}>
                        <Grid item xs={12} className="heading" >
                          Google Verification
                        </Grid>
                        <Grid >
                          <Typography className="text">
                            Please use google authentication app for 2 Factor
                            Authentication, 2FA enter below to Authenticate your
                            Identity
                          </Typography>
                        </Grid>
                        <Grid className="company-title3 FA">
                          2FA
                        </Grid>
                      </Grid>
                      <Grid item xs={12}> 
                        <Grid container>
                          <Grid item xm={2} xs={2} md={2} lg={2} xl={2}>
                            <input
                              id="input1"
                              className="otp-all-input"
                              type="text"
                              name="digit1"
                              maxLength={1}
                              inputMode="numeric"
                              pattern="[0-9]*"
                              onKeyUp={(e) => {
                                tabChange(1, e);
                              }}
                            />
                          </Grid>
                          <Grid item xm={2} xs={2} md={2} lg={2} xl={2}>
                            <input
                              id="input1"
                              className="otp-all-input"
                              type="text"
                              name="digit2"
                              maxLength={1}
                              inputMode="numeric"
                              pattern="[0-9]*"
                              onKeyUp={(e) => {
                                tabChange(2, e);
                              }}
                            />
                          </Grid>
                          <Grid item xm={2} xs={2} md={2} lg={2} xl={2}>
                            <input
                              id="input1"
                              className="otp-all-input"
                              type="text"
                              name="digit3"
                              maxLength={1}
                              inputMode="numeric"
                              pattern="[0-9]*"
                              onKeyUp={(e) => {
                                tabChange(3, e);
                              }}
                            />
                          </Grid>
                          <Grid item xm={2} xs={2} md={2} lg={2} xl={2}>
                            <input
                              id="input1"
                              className="otp-all-input"
                              type="text"
                              name="digit4"
                              maxLength={1}
                              inputMode="numeric"
                              pattern="[0-9]*"
                              onKeyUp={(e) => {
                                tabChange(4, e);
                              }}
                            />
                          </Grid>
                          <Grid item xm={2} xs={2} md={2} lg={2} xl={2}>
                            <input
                              id="input1"
                              className="otp-all-input"
                              type="text"
                              name="digit5"
                              maxLength={1}
                              inputMode="numeric"
                              pattern="[0-9]*"
                              onKeyUp={(e) => {
                                tabChange(5, e);
                              }}
                            />
                          </Grid>
                          <Grid item xm={2} xs={2} md={2} lg={2} xl={2}>
                            <input
                              id="input1"
                              className="otp-all-input"
                              type="text"
                              name="digit6"
                              maxLength={1}                              
                              inputMode="numeric"
                              pattern="[0-9]*"
                              onKeyUp={(e) => {
                                tabChange(6, e);
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      {error && error === true && (
                        <Grid className="company-title3" style={{ color: "red" }} >
                          Please fill all the fields
                        </Grid>
                      )}
                      <Grid container spacing={3} className="button-grid">
                        <Grid item xs={6} sm={4} md={5} xl={5}>
                            <Button className="save" onClick={() => onSubmit()} >Verify Now</Button>
                        </Grid>
                        <Grid item xs={6} sm={4} md={5} xl={5}>
                            <Button className="cancel" onClick={() => onCancel()}>Cancel</Button>
                        </Grid>
                      </Grid> 
                    </Box>
                  </Grid>
                  {isMobile ? (
                    " "
                  ) : (
                    <Grid item xs={12} sm={12} md={5} xl={5}>
                      {img && img !== "" ? (
                        <>
                          <img className="mobile-img" src={`data:image/png;base64,${img}`} />
                          <Row>
                            <Col md={3}>
                              <Typography variant="button" display="block">
                                Secret Key :
                              </Typography>
                            </Col>
                            <Col md={8}>
                              {" "}
                              <Typography variant="button" display="block">
                                {data.secret_key}
                              </Typography>
                            </Col>
                            <Col md={1}>
                              <i className="far fa-copy" onClick={() => copyToClip()}
                              ></i>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <img className="mobile-img" src={image} />
                      )}
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Box>
          </Box>
        </>
      )}
      <HomePage5 />
      {isMobile ? " " : <SignupFooter />}
    </>
  );
}
