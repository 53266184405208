import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom"
import {
    Grid,
    OutlinedInput,
    MenuItem,
    Box,
    withStyles,
    Typography,
    FormControl,
    Select,
    Button,
    Table,
    TableBody,
    TableContainer,
    TableCell,
    TableRow,
    Paper,
    Snackbar,
    SnackbarContent
} from "@material-ui/core";
import PropTypes from 'prop-types';
import ThemeContainer from 'views/Components/sideBar/ThemeContainer';
import { getAccessToken } from "../../utilities/tokenUtil";
import { Spinner } from "react-bootstrap";
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@mui/material/Tooltip';

var axios = require("axios");

const styles = theme => ({
    textFields: {
        background: "#F3F5F6",
        height: "40px",
        marginTop: "1%",
        "& div": {
            "&::before": {
                content: "",
                borderBottom: "none",

            },
            "&::after": {
                borderBottom: "none"
            },
            "&:hover": {
                "&::before": {
                    borderBottom: "none !important"
                },
                "&::after": {
                    borderBottom: "none !important"
                },
            },
            "& .MuiInputBase-input": {
                padding: "9px 7px"
            }
        },

    },

})

function CreditOffers(props) {

    const userId = localStorage.getItem("userId");
    const userName = localStorage.getItem("userName");
    const kyc_status = localStorage.getItem("kyc_status");
    const trader_type = localStorage.getItem("trader_type");
    const currency_symbol = localStorage.getItem("currency_symbol");

    const history = useHistory();
    const [creditOfferList, setCreditOfferList] = useState([]);
    const [message, setMessage] = useState({});
    const [open, setOpen]           = useState(false);
    const [loader, setLoader] = useState(false);

    if (!userId || userName === null) {
        history.push('/signin');
    }
    if (kyc_status !== "Approved") {
        history.push('/kyc-page');
    }
    if (trader_type !== "supplier") {
        history.push('/invoice');
    }

    const allCreditOfferList = () => {
        const access_token = getAccessToken();
        const data = {
            "access_token": access_token,
            "user_id": parseInt(localStorage.getItem("userId")),
        };

        axios
            .post(`${process.env.REACT_APP_URL}/api/v1/supplier/credit_offer/list`, data, {
                headers: {
                    "Content-Type": "text/plain",
                },
            })
            .then((response) => {
                if (response.data.success) {
                    setCreditOfferList(response.data.data)
                    setLoader(true)
                    setOpen(true);
                    setMessage({ type: "success", content: response.data.message });
                } else {
                    setOpen(true);
                    setLoader(true);
                    setMessage({ type: "error", content: response.data.message });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    useEffect(() => {
        document.title = 'Interswitch - Credit Offers';
        allCreditOfferList();
    }, []);
    const { classes } = props;

    return (
        <ThemeContainer type="private" value={2}>
            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={open} autoHideDuration={3000} onClose={handleClose}
            >
                <SnackbarContent className={message.type === "error" ? "snack-error" : message.type === "success" ? "snack-success" : "snack-teal"} message={message.content} />
            </Snackbar>
            {loader !== true ? (
                <Grid container spacing={3} className="bootstrap-loader">
                    <Spinner animation="grow" variant="spinner1" />
                    <Spinner animation="grow" variant="spinner2" />
                    <Spinner animation="grow" variant="spinner3" />
                </Grid>
            ) : (
                <>
                    <Box className="scf-box">
                        <Box className="scf-subbox">

                            <Box>
                                <Grid container>
                                    <Grid item xs={9} sm={12} md={11} xl={11}>
                                        <Typography className="listing-main-title">Credit Offers</Typography>
                                    </Grid>
                                </Grid>

                                <Typography className="list-sub-title">Credit Offers List</Typography>
                            </Box>
                            <Box>
                                <TableContainer component={Paper}>
                                    <Table className="table-top" aria-label="simple table">

                                        <TableRow className="table-row">
                                            <TableCell className="table-header">S. No. </TableCell>
                                            <TableCell align="left" className="table-header">Supplier Name</TableCell>
                                            <TableCell align="left" className="table-header">Lender Name</TableCell>
                                            <TableCell align="left" className="table-header">Lender Email</TableCell>
                                            <TableCell align="left" className="table-header">Expected Interest Rate (%)</TableCell>
                                            <TableCell align="left" className="table-header">Status</TableCell>
                                        </TableRow>

                                        <TableBody >
                                            {creditOfferList.length > 0 ? (
                                                <>
                                                    {creditOfferList ? creditOfferList.map((row, index) =>
                                                        <TableRow className="table-row" key={row.name} >
                                                            <TableCell component="th" scope="row" onClick={() => history.push("/creditofferdetail/" + (row.credit_offer_id))}> {index + 1} </TableCell>
                                                            <TableCell align="left" onClick={() => history.push("/creditofferdetail/" + (row.credit_offer_id))}> {row.supplier_name} </TableCell>
                                                            <TableCell align="left" onClick={() => history.push("/creditofferdetail/" + (row.credit_offer_id))}> {row.lender_name} </TableCell>
                                                            <TableCell align="left" onClick={() => history.push("/creditofferdetail/" + (row.credit_offer_id))}> {row.lender_email} </TableCell>
                                                            <TableCell align="left" onClick={() => history.push("/creditofferdetail/" + (row.credit_offer_id))}> {row.expected_interest_rate} </TableCell>
                                                            <TableCell align="left" onClick={() => history.push("/creditofferdetail/" + (row.credit_offer_id))} className={row.status.toLowerCase().replace(/\s/g, '-')}>
                                                                {row.status} {" "}
                                                                {row.rejection_reason &&
                                                                    <Tooltip title={row.rejection_reason} placement="top" arrow>
                                                                        <InfoIcon />
                                                                    </Tooltip>
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    ) : null}
                                                </>
                                            ) : (
                                                <TableRow >
                                                    <TableCell colSpan={6} className="nodata">
                                                        No Data to Display
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>

                        </Box>
                    </Box>
                </>
            )}
        </ThemeContainer>
    );
}

CreditOffers.propTypes = {
    classes: PropTypes.object
}

export default withStyles(styles)(CreditOffers)