import React, { useEffect, useState } from "react";
import { Grid, Divider } from "@material-ui/core";
import logo from "../../assets/img/I.svg";
import facebook from "../../assets/img/facebook.svg";
import insta from "../../assets/img/insta.svg";
import twitter from "../../assets/img/twitter.svg";
import youtube from "../../assets/img/youtube.svg";
import linkedin from "../../assets/img/linkedin.svg";
import { isMobile } from "react-device-detect";
var axios = require("axios");

export default function SignupFooter() {
  const [links, setLinks] = useState({});
  useEffect(() => {
    const config = {
      method: "get",
      url: process.env.REACT_APP_URL + "/api/v1/footer",
    };
    axios(config)
      .then((response) => {
        if (response && response.data.success) {
          setLinks(response.data.social_media_dict);
          //console.log(response);
        } else {
          //console.log(JSON.stringify(response.data));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  return (
    <div className="bottom-footer">
      <Grid container>
        <Grid item xs={12} className="footer-divider">
          <Divider />
        </Grid>
        <Grid item xs={4} sm={8} md={4} xl={4} className="footer-logo">
          <a href="/">
            <img src={logo} />
          </a>
        </Grid>
        <Grid item xs={8} sm={4} md={5} xl={5} className="copy-write">
          <span>
            © 2002 - {new Date().getFullYear()} Interswitch Group
          </span>
        </Grid>
        <Grid item xs={12} sm={12} md={3} xl={3} className="footer-social">
          <a href={links.social_linkedin} className="social-img" target="_blank">
            <img src={linkedin} />
          </a>
          <a href={links.social_twitter} className="social-img" target="_blank">
            <img src={twitter} />
          </a>
          <a href={links.social_facebook} className="social-img" target="_blank">
            <img src={facebook} />
          </a>
          <a href={links.social_instagram} className="social-img" target="_blank">
            <img src={insta} />
          </a>
          <a href={links.social_youtube} className="social-img" target="_blank" >
            <img src={youtube} />
          </a>
        </Grid>
      </Grid>
    </div>
  );
}
