import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  OutlinedInput,
  MenuItem,
  FormControl,
  Select,
  withStyles,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Snackbar,
  SnackbarContent,
  IconButton,
} from "@material-ui/core";
import preview from "../../../../assets/img/previewButton.svg";
import Custom from "views/Components/Custom";
import PropTypes from "prop-types";
import { isMobile } from "react-device-detect";
import TableComponent from "views/Components/tableComponent/tablecomponent";
import { getAccessToken } from "../../../../utilities/tokenUtil";
import { setAccessToken } from "utilities/tokenUtil";
import { LocalHospitalTwoTone } from "@material-ui/icons";
import image1 from "assets/img/coun1.svg";
import image11 from "assets/img/write(2).png";
import image2 from "assets/img/right(1).png";
import image3 from "assets/img/delete(3).png";
import image4 from "assets/img/eyes.png";
import EditImgIcon from "../../../../assets/img/EditImgIcon.svg";
import DeleteImgIcon from "../../../../assets/img/DeleteImgIcon.svg";
import View from "assets/img/view.svg";
import Updatestatus from "assets/img/updatestatus.svg";
import Addedit from "assets/img/add-edit.svg";
import Delete from "assets/img/delete.svg";
import EditOutlinedIcon from "@material-ui/icons/BorderColor";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ThemeContainer from "views/Components/sideBar/ThemeContainer";
import { Link, useHistory } from "react-router-dom";
import { Spinner } from "react-bootstrap";

var axios = require("axios");

const styles = () => ({
  textFields: {
    background: "#F3F5F6",
    height: "40px",
    marginTop: "1%",
    "& div": {
      "&::before": {
        content: "",
        borderBottom: "none",
      },
      "&::after": {
        borderBottom: "none",
      },
      "&:hover": {
        "&::before": {
          borderBottom: "none !important",
        },
        "&::after": {
          borderBottom: "none !important",
        },
      },
      "& .MuiInputBase-input": {
        padding: "9px 7px",
      },
    },
  },
});

function AddBuyer(props) {
  const trader_type = localStorage.getItem("trader_type");
  const userId = localStorage.getItem("userId");
  const userName = localStorage.getItem("userName");
  const kyc_status = localStorage.getItem("kyc_status");

  const history = useHistory();
  const [listBuyer, setListBuyer] = useState(true);
  const [addBuyer, setAddBuyer] = useState(false);
  const [editBuyer, setEditBuyer] = useState(false);
  const [buyerList, setBuyerList] = useState([]);
  const [buyerName, setBuyerName] = useState("");
  const [buyerEmail, setBuyerEmail] = useState("");
  const [businessaddress, setBusinessAddress] = useState("");
  const [businesszip, setBusinessZip] = useState("");
  const [businesscountry, setBusinessCountry] = useState("");
  const [businessstate, setBusinessState] = useState("");
  const [businesscity, setBusinessCity] = useState("");
  const [allcountry, setcountry] = useState([]);
  const [allState1, setAllState1] = useState();
  const [allState2, setAllState2] = useState();
  const [fullname, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [taxation, setTaxation] = useState("");
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState({});
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [readonly, setReadOnly] = useState(false);
  const [file, setfile] = useState();
  const [fileView, setfileView] = useState();
  const [fileView1, setfileView1] = useState();
  const [name, setName] = useState("");
  const [registration, setRegistration] = useState("");
  const [entity, setEntity] = useState("");
  const [entityList, setentityList] = useState("");
  const [documents, setDocuments] = useState("");
  const [documenttype, setDocumentType] = useState("");
  const [buyer_supplier_id, setBuyerSupplierId] = useState("");
  const [status, setStatus] = useState("");
  const [document_id, setDocumentId] = useState("");
  const [document_type_id, setDocumentTypeId] = useState("");
  const [trader_documents, setTraderDocuments] = useState("");
  const [buyerTaxNumber, setbuyerTaxNumber] = useState("");
  const [inviteButton, setInviteButton] = useState(true);
  const [loader, setLoader] = useState(false);

  if (!userId || userName === null) {
    history.push("/signin");
  }
  if (kyc_status !== "Approved") {
    history.push("/kyc-page");
  }
  const allbuyerList = () => {
    const access_token = getAccessToken();

    const data = {
      access_token: access_token,
      user_id: parseInt(localStorage.getItem("userId")),
      page_no: 1,
      //"user_type": localStorage.getItem("trader_type"),
    };

    let api_url;
    if (trader_type === "buyer") {
      api_url = "/api/v1/supplier/list";
    } else {
      api_url = "/api/v1/buyer/list";
    }

    axios
      .post(`${process.env.REACT_APP_URL}` + api_url, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        setBuyerList(response.data.trader_list);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  function handleAddBuyer() {
    /*setListBuyer(!listBuyer)
        setAddBuyer(!addBuyer)
        setEditBuyer(editBuyer)*/

    setTraderDocuments("");
    setBusinessAddress("");
    setBusinessCity("");
    setEntity("");
    setBusinessCountry("");
    setEmail("");
    setPhone("");
    setName("");
    setRegistration("");
    setBusinessState("");
    setTaxation("");
    setBusinessZip("");
    setfileView1("");
  }
  function handleCancelAddBuyer() {
    setListBuyer(!listBuyer);
    setAddBuyer(!addBuyer);
    setEditBuyer(editBuyer);
  }
  function handleCancelEditBuyer() {
    setListBuyer(!listBuyer);
    setAddBuyer(addBuyer);
    setEditBuyer(!editBuyer);
  }
  const filter = () => {
    const access_token = getAccessToken();
    let traderType = trader_type === "buyer" ? "supplier" : "buyer";
    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: access_token,
      user_type: traderType,
      trader_name: buyerName,
      email: buyerEmail,
      tax_number: buyerTaxNumber,
      status: status,
    };

    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/trader/list/search`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        setBuyerList(response.data.trader_list);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const clearSearch = () => {
    setStatus("");
    setBuyerName("");
    setBuyerEmail("");
    setbuyerTaxNumber("");
    allbuyerList();
  };
  const masterFunction = () => {
    const access_token = getAccessToken();

    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: access_token,
      user_type: localStorage.getItem("trader_type"),
    };

    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/trader/master`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        if (response) {
          setentityList(response.data.entity_type);
          setDocuments(response.data.documents);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const traderDetail = () => {
    const access_token = getAccessToken();
    let traderType = trader_type === "buyer" ? "supplier" : "buyer";
    if (buyer_supplier_id) {
      const data = {
        user_id: parseInt(localStorage.getItem("userId")),
        access_token: access_token,
        trader_type: traderType,
        id: parseInt(buyer_supplier_id),
      };

      setLoader(false);
      axios
        .post(`${process.env.REACT_APP_URL}/api/v1/trader/detail`, data, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then((response) => {
          if (response) {
            setTraderDocuments(response.data.documents);
            setLoader(true);
          } else {
            setLoader(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setLoader(true);
    }
  };
  const handleTeamMemberImageChage = (event) => {
    const access_token = getAccessToken();
    if (event.target.files && event.target.files[0]) {
      let fileext = event.target.files[0];
      if (fileext.name.match(/\.(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/)) {
        if (fileext.size > 2000000) {
          setOpen(true);
          setMessage({
            type: "error",
            content: "Image size should be less than 2MB.",
          });
          return;
        }
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setfileView(reader.result);
        });
        setfile(event.target.files[0]);
        setfileView1("");
        reader.readAsDataURL(event.target.files[0]);
      } else {
        setLoading(false);
        setOpen(true);
        setMessage({
          type: "error",
          content: "Only jpg, jpeg, png files are allowed!",
        });
      }
    }
  };
  const countryFunction = () => {
    const access_token = getAccessToken();
    const userID = localStorage.getItem("userId");

    const data = {
      user_id: localStorage.getItem("userId"),
      access_token: access_token,
    };

    axios
      .post(
        `${process.env.REACT_APP_URL}/api/v1/country/master`,
        JSON.stringify(data)
      )
      .then((response) => {
        if (response && response.data.sucess) {
          setcountry(response.data.country);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const fetchState = (country_id, data1) => {
    const dataSet = JSON.stringify({
      country_id: country_id,
    });
    const config = {
      method: "post",
      url: process.env.REACT_APP_URL + "/api/v1/state/master",
      headers: {
        "Content-Type": "text/plain",
      },
      data: dataSet,
    };
    axios(config)
      .then((response) => {
        if (data1 == "allstate1") {
          setAllState1(response.data.state);
        }
        if (data1 == "allstate2") {
          setAllState2(response.data.state);
        }

        setUserState(response.data.state);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const fetchState1 = (country_id, data1, state) => {
    const dataSet = JSON.stringify({
      country_id: country_id,
    });
    const config = {
      method: "post",
      url: process.env.REACT_APP_URL + "/api/v1/state/master",
      headers: {
        "Content-Type": "text/plain",
      },
      data: dataSet,
    };
    axios(config)
      .then((response) => {
        if (data1 == "allstate1") {
          setAllState1(response.data.state);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handlePreview = (image) => {
    setToDisplay(image);
    setOpen1(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const onBlurAddName = () => {
    const access_token = getAccessToken();

    if (
      name.trim() === "" ||
      name.trim() === undefined ||
      registration.trim() === "" ||
      registration.trim() === undefined ||
      taxation.trim() === "" ||
      taxation.trim() === undefined ||
      email.trim() === "" ||
      email.trim() === undefined ||
      businessaddress.trim() === "" ||
      businessaddress.trim() === undefined ||
      businesscountry === "" ||
      businesscountry === undefined
    ) {
      setLoading(false);
      setOpen(true);
      setMessage({ type: "error", content: "All fields are mandatory." });
      return;
    }

    /*if (taxation.length < 15) {
          setLoading(false);
          setOpen(true);
          setMessage({ type: "error", content: "Taxation expected format is 12AAAAA1234AAZA & minimum 15 length" });
          return;
        }*/
    let traderType = trader_type === "buyer" ? "supplier" : "buyer";
    let data = new FormData();
    data.append("id", parseInt(buyer_supplier_id ? buyer_supplier_id : 0));
    data.append("user_id", parseInt(localStorage.getItem("userId")));
    data.append("access_token", access_token);
    data.append("trader_type", traderType);
    data.append("name", name.trim());
    data.append("mobile", phone.trim());
    data.append("email", email.trim());
    data.append("address", businessaddress.trim());
    data.append("zip", businesszip.trim());
    data.append("country_id", parseInt(businesscountry));
    data.append("state_id", parseInt(businessstate));
    data.append("city", businesscity.trim());
    data.append("company_entity", parseInt(entity));
    data.append("tax_number", taxation.trim());
    data.append("registration_no", registration.trim());
    data.append("image", file);

    const config = {
      method: "post",
      url: process.env.REACT_APP_URL + "/api/v1/trader/create",
      headers: {
        "Content-Type": "text/plain",
      },
      data: data,
    };
    axios(config)
      .then((response) => {
        if (response && response.data) {
          if (response.data.success === true) {
            setBuyerSupplierId(response.data.buyer_supplier_id);
            setMessage(response.data.message);
            return traderDetail();
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const sendinvitation = () => {
    const access_token = getAccessToken();

    if (
      name.trim() === "" ||
      name.trim() === undefined ||
      registration.trim() === "" ||
      registration.trim() === undefined ||
      taxation.trim() === "" ||
      taxation.trim() === undefined ||
      email.trim() === "" ||
      email.trim() === undefined ||
      businessaddress.trim() === "" ||
      businessaddress.trim() === undefined ||
      businesscountry === "" ||
      businesscountry === undefined
    ) {
      setLoading(false);
      setOpen(true);
      setMessage({ type: "error", content: "Please fill mandatory fields." });
      return;
    }

    let regEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let mobile_validate =
      /^(?:\+)?\s*[0][1-9]\d{9,12}$|^(?:\+)?\s*[1-9]\d{9,12}$/;

    let rn_validate = /^(?=.*?[aA-Za])(?=.*?[0-9]).{8}$/;
    if (email) {
      if (!regEmail.test(email)) {
        setLoading(false);
        setOpen(true);
        setMessage({ type: "error", content: "Please enter a valid email." });
        return false;
      }
    }
    if (phone) {
      if (!phone.replace(/\s/g, "").match(mobile_validate)) {
        setLoading(false);
        setOpen(true);
        setMessage({
          type: "error",
          content: "Mobile number should be 10 to 13 digit",
        });
        return false;
      }
    }
    if (!registration.match(rn_validate)) {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "Registration number should be 8 digit & character",
      });
      return;
    }

    let uploadFile;
    if (file) {
      uploadFile = file;
    } else {
      uploadFile = "";
    }

    let traderType = trader_type === "buyer" ? "supplier" : "buyer";
    let data = new FormData();
    data.append("id", parseInt(buyer_supplier_id ? buyer_supplier_id : 0));
    data.append("user_id", parseInt(localStorage.getItem("userId")));
    data.append("access_token", access_token);
    data.append("trader_type", traderType);
    data.append("name", name.trim());
    data.append("mobile", phone ? phone.trim() : "");
    data.append("email", email.trim());
    data.append("address", businessaddress.trim());
    data.append("zip", businesszip ? businesszip.trim() : "");
    data.append("country_id", parseInt(businesscountry));
    data.append("state_id", businessstate ? parseInt(businessstate) : 0);
    data.append("city", businesscity ? businesscity.trim() : "");
    data.append("company_entity", entity ? parseInt(entity) : 0);
    data.append("tax_number", taxation.trim());
    data.append("registration_no", registration.trim());
    data.append("image", uploadFile);

    setLoader(false);

    const config = {
      method: "post",
      url: process.env.REACT_APP_URL + "/api/v1/trader/create",
      headers: {
        "Content-Type": "text/plain",
      },
      data: data,
    };
    axios(config)
      .then((response) => {
        if (response && response.data) {
          if (response.data.success === true) {
            let body = {
              user_id: parseInt(localStorage.getItem("userId")),
              access_token: access_token,
              email: email,
              id: parseInt(response.data.buyer_supplier_id),
            };
            const configs = {
              method: "post",
              url: process.env.REACT_APP_URL + "/api/v1/send/trader/invitation",
              headers: {
                "Content-Type": "text/plain",
              },
              data: body,
            };
            axios(configs).then((res) => {
              if (res.data.success) {
                setLoading(false);
                setOpen(true);
                setMessage({ type: "success", content: res.data.message });
                setTimeout(() => {
                  if (trader_type === "buyer") {
                    history.push("/supplier");
                  } else {
                    history.push("/buyer");
                  }
                }, 3000);
                allbuyerList();
              } else {
                setLoader(true);
              }
            });
          } else {
            setLoading(false);
            setOpen(true);
            setLoader(true);
            setMessage({ type: "error", content: response.data.message });
          }
        } else {
          setLoader(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSubmit = () => {
    const access_token = getAccessToken();

    if (
      name.trim() === "" ||
      name.trim() === undefined ||
      registration.trim() === "" ||
      registration.trim() === undefined ||
      taxation.trim() === "" ||
      taxation.trim() === undefined ||
      email.trim() === "" ||
      email.trim() === undefined ||
      businessaddress.trim() === "" ||
      businessaddress.trim() === undefined ||
      businesscountry === "" ||
      businesscountry === undefined
    ) {
      setLoading(false);
      setOpen(true);
      setMessage({ type: "error", content: "Please fill mandatory fields." });
      return;
    }

    let regEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let mobile_validate =
      /^(?:\+)?\s*[0][1-9]\d{9,12}$|^(?:\+)?\s*[1-9]\d{9,12}$/;

    let rn_validate = /^(?=.*?[aA-Za])(?=.*?[0-9]).{8}$/;
    if (email) {
      if (!regEmail.test(email)) {
        setLoading(false);
        setOpen(true);
        setMessage({ type: "error", content: "Please enter a valid email." });
        return false;
      }
    }
    if (phone) {
      if (!phone.replace(/\s/g, "").match(mobile_validate)) {
        setLoading(false);
        setOpen(true);
        setMessage({
          type: "error",
          content: "Mobile number should be 10 to 13 digit",
        });
        return false;
      }
    }
    if (!registration.match(rn_validate)) {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "Registration number should be 8 digit & character",
      });
      return;
    }

    let uploadFile;
    if (file) {
      uploadFile = file;
    } else {
      uploadFile = "";
    }
    /*const reader = new FileReader();
        reader.addEventListener("load", () => {
            setfile(reader.result);
        });
        reader.readAsDataURL(event.target.files[0]);*/
    let traderType = trader_type === "buyer" ? "supplier" : "buyer";
    let data = new FormData();
    data.append("id", parseInt(buyer_supplier_id ? buyer_supplier_id : 0));
    data.append("user_id", parseInt(localStorage.getItem("userId")));
    data.append("access_token", access_token);
    data.append("trader_type", traderType);
    data.append("name", name.trim());
    data.append("mobile", phone ? phone.trim() : "");
    data.append("email", email.trim());
    data.append("address", businessaddress.trim());
    data.append("zip", businesszip ? businesszip.trim() : "");
    data.append("country_id", parseInt(businesscountry));
    data.append("state_id", businessstate ? parseInt(businessstate) : 0);
    data.append("city", businesscity ? businesscity.trim() : "");
    data.append("company_entity", entity ? parseInt(entity) : 0);
    data.append("tax_number", taxation.trim());
    data.append("registration_no", registration.trim());
    data.append("image", uploadFile);

    setLoader(false);

    const config = {
      method: "post",
      url: process.env.REACT_APP_URL + "/api/v1/trader/create",
      headers: {
        "Content-Type": "text/plain",
      },
      data: data,
    };
    axios(config)
      .then((response) => {
        if (response && response.data) {
          if (response.data.success === true) {
            setLoading(false);
            setOpen(true);
            setMessage({ type: "success", content: response.data.message });
            setTimeout(() => {
              if (trader_type === "buyer") {
                history.push("/supplier");
              } else {
                history.push("/buyer");
              }
            }, 3000);
            /*setListBuyer(!listBuyer);
                    setAddBuyer(!addBuyer);
                    setEditBuyer(editBuyer);*/
            allbuyerList();
            setLoader(true);
          } else {
            setLoading(false);
            setOpen(true);
            setMessage({ type: "error", content: response.data.message });
            setLoader(true);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const documentUpload = (event, document_name, document_type_id) => {
    if (
      name === "" ||
      name === undefined ||
      registration === "" ||
      registration === undefined ||
      taxation === "" ||
      taxation === undefined ||
      email === "" ||
      email === undefined ||
      businessaddress === "" ||
      businessaddress === undefined ||
      businesscountry === "" ||
      businesscountry === undefined
    ) {
      setLoading(false);
      setOpen(true);
      setMessage({ type: "error", content: "Please fill mandatory fields." });
      return;
    }

    let regEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let mobile_validate =
      /^(?:\+)?\s*[0][1-9]\d{9,12}$|^(?:\+)?\s*[1-9]\d{9,12}$/;

    let rn_validate = /^(?=.*?[aA-Za])(?=.*?[0-9]).{8}$/;
    if (email) {
      if (!regEmail.test(email)) {
        setLoading(false);
        setOpen(true);
        setMessage({ type: "error", content: "Please enter a valid email." });
        return false;
      }
    }
    if (phone) {
      if (!phone.replace(/\s/g, "").match(mobile_validate)) {
        setLoading(false);
        setOpen(true);
        setMessage({
          type: "error",
          content: "Mobile number should be 10 to 13 digit",
        });
        return false;
      }
    }
    if (!registration.match(rn_validate)) {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "Registration number should be 8 digit & character",
      });
      return;
    }

    const access_token = getAccessToken();
    const reader = new FileReader();
    /*reader.addEventListener("load", () => {
            setfile(reader.result);
        });*/
    setfile(event.target.files[0]);
    reader.readAsDataURL(event.target.files[0]);

    let data = new FormData();
    data.append("document_id", 0);
    data.append("user_id", parseInt(localStorage.getItem("userId")));
    data.append("access_token", access_token);
    data.append("document_type_id", parseInt(document_type_id));
    data.append("buyer_supplier_id", parseInt(buyer_supplier_id));
    data.append("document_name", document_name);
    data.append("document_data", event.target.files[0]);

    const config = {
      method: "post",
      url: process.env.REACT_APP_URL + "/api/v1/trader/document/upload",
      headers: {
        "Content-Type": "text/plain",
      },
      data: data,
    };
    axios(config)
      .then((response) => {
        if (response && response.data) {
          if (response.data.success === true) {
            setDocumentId(response.data.document_id);
            return traderDetail();
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const editUpload = (event, document_name, document_type_id, document_id) => {
    const access_token = getAccessToken();
    const reader = new FileReader();
    /*reader.addEventListener("load", () => {
            setfileView(reader.result);
        });*/
    setfile(event.target.files[0]);
    reader.readAsDataURL(event.target.files[0]);

    let data = new FormData();
    data.append("document_id", parseInt(document_id ? document_id : 0));
    data.append("user_id", parseInt(localStorage.getItem("userId")));
    data.append("access_token", access_token);
    data.append("document_type_id", parseInt(document_type_id));
    data.append("buyer_supplier_id", parseInt(buyer_supplier_id));
    data.append("document_name", document_name);
    data.append("document_data", event.target.files[0]);

    const config = {
      method: "post",
      url: process.env.REACT_APP_URL + "/api/v1/trader/document/upload",
      headers: {
        "Content-Type": "text/plain",
      },
      data: data,
    };
    axios(config)
      .then((response) => {
        if (response && response.data) {
          if (response.data.success === true) {
            setDocumentId(response.data.document_id);
            setMessage(response.data.message);
            return traderDetail();
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteUpload = (event, buyer_supplier_id, document_id) => {
    const access_token = getAccessToken();
    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: access_token,
      buyer_supplier_id: parseInt(buyer_supplier_id),
      document_id: parseInt(document_id),
    };
    const config = {
      method: "post",
      url: process.env.REACT_APP_URL + "/api/v1/trader/document/delete",
      headers: {
        "Content-Type": "text/plain",
      },
      data: data,
    };
    axios(config)
      .then((response) => {
        if (response && response.data) {
          if (response.data.success === true) {
            setMessage(response.data.message);
            return traderDetail();
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateBuyer = (event, trader_id) => {
    event.preventDefault();
    setAddBuyer(addBuyer);
    setEditBuyer(!editBuyer);
    setListBuyer(!listBuyer);
    setBuyerSupplierId(trader_id);
    //return traderDetail(trader_id);
    const access_token = getAccessToken();
    let traderType = trader_type === "buyer" ? "supplier" : "buyer";
    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: access_token,
      trader_type: traderType,
      id: parseInt(trader_id),
    };

    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/trader/detail`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        setBuyerSupplierId(trader_id);
        if (response) {
          setTraderDocuments(response.data.documents);
          setBusinessAddress(response.data.address);
          setBusinessCity(response.data.city);
          setEntity(response.data.company_entity);
          setBusinessCountry(response.data.country_id);
          setEmail(response.data.email);
          setPhone(response.data.mobile);
          setName(response.data.name);
          setRegistration(response.data.registration_no);
          setBusinessState(response.data.state_id);
          setTaxation(response.data.tax_number);
          setBusinessZip(response.data.zip);
          setfileView1(response.data.image);

          if (response.data.country_id !== false) {
            fetchState1(
              response.data.country_id,
              "allstate1",
              response.data.state_id
            );
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const removeUserImage = () => {
    setfileView("");
  };
  // Get already exist buyer/supplier if exist by taxtaion id
  const getTaxData = () => {
    if (taxation == "") {
      setOpen(true);
      setMessage({ type: "error", content: "Please enter a Taxation number" });
      return;
    }

    setBusinessAddress("");
    setBusinessCity("");
    setEntity("");
    setBusinessCountry("");
    setEmail("");
    setPhone("");
    setName("");
    setRegistration("");
    setBusinessState("");
    setBusinessZip("");
    setfileView1("");
    setReadOnly("");

    const access_token = getAccessToken();
    let traderType = trader_type === "buyer" ? "supplier" : "buyer";

    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: access_token,
      user_type: traderType,
      tax_number: taxation,
    };
    setLoader(false);
    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/buyer/supplier/list`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        //setBuyerSupplierId(trader_id)
        if (response.data.success) {
          //setTraderDocuments(response.data.documents);
          setBusinessAddress(response.data.trader_list[0].address);
          setBusinessCity(response.data.trader_list[0].city);
          setEntity(response.data.trader_list[0].company_entity);
          setBusinessCountry(response.data.trader_list[0].country_id);
          setEmail(response.data.trader_list[0].email);
          setPhone(response.data.trader_list[0].mobile);
          setName(response.data.trader_list[0].name);
          setRegistration(response.data.trader_list[0].registration_no);
          setBusinessState(response.data.trader_list[0].state_id);
          setTaxation(response.data.trader_list[0].tax_number);
          setBusinessZip(response.data.trader_list[0].zip);
          setfileView1(response.data.trader_list[0].image);
          setReadOnly(true);
          setInviteButton(false);
          // setOpen(true);
          // setMessage({ type: "success", content: response.data.message });
          if (response.data.trader_list[0].country_id !== false) {
            fetchState1(
              response.data.trader_list[0].country_id,
              "allstate1",
              response.data.trader_list[0].state_id
            );
          }
          setLoader(true);
        } else {
          setLoader(true);
          setOpen(true);
          setInviteButton(true);
          setMessage({ type: "error", content: response.data.message });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleMobileNumber = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setPhone(e.target.value);
    }
  };
  useEffect(() => {
    let type = trader_type === "buyer" ? "Add New Supplier" : "Add New Buyers";
    document.title = "Interswitch - " + type;
    allbuyerList();
    countryFunction();
    masterFunction();
    traderDetail();
  }, []);
  const { classes } = props;

  return (
    <ThemeContainer type="private" value={3}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}>
        <SnackbarContent
          className={
            message.type === "error"
              ? "snack-error"
              : message.type === "success"
              ? "snack-success"
              : "snack-teal"
          }
          message={message.content}
        />
      </Snackbar>
      {loader !== true ? (
        <Grid container spacing={3} className="bootstrap-loader">
          <Spinner animation="grow" variant="spinner1" />
          <Spinner animation="grow" variant="spinner2" />
          <Spinner animation="grow" variant="spinner3" />
        </Grid>
      ) : (
        <>
          <Box className="scf-box">
            <Box className="scf-subbox">
              <Box>
                <Grid container>
                  <Grid item xs={11} sm={11} md={11} xl={11}>
                    <Typography className="company-title11">
                      Add New {trader_type === "buyer" ? "Supplier" : "Buyers"}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={2} className="form-style">
                  <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                    <center>
                      <Box className="image-box">
                        {fileView ? (
                          <img className="img-thumb" src={fileView} />
                        ) : (
                          <img className="img-thumb" src={preview} />
                        )}
                        {!fileView && (
                          <Box className="img-action-option edit">
                            <center>
                              <label>
                                <EditOutlinedIcon className="img-edit" />
                                <input
                                  type="file"
                                  hidden
                                  onChange={(e) =>
                                    handleTeamMemberImageChage(e)
                                  }
                                />
                              </label>
                            </center>
                          </Box>
                        )}
                        {fileView && (
                          <Box className="img-action-option delete">
                            <center>
                              <DeleteOutlineIcon
                                className="img-delete"
                                onClick={removeUserImage}
                              />
                            </center>
                          </Box>
                        )}
                      </Box>
                    </center>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <Typography className="img-heading">
                      Upload Company Logo
                    </Typography>
                    <Typography className="img-subheading">
                      In PNG, PDF JPG, GIF, and JPEG format size should be less
                      than 2MB*
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={4} xl={4}>
                    <Box className="input-box">
                      <span className="company-title3">
                        Taxation Number<span className="required">*</span>
                      </span>
                      <OutlinedInput
                        className="company-title5"
                        fullWidth
                        placeholder="e.g. Taxation Number"
                        value={taxation}
                        inputProps={{ maxLength: 30 }}
                        onChange={(e) => setTaxation(e.target.value)}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} xl={4}>
                    <Grid item xs={6} sm={6} md={4} xl={4}>
                      <Box style={{ paddingTop: isMobile ? "0" : "8%" }}>
                        <span className="company-title3">&nbsp; </span>
                        <Button className="getdata" onClick={getTaxData}>
                          Get detail
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} xl={4}></Grid>
                  <Grid item xs={12} sm={12} md={4} xl={4}>
                    <Box className="input-box">
                      <span className="company-title3">
                        Organization Name<span className="required">*</span>
                      </span>
                      <OutlinedInput
                        className="company-title5"
                        fullWidth
                        placeholder="e.g. Organization Name"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                        readOnly={readonly === true ? "readonly" : ""}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12} md={4} xl={4}>
                    <Box className="input-box">
                      <span className="company-title3">
                        Type of Company Entity{" "}
                      </span>
                      <FormControl fullWidth>
                        <Select
                          displayEmpty
                          className="company-title4"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select2"
                          placeholder="Select from Option"
                          value={entity}
                          readOnly={readonly === true ? "readonly" : ""}
                          onChange={(e) => setEntity(e.target.value)}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}>
                          <MenuItem value="" disabled={true}>
                            Select from options
                          </MenuItem>

                          {entityList !== undefined && entityList.length > 0
                            ? entityList.map((item, index1) => {
                                return (
                                  <MenuItem value={item.id} key={index1}>
                                    {item.name}
                                  </MenuItem>
                                );
                              })
                            : null}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} xl={4}>
                    <Box className="input-box">
                      <span className="company-title3">
                        Organization Registration Number
                        <span className="required">*</span>
                      </span>
                      <OutlinedInput
                        className="company-title5"
                        fullWidth
                        placeholder="e.g. Organization Registration Number"
                        onChange={(e) => setRegistration(e.target.value)}
                        value={registration}
                        readOnly={readonly === true ? "readonly" : ""}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} xl={4}>
                    <Box className="input-box">
                      <span className="company-title3">
                        Email Address<span className="required">*</span>
                      </span>
                      <OutlinedInput
                        className="company-title5"
                        fullWidth
                        type="email"
                        placeholder="e.g. Email Address"
                        value={email}
                        readOnly={readonly === true ? "readonly" : ""}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} xl={4}>
                    <Box className="input-box">
                      <span className="company-title3">Phone Number </span>
                      <OutlinedInput
                        className="company-title5"
                        fullWidth
                        placeholder="e.g. Phone Number"
                        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                        value={phone}
                        readOnly={readonly === true ? "readonly" : ""}
                        onChange={(e) => handleMobileNumber(e)}
                      />
                    </Box>
                  </Grid>
                </Grid>

                <Typography className="sub-heading">
                  Business Address
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={4} xl={4}>
                    <Box className="input-box">
                      <span className="company-title3">
                        Address<span className="required">*</span>
                      </span>
                      <OutlinedInput
                        className="company-title5"
                        fullWidth
                        placeholder="e.g. Address"
                        value={businessaddress}
                        readOnly={readonly === true ? "readonly" : ""}
                        onChange={(e) => setBusinessAddress(e.target.value)}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12} md={4} xl={4}>
                    <Box className="input-box">
                      <span className="company-title3">Zip/ Postal Code </span>
                      <OutlinedInput
                        className="company-title5"
                        fullWidth
                        placeholder="e.g. Zip/ Postal Code"
                        value={businesszip}
                        readOnly={readonly === true ? "readonly" : ""}
                        onChange={(e) => setBusinessZip(e.target.value)}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} xl={4}>
                    <Box className="input-box">
                      <span className="company-title3">
                        Country<span className="required">*</span>
                      </span>
                      <FormControl fullWidth>
                        <Select
                          value={businesscountry}
                          readOnly={readonly === true ? "readonly" : ""}
                          onChange={(e) => {
                            setBusinessCountry(e.target.value);
                            const data = e.currentTarget.getAttribute("name");
                            fetchState(e.target.value, "allstate1");
                          }}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}>
                          <MenuItem value={""}>Select from options</MenuItem>
                          {allcountry !== "" ? (
                            allcountry.map((item, index2) => (
                              <MenuItem value={item.id} key={index2}>
                                {item.name}
                              </MenuItem>
                            ))
                          ) : (
                            <></>
                          )}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} xl={4}>
                    <Box className="input-box">
                      <span className="company-title3">State/ Province </span>
                      <FormControl fullWidth>
                        <Select
                          displayEmpty
                          className="company-title4"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={businessstate}
                          readOnly={readonly === true ? "readonly" : ""}
                          onChange={(e) => {
                            setBusinessState(e.target.value);
                          }}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}>
                          <MenuItem value="" disabled={true}>
                            Select from options
                          </MenuItem>

                          {allState1 &&
                            allState1.map((item, index3) => (
                              <MenuItem
                                value={item.id}
                                name={item.state_id}
                                key={index3}>
                                {item.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} xl={4}>
                    <Box className="input-box">
                      <span className="company-title3">City </span>
                      <FormControl fullWidth>
                        <OutlinedInput
                          className="company-title4"
                          fullWidth
                          varient="filled"
                          placeholder="Select from option"
                          onChange={(e) => setBusinessCity(e.target.value)}
                          value={businesscity}
                          readOnly={readonly === true ? "readonly" : ""}
                        />
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>

                {/*
              <Typography style={{ marginBottom: isMobile ? "7%" : "", paddingTop: "2%", color: "#0275D8", fontSize: "16px", fontWeight: "600" }}> Upload Documents</Typography>      
              <Box style={{ marginTop: "1%", marginBottom: "5%" }}>
                  {documents &&
                  documents.map((item, index4) => (

                  <Box key={index4}>
                      <Grid container spacing={2} >
                          <Grid item xs={6} sm={12} md={3} lg={3} xl={3}>
                              <cenetr><h4 className="company-title3" style={{ padding: "5%", color: "black" }}>{item.name} <span style={{ color: "red" }}>*</span></h4></cenetr>
                          </Grid>
                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                              <center> 
                              <Box style={{ width: "80%", padding: "8%" }}>
                                  <Button component="label" style={{ fontFamily: "Averta-Regular", width: "100%", color: "#0275D8", border: "2px solid #0275D8", borderRadius: "8px", textTransform: "initial" }}
                                     >Upload Document
                                      <input
                                         type="file"
                                         hidden
                                         onChange={(e) => documentUpload(e, item.name, item.id)}                                        
                                     />
                                     </Button>                                                   
                                  </Box>
                              </center>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                              <Grid container spacing={2}>       
                              {trader_documents &&
                                  trader_documents.map((trader_item, index5) => (
                                  <>
                                  {trader_item.document_type_id === item.id ? (                                         
                                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4} key={index5}>
                                      <center>
                                          <Box style={{
                                              height: "100%", width: "100%", position: "relative",
                                              textAlign: "center"
                                          }}>
                                              <img style={{ height: "100%", width: "100%" }} src={process.env.REACT_APP_URL+trader_item.document_url} />
                                              <Box style={{ position: "absolute", bottom: "5px", left: "13%", background: "#659EC7", height: "27px", width: "30px" }}>
                                                  <center>
                                                  <Button component="label" style={{ fontFamily: "Averta-Regular", width: "100%", color: "#0275D8", textTransform: "initial" }}>
                                                      <EditOutlinedIcon style={{ color: "#ffff" }} />
                                                      <input
                                                          type="file"
                                                          hidden
                                                          onChange={(e) => editUpload(e, item.name, item.id, trader_item.document_id)}
                                                      />
                                                  </Button>
                                                  </center>
                                              </Box>
                                              <Box style={{ position: "absolute", bottom: "5px", right: "13%", background: "#659EC7", height: "27px", width: "30px" }}>
                                                  <center><DeleteOutlineIcon style={{ marginTop: "6%", color: "#ffff" }} onClick={(e) => deleteUpload(e, trader_item.buyer_supplier_id, trader_item.document_id)} /></center>
                                              </Box>
                                          </Box>
                                      </center>
                                  </Grid>
                                  ) : (
                                      <></>
                                  )} 
                                  </>
                              ))}
                              </Grid>
                          </Grid>
                      </Grid>
                  </Box>
                  ))}
              </Box>
            */}
                <Grid container spacing={3} className="button-grid">
                  <Grid item xs={6} sm={4} md={2} xl={2}>
                    <Button
                      className="save"
                      onClick={onSubmit}
                      disabled={
                        !name ||
                        !registration ||
                        !taxation ||
                        !email ||
                        !businessaddress ||
                        !businesscountry
                      }>
                      Save
                    </Button>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2} xl={2}>
                    <Link to={trader_type === "buyer" ? "/supplier" : "/buyer"}>
                      <Button className="cancel">Cancel</Button>
                    </Link>
                  </Grid>
                  {inviteButton == true && (
                    <Grid item xs={6} sm={4} md={2} xl={2}>
                      <Button
                        className="invite"
                        onClick={sendinvitation}
                        disabled={
                          !name ||
                          !registration ||
                          !taxation ||
                          !email ||
                          !businessaddress ||
                          !businesscountry
                        }>
                        Send Invitation
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </ThemeContainer>
  );
}

AddBuyer.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(AddBuyer);
