import React, { useEffect, useState } from "react";
import { Grid, Box, } from "@material-ui/core";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import Card from "../Components/Common/Card";
import Header from "../Components/Common/Header";
import UploadDocument from "../Components/Common/KycDocument";
import ThemeContainer from "views/Components/sideBar/ThemeContainer";
import { getAccessToken } from "../../utilities/tokenUtil";
import axios from "axios";
import { useHistory } from "react-router-dom";
import EditICon from "../../assets/img/editIcon.svg";


const ViewBuyer = (props) => {
  const userId = localStorage.getItem("userId");
  const userName = localStorage.getItem("userName");
  const trader_type = localStorage.getItem("trader_type");

  const [loader, setLoader]     = useState(false);
  const [data, setData]         = useState();
  const history                 = useHistory();
  const profileId = parseInt(
    props.location.state.buyer_id
      ? props.location.state.buyer_id
      : props.location.state.supplier_id
  );
  if (!userId || userName === null) {
    history.push("/signin");
  }

  const userRole = localStorage.getItem("user_role");
  const userDetail = () => {
    const access_token = getAccessToken();
    setLoader(false)
    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: access_token,
      trader_type: trader_type == "supplier" ? "buyer" : "supplier",
      id: profileId,
    };
    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/trader/detail`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setData(response.data);
          setLoader(true)
        }else{
          setLoader(true)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    let type = trader_type === "buyer" ? "Supplier Profile" : "Buyer Profile";
    document.title = 'Interswitch - '+type;
    window.scrollTo(0, 0);
    userDetail();
  }, []);
  return (
    <>
      {loader !== true ? (
        <Grid container spacing={3} className="bootstrap-loader">
          
            <Spinner animation="grow" variant="spinner1" />
            <Spinner animation="grow" variant="spinner2" />
            <Spinner animation="grow" variant="spinner3" />
          
        </Grid>
      ) : (
        <>
      {data && data.success && (
        <>
          <ThemeContainer type="private" value={5}>
            <Container className="my-5 view-buyer-suppliers">
              <Row>
                <Col md={8} className="cp-heading2 my-3 ">
                  {props.location.state.buyer_id
                    ? "Buyer Profile"
                    : "Supplier Profile"}
                </Col>
                <Col md={4} className="my-3 text-end">
                  <Row>
                    <span>
                      <button
                        className="back-button view"
                        onClick={() => history.push(trader_type === "buyer" ? "/supplier" : "/buyer")}
                      >
                        <i className="fas fa-long-arrow-alt-left" >{" "}</i>{" "}
                        Back
                      </button>
                    </span>
                  </Row>
                  <Row className="mt-2 status" >
                    <span
                      className={
                        data.status == "Approved"
                          ? "status-approve"
                          : data.status == "Rejected"
                          ? "status-reject"
                          : "status-pending"
                      }
                    >
                      {data.status}
                    </span>{" "}
                  </Row>

                  <Row className="mt-2">
                    <Col>
                      {userRole == "admin" || userRole == "approver" ? (
                        <button className="view-edit" onClick={() => history.push(trader_type === "buyer" ? "/editsupplier/" + profileId : "/editbuyer/" + profileId) } >
                          <img src={EditICon} alt="" />
                        </button>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Header info={data} />

              <Card
                info={data}
                color={"#479FC8"}
                name={"Business Contact Information"}
              />

              <UploadDocument name={"Taxation Number"} info={data.documents} />              
              
            </Container>
          </ThemeContainer>
        </>
      )}
      </>
      )} 
    </>
  );
};
export default ViewBuyer;
