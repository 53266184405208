import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import styles from "assets/jss/material-kit-react/views/components.js";
import {
  Grid,
  Box,
  Typography,
  OutlinedInput,
  withStyles,
  MenuItem,
  FormControl,
  Select,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Toolbar,
  IconButton,
  Snackbar,
  SnackbarContent,
  InputLabel,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";

import image from "../../assets/img/signup.png";
import SignupFooter from "./footer";
import HomePage5 from "views/HomePage/HomePage5";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import HomePage from "views/HomePage/HomePage";
import { isMobile } from "react-device-detect";
import facebook from "../../assets/img/facebook2.svg";
import google from "../../assets/img/google.svg";
import linkedin from "../../assets/img/in2.svg";
import twitter from "../../assets/img/twitter2.svg";
import { Spinner } from "react-bootstrap";
import InputAdornment from "@mui/material/InputAdornment";
import Autocomplete from "@mui/material/Autocomplete";
import InfoIcon from "@material-ui/icons/Help";
import Tooltip from "@mui/material/Tooltip";
import { Store } from "../../context/store/storeState";

const axios = require("axios");
const useStyles = makeStyles(styles);

export default function NewSignUp(props) {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  let regEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const regPassword =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  let txtno = /^(\d{2}[a-zA-Z]{5}\d{4}[a-zA-Z]{4})$/;

  const classes = useStyles();
  const history = useHistory();
  const [message, setMessage] = useState({});
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cName, setCName] = useState("");
  const [txnID, setTxnID] = useState("");
  const [RCNumber, setRCNumber] = useState("");
  const [address, setAddress] = useState("");
  const [fullName, setFullName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [user_type, setUserType] = useState("buyer_supplier");
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");
  const [country, setCountry] = useState("");
  const [lender_id, setLenders] = useState("");
  const [default_country, setDefaultCountry] = useState("");
  const [data, setData] = useState();
  const [auto_focus, setFocus] = useState(false);
  const [countryList, setCountryList] = useState("");
  const [lendersList, setLendersList] = useState("");
  const [loader, setLoader] = useState(false);
  const [authToken, setAuthToken] = useState("");
  const [matchmsg, setMatchMsg] = useState(false);
  const [mismatchmsg, setMismatchMsg] = useState(false);
  const [matchRegex, setMatchRegex] = useState(false);

  const [values, setValues] = useState({
    showPassword1: false,
    showPassword2: false,
  });
  const userId = localStorage.getItem("userId");
  const userName = localStorage.getItem("userName");
  const verifyContext = useContext(Store);
  const otp_timer = verifyContext.otp_timer;
  const setOtpTimer = verifyContext.setOtpTimer;

  const api_url = process.env.REACT_APP_URL;

  if (userId && userName !== null) {
    history.push("/supplierdash");
  }

  const handleMobileNumber = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setMobile(e.target.value);
    }
  };
  const handleClickShowPassword = (num) => {
    if (num == 1) {
      setValues({
        ...values,
        showPassword1: !values.showPassword1,
      });
    } else {
      setValues({
        ...values,
        showPassword2: !values.showPassword2,
      });
    }
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const signUpHandler = () => {
    // setLoading(true);
    var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    let name_validate = /^(?=.*?[A-Za-z]).{2,}$/;
    let mobile_validate = /^[0][1-9]\d{10,12}$|^[1-9]\d{10,12}$/;
    let txnid_validate = /^(?=.*?[aA-Za])(?=.*?[0-9]).{8}$/;

    var hasNumber = /\d/;
    if (cName.trim() === "") {
      setLoading(false);
      setOpen(true);
      document.querySelector("#company_name").focus();
      setMessage({ type: "error", content: "Company Name cannot be empty" });
      return;
    }
    if (txnID.trim() === "") {
      setLoading(false);
      setOpen(true);
      document.querySelector("#tax_id").focus();
      setMessage({
        type: "error",
        content: "Taxation Number cannot be empty or invalid Taxation id",
      });
      return;
    }
    if (!txnID.match(txnid_validate)) {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "Taxation number should be 8 digit & Upper Character",
      });
      return;
    }
    if (RCNumber.trim() === "") {
      setLoading(false);
      setOpen(true);
      document.querySelector("#rcnumber").focus();
      setMessage({ type: "error", content: "RC Number cannot be empty" });
      return;
    }
    if (!RCNumber.match(txnid_validate)) {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "RC number should be 8 digit & character",
      });
      return;
    }

    if (address.trim() === "") {
      setLoading(false);
      setOpen(true);
      document.querySelector("#company_address").focus();
      setMessage({ type: "error", content: "Address cannot be empty" });
      return;
    }
    if (fullName.trim() === "") {
      setLoading(false);
      setOpen(true);
      document.querySelector("#full_name").focus();
      setMessage({ type: "error", content: "Full Name cannot be empty" });
      return;
    }
    if (!fullName.match(name_validate)) {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "Please enter atleast two character contact name",
      });
      return;
    }
    if (mobile.trim() === "") {
      setLoading(false);
      setOpen(true);
      document.querySelector("#mobile_number").focus();
      setMessage({ type: "error", content: "Mobile Number cannot be empty" });
      return;
    }
    if (!mobile.match(mobile_validate)) {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "Mobile number should be 11-13 digit",
      });
      return;
    }
    if (!regEmail.test(email) || email === "") {
      setLoading(false);
      setOpen(true);
      document.querySelector("#contact_email").focus();
      setMessage({
        type: "error",
        content: "Email cannot be empty OR enter a valid email",
      });
      return;
    }
    if (user_type === "") {
      setLoading(false);
      setOpen(true);
      document.querySelector("#demo-simple-select2").focus();
      setMessage({ type: "error", content: "User Type cannot be empty" });
      return;
    }
    if (country === "") {
      setLoading(false);
      setOpen(true);
      document.querySelector("#demo-simple-select").focus();
      setMessage({ type: "error", content: "Country cannot be empty" });
      return;
    }

    if (lender_id === "") {
      setLoading(false);
      setOpen(true);
      document.querySelector("#domiciled-banks").focus();
      setMessage({ type: "error", content: "Domiciled banks cannot be empty" });
      return;
    }
    if (password.trim() === "") {
      setLoading(false);
      setOpen(true);
      document.querySelector("#main_password").focus();
      setMessage({ type: "error", content: "Password cannot be empty" });
      return;
    }
    if (cPassword.trim() === "") {
      setLoading(false);
      setOpen(true);
      document.querySelector("#confirm_password").focus();
      setMessage({
        type: "error",
        content: "Confirm Password cannot be empty",
      });
      return;
    }

    var regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;
    if (!password.match(regex)) {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content:
          "Password should be minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character",
      });
      return;
    }
    if (password !== cPassword) {
      setLoading(false);
      setOpen(true);
      setMessage({ type: "error", content: "Password not Matched" });
      return;
    }

    let tempData = {
      type: "CORPORATE",
      email: email.trim(),
      registeredName: cName.trim(),
      verificationRequests: [
        {
          type: "CORPORATE_REGISTRATION",
          country: "NGA",
          identityNumber: "RC12345",
        },
      ],
      callbackUrl: "http://localhost:8062",
    };

    const config1 = {
      method: "post",
      url: api_url + "/api/v1/business_verification",
      headers: {
        "Content-Type": "text/plain",
        Authorization: "Bearer " + authToken,
      },
      data: tempData,
    };
    axios(config1).then((resp) => {
      const dataSet = JSON.stringify({
        contact_email: email.trim(),
        password: password.trim(),
        company_name: cName.trim(),
        contact_number: mobile.trim(),
        company_registration_number: RCNumber.trim(),
        company_address: address.trim(),
        company_tax_number: txnID.trim(),
        contact_full_name: fullName.trim(),
        user_type: user_type,
        country_id: parseInt(country ? country : 0),
        lender_id: lender_id,
      });

      setLoader(false);
      const config2 = {
        method: "post",
        url: process.env.REACT_APP_URL + "/api/v1/company/signup",
        headers: {
          "Content-Type": "text/plain",
        },
        data: dataSet,
      };

      axios(config2)
        .then((response) => {
          if (response && response.data.success) {
            setLoading(false);
            setOpen(true);
            setMessage({ type: "success", content: response.data.message });

            return generateOtp(email);
          } else {
            setLoading(false);
            setOpen(true);
            setMessage({ type: "error", content: response.data.message });
            setLoader(true);
          }
        })
        .catch(function (error) {
          setOpen(true);
          setMessage({ type: "error", content: "Signup failed" });
          setLoading(false);
          setLoader(true);
        });
    });
  };

  const generateOtp = (email) => {
    const request = {
      email: email,
    };
    axios
      .post(process.env.REACT_APP_URL + "/api/v1/generate/otp", request, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        if (response && response.data.success === true) {
          setOtpTimer({
            otp_timer: response.data.otp_time,
          });
          setLoading(false);
          setOpen(true);
          setMessage({ type: "success", content: response.data.message });
          setTimeout(() => {
            history.push("/otpverify/" + email);
          }, [2000]);
        } else {
          setOpen(true);
          setLoading(false);
          setLoader(true);
          setMessage({ type: "error", content: response.data.message });
        }
      });
  };

  const countryFunction = () => {
    //const access_token = getAccessToken();

    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: "",
    };
    axios
      .post(
        `${process.env.REACT_APP_URL}/api/v1/country/master`,
        JSON.stringify(data)
      )
      .then((response) => {
        if (response && response.data.sucess) {
          let countries = response.data.country;
          let country_options = [];
          if (countries !== undefined && countries.length) {
            countries.forEach(function (data, key) {
              country_options.push({
                value: data.name,
                id: data.id,
                label: data.name,
              });
            });
          }
          setCountryList(country_options);
          setDefaultCountry(country_options[158]);
          setCountry(country_options[158].id);
          //setCountryList(response.data.country);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const domiciledBankFunction = () => {
    axios
      .get(`${process.env.REACT_APP_URL}/api/v1/user/master`)
      .then((response) => {
        if (response && response.status) {
          let lenders = response.data.lenders;
          let lenders_options = [];
          if (lenders !== undefined && lenders.length) {
            lenders.forEach(function (data, key) {
              lenders_options.push({
                value: data.name,
                id: data.id,
                label: data.name,
              });
            });
          }
          setLendersList(lenders_options);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getData = () => {
    setLoader(false);
    axios
      .get(
        api_url + "/api/v1/homepage",

        {
          headers: {
            "Content-Type": "text/plain",
          },
        }
      )
      .then((res) => {
        if (res.status == 200) {
          setData(res.data);
          setLoader(true);
        } else {
          setLoader(true);
        }
      });
  };

  const passwordValidate = () => {
    var regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;
    if (!password.match(regex)) {
      setMatchRegex(true);
      setTimeout(() => {
        setMatchRegex(false);
      }, 3000);
      return;
    }
  };
  const matchPassword = () => {
    if (password === cPassword) {
      setMatchMsg(true);
      setTimeout(() => {
        setMatchMsg(false);
      }, 2000);
      return;
    } else {
      setMismatchMsg(true);
      setTimeout(() => {
        setMismatchMsg(false);
      }, 2000);
      return;
    }
  };

  const handleCountry = (event, country_id) => {
    event.preventDefault();
    if (country_id) {
      setCountry(country_id);
      let country_key = [];
      if (countryList !== undefined && countryList.length) {
        countryList.forEach(function (data, key) {
          if (data.id == country_id) {
            country_key.push({
              country_key: key,
            });
          }
        });
        setDefaultCountry(countryList[country_key[0].country_key]);
      }
    }
  };
  const getAuthToken = () => {
    setLoader(false);
    axios
      .get(api_url + "/api/v1/bv/access_token", {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((res) => {
        if (res.status == 200) {
          setAuthToken(res.data.access_token);
        } else {
          setLoader(true);
        }
      });
  };

  useEffect(() => {
    document.title = "Interswitch - Sign Up";
    countryFunction();
    domiciledBankFunction();
    getData();
    getAuthToken();
    if (!isMobile) {
      window.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}>
        <SnackbarContent
          className={
            message.type === "error"
              ? "snack-error"
              : message.type === "success"
              ? "snack-success"
              : "snack-teal"
          }
          message={message.content}
        />
      </Snackbar>
      {loader !== true ? (
        <Grid container spacing={3} className="bootstrap-loader">
          <Spinner animation="grow" variant="spinner1" />
          <Spinner animation="grow" variant="spinner2" />
          <Spinner animation="grow" variant="spinner3" />
        </Grid>
      ) : (
        <>
          <Box className="signup-page">
            {isMobile ? (
              <Grid item xs={12} sm={12} md={5} xl={5} className="text-center">
                <img className="mobile-img" src={image} />
              </Grid>
            ) : (
              ""
            )}
            <Box className="scf-box">
              <Box className="scf-subbox">
                <Grid container>
                  <Grid item xs={12} sm={12} md={7} xl={7}>
                    <Typography className="heading">
                      {" "}
                      Do you want to Know about Invoicing Discounting Process?{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}></Grid>
                  <Grid item xs={12} className="title-section">
                    <span className="sub-heading"> Already a user? </span>
                    <Link to={"/signin"} className={classes.link}>
                      <span className="sub-heading signin">Sign In</span>
                    </Link>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8} xl={8}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12} md={6} xl={6}>
                        <Box className="input-box">
                          <span className="company-title3">
                            Company Name <span className="required">*</span>
                          </span>
                          <OutlinedInput
                            fullWidth
                            autoFocus
                            className="company-title51"
                            placeholder="Company Name"
                            id="company_name"
                            value={cName}
                            onChange={(e) => setCName(e.target.value)}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} xl={6}>
                        <Box className="input-box">
                          <span className="company-title3">
                            Companys’ Taxation ID/Number{" "}
                            <span className="required">*</span>
                          </span>
                          <OutlinedInput
                            fullWidth
                            id="tax_id"
                            className="company-title51"
                            placeholder="Company's Taxation ID/Number"
                            value={txnID}
                            onChange={(e) => setTxnID(e.target.value)}
                            inputProps={{ maxLength: 30 }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12} md={6} xl={6}>
                        <Box className="input-box">
                          <span className="company-title3">
                            Company’s Registration/ RC Number{" "}
                            <span className="required">*</span>
                          </span>
                          <OutlinedInput
                            fullWidth
                            id="rcnumber"
                            className="company-title51"
                            placeholder="Company’s Registration/ RC Number"
                            value={RCNumber}
                            onChange={(e) => setRCNumber(e.target.value)}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} xl={6}>
                        <Box className="input-box">
                          <span className="company-title3">
                            Company’s Address{" "}
                            <span className="required">*</span>
                          </span>
                          <OutlinedInput
                            fullWidth
                            id="company_address"
                            className="company-title51"
                            placeholder="Company’s Address"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12} md={6} xl={6}>
                        <Box className="input-box">
                          <span className="company-title3">
                            Contact Full Name{" "}
                            <span className="required">*</span>
                          </span>
                          <OutlinedInput
                            fullWidth
                            id="full_name"
                            className="company-title51"
                            placeholder="Contact Full Name"
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} xl={6}>
                        <Box className="input-box">
                          <span className="company-title3">
                            Contact Mobile Number{" "}
                            <span className="required">*</span>
                          </span>
                          <OutlinedInput
                            fullWidth
                            id="mobile_number"
                            className="company-title51"
                            placeholder="Contact Mobile Number"
                            inputProps={{
                              inputMode: "numeric",
                              pattern: "[0-9]*",
                            }}
                            value={mobile}
                            onChange={(e) => handleMobileNumber(e)}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12} md={6} xl={6}>
                        <Box className="input-box">
                          <span className="company-title3">
                            User Type <span className="required">*</span>
                          </span>
                          <FormControl fullWidth className={classes.textFields}>
                            <Select
                              displayEmpty
                              className="company-title4"
                              labelId="demo-simple-select-label"
                              id="demo-simple-select2"
                              placeholder="Select from Option"
                              onChange={(e) => setUserType(e.target.value)}
                              value={user_type}
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },
                                getContentAnchorEl: null,
                              }}>
                              <MenuItem value="" disabled>
                                Select from options
                              </MenuItem>
                              <MenuItem value="buyer_supplier">
                                Buyer - Supplier
                              </MenuItem>
                              <MenuItem value="buyer">Buyer</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} xl={6}>
                        <Box className="input-box">
                          <span className="company-title3">
                            Contact Email Address{" "}
                            <span className="required">*</span>
                          </span>
                          <OutlinedInput
                            fullWidth
                            type="email"
                            id="contact_email"
                            className="company-title51"
                            placeholder="Contact Email Address"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12} md={6} xl={6}>
                        <Box className="input-box signup-country-list">
                          <span className="company-title3">
                            Country <span className="required">*</span>
                          </span>
                          <FormControl fullWidth>
                            {/*<Select
                          displayEmpty
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Country"
                          onChange={(e) => {
                            setCountry(e.target.value);
                          }}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}
                        >
                          <MenuItem value={""}> Select from options</MenuItem>

                          {countryList !== "" ? (
                            countryList.map((item, index) => (
                              <MenuItem value={item.id} key="index">{item.name}</MenuItem>
                            ))
                          ) : (
                            <></>
                          )}
                        </Select>*/}
                            <Autocomplete
                              id="country-select-demo"
                              options={countryList}
                              defaultValue={default_country}
                              //inputValue={country}
                              onChange={(event, value) =>
                                handleCountry(event, value.id)
                              }
                              /*onInputChange={(_, newInputValue) => {
                                setCountry(newInputValue)
                              }}*/
                              getOptionLabel={(countryList) =>
                                countryList.label
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Select from Option"
                                />
                              )}
                            />
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} xl={6}>
                        <Box className="input-box signup-country-list">
                          <span className="company-title3">
                            Domiciled Bank <span className="required">*</span>
                          </span>
                          <FormControl fullWidth className="select-placeholder">
                            <InputLabel id="lenders-simple-select-label">
                              Please Select Bank
                            </InputLabel>
                            <Select
                              displayEmpty
                              className="company-title4"
                              labelId="lenders-simple-select-label"
                              id="domiciled-banks"
                              label="Please Select Bank"
                              value={lender_id}
                              onChange={(e) => {
                                setLenders(e.target.value);
                              }}
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },
                                getContentAnchorEl: null,
                              }}>
                              <MenuItem disabled value={""}>
                                {" "}
                                Select from options
                              </MenuItem>

                              {lendersList !== "" ? (
                                lendersList.map((item, index) => (
                                  <MenuItem value={item.id} key="index">
                                    {item.value}
                                  </MenuItem>
                                ))
                              ) : (
                                <></>
                              )}
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12} md={6} xl={6}>
                        <Box className="input-box">
                          <span className="company-title3">
                            Password <span className="required">*</span>{" "}
                            <Tooltip
                              title="Password should be minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character"
                              placement="top"
                              arrow>
                              <InfoIcon />
                            </Tooltip>
                          </span>
                          <OutlinedInput
                            fullWidth
                            id="main_password"
                            className="company-title51"
                            type={values.showPassword1 ? "text" : "password"}
                            value={password}
                            onKeyUp={passwordValidate}
                            onChange={(e) => setPassword(e.target.value)}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => handleClickShowPassword(1)}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end">
                                  {values.showPassword1 ? (
                                    <i className="far fa-eye-slash"></i>
                                  ) : (
                                    <i className="fas fa-eye"></i>
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </Box>
                        {matchmsg && matchmsg === true && (
                          <span
                            className="company-title3"
                            style={{ color: "green" }}>
                            Password matched.
                          </span>
                        )}
                        {mismatchmsg && mismatchmsg === true && (
                          <span
                            className="company-title3"
                            style={{ color: "red" }}>
                            Password not matched.
                          </span>
                        )}
                        {matchRegex && matchRegex === true && (
                          <span
                            className="company-title3"
                            style={{ color: "red" }}>
                            Password should be minimum eight characters, at
                            least one uppercase letter, one lowercase letter,
                            one number and one special character
                          </span>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} xl={6}>
                        <Box className="input-box">
                          <span className="company-title3">
                            Confirm Password <span className="required">*</span>
                          </span>
                          <OutlinedInput
                            fullWidth
                            id="confirm_password"
                            className="company-title51"
                            placeholder="Confirm Password"
                            type={values.showPassword2 ? "text" : "password"}
                            value={cPassword}
                            onChange={(e) => setCPassword(e.target.value)}
                            onKeyUp={matchPassword}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => handleClickShowPassword(2)}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end">
                                  {values.showPassword2 ? (
                                    <i className="far fa-eye-slash"></i>
                                  ) : (
                                    <i className="fas fa-eye"></i>
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6} md={6} xl={6}>
                        <Grid container className="button-box">
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Button
                              onClick={signUpHandler}
                              fullWidth
                              className="button"
                              disabled={
                                !email ||
                                !password ||
                                !cPassword ||
                                !cName ||
                                !mobile ||
                                !RCNumber ||
                                !address ||
                                !txnID ||
                                !fullName ||
                                !user_type ||
                                !country ||
                                !lender_id
                              }>
                              Sign Up
                            </Button>
                          </Grid>

                          {/* <FormGroup className="text-small">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onClick={handleAPICall}
                                  defaultChecked={!apiCall}
                                  size="small"
                                />
                              }
                              label="Skip Business Verification"
                            />
                          </FormGroup> */}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {isMobile ? (
                    ""
                  ) : (
                    <Grid item xs={12} sm={12} md={4} xl={4}>
                      <Box className="right-image">
                        <img src={image} />
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Box>
            {isMobile ? "" : <HomePage5 />}
            <SignupFooter />
          </Box>
        </>
      )}
    </>
  );
}
