import React, { useState } from "react";
import { Grid, Typography, Button, Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/components.js";
const useStyles = makeStyles(styles);
import { isMobile } from "react-device-detect";

export default function BottomComponent(props) {
  const classes = useStyles();
  const [data, setData] = useState(props.info);
  return (
    <>
      {data && data !== undefined && (
        <>
          <Box className="home-register-now">
            <Grid container className="register-now-container">
              <Grid item xs={12} sm={12} md={9}>
                <Box>
                  <Typography className="register-now-content">
                    <div dangerouslySetInnerHTML={{ __html: data }}></div>
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} sm={3} md={3}>
                <Box className="register-now-link">
                  <Link to={"/signup"} className={classes.link}>
                    <Button className="register-now-btn">
                      Register Now
                    </Button>
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
}
