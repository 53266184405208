import React from 'react';
import styles from "assets/jss/material-kit-react/views/components.js";
import Grid from '@material-ui/core/Grid'
import { Button, Box } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import { makeStyles } from "@material-ui/core/styles";
import image from "../../assets/img/loader.png"
import HomePage5 from "views/HomePage/HomePage5";
const useStyles = makeStyles(styles);
import SignupFooter from 'views/Signup/footer';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';



export default function Loader() {
    const classes = useStyles();
    return (
        <>
            <Box style={{ background: "white" }}>
                <Box className={classes.imgfluid} style={{ padding: "2%", "mix-blend-mode": "multiply", background: "#FFF" }}>
                    <Box style={{ margin: "5%", background: "#FFFFFF", borderRadius: "16px" }}>
                        {isMobile ? <img style={{ marginLeft: "15%", height: "70%", width: "70%" }} src={image} />
                            : ""}
                        <Grid container spacing={10}>
                            <Grid item xs={12} sm={12} md={6} xl={6}>
                                <Box style={{ "margin-top": "5%", "margin-left": "10%", "margin-bottom": "40px" }}>
                                    <Grid style={{ fontFamily: "Averta-SemiBold", fontStyle: "normal", fontWeight: "700", fontSize: "56px", "lineHeight": "84px", "color": "#18425D" }}>
                                        Loader
                                    </Grid>
                                    <Typography style={{ "fontFamily": "Averta-Regular", "fontStyle": "normal", "fontWeight": "bold", "fontSize": "24px", height: "36px", "color": "#479FC8" }}>Loading Data</Typography>

                                    <Typography style={{ "margin-top": "5%", color: "#848F9F", fontSize: "20px", fontWeight: "600", style: "normal", height: "32px", fontFamily: "Averta-Regular" }}  >Please Wait<br />
                                        Text Content,  Graphical Content and Component loading
                                    </Typography>



                                    <Link to={"/"}>
                                        <Button style={{ "background": "#479FC8", padding: "5px", marginTop: isMobile ? "" : "4%", width: "100%", height: "55px", top: "100px", color: "#FFFFFF", fontFamily: "Averta-Regular", marginBottom: isMobile ? "20%" : " ", marginLeft: isMobile ? "-5%" : " " }}>
                                            Back to Home Page
                                        </Button>
                                    </Link>


                                </Box>
                            </Grid>
                            {isMobile ? "" : <Grid item xs={12} sm={12} md={6} xl={6}>
                                <Box style={{ padding: "10%" }}>
                                    {/*<img style={{ height: "90%", width: "90%" }} src={image} />*/}
                                    <img src={image} alt="loader"/>

                                </Box>
                            </Grid>}
                        </Grid>
                    </Box >
                </Box >
                <HomePage5 />
                {isMobile ? "" : <SignupFooter />}
            </Box>
        </>
    )
}
