import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  withStyles,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Snackbar,
  SnackbarContent,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { getAccessToken } from "../../utilities/tokenUtil";
import ThemeContainer from "views/Components/sideBar/ThemeContainer";
import { useHistory } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import Tooltip from '@mui/material/Tooltip';

var axios = require("axios");

const styles = () => ({

  textFields: {
    background: "#F3F5F6",
    height: "40px",
    marginTop: "1%",
    "& div": {
      "&::before": {
        content: "",
        borderBottom: "none",
      },
      "&::after": {
        borderBottom: "none",
      },
      "&:hover": {
        "&::before": {
          borderBottom: "none !important",
        },
        "&::after": {
          borderBottom: "none !important",
        },
      },
      "& .MuiInputBase-input": {
        padding: "9px 7px",
      },
    },
  },
});

function KycDocs(props) {

  const userId = localStorage.getItem("userId");
  const userName = localStorage.getItem("userName");
  const kyc_status = localStorage.getItem("kyc_status");
  const kyc_type = localStorage.getItem("kyc_type");

  const history = useHistory();
  const [documentList, setDocumentList] = useState([]);
  const [message, setMessage] = useState({});
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);

  if (!userId || userName === null) {
    history.push("/signin");
  }
  if (kyc_status !== "Approved") {
    history.push("/kyc-page");
  }
  const allDomiciledKycDocList = () => {
    const access_token = getAccessToken();
    let kycTypes;
    if (kyc_type === "Bank") {
      kycTypes = "bank_kyc";
    } else {
      kycTypes = "manual_kyc";
    }
    const data = {
      access_token: access_token,
      user_id: parseInt(localStorage.getItem("userId")),
      kyc_type: kycTypes,
    };
    axios
      .post(`${process.env.REACT_APP_URL}` + "/api/v1/kyc/get/document/list", data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        if (response && response.data.success === true) {
          setDocumentList(response.data.document_list);
          setLoader(true);
        } else {
          setLoader(true)
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(true)
      });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    document.title = 'Interswitch - Domiciled Banks KYC Docs';
    allDomiciledKycDocList();
  }, []);
  const { classes } = props;

  return (
    <ThemeContainer type="private" value={3}>
      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={open} autoHideDuration={3000} onClose={handleClose}
      >
        <SnackbarContent className={message.type === "error" ? "snack-error" : message.type === "success" ? "snack-success" : "snack-teal"} message={message.content} />
      </Snackbar>
      {loader !== true ? (
        <Grid container spacing={3} className="bootstrap-loader">
          <Spinner animation="grow" variant="spinner1" />
          <Spinner animation="grow" variant="spinner2" />
          <Spinner animation="grow" variant="spinner3" />
        </Grid>
      ) : (
        <Box className="scf-box">
          <Box className="scf-subbox">
            <Box >
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6} md={11} xl={11}>
                    <Typography className="listing-main-title">
                      {"Domiciled Banks KYC Docs"}{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={1} xl={1}>
                    <Button className="upload back-button" onClick={() => history.push("/domiciled-banks")}>
                      <i className="fas fa-long-arrow-alt-left"> {" "}Back </i>
                    </Button>
                  </Grid>
                </Grid>

                <Typography className="list-sub-title">
                  Domiciled Banks KYC Docs List
                </Typography>
              </Box>
              <Box>
                <TableContainer component={Paper}>
                  <Table className="table-top" aria-label="simple table" >
                    <TableHead>
                      <TableRow className="table-row" >
                        <TableCell className="table-header" align="left" style={{ width: "5%" }}> S. No.{" "} </TableCell>
                        <TableCell align="left" className="table-header"> Name </TableCell>
                        <TableCell align="left" className="table-header"> Status </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {documentList &&
                        documentList.length > 0 &&
                        documentList !== undefined ? (
                        documentList.map((info, index) => (
                          <>
                            {info &&
                              info.doc_list.map((doc_item, kyc_key) => (
                                <>
                                  {doc_item.document_type_id === info.doc_id &&
                                    <TableRow className="table-row" key={kyc_key}>
                                      <TableCell align="left" component="th" scope="row" onClick={() => history.push("/viewkycdocs/" + (doc_item.document_id))} > {index + 1} </TableCell>
                                      <TableCell align="left" onClick={() => history.push("/viewkycdocs/" + (doc_item.document_id))} > {doc_item.document_name} </TableCell>
                                      <TableCell align="left" onClick={() => history.push("/viewkycdocs/" + (doc_item.document_id))} className={doc_item.state.toLowerCase().replace(/\s/g, '-')} style={{ textTransform: "capitalize" }}>
                                        {doc_item.state === "Rejected" && doc_item.rejection_reason !== false ? (
                                          <Box>
                                            <Tooltip title={doc_item.rejection_reason} placement="top" arrow>
                                              <span>
                                                {doc_item.state}
                                              </span>
                                            </Tooltip>
                                          </Box>
                                        ) : (
                                          <>
                                            {doc_item.state}
                                          </>
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  }
                                </>
                              ))
                            }
                          </>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={3} className="nodata">
                            No Data to Display
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>

            </Box>
          </Box>
        </Box>
      )}
    </ThemeContainer>
  );
}

KycDocs.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(KycDocs);
