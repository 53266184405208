import React from 'react';
import { Line } from 'react-chartjs-2';

const data = {
    labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
    datasets: [
        {
            label: 'CMF',
            data: [12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3],
            fill: false,
            backgroundColor: 'rgb(239, 135, 129)',
            borderColor: 'rgba(255, 99, 132, 0.5)',
            tension: 0.3
        },
        {
            label: 'ABC',
            data: [8, 10, 4, 5, 12, 9, 8, 10, 4, 5, 12, 9],
            fill: false,
            backgroundColor: 'rgb(71, 159, 200)',
            borderColor: 'rgba(71, 159, 200, 0.5)',
            tension: 0.3
        },
        {
            label: 'BCD',
            data: [13, 14, 7, 16, 9, 12, 13, 14, 7, 16, 9, 12],
            fill: false,
            backgroundColor: 'rgb(247, 202, 92)',
            borderColor: 'rgba(247, 202, 92, 0.5)',
            tension: 0.3
        },
    ],
};

const options = {
    elements: {
        point: {
            radius: 0
        }
    },
    scales: {
        // yAxes: [
        //     {
        //         ticks: {
        //             beginAtZero: true,
        //         },
        //     },
        // ],
    },
    plugins: {
        legend: {
            labels: {
                usePointStyle: true,
            },
        },
        title: {
            display: true,
            text: "Month wise Lenders Discounting",
            align: 'start',
            font: {
                size: 16,
                weight: 'bold'
            },
            padding: 20,
            color: "#479FC8"
        },
    }
};

const LineChart = () => (
    <Line data={data} options={options} />
);

export default LineChart;