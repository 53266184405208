import React from 'react';
import { Grid, Button, Box, Typography } from "@material-ui/core"
import Otpinput from "../../../components/OTP/otp.jsx"
import image from "../../../assets/img/forgot-password.png";
import HomePage5 from "../../HomePage/HomePage5"
import SignupFooter from 'views/Signup/footer';
import { isMobile } from "react-device-detect"


export default function GoogleAuth() {
    return (
        <>
            <Box style={{ background: "white" }}>
                {isMobile ? <Box style={{ background: "white", padding: "5%" }}>
                    <img style={{ width: "98%" }} src={image} />
                </Box> : ""}
                <Box style={{ backgroundImage: "", width: "100%", height: "100%", padding: isMobile ? "" : "7%", paddingBottom: "10%" }}>

                    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", background: "white", borderRadius: isMobile ? "" : "16px" }}>

                        <Grid container>
                            <Grid item xs={12} sm={12} md={6} xl={6} style={{ marginRight: "0%" }}>
                                <Grid container style={{ paddingLeft: isMobile ? "5%" : "15%", paddingRight: "15%", paddingTop: "8%" }}>
                                    <Grid item xs={12} style={{ fontSize: isMobile ? "24px" : "32px", color: "#18425D", fontWeight: "bold", lineHeight: "48px", fontFamily: "Averta-SemiBold" }}>
                                        Google Authenticator
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography style={{ fontWeight: "600", color: "#848F9F", fontSize: "12px" }} >Please use google authentication app for 2 Factor Authentication, OTP enter below to Authenticate your Identity</Typography>
                                    </Grid>
                                    <Grid item xs={12} style={{ paddingTop: "10%" }}>
                                        <Typography style={{ fontSize: "14px", fontWeight: "600" }}>OTP</Typography>
                                    </Grid>
                                    <Grid item xs={12} style={{ alignContent: "center" }}>
                                        <Otpinput type="passsword" />
                                    </Grid>
                                    <Grid item xs={12}>

                                        <Typography style={{ paddingLeft: "25%" }}>Resend in { }</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container style={{ paddingTop: "5%", height: "15%", paddingBottom: "5%" }}>
                                    {isMobile ?
                                        <>
                                            <Grid item xs={6} container direction="row" alignItems="center" justify="flex-end">
                                                <Button fullWidth style={{ textTransform: "none", marginLeft: "5%", color: "#FFFFFF", background: "#479FC8", width: "100%", height: "130%" }} >Authenticate now</Button>
                                            </Grid>
                                            <Grid item xs={6} >
                                                <Button fullWidth style={{ textTransform: "none", color: "#479FC8", background: "#EBF8FE", width: "90%", height: "130%" }}>Cancel</Button>
                                            </Grid>
                                        </>
                                        :
                                        <>
                                            <Grid item xs={5} container direction="row" alignItems="center" justify="flex-end" style={{ paddingRight: "1%" }}>
                                                <Button fullWidth style={{ textTransform: "none", color: "#FFFFFF", background: "#479FC8", width: "60%", height: "120%", lineHeight: "16px", fontFamily: "Averta-Regular" }} >Authenticate now</Button>
                                            </Grid>
                                            <Grid item xs={6} >
                                                <Button style={{ vmarginRight: "10%", color: "#479FC8", background: "#EBF8FE", width: "50%", height: "120%" }}>Cancel</Button>
                                            </Grid>
                                        </>}
                                </Grid>

                            </Grid>
                            {isMobile ? " " : <Grid item xs={12} sm={12} md={6} xl={6} style={{ padding: "10%" }}>
                                <img style={{ width: "98%" }} src={image} />
                            </Grid>}
                        </Grid>
                    </Box>

                </Box>
                <HomePage5 />
                {isMobile ? "" : <SignupFooter />}
            </Box>

        </>
    );
}