import React from 'react';
// import { Box } from "@material-ui/core"
import { Bar } from 'react-chartjs-2';
import { PropTypes } from 'prop-types';

export default function VerticalBarBottom(props) {

    const { firstCol, secondCol, title } = props;
    const data = {
        labels: ["abc", 'Jan', 'Feb', "Mar"],
        datasets: [
            {
                label: ['Lending Amount'],
                data: [80, 50],
                backgroundColor: [
                    firstCol, secondCol

                ],
                borderRadius: Number.parseInt(4),
                barPercentage: 1.0,
                categoryPercentage: 1.0,
            },
            {
                label: ['Current Value'],
                backgroundColor: [
                    secondCol

                ]
            },
        ],
    };

    const options = {
        plugins: {
            title: {
                display: true,
                text: title,
                align: 'start',
                font: {
                    size: 16,
                    weight: 'bold'
                },
                padding: 20,
                color: "#479FC8"
            },
            legend: {
                position: 'right',
                usePointStyle: true,
            },
        },

        scales: {
            x: {
                display: false,
                grid: {
                    lineWidth: 0,
                },
                stacked: true
            },
            y: {
                display: false,
                grid: {
                    lineWidth: 0,
                },

            },
        },
    };

    return (
        <Bar data={data} options={options} />
    );
}



VerticalBarBottom.propTypes = {
    firstCol: PropTypes.string,
    secondCol: PropTypes.string,
    title: PropTypes.string
}