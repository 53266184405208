import React, { useEffect, useState } from "react";
import ContactDetail from "../Components/Common/ContactDetail";
import { getAccessToken } from "../../utilities/tokenUtil";
import axios from "axios";
import ThemeContainer from "views/Components/sideBar/ThemeContainer";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";
import EditICon from "../../assets/img/editIcon.svg";
import Defaultimg from "../../assets/img/avatar.png";
import { Grid } from "@material-ui/core";

const Myaccount = (props) => {
  const userId        = localStorage.getItem("userId");
  const userName      = localStorage.getItem("userName");
  const is_lender     = localStorage.getItem("is_lender");
  const trader_type   = localStorage.getItem('trader_type');
  const api_url       = process.env.REACT_APP_URL;

  const [account_detail, setAccountDetail] = useState("");
  const history                     = useHistory();
  const [loader, setLoader]         = useState(false);

  if(!userId || userName === null){
    history.push('/signin');
  }

  if(is_lender === "true" && trader_type === "undefined"){
    history.push('/lender/dashboard');
  }
    
  const myDetail = () => {
    const access_token = getAccessToken();
    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: access_token
    };
    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/get/my_account/detail`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        if (response.data.success === true) {
          setAccountDetail(response.data.account_detail[0]);
          setLoader(true)
        }else{
          setLoader(true)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  
  useEffect(() => {
    document.title = 'Interswitch - My Account';
    window.scrollTo(0, 0);
    myDetail();
  }, []);

  
  return (
      
          <ThemeContainer type="private" value={5}>
            {loader !== true ? (
              <Grid container spacing={3} className="bootstrap-loader">
                
                  <Spinner animation="grow" variant="spinner1" />
                  <Spinner animation="grow" variant="spinner2" />
                  <Spinner animation="grow" variant="spinner3" />
                
              </Grid>
            ) : (
              <>
              <Container className="viewusers">
                <Row className="my-3">
                  <Col className="cp-heading1" md={11} xs={10}>                  
                      My Account  
                  </Col>
                  <Col className="cp-heading1" md={1} xs={2}>                        
                      <button className="view-edit" onClick={() => history.push("/editaccount")} >
                          <img src={EditICon} alt="" />
                      </button>
                  </Col>
                </Row>
                
                  {account_detail &&
                  
                    <div>
                      <Row>
                        <div className="cp-card" style={{ backgroundColor: props.color }}>
                          <Col className="p-4">
                            <Row className="cp-heading2 mb-5">
                              <Col>Contact Person Details</Col>
                            </Row>
                            <Row>
                              <Col md={3}>
                              {account_detail.image ? (
                                <img src={`${api_url+account_detail.image}?${Date.now()}`} alt="" className="cp-contact-img" />
                              ) : (
                                <img src={Defaultimg} alt="" className="cp-contact-img" />
                              )}
                              </Col>
                              <Col md={9}>
                                <Row className="cp-heading3">
                                  <Col>{account_detail.name}</Col>
                                </Row>
                                <Row
                                  className="cp-sub2 my-3"
                                  style={{ fontSize: "20px" }}
                                >
                                  <Col>
                                    {account_detail.job_position ? account_detail.job_position : ""}
                                  </Col>
                                </Row>
                                <Row className="cp-sub1 my-3">
                                  <Col>
                                    <span>BVN Number - </span>
                                    <span className="cp-sub2">{account_detail.bvn_number}</span>
                                  </Col>
                                </Row>
                                <Row className="cp-sub1 my-3">
                                  <Col>
                                    <span>Email Address - </span>
                                    <span className="cp-sub2">{account_detail.email}</span>
                                  </Col>
                                </Row>
                                {account_detail.phone &&
                                <Row className="cp-sub1 my-3">
                                  <Col>
                                    <span>Mobile Number - </span>
                                    <span className="cp-sub2">
                                      {account_detail.mobile &&
                                          account_detail.mobile
                                      }
                                    </span>
                                  </Col>
                                </Row>
                                }
                                {account_detail.phone &&
                                <Row className="cp-sub1 my-3">
                                  <Col>
                                    <span>Phone Number - </span>
                                    <span className="cp-sub2">
                                      {account_detail.phone &&
                                        account_detail.phone
                                      }
                                    </span>
                                  </Col>
                                </Row>
                              }
                                <Row className="cp-sub1 my-3">
                                  <Col>
                                    <span>Address - </span>
                                    <span className="cp-sub2">
                                      {account_detail.address &&
                                          account_detail.address
                                      }
                                      {account_detail.street2 &&
                                          ", " + account_detail.street2
                                      }
                                      {account_detail.city_id &&
                                          ", " + account_detail.city_id
                                      }
                                      {account_detail.state_name &&
                                          ", " + account_detail.state_name 
                                      }
                                      {account_detail.country_name &&
                                          ", " + account_detail.country_name
                                      }
                                      {account_detail.zip &&
                                          " - " + account_detail.zip
                                      }
                                    </span>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                        </div>
                      </Row>
                    </div>
                  }

              </Container>
              </>
            )}
          </ThemeContainer>        
          
        
      
  );
};
export default Myaccount;
