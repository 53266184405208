import React from "react";
import { Row, Col } from "react-bootstrap";
import Defaultimg from "../../../assets/img/avatar.png";

const ContactDetail = (props) => {
  const api_url = process.env.REACT_APP_URL;
  let datas = props.info;
  
  return (
    <>
      {datas &&
        datas.length > 0 &&
        datas.map((info, index) => (
          <div key={index}>
            <Row>
              <div className="cp-card" style={{ backgroundColor: props.color }}>
                <Col className="p-4">
                  <Row className="cp-heading2 mb-5">
                    <Col>Contact Person Details</Col>
                  </Row>
                  <Row>
                    <Col md={3}>
                      {info.image ? (
                        <img src={`${api_url+info.image}?${Date.now()}`} alt="" className="cp-contact-img" />
                      ) : (
                        <img src={Defaultimg} alt="" className="cp-contact-img" />
                      )}
                    </Col>
                    <Col md={9}>
                      <Row className="cp-heading3">
                        <Col>{info.name}</Col>
                      </Row>
                      <Row className="cp-sub2 my-3" style={{ fontSize: "20px" }} >
                        <Col>
                          {info.user_role ? info.user_role : ""}
                        </Col>
                      </Row>

                      {info.designation &&
                      <Row className="cp-sub1 my-3">
                        <Col>
                          <span>Designation - </span>
                          <span className="cp-sub2">{info.designation}</span>
                        </Col>
                      </Row>
                      }
                      <Row className="cp-sub1 my-3">
                        <Col>
                          <span>BVN Number - </span>
                          <span className="cp-sub2">{info.bvn_number}</span>
                        </Col>
                      </Row>
                      <Row className="cp-sub1 my-3">
                        <Col>
                          <span>Working at Branch/ HO - </span>
                          <span className="cp-sub2">
                            {info.working_location ? info.working_location : ""}
                            {info.working_location_name
                              ? info.working_location_name
                              : ""}
                          </span>
                        </Col>
                      </Row>
                      <Row className="cp-sub1 my-3">
                        <Col>
                          <span>Email Address - </span>
                          <span className="cp-sub2">{info.email}</span>
                        </Col>
                      </Row>
                      {info.mobile &&
                      <Row className="cp-sub1 my-3">
                        <Col>
                          <span>Mobile Number - </span>
                          <span className="cp-sub2">
                            {info.mobile &&
                                info.mobile
                            }
                          </span>
                        </Col>
                      </Row>
                      }
                      {info.phone_number &&
                      <Row className="cp-sub1 my-3">
                        <Col>
                          <span>Phone Number - </span>
                          <span className="cp-sub2">
                            {info.phone_number &&
                              info.phone_number
                            }
                          </span>
                        </Col>
                      </Row>
                      }
                      <Row className="cp-sub1 my-3">
                        <Col>
                          <span>Address - </span>
                          <span className="cp-sub2">
                            {info.address &&
                                info.address
                            }
                            {info.street2 &&
                                ", " + info.street2
                            }
                            {info.city &&
                                ", " + info.city
                            }
                            {info.state_name &&
                                ", " + info.state_name 
                            }
                            {info.country_name &&
                                ", " + info.country_name
                            }
                            {info.zip !== "False" &&
                                " - " + info.zip
                            }
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </div>
            </Row>
          </div>
        ))}
    </>
  );
};
export default ContactDetail;
