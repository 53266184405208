
import React from 'react';
import { IconButton } from '@material-ui/core';
import image1 from "assets/img/write(2).png";
import image2 from "assets/img/right(1).png";
import image3 from "assets/img/delete(3).png";
import image4 from "assets/img/eyes.png";
import { Box } from '@material-ui/core';
import { isMobile } from 'react-device-detect';


const myStyles = {
    buttonStyle: {
        margin: isMobile ? "" : "2%"

    },
    box: {
        display: "flex"
    }
}

export default function UploadButtons() {
    return (<>

        <Box style={myStyles.box}>
            <IconButton size={isMobile ? "small" : "medium"}>
                <img style={myStyles.buttonStyle} src={image1} /></IconButton>
            <IconButton size={isMobile ? "small" : "medium"}>
                <img style={myStyles.buttonStyle} src={image2} /></IconButton>
            <IconButton size={isMobile ? "small" : "medium"}> <img style={myStyles.buttonStyle} src={image3} /></IconButton>
            <IconButton size={isMobile ? "small" : "medium"}> <img style={myStyles.buttonStyle} src={image4} /></IconButton>
        </Box>


    </>
    );
}