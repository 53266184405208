import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom"
import {
  Grid,
  Box,
  Typography,
  OutlinedInput,
  withStyles,
  MenuItem,
  FormControl,
  Select,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Toolbar,
  Snackbar,
  SnackbarContent
} from "@material-ui/core";
import preview from "assets/img/previewButton.svg";
import Custom from "views/Components/Custom";
import ThemeContainer from "views/Components/sideBar/ThemeContainer";
import { isMobile } from "react-device-detect";
import { PropTypes } from "prop-types";
import TableComponent from "views/Components/tableComponent/tablecomponent";
import { getAccessToken } from "../../utilities/tokenUtil";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
//import image from "../../assets/img/profile.svg";
import image from "assets/img/defaultimg.jpg";
var axios = require("axios");

const userId        = localStorage.getItem("userId");
const userName      = localStorage.getItem("userName");
const kyc_status    = localStorage.getItem("kyc_status");

const styles = (theme) => ({
  textFields: {
    background: "#F3F5F6",
    height: "40px",
    marginTop: "1%",
    "& div": {
      "&::before": {
        content: "",
        borderBottom: "none",
      },
      "&::after": {
        borderBottom: "none",
      },
      "&:hover": {
        "&::before": {
          borderBottom: "none !important",
        },
        "&::after": {
          borderBottom: "none !important",
        },
      },
      "& .MuiInputBase-input": {
        padding: "9px 7px",
      },
    },
  },
});

const mystyle = {
    heading: { marginTop: "5%", marginBottom: "2%", color: "#479FC8", fontSize: "16px", fontWeight: "600", height: "24px" },
    titles: { fontSize: "16px", fontWeight: "600", paddingBottom: "2%", color: "#479FC8" },
    textfield: { fontSize: "12px", fontWeight: "600", color: "#848F9F", marginTop: "-1%" },
    detail: { fontSize: "9px", fontWeight: "400", color: "#999999" },
    box: { paddingTop: "3%", paddingBottom: "3%" },
    upload: { fontSize: "14px", fontWeight: "600", color: "#0275D8", border: "1px solid #0275D8", width: "100%" },
    save: { height: "150%", width: "100%", background: "#479FC8", borderRadius: "5px", color: "#FFFFFF", textTransform: "initial" },
    invite: { height: "150%", width: "100%", background: "#008000", borderRadius: "5px", color: "#FFFFFF", textTransform: "initial" },
    cancel: { height: "150%", width: "100%", background: "#EBF8FE", borderRadius: "5px", color: "#479FC8", textTransform: "initial" },
}

function ViewUser(props) {
    const { classes } = props;
    const history = useHistory()

    const [name, setFullName]               = useState("");
    const [country, setCountry]             = useState("");
    const [countryList, setCountryList]     = useState("");
    const [email, setEmail]                 = useState("");
    const [designation, setDesignation]     = useState("");
    const [Mobilenumber, setMobileNumber]   = useState("");
    const [Bvnnumber, setBvnNumber]         = useState("");
    const [address, setAddress]             = useState("");
    const [zip, setZip]                     = useState("");
    const [WorkingatBranchHO, setWorkingatBranchHO] = useState("");
    const [state, setState]                 = useState("");
    const [city, setCity]                   = useState("");
    const [roles, setRoles]                 = useState("");
    const [user_list,setUserList]           = useState([]);
    const [documents,setDocuments]          = useState([]);
    const [loading, setLoading]             = useState(true);
    const [message, setMessage]             = useState({});
    const [open, setOpen]                   = useState(false);
    const [user_documents, setUserDocuments] = useState("")
    const [contact_id, setContactId]        = useState("");
    const [partner_id, setPartnerId]        = useState("");
    const [document_id, setDocumentId]      = useState("");
    const [document_type_id, setDocumentTypeID]   = useState("");
    const [file2, setfile2]                 = useState();
    const [file, setfile]                   = useState();
    const [hqList, setHqList]               = useState([]);

    if(!userId || userName === null){
        history.push('/signin');
    }
    if(kyc_status !== "Approved"){
        history.push('/kyc-page');
    }
    
    const userDetail = () => {
        const access_token = getAccessToken();
        const data = {
            user_id: parseInt(localStorage.getItem("userId")),
            access_token: access_token,
            type: "user",
            contact_id: parseInt(props.match.params.id),
        };
        axios
        .post(`${process.env.REACT_APP_URL}/api/v1/user/detail`, data, {
            headers: {
                "Content-Type": "text/plain",
            },
        })
        .then((response) => {

            if (response) {
                setAddress(response.data.address);
                setBvnNumber(response.data.bvn_number);
                setCity(response.data.city);
                setCountry(response.data.country_name);
                setState(response.data.state_name);
                setDesignation(response.data.designation);
                setMobileNumber(response.data.mobile);
                setFullName(response.data.name);
                setEmail(response.data.email);
                setRoles(response.data.user_role);
                setWorkingatBranchHO(response.data.working_location_name);
                setZip(response.data.zip);
                setUserDocuments(response.data.documents);
                setfile2(response.data.image);
            }
        })
        .catch((error) => {
            console.log(error);
        });
    };
    // Download uploaded files
    const downloadDocument = (event, document_id) => {
        event.preventDefault();        
        const access_token = getAccessToken();
        
        const data = {
            user_id: parseInt(localStorage.getItem("userId")),
            access_token: access_token,
            partner_id: parseInt(props.match.params.id),
            document_id: parseInt(document_id)
        };

        axios
        .post(`${process.env.REACT_APP_URL}/api/v1/user/document/download`, data, {
            headers: {
                "Content-Type": "text/plain",
            },
        })
        .then((response) => {
          
            if(response.data.success === true){
                let url = process.env.REACT_APP_URL+response.data.document_url;
                window.open( url, '_blank' );
            }else{
                setLoading(false);
                setOpen(true);
                setMessage({ type: "error", content: "Something went wrong." });
            }
            
        })
        .catch((error) => {
            console.log(error);
        });
    };
    useEffect(() => {
        window.scrollTo(0, 0);
        userDetail();
    }, []);

  return (
    <ThemeContainer type="private" value={1}>        
        <Box style={{ height: "100%" }}>
          <Box style={{ marginTop: isMobile ? "10%" : " ", background: "#FFFFFF", padding: "3%", borderRadius: "16px", }} >
            <Grid container>
              <Grid item xs={12} sm={12} md={12} xl={12}>
                <Typography className="company-title11">User Details</Typography>
              </Grid>
            </Grid>
            <Grid container xs={12} sm={12} md={12} lg={12} xl={12} style={{ alignItems: "center" }}>  
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <center>
                    <Box style={{ height: "140px", width: "85%", position: "relative", textAlign: "center", }} >
                      {file2 ? (
                        <img style={{ height: "100%", width: "85%" }} src={process.env.REACT_APP_URL+file2} />
                      ) : (
                        <img style={{ height: "100%", width: "85%" }} src={image} />
                      )}
                    </Box>
                  </center>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4} xl={4}>
                <Box style={mystyle.box}>
                  <span className="company-title3">Full Name<span className="required">*</span></span>
                  <OutlinedInput
                    className="company-title5"
                    fullWidth
                    readOnly
                    placeholder="e.g. Full Name"
                    value={name}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={4} xl={4}>
                <Box style={mystyle.box}>
                  <span className="company-title3">Mobile<span className="required">*</span></span>
                  <OutlinedInput
                    className="company-title5"
                    fullWidth
                    placeholder="e.g. Mobile Number"
                    readOnly
                    value={Mobilenumber}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4} xl={4}>
                <Box style={mystyle.box}>
                  <span className="company-title3">Email Address<span className="required">*</span></span>
                  <OutlinedInput
                    className="company-title5"
                    fullWidth
                    placeholder="e.g. Email"
                    readOnly
                    value={email}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={4} xl={4}>
                <Box style={mystyle.box}>
                  <span className="company-title3">Roles<span className="required">*</span></span>
                  <OutlinedInput
                    className="company-title5"
                    fullWidth
                    placeholder="e.g. Roles"
                    readOnly
                    value={roles}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4} xl={4}>
                <Box style={mystyle.box}>
                  <span className="company-title3">Designation<span className="required">*</span></span>
                  <OutlinedInput
                    className="company-title5"
                    fullWidth
                    placeholder="e.g. Designation"
                    readOnly
                    value={designation}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={4} xl={4}>
                <Box style={mystyle.box}>
                  <span className="company-title3">BVN Number<span className="required">*</span></span>
                  <OutlinedInput
                    className="company-title5"
                    fullWidth
                    placeholder="e.g. BVN Number"
                    readOnly
                    value={Bvnnumber}
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4} xl={4}>
                <Box style={mystyle.box}>
                  <span className="company-title3">Working At Branch HO<span className="required">*</span></span>
                  <OutlinedInput
                    className="company-title5"
                    fullWidth
                    placeholder="e.g. Working at Branch HO"
                    readOnly
                    value={WorkingatBranchHO}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4} xl={4}>
                <Box style={mystyle.box}>
                  <span className="company-title3">Address<span className="required">*</span></span>
                  <OutlinedInput
                    className="company-title5"
                    fullWidth
                    placeholder="e.g. Address"
                    readOnly
                    value={address}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={4} xl={4}>
                <Box style={mystyle.box}>
                  <span className="company-title3">Zip/ Postal Code<span className="required">*</span></span>
                  <OutlinedInput
                    className="company-title5"
                    fullWidth
                    placeholder="e.g. Zip/ Postal Code"
                    readOnly
                    value={zip}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4} xl={4}>
                <Box style={mystyle.box}>
                  <span className="company-title3">Country<span className="required">*</span></span>
                  <OutlinedInput
                    className="company-title5"
                    fullWidth
                    placeholder="e.g. Country"
                    readOnly
                    value={country}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4} xl={4}>
                <Box style={mystyle.box}>
                  <span className="company-title3">State/Provience<span className="required">*</span></span>
                  <OutlinedInput
                    className="company-title5"
                    fullWidth
                    placeholder="e.g. State"
                    readOnly
                    value={state}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4} xl={4}>
                <Box style={mystyle.box}>
                  <span className="company-title3">City<span className="required">*</span></span>
                  <OutlinedInput
                    className="company-title5"
                    fullWidth
                    placeholder="e.g. City"
                    readOnly
                    value={city}
                  />
                </Box>
              </Grid>
            </Grid>

            <Typography style={mystyle.heading}>Upload Documents</Typography>

            <Box style={{ marginTop: "1%", marginBottom: "5%" }}>
                
                {user_documents &&
                user_documents.map((item) => (
                <Box>
                    <Grid container spacing={2} >
                        <Grid item xs={6} sm={12} md={3} lg={3} xl={3}>
                            <cenetr><h4 className="company-title3" style={{ padding: "5%", color: "black" }}>{item.doc_name} <span style={{ color: "red" }}>*</span></h4></cenetr>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Grid container spacing={2}>       
                            {item.doc_list &&
                                item.doc_list.map((user_item) => (
                                                                         
                                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                    <center>
                                        <Box style={{ height: "100%",  width: "100%",  position: "relative", textAlign: "center" }}>
                                            <img style={{ height: "100%", width: "100%" }} src={process.env.REACT_APP_URL+user_item.document_url} onClick={(e) => downloadDocument(e, user_item.document_id)}/>                                            
                                        </Box>
                                    </center>
                                </Grid>                               
                                
                            ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                ))}
            </Box>

            <Grid container spacing={3} style={{ paddingLeft: "0%", paddingTop: "2%", paddingBottom: "5%" }}>
              <Grid item xs={6} sm={4} md={2} xl={2}>
                  <Link to="/manageuser"><Button style={ mystyle.cancel }>Cancel</Button></Link>
              </Grid>
            </Grid>

          </Box>
        </Box>
          
    </ThemeContainer>
  );
}

ViewUser.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(ViewUser);
