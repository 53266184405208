import React, { useState, useEffect } from "react";
import {
    Grid,
    Box,
    Typography,
    withStyles,
    OutlinedInput,
    Button,
    Table,
    TableBody,
    TableContainer,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    Snackbar,
    SnackbarContent,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { getAccessToken } from "../../utilities/tokenUtil";
import ThemeContainer from "views/Components/sideBar/ThemeContainer";
import { useHistory } from "react-router-dom";
import { Spinner } from "react-bootstrap";

var axios = require("axios");

const styles = () => ({

    textFields: {
        background: "#F3F5F6",
        height: "40px",
        marginTop: "1%",
        "& div": {
            "&::before": {
                content: "",
                borderBottom: "none",
            },
            "&::after": {
                borderBottom: "none",
            },
            "&:hover": {
                "&::before": {
                    borderBottom: "none !important",
                },
                "&::after": {
                    borderBottom: "none !important",
                },
            },
            "& .MuiInputBase-input": {
                padding: "9px 7px",
            },
        },
    },
});

function ViewKycDocs(props) {
    const { classes } = props;

    const userId = localStorage.getItem("userId");
    const userName = localStorage.getItem("userName");
    const kyc_status = localStorage.getItem("kyc_status");
    const api_url = process.env.REACT_APP_URL;

    const history = useHistory();
    const [lenderstList, setLendersList] = useState([]);
    const [docDetail, setDocDetail] = useState("");
    const [message, setMessage] = useState({});
    const [open, setOpen] = useState(false);
    const [loader, setLoader] = useState(false);

    if (!userId || userName === null) {
        history.push("/signin");
    }
    if (kyc_status !== "Approved") {
        history.push("/kyc-page");
    }
    const allDomiciledKycDocList = () => {
        const access_token = getAccessToken();
        const data = {
            access_token: access_token,
            user_id: parseInt(localStorage.getItem("userId")),
            doc_id: parseInt(props.match.params.id),
        };
        axios
            .post(`${process.env.REACT_APP_URL}` + "/api/v1/doc/detail", data, {
                headers: {
                    "Content-Type": "text/plain",
                },
            })
            .then((response) => {
                if (response && response.data.success === true) {
                    setLendersList(response.data.lenders);
                    setDocDetail(response.data.doc);
                    setLoader(true);
                } else {
                    setLoader(true)
                }
            })
            .catch((error) => {
                console.log(error);
                setLoader(true)
            });
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    useEffect(() => {
        document.title = 'Interswitch - Domiciled Banks KYC Docs';
        allDomiciledKycDocList();
    }, []);

    return (
        <ThemeContainer type="private" value={3}>
            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={open} autoHideDuration={3000} onClose={handleClose}
            >
                <SnackbarContent className={message.type === "error" ? "snack-error" : message.type === "success" ? "snack-success" : "snack-teal"} message={message.content} />
            </Snackbar>
            {loader !== true ? (
                <Grid container spacing={3} className="bootstrap-loader">
                    <Spinner animation="grow" variant="spinner1" />
                    <Spinner animation="grow" variant="spinner2" />
                    <Spinner animation="grow" variant="spinner3" />
                </Grid>
            ) : (
                <Box className="scf-box">
                    <Box className="scf-subbox">
                        <Box >
                            <Box>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} sm={6} md={11} xl={11}>
                                        <Typography className="listing-main-title">
                                            {"Domiciled Banks KYC Docs Detail"}{" "}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={1} xl={1}>
                                        <Button className="upload back-button" onClick={() => history.push("/domiciled-banks")}>
                                            <i className="fas fa-long-arrow-alt-left"> {" "}Back </i>
                                        </Button>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2}>
                                    <Grid item xs={6} sm={6} md={4} xl={4}>
                                        <Box>
                                            <span className="company-title3">Document Name</span>
                                            <OutlinedInput
                                                className="company-title5"
                                                fullWidth
                                                placeholder="Doc Name"
                                                value={docDetail.name}
                                                readOnly
                                            />
                                        </Box>
                                    </Grid>
                                    {docDetail.document_url &&
                                    <Grid item xs={6} sm={6} md={4} xl={4}>
                                        <Box>
                                            <span className="company-title3">Uploaded Document</span>
                                            {docDetail.doc_extension === "application/pdf" ? (
                                                <embed src={`${api_url + docDetail.document_url}?${Date.now()}`} type={doc_item.image_extension} />
                                            ) : (
                                                <img src={`${api_url + docDetail.document_url}?${Date.now()}`} style={{ width: "100%"}}/>
                                            )}
                                        </Box>
                                    </Grid>
                                    }
                                </Grid>

                                <Typography className="list-sub-title">
                                    Domiciled Banks Lenders List
                                </Typography>
                            </Box>
                            <Box className="view-kyc-docs no-cursor-table">
                                <TableContainer component={Paper}>
                                    <Table className="table-top" aria-label="simple table" >
                                        <TableHead>
                                            <TableRow className="table-row" >
                                                <TableCell className="table-header" align="left" style={{ width: "5%" }}> S. No.{" "} </TableCell>
                                                <TableCell align="left" className="table-header"> Name </TableCell>
                                                <TableCell align="left" className="table-header"> Status </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {lenderstList &&
                                                lenderstList.length > 0 &&
                                                lenderstList !== undefined ? (
                                                lenderstList.map((info, index) => (
                                                    <TableRow className="table-row" key={index}>
                                                        <TableCell align="left" component="th" scope="row"> {index + 1} </TableCell>
                                                        <TableCell align="left"> {info.name} </TableCell>
                                                        <TableCell align="left" className={info.lender_status.toLowerCase().replace(/\s/g, '-')} style={{ textTransform: "capitalize" }}> {info.lender_status} </TableCell>
                                                    </TableRow>
                                                ))
                                            ) : (
                                                <TableRow>
                                                    <TableCell colSpan={3} className="nodata">
                                                        No Data to Display
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>

                        </Box>
                    </Box>
                </Box>
            )}
        </ThemeContainer>
    );
}

ViewKycDocs.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(styles)(ViewKycDocs);
