import React, { useEffect, useState } from "react";
import ContactDetail from "../Components/Common/ContactDetail";
import Card from "../Components/Common/Card";
import UploadDocument from "../Components/Common/UploadDocument";
import { getAccessToken } from "../../utilities/tokenUtil";
import axios from "axios";
import ThemeContainer from "views/Components/sideBar/ThemeContainer";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Button, Grid } from "@material-ui/core";

const ViewUsers = (props) => {

  const userId        = localStorage.getItem("userId");
  const userName      = localStorage.getItem("userName");

  const [data, setData] = useState();
  const [array, setArray] = useState([]);
  const history = useHistory();
  const [loader, setLoader]       = useState(false);

  if(!userId || userName === null){
    history.push('/signin');
  }
    
  const userDetail = () => {
    const access_token = getAccessToken();
    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: access_token,
      type: "user",
      contact_id: parseInt(props.match.params.id),
    };
    setLoader(false)
    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/user/detail`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setData(response.data);
          setArray([response.data]);
          setLoader(true)
        }else{
          setLoader(true)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  
  useEffect(() => {
    document.title = 'Interswitch - User Profile';
    window.scrollTo(0, 0);
    userDetail();
  }, []);
  return (
    <>
      {loader !== true ? (
          <Grid container spacing={3} className="bootstrap-loader">            
              <Spinner animation="grow" variant="spinner1" />
              <Spinner animation="grow" variant="spinner2" />
              <Spinner animation="grow" variant="spinner3" />            
          </Grid>
        ) : (
        <>
      {data && data.success && (
        <>
          <ThemeContainer type="private" value={5}>
            <Container className="viewusers">
              <Row className="my-4" >
                <Col className="cp-heading1" md={11} xs={8} >User Profile</Col>
                <Col md={1} xs={4} className="user-heading">                    
                  <Button className="back-button upload" onClick={() => history.push("/manageuser")}><i className="fas fa-long-arrow-alt-left" > </i> Back</Button>
                </Col>
              </Row>
              <ContactDetail info={array} />
              <Card
                info={data}
                name={"Contact Information"}
                color={"#479FC8"}
              />
              <UploadDocument info={data.documents} />
            </Container>
          </ThemeContainer>
        </>
      )}
      </>
      )}
    </>
  );
};
export default ViewUsers;
