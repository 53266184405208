import React, { useState, useEffect, useContext } from "react";
import styles from "assets/jss/material-kit-react/views/components.js";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { OutlinedInput } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { FormControl } from "@material-ui/core";
import {
  Box,
  Typography,
  Toolbar,
  Switch,
  Snackbar,
  SnackbarContent,
  Select,
  MenuItem,
} from "@material-ui/core";
import image from "../../assets/img/Financedoodle.svg";
import KYCNow from "./KYCComponents/KYCNow";
import SignupFooter from "../Signup/footer";
import BankKYC from "./KYCComponents/BankKYC";
import { isMobile } from "react-device-detect";
import { styled } from "@material-ui/core";
import ThemeContainer from "views/Components/sideBar/ThemeContainer";
import axios from "axios";
import { getAccessToken } from "../../utilities/tokenUtil";
import { useHistory } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { Row, Col, Spinner } from "react-bootstrap";
import UploadDocument from "views/Components/Common/KycDocumentNew";
import EditICon from "../../assets/img/editIcon.svg";
import { Store } from "../../context/store/storeState";
import Approved from "../../assets/img/approved.png";
import AwaitingApproval from "../../assets/img/awaiting-approval.png";
import Draft from "../../assets/img/draft.png";
import Rejected from "../../assets/img/rejected.png";
import Tooltip from "@mui/material/Tooltip";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#444444" : "#444444",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    // transition: theme.transitions.create(['width'], {
    //     duration: 200,
    // }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#444444" : "#444444",
    boxSizing: "border-box",
  },
}));

const useStyles = makeStyles(styles);

export default function KycDocUpload() {
  const userId = localStorage.getItem("userId");
  const kyc_type = localStorage.getItem("kyc_type");
  const kyc_status = localStorage.getItem("kyc_status");
  const company_info = localStorage.getItem("redirect_company_info");
  const is_manual_kyc_enabled = localStorage.getItem("is_manual_kyc_enabled");

  const access_token = getAccessToken();

  const verifyContext = useContext(Store);
  const setKycStatusAdd = verifyContext.setKycStatusAdd;
  const kyc_status_add = verifyContext.kyc_status_add;
  const [loader, setLoader] = useState(false);

  const classes = useStyles();
  const [bank, setBank] = useState(
    (kyc_type === "Manual" && is_manual_kyc_enabled === true) ||
      is_manual_kyc_enabled === "true"
      ? true
      : false
  );
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [accNumber, setAccNumber] = useState("");
  const [branchName, setBranchName] = useState("");
  const [email, setEmail] = useState("");
  const [bankaccNumber, setBankAccNumber] = useState("");
  const [bankbranchName, setBankBranchName] = useState("");
  const [bankemail, setBankEmail] = useState(1);
  const [lender, setlender] = useState();
  const [Upload, setUpload] = useState("");
  const [message, setMessage] = useState({});
  const [allList, setallList] = useState({});
  //const [kyc_type,setkyc_type] =useState("");
  const [docType, setDocType] = useState({});
  const [bankDoc, setBankDoc] = useState();
  const [kycstatus, setKycStatus] = useState();
  const [kycType, setKycType] = useState();
  const history = useHistory();

  if (company_info && company_info !== "false") {
    history.push("/mycompany");
  }

  if (!userId) {
    history.push("/signin");
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const onSubmit = (event) => {
    if (bank) {
      history.push("/kycsuccess");
    } else {
      var a = new Set([]);
      docType.map((item) => {
        if (item.is_required === true) {
          allList.map((x) => {
            if (x.document_type_id === item.id) {
              a.add(x.document_type_id);
            }
          });
        }
      });
      let array = Array.from(a);
      let req = [];
      docType.map((item) => {
        if (item.is_required === true) {
          req.push(item.id);
        }
      });
      if (
        Array.isArray(req) &&
        Array.isArray(array) &&
        req.length === array.length &&
        req.every((val, index) => val === array[index])
      ) {
      } else {
        setOpen(true);
        setMessage({
          type: "error",
          content: "please submit all require document",
        });
        return;
      }
      if (accNumber === "") {
        setOpen(true);
        setMessage({ type: "error", content: "Account cannot be empty" });
        return;
      }

      if (email === "") {
        setOpen(true);
        setMessage({ type: "error", content: "Email cannot be empty" });
        return;
      }

      const access_token = getAccessToken();
      const dataset = {
        account_number: accNumber,
        rm_email: email,
        user_id: parseInt(localStorage.getItem("userId")),
        access_token: access_token,
        lender_id: lender,
      };

      axios
        .post(`${process.env.REACT_APP_URL}/api/v1/kyc/bank/process`, dataset, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then((response) => {
          history.push("/kycsuccess");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const toggle = () => {
    setBank(!bank);
  };

  const activity = {
    setAccNumber,
    setBranchName,
    setEmail,
    setlender,
    setallList,
    setDocType,
    setOpen,
    setMessage,
    setBank,
  };
  const activity1 = {
    setBankAccNumber,
    setBankBranchName,
    setBankEmail,
    setOpen,
    setMessage,
    setBank,
  };
  const getDocumentBank = () => {
    let kycTypes;
    if (kyc_type === "Bank") {
      kycTypes = "bank_kyc";
    } else {
      kycTypes = "manual_kyc";
    }
    const data = {
      access_token: access_token,
      user_id: parseInt(localStorage.getItem("userId")),
      kyc_type: kycTypes,
    };

    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/kyc/get/document/list`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((res) => {
        if (res.data.success) {
          setBankDoc(res.data);
          if (res.data.kyc_status === "Pending") {
            setLoading(false);
            setOpen(true);
            setMessage({
              type: "error",
              content: "Your KYC is pending, please upload the KYC documents.",
            });
          }
          if (res.data.kyc_status === "In Review") {
            setLoading(false);
            setOpen(true);
            setMessage({
              type: "error",
              content: "Your KYC is pending, your KYC is in process",
            });
          }
          if (res.data.kyc_status === "Pending") {
            localStorage.setItem("kyc_status", "Pending");
            setKycStatusAdd(res.data.kyc_status);
          }
          if (res.data.kyc_status === "Awaiting Approval") {
            localStorage.setItem("kyc_status", "Awaiting Approval");
            setKycStatusAdd(res.data.kyc_status);
          }
          if (res.data.kyc_status === "Approved") {
            localStorage.setItem("kyc_status", "Approved");
            setKycStatusAdd(res.data.kyc_status);
          }
          if (res.data.kyc_status === "Rejected") {
            localStorage.setItem("kyc_status", "Rejected");
            setKycStatusAdd(res.data.kyc_status);
          }
          if (res.data.kyc_status === "In Review") {
            localStorage.setItem("kyc_status", "In Review");
            setKycStatusAdd(res.data.kyc_status);
          }
          setKycType(res.data.kyc_type);
          setLoader(true);
        } else {
          setLoader(true);
        }
      });
  };
  const showKycForm = () => {};

  useEffect(() => {
    document.title = "Interswitch - KYC Process";
    window.scrollTo(0, 0);
    getDocumentBank();
  }, []);

  return (
    <ThemeContainer type="private" value={5}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}>
        <SnackbarContent
          className={
            message.type === "error"
              ? "snack-error"
              : message.type === "success"
              ? "snack-success"
              : "snack-teal"
          }
          message={message.content}
        />
      </Snackbar>
      {loader !== true ? (
        <Grid container spacing={3} className="bootstrap-loader">
          <Spinner animation="grow" variant="spinner1" />
          <Spinner animation="grow" variant="spinner2" />
          <Spinner animation="grow" variant="spinner3" />
        </Grid>
      ) : (
        <>
          <Box className="kyc-page scf-box">
            <Box className="scf-subbox">
              <Grid container alignItems="center">
                <Grid item xs={10} sm={10} md={10} xl={10}>
                  <Typography className="listing-main-title">
                    KYC Process
                  </Typography>
                </Grid>
                <Grid item xs={2} sm={2} md={2} xl={2} className="kyc-status">
                  {kyc_status_add === "Pending" && (
                    <Tooltip title={kyc_status_add} placement="top" arrow>
                      <span>
                        <img src={Draft} alt="" className="kyc-status-badge" />
                      </span>
                    </Tooltip>
                  )}
                  {kyc_status_add === "Rejected" && (
                    <Tooltip title={kyc_status_add} placement="top" arrow>
                      <span>
                        <img
                          src={Rejected}
                          alt=""
                          className="kyc-status-badge"
                        />
                      </span>
                    </Tooltip>
                  )}
                  {kyc_status_add === "Approved" && (
                    <Tooltip title={kyc_status_add} placement="top" arrow>
                      <span>
                        <img
                          src={Approved}
                          alt=""
                          className="kyc-status-badge"
                        />
                      </span>
                    </Tooltip>
                  )}
                  {kyc_status_add === "Awaiting Approval" && (
                    <Tooltip title={kyc_status_add} placement="top" arrow>
                      <span>
                        <img
                          src={AwaitingApproval}
                          alt=""
                          className="kyc-status-badge"
                        />
                      </span>
                    </Tooltip>
                  )}
                  {kyc_status_add === "In Review" && (
                    <Tooltip title={kyc_status_add} placement="top" arrow>
                      <span>
                        <img src={Draft} alt="" className="kyc-status-badge" />
                      </span>
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
              <br />
              {kyc_status_add === "Approved" ||
              (kyc_status_add === "In Review" &&
                kyc_status_add !== "Pending" &&
                kyc_status_add !== "Rejected") ? (
                <>
                  <Row>
                    <Col className="kyc-card">
                      <Row className="my-4">
                        <Col md={3}>
                          <Typography
                            className="main-title"
                            style={{ color: "#0275D8", fontSize: "32px" }}>
                            {kycType === "bank" && "KYC By Bank"}
                            {kycType === "manual" && "Manual KYC"}
                          </Typography>
                        </Col>
                      </Row>
                      {bankDoc && kycType === "bank" && (
                        <>
                          <Row>
                            <Col md={4}>
                              {" "}
                              <Box>
                                <span className="company-title15">
                                  Account Number
                                  <span className="required">*</span>
                                </span>
                                <br />
                                <span className="company-title16">
                                  {bankDoc.account_number
                                    ? bankDoc.account_number
                                    : ""}
                                </span>
                              </Box>
                            </Col>
                            <Col md={4}>
                              {" "}
                              <Box>
                                <span className="company-title15">
                                  Bank Name<span className="required">*</span>
                                </span>
                                <br />
                                <span className="company-title16">
                                  {bankDoc.kyc_bank_name
                                    ? bankDoc.kyc_bank_name
                                    : ""}
                                </span>
                              </Box>
                            </Col>
                            <Col md={4}>
                              {" "}
                              <Box>
                                <span className="company-title15">
                                  Relationship Manager Email
                                  <span className="required">*</span>
                                </span>
                                <br />
                                <span className="company-title16">
                                  {bankDoc.rm_email ? bankDoc.rm_email : ""}
                                </span>
                              </Box>
                            </Col>
                          </Row>
                        </>
                      )}
                    </Col>
                  </Row>
                  {bankDoc && (
                    <>
                      <Row>
                        <UploadDocument
                          info={bankDoc.document_list}
                          pagename="kycpage"
                        />
                      </Row>
                    </>
                  )}
                </>
              ) : (
                <>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Box>
                      <Typography
                        className="main-title"
                        style={
                          bank ? { color: "#848f9f" } : { color: "#0275D8" }
                        }>
                        KYC by bank
                      </Typography>
                      <Typography className="sub-title">
                        {" "}
                        Please upload all required documents
                      </Typography>
                    </Box>
                    {is_manual_kyc_enabled === true ||
                    is_manual_kyc_enabled === "true" ? (
                      <>
                        <AntSwitch
                          className="bank-switch-btn"
                          checked={bank}
                          inputProps={{ "aria-label": "controlled" }}
                          onClick={toggle}
                        />
                        <Box>
                          <Typography
                            className="main-title"
                            style={
                              !bank
                                ? { color: "#848f9f" }
                                : { color: "#0275D8" }
                            }>
                            Manual KYC
                          </Typography>
                          <Typography className="sub-title">
                            Please upload all required documents
                          </Typography>
                        </Box>
                      </>
                    ) : null}
                  </Stack>
                  {bank && <KYCNow activity1={activity1} />}

                  {!bank && <BankKYC activity={activity} />}
                </>
              )}
            </Box>
          </Box>
        </>
      )}
    </ThemeContainer>
  );
}
