import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Box,
  Typography,
  OutlinedInput,
  MenuItem,
  FormControl,
  Select,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Snackbar,
  SnackbarContent,
} from "@material-ui/core";
import CustomInvoice from "views/Components/CustomInvoice";
import TableComponent from "views/Components/tableComponent/tablecomponent";
import ThemeContainer from "views/Components/sideBar/ThemeContainer";
import { isMobile } from "react-device-detect";
import { getAccessToken } from "../../../../utilities/tokenUtil";
import TextField from "@mui/material/TextField";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { Link, useHistory } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
//import DatePicker from '@mui/lab/DatePicker';
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
//import MobileDatePicker from '@mui/lab/MobileDatePicker';

var axios = require("axios");

export default function Invoice(props) {
  const history = useHistory();

  const trader_type = localStorage.getItem("trader_type");
  const userId = localStorage.getItem("userId");
  const userName = localStorage.getItem("userName");
  const kyc_status = localStorage.getItem("kyc_status");

  const [addInvoices, setAddInvoices] = useState(true);
  const [startDate, setstartdate] = useState("");
  const [endDate, setendDate] = useState("");
  const [buyerName, setbuyerName] = useState("");
  const [invoiceNoSearch, setinvoiceNoSearch] = useState("");
  const [searchStatus, setsearchStatus] = useState("");
  const [InvoiceDocuments, setInvoiceDocuments] = useState([]);
  const [invoiceList, setinvoiceList] = useState([]);
  const [file, setfile] = useState();
  const [serverImage, setserverImage] = useState("");
  const [invoiceId, setinvoiceId] = useState(0);
  const [buyerListing, setbuyerListing] = useState([]);
  const [buyerId, setbuyerId] = useState("");
  const [docId, setdocId] = useState("");
  const [serverDocId, setserverDocId] = useState("");
  const [editeddocId, setediteddocId] = useState("");
  const [lenderId, setlenderId] = useState("");
  const [buyerLocationId, setbuyerLocationId] = useState("");
  const [purchaseOrderNO, setpurchaseOrderNO] = useState("");
  const [purchaseOrderDate, setpurchaseOrderDate] = useState("");
  const [invoiceNo, setinvoiceNo] = useState("");
  const [invoiceammount, setinvoiceammount] = useState("");
  const [invoiceDate, setinvoiceDate] = useState("");
  const [dueDate, setdueDate] = useState("");
  const [creditDays, setcreditDays] = useState("");
  const [amountRequired, setamountRequired] = useState("");
  const [logistic, setlogistic] = useState("");
  const [locationId, setlocationId] = useState("");
  const [deliveryChallan, setdeliveryChallan] = useState("");
  const [deliveryChallanDate, setdeliveryChallanDate] = useState("");
  const [goodReceivingNote, setgoodReceivingNote] = useState("");
  const [grnDate, setgrnDate] = useState("");
  const [grnNo, setgrnNo] = useState("");
  const [receivedBy, setreceivedBy] = useState("");
  const [remark, setremark] = useState("");
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState({});
  const [lenderName, setLenderName] = useState("");
  const [currency_symbol, setCurrencySymbol] = useState("");
  const [lenderID, setLenderID] = useState();
  const [open, setOpen] = useState(false);
  const documentRef = useRef([]);
  const [loader, setLoader] = useState(false);
  const [page_no, setPageNo] = useState(1);
  const [page_count, setPageCount] = useState(1);
  const [creditOffersLength, setCreditOffersLength] = useState(false);
  const [paymentMasterData, setPaymentMasterData] = useState("");

  documentRef.current = [];

  if (!userId || userName === null) {
    history.push("/signin");
  }
  if (kyc_status !== "Approved") {
    history.push("/kyc-page");
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const filter = () => {
    const access_token = getAccessToken();
    let traderType = trader_type === "buyer" ? "buyer" : "supplier";
    let search_value;
    if (searchStatus === "all") {
      search_value = "";
    } else {
      search_value = searchStatus;
    }
    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: access_token,
      status: search_value,
      invoice_no: invoiceNoSearch,
      filed_from: startDate,
      filed_to: endDate,
      buyer_name: buyerName,
      user_type: traderType,
      supplier_name: "",
      page_no: parseInt(page_no ? page_no : 1),
    };
    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/invoice/list/search`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setinvoiceList(response.data.invoice_list);
          let total_records = response.data.total_rec;
          let records_per_page = response.data.records_per_page;
          let page_count = Math.ceil(total_records / records_per_page);
          setPageNo(response.data.current_page);
          if (page_count < 1) {
            setPageCount(1);
          } else {
            setPageCount(page_count);
          }
        } else {
          setOpen(true);
          setMessage({ type: "error", content: response.data.message });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const invoiceMaster = () => {
    const access_token = getAccessToken();
    let traderType = trader_type === "buyer" ? "supplier" : "buyer";
    const data = {
      access_token: access_token,
      user_id: parseInt(localStorage.getItem("userId")),
      user_type: traderType,
    };
    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/invoice/master`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        setdocId(response.data.document);
        setbuyerListing(response.data.buyer);
        setlenderId(response.data.lender_id);
        setLenderName(response.data.lender);
        setCurrencySymbol(response.data.currency_symbol);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getInvoiceList = (value = 1) => {
    if (value === "clear") {
    } else {
      setLoader(false);
    }
    const access_token = getAccessToken();
    let traderType = trader_type === "buyer" ? "buyer" : "supplier";
    const data = {
      access_token: access_token,
      user_id: parseInt(localStorage.getItem("userId")),
      user_type: traderType,
      page_no: parseInt(value),
    };
    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/invoice/list`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        if (response.data.success == true) {
          setinvoiceList(response.data.invoice_list);

          let total_records = response.data.total_rec;
          let records_per_page = response.data.records_per_page;
          let page_count = Math.ceil(total_records / records_per_page);
          setPageNo(response.data.current_page);
          if (page_count < 1) {
            setPageCount(1);
          } else {
            setPageCount(page_count);
          }
          setLoader(true);
        } else {
          setLoader(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(true);
      });
  };

  const viewInvoice = (item) => {
    history.push("/displayInvoiceList", item);
  };

  const clearSearch = () => {
    setstartdate("");
    setendDate("");
    setbuyerName("");
    setinvoiceNoSearch("");
    setsearchStatus("");
    getInvoiceList("clear");
  };

  const viewInvoiceDetail = (item) => {
    history.push("/ViewInvoiceList", item);
  };
  const addNewInovice = () => {
    if (!creditOffersLength /*|| !lenderId && !lenderName*/) {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "No active offer found, please apply/track credit request.",
      });
      setTimeout(() => {
        history.push("/credit");
      }, 3000);
    } else {
      history.push("/addinvoice");
    }
  };

  const activeCreditOffers = () => {
    const access_token = getAccessToken();
    const data = {
      access_token: access_token,
      user_id: parseInt(localStorage.getItem("userId")),
    };

    axios
      .post(
        `${process.env.REACT_APP_URL}/api/v1/supplier/credit_offer/list`,
        data,
        {
          headers: {
            "Content-Type": "text/plain",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          setLoader(true);
          if (response?.data?.data?.length > 0) {
            setCreditOffersLength(true);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePageClick = (event, value) => {
    getInvoiceList(value);
  };

  const startDateChange = (newValue) => {
    var date = new Date(newValue);
    var finaldate =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    setstartdate(finaldate);
  };
  const endDateChange = (newValue) => {
    var date = new Date(newValue);
    var finaldate =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    setendDate(finaldate);
  };
  function disablePrevDates(startDate) {
    const startSeconds = Date.parse(startDate);
    return (date) => {
      return Date.parse(date) < startSeconds;
    };
  }
  const paymentMaster = () => {
    axios
      .get(`${process.env.REACT_APP_URL}/api/v1/payment/master`, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setPaymentMasterData(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  let paymentStatus = new URLSearchParams(window.location.search).get(
    "payment_status"
  );
  useEffect(() => {
    if (paymentStatus !== null) {
      console.log("paymentStatus", paymentStatus);
      if (paymentStatus === "success") {
        invoiceUpdateStatusAfterPayment();
      } else {
        console.log("UseEffect id called");
        localStorage.removeItem("invoice_id");
        setLoading(false);
        setOpen(true);
        setMessage({
          type: "error",
          content: "Something went wrong. Please try again.",
        });
      }
    }
  }, [paymentStatus]);

  const invoiceUpdateStatusAfterPayment = () => {
    const access_token = getAccessToken();
    let iStatus;
    let invoice_id = localStorage.getItem("invoice_id");
    if (invoice_id) {
      if (trader_type === "supplier") {
        iStatus = "waiting_for_buyer_approval";
      } else if (trader_type === "buyer") {
        iStatus = "waiting_for_lender_approval";
      } else {
        iStatus = "";
      }

      const data = {
        user_id: parseInt(localStorage.getItem("userId")),
        access_token: access_token,
        invoice_id: parseInt(invoice_id),
        status: iStatus,
        comment: "",
      };

      axios
        .post(
          `${process.env.REACT_APP_URL}/api/v1/invoice/update/status`,
          data,
          {
            headers: {
              "Content-Type": "text/plain",
            },
          }
        )
        .then((response) => {
          if (response.data.message) {
            localStorage.removeItem("invoice_id");
            setLoading(false);
            setOpen(true);
            setMessage({ type: "success", content: response.data.message });
            history.push("/invoice");
            invoiceMaster();
            getInvoiceList();
            activeCreditOffers();
            paymentMaster();
            // window.location.reload();
          } else {
            localStorage.removeItem("invoice_id");
            setLoading(false);
            setOpen(true);
            setMessage({ type: "error", content: response.data.message });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log("Function is called");
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "Something went wrong. Please try again.",
      });
    }
  };

  useEffect(() => {
    let page_title = props.pagetitle;
    let pageTitle;
    if (page_title) {
      pageTitle = page_title;
    } else {
      pageTitle = "Invoices";
    }
    document.title = "Interswitch - " + pageTitle;
    window.scrollTo(0, 0);
    invoiceMaster();
    getInvoiceList();
    activeCreditOffers();
    paymentMaster();
    // Include payment script library
    const script = document.createElement("script");
    script.src = process.env.REACT_APP_PAYMENT_INLINE_CHECKOUT_URL;
    script.async = true;
    document.body.appendChild(script);
  }, []);
  return (
    <ThemeContainer type="private" value={1}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}>
        <SnackbarContent
          className={
            message.type === "error"
              ? "snack-error"
              : message.type === "success"
              ? "snack-success"
              : "snack-teal"
          }
          message={message.content}
        />
      </Snackbar>
      {loader !== true ? (
        <Grid container spacing={3} className="bootstrap-loader">
          <Spinner animation="grow" variant="spinner1" />
          <Spinner animation="grow" variant="spinner2" />
          <Spinner animation="grow" variant="spinner3" />
        </Grid>
      ) : (
        <>
          <Box className="scf-box">
            <Box className="scf-subbox">
              <Box>
                <Grid container>
                  <Grid item xs={8} sm={8} md={10} xl={10}>
                    <Typography className="listing-main-title">
                      Manage Invoices{" "}
                    </Typography>
                  </Grid>
                  {trader_type === "supplier" && (
                    <Grid item xs={4} sm={4} md={2} xl={2}>
                      <Button className="upload" onClick={addNewInovice}>
                        Add New Invoice
                      </Button>
                    </Grid>
                  )}
                </Grid>
                <Typography className="company-title2"> Filters</Typography>

                <Grid container spacing={2} className="filters">
                  {/*<Grid item xs={6} sm={12} md={4} xl={4}>
                          <Box>
                              <span className="company-title3">Start Date<span className="required">*</span></span>
                              
                              <TextField
                                  id="start_date"
                                  type="date"
                                  fullWidth
                                  value={startDate}
                                  onChange={(e) => setstartdate(e.target.value)}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                              />

                          </Box>
                        </Grid>*/}
                  <Grid item xs={6} sm={12} md={4} xl={4}>
                    <Box>
                      <span className="company-title3">
                        Start Date<span className="required">*</span>
                      </span>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack spacing={3}>
                          <DesktopDatePicker
                            inputFormat="MM/dd/yyyy"
                            value={startDate}
                            onChange={startDateChange}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={12} md={4} xl={4}>
                    <Box>
                      <span className="company-title3">
                        {" "}
                        End Date<span className="required">*</span>
                      </span>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack spacing={3}>
                          <DesktopDatePicker
                            id="end_date"
                            inputFormat="MM/dd/yyyy"
                            shouldDisableDate={disablePrevDates(startDate)}
                            value={endDate}
                            onChange={endDateChange}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={12} md={4} xl={4}>
                    <Box>
                      <span className="company-title3">
                        {trader_type === "buyer" ? "Supplier" : "Buyer"} Name
                        <span className="required">*</span>
                      </span>
                      <OutlinedInput
                        className="company-title5"
                        fullWidth
                        placeholder="e.g. Name"
                        value={buyerName}
                        onChange={(e) => setbuyerName(e.target.value)}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={6} sm={12} md={4} xl={4}>
                    <Box>
                      <span className="company-title3">
                        {" "}
                        Invoice Number<span className="required">*</span>
                      </span>
                      <OutlinedInput
                        className="company-title5"
                        fullWidth
                        placeholder="e.g. Invoice Number"
                        value={invoiceNoSearch}
                        onChange={(e) => setinvoiceNoSearch(e.target.value)}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={12} md={4} xl={4}>
                    <Box>
                      <span className="company-title3">
                        {" "}
                        Status Type<span className="required">*</span>
                      </span>
                      <FormControl fullWidth>
                        <Select
                          displayEmpty
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Age"
                          value={searchStatus}
                          onChange={(e) => setsearchStatus(e.target.value)}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}>
                          <MenuItem value="" disabled>
                            Select from options
                          </MenuItem>
                          <MenuItem value="all">All</MenuItem>
                          <MenuItem value="draft">Draft</MenuItem>
                          <MenuItem value="approved">Approved</MenuItem>
                          <MenuItem value="funded">Funded</MenuItem>
                          <MenuItem value="rejected">Rejected</MenuItem>
                          <MenuItem value="awaiting_disbursal">
                            Awaiting Disbursal
                          </MenuItem>
                          <MenuItem value="waiting_for_buyer_approval">
                            Awaiting Buyer Approval
                          </MenuItem>
                          <MenuItem value="waiting_for_lender_approval">
                            Awaiting Lender Approval
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4} xl={4}>
                    <span className="company-title3">&nbsp;</span>
                    <Box className="filter-action">
                      <Button className="submit-button" onClick={filter}>
                        Submit
                      </Button>
                      <Button className="cancel-button" onClick={clearSearch}>
                        Clear
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
                <Typography className="list-sub-title">
                  {" "}
                  Invoice List
                </Typography>
              </Box>
              <Box>
                <TableContainer component={Paper}>
                  <Table className="table-top" aria-label="simple table">
                    <TableHead>
                      <TableRow className="table-row">
                        <TableCell align="left" className="table-header">
                          S. No.{" "}
                        </TableCell>
                        <TableCell align="left" className="table-header">
                          Lender Name
                        </TableCell>
                        <TableCell align="left" className="table-header">
                          Invoice#
                        </TableCell>
                        <TableCell align="left" className="table-header">
                          Invoice Date
                        </TableCell>
                        <TableCell align="left" className="table-header">
                          Due Date
                        </TableCell>
                        <TableCell
                          align="left"
                          className="table-header"
                          style={{ width: "20%" }}>
                          {trader_type === "buyer" ? "Supplier" : "Buyer"} Name
                        </TableCell>
                        <TableCell align="left" className="table-header">
                          Inv. Amount ({currency_symbol})
                        </TableCell>
                        <TableCell align="left" className="table-header">
                          Appr. Amount ({currency_symbol})
                        </TableCell>
                        <TableCell align="left" className="table-header">
                          Reference#
                        </TableCell>
                        <TableCell align="left" className="table-header">
                          Logistics
                        </TableCell>
                        <TableCell align="left" className="table-header">
                          Status
                        </TableCell>
                        <TableCell className="table-header">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {invoiceList.length > 0 && invoiceList !== undefined ? (
                        invoiceList.map((row, invoice_key) => (
                          <TableRow className="table-row" key={invoice_key}>
                            <TableCell component="th" scope="row" align="left">
                              {" "}
                              {invoice_key + 1}{" "}
                            </TableCell>
                            <TableCell
                              align="left"
                              onClick={() => viewInvoiceDetail(row)}>
                              {row.lender_name}
                            </TableCell>
                            <TableCell
                              align="left"
                              onClick={() => viewInvoiceDetail(row)}>
                              {row.invoice_no}
                            </TableCell>
                            <TableCell
                              align="left"
                              onClick={() => viewInvoiceDetail(row)}>
                              {row.invoice_date}
                            </TableCell>
                            <TableCell
                              align="left"
                              onClick={() => viewInvoiceDetail(row)}>
                              {row.due_date}
                            </TableCell>
                            <TableCell
                              align="left"
                              onClick={() => viewInvoiceDetail(row)}>
                              {trader_type === "buyer"
                                ? row.supplier_name
                                : row.buyer_name}
                            </TableCell>
                            <TableCell
                              align="left"
                              onClick={() => viewInvoiceDetail(row)}>
                              {row.invoice_ammount.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                              })}
                            </TableCell>
                            <TableCell
                              align="left"
                              onClick={() => viewInvoiceDetail(row)}>
                              {row.approved_amount.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                              })}
                            </TableCell>
                            <TableCell
                              align="left"
                              onClick={() => viewInvoiceDetail(row)}>
                              {row.reference}
                            </TableCell>
                            <TableCell
                              align="left"
                              onClick={() => viewInvoiceDetail(row)}>
                              {row.logistics}
                            </TableCell>
                            <TableCell
                              align="left"
                              onClick={() => viewInvoiceDetail(row)}
                              className={row.status
                                .toLowerCase()
                                .replace(/\s/g, "-")}>
                              {row.status}
                            </TableCell>
                            <TableCell className="table-header1">
                              {
                                <CustomInvoice
                                  value={row}
                                  paymentMasterData={paymentMasterData}
                                />
                              }
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={11} className="nodata">
                            No Data to Display
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>

              {invoiceList.length > 0 && invoiceList !== undefined ? (
                <Grid container spacing={2} className="paginate">
                  <Grid item xs={6} sm={6} md={4} xl={4}>
                    <Box>
                      <Stack spacing={2}>
                        <Pagination
                          count={page_count}
                          page={page_no}
                          variant="outlined"
                          shape="rounded"
                          onChange={handlePageClick}
                        />
                      </Stack>
                    </Box>
                  </Grid>
                </Grid>
              ) : null}
            </Box>
          </Box>
        </>
      )}
    </ThemeContainer>
  );
}
