import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  MenuItem,
  FormControl,
  Select,
  withStyles,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Snackbar,
  SnackbarContent,
  Modal,
  InputLabel,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { getAccessToken } from "../../utilities/tokenUtil";
import ThemeContainer from "views/Components/sideBar/ThemeContainer";
import { useHistory } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import CancelIcon from '@material-ui/icons/HighlightOffOutlined';
import viewSignatory from "views/UpdateCompany/UpdateComponents/authorized/viewSignatory";

var axios = require("axios");

const styles = () => ({

  textFields: {
    background: "#F3F5F6",
    height: "40px",
    marginTop: "1%",
    "& div": {
      "&::before": {
        content: "",
        borderBottom: "none",
      },
      "&::after": {
        borderBottom: "none",
      },
      "&:hover": {
        "&::before": {
          borderBottom: "none !important",
        },
        "&::after": {
          borderBottom: "none !important",
        },
      },
      "& .MuiInputBase-input": {
        padding: "9px 7px",
      },
    },
  },
});
const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'absolute',
    width: 450,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
  },
}));

function getModalStyle() {
  return {
    top: `50%`,
    left: `0`,
    right: `0`,
    margin: `0 auto`,
    transform: `translateY(-75%)`,
  };
};

function DomiciledBanks(props) {

  const userId = localStorage.getItem("userId");
  const userName = localStorage.getItem("userName");
  const kyc_status = localStorage.getItem("kyc_status");

  const history = useHistory();
  const [lenderList, setLenderList] = useState([]);
  const [message, setMessage] = useState({});
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const modelClasses = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [lendersList, setLendersList] = useState("");
  const [lender_id, setLenders] = useState("");

  if (!userId || userName === null) {
    history.push("/signin");
  }
  if (kyc_status !== "Approved") {
    history.push("/kyc-page");
  }
  const allDomiciledBankList = () => {
    const access_token = getAccessToken();
    const data = {
      access_token: access_token,
      user_id: parseInt(localStorage.getItem("userId")),
    };
    axios
      .post(`${process.env.REACT_APP_URL}` + "/api/v1/lender/list", data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        if (response && response.data.success === true) {
          setLenderList(response.data.lender_list);
          setLoader(true);
        } else {
          setLoader(true)
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(true)
      });
  };

  const domiciledBankFunction = () => {
    axios
      .get(`${process.env.REACT_APP_URL}/api/v1/user/master`)
      .then((response) => {
        if (response && response.status) {
          let lenders = response.data.lenders;
          let lenders_options = [];
          if (lenders !== undefined && lenders.length) {
            lenders.forEach(function (data, key) {
              lenders_options.push({
                value: data.name,
                id: data.id,
                label: data.name
              })

            });
          }
          setLendersList(lenders_options);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(true)
      });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const modelOpen = (event) => {
    event.preventDefault();
    setOpenModel(true);
  };
  const modelClose = () => {
    setOpenModel(false);
    setLenders("");
  };

  const onSave = () => {
    const access_token = getAccessToken();
    if (lender_id === "") {
      setOpen(true)
      setMessage({ type: "error", content: "Please select at least one bank." })
      return;
    }
    const data = {
      access_token: access_token,
      user_id: parseInt(localStorage.getItem("userId")),
      lender: parseInt(lender_id),
    };
    setLoader(false);

    axios
      .post(`${process.env.REACT_APP_URL}` + "/api/v1/add/lender", data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        if (response.data.success === true) {
          setOpenModel(false);
          setOpen(true);
          setLoader(true);
          allDomiciledBankList();
          setMessage({ type: "success", content: response.data.message });
        } else {
          setOpen(true);
          setLoader(true);
          setMessage({ type: "error", content: response.data.message });
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false)
      });
  };

  const viewKycDocs = (e) => {
    e.preventDefault();
    history.push('/kyc-docs');
  }
  const viewSignatory = (e) => {
    e.preventDefault();
    history.push('/authorised-signatory');
  }
  useEffect(() => {
    document.title = 'Interswitch - Domiciled Banks';
    allDomiciledBankList();
    domiciledBankFunction();
  }, []);
  const { classes } = props;

  return (
    <ThemeContainer type="private" value={3}>
      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={open} autoHideDuration={3000} onClose={handleClose}
      >
        <SnackbarContent className={message.type === "error" ? "snack-error" : message.type === "success" ? "snack-success" : "snack-teal"} message={message.content} />
      </Snackbar>
      {loader !== true ? (
        <Grid container spacing={3} className="bootstrap-loader">
          <Spinner animation="grow" variant="spinner1" />
          <Spinner animation="grow" variant="spinner2" />
          <Spinner animation="grow" variant="spinner3" />
        </Grid>
      ) : (
        <>
          <Box className="scf-box">
            <Box className="scf-subbox">
              <Box >
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} md={5} xl={5}>
                      <Typography className="listing-main-title">
                        {"Domiciled Banks"}{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} xl={3}>
                      <Button className="upload" onClick={(e) => modelOpen(e)}>
                        Add New Domiciled Bank
                      </Button>
                    </Grid>
                    <Grid item xs={6} sm={6} md={2} xl={2}>
                      <Button className="upload" onClick={(e) => viewKycDocs(e)}>
                        View KYC Docs
                      </Button>
                    </Grid>
                    <Grid item xs={6} sm={6} md={2} xl={2}>
                      <Button className="upload" onClick={(e) => viewSignatory(e)}>
                        View Signatory
                      </Button>
                    </Grid>
                  </Grid>

                  <Typography className="list-sub-title">
                    Domiciled Banks List
                  </Typography>
                </Box>
                <Box className="domiciled-banks-list">
                  <TableContainer component={Paper}>
                    <Table className="table-top" aria-label="simple table" >
                      <TableHead>
                        <TableRow className="table-row" >
                          <TableCell className="table-header" align="left"> S. No.{" "} </TableCell>
                          <TableCell align="left" className="table-header"> Name </TableCell>
                          <TableCell align="left" className="table-header"> Email </TableCell>
                          <TableCell align="left" className="table-header"> Margin Rate(%) </TableCell>
                          <TableCell align="left" className="table-header"> Type </TableCell>
                          <TableCell align="left" className="table-header"> Taxation# </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {lenderList &&
                          lenderList.length > 0 &&
                          lenderList !== undefined ? (
                          lenderList.map((row, index) => (
                            <TableRow className="table-row" key={index}>
                              <TableCell align="left" component="th" scope="row" onClick={() => history.push("/viewlender/" + (row.lender_id))} > {index + 1} </TableCell>
                              <TableCell align="left" onClick={() => history.push("/viewlender/" + (row.lender_id))} > {row.name} </TableCell>
                              <TableCell align="left" onClick={() => history.push("/viewlender/" + (row.lender_id))} > {row.email} </TableCell>
                              <TableCell align="left" onClick={() => history.push("/viewlender/" + (row.lender_id))} > {row.margin_rate} </TableCell>
                              <TableCell align="left" style={{ textTransform: "capitalize" }} onClick={() => history.push("/viewlender/" + (row.lender_id))}> {row.type} </TableCell>
                              <TableCell align="left" onClick={() => history.push("/viewlender/" + (row.lender_id))} > {row.tax_number} </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={6} className="nodata">
                              No Data to Display
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>

              </Box>
            </Box>
          </Box>
          <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description"
            open={openModel}
          >
            <div style={modalStyle} className={modelClasses.paper}>
              <CancelIcon style={{ color: "#848f9f", position: "absolute", right: "0", top: "0", cursor: "pointer" }} onClick={modelClose} />
              <h4 className="modal-title" style={{ paddingBottom: "5%", textAlign: "center" }}>Add Domiciled Bank</h4>

              <Grid item xs={12} sm={12} md={12} xl={12} className="modal-image">
                <Box style={{ paddingBottom: "5%", textAlign: "left" }}>
                  <FormControl fullWidth className="select-placeholder">
                    <InputLabel id="lenders-simple-select-label">Please Select Bank</InputLabel>
                    <Select
                      displayEmpty
                      labelId="lenders-simple-select-label"
                      id="domiciled-banks"
                      label="Please Select Bank"
                      onChange={(e) => {
                        setLenders(e.target.value);
                      }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      <MenuItem disabled value={""}> Select from options</MenuItem>

                      {lendersList !== "" ? (
                        lendersList.map((item, index) => (
                          <MenuItem value={item.id} key="index">{item.value}</MenuItem>
                        ))
                      ) : (
                        <></>
                      )}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid container>
                <Grid item xs={6} sm={6} md={2} xl={2}>
                  <Button className="submit-button" onClick={onSave}>Submit</Button>
                </Grid>
              </Grid>
            </div>
          </Modal>
        </>
      )}
    </ThemeContainer>
  );
}

DomiciledBanks.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(DomiciledBanks);
