import React, { useState,useEffect } from 'react';
import { Link, useHistory } from "react-router-dom"
import { 
    Grid, 
    OutlinedInput, 
    MenuItem, 
    Box, 
    withStyles, 
    Typography, 
    FormControl, 
    Select, 
    Button, 
    Table, 
    TableBody, 
    TableContainer, 
    TableCell, 
    TableHead, 
    TableRow, 
    Paper,
    IconButton,
    Snackbar,
    SnackbarContent,
    Modal,
    makeStyles 
} from "@material-ui/core";
import PropTypes from 'prop-types';
import Custom from "views/Components/Custom";
import ThemeContainer from 'views/Components/sideBar/ThemeContainer';
import TableComponent from 'views/Components/tableComponent/tablecomponent';
import { isMobile } from 'react-device-detect';
import { getAccessToken } from "../../utilities/tokenUtil";
import { setAccessToken } from "utilities/tokenUtil";
import image11 from "assets/img/write(2).png";
import image2 from "assets/img/right(1).png";
import image3 from "assets/img/delete(3).png";
import image4 from "assets/img/eyes.png";
import reject from "assets/img/reject1.png";
import TextField from "@mui/material/TextField";
import View from "assets/img/view.svg"
import Updatestatus from "assets/img/updatestatus.svg"
import Addedit from "assets/img/add-edit.svg"
import Delete from "assets/img/delete.svg"
import { Spinner } from "react-bootstrap";
import Stack from '@mui/material/Stack';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@mui/material/Tooltip';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';

var axios = require("axios");

const styles = theme => ({
    textFields: {
        background: "#F3F5F6",
        height: "40px",
        marginTop: "1%",
        "& div": {
            "&::before": {
                content: "",
                borderBottom: "none",

            },
            "&::after": {
                borderBottom: "none"
            },
            "&:hover": {
                "&::before": {
                    borderBottom: "none !important"
                },
                "&::after": {
                    borderBottom: "none !important"
                },
            },
            "& .MuiInputBase-input": {
                padding: "9px 7px"
            }
        },

    },

})

function getModalStyle() {
    return {
        top: `50%`,
        left: `0`,
        right: `0`,
        margin: `0 auto`,
        transform: `translateY(-75%)`,
    };
}

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'absolute',
        width: 450,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

function CreditRequest(props) {
    const user_role         = localStorage.getItem('user_role');
    const userId            = localStorage.getItem("userId");
    const userName          = localStorage.getItem("userName");
    const kyc_status        = localStorage.getItem("kyc_status");
    const trader_type       = localStorage.getItem("trader_type");
    const currency_symbol   = localStorage.getItem("currency_symbol");

    const history = useHistory();
    const modelClasses = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    const [loader, setLoader]           = useState(false);
    const [error, setError]             = useState(false);
    const [start_date, setStartDate]    = useState("")
    const [end_date, setEndDate]        = useState("")
    const [lender, setLender]           = useState("")
    const [percentage, setPercentage]   = useState("")
    const [status, setStatus]           = useState("")
    const [creditList, setCreditList]   = useState([]);
    const [lenderName, setLenderName]   = useState("");
    const [lenderList, setLenderList]   = useState([]);
    const [loading, setLoading]         = useState(true);
    const [message, setMessage]         = useState({});
    const [open, setOpen]               = useState(false);
    const [openModel, setOpenModel]     = useState(false);
    const [reject_reason, setRejectReason] = useState("");
    const [credit_request_id, setCreditRequestID] = useState("");
    const [credit_assessment_status, setCreditAssessmentStatus] = useState("")

    if(!userId || userName === null){
        history.push('/signin');
    }
    if(kyc_status !== "Approved"){
        history.push('/kyc-page');
    }
    if(trader_type !== "supplier"){
        history.push('/invoice');
    }

    const creditMaster=()=>{
        const access_token = getAccessToken();       
    
        const data = {
            "access_token":access_token, 
            "user_id": parseInt(localStorage.getItem("userId"))
          };

        axios
          .post(`${process.env.REACT_APP_URL}/api/v1/credit/master`,data, {
            headers: {
              "Content-Type": "text/plain",
            },
          })
          .then((response) => {
            if (response && response.data.success === true) {  
                setLenderList(response.data.lender) 
                setCreditAssessmentStatus(response.data.credit_assessment_pending);    
                setLoader(true) 
            }    else{
                setLoader(true)
            }
          })
          .catch((error) => {
            console.log(error);
          });
    }
    const allCreditList=()=>{
        const access_token = getAccessToken();       
        //setLoader(false)
        const data = {
            "access_token":access_token, 
            "user_id": parseInt(localStorage.getItem("userId"))
          };

        axios
          .post(`${process.env.REACT_APP_URL}/api/v1/credit/request/list`,data, {
            headers: {
              "Content-Type": "text/plain",
            },
          })
          .then((response) => {
            setCreditList(response.data.credit_list)  
            setLoader(true)        
          })
          .catch((error) => {
            console.log(error);
          });
    }
    const filter=()=>{
        let search_value;
        if(status === "all"){
          search_value = "";
        }else{
          search_value = status;
        }
        setCreditList("")
        const access_token = getAccessToken();    
        const data = {
            "access_token":access_token, 
            "user_id": parseInt(localStorage.getItem("userId")), 
            "lender_name":lenderName,
            "start_date": start_date,
            "end_date": end_date,
            "status": search_value
        };

        axios
          .post(`${process.env.REACT_APP_URL}/api/v1/credit/request/search`,data, {
            headers: {
              "Content-Type": "text/plain",
            },
          })
          .then((response) => {           
            setCreditList(response.data.credit_list)    
          })
          .catch((error) => {
            console.log(error);
          });
    }
    const clearSearch=()=>{
        setStatus("");
        setLenderName("")
        //setCreditList("")
        setStartDate("")
        //setEndDate("")
        allCreditList();
    }
    // Get Lender's name
    let lendersName = (arrayData)=> {
        if (arrayData.length === 0) {
            return '';
        }
        let commaSepratedElement = [];
        arrayData.map((item)=>{
            commaSepratedElement.push(item.name);
        });
        return commaSepratedElement.join(', ');
    }
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };
    // Update credit status from draft to awaiting_approval
    const updateStatus = (event, credit_request_id, status) => {
        event.preventDefault();        
        const access_token = getAccessToken();
        let setStatus; 
        if(openModel && reject_reason === "" ){            
            setError(true);
        }else{
            setError(false);
            if(status === "Pending" && reject_reason === ""){
                setStatus = "draft";
            }else if(reject_reason !== ""){
                setStatus = "rejected";
            }else{
                return false;
            }
            setLoader(false)
            const data = {
                user_id: parseInt(localStorage.getItem("userId")),
                access_token: access_token,
                credit_request_id: parseInt(credit_request_id),
                status: setStatus,
                reject_reason: reject_reason ? reject_reason : ""
            };
            
            axios
            .post(`${process.env.REACT_APP_URL}/api/v1/invoice/credit/status`, data, {
                headers: {
                    "Content-Type": "text/plain",
                },
            })
            .then((response) => {
                if(response.data.message){
                    setLoading(false);
                    setOpen(true);
                    setMessage({ type: "success", content: response.data.message });
                    allCreditList();
                    setLoader(true)
                }else{
                    setLoading(false);
                    setOpen(true);
                    setMessage({ type: "error", content: response.data.message });
                    allCreditList();
                    setLoader(true)
                }
                setOpenModel(false);
                setRejectReason("")
            })
            .catch((error) => {
                setLoader(true)
                console.log(error);
            });
        }
    };

    const modelOpen = (event, credit_request_id, status) => {
        event.preventDefault();     
        setCreditRequestID(credit_request_id)
        setStatus(status)
        setOpenModel(true);
    };

    const modelClose = () => {
        setOpenModel(false);
        setError(false);
        setRejectReason("")
    };

    const addNewSupplierCredit = () => {
        if(credit_assessment_status === "Pending"){
            setLoading(false);
            setOpen(true);
            setMessage({ type: "error", content: "Please upload the credit assessment document or assessment approval process going on." });
            setTimeout(() => {
              history.push('/trader-assessment')
            }, 3000)
        }else{
            history.push("/add-supplier-credit");
        }
    }

    const startDateChange = (newValue) => {
        var date = new Date(newValue);
        var finaldate = date.getFullYear() + '-' +  (date.getMonth() + 1)  + '-' +  date.getDate();
        setStartDate(finaldate);
    };

    useEffect(() => {
        document.title = 'Interswitch - Supplier Credit Request';
        allCreditList();
        creditMaster();
    }, []);
    const { classes } = props;   //This one

    return (
        <ThemeContainer type="private" value={2}>
            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={open} autoHideDuration={3000} onClose={handleClose} >
                <SnackbarContent className={message.type === "error" ? "snack-error" : message.type === "success" ? "snack-success" : "snack-teal" } message={message.content} /> 
            </Snackbar>
            {loader !== true ? (
                <Grid container spacing={3} className="bootstrap-loader">
                  
                    <Spinner animation="grow" variant="spinner1" />
                    <Spinner animation="grow" variant="spinner2" />
                    <Spinner animation="grow" variant="spinner3" />
                  
                </Grid>
              ) : (
              <>
            <Box className="scf-box">
                <Box className="scf-subbox">                    
                    <Box>
                        <Grid container>
                            <Grid item xs={6} sm={6} md={9} xl={9}>
                                <Typography className="listing-main-title">Supplier Credit Request</Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={3} xl={3}>
                                <Button className="upload" onClick={addNewSupplierCredit}>Add Supplier Credit Request</Button>
                            </Grid>
                        </Grid>
                        <Typography className="company-title2">Filters</Typography>                           

                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={6} md={4} xl={4}>
                                <Box>
                                    <span className="company-title3">Start Date<span className="required">*</span></span>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                      <Stack spacing={3}>
                                        <DesktopDatePicker
                                            inputFormat="MM/dd/yyyy"
                                            value={start_date}
                                            onChange={startDateChange}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                      </Stack>
                                    </LocalizationProvider>
                                </Box>
                            </Grid>
                            <Grid item xs={6} sm={6} md={4} xl={4}>
                                <Box>
                                    <span className="company-title3">Lender Name<span className="required">*</span></span>
                                    <FormControl fullWidth>
                                        <OutlinedInput className="company-title5"
                                            fullWidth
                                            value={lenderName}
                                            placeholder="Lender name"
                                            onChange={(e)=>setLenderName(e.target.value)}
                                        />
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={6} sm={6} md={4} xl={4}>
                                <Box>
                                    <span className="company-title3">Select Status<span className="required">*</span></span>
                                    <FormControl fullWidth>
                                        <Select
                                            displayEmpty
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Age"
                                            value={status}
                                            onChange={(e) => setStatus(e.target.value)}
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "left",
                                                },
                                                transformOrigin: {
                                                    vertical: "top",
                                                    horizontal: "left",
                                                },
                                                getContentAnchorEl: null,
                                            }}
                                        >

                                        <MenuItem value="" disabled>Select from options</MenuItem>
                                        <MenuItem value="all">All</MenuItem>
                                        <MenuItem value="pending">Pending</MenuItem>
                                        <MenuItem value="approved">Approved</MenuItem>
                                        <MenuItem value="rejected">Rejected</MenuItem>
                                        <MenuItem value="waiting_for_approval ">Awaiting Approval</MenuItem>
                                        </Select>
                                        
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={6} sm={6} md={4} xl={4}></Grid>
                            <Grid item xs={6} sm={6} md={4} xl={4}></Grid>
                            <Grid item xs={6} sm={6} md={4} xl={4}>
                                <span className="company-title3">&nbsp;</span>
                                <Box className="filter-action">
                                    <Button className="submit-button" onClick={filter}>Submit</Button>
                                    <Button className="cancel-button" onClick={clearSearch}>Clear</Button>
                                </Box>
                            </Grid>
                        </Grid>
                        <Typography className="list-sub-title">Supplier Credit Request List</Typography>
                    </Box>
                    <Box>
                        <TableContainer component={Paper}>
                            <Table className="table-top" aria-label="simple table">
                                
                                <TableRow className="table-row">
                                    <TableCell className="table-header" align="left">S. No. </TableCell>
                                    <TableCell align="left" className="table-header">Name</TableCell>
                                    <TableCell align="left" className="table-header">Lender Name</TableCell>
                                    {/*<TableCell align="left" className="table-header">Limit Required ({currency_symbol})</TableCell>*/}
                                    <TableCell align="left" className="table-header">Expected Interest Rate (%)</TableCell>
                                    <TableCell align="left" className="table-header">Start Date</TableCell>
                                    <TableCell align="left" className="table-header">Status</TableCell>
                                    <TableCell align="left" className="table-header">Action</TableCell>
                                </TableRow>
                                
                                <TableBody >
                                    
                                    {creditList.length > 0 ? (
                                        <>
                                        {creditList ? creditList.map((row, index) => 
                                            <TableRow className="table-row" key={row.name} >
                                                <TableCell component="th" scope="row" align="left" onClick={() => history.push("/credit-offer/"+(row.credit_request_id))}>{index+1}</TableCell>
                                                <TableCell align="left" onClick={() => history.push("/credit-offer/"+(row.credit_request_id))}> {row.name}</TableCell>
                                                <TableCell align="left" onClick={() => history.push("/credit-offer/"+(row.credit_request_id))}> {lendersName(row.lender_ids)}</TableCell>
                                                {/*<TableCell align="left" onClick={() => history.push("/credit-offer/"+(row.credit_request_id))}> {row.limit_required.toLocaleString(undefined, {minimumFractionDigits: 2})}</TableCell>*/}
                                                <TableCell align="left" onClick={() => history.push("/credit-offer/"+(row.credit_request_id))}> {row.expected_interest_rate}</TableCell>
                                                <TableCell align="left" onClick={() => history.push("/credit-offer/"+(row.credit_request_id))}> {row.start_date !== "False" ? row.start_date : ""}</TableCell>
                                                <TableCell align="left" onClick={() => history.push("/credit-offer/"+(row.credit_request_id))} className={row.state.toLowerCase().replace(/\s/g, '-')}> 
                                                    {row.state}{" "}
                                                    {row.rejection_reason &&
                                                        <Tooltip title={row.rejection_reason}>
                                                            <InfoIcon />
                                                        </Tooltip>
                                                    }
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Box className="table-action">
                                                        {row.state === "Pending" ? (
                                                        <Link to={`/edit-supplier-credit/${ row.credit_request_id }`}>
                                                            <Tooltip title="Edit" placement="top" arrow>
                                                                <IconButton size={isMobile ? "small" : "medium"}>                                                            
                                                                        <img className="iconstyle" src={Addedit} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Link>
                                                        ):null}
                                                        {user_role === "approver" && row.state === "Pending" ? (
                                                          <>
                                                            <Link onClick={(e) => updateStatus(e, row.credit_request_id, row.state)}>
                                                                <Tooltip title="Credit Request Status" placement="top" arrow>
                                                                    <IconButton size={isMobile ? "small" : "medium"}>
                                                                    <img className="iconstyle iconstyle" src={Updatestatus} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Link>
                                                            <Link onClick={(e) => modelOpen(e, row.credit_request_id, row.state)}>
                                                                <Tooltip title="Reject" placement="top" arrow>
                                                                    <IconButton size={isMobile ? "small" : "medium"}>
                                                                    <i class="far fa-times-circle"></i>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Link>
                                                          </>
                                                        ) : null}
                                                        {user_role === "admin" && row.state === "Pending" ? (
                                                            <>
                                                            <Link onClick={(e) => updateStatus(e, row.credit_request_id, row.state)}>
                                                                <Tooltip title="Credit Request Status" placement="top" arrow>
                                                                    <IconButton size={isMobile ? "small" : "medium"}>
                                                                    <img className="iconstyle" src={Updatestatus} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Link>
                                                            <Link onClick={(e) => modelOpen(e, row.credit_request_id, row.state)}>
                                                                <Tooltip title="Reject" placement="top" arrow>
                                                                    <IconButton size={isMobile ? "small" : "medium"}>
                                                                    <i class="far fa-times-circle"></i>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Link>
                                                            </>
                                                        ) : null}
                                                                            
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        ):null}
                                        </>
                                    ) : (
                                        <TableRow >
                                            <TableCell colSpan={8} className="nodata">
                                                No Data to Display
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                                   
                </Box>
            </Box>

            <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description"
                open={openModel}
                onClose={modelClose}
            >
                <div style={modalStyle} className={modelClasses.paper}>
                    <h4 className="reject-modal-title">Reject Reason</h4>
                    
                    <Grid item xs={12} sm={12} md={12} xl={12}>
                        <Box className="input-box">
                            <FormControl fullWidth className={classes.textFields}>
                                <OutlinedInput className="company-title5"
                                    fullWidth
                                    required
                                    value={reject_reason}
                                    placeholder="Enter Reject Reason"
                                    onChange={(e)=>setRejectReason(e.target.value)}
                                />
                            </FormControl>
                            {error && error === true && (
                                <Grid className="company-title3" style={{ color: "red" }}>
                                    This field is required.
                                </Grid>
                            )}
                        </Box>
                    </Grid>
                    <Grid container spacing={3} className="button-grid" >
                      <Grid item xs={6} sm={4} md={3} xl={3}>
                          <Button className="save reason" onClick={(e) => updateStatus(e, credit_request_id, status)} >Submit</Button>
                      </Grid>
                      <Grid item xs={6} sm={4} md={3} xl={3}>
                          <Button className="cancel reason" onClick={modelClose}>Cancel</Button>
                      </Grid>
                    </Grid>
                    
                </div>
            </Modal>
            </>
          )}
        </ThemeContainer>
    );
}

CreditRequest.propTypes = {
    classes: PropTypes.object
}

export default withStyles(styles)(CreditRequest)