import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom"
import {
  Grid,
  Box,
  Typography,
  OutlinedInput,
  withStyles,
  MenuItem,
  FormControl,
  Select,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Toolbar,
  Snackbar,
  SnackbarContent,
  Container
} from "@material-ui/core";
import preview from "assets/img/previewButton.svg";
import Custom from "views/Components/Custom";
import ThemeContainer from "views/Components/sideBar/ThemeContainer";
import { isMobile } from "react-device-detect";
import { PropTypes } from "prop-types";
import TableComponent from "views/Components/tableComponent/tablecomponent";
import { getAccessToken } from "../../utilities/tokenUtil";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import image from "../../assets/img/profile.svg";
import { Row, Col, Spinner } from "react-bootstrap";
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@mui/material/Tooltip';

var axios = require("axios");

const styles = (theme) => ({
  textFields: {
    background: "#F3F5F6",
    height: "40px",
    marginTop: "1%",
    "& div": {
      "&::before": {
        content: "",
        borderBottom: "none",
      },
      "&::after": {
        borderBottom: "none",
      },
      "&:hover": {
        "&::before": {
          borderBottom: "none !important",
        },
        "&::after": {
          borderBottom: "none !important",
        },
      },
      "& .MuiInputBase-input": {
        padding: "9px 7px",
      },
    },
  },
});

function CreditOfferDetail(props) {

  const userId            = localStorage.getItem("userId");
  const userName          = localStorage.getItem("userName");
  const kyc_status        = localStorage.getItem("kyc_status");
  const trader_type       = localStorage.getItem("trader_type");
  const currency          = localStorage.getItem("currency_symbol");

    const { classes } = props;
    const history = useHistory()

    const [discount_type, setDiscountType]          = useState("");
    const [email, setEmail]                         = useState("");
    const [expected_interest_rate, setExpectedInterestRate]     = useState("");
    const [facility_tenor, setFacilityTenor]        = useState("");
    const [interest_percentage, setInterestPercentage]     = useState("");
    const [lender_name, setLenderName]              = useState("");
    const [limit, setLimit]                         = useState("");
    const [limit_assessment, setLimitAssessment]    = useState("");
    const [limit_required, setLimitRequired]        = useState("");
    const [margin_rate, setMarginRate]              = useState("");
    const [penal_grace_period, setPenalGracePeriod] = useState("");
    const [penal_interest, setPenalInterest]        = useState("");
    const [recommended_limit, setRecommendedLimit]  = useState("");
    const [start_date, setStartDate]                = useState("");
    const [end_date, setEndDate]                    = useState("");
    const [term, setTerm]                           = useState("");
    const [rejection_reason, setRejectionReason]    = useState("");
    const [currency_symbol, setCurrencySymbol]      = useState("");
    const [processing_fee, setProcessingFee]        = useState("");
    const [status,setStatus]                        = useState([]);
    const [loader, setLoader]                       = useState(false);

    if(!userId || userName === null){
        history.push('/signin');
    }
    if(kyc_status !== "Approved"){
        history.push('/kyc-page');
    }
    if(trader_type !== "supplier"){
        history.push('/invoice');
    }
    // Get all credit list
    const allCreditList=()=>{
        const access_token = getAccessToken();       
        let supplier_credit_lender_id  = props.match.params.supplier_credit_lender_id;
        const data = {
            "access_token":access_token, 
            "user_id": parseInt(localStorage.getItem("userId")),
            "credit_request_id": parseInt(props.match.params.credit_request_id)
          };
          setLoader(false)
        axios
          .post(`${process.env.REACT_APP_URL}/api/v1/credit/offer/list`,data, {
            headers: {
              "Content-Type": "text/plain",
            },
          })
          .then((response) => {
           
            if(response){
                
                const credit_supplier_data  = response.data.credit_offer_list.find(item => item.supplier_credit_lender_id == supplier_credit_lender_id);
                
                setDiscountType(credit_supplier_data.discount_type);
                setEmail(credit_supplier_data.email);
                setExpectedInterestRate(credit_supplier_data.expected_interest_rate);
                setFacilityTenor(credit_supplier_data.facility_tenor);
                setInterestPercentage(credit_supplier_data.interest_percentage);
                setLenderName(credit_supplier_data.lender_name);
                setLimit(credit_supplier_data.limit);
                setLimitAssessment(credit_supplier_data.limit_assessment);
                setLimitRequired(credit_supplier_data.limit_required);
                setMarginRate(credit_supplier_data.margin_rate);
                setPenalGracePeriod(credit_supplier_data.penal_grace_period);
                setPenalInterest(credit_supplier_data.penal_interest);
                setRecommendedLimit(credit_supplier_data.recommended_limit);
                setStartDate(credit_supplier_data.start_date);
                setEndDate(credit_supplier_data.end_date);
                setStatus(credit_supplier_data.status);
                setTerm(credit_supplier_data.term);
                setRejectionReason(credit_supplier_data.rejection_reason);
                setCurrencySymbol(credit_supplier_data.currency_symbol);
                setProcessingFee(credit_supplier_data.processing_fee);
                setLoader(true)
            }else{
              setLoader(true)
            }       
          })
          .catch((error) => {
            console.log(error);
          });
    }

    useEffect(() => {
        document.title = 'Interswitch - Credit Offer Detail';
        window.scrollTo(0, 0);
        allCreditList();
    }, []);

  return (
    <ThemeContainer type="private" value={1}>
        {loader !== true ? (
            <Grid container spacing={3} className="bootstrap-loader">
              
                <Spinner animation="grow" variant="spinner1" />
                <Spinner animation="grow" variant="spinner2" />
                <Spinner animation="grow" variant="spinner3" />
              
            </Grid>
          ) : (
          <>
          <Box className="scf-box">
            <Container className="credit-offer-detail">
              <Row className="">
                <Col className="cp-heading5" md={11} xs={8}>Credit Offer Detail</Col>
                <Col className="heading" md={1} xs={4}>                    
                  <Button className="back-button" onClick={() => history.push(`/credit-offer/${props.match.params.credit_request_id}`)}><i className="fas fa-long-arrow-alt-left" > {" "}Back </i></Button>
                </Col>
              </Row>
              <div>
                <Row className="mb-4 mt-3">
                  <Col>
                    <span className="ao-header-box">
                      {lender_name}
                    </span>
                  </Col>
                </Row>
                <Row className="ao-box ">
                  
                  <Row className="mb-4">
                    <Col md={3} className={isMobile ? "mb-4" : ""}>
                      <Row className="cp-sub6">Terms</Row>
                      <Row className="cp-sub7">
                        {term ? term : "0"} Days
                      </Row>
                    </Col>
                    <Col md={3} className={isMobile ? "mb-4" : ""}>
                      {" "}
                      <Row className="cp-sub6">Interest Percentage</Row>
                      <Row className="cp-sub7">
                        {interest_percentage ? interest_percentage : "0"}% p.a
                      </Row>
                    </Col>
                    <Col md={3} className={isMobile ? "mb-4" : ""}>
                      <Row className="cp-sub6">Margin Rate</Row>
                      <Row className="cp-sub7">
                        {margin_rate ? margin_rate : "0"}% p.a
                      </Row>
                    </Col>
                    <Col md={3} className={isMobile ? "mb-4" : ""}>
                      {" "}
                      <Row className="cp-sub6">Discount Type</Row>
                      <Row className="cp-sub7">
                        {discount_type ? discount_type : ""}
                      </Row>
                    </Col>
                  </Row>
                  
                  <Row className="mb-4">
                    <Col md={3} className={isMobile ? "mb-4" : ""}>
                      {" "}
                      <Row className="cp-sub6">Penal Grace Period</Row>
                      <Row className="cp-sub7">
                        {penal_grace_period ? penal_grace_period : "0"}{" "}
                        Day
                      </Row>
                    </Col>
                    <Col md={3} className={isMobile ? "mb-4" : ""}>
                      <Row className="cp-sub6">
                        Penal Interest Per Annum
                      </Row>
                      <Row className="cp-sub7">
                        {penal_interest ? penal_interest : "0"}% p.a
                      </Row>
                    </Col>
                    <Col md={3} className={isMobile ? "mb-4" : ""}>
                      {" "}
                      <Row className="cp-sub6">Processing Fees</Row>
                      <Row className="cp-sub7">
                        {processing_fee &&
                          <>
                          {currency_symbol ? currency_symbol : currency}{" "}
                          {processing_fee ? processing_fee : ""}
                          </>
                        }
                      </Row>
                    </Col>
                    <Col md={3} className={isMobile ? "mb-4" : ""}>
                      {" "}
                      <Row className="cp-sub6">Expected Interest Rate</Row>
                      <Row className="cp-sub7">
                        {expected_interest_rate ? expected_interest_rate : "0"}% p.a
                      </Row>
                    </Col>
                  </Row>                
                  
                  <Row className="">
                    <Col md={3} className={isMobile ? "mb-4" : ""}>
                      {" "}
                      <Row className="cp-sub6">Facility Tenure</Row>
                      <Row className="cp-sub7">
                        {facility_tenor ? facility_tenor : "0"}
                      </Row>
                    </Col>
                    <Col md={3} className={isMobile ? "mb-4" : ""}>
                      <Row className="cp-sub6">Lender Email</Row>
                      <Row className="cp-sub7">
                        {email ? email : ""}
                      </Row>
                    </Col>
                    <Col md={3} className={isMobile ? "mb-4" : ""}>
                      {" "}
                      <Row className="cp-sub6">Limit Assessment</Row>
                      <Row className="cp-sub7">
                        {currency_symbol ? currency_symbol : currency}{" "}
                        {limit_assessment ? limit_assessment.toLocaleString(undefined, {minimumFractionDigits: 2}) : "0"}
                      </Row>
                    </Col>
                    <Col md={3} className={isMobile ? "mb-4" : ""}>
                      <Row className="cp-sub6">Recommended Limit</Row>
                      <Row className="cp-sub7">
                        {currency_symbol ? currency_symbol : currency}{" "}
                        {recommended_limit ? recommended_limit.toLocaleString(undefined, {minimumFractionDigits: 2}) : "0"}
                      </Row>
                    </Col>
                  </Row>                
                </Row>
                <Grid container spacing={3} >
                  <Grid item xs={6} sm={4} md={2} xl={2}>
                    {status === "Approved" &&
                      <Button className="approved">{status}</Button>
                    }
                    {status === "Rejected" &&
                      <Tooltip title={rejection_reason} placement="top" arrow>
                        <Button className="rejected">{status}</Button>
                      </Tooltip>
                    }
                  </Grid>
                </Grid>
              </div>
                
            </Container>
          </Box>
          </>
        )}
    </ThemeContainer>
  );
}

CreditOfferDetail.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(CreditOfferDetail);
