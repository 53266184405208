import React, { useEffect, useState } from "react";
import axios from "axios";
import { getAccessToken } from "utilities/tokenUtil";
import { Row, Col, Spinner } from "react-bootstrap";
import { Container } from "@material-ui/core";
import ThemeContainer from "views/Components/sideBar/ThemeContainer";
import { useHistory, Link } from "react-router-dom";
import { Box, Typography, Grid, Button, Snackbar, SnackbarContent, } from "@material-ui/core";
import Noactiveoffer from "../../assets/img/noactiveoffer.png"
import { isMobile } from "react-device-detect";

const CreditOfferDetail = (props) => {
  const userId              = localStorage.getItem("userId");
  const userName            = localStorage.getItem("userName");

  const [data, setData]     = useState();
  const [error, setError]   = useState(false);
  const [loader, setLoader] = useState(false);
  const api_url             = process.env.REACT_APP_URL;
  const history             = useHistory();
  const trader_type         = localStorage.getItem("trader_type");
  const currency            = localStorage.getItem("currency_symbol");

  if (!userId && !userName) {
    history.push("/signin");
  }  
  if(trader_type !== "supplier"){
      history.push('/invoice');
  }
  const getData = () => {
    let body = {
      user_id: localStorage.getItem("userId"),
      access_token: getAccessToken(),
    };
    setLoader(false)
    axios
      .post(api_url + "/api/v1/supplier/credit_offer/list/"+props.match.params.id, body, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((res) => {
        if (res.data.success) {
          setError(false);
          setData(res.data.data);
          setLoader(true)
        } else {
          setError(true);
          setData(res.data.message);
          setLoader(true)
        }
      });
  };
  useEffect(() => {
    document.title = 'Interswitch - Active Offer';
    getData();
  }, []);
  return (
    <>
      {loader !== true ? (
        <Grid container spacing={3} className="bootstrap-loader">
          
            <Spinner animation="grow" variant="spinner1" />
            <Spinner animation="grow" variant="spinner2" />
            <Spinner animation="grow" variant="spinner3" />
          
        </Grid>
      ) : (
      <>
      {error == false ? (
        <>
          <ThemeContainer type="private" value={5}>
            <Box className="scf-box">
              <Container className="active-offer credit-new">
                <Row>
                  <Col className="cp-heading5">Credit Offer Detail</Col>     
                <Col className="heading" md={1} xs={4}>                    
                  <Button className="back-button" onClick={() => history.push(`/creditoffers`)}><i className="fas fa-long-arrow-alt-left" > {" "}Back </i></Button>
                </Col>           
                </Row>
                {/*<Row>
                  <Col className="cp-sub6 my-4">Lender</Col>
                </Row>*/}
                {data &&
                  data.length > 0 &&
                  data.map((info, index) => (
                    <div key={index}>
                      <Row className="mb-4 mt-3">
                        <Col>
                          {/*<span className="cp-sub6">Lender - </span>*/}
                          <span className="ao-header-box">
                          {info.lender_name}
                          </span>
                        </Col>
                      </Row>
                      <Row className="ao-box ">
                        
                        <Row className="mb-4">
                          <Col md={3} className={isMobile ? "mb-4" : ""}>
                            <Row className="cp-sub6">Terms</Row>
                            <Row className="cp-sub7">
                              {info.term ? info.term : "0"} Days
                            </Row>
                          </Col>
                          <Col md={3} className={isMobile ? "mb-4" : ""}>
                            {" "}
                            <Row className="cp-sub6">Interest Percentage</Row>
                            <Row className="cp-sub7">
                              {info.interest_percentage
                                ? info.interest_percentage
                                : "0"}{" "}
                              % p.a
                            </Row>
                          </Col>
                          <Col md={3} className={isMobile ? "mb-4" : ""}>
                            <Row className="cp-sub6">Margin Rate</Row>
                            <Row className="cp-sub7">
                              {info.margin_rate ? info.margin_rate : "0"} % p.a
                            </Row>
                          </Col>
                          <Col md={3} className={isMobile ? "mb-4" : ""}>
                            {" "}
                            <Row className="cp-sub6">Discount Type</Row>
                            <Row className="cp-sub7">
                              {info.discount_type ? info.discount_type : ""}
                            </Row>
                          </Col>
                        </Row>
                        
                        <Row className="mb-4">
                          <Col md={3} className={isMobile ? "mb-4" : ""}>
                            {" "}
                            <Row className="cp-sub6">Penal Grace Period</Row>
                            <Row className="cp-sub7">
                              {info.penal_grace_period
                                ? info.penal_grace_period
                                : "0"}{" "}
                              Day
                            </Row>
                          </Col>
                          <Col md={3} className={isMobile ? "mb-4" : ""}>
                            <Row className="cp-sub6">
                              Penal Interest Per Annum
                            </Row>
                            <Row className="cp-sub7">
                              {info.penal_interest ? info.penal_interest : "0"} %
                              p.a
                            </Row>
                          </Col>
                          <Col md={3} className={isMobile ? "mb-4" : ""}>
                            {" "}
                            <Row className="cp-sub6">Processing Fees</Row>
                            <Row className="cp-sub7">
                              {info.processing_fee &&
                              <>
                              {info.currency_symbol ? info.currency_symbol : currency}{" "}
                              {info.processing_fee ? info.processing_fee : ""}
                              </>
                              }
                            </Row>
                          </Col>
                          <Col md={3} className={isMobile ? "mb-4" : ""}>
                            {" "}
                            <Row className="cp-sub6">Supplier Name</Row>
                            <Row className="cp-sub7">
                              {info.supplier_name ? info.supplier_name : ""}
                            </Row>
                          </Col>
                        </Row>                      
                        
                        <Row className="mb-4">
                          <Col md={3} className={isMobile ? "mb-4" : ""}>
                            {" "}
                            <Row className="cp-sub6">Expected Interest Rate</Row>
                            <Row className="cp-sub7">
                              {info.expected_interest_rate
                                ? info.expected_interest_rate
                                : "0"}{" "}
                              % p.a
                            </Row>
                          </Col>
                          <Col md={3} className={isMobile ? "mb-4" : ""}>
                            {" "}
                            <Row className="cp-sub6">Facility Tenure</Row>
                            <Row className="cp-sub7">
                              {info.facility_tenor ? info.facility_tenor : "0"}
                            </Row>
                          </Col>
                          <Col md={3} className={isMobile ? "mb-4" : ""}>
                            <Row className="cp-sub6">Lender Email</Row>
                            <Row className="cp-sub7">
                              {info.lender_email ? info.lender_email : ""}
                            </Row>
                          </Col>
                          <Col md={3} className={isMobile ? "mb-4" : ""}>
                            {" "}
                            <Row className="cp-sub6">Limit Assessment</Row>
                            <Row className="cp-sub7">
                              {info.currency_symbol ? info.currency_symbol : currency}{" "}
                              {info.limit_assessment
                                ? info.limit_assessment.toLocaleString(undefined, {minimumFractionDigits: 2})
                                : "0"}
                            </Row>
                          </Col>
                        </Row>
                        
                        <Row className="">
                          <Col md={3} className={isMobile ? "mb-4" : ""}>
                            <Row className="cp-sub6">Recommended Limit</Row>
                            <Row className="cp-sub7">
                              {info.currency_symbol ? info.currency_symbol : currency}{" "}
                              {info.recommended_limit
                                ? info.recommended_limit.toLocaleString(undefined, {minimumFractionDigits: 2})
                                : "0"}
                            </Row>
                          </Col>
                          <Col md={3} className={isMobile ? "mb-4" : ""}>
                            {" "}
                            <Row className="cp-sub6">Start Date</Row>
                            <Row className="cp-sub7">
                              {info.start_date ? info.start_date : ""}{" "}
                            </Row>
                          </Col>
                          <Col md={3} className={isMobile ? "mb-4" : ""}>
                            <Row className="cp-sub6">End Date</Row>
                            <Row className="cp-sub7">
                              {info.end_date ? info.end_date : "0"}
                            </Row>
                          </Col>
                        </Row>                      
                      </Row>
                    </div>
                  ))}
              </Container>
            </Box>
          </ThemeContainer>
        </>
      ) : (
        <ThemeContainer type="private" value={5}>
          <Box className="scf-box kyc-success">
              <Box className="scf-subbox">
                  <Grid container className="scf-container">
                      <Grid item xs={12} className="image-box">
                          <img src={Noactiveoffer} />
                      </Grid>
                      <Grid item xs={12}>
                          <Typography className="heading">You have no active contract or your Credit offer has expired.</Typography>
                      </Grid>
                      <Grid item xs={12}>
                          <Typography className="subheading">You can apply for new credit offer.</Typography>
                      </Grid>

                      <Grid item xs={12} className="image-box">
                          <Link to={"/credit"}>
                              <Button className="button">Request for New Offer</Button>
                          </Link>
                      </Grid>

                  </Grid>

              </Box>
          </Box>
        </ThemeContainer>
      )}
      </>
      )}
    </>
  );
};
export default CreditOfferDetail;
