import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  OutlinedInput,
  MenuItem,
  FormControl,
  Select,
  withStyles,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Snackbar,
  SnackbarContent,
  IconButton,
  Modal,
} from "@material-ui/core";
import preview from "../../../../assets/img/previewButton.svg";
import Custom from "views/Components/Custom";
import PropTypes from "prop-types";
import { isMobile } from "react-device-detect";
import TableComponent from "views/Components/tableComponent/tablecomponent";
import { getAccessToken } from "../../../../utilities/tokenUtil";
import { setAccessToken } from "utilities/tokenUtil";
import { LocalHospitalTwoTone } from "@material-ui/icons";
import image1 from "assets/img/coun1.svg";
import image11 from "assets/img/write(2).png";
import image2 from "assets/img/right(1).png";
import image3 from "assets/img/delete(3).png";
import image4 from "assets/img/eyes.png";
import View from "assets/img/view.svg";
import Updatestatus from "assets/img/updatestatus.svg";
import Addedit from "assets/img/add-edit.svg";
import Delete from "assets/img/delete.svg";
import EditOutlinedIcon from "@material-ui/icons/BorderColor";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ThemeContainer from "views/Components/sideBar/ThemeContainer";
import { Link, useHistory } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from "@material-ui/core/styles";
import SampleFile from "assets/img/sample.csv";
import CancelIcon from '@material-ui/icons/HighlightOffOutlined';

var axios = require("axios");


const styles = () => ({

  textFields: {
    background: "#F3F5F6",
    height: "40px",
    marginTop: "1%",
    "& div": {
      "&::before": {
        content: "",
        borderBottom: "none",
      },
      "&::after": {
        borderBottom: "none",
      },
      "&:hover": {
        "&::before": {
          borderBottom: "none !important",
        },
        "&::after": {
          borderBottom: "none !important",
        },
      },
      "& .MuiInputBase-input": {
        padding: "9px 7px",
      },
    },
  },
});
const useStyles = makeStyles(theme => ({
  modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
  },
  paper: {
      position: 'absolute',
      width: 450,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius: 10,
  },
}));

function getModalStyle() {
  return {
      top: `50%`,
      left: `0`,
      right: `0`,
      margin: `0 auto`,
      transform: `translateY(-75%)`,
  };
};

function Buyer(props) {

  const trader_type = localStorage.getItem("trader_type");
  const userId = localStorage.getItem("userId");
  const userName = localStorage.getItem("userName");
  const kyc_status = localStorage.getItem("kyc_status");
  const user_role = localStorage.getItem("user_role");

  const history = useHistory();
  const [listBuyer, setListBuyer] = useState(true);
  const [addBuyer, setAddBuyer] = useState(false);
  const [editBuyer, setEditBuyer] = useState(false);
  const [buyerList, setBuyerList] = useState([]);
  const [buyerName, setBuyerName] = useState("");
  const [buyerEmail, setBuyerEmail] = useState("");
  const [businessaddress, setBusinessAddress] = useState("");
  const [businesszip, setBusinessZip] = useState("");
  const [businesscountry, setBusinessCountry] = useState("");
  const [businessstate, setBusinessState] = useState("");
  const [businesscity, setBusinessCity] = useState("");
  const [allcountry, setcountry] = useState([]);
  const [allState1, setAllState1] = useState();
  const [allState2, setAllState2] = useState();
  const [fullname, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [taxation, setTaxation] = useState("");
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState({});
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [file, setfile] = useState();
  const [fileView, setfileView] = useState();
  const [fileView1, setfileView1] = useState();
  const [name, setName] = useState("");
  const [registration, setRegistration] = useState("");
  const [entity, setEntity] = useState("");
  const [entityList, setentityList] = useState("");
  const [documents, setDocuments] = useState("");
  const [documenttype, setDocumentType] = useState("");
  const [buyer_supplier_id, setBuyerSupplierId] = useState("");
  const [status, setStatus] = useState("");
  const [document_id, setDocumentId] = useState("");
  const [document_type_id, setDocumentTypeId] = useState("");
  const [trader_documents, setTraderDocuments] = useState("");
  const [buyerTaxNumber, setbuyerTaxNumber] = useState("");
  const [loader, setLoader] = useState(false);
  const [page_no, setPageNo] = useState(1);
  const [page_count, setPageCount] = useState(1);
  const [openModel, setOpenModel] = useState(false);
  const [csvFile, setCsvfile] = useState("");
  const modelClasses = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  if (!userId || userName === null) {
    history.push("/signin");
  }
  if (kyc_status !== "Approved") {
    history.push("/kyc-page");
  }
  const allbuyerList = (value=1) => {
    const access_token = getAccessToken();

    const data = {
      access_token: access_token,
      user_id: parseInt(localStorage.getItem("userId")),
      page_no: parseInt(value)
      //"user_type": localStorage.getItem("trader_type"),
    };

    let api_url;
    if (trader_type === "buyer") {
      api_url = "/api/v1/supplier/list";
    } else {
      api_url = "/api/v1/buyer/list";
    }
    if(value === "clear"){

    }else{
      setLoader(false)
    }
    axios
      .post(`${process.env.REACT_APP_URL}` + api_url, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        if (response && response.data.success === true) {
        setBuyerList(response.data.trader_list);
        let total_records       = response.data.total_rec;
        let records_per_page    = response.data.records_per_page;
        let page_count          = Math.ceil(total_records / records_per_page);
        setPageNo(response.data.current_page)
        setLoader(true)
        if(page_count < 1){
            setPageCount(1)
        }else {
            setPageCount(page_count)
        }
        
      }else{
        setLoader(true)
      }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const filter = () => {
    const access_token = getAccessToken();
    let traderType = trader_type === "buyer" ? "supplier" : "buyer";

    let regEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if(buyerEmail){
      if (!regEmail.test(buyerEmail)) {
        setLoading(false);
        setOpen(true);
        setMessage({ type: "error", content: "Please enter a valid email." });
        return false;
      }
    }
    
    let search_value;
    if(status === "all"){
      search_value = "";
    }else{
      search_value = status;
    }
    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: access_token,
      user_type: traderType,
      trader_name: buyerName,
      email: buyerEmail,
      tax_number: buyerTaxNumber,
      status: search_value,
      page_no: parseInt(page_no ? page_no : 1)
    };

    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/trader/list/search`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        if(response.data.success !== false){
          setBuyerList(response.data.trader_list);
          let total_records       = response.data.total_rec;
          let records_per_page    = response.data.records_per_page;
          let page_count          = Math.ceil(total_records / records_per_page);
          setPageNo(response.data.current_page)
          if(page_count < 1){
              setPageCount(1)
          }else {
              setPageCount(page_count)
          }
        }else{
          setBuyerList("");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const clearSearch = () => {
    setStatus("");
    setBuyerName("");
    setBuyerEmail("");
    setbuyerTaxNumber("");
    allbuyerList("clear");
  };
  const masterFunction = () => {
    const access_token = getAccessToken();

    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: access_token,
      user_type: localStorage.getItem("trader_type"),
    };

    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/trader/master`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        if (response) {
          setentityList(response.data.entity_type);
          setDocuments(response.data.documents);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const traderDetail = () => {
    const access_token = getAccessToken();
    let traderType = trader_type === "buyer" ? "supplier" : "buyer";
    if(buyer_supplier_id){
      const data = {
        user_id: parseInt(localStorage.getItem("userId")),
        access_token: access_token,
        trader_type: traderType,
        id: parseInt(buyer_supplier_id),
      };

      axios
        .post(`${process.env.REACT_APP_URL}/api/v1/trader/detail`, data, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then((response) => {
          if (response) {
            setTraderDocuments(response.data.documents);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const countryFunction = () => {
    const access_token = getAccessToken();
    const userID = localStorage.getItem("userId");

    const data = {
      user_id: localStorage.getItem("userId"),
      access_token: access_token,
    };

    axios
      .post(
        `${process.env.REACT_APP_URL}/api/v1/country/master`,
        JSON.stringify(data)
      )
      .then((response) => {
        if (response && response.data.sucess) {
          setcountry(response.data.country);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const viewBuyerDetail = (item) => {    
    if(trader_type === "buyer"){
      history.push("/view-supplier", item);
    }else{
      history.push("/view-buyer", item);
    }
  };

  // Send invitation to the user
  const sendInvitation = (event, email, contact_user_id) => {
    event.preventDefault();
    const access_token = getAccessToken();
    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      id: parseInt(contact_user_id ? contact_user_id : 0),
      access_token: access_token,
      email: email,
    };
    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/send/trader/invitation`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        if (response.data.success === true) {
          setLoading(false);
          setOpen(true);
          setMessage({ type: "success", content: response.data.message });
        } else {
          setLoading(false);
          setOpen(true);
          setMessage({ type: "error", content: response.data.message });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handlePageClick = (event, value) => {        
      allbuyerList(value);
  };
  const modelOpen = (event) => {
      event.preventDefault();
      setOpenModel(true);
  };
  const modelClose = () => {
      setOpenModel(false);
  };

  const handleCsvUpload = (event) => {
    if (event.target.files && event.target.files[0]) { 
      let fileext = event.target.files[0];
      if (fileext.name.match(/\.(csv)$/)) {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          //setfileView(reader.result);
        });
        setCsvfile(event.target.files[0]);
        reader.readAsDataURL(event.target.files[0]);
      }else{
        setLoading(false);
        setOpen(true);
        setMessage({ type: "error", content: "Only csv file allowed!" });
      }
    }
  };

  const onSubmit = () => {
    const access_token = getAccessToken();
    if (csvFile === "") {
        setOpen(true)
        setMessage({ type: "error", content: "Please upload csv file." })
        return
    }

    let data = new FormData();
    data.append("user_id", parseInt(localStorage.getItem("userId")));
    data.append("access_token", access_token);
    data.append("ufile", csvFile);
    setLoader(false);
    const config = {
      method: "post",
      url: process.env.REACT_APP_URL + "/api/v1/bulk/supplier/upload",
      headers: {
          "Content-Type": "text/plain",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
          if (response.data.success === true) {
            setOpenModel(false);
            setOpen(true);
            setLoader(true);
            setLoading(false);
            allbuyerList();
            setMessage({ type: "success", content: response.data.message });
          }else{
            setOpen(true);
            setLoader(true);
            setLoading(false);
            setMessage({ type: "error", content: response.data.message });
          }
      })
      .catch((error) => {
          console.log(error);
          setLoader(false)
      });
};

  useEffect(() => {
    let type = trader_type === "buyer" ? "Supplier" : "Buyers";
    document.title = 'Interswitch - '+type;
    allbuyerList();
    countryFunction();
    masterFunction();
    traderDetail();
  }, []);
  const { classes } = props;

  return (
    <ThemeContainer type="private" value={3}>
      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={open} autoHideDuration={3000} onClose={handleClose}
      >
        <SnackbarContent className={message.type === "error" ? "snack-error" : message.type === "success" ? "snack-success" : "snack-teal" } message={message.content}/>
      </Snackbar>
      {loader !== true ? (
        <Grid container spacing={3} className="bootstrap-loader">          
            <Spinner animation="grow" variant="spinner1" />
            <Spinner animation="grow" variant="spinner2" />
            <Spinner animation="grow" variant="spinner3" />          
        </Grid>
      ) : (
        <>
      <Box className="scf-box">
        <Box className="scf-subbox">
          <Box >
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={trader_type === "buyer" ? 8 : 10} xl={trader_type === "buyer" ? 8 : 10}>
                  <Typography className="listing-main-title">
                    {trader_type === "buyer" ? "Supplier" : "Buyers"}{" "}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={trader_type === "buyer" ? 2 : 2} xl={trader_type === "buyer" ? 2 : 2}>
                  <Link to={trader_type === "buyer" ? "/addsupplier" : "/addbuyer"}>
                    <Button className="upload">
                      Add New {trader_type === "buyer" ? "Supplier" : "Buyer"}
                    </Button>
                  </Link>
                </Grid>
                {trader_type === "buyer" ? (
                <>
                <Grid item xs={6} sm={6} md={2} xl={2}>
                  <Button className="upload" onClick={(e) => modelOpen(e)}>
                    Upload Bulk Supplier
                  </Button>
                </Grid>
                </>
                ):null}
              </Grid>
              <Typography className="company-title2"> Filters</Typography>

              <Grid container spacing={2}>
                <Grid item xs={6} sm={12} md={4} xl={4}>
                  <Box>
                    <span className="company-title3">
                      Name<span className="required">*</span>
                    </span>
                    <OutlinedInput
                      className="company-title5"
                      fullWidth
                      value={buyerName}
                      placeholder="Enter Name"
                      onChange={(e) => setBuyerName(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6} sm={12} md={4} xl={4}>
                  <Box>
                    <span className="company-title3">
                      Email<span className="required">*</span>
                    </span>
                    <OutlinedInput
                      className="company-title5"
                      fullWidth
                      value={buyerEmail}
                      placeholder="Enter Email"
                      onChange={(e) => setBuyerEmail(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6} sm={12} md={4} xl={4}>
                  <Box>
                    <span className="company-title3">
                      Taxation Number<span className="required">*</span>
                    </span>
                    <OutlinedInput
                      className="company-title5"
                      fullWidth
                      value={buyerTaxNumber}
                      placeholder="Enter Tax Number"
                      onChange={(e) => setbuyerTaxNumber(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6} sm={12} md={4} xl={4}>
                  <Box>
                    <span className="company-title3">
                      Status Type<span className="required">*</span>
                    </span>
                    <FormControl fullWidth>
                      <Select
                        displayEmpty
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Status"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                      >
                        <MenuItem value="" disabled>
                          Select from options
                        </MenuItem>
                        <MenuItem value="all">All</MenuItem>
                        <MenuItem value="approved">Approved</MenuItem>
                        <MenuItem value="draft">Pending</MenuItem>
                        <MenuItem value="kyc_pending">In Review</MenuItem>
                        <MenuItem value="rejected">Rejected</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={12} md={4} xl={4}></Grid>
                <Grid item xs={6} sm={12} md={4} xl={4}>
                  <span className="company-title3">&nbsp;</span>
                  <Box className="filter-action">
                    <Button className="submit-button" onClick={filter}>
                      Submit
                    </Button>
                    <Button className="cancel-button" onClick={clearSearch}>
                      Clear
                    </Button>
                  </Box>
                </Grid>
              </Grid>
              <Typography className="list-sub-title">
                {trader_type === "buyer" ? "Supplier" : "Buyer"} List
              </Typography>
            </Box>
            <Box>
              <TableContainer component={Paper}>
                <Table className="table-top" aria-label="simple table" >
                  <TableHead>
                    <TableRow className="table-row" >
                      <TableCell className="table-header" align="center"> S. No.{" "} </TableCell>
                      <TableCell align="left" className="table-header" style={{ width: "20%" }}> Name </TableCell>
                      <TableCell align="left" className="table-header"> Mobile No </TableCell>
                      <TableCell align="left" className="table-header"> Email </TableCell>
                      <TableCell align="left" className="table-header"> Taxation ID </TableCell>
                      <TableCell align="left" className="table-header" style={{ width: "20%" }} > Address </TableCell>
                      <TableCell align="left" className="table-header"> Status </TableCell>
                      <TableCell align="left" className="table-header"> Action </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {buyerList &&
                    buyerList.length > 0 &&
                    buyerList !== undefined ? (
                      buyerList.map((row, index) => (
                        <TableRow className="table-row" key={index}>
                          <TableCell align="left" component="th" scope="row" onClick={() => viewBuyerDetail(row)} > {index + 1} </TableCell>
                          <TableCell align="left" onClick={() => viewBuyerDetail(row)} > {row.name} </TableCell>
                          <TableCell align="left" onClick={() => viewBuyerDetail(row)} > {row.mobile} </TableCell>
                          <TableCell align="left" onClick={() => viewBuyerDetail(row)} > {row.email} </TableCell>
                          <TableCell align="left" onClick={() => viewBuyerDetail(row)} > {row.tax_number} </TableCell>
                          <TableCell align="left" onClick={() => viewBuyerDetail(row)} > {row.address} </TableCell>
                          <TableCell align="left" onClick={() => viewBuyerDetail(row)} className={row.status.toLowerCase().replace(/\s/g, '-')} > {row.status} </TableCell>
                          <TableCell align="left">
                            <Box className="table-action">
                              {trader_type === "supplier" &&
                                row.trader_manually_added && (    
                                  <Tooltip title="Send Invitation" placement="top" arrow>                             
                                    <IconButton size={isMobile ? "small" : "medium"} onClick={(e) => sendInvitation(e, row.email, trader_type === "buyer" ? row.supplier_id : row.buyer_id) } >
                                      <i className="far fa-paper-plane" ></i>
                                    </IconButton>
                                  </Tooltip>
                                )}
                              {user_role == "admin" ||
                              user_role == "approver" ? (
                                <Tooltip title="Send Invitation" placement="top" arrow> 
                                  <IconButton size={isMobile ? "small" : "medium"} onClick={(e) => sendInvitation(e, row.email, trader_type === "buyer" ? row.supplier_id : row.buyer_id) } >
                                    <i className="far fa-paper-plane" ></i>
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                ""
                              )}
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={8} className="nodata">
                          No Data to Display
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

            {buyerList.length > 0 && buyerList !== undefined ? (
              <Grid container spacing={2} className="paginate">
                  <Grid item xs={6} sm={6} md={4} xl={4}>
                    <Box>
                      <Stack spacing={2}>
                        <Pagination count={page_count} page={page_no} variant="outlined" shape="rounded" onChange={handlePageClick} />
                      </Stack>
                    </Box>
                  </Grid>
              </Grid>
              ):null}

          </Box>
        </Box>
      </Box>
      <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description"
              open={openModel}
              onClose={modelClose}
          >
          <div style={modalStyle} className={modelClasses.paper}>
              <CancelIcon style={{ color: "#848f9f", position: "absolute", right: "0", top: "0", cursor:"pointer" }}  onClick={modelClose}/>
              <h4 className="modal-title" style={{ paddingBottom: "5%", textAlign: "center" }}>Upload Bulk Suppliers</h4>
              
              <Grid item xs={12} sm={12} md={12} xl={12} className="modal-image">
                  <Box style={{ paddingBottom: "5%", textAlign: "left" }}>
                    <input type="file" name="upload_suppliers" onChange={(e) => handleCsvUpload(e)}/>          
                  </Box>
              </Grid>  
              <Grid container>
                <Grid item xs={6} sm={6} md={2} xl={2}>
                  <Button className="submit-button" onClick={onSubmit}>Upload</Button>
                </Grid>  
                <Grid item xs={6} sm={6} md={4} xl={4}></Grid>
                <Grid item xs={6} sm={6} md={6} xl={6} style={{ textAlign: "right" }}>
                  <a href={SampleFile} target="_blank" download>
                    <Button style={{ color: "rgb(0, 184, 222)" }}>Sample File</Button>
                  </a>
                </Grid>
              </Grid>         
          </div>
      </Modal>
      </>
          )} 
    </ThemeContainer>
  );
}

Buyer.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(Buyer);
