import React, { useState, useEffect, useContext } from "react";
import styles from "assets/jss/material-kit-react/views/components.js";
import { makeStyles } from "@material-ui/core/styles";
import { isMobile } from "react-device-detect";
import verifyImg from "../../assets/img/verifyOTP.svg";
import googleAuthImg from "../../assets/img/google-auth.svg";
import {
  Grid,
  TextField,
  Typography,
  Button,
  Divider,
  Box,
  Snackbar,
  SnackbarContent,
  OutlinedInput,
} from "@material-ui/core";
import { Container, Row, Col } from "react-bootstrap";
import SignupFooter from "../Signup/footer";
import HomePage5 from "views/HomePage/HomePage5";
import image from "../../assets/img/forgot-password.png";
import { Link } from "react-router-dom";
import axios from "axios";
import { Store } from "../../context/store/storeState";
import { useHistory } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import OtpTimer from "otp-timer";

const useStyles = makeStyles(styles);

export default function forgotPassword() {
  const classes = useStyles();
  const api_url = process.env.REACT_APP_URL;
  const [data, setData] = useState();
  const [error, setError] = useState(false);
  const [block, setBlock] = useState(false);
  const verifyContext = useContext(Store);
  const verify = verifyContext.verify;
  const setVerify = verifyContext.setVerify;
  const logged_name = verifyContext.logged_name;
  const setLoggedName = verifyContext.setLoggedName;
  const logged_id = verifyContext.logged_id;
  const setLoggedID = verifyContext.setLoggedID;
  const otp_timer = verifyContext.otp_timer;
  const setOtpTimer = verifyContext.setOtpTimer;
  const history = useHistory();
  const [userInputOtp, setUserInputOtp] = useState({});
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState({});
  const email = localStorage.getItem("user_email");
  const [loader, setLoader] = useState(false);
  //const [seconds, setSeconds]     = useState(0);

  const validation = () => {
    if (
      userInputOtp !== {} &&
      userInputOtp !== null &&
      userInputOtp !== undefined &&
      userInputOtp.digit1 !== "" &&
      userInputOtp.digit2 !== "" &&
      userInputOtp.digit3 !== "" &&
      userInputOtp.digit4 !== "" &&
      userInputOtp.digit5 !== "" &&
      userInputOtp.digit6 !== ""
    ) {
      return true;
    }
    setError(true);
    return false;
  };
  const inputValue = (e) => {
    setData(e.target.value);
    setError(false);
  };
  const onSubmit = () => {
    const validate = validation();
    if (validate) {
      const otp =
        userInputOtp.digit1 +
        userInputOtp.digit2 +
        userInputOtp.digit3 +
        userInputOtp.digit4 +
        userInputOtp.digit5 +
        userInputOtp.digit6;
      let body = {
        email:
          verify.email === undefined ||
          verify.email === null ||
          verify.email === ""
            ? localStorage.getItem("user_email")
            : verify.email,
        otp: otp,
      };
      setLoader(false);
      axios
        .post(api_url + "/api/v1/submit/otp", body, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then((res) => {
          if (res.data.success) {
            if (localStorage.getItem("access_token") !== null) {
              localStorage.setItem("userName", res.data.user_name); //Setting username and password to local storage
              localStorage.setItem("userId", res.data.user_id);
              localStorage.setItem("user_type", res.data.user_type);
              localStorage.setItem("user_role", res.data.user_role);
              localStorage.setItem("access_token", res.data.access_token);
              localStorage.setItem("user_email", res.data.user_email);
              localStorage.setItem("kyc_type", res.data.kyc_type);
              localStorage.setItem("kyc_status", res.data.kyc_status);
              localStorage.setItem("currency_name", res.data.currency_name);
              localStorage.setItem("currency_symbol", res.data.currency_symbol);
              localStorage.setItem("is_lender", res.data.is_lender);
              localStorage.setItem(
                "redirect_company_info",
                res.data.redirect_company_info
              );
              localStorage.setItem(
                "is_manual_kyc_enabled",
                res.data.is_manual_kyc_enabled
              );

              setLoggedName(res.data.user_name);
              setLoggedID(res.data.user_id);

              if (res.data.user_type === "both") {
                localStorage.setItem("trader_type", "supplier");
              } else if (res.data.user_type === "buyer") {
                localStorage.setItem("trader_type", "buyer");
              } else {
              }
              if (res.data.user_type === "buyer") {
                window.location.href = "/buyer/dashboard";
              } else if (res.data.is_lender === true) {
                window.location.href = "/lender/dashboard";
              } else {
                window.location.href = "/supplierdash";
              }
            } else {
              history.push("/resetPassword");
            }
          } else {
            setLoader(true);
            setOpen(true);
            setMessage({ type: "error", content: res.data.message });
          }
        });
    }
  };
  const tabChange = function (val, e) {
    e.preventDefault();
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      let ele = document.querySelectorAll("input");
      if (ele[val - 1].value != "") {
        if (typeof ele[val] != "undefined") {
          ele[val].focus();
        }
      } else if (ele[val - 1].value == "") {
        if (typeof ele[val - 2] != "undefined") {
          ele[val - 2].focus();
        }
      }

      setUserInputOtp({ ...userInputOtp, [e.target.name]: e.target.value });
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const onCancel = () => {
    localStorage.clear();
    history.push("/signin");
  };
  const resedOTP = () => {
    setLoader(false);
    const body = {
      email: verify.email ? verify.email : email,
    };
    axios
      .post(api_url + "/api/v1/generate/otp", body, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((res) => {
        if (res.data.success) {
          setLoader(true);
          setVerify({
            email: verify.email,
          });
          setOtpTimer({
            otp_timer: res.data.otp_time,
          });
          setOpen(true);
          setBlock(false);
          setMessage({ type: "success", content: res.data.message });
        } else {
          setLoader(true);
          setOpen(true);
          setMessage({ type: "error", content: res.data.message });
        }
      });
  };
  let minutes;
  let seconds;
  if (otp_timer !== undefined && otp_timer.otp_timer) {
    minutes = Math.floor(otp_timer.otp_timer / 60);
    seconds = otp_timer.otp_timer - minutes * 60;
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    setLoader(true);
    if (email !== undefined && email !== null) {
      let body = {
        email: email,
      };
      axios
        .post(api_url + "/api/v1/generate/otp", body, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then((res) => {
          if (res.data.success) {
            setOtpTimer({
              otp_timer: res.data.otp_time,
            });
            setOpen(true);
            setMessage({ type: "success", content: res.data.message });
            setTimeout(() => {
              setBlock(true);
            }, res.data.otp_time * 1000);
          } else {
            setOpen(true);
            setMessage({ type: "error", content: res.data.message });
          }
        });
    }
    document.title = "Interswitch - Verify OTP";
  }, []);

  return (
    <>
      {" "}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}>
        <SnackbarContent
          className={
            message.type === "error"
              ? "snack-error"
              : message.type === "success"
              ? "snack-success"
              : "snack-teal"
          }
          message={message.content}
        />
      </Snackbar>
      {loader !== true ? (
        <Grid container spacing={3} className="bootstrap-loader">
          <Spinner animation="grow" variant="spinner1" />
          <Spinner animation="grow" variant="spinner2" />
          <Spinner animation="grow" variant="spinner3" />
        </Grid>
      ) : (
        <>
          <Box className="forgot-password">
            <Box className="scf-box">
              <Box className="scf-subbox">
                {isMobile ? <img className="mobile-img" src={image} /> : ""}

                <Grid container spacing={10}>
                  <Grid item xs={12} sm={12} md={6} xl={6}>
                    <Box>
                      <Grid item xs={12}>
                        <Grid item xs={12} className="heading">
                          Verification
                        </Grid>
                        <Grid>
                          <Typography className="text">
                            We have just sent you SMS and email with OTP, please
                            enter below to verify
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        {otp_timer !== undefined && otp_timer.otp_timer && (
                          <span className="otp-msg text">
                            <OtpTimer
                              minutes={minutes}
                              seconds={1}
                              text="This OTP is valid only for: "
                              ButtonText="Resend"
                              resend={resedOTP}
                            />
                          </span>
                        )}
                        <Box className="input-box">
                          <Grid className="company-title3"> OTP </Grid>
                          <Row>
                            <Col md={2} xs={2}>
                              <input
                                id="input1"
                                className="otp-all-input"
                                inputMode="numeric"
                                pattern="[0-9]*"
                                name="digit1"
                                maxLength={1}
                                disabled={block}
                                onKeyUp={(e) => {
                                  tabChange(1, e);
                                }}
                              />
                            </Col>
                            <Col md={2} xs={2}>
                              <input
                                id="input1"
                                className="otp-all-input"
                                inputMode="numeric"
                                pattern="[0-9]*"
                                name="digit2"
                                maxLength={1}
                                disabled={block}
                                onKeyUp={(e) => {
                                  tabChange(2, e);
                                }}
                              />
                            </Col>
                            <Col md={2} xs={2}>
                              <input
                                id="input1"
                                className="otp-all-input"
                                inputMode="numeric"
                                pattern="[0-9]*"
                                name="digit3"
                                maxLength={1}
                                disabled={block}
                                onKeyUp={(e) => {
                                  tabChange(3, e);
                                }}
                              />
                            </Col>
                            <Col md={2} xs={2}>
                              <input
                                id="input1"
                                className="otp-all-input"
                                inputMode="numeric"
                                pattern="[0-9]*"
                                name="digit4"
                                maxLength={1}
                                disabled={block}
                                onKeyUp={(e) => {
                                  tabChange(4, e);
                                }}
                              />
                            </Col>
                            <Col md={2} xs={2}>
                              <input
                                id="input1"
                                className="otp-all-input"
                                inputMode="numeric"
                                pattern="[0-9]*"
                                name="digit5"
                                maxLength={1}
                                disabled={block}
                                onKeyUp={(e) => {
                                  tabChange(5, e);
                                }}
                              />
                            </Col>
                            <Col md={2} xs={2}>
                              <input
                                id="input1"
                                className="otp-all-input"
                                inputMode="numeric"
                                pattern="[0-9]*"
                                name="digit6"
                                maxLength={1}
                                disabled={block}
                                onKeyUp={(e) => {
                                  tabChange(6, e);
                                }}
                              />
                            </Col>
                          </Row>
                        </Box>
                      </Grid>
                      <Grid className="company-title3">
                        <a
                          href="#"
                          onClick={() => resedOTP()}
                          style={{ color: "red" }}>
                          Resend OTP
                        </a>
                      </Grid>
                      {error && error === true && (
                        <Grid
                          className="company-title3"
                          style={{ color: "red" }}>
                          Please fill all the fields
                        </Grid>
                      )}

                      <Grid container spacing={3} className="button-grid">
                        <Grid item xs={6} sm={4} md={5} xl={5}>
                          <Button
                            className="save"
                            onClick={() => onSubmit()}
                            disabled={
                              !userInputOtp.digit1 ||
                              !userInputOtp.digit2 ||
                              !userInputOtp.digit3 ||
                              !userInputOtp.digit4 ||
                              !userInputOtp.digit5 ||
                              !userInputOtp.digit6
                            }>
                            Verify
                          </Button>
                        </Grid>
                        <Grid item xs={6} sm={4} md={5} xl={5}>
                          <Button className="cancel" onClick={() => onCancel()}>
                            Cancel
                          </Button>
                        </Grid>
                      </Grid>

                      {verify.email === "" && (
                        <Grid container>
                          <Grid item xs={8} style={{ textAlign: "center" }}>
                            <img
                              src={verifyImg}
                              alt=""
                              style={{ marginTop: "50px" }}
                            />
                            <img
                              onClick={() =>
                                history.push("/googleAuthentication")
                              }
                              src={googleAuthImg}
                              alt=""
                              style={{ marginTop: "50px", cursor: "pointer" }}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Box>
                  </Grid>
                  {isMobile ? (
                    " "
                  ) : (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={5}
                      xl={5}
                      className="right-image">
                      <img src={image} />
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Box>
          </Box>
          <HomePage5 />
          {isMobile ? " " : <SignupFooter />}
        </>
      )}
    </>
  );
}
