import React, { useState, useEffect, useContext } from "react";
import styles from "assets/jss/material-kit-react/views/components.js";
import { makeStyles } from "@material-ui/core/styles";
import { isMobile } from "react-device-detect";

import {
  Grid,
  TextField,
  Typography,
  Button,
  Divider,
  Box,
  OutlinedInput,
  Snackbar,
  SnackbarContent,
} from "@material-ui/core";

import SignupFooter from "../Signup/footer";
import HomePage5 from "views/HomePage/HomePage5";
import image from "../../assets/img/forgot-password.png";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { Store } from "../../context/store/storeState";
import { Spinner } from "react-bootstrap";

const useStyles = makeStyles(styles);

export default function forgotPassword() {
  const classes                 = useStyles();
  const api_url                 = process.env.REACT_APP_URL;
  const [error, setError]       = useState(false);
  const [message, setMessage]   = useState({});

  const verifyContext           = useContext(Store);
  const setVerify               = verifyContext.setVerify;
  const verify                  = verifyContext.verify;
  const otp_timer               = verifyContext.otp_timer;
  const setOtpTimer             = verifyContext.setOtpTimer;
  const history                 = useHistory();
  const url                     = window.location.href;
  const email                   = url.split("=")[1];
  const [getEmail, setEmail]    = useState(email);
  const [data, setData]         = useState(email ? email : "");
  const [open, setOpen]         = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [loader, setLoader]     = useState(false);

  const validation = () => {
    if (data == "") {
      setError(true);
      return false;
    }
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data)) {
      return true;
    }
    setError(true);
    return false;
  };
  const inputValue = (e) => {
    setData(e.target.value);
    setError(false);
    setDisabled(false)
  };
  const onSubmit = () => {
    const validate = validation();
    if (validate) {
      setLoader(false);
      setDisabled(true);
      const body = {
        email: data,
      };
      axios
        .post(api_url + "/api/v1/generate/otp", body, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then((res) => {
          if (res.data.success) {
            setVerify({
              email: data,
              userId: res.data.user_id,
              accessToken: res.data.access_token,
            });
            setOtpTimer({
              otp_timer: res.data.otp_time
            });
            setOpen(true);
            setMessage({ type: "success", content: res.data.message });
            setTimeout(() => {
              history.push("/verifyOTP");
            }, 500);
          } else {
            setLoader(true);
            setOpen(true);
            setMessage({ type: "error", content: res.data.message });
          }
        });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  useEffect(() => {
    setLoader(true);
    localStorage.clear();
    let page = getEmail && getEmail !== "" && getEmail !== undefined ? "Reset" : "Forgot";
    document.title = 'Interswitch - '+page+' Password';
  }, []);
  return (
    <>
      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={open} autoHideDuration={4000} onClose={handleClose} >
        <SnackbarContent className={message.type === "error" ? "snack-error" : message.type === "success" ? "snack-success" : "snack-teal" } message={message.content} />
      </Snackbar>
      {loader !== true ? (
        <Grid container spacing={3} className="bootstrap-loader">          
            <Spinner animation="grow" variant="spinner1" />
            <Spinner animation="grow" variant="spinner2" />
            <Spinner animation="grow" variant="spinner3" />          
        </Grid>
      ) : (
      <>
      <Box className="forgot-password">
        <Box className="scf-box">
          <Box className="scf-subbox">
            <Box>
              {isMobile ? (
                <img className="mobile-img" src={image} />
              ) : (
                ""
              )}

              <Grid container spacing={10}>
                <Grid item xs={12} sm={12} md={6} xl={6}>
                  <Box>
                    <Grid item xs={12}>
                      <Grid item xs={12} className="heading">
                        {getEmail && getEmail !== "" && getEmail !== undefined
                          ? "Reset"
                          : "Forgot"}{" "}
                        Password
                      </Grid>
                      {/*<Grid>
                        <Typography className="text" >
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit.Sed feugiat, sapien
                          <br />
                          in rhoncus suscipit, metus mi accumsan dui, vel vulputate metus mauris
                          <br /> vitae tellus.Aenean porttitor condimentum eros sed tempus.
                        </Typography>
                      </Grid>*/}
                    </Grid>
                    <Grid item xs={10}>
                      <Box className="input-box">
                        <Grid className="company-title3">
                          Email Address <span className="required">*</span>
                        </Grid>
                        <OutlinedInput
                          type="text"
                          fullWidth
                          disabled={getEmail !== undefined ? true : false}
                          className="company-title51"
                          placeholder="Please enter your registered email to recover your password"
                          onChange={(e) => inputValue(e)}
                          value={data}
                        />
                      </Box>
                    </Grid>
                    {error && error === true && (
                      <Grid className="company-title3" style={{ color: "red" }}>
                        Email is invalid
                      </Grid>
                    )}
                    <Grid container spacing={3} className="button-grid">
                      <Grid item xs={6} sm={4} md={5} xl={5}>
                          <Button className="save" onClick={() => onSubmit()} disabled={!data}>Confirm Email</Button>
                      </Grid>
                      <Grid item xs={6} sm={4} md={5} xl={5}>
                          <Link to="/signin"><Button className="cancel">Cancel</Button></Link>
                      </Grid>
                    </Grid>

                    <Grid container></Grid>
                    <Grid item xs={12}></Grid>
                  </Box>
                </Grid>
                {isMobile ? (
                  " "
                ) : (
                  <Grid item xs={12} sm={12} md={5} xl={5}>
                    <img src={image} />
                  </Grid>
                )}
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>      
      <HomePage5 />
      {isMobile ? " " : <SignupFooter />}
      </>
      )}
    </>
  );
}
