import React from 'react';
import image2 from "assets/img/Financedoodle.svg"
import { Grid, Box, Typography, OutlinedInput, MenuItem, FormControl, Select, Table, TableBody, TableContainer, TableCell, TableHead, TableRow, Paper, Button } from "@material-ui/core";

import Custom2 from 'views/Components/Custom2';



function createData(sno, invoiceno, issuedate, duedate, buyername, invamount, appramount, supplier, status, action) {
    return { sno, invoiceno, issuedate, duedate, buyername, invamount, appramount, supplier, status, action };
}

const mystyles = {
    topFiltersInput: { paddingTop: "5%", paddingBottom: "5%", "borderRadius": "5px" },
    topFilterSelect: { paddingTop: "12%", paddingBottom: "5%", "borderRadius": "5px" }
}

const rows = [
    createData(1, "2021 - 00001", "2021 - 06 - 20", "2021 - 07 - 01", "Thomson", "$1500000", "$1450000", "Fedex", "Funded"),
    createData(2, "2021 - 00001", "2021 - 06 - 20", "2021 - 07 - 01", "Thomson", "$1500000", "$1450000", "Fedex", "Varified"),
    createData(3, "2021 - 00001", "2021 - 06 - 20", "2021 - 07 - 01", "Thomson", "$1500000", "$1450000", "Fedex", "Funded"),
    createData(4, "2021 - 00001", "2021 - 06 - 20", "2021 - 07 - 01", "Thomson", "$1500000", "$1450000", "Fedex", " Varified"),

];
const mystyle = {
    upload: { fontSize: "14px", fontWeight: "600", color: "#0275D8", border: "1px solid #0275D8", width: "100%" },

}

export default function BuyerInvoice() {

    return (
        <>

            <Box style={{ background: "white" }}>
                <Box style={{ padding: "5%", height: "100%", backgroundImage: `url(${image2})` }} >
                    <Box style={{ marginTop: "5%", background: "white", borderRadius: "16px", padding: "2%" }}>
                        <Box sx={{ width: '100%' }}>
                            <Grid container >

                                <Grid item xs={8} sm={8} md={10} xl={10}>
                                    <Typography style={{ color: "#18425D", fontSize: "24px", fontWeight: "700" }}>Invoice </Typography>
                                </Grid>
                                <Grid item xs={4} sm={4} md={2} xl={2}>
                                    <Button style={mystyle.upload}>Add New Invoice</Button>
                                </Grid>
                            </Grid>
                            <Typography style={{ paddingTop: "2%", color: "#0275D8", fontSize: "16px", fontWeight: "600" }}> Filters</Typography>

                        </Box>



                        <Grid container style={{ display: "flex", flexFlow: "inherit", flexDirection: "row", justifyContent: "center", padding: "8px 12px" }} spacing={1}>
                            <Grid item xs={11} sm={12} md={2} xl={1} lg={2} >
                                <Box style={mystyles.topFiltersInput}>
                                    <span style={{ fontSize: "12px", fontWeight: "600", fontFamily: "Averta", fontStyle: "normal", height: "16px" }}>Start Date*</span>
                                    <OutlinedInput
                                        fullWidth
                                        margin="dense"
                                        placeholder="e.g. 09-09-2021"
                                    />
                                </Box>
                            </Grid>


                            <Grid item xs={11} sm={12} md={2} xl={1} lg={2}>
                                <Box style={mystyles.topFiltersInput}>
                                    <span style={{ fontSize: "12px", fontWeight: "600", fontFamily: "Averta", fontStyle: "normal", height: "16px" }}>End Date*</span>
                                    <OutlinedInput
                                        fullWidth
                                        margin="dense"
                                        placeholder="e.g. 09-09-2021"
                                    />
                                </Box>
                            </Grid>


                            <Grid item xs={11} sm={12} md={2} xl={1} lg={2}>
                                <Box style={mystyles.topFiltersInput}>
                                    <span style={{ fontSize: "12px", fontWeight: "600", fontFamily: "Averta", fontStyle: "normal", height: "16px" }}>Invoice Number*</span>
                                    <OutlinedInput
                                        fullWidth
                                        margin="dense"
                                        placeholder="e.g. 09-09-2021"
                                    />
                                </Box>
                            </Grid>


                            <Grid item xs={11} sm={12} md={2} xl={1} lg={2}>
                                <Box style={mystyles.topFilterSelect}>
                                    <span style={{ fontSize: "12px", fontWeight: "600", fontFamily: "Averta", fontStyle: "normal", height: "16px" }}>Invoice Range*</span>
                                    <FormControl fullWidth>
                                        <Select
                                            margin="dense"
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Age"
                                        >
                                            <MenuItem value={10}>Ten</MenuItem>
                                            <MenuItem value={20}>Twenty</MenuItem>
                                            <MenuItem value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>


                            <Grid item xs={11} sm={12} md={2} xl={1} lg={2}>
                                <Box style={mystyles.topFilterSelect}>

                                    <Typography style={{ fontSize: "12px", fontWeight: "600", fontFamily: "Averta", fontStyle: "normal", height: "16px" }}>Select Supplier*</Typography>
                                    <FormControl fullWidth>
                                        <Select
                                            margin="dense"
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Age"
                                        >
                                            <MenuItem value={10}>Ten</MenuItem>
                                            <MenuItem value={20}>Twenty</MenuItem>
                                            <MenuItem value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>


                            </Grid>

                            <Grid item xs={11} sm={12} md={2} xl={1} lg={2}>
                                <Box style={mystyles.topFilterSelect}>
                                    <Typography style={{ fontSize: "12px", fontWeight: "600", fontFamily: "Averta", fontStyle: "normal", height: "16px" }}>Select Buyer*</Typography>
                                    <FormControl fullWidth>
                                        <Select
                                            margin="dense"
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Age"
                                        >
                                            <MenuItem value={10}>Ten</MenuItem>
                                            <MenuItem value={20}>Twenty</MenuItem>
                                            <MenuItem value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>

                            </Grid>

                            <Grid item xs={11} sm={12} md={2} xl={1} lg={2}>
                                <Box style={mystyles.topFilterSelect}>
                                    <Typography style={{ fontSize: "12px", fontWeight: "600", fontFamily: "Averta", fontStyle: "normal", height: "16px" }}>Select Status*</Typography>
                                    <FormControl fullWidth>
                                        <Select
                                            margin="dense"
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Age"
                                        >
                                            <MenuItem value={10}>Ten</MenuItem>
                                            <MenuItem value={20}>Twenty</MenuItem>
                                            <MenuItem value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>


                                </Box>


                            </Grid>

                            <Grid item xs={11} sm={12} md={2} xl={1} lg={1}>
                                <Box style={{ paddingTop: "35%", paddingBottom: "5%", paddingRight: "10%", }}>

                                    <Button style={{ "alignItems": "center", "width": "90px", "height": "40px", "background": "#479FC8", "borderRadius": "5px" }} >Submit</Button>

                                </Box>
                            </Grid>
                            <Grid item xs={11} sm={12} md={2} xl={1} lg={1}>
                                <Box style={{ paddingTop: "35%", paddingBottom: "5%", paddingRight: "10%" }}>

                                    <Button style={{ "alignItems": "center", "width": "90px", "height": "40px", "background": "#6B7280", "borderRadius": "5px" }}>Clear</Button>

                                </Box>
                            </Grid>


                        </Grid>
                        <Box>
                            <TableContainer component={Paper}>
                                <Table style={{ padding: "27px", borderCollapse: 'separate', borderSpacing: '0px 5px' }} sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow style={{ background: "#F3F5F6", borderRadius: "4x" }}>
                                            <TableCell>S. No. </TableCell>
                                            <TableCell align="left">Invoice Number</TableCell>
                                            <TableCell align="left">Issue Date </TableCell>
                                            <TableCell align="left">Due Date</TableCell>
                                            <TableCell align="left"> Buyer Name</TableCell>
                                            <TableCell align="left">Inv Amount</TableCell>
                                            <TableCell align="left">Appr Amount</TableCell>
                                            <TableCell align="left">Supplier</TableCell>
                                            <TableCell align="left">Status</TableCell>

                                            <TableCell align="left">Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody >
                                        {rows.map((row) => (
                                            <TableRow
                                                style={{ background: "#F3F5F6" }}
                                                key={row.name}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {row.sno}
                                                </TableCell>
                                                <TableCell align="left">{row.invoiceno}</TableCell>
                                                <TableCell align="left">{row.issuedate}</TableCell>
                                                <TableCell align="left">{row.duedate}</TableCell>
                                                <TableCell align="left">{row.buyername}</TableCell>
                                                <TableCell align="left">{row.invamount}</TableCell>
                                                <TableCell align="left">{row.appramount}</TableCell>
                                                <TableCell align="left">{row.supplier}</TableCell>
                                                <TableCell align="left">{row.status}</TableCell>

                                                <TableCell align="left">{<Custom2 />}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Box >

            </Box >




        </>
    )
}