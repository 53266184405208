import React, { Fragment } from 'react';
import { Grid } from "@material-ui/core";
import TemporaryDrawer from "../sampleMini";
import SupplierNavBar from '../SupplierNavBar';
import { isMobile } from 'react-device-detect';
import MobileAppbarPrivate from '../MobileAppbarPrivate';

const ThemeContainer = ({ type = 'public', children, value }) => {
    if (type === 'public') {
        return (<Fragment>
            {children}
        </Fragment>);
    }
    else {
        if (type === 'private') {
            return (
                <>
                    {isMobile ? <MobileAppbarPrivate /> : <SupplierNavBar />}
                    {!isMobile ? <Grid container>
                        <Grid item xs={1}>
                            <TemporaryDrawer value={value} />
                        </Grid>
                        <Grid item xs={11}>
                            {children}
                        </Grid>
                    </Grid> : children}
                </>);
        }
    }
}


export default ThemeContainer;