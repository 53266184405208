import React from 'react';
import { Doughnut } from 'react-chartjs-2';

const data = {
    labels: ['ABC', 'CMF', 'BDC'],
    datasets: [
        {
            label: '# of Votes',
            data: [48, 26, 26],
            backgroundColor: [
                'rgba(255, 128, 128, 1)',
                'rgba(255, 123, 0, 1)',
                'rgba(255, 206, 86, 1)',

            ],
            borderColor: [
                'rgba(255, 128, 128, 1)',
                'rgba(255, 123, 0, 1)',
                'rgba(255, 206, 86, 1)',
            ],
            borderWidth: 1,
        },
    ],
};
const options = {
    plugins: {
        legend: {
            position: 'right',
            labels: {
                usePointStyle: true,
                padding: 30
            }
        },
        title: {
            display: true,
            text: "Lenders",
            align: 'start',
            font: {
                size: 16,
                weight: 'bold'
            },
            padding: 20,
            color: "#479FC8"
        },
    }
}

const DoughnutChart = () => (
    <Doughnut data={data} options={options} />
);

export default DoughnutChart;
