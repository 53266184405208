import React from "react";
import ReactDOM from "react-dom";
import "assets/scss/material-kit-react.scss?v=1.10.0";
import "./assets/css/index.css";
import "./assets/css/common.css";
import "./assets/css/style.css";
import StoreState from "context/store/storeState";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";

ReactDOM.render(
  <StoreState>
    <App />
  </StoreState>,
  document.getElementById("root")
);
