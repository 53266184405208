import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider,
  TwitterAuthProvider,
} from "firebase/auth";
import axios from "axios";
const firebaseConfig = {
  apiKey: "AIzaSyBaNc1EnFqIR-_ajXh1zxIizdWWoBgQrQQ",
  authDomain: "interswitch-3ba89.firebaseapp.com",
  projectId: "interswitch-3ba89",
  storageBucket: "interswitch-3ba89.appspot.com",
  messagingSenderId: "793205635208",
  appId: "1:793205635208:web:caeb5af4f15a29389fc73d",
  measurementId: "G-72DVRHH5QE",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
