import React from "react";
import { Row, Col } from "react-bootstrap";
import IdCard from "../../../assets/img/idCard.svg";
import EditIcon from "../../../assets/img/editIcon.svg";
import DeleteIcon from "../../../assets/img/deleteIcon.svg";
const UploadDocument = (props) => {
  
  const data = props.info;
  const api_url = process.env.REACT_APP_URL;

  return (
    <>
      {data && (
        <>
          <div className="Averta-font">
            <Row className="my-3">
              {data &&
                data.length > 0 &&
                data.map((info, upload_key) => (
                  <div key={upload_key} >
                    {info.doc_list &&
                        info.doc_list.length > 0 &&
                        <>
                    <Row>
                      <Col className="cp-heading2 my-3">Uploaded Documents</Col>
                    </Row>
                    <Row className="my-3">
                      <Col className="cp-sub8">{info.doc_name}</Col>
                    </Row>
                    </>
                    }

                    <Row>
                      {info.doc_list &&
                        info.doc_list.length > 0 &&
                        info.doc_list.map((ele, upload_key1) => (
                          <>
                            <Col md={2} xs={6} key={upload_key1}>
                              {ele.image_extension === "application/pdf" ? (
                                  <embed src={`${api_url+ele.document_url}?${Date.now()}`} heigth="100px" width="160px" type={ele.image_extension}/>                                                                  
                                ) : (
                                  <img src={`${api_url+ele.document_url}?${Date.now()}`} heigth="100px" width="160px"/>
                                )}
                            </Col>
                          </>
                        ))}
                    </Row>
                  </div >
                ))}
            </Row>
          </div>
        </>
      )}
    </>
  );
};
export default UploadDocument;
