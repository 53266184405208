// Access Token handling library
import cookieHandle from "./cookieHandle";
const { setCookie, getCookie } = cookieHandle();


/**
 * retrieve the access token
 */
const getAccessToken = function () {
    //const accessToken = getCookie('accessToken');
    const accessToken = localStorage.getItem("access_token");
    return accessToken ? accessToken : null;
};


/**
 * set the access token
 * @param {String} accessToken 
 */
const setAccessToken = function (accessToken) {
    if (accessToken && typeof accessToken === "string") {
        setCookie('accessToken', accessToken);
    }
}


/**
 * remove the access token from the system
 */
const removeAccessToken = function () {
    setCookie('accessToken', "");
}

const setUserId = function () {
    setCookie('userId', "");
}



export {
    getAccessToken,
    setAccessToken,
    removeAccessToken,
    setUserId
}