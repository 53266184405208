import React from 'react'
import { Dialog, CircularProgress, Box, withStyles } from "@material-ui/core"
import { PropTypes } from "prop-types"

const styles = () => ({

    box: {
        "& div": {
            background: "bottom !important",
            "&.MuiDialog-paperWidthSm": {
                maxWidth: "100%"
            },
            "&.MuiDialog-paperScrollPaper": {
                maxHeight: "100%"
            },
            "&.MuiDialog-paper": {
                margin: 0,
            },
        }

    }
})

function Loader(props) {
    const { loading } = props;
    const { classes } = props;
    return (
        <Dialog className={classes.box}
            // maxWidth={"xs"}
            open={loading}>
            <Box
                // noValidate
                component="form"
                style={{
                    background: "rgba(255, 255, 255, 0.5)",
                }}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: "center",
                    alignItems: "center",
                    m: 'auto',
                    width: "100vw",
                    height: "100vh"
                }}
            >
                {loading && <CircularProgress size={70} thickness={5} />}
            </Box>
        </Dialog>
    );
}

Loader.propTypes = {
    classes: PropTypes.object
}


export default withStyles(styles)(Loader);