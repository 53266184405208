import React from "react";
import Grid from '@material-ui/core/Grid'
import { Box, CardMedia, Toolbar } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import image from "assets/img/interswitch.png";
import { isMobile } from "react-device-detect";




export default function AboutInterswitch() {


    return (
        <>
            <Box style={{ background: "white" }}>
                {isMobile ?
                    <Box style={{ padding: "6%" }}>
                        <CardMedia style={{ "borderRadius": "9px", width: "97%", paddingTop: "5%" }} component="img" image={image} />

                    </Box>
                    : ""}
                {isMobile ? " " : <Typography style={{
                    fontSize: "20px",
                    fontWeight: "400",
                    "height": "50px",
                    "background": "#FDED94",
                    textAlign: "center",
                    padding: "2% 0% 4% 0%",
                    marginBottom: "3%",

                    color: "#072F40"
                }}> <span style={{
                    fontSize: "24px",
                    fontWeight: "700",
                    color: "#072F40"
                }}>
                        Announcements -
                    </span>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                </Typography>}

                <Box style={{ padding: "5%" }}>

                    <Box style={{ "margin-right": "10%" }}>

                        <Grid container spacing={2}>

                            <Grid item xs={12} sm={12} md={7} xl={7}>
                                <Typography className="heading1" style={{ marginBottom: "24px" }}> About Interswitch </Typography>

                                <Typography style={{ "fontFamily": "Averta-Regular", "fontStyle": "normal", "fontWeight": "normal", "fontSize": "14px", "lineHeight": "25px", "color": "#353F50", paddingBottom: "4%" }}>We Are Here To Provide A Marketplace Where Suppliers, Buyers and Lenders can register and utilies platform for discount there Invoices! </Typography>

                                <Typography className="company-title2" style={{ paddingTop: "2%", paddingBottom: "2%" }}> OUR VISION</Typography>
                                <Typography style={{ "fontFamily": "Averta-Regular", "fontStyle": "normal", "fontWeight": "normal", "fontSize": "14px", "lineHeight": "25px", "color": "#353F50", paddingBottom: "4%" }}>A prosperous Africa, driven by a seamless exchange of value and commerce.</Typography>

                                <Typography className="company-title2" style={{ paddingTop: "2%", paddingBottom: "2%" }}>OUR MISSION</Typography>
                                <Typography style={{ "fontFamily": "Averta-Regular", "fontStyle": "normal", "fontWeight": "normal", "fontSize": "14px", "lineHeight": "25px", "color": "#353F50", paddingBottom: "4%" }}>Create technology solutions that connect and empower individuals, businesses, and communities.</Typography>

                                <Typography className="company-title2" style={{ paddingTop: "2%", paddingBottom: "2%" }}> OUR PURPOSE</Typography>
                                <Typography style={{ "fontFamily": "Averta-Regular", "fontStyle": "normal", "fontWeight": "normal", "fontSize": "14px", "lineHeight": "25px", "color": "#353F50", paddingBottom: "4%" }}>Inspire Africa to greatness through innovation, value creation and excellence.</Typography>

                                <Typography className="company-title2" style={{ paddingTop: "2%", paddingBottom: "2%" }}>Switching Africa’s Payment Future through Innovation</Typography>

                                <Typography style={{ "fontFamily": "Averta-Regular", "fontStyle": "normal", "fontWeight": "normal", "fontSize": "14px", "lineHeight": "25px", "color": "#353F50", paddingBottom: "4%" }}><p>Interswitch’s broad network and robust payments platform have been instrumental to the development of the Nigerian payments ecosystem and provide Interswitch with credibility to expand across Africa.</p></Typography>
                                <Typography style={{ "fontFamily": "Averta-Regular", "fontStyle": "normal", "fontWeight": "normal", "fontSize": "14px", "lineHeight": "25px", "color": "#353F50", paddingBottom: "4%" }}><p> Today, Interswitch is a leading player with critical mass in Africa’s rapidly developing financial ecosystem and is active across the payments value chain, providing a full suite of omni-channel payment solutions.</p></Typography>
                            </Grid>

                            {isMobile ? " " : <Grid item xs={12} sm={12} md={5} xl={5} style={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
                                <Box style={{ padding: "6%" }}>
                                    <CardMedia style={{ "borderRadius": "9px", width: "97%", paddingTop: "20%" }} component="img" image={image} />

                                </Box>
                            </Grid>}
                        </Grid>
                    </Box >
                </Box >
            </Box>
        </>
    );
}




//: header / h7;
