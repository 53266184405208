import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Grid,
  Box,
  Typography,
  OutlinedInput,
  withStyles,
  MenuItem,
  FormControl,
  Select,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Toolbar,
  Snackbar,
  SnackbarContent,
} from "@material-ui/core";
import preview from "assets/img/previewButton.svg";
import Custom from "views/Components/Custom";
import ThemeContainer from "views/Components/sideBar/ThemeContainer";
import { isMobile } from "react-device-detect";
import { PropTypes } from "prop-types";
import TableComponent from "views/Components/tableComponent/tablecomponent";
import { getAccessToken } from "../../../../utilities/tokenUtil";
import EditOutlinedIcon from "@material-ui/icons/BorderColor";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import image from "../../../../assets/img/avatar.png";
import Slider from "react-slick";
import { Spinner } from "react-bootstrap";

var axios = require("axios");

const styles = (theme) => ({
  textFields: {
    background: "#F3F5F6",
    height: "40px",
    "& div": {
      "&::before": {
        content: "",
        borderBottom: "none",
      },
      "&::after": {
        borderBottom: "none",
      },
      "&:hover": {
        "&::before": {
          borderBottom: "none !important",
        },
        "&::after": {
          borderBottom: "none !important",
        },
      },
      "& .MuiInputBase-input": {
        padding: "9px 7px",
      },
    },
  },
});

function viewSignatory(props) {
  const { classes } = props;
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const api_url = process.env.REACT_APP_URL;
  const [name, setFullName] = useState("");
  const [country, setCountry] = useState("");
  const [countryList, setCountryList] = useState("");
  const [email, setEmail] = useState("");
  const [designation, setDesignation] = useState("");
  const [Mobilenumber, setMobileNumber] = useState("");
  const [Bvnnumber, setBvnNumber] = useState("");
  const [address, setAddress] = useState("");
  const [zip, setZip] = useState("");
  const [WorkingatBranchHO, setWorkingatBranchHO] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [roles, setRoles] = useState("");
  const [ManageUsers, setManageUsers] = useState("");
  const [status, setStatus] = useState("");
  const [stateList, setStateList] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [user_list, setUserList] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState({});
  const [open, setOpen] = useState(false);
  const [user_documents, setUserDocuments] = useState("");
  const [contact_id, setContactId] = useState("");
  const [partner_id, setPartnerId] = useState("");
  const [document_id, setDocumentId] = useState("");
  const [document_type_id, setDocumentTypeID] = useState("");
  const [file2, setfile2] = useState();
  const [fileview2, setfileView2] = useState();
  const [fileview3, setfileView3] = useState();
  const [file, setfile] = useState();
  const [newfile, setNewfile] = useState();
  const [hqList, setHqList] = useState([]);
  const [uploadDoc, setuploadDoc] = useState([]);
  let regEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    initialSlide: 0,
    padSlides: false,
  };
  const editUpload = (event, type, id, name) => {
    const access_token = getAccessToken();
    let data1 = new FormData();
    data1.append("user_id", parseInt(localStorage.getItem("userId")));
    data1.append("access_token", access_token);
    data1.append("document_id", id);
    data1.append("document_type_id", type);
    data1.append("document_name", name);
    data1.append("document_data", event.target.files[0]);
    data1.append("partner_id", props.match.params.id);

    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/user/document/upload`, data1, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response1) => {
        if (response1.data.success == true) {
          setLoading(false);
          setOpen(true);
          setMessage({ type: "success", content: response1.data.message });
          uploadDocumentList(props.match.params.id);
        } else {
          setLoading(false);
          setOpen(true);
          setMessage({ type: "error", content: response1.data.message });
        }
      })
      .catch((error) => {});
  };

  const remove = (type, id) => {
    const access_token = getAccessToken();

    const dataSet = JSON.stringify({
      access_token: access_token,
      user_id: localStorage.getItem("userId"),
      partner_id: parseInt(props.match.params.id),
      document_type_id: type,
      document_id: id,
    });

    const config = {
      method: "post",
      url: process.env.REACT_APP_URL + "/api/v1/user/document/delete",
      headers: {
        "Content-Type": "text/plain",
      },
      data: dataSet,
    };
    axios(config)
      .then((response1) => {
        if (response1.data.sucess) {
          setLoading(false);
          setOpen(true);
          setMessage({ type: "error", content: response1.data.message });
          uploadDocumentList(props.match.params.id);
        } else {
          setLoading(false);
          setOpen(true);
          setMessage({ type: "error", content: response1.data.message });
          uploadDocumentList(props.match.params.id);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const uploadDocumentList = (id) => {
    const access_token = getAccessToken();

    const data = {
      access_token: access_token,
      user_id: parseInt(localStorage.getItem("userId")),
      contact_id: id,
      type: "authorized_signatory",
    };

    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/user/detail`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        setuploadDoc(response.data.documents);
        if (response.data.country_id !== false) {
          fetchState1(
            response.data.country_id,
            "allstate1",
            response.data.state_id
          );
        }
        setLoader(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const masterFunction1 = () => {
    const access_token = getAccessToken();

    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: access_token,
    };

    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/user/master`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        console.log(response);
        if (response) {
          setHqList(response.data.working_location);
          setLoader(true);
        } else {
          setLoader(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const masterFunction = () => {
    const access_token = getAccessToken();

    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: access_token,
    };

    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/signatory/master`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        if (response) {
          //setHqList(response.data.working_location);
          setDocuments(response.data.signatory_documents);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const userDetail = () => {
    const access_token = getAccessToken();
    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: access_token,
      contact_id: parseInt(props.match.params.id),
      type: "authorized_signatory",
    };
    setLoader(false);
    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/user/detail`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        if (response) {
          setAddress(response.data.address);
          setBvnNumber(response.data.bvn_number);
          setCity(response.data.city);
          setCountry(response.data.country_id);
          setState(response.data.state_id);
          setDesignation(response.data.designation);
          setMobileNumber(response.data.mobile);
          setFullName(response.data.name);
          setEmail(response.data.email);
          setRoles(response.data.user_role);
          setWorkingatBranchHO(response.data.working_location_id);
          setZip(response.data.zip);
          setUserDocuments(response.data.documents);
          setfileView2(
            response.data.image.substring(1, response.data.image.length)
          );
          setfileView3(response.data.image);

          if (response.data.country_id !== false) {
            fetchState1(
              response.data.country_id,
              "allstate1",
              response.data.state_id
            );
          }
          setLoader(true);
        } else {
          setLoader(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSubmit = () => {
    const access_token = getAccessToken();
    let mobile_validate =
      /^(?:\+)?\s*[0][1-9]\d{9,12}$|^(?:\+)?\s*[1-9]\d{9,12}$/;

    let bvn_validate = /^[0][1-9]\d{10}$|^[1-9]\d{10}$/;

    if (
      name.trim() === "" ||
      name.trim() === undefined ||
      Mobilenumber.trim() === "" ||
      Mobilenumber.trim() === undefined ||
      email.trim() === "" ||
      email.trim() === undefined ||
      roles === "" ||
      roles === undefined ||
      designation.trim() === "" ||
      designation.trim() === undefined ||
      Bvnnumber.trim() === "" ||
      Bvnnumber.trim() === undefined ||
      WorkingatBranchHO === "" ||
      WorkingatBranchHO === undefined ||
      address.trim() === "" ||
      address.trim() === undefined ||
      zip.trim() === "" ||
      zip.trim() === undefined ||
      country === "" ||
      country === undefined ||
      state === "" ||
      state === undefined ||
      city.trim() === "" ||
      city.trim() === undefined
    ) {
      setLoading(false);
      setOpen(true);
      setMessage({ type: "error", content: "All fields are mandatory." });
      return;
    }

    if (!Mobilenumber.replace(/\s/g, "").match(mobile_validate)) {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "Mobile number should be 10 to 13 digit",
      });
      return;
    }
    if (!Bvnnumber.match(bvn_validate)) {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "BVN number should be 11 digit",
      });
      return;
    }

    var a = new Set([]);
    documents.map((item) => {
      if (item.is_required === true) {
        uploadDoc.map((x) => {
          x.doc_list.map((y) => {
            if (y.document_type_id === item.id) {
              a.add(y.document_type_id);
            }
          });
        });
      }
    });

    let array = Array.from(a);

    let req = [];
    documents.map((item) => {
      if (item.is_required === true) {
        req.push(item.id);
      }
    });

    if (
      Array.isArray(req) &&
      Array.isArray(array) &&
      req.length === array.length &&
      req.every((val, index) => val === array[index])
    ) {
    } else {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "Please upload all mandatory documents.",
      });
      return;
    }

    let image;
    if (newfile) {
      image = newfile;
    } else {
      image = "";
    }

    let data = new FormData();
    data.append("id", props.match.params.id);
    data.append("user_id", parseInt(localStorage.getItem("userId")));
    data.append("access_token", access_token);
    data.append("user_type", "authorized_signatory");
    data.append("user_role", roles);
    data.append("name", name.trim());
    data.append("mobile", Mobilenumber.trim());
    data.append("email", email.trim());
    data.append("bvn_number", Bvnnumber.trim());
    data.append("address", address.trim());
    data.append("zip", zip.trim());
    data.append("country_id", parseInt(country));
    data.append("state_id", parseInt(state));
    data.append("city", city.trim());
    data.append("designation", designation.trim());
    data.append("working_location_id", WorkingatBranchHO);
    data.append("image", image);
    setLoader(false);
    const config = {
      method: "post",
      url: process.env.REACT_APP_URL + "/api/v1/user/update",
      headers: {
        "Content-Type": "text/plain",
      },
      data: data,
    };
    axios(config)
      .then((response) => {
        if (response && response.data) {
          if (response.data.success === true) {
            setLoading(false);
            setOpen(true);
            setMessage({ type: "success", content: response.data.message });
            setLoader(true);
            setTimeout(() => {
              history.push("/signatory");
            }, 500);

            //return userDetail();
          } else {
            setLoading(false);
            setOpen(true);
            setLoader(true);
            setMessage({ type: "error", content: response.data.message });
          }
        } else {
          setLoader(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(true);
      });
  };
  const generatedocumentID = (event, document_name, document_type_id) => {
    let mobile_validate =
      /^(?:\+)?\s*[0][1-9]\d{9,12}$|^(?:\+)?\s*[1-9]\d{9,12}$/;

    let bvn_validate = /^[0][1-9]\d{10}$|^[1-9]\d{10}$/;

    if (
      name === "" ||
      name === undefined ||
      Mobilenumber === "" ||
      Mobilenumber === undefined ||
      email === "" ||
      email === undefined ||
      roles === "" ||
      roles === undefined ||
      designation === "" ||
      designation === undefined ||
      Bvnnumber === "" ||
      Bvnnumber === undefined ||
      WorkingatBranchHO === "" ||
      WorkingatBranchHO === undefined ||
      address === "" ||
      address === undefined ||
      zip === "" ||
      zip === undefined ||
      country === "" ||
      country === undefined ||
      state === "" ||
      state === undefined ||
      city === "" ||
      city === undefined
    ) {
      setLoading(false);
      setOpen(true);
      setMessage({ type: "error", content: "All fields are mandatory." });
      return;
    }

    if (!Mobilenumber.replace(/\s/g, "").match(mobile_validate)) {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "Mobile number should be 10 to 13 digit",
      });
      return;
    }
    if (!Bvnnumber.match(bvn_validate)) {
      setLoading(false);
      setOpen(true);
      setMessage({
        type: "error",
        content: "BVN number should be 11 digit",
      });
      return;
    }

    const access_token = getAccessToken();

    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: access_token,
      partner_id: parseInt(props.match.params.id),
      document_type_id: parseInt(document_type_id),
    };

    const config = {
      method: "post",
      url: process.env.REACT_APP_URL + "/api/v1/user/document/id",
      headers: {
        "Content-Type": "text/plain",
      },
      data: data,
    };
    axios(config)
      .then((response) => {
        if (response && response.data) {
          if (response.data.success === true) {
            setPartnerId(response.data.partner_id);
            setDocumentTypeID(response.data.document_type_id);
            setDocumentId(response.data.document_id);
            return documentUpload(
              event,
              document_name,
              document_type_id,
              response.data.document_id
            );
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const documentUpload = (
    event,
    document_name,
    document_type_id,
    document_id
  ) => {
    const access_token = getAccessToken();
    const reader = new FileReader();
    /*reader.addEventListener("load", () => {
            setfile(reader.result);
        });*/
    //setfile2(event.target.files[0]);
    reader.readAsDataURL(event.target.files[0]);

    let data = new FormData();
    data.append("user_id", parseInt(localStorage.getItem("userId")));
    data.append("access_token", access_token);
    data.append("partner_id", parseInt(partner_id));
    data.append("document_type_id", parseInt(document_type_id));
    data.append("document_id", parseInt(document_id ? document_id : 0));
    data.append("document_name", document_name);
    data.append("document_data", event.target.files[0]);

    const config = {
      method: "post",
      url: process.env.REACT_APP_URL + "/api/v1/user/document/upload",
      headers: {
        "Content-Type": "text/plain",
      },
      data: data,
    };
    axios(config)
      .then((response) => {
        if (response && response.data) {
          if (response.data.success) {
            setLoading(false);
            setOpen(true);
            setMessage({ type: "success", content: response.data.message });
            return userDetail();
          } else {
            setLoading(false);
            setOpen(true);
            setMessage({ type: "error", content: response.data.message });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteUpload = (event, contact_id, document_id) => {
    const access_token = getAccessToken();
    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: access_token,
      partner_id: parseInt(partner_id),
      document_type_id: parseInt(document_type_id),
      document_id: parseInt(document_id),
    };
    const config = {
      method: "post",
      url: process.env.REACT_APP_URL + "/api/v1/user/document/delete",
      headers: {
        "Content-Type": "text/plain",
      },
      data: data,
    };
    axios(config)
      .then((response) => {
        if (response && response.data) {
          if (response.data.success === true) {
            setLoading(false);
            setOpen(true);
            setMessage({ type: "success", content: response.data.message });
            return userDetail();
          } else {
            setLoading(false);
            setOpen(true);
            setMessage({ type: "error", content: response.data.message });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const countryFunction = () => {
    const access_token = getAccessToken();

    const userID = localStorage.getItem("userId");

    const data = {
      user_id: localStorage.getItem("userId"),
      access_token: access_token,
    };
    axios
      .post(
        `${process.env.REACT_APP_URL}/api/v1/country/master`,
        JSON.stringify(data)
      )
      .then((response) => {
        if (response && response.data.sucess) {
          setCountryList(response.data.country);
          setLoader(true);
        } else {
          setLoader(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const fetchState = (country_id, data1) => {
    const dataSet = JSON.stringify({
      country_id: country_id,
    });
    const config = {
      method: "post",
      url: process.env.REACT_APP_URL + "/api/v1/state/master",
      headers: {
        "Content-Type": "text/plain",
      },
      data: dataSet,
    };
    axios(config)
      .then((response) => {
        setStateList(response.data.state);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const fetchState1 = (country_id, data1, state) => {
    const dataSet = JSON.stringify({
      country_id: country_id,
    });
    const config = {
      method: "post",
      url: process.env.REACT_APP_URL + "/api/v1/state/master",
      headers: {
        "Content-Type": "text/plain",
      },
      data: dataSet,
    };
    axios(config)
      .then((response) => {
        if (data1 == "allstate1") {
          setStateList(response.data.state);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const removeUserImage = () => {
    setfileView2("");
    setfileView3("");
    const access_token = getAccessToken();

    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: access_token,
      company_id: 0,
      contact_id: parseInt(props.match.params.id),
    };

    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/image/delete`, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        console.log("response", response);
        if (response.data.success) {
          setLoading(false);
          setOpen(true);
          setMessage({
            type: "success",
            content: response.data.message,
          });
        } else {
          setLoading(false);
          setOpen(true);
          setMessage({
            type: "error",
            content: response.data.message,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const userImageUpload = (event) => {
    const access_token = getAccessToken();
    if (event.target.files && event.target.files[0]) {
      let fileext = event.target.files[0];
      if (fileext.name.match(/\.(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/)) {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setfileView2(reader.result);
        });
        setNewfile(event.target.files[0]);
        reader.readAsDataURL(event.target.files[0]);
        setfileView3("");
      } else {
        setLoading(false);
        setOpen(true);
        setMessage({
          type: "error",
          content: "Only jpg, jpeg, PNG, PDF gif files are allowed!",
        });
      }
    }
  };
  // Download uploaded files
  const downloadDocument = (event, document_id) => {
    event.preventDefault();
    const access_token = getAccessToken();

    const data = {
      user_id: parseInt(localStorage.getItem("userId")),
      access_token: access_token,
      partner_id: parseInt(props.match.params.id),
      document_id: parseInt(document_id),
    };

    axios
      .post(
        `${process.env.REACT_APP_URL}/api/v1/user/document/download`,
        data,
        {
          headers: {
            "Content-Type": "text/plain",
          },
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          let url = process.env.REACT_APP_URL + response.data.document_url;
          const a = document.createElement("a");
          a.href = url;
          // a.download=url;
          // a.click();
          // link.href = url;
          a.setAttribute("download", "image.png"); //or any other extension
          document.body.appendChild(a);
          a.click();
        } else {
          setLoading(false);
          setOpen(true);
          setMessage({ type: "error", content: "Something went wrong." });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleTeamMemberImageChage = async (event, id, name) => {
    const access_token = getAccessToken();

    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {});
      reader.readAsDataURL(event.target.files[0]);

      const access_token = getAccessToken();

      const data11 = {
        user_id: localStorage.getItem("userId"),
        access_token: access_token,
        document_type_id: id,
        partner_id: props.match.params.id,
      };

      await axios
        .post(`${process.env.REACT_APP_URL}/api/v1/user/document/id`, data11, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then(async (response) => {
          const data0 = {
            user_id: parseInt(localStorage.getItem("userId")),
            access_token: access_token,
            document_type_id: id,
          };

          let data1 = new FormData();
          data1.append("user_id", localStorage.getItem("userId"));
          data1.append("access_token", access_token);
          data1.append("document_id", response.data.document_id);
          data1.append("document_type_id", id);
          data1.append("document_name", name);
          data1.append("document_data", event.target.files[0]);
          data1.append("partner_id", props.match.params.id);

          await axios
            .post(
              `${process.env.REACT_APP_URL}/api/v1/user/document/upload`,
              data1,
              {
                headers: {
                  "Content-Type": "text/plain",
                },
              }
            )
            .then((response1) => {
              if (response1.data.success == true) {
                setLoading(false);
                setOpen(true);
                setMessage({
                  type: "success",
                  content: response1.data.message,
                });
                uploadDocumentList(props.match.params.id);
              } else {
                setLoading(false);
                setOpen(true);
                setMessage({ type: "error", content: response1.data.message });
              }
            })
            .catch((error) => {});
        })
        .catch((error) => {});
    }
  };
  useEffect(() => {
    document.title = "Interswitch - Update Signatory";
    // window.scrollTo(0, 0);
    countryFunction();
    masterFunction();
    masterFunction1();
    userDetail();
    uploadDocumentList(props.match.params.id);
  }, []);

  return (
    <ThemeContainer type="private" value={5}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}>
        <SnackbarContent
          className={
            message.type === "error"
              ? "snack-error"
              : message.type === "success"
              ? "snack-success"
              : "snack-teal"
          }
          message={message.content}
        />
      </Snackbar>
      {loader !== true ? (
        <Grid container spacing={3} className="bootstrap-loader">
          <Spinner animation="grow" variant="spinner1" />
          <Spinner animation="grow" variant="spinner2" />
          <Spinner animation="grow" variant="spinner3" />
        </Grid>
      ) : (
        <>
          <Box className="scf-box">
            <Box className="scf-subbox">
              <Grid container>
                <Grid item xs={12} sm={12} md={12} xl={12}>
                  <Typography className="company-title11">
                    Edit Signatory
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="form-style">
                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                  <center>
                    <Box className="image-box">
                      {fileview3 ? (
                        <img
                          className="img-thumb"
                          src={`${api_url + fileview3}?${Date.now()}`}
                        />
                      ) : (
                        <>
                          {fileview2 ? (
                            <img className="img-thumb" src={fileview2} />
                          ) : (
                            <img className="img-thumb" src={image} />
                          )}
                        </>
                      )}
                      {!fileview2 && !fileview3 && (
                        <Box className="img-action-option edit">
                          <center>
                            <label>
                              <EditOutlinedIcon className="img-edit" />
                              <input
                                type="file"
                                hidden
                                onChange={(e) => userImageUpload(e)}
                              />
                            </label>
                          </center>
                        </Box>
                      )}
                      {fileview3 || fileview2 ? (
                        <Box
                          className="img-action-option delete"
                          onClick={removeUserImage}>
                          <center>
                            <DeleteOutlineIcon className="img-delete" />
                          </center>
                        </Box>
                      ) : null}
                    </Box>
                  </center>
                </Grid>
                <Grid item xs={8} sm={6} md={4} lg={4} xl={4}>
                  <Typography className="img-heading">
                    {" "}
                    Signatory Person Photo
                  </Typography>
                  <Typography className="img-subheading">
                    {" "}
                    In PNG, PDF JPG, GIF and JPEG format <br></br>
                    size should be less then 2MB
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="input-box">
                    <span className="company-title3">
                      Full Name <span className="required">*</span>
                    </span>
                    <OutlinedInput
                      className="company-title5"
                      fullWidth
                      value={name}
                      placeholder="e.g. Full Name"
                      onChange={(e) => setFullName(e.target.value)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="input-box">
                    <span className="company-title3">
                      Mobile <span className="required">*</span>
                    </span>
                    <OutlinedInput
                      className="company-title5"
                      fullWidth
                      value={Mobilenumber}
                      placeholder="e.g. Mobile Number"
                      onChange={(e) => setMobileNumber(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="input-box">
                    <span className="company-title3">
                      Email Address<span className="required">*</span>
                    </span>
                    <OutlinedInput
                      className="company-title5"
                      fullWidth
                      type="email"
                      value={email}
                      placeholder="e.g. Email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="input-box">
                    <span className="company-title3">
                      Roles <span className="required">*</span>
                    </span>
                    <FormControl fullWidth className={classes.textFields}>
                      <Select
                        displayEmpty
                        value={roles}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Age"
                        onChange={(e) => setRoles(e.target.value)}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}>
                        <MenuItem value={""}> Select from options</MenuItem>
                        <MenuItem value="initiator">
                          Orgnization Admin (Initiator)
                        </MenuItem>
                        <MenuItem value="admin">
                          Orgnization Admin (Admin)
                        </MenuItem>
                        <MenuItem value="approver">Approver</MenuItem>
                        <MenuItem value="executive">Executive</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="input-box">
                    <span className="company-title3">
                      Designation <span className="required">*</span>
                    </span>
                    <OutlinedInput
                      className="company-title5"
                      fullWidth
                      value={designation != false ? designation : ""}
                      placeholder="e.g. Designation"
                      onChange={(e) => setDesignation(e.target.value)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="input-box">
                    <span className="company-title3">
                      BVN Number <span className="required">*</span>
                    </span>
                    <OutlinedInput
                      className="company-title5"
                      fullWidth
                      value={Bvnnumber}
                      placeholder="e.g. BVN Number"
                      onChange={(e) => setBvnNumber(e.target.value)}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="input-box">
                    <span className="company-title3">
                      Working at Branch HO <span className="required">*</span>
                    </span>
                    <FormControl fullWidth className={classes.textFields}>
                      <Select
                        displayEmpty
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Age"
                        value={WorkingatBranchHO}
                        onChange={(e) => setWorkingatBranchHO(e.target.value)}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}>
                        <MenuItem value={""}> Select from options</MenuItem>
                        {hqList && hqList !== "" ? (
                          hqList.map((item, index) => (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          ))
                        ) : (
                          <></>
                        )}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="input-box">
                    <span className="company-title3">
                      Address <span className="required">*</span>
                    </span>
                    <OutlinedInput
                      className="company-title5"
                      fullWidth
                      value={address}
                      placeholder="e.g. Address"
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="input-box">
                    <span className="company-title3">
                      Zip/ Postal Code <span className="required">*</span>
                    </span>
                    <OutlinedInput
                      className="company-title5"
                      fullWidth
                      value={zip}
                      placeholder="e.g. Zip/ Postal Code"
                      onChange={(e) => setZip(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="input-box">
                    <span className="company-title3">
                      Country <span className="required">*</span>
                    </span>
                    <FormControl fullWidth className={classes.textFields}>
                      <Select
                        displayEmpty
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Age"
                        value={country}
                        onChange={(e) => {
                          setCountry(e.target.value);
                          const data = e.currentTarget.getAttribute("name");
                          fetchState(e.target.value, "allstate1");
                        }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}>
                        <MenuItem value={""}> Select from options</MenuItem>
                        {countryList !== "" ? (
                          countryList.map((item, index) => (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          ))
                        ) : (
                          <></>
                        )}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="input-box">
                    <span className="company-title3">
                      State/Provience <span className="required">*</span>
                    </span>
                    <FormControl fullWidth className={classes.textFields}>
                      <Select
                        displayEmpty
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Age"
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}>
                        <MenuItem value="" disabled={true}>
                          Select from options
                        </MenuItem>
                        {stateList !== "" ? (
                          stateList.map((item, index) => (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          ))
                        ) : (
                          <></>
                        )}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} xl={4}>
                  <Box className="input-box">
                    <span className="company-title3">
                      City <span className="required">*</span>
                    </span>
                    <OutlinedInput
                      className="company-title5"
                      fullWidth
                      value={city}
                      placeholder="e.g. City"
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Typography className="sub-heading">Upload Documents</Typography>

              {documents.map((item, index) => (
                <Box className="upload-documents" key={index}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <cenetr>
                            <h4 className="company-title3 documents-title">
                              {item.name}{" "}
                              {item.is_required === true ? (
                                <span className="document-required">*</span>
                              ) : (
                                ""
                              )}
                            </h4>
                          </cenetr>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <center>
                            <Box className="button">
                              <Button
                                component="label"
                                className="button-title">
                                Upload Document
                                <input
                                  type="file"
                                  hidden
                                  onChange={(e) => {
                                    handleTeamMemberImageChage(e, item.id);
                                  }}
                                />
                              </Button>
                            </Box>
                          </center>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Grid container spacing={2}>
                        {uploadDoc &&
                          uploadDoc.map((docItem_item) => (
                            <>
                              {docItem_item.doc_id === item.id ? (
                                <>
                                  {docItem_item.doc_list.map((doc) => (
                                    <>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={6}
                                        lg={6}
                                        xl={6}>
                                        <center>
                                          <Box className="uploaded-documents-list image-box">
                                            {doc.image_extension ===
                                            "application/pdf" ? (
                                              <embed
                                                src={`${
                                                  api_url + doc.document_url
                                                }?${Date.now()}`}
                                                className="image"
                                                type={doc.image_extension}
                                                onClick={(e) =>
                                                  downloadDocument(
                                                    e,
                                                    doc.document_id
                                                  )
                                                }
                                              />
                                            ) : (
                                              <img
                                                className="image"
                                                src={`${
                                                  api_url + doc.document_url
                                                }?${Date.now()}`}
                                                onClick={(e) =>
                                                  downloadDocument(
                                                    e,
                                                    doc.document_id
                                                  )
                                                }
                                              />
                                            )}

                                            <Box className="img-action-option edit">
                                              <label className="edit-label">
                                                <center>
                                                  <EditOutlinedIcon className="img-edit" />
                                                </center>
                                                <input
                                                  type="file"
                                                  hidden
                                                  onChange={(e) =>
                                                    editUpload(
                                                      e,
                                                      doc.document_type_id,
                                                      doc.document_id,
                                                      docItem_item.doc_name
                                                    )
                                                  }
                                                />
                                              </label>
                                            </Box>
                                            <Box className="img-action-option delete">
                                              <center>
                                                <DeleteOutlineIcon
                                                  onClick={() =>
                                                    remove(
                                                      doc.document_type_id,
                                                      doc.document_id
                                                    )
                                                  }
                                                  className="img-delete"
                                                />
                                              </center>
                                            </Box>
                                          </Box>
                                        </center>
                                      </Grid>
                                    </>
                                  ))}
                                </>
                              ) : (
                                ""
                              )}
                            </>
                          ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              ))}

              <Grid container spacing={3} className="button-grid">
                <Grid item xs={6} sm={4} md={2} xl={2}>
                  <Button
                    className="save"
                    onClick={onSubmit}
                    disabled={
                      !name ||
                      !Mobilenumber.replace(/\s/g, "") ||
                      !email ||
                      !roles ||
                      !designation ||
                      !Bvnnumber ||
                      !WorkingatBranchHO ||
                      !address ||
                      !zip ||
                      !country ||
                      !state ||
                      !city
                    }>
                    {" "}
                    Save{" "}
                  </Button>
                </Grid>
                <Grid item xs={6} sm={4} md={2} xl={2}>
                  <Button
                    className="cancel"
                    onClick={(e) => history.push("/signatory")}>
                    {" "}
                    Cancel{" "}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </>
      )}
    </ThemeContainer>
  );
}

viewSignatory.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(viewSignatory);
